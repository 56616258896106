import React from 'react';
import { ReactIntl } from 'react-intl';
import Subscription from '../components/Subscription';

export default class CustomerDetailsContent extends React.Component {
    constructor() {
        super();
        this.state = {
            details: false,
        };
    }

    toggle() {
        this.setState({
            details: !this.state.details,
        });
    }

    render() {
        return (
            <div class='row'>
                <div class='large-12 columns'>
                    {this.props.invoices.map(
                        function(invoice) {
                            return <Subscription subscription={invoice} key={invoice.id} />;
                        }.bind(this),
                    )}
                </div>
            </div>
        );
    }
}
