// @flow
// $FlowFixMe
import './permissions.scss';
import React from 'react';
import { connect } from 'react-redux';
import type { $PermissionsState } from './reducer';
import type { $Dispatcher } from '../../util/Types';
import ModeratorList from '../../components/Settings/TeamSettings';
import ContentLayout from '../../pages/ContentLayout';
import CollaboratorsList from './CollaboratorsList';
import * as Actions from './actions';
import PermissionGroups from './PermissionGroups';
import Error from '../../components/Error';
import NotPermitted from './NotPermitted';
import Loading from '../../components/Loader';

type Props = {
    permissionsDetails: $PermissionsState,
} & $Dispatcher;

class Permissions extends React.Component {
    props: Props;

    componentDidMount(): void {
        const { dispatch } = this.props;
        dispatch(Actions.preparePermissions());
    }

    render() {
        const { permissionsDetails } = this.props;
        const title = (
            <div className="row">
                <div className="medium-12 columns">
                    <h4>Team settings</h4>
                </div>
            </div>
        );
        return (
            <div class="row">
                <div class="medium-12 columns">
                    {permissionsDetails.global.fetching && <Loading />}
                    {permissionsDetails.global.error ? (
                        permissionsDetails.global.error.notPermitted ? (
                            <ContentLayout title={title}>
                                <NotPermitted />
                            </ContentLayout>
                        ) : (
                            <Error />
                        )
                    ) : (
                        permissionsDetails.global.fetched && (
                            <div>
                                <ContentLayout title={title}>
                                    {permissionsDetails.collaborators.error ? (
                                        permissionsDetails.collaborators.error.notPermitted ? (
                                            <NotPermitted />
                                        ) : (
                                            <Error />
                                        )
                                    ) : (
                                        <CollaboratorsList />
                                    )}
                                </ContentLayout>
                                {permissionsDetails.permissionGroups.error ? (
                                    permissionsDetails.permissionGroups.error.notPermitted ? (
                                        <NotPermitted />
                                    ) : (
                                        <Error />
                                    )
                                ) : (
                                    <PermissionGroups />
                                )}
                            </div>
                        )
                    )}
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { permissionsDetails: store.permissions };
})(Permissions);
