import { REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH } from '../../../features/CustomerDetails/actions';
import * as ProcessOut from '../../../util/ProcessOut';

const defaultState = {
    fetched: false,
    fetching: false,
    customer: null,
};

export default function(state = defaultState, action) {
    switch (action.type) {
        case 'FETCH_CUSTOMER_DETAILS_PENDING': {
            return {
                ...defaultState,
                ...state,
                fetching: true,
                fetched: false,
            };
        }
        case 'FETCH_CUSTOMER_DETAILS_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                customer: { ...state.customer, ...action.payload.customer },
            };
        }

        case ProcessOut.typeFailed('FETCH_CUSTOMER_DETAILS'): {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: action.payload,
            };
        }

        case 'EDITING_CUSTOMER_FULFILLED': {
            return {
                ...state,
                customer: { ...state.customer, ...action.payload.customer },
            };
        }

        case ProcessOut.typeFulfilled(REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH): {
            return {
                ...state,
                customer: {
                    ...state.customer,
                    monthlyRevenue: action.payload.monthlyRevenue,
                },
            };
        }
    }

    if (action.type.includes('@@router')) {
        return {
            fetching: false,
            fetched: false,
        };
    }

    return state;
}
