// @flow

import React from 'react';
import { connect } from 'react-redux';
import AceEditor from 'react-ace';
import './polang';
import 'brace/theme/cobalt';
import 'brace/ext/language_tools';
// $FlowFixMe
import './highlight.scss';
import CustomPolangMode from './polang';
import TOKENS_LIST from './PolangAutoComplete';
import { CARDINALITY_FIELDS, TRANSACTION_FIELDS } from '../../ChartPreviewer/PathField/consts';
import uniqid from 'uniqid';
import type { $Dispatcher } from '../../../../util/Types';
import type { $DataExplorerState } from '../reducer';
import * as Actions from '../actions';

type Props = {
    formula: {
        formula: string,
    },
    disabled: boolean,
    dataExplorer: $DataExplorerState,
} & $Dispatcher;

/**
 * We only want to run this code once
 * @type {any | *}
 */
// $FlowFixMe
const langTools = ace.acequire('ace/ext/language_tools');
const customMapper = {
    getCompletions: (editor, session, pos, prefix, callback) => {
        const tokens = [];
        tokens.push(TOKENS_LIST);
        for (const field of TRANSACTION_FIELDS.concat(CARDINALITY_FIELDS).filter(
            (field, index, array) => array.findIndex(f => f === field) === index,
        )) {
            tokens.push({
                value: field,
                name: field,
                meta: 'transactions',
            });
        }

        callback(null, tokens);
    },
};
langTools.addCompleter(customMapper);

class Editor extends React.Component {
    props: Props;

    componentDidMount() {
        this.props.dispatch(Actions.selectTab('editor'));
        const customMode = new CustomPolangMode();
        const editor = this.refs.aceEditor.editor;
        this.refs.aceEditor.editor.getSession().setMode(customMode);
        editor.setOption('enableBasicAutocompletion', false);
        editor.setOption('enableLiveAutocompletion', true);
        editor.setOption('setBehavioursEnabled', true);
        editor.renderer.setShowGutter(false);
        this.resetContent(this.props.dataExplorer.formula);
        this.props.dispatch(Actions.setEditorHasBeenEdited(false));
    }

    // Update the state => content
    onChange = (value: string) => {
        if (value === this.props.dataExplorer.formula) return;
        this.props.dispatch(Actions.updateFormula(value));
        this.props.dispatch(Actions.setEditorHasBeenEdited(true));
    };

    resetContent = (formula: string) => {
        this.props.dispatch(Actions.updateFormula(formula));
    };

    render() {
        return (
            <div class="row">
                <div class="large-12 columns" data-equalizer-watch style={{ minHeight: '15em' }}>
                    <div
                        style={{
                            background: '#002240',
                            margin: 0,
                            padding: '1em 0 0 1em',
                            borderRadius: '4px',
                            boxShadow:
                                '0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07)',
                            border: '1px solid #F0F0F0',
                        }}
                    >
                        <AceEditor
                            ref="aceEditor"
                            theme="cobalt"
                            value={this.props.dataExplorer.formula}
                            onChange={this.onChange}
                            name={uniqid()}
                            editorProps={{ $blockScrolling: true }}
                            showPrintMargin={false}
                            fontSize={14}
                            style={{
                                width: '100%',
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { dataExplorer: store.dataExplorer };
})(Editor);
