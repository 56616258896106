// @flow
import React from 'react';
import Customer from '../components/Customer';
import ContentLayout from './ContentLayout';
import * as CustomerActions from '../Actions/CustomerActions';
import Loader from '../components/Loader';
import Error from '../components/Error';
import Empty from '../components/Empty';
import Pagination from '../components/Pagination';
import { connect } from 'react-redux';
import CustomerModal from '../components/CustomerDetails/DetailsModal';
import PropTypes from 'prop-types';
import { RebuildFilter } from '../features/SearchBar/Util';
import SearchBar from '../features/SearchBar/SearchBar';
import type { $Dispatcher, $State } from '../util/Types';
import NotPermitted from '../features/Permissions/NotPermitted';

const customersPerPage = 15;

type Props = {
    customers: {} & $State,
} & $Dispatcher;

class Customers extends React.Component {
    props: Props;
    state: {
        filter: string,
    };
    _modal: any;

    constructor() {
        super();
        this.state = {
            filter: '',
        };
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Customers | ProcessOut';
        const filter = this.props.location.query.filter;
        this.setState({ filter: filter });
        // we check that there is an afterItem parameter
        // if not then we are going backward and there should be a before item
        const afterItem = this.props.location.query.after_item;
        const beforeItem = this.props.location.query.before_item;
        this.applyFilter(filter, false, afterItem || beforeItem, !beforeItem);
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    componentWillReceiveProps(nextProps: Props) {
        let firstItem = this.props.location.query.after_item;
        if (!firstItem) firstItem = '';

        if (
            !nextProps.customers.fetching &&
            !nextProps.customers.fetched &&
            !nextProps.customers.error
        ) {
            this.props.dispatch(
                CustomerActions.loadCustomers(this.state.filter, customersPerPage, true, firstItem),
            );
        }
    }

    openModal(event: Event) {
        event.preventDefault();
        this._modal.getWrappedInstance().openModal();
    }

    applyFilter = (filter: string, reset: boolean, firstItem: string, forward?: boolean) => {
        this.props.dispatch(
            CustomerActions.loadCustomers(
                filter,
                customersPerPage,
                forward !== null ? forward : true,
                reset ? '' : firstItem,
            ),
        );
    };

    render() {
        const { customers } = this.props.customers;
        let header;
        let content;
        let empty;
        if (
            (this.props.customers.fetching || !this.props.customers.fetched) &&
            !this.props.customers.error
        ) {
            content = <Loader />;
        } else if (this.props.customers.error) {
            if (this.props.customers.error.notPermitted) content = <NotPermitted />;
            else content = <Error />;
        } else {
            if (customers.length === 0) {
                let emptyText = "You haven't created any customer yet.";
                if (this.state.filter !== '') {
                    emptyText = 'No customer matches your search.';
                }
                empty = (
                    <div>
                        <div class='row small-margin-bottom'>
                            <Empty text={emptyText} />
                        </div>
                        <div class='row'>
                            <div class='large-12 columns text-center'>
                                <a
                                    onClick={this.openModal.bind(this)}
                                    class='button round small border'
                                >
                                    New customer
                                </a>
                            </div>
                        </div>
                    </div>
                );
            }

            if (customers.length > 0) {
                header = (
                    <div
                        class='row capitalize greyed bold small-margin-bottom'
                        style={{ fontSize: '0.8em' }}
                    >
                        <div class='medium-offset-1 medium-11 columns'>Name</div>
                    </div>
                );
            }

            content = (
                <div>
                    {empty}
                    {customers.map(function(customer) {
                        return (
                            <div key={customer.id}>
                                <Customer customer={customer} />
                            </div>
                        );
                    })}
                </div>
            );
        }

        const createButton = (
            <a onClick={this.openModal.bind(this)} class='button round border small'>
                New customer
            </a>
        );

        const pageHeader = (
            <div class='row'>
                <div class='medium-2 columns'>
                    <h4>Customers</h4>
                </div>
                <div class='medium-7 columns'>
                    <SearchBar
                        defaultFilter={this.props.location.query.filter || ''}
                        applyFunc={RebuildFilter.bind(this, this.applyFilter)}
                        document={'customers'}
                    />
                </div>
                <div class='medium-3 columns text-right'>{createButton}</div>
            </div>
        );

        return (
            <ContentLayout title={pageHeader}>
                <CustomerModal ref={m => (this._modal = m)} creating={true} />
                <div class='row'>
                    <div class='large-12 columns'>
                        {header}

                        {content}

                        <Pagination
                            reloadFunction={CustomerActions.loadCustomers.bind(
                                null,
                                this.state.filter,
                                customersPerPage,
                            )}
                            hasMore={
                                this.props.customers.fetched ? this.props.customers.has_more : false
                            }
                            hide={this.props.customers.fetching || !this.props.customers.fetched}
                            location={this.props.location}
                            itemsArray={this.props.customers.fetched ? customers : []}
                        />
                    </div>
                </div>
            </ContentLayout>
        );
    }
}

export default connect(store => ({
    customers: store.customers,
    projects: store.projects,
}))(Customers);
