// @flow

import React from 'react';

type Props = {
    onHide: () => void,
};

class AdyenOnboardingWarning extends React.Component<Props> {
    render() {
        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <div class='row margin-bottom'>
                        <div className='large-12 columns'>
                            Glad to have you on board! Adyen’s set up requires a few steps to be
                            done on your Adyen account. Although it can definitely be done on your
                            own, if it’s your initial set up we recommend you book a time slot with
                            your Integrations Team to walk you through the process!
                        </div>
                    </div>
                    <div className='row'>
                        <div
                            class='medium-3 medium-offset-6 columns'
                            style={{ paddingTop: '.5em' }}
                        >
                            <a
                                onClick={() => {
                                    this.props.onHide();
                                }}
                            >
                                Go through setup anyway
                            </a>
                        </div>
                        <div class='medium-3 columns'>
                            <a
                                class='round button small main'
                                onClick={() => {
                                    window.Intercom(
                                        'showNewMessage',
                                        'Hi Integrations Team! I’d like to do my initial Adyen set up.',
                                    );
                                }}
                            >
                                Setup a meeting
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdyenOnboardingWarning;
