// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { $RoutingRule } from './consts';
import * as Actions from './actions';
import type { $Dispatcher } from '../../util/Types';
import Rule from './Rule';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
type Props = {
    rules: Array<$RoutingRule>,
} & $Dispatcher;
import ReactTooltip from 'react-tooltip';
import { SortableContainer } from 'react-sortable-hoc';

class RoutingRuleListing extends React.Component<Props> {
    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    render() {
        const { rules } = this.props;
        return (
            <div class='row'>
                <div class='medium-12 columns'>
                    {rules.length === 0 ? (
                        <div class='greyed' style={{ position: 'relative', top: '.5em' }}>
                            {`You haven't defined any routing rules yet. `}
                            <a
                                class='small round border button right add-rule-button'
                                onClick={() => {
                                    this.props.dispatch(Actions.addRule('route'));
                                }}
                            >
                                Add a routing rule
                            </a>
                        </div>
                    ) : null}
                    <ReactTooltip effect='solid' />
                    <ReactCSSTransitionGroup
                        transitionName='routing-setting-rule-transition'
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={300}
                    >
                        {rules.map((rule, index, array) => {
                            return (
                                <Rule
                                    index={index}
                                    key={rule.id}
                                    rule={rule}
                                    ruleWontMatch={
                                        array
                                            .slice(0, index)
                                            .findIndex(
                                                rule =>
                                                    rule.conditions[0].filters.filter(
                                                        filter =>
                                                            filter.path !== '' &&
                                                            filter.value !== '',
                                                    ).length === 0,
                                            ) > -1
                                    }
                                />
                            );
                        })}
                    </ReactCSSTransitionGroup>
                    {this.props.rulesSettings.rules.length > 0 &&
                    this.props.rulesSettings.rules.filter(
                        rule =>
                            rule.declaration === 'route' &&
                            rule.conditions[0].filters.filter(
                                filter => filter.path !== '' && filter.value !== '',
                            ).length === 0,
                    ).length === 0 ? (
                        <div class='row'>
                            <div class='medium-12 columns'>
                                <div class='rules-warning-no-match'>
                                    <span class='warning'>Warning:</span>{' '}
                                    {`some transactions might not be
                            processed: you should add a final route without any filter to route
                            transactions that didn't match any rule.`}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div class='small-margin-bottom' />
                    <div class='row'>
                        {this.props.rulesSettings.rules.filter(rule => rule.declaration === 'route')
                            .length > 0 ? (
                            <div className='large-offset-7 large-3 columns text-center'>
                                <a
                                    onClick={() => {
                                        this.props.dispatch(Actions.addRule('route'));
                                    }}
                                    class='round border button small right'
                                >
                                    &#43; add another rule
                                </a>
                            </div>
                        ) : null}
                        {this.props.rulesSettings.saving ? (
                            'Saving...'
                        ) : (
                            <div className='large-2 columns text-center'>
                                <a
                                    onClick={() => {
                                        this.props.dispatch(Actions.requestRulesSaving());
                                    }}
                                    class='round main white-text small-font button expanded'
                                >
                                    Save
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const ConnectedRules = connect(store => {
    return {
        rulesSettings: store.routingRulesSettings,
    };
})(RoutingRuleListing);
export default SortableContainer(props => <ConnectedRules {...props} />);
