// @flow

import React from 'react';
import { connect } from 'react-redux';
import ContentLayout from '../../pages/ContentLayout';
import Loading from '../../components/Loader';
import type { $PayoutDetailsState } from './detailsReducer';
import type { $Dispatcher } from '../../util/Types';
import * as Actions from './actions';
import Error from '../../components/Error';
import PayoutItem from './PayoutItem';
import { FormattedNumber } from 'react-intl';
import moment from 'moment';
import MetadataDisplay from '../../components/MetadataDisplay';
import type { $Payout } from './reducer';
import Pagination from '../../components/Pagination';
import ItemsList from './ItemsList';
import NotPermitted from '../Permissions/NotPermitted';
import type { $State } from '../../util/Types';
import PayoutDetailsExporter from '../Exports/PayoutDetailsExporter';

type Props = {
    payoutDetails: $PayoutDetailsState,
    params: {
        project: string,
        payout: string,
    },
} & $Dispatcher;

class PayoutDetails extends React.Component<Props> {
    componentDidMount() {
        const { dispatch, params } = this.props;
        dispatch(Actions.requestPayoutDetailsFetch(params.payout));
    }

    getLine = (key: any, value: any) => {
        return (
            <div className='row small-margin-bottom'>
                <div className='medium-6 columns'>{key}</div>
                <div className='medium-6 columns text-right'>
                    {value || <span class='greyed'>—</span>}
                </div>
            </div>
        );
    };

    render() {
        const { payoutDetails, params } = this.props;
        const payout = payoutDetails.payout;

        return payoutDetails.fetching || !payoutDetails.fetched || !payout ? (
            <Loading />
        ) : payoutDetails.error ? (
            payoutDetails.error.notPermitted ? (
                <NotPermitted />
            ) : (
                <Error />
            )
        ) : (
            <div class='row'>
                <div class='medium-12 columns'>
                    <ContentLayout
                        title={
                            <div className='row'>
                                <div class='medium-6 columns'>
                                    <div class='row'>
                                        <div class='medium-12 columns'>
                                            <h4>Payout Details</h4>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div class='medium-12 columns greyed'>
                                            {payout ? payout.id : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className='medium-6 columns text-right'>
                                    <PayoutDetailsExporter payoutId={payout.id} />
                                </div>
                            </div>
                        }
                        style={null}
                    >
                        <div class='row'>
                            <div class='medium-6 columns'>
                                <span style={{ fontSize: '1.2em' }}>
                                    {this.getLine(
                                        'Amount',
                                        payout.amount !== '0' ? (
                                            Actions.formatCurrency(payout.amount, payout.currency)
                                        ) : (
                                            <span class='greyed'>—</span>
                                        ),
                                    )}
                                </span>
                                {this.getLine(
                                    'Gateway configuration',
                                    payout.gateway_configuration.name,
                                )}
                                {this.getLine(
                                    'Fee',
                                    payout.fees !== '0' ? (
                                        Actions.formatCurrency(payout.fees, payout.currency)
                                    ) : (
                                        <span class='greyed'>—</span>
                                    ),
                                )}
                                {this.getLine(
                                    'Status',
                                    <span
                                        class={`log-level ${
                                            payout.status === 'received'
                                                ? 'success'
                                                : payout.status === 'cancelled' ||
                                                  payout.status === 'failed'
                                                ? 'failed'
                                                : 'warning'
                                        }`}
                                    >
                                        {payout.status}
                                    </span>,
                                )}
                                {payout.status === 'failed' ? (
                                    <div class='row'>
                                        <div class='medium-12 columns'>
                                            {this.getLine('Failure reason', payout.failure_reason)}
                                            {this.getLine(
                                                'Failure code',
                                                payout.failure_reason_code,
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                                {this.getLine('Date', moment(payout.created_at).calendar())}
                                {this.getLine('Bank name', payout.bank_name)}
                                {this.getLine('Bank summary', payout.bank_summary)}
                                <MetadataDisplay metadata={payout.metadata} />
                            </div>
                        </div>
                    </ContentLayout>
                    <ContentLayout
                        title={
                            <div className='row'>
                                <div className='medium-12 columns'>
                                    <h5>Summary</h5>
                                </div>
                            </div>
                        }
                        style={null}
                    >
                        <div class='row'>
                            <div class='medium-12 columns'>
                                <div
                                    class='row capitalize greyed bold'
                                    style={{ fontSize: '.8em' }}
                                >
                                    <div class='medium-2 medium-offset-6 columns text-right'>
                                        Count
                                    </div>
                                    <div className='medium-2 columns text-right'>Fees</div>
                                    <div className='medium-2 columns text-right'>Total</div>
                                </div>
                                <div className='row payout-summary-row'>
                                    <div className='medium-2 columns'>Transactions</div>
                                    <LineColumns />
                                    <div className='medium-2 columns text-right'>
                                        <FormattedNumber value={payout.sales_transactions} />
                                    </div>
                                    <div className='medium-2 columns text-right'>
                                        {Actions.formatCurrency(
                                            `${payout.sales_fees}`,
                                            payout.currency,
                                        )}
                                    </div>
                                    <div className='medium-2 columns text-right'>
                                        {Actions.formatCurrency(
                                            payout.sales_volume,
                                            payout.currency,
                                        )}
                                    </div>
                                </div>
                                <div className='row payout-summary-row'>
                                    <div className='medium-2 columns'>Refunds</div>
                                    <LineColumns />
                                    <div className='medium-2 columns text-right'>
                                        <FormattedNumber value={payout.refunds_transactions} />
                                    </div>
                                    <div className='medium-2 columns text-right'>
                                        {Actions.formatCurrency(
                                            `${payout.refunds_fees}`,
                                            payout.currency,
                                        )}
                                    </div>
                                    <div className='medium-2 columns text-right'>
                                        {Actions.formatCurrency(
                                            payout.refunds_volume,
                                            payout.currency,
                                        )}
                                    </div>
                                </div>
                                <div className='row payout-summary-row'>
                                    <div className='medium-2 columns'>Chargebacks</div>
                                    <LineColumns />
                                    <div className='medium-2 columns text-right'>
                                        <FormattedNumber value={payout.chargebacks_transactions} />
                                    </div>
                                    <div className='medium-2 columns text-right'>
                                        {Actions.formatCurrency(
                                            `${payout.chargebacks_fees}`,
                                            payout.currency,
                                        )}
                                    </div>
                                    <div className='medium-2 columns text-right'>
                                        {Actions.formatCurrency(
                                            payout.chargebacks_volume,
                                            payout.currency,
                                        )}
                                    </div>
                                </div>
                                <div className='row payout-summary-row'>
                                    <div className='medium-2 columns'>Adjustments</div>
                                    <LineColumns />
                                    <div className='medium-2 columns greyed text-right'>—</div>
                                    <div className='medium-2 columns greyed text-right'>—</div>
                                    <div className='medium-2 columns text-right'>
                                        {Actions.formatCurrency(
                                            `${payout.adjustments}`,
                                            payout.currency,
                                        )}
                                    </div>
                                </div>
                                <div className='row margin-bottom payout-summary-row'>
                                    <div className='medium-2 columns'>Reserve</div>
                                    <LineColumns />
                                    <div className='medium-2 columns greyed text-right'>—</div>
                                    <div className='medium-2 columns greyed text-right'>—</div>
                                    <div className='medium-2 columns text-right'>
                                        {Actions.formatCurrency(
                                            `${payout.reserve}`,
                                            payout.currency,
                                        )}
                                    </div>
                                </div>
                                <div className='row' style={{ fontSize: '1.2em' }}>
                                    <div class='medium-6 columns'>Payout</div>
                                    <div className='medium-6 columns text-right'>
                                        {Actions.formatCurrency(payout.amount, payout.currency)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ContentLayout>
                    <ItemsList />
                </div>
            </div>
        );
    }
}

const LineColumns = props => {
    return (
        <div className='medium-4 columns' style={{ padding: 0 }}>
            <div
                style={{
                    width: '100%',
                    height: '1px',
                    background: '#cacaca',
                    position: 'relative',
                    top: '.5em',
                    opacity: 0.7,
                }}
            />
        </div>
    );
};

export default connect(store => {
    return {
        payoutDetails: store.payoutDetails,
    };
})(PayoutDetails);
