export default function(
    state = {
        fetching: false,
        fetched: false,
    },
    action,
) {
    switch (action.type) {
        case 'FETCH_INVOICES_PENDING': {
            return {
                ...state,
                error: false,
                fetching: true,
                fetched: false,
            };
        }
        case 'FETCH_INVOICES_FULFILLED': {
            if (!action.payload.success) {
                return {
                    ...state,
                    fetching: false,
                    fetched: true,
                    invoices: [],
                };
            }
            return {
                ...state,
                fetching: false,
                fetched: true,
                invoices: action.payload.products,
                has_more: action.payload.has_more,
            };
        }
        case 'FETCH_INVOICES_REJECTED': {
            return {
                ...state,
                error: action.payload,
                fetched: false,
                fetching: false,
            };
        }
    }

    return state;
}
