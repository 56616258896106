// @flow
import React from 'react';
import Analytics from '../../util/Analytics';
// $FlowFixMe
import './style.scss';

type Props = {
    surface: string,
    email: ?string,
};

type State = {
    requested: boolean,
};

class TalkToExpertCTA extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            requested: false,
        };
    }

    render() {
        const { surface, email } = this.props;
        const { requested } = this.state;

        if (!requested)
            return (
                <a
                    class='round button main small'
                    onClick={() => {
                        Analytics('REQUESTED_EXPERT_ADVICE', {
                            email: email || 'Unknown email',
                            surface: surface,
                        });
                        this.setState({ requested: true });
                    }}
                >
                    Talk to an expert
                </a>
            );
        return (
            <div class='expert-request-done'>
                {'Done ✅! An expert will get back to you as soon as possible!'}
            </div>
        );
    }
}

export default TalkToExpertCTA;
