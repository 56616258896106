import React from 'react';
import { connect } from 'react-redux';
import * as ISOCountries from '../../util/ISOCountries';
import PropTypes from 'prop-types';
import Loader from '../Loader';

@connect(function(store) {
    return {};
})
export default class GeoChart extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        color: PropTypes.func,
        tip: PropTypes.func,
    };

    draw() {
        if (!this.props.data || !document.getElementById(`chart${this.state.uniqid}`)) return;
        if (!this.state.map) {
            this.setState({
                map: new Picasso.MapChart(`#chart${this.state.uniqid}`, {
                    tip: this.props.tip,
                }),
            });
            return;
        }

        var map = this.state.map;
        for (var country of this.props.data) {
            var c = ISOCountries.isoCountries[country.key];
            if (!c) continue;
            map.addCountry({
                key: country.key,
                name: c['alpha3'],
                country: country,
                color: this.props.color,
            });
        }

        map.resetSVG();
        map.draw();
    }

    componentWillMount() {
        this.setState({
            uniqid: Math.floor(Math.random() * 1000),
        });
    }

    componentDidMount() {
        this.draw();
    }

    componentDidUpdate() {
        this.draw();
    }

    render() {
        if (!this.props.data) {
            return <Loader />;
        }

        return (
            <svg
                class="chart map-chart"
                id={`chart${this.state.uniqid}`}
                width="100%"
                height="100%"
            />
        );
    }
}
