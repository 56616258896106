// @flow

import type { $State } from '../../util/Types';

export type $ApiSetupState = {
    currentApiVersion: string,
    apiVersions: Array<{ name: string, description: string }>,
    latestApiVersion: string,
    publicKey: string,
    apiKeys: Array<$ApiKey>,
} & $State;

export type $ApiKeyModel = {
    name: string,
    project_id: string,
    created_at: string,
    enabled: boolean,
    sandbox: boolean,
};

export type $ApiKey = $ApiKeyModel & {};

export const PREPARE_API_SETUP = 'PREPARE_API_SETUP';
export const FETCH_NEW_PRIVATE_KEY = 'FETCH_NEW_PRIVATE_KEY';
export const CREATE_NEW_PRIVATE_KEY = 'CREATE_NEW_PRIVATE_KEY';
export const DELETE_PRIVATE_KEY = 'DELETE_PRIVATE_KEY';
export const SAVE_PRIVATE_KEY_UPDATE = 'SAVE_PRIVATE_KEY_UPDATE';
