// @flow
import React from 'react';
import ReactTooltip from 'react-tooltip';

class Warning extends React.Component<{
    text: string,
}> {
    componentDidMount() {
        ReactTooltip.rebuild();
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    render() {
        return (
            <div class='telescope-warning' data-tip={this.props.text}>
                <ReactTooltip effect={'solid'} />
                <img src='//dashboard.processout.com/images/warning.png' />
            </div>
        );
    }
}

export default Warning;
