import React from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import MetadataDisplay from '../MetadataDisplay';
import { connect } from 'react-redux';

@connect(function(store) {
    return {};
})
export default class Operation extends React.Component {
    constructor() {
        super();
    }

    openDetails(event) {
        event.preventDefault();
        let operation = this.props.operation;
        let header = 'Operation details';

        let gwayId;
        let gwayConfName;
        let gwayMessage;
        if (operation.gateway_operation_id !== '') {
            gwayId = (
                <div class='row small-margin-bottom'>
                    <div class='large-3 columns greyed'>Processor ID</div>
                    <div
                        class='large-9 columns text-right small-font font-console'
                        style={{ wordWrap: 'break-word' }}
                    >
                        {operation.gateway_operation_id}
                    </div>
                </div>
            );
        }
        if (operation.gateway_configuration) {
            gwayConfName = (
                <div class='row small-margin-bottom'>
                    <div class='large-3 columns greyed'>Gateway configuration</div>
                    <div class='large-9 columns text-right' style={{ wordWrap: 'break-word' }}>
                        {operation.gateway_configuration.name}
                    </div>
                </div>
            );
        }
        if (
            operation.gateway_variable !== '' &&
            operation.gateway_variable !== operation.gateway_operation_id
        ) {
            gwayMessage = (
                <div class='row small-margin-bottom'>
                    <div class='large-3 columns greyed'>Data</div>
                    <div
                        class='large-9 columns text-right small-font font-console'
                        style={{ wordWrap: 'break-word' }}
                    >
                        {operation.gateway_variable || <span class='greyed'>——</span>}
                    </div>
                </div>
            );
        }

        let content = (
            <div class='row'>
                <div class='large-12 columns'>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <h6
                                class='capitalize greyed font-console'
                                style={{ marginBottom: '0px' }}
                            >
                                Details
                            </h6>
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-3 columns greyed'>Identifier</div>
                        <div
                            class='large-9 columns small-font font-console text-right'
                            style={{ wordWrap: 'break-word' }}
                        >
                            {operation.id}
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-3 columns greyed'>Name</div>
                        <div class='large-9 columns text-right'>{operation.type}</div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-3 columns greyed'>Created on</div>
                        <div class='large-9 columns text-right'>
                            <FormattedDate
                                value={operation.created_at}
                                day='2-digit'
                                month='2-digit'
                                year='numeric'
                            />
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-3 columns greyed'>Status</div>
                        <div class='large-3 columns text-right'>
                            <div
                                class={
                                    'log-level ' +
                                    (operation.is_attempt
                                        ? 'warning blinking'
                                        : operation.has_failed
                                        ? 'failed'
                                        : 'success')
                                }
                                style={{
                                    marginTop: '5px',
                                    marginRight: '0px',
                                    marginLeft: '0px',
                                }}
                            >
                                {operation.is_attempt
                                    ? 'pending'
                                    : operation.has_failed
                                    ? 'failed'
                                    : 'success'}
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='large-3 columns greyed'>Error code</div>
                        <div class='large-9 columns text-right font-console'>
                            {operation.error_code || <span class='greyed'>—</span>}
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <hr />
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <h6 class='capitalize greyed font-console'>Processor</h6>
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-3 columns greyed'>Name</div>
                        <div class='large-9 columns text-right' style={{ wordWrap: 'break-word' }}>
                            {operation.gateway_configuration ? (
                                <img
                                    style={{ maxHeight: '1em' }}
                                    src={operation.gateway_configuration.gateway.logo_url}
                                />
                            ) : (
                                <span class='greyed'>—</span>
                            )}
                        </div>
                    </div>
                    {gwayConfName}
                    {gwayId}
                    {gwayMessage}
                    <div class='row small-margin-bottom'>
                        <div class='large-3 columns greyed'>Acquirer</div>
                        <div class='large-9 columns text-right'>
                            {operation.acquirer_name || <span class='greyed'>—</span>}
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-3 columns greyed'>Amount</div>
                        <div class='large-9 columns text-right'>
                            <FormattedNumber
                                value={operation.amount}
                                style='currency'
                                currency={this.props.transaction.currency}
                            />
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <hr />
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <h6 class='capitalize greyed font-console'>Metadata</h6>
                        </div>
                    </div>
                    <MetadataDisplay
                        metadata={operation.metadata}
                        displayTaxes={true}
                        displayShipping={true}
                    />
                </div>
            </div>
        );

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: header,
                content: content,
                footer: (
                    <div class='row'>
                        <div class='large-12 columns text-right'>
                            <a
                                onClick={function(event) {
                                    event.preventDefault();
                                    this.props.dispatch({
                                        type: 'CLOSE_MODAL',
                                    });
                                }.bind(this)}
                                class='round border small button'
                            >
                                Close
                            </a>
                        </div>
                    </div>
                ),
            },
        });
    }

    render() {
        const transaction = this.props.transaction;
        const operation = this.props.operation;

        let logLevel = 'success';
        if (operation.has_failed) logLevel = 'failed';
        if (operation.is_attempt) logLevel = 'warning blinking';

        if (operation.type == 'request') {
            if (this.props.request) logLevel = 'success';
            else logLevel = 'warning';
        }

        return (
            <a onClick={this.openDetails.bind(this)}>
                <div
                    class='row small-margin-bottom box-row'
                    style={{
                        marginBottom: '0px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                    }}
                >
                    <div class='large-4 columns'>{operation.type}</div>
                    <div class='large-2 columns'>
                        <span class='right'>
                            <FormattedNumber
                                value={operation.amount}
                                style='currency'
                                currency={transaction.currency}
                            />
                        </span>
                    </div>
                    <div class='large-4 columns'>
                        <span class='right'>
                            <FormattedDate
                                value={operation.created_at}
                                day='2-digit'
                                month='2-digit'
                                year='numeric'
                                hour='numeric'
                                minute='numeric'
                                time='numeric'
                            />
                        </span>
                    </div>
                    <div class='large-2 columns text-right'>
                        <div
                            class={'right log-level ' + logLevel}
                            style={{
                                width: 'fit-content',
                                marginTop: '0px',
                                marginRight: '0',
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: '1em',
                                    marginRight: '1em',
                                }}
                            >
                                {operation.is_attempt
                                    ? 'pending'
                                    : operation.has_failed
                                    ? 'failed'
                                    : 'ok'}
                            </span>
                        </div>
                    </div>
                </div>
            </a>
        );
    }
}
