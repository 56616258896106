// @flow

import React from 'react';
import type { $LocalReport, $Report } from './reducer';
import moment from 'moment';
import { FormattedNumber } from 'react-intl';

type Props = {
    report: $LocalReport,
};

class UploadingReport extends React.Component {
    props: Props;

    render() {
        const { report } = this.props;
        const progression =
            report.status === 'pending'
                ? 0
                : report.status === 'processing' && report.progress_percentage
                  ? report.progress_percentage / 100
                  : 0;
        return (
            <div class="row report-row">
                <div class="medium-9 columns">{report.fileName}</div>
                <div class="medium-3 columns text-right">
                    <div className="progress-bar">
                        <div
                            className="content"
                            style={{ width: `${report.progress_percentage * 100}%` }}
                        />
                        <div className="progress-label">
                            <FormattedNumber value={report.progress_percentage} style={'percent'} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UploadingReport;
