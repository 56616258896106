// @flow
import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import type { $Collaborator } from './consts';
import type { $Dispatcher } from '../../util/Types';
import { OWNER, PRIMARY_OWNER, CUSTOM_PERM } from './consts';
import type { $PermissionsState } from './reducer';
import PermissionGroupSelection from './PermissionGroupSelection';
import * as Actions from './actions';

var serialize = require('form-serialize');
var md5 = require('js-md5');
import uniqid from 'uniqid';
import Delete from '../../components/Delete';
type Props = { permissionsDetails: $PermissionsState } & $Dispatcher;
class CollaboratorsList extends React.Component {
    props: Props;

    saveTeamSettings = () => {
        const { dispatch } = this.props;
        dispatch(Actions.saveTeamSettings());
    };

    render() {
        const { permissionsDetails } = this.props;
        let content;
        if (!permissionsDetails.collaborators.fetched) {
            content = (
                <div class='large-12 columns text-center'>
                    <Loader />
                </div>
            );
        } else {
            content = (
                <div>
                    {permissionsDetails.collaborators.collaborators.map(collaborator => {
                        return (
                            <ConnectedCollaborator
                                key={collaborator.id}
                                collaborator={collaborator}
                            />
                        );
                    })}
                </div>
            );
        }

        return (
            <div className='row'>
                <div className='medium-12 columns'>
                    <div class='row margin-bottom'>
                        <div class='medium-12 columns'>{content}</div>
                    </div>
                </div>
                <div className='row small-margin-bottom'>
                    <div className='medium-2 columns medium-offset-7 text-right'>
                        <a
                            onClick={() => {
                                const { dispatch } = this.props;
                                dispatch(Actions.addCollaborator());
                            }}
                            className='small round border button expanded'
                        >
                            Add collaborator
                        </a>
                    </div>
                    <div className='medium-3 columns text-right'>
                        {permissionsDetails.collaborators.saving ? (
                            <Loader size={14} />
                        ) : (
                            <a
                                onClick={this.saveTeamSettings}
                                className='small round main button expanded'
                            >
                                Save collaborators
                            </a>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

class Collaborator extends React.Component {
    props: {
        collaborator: $Collaborator,
        creation: boolean,
    } & $Dispatcher;
    state: {
        hidden: boolean,
    };

    constructor() {
        super();
        this.state = {
            hidden: false,
        };
    }

    deleteModerator = () => {
        const { collaborator, dispatch } = this.props;
        dispatch(Actions.deleteModerator(collaborator.id));
    };

    submit = event => {
        event.preventDefault();
        const { dispatch } = this.props;
        const collaborator = serialize(event.target, { hash: true });
    };

    render() {
        const { collaborator } = this.props;
        const role = collaborator ? collaborator.role : '';

        let email;
        if (!collaborator.editing) {
            email = (
                <span class='greyed'>
                    <img
                        className='gravatar-icon'
                        style={{ marginRight: '1em', display: 'inline-block' }}
                        src={
                            'https://secure.gravatar.com/avatar/' +
                            md5(collaborator.user.email) +
                            '?s=80&r=g&d=identicon'
                        }
                    />
                    <div
                        style={{ display: 'inline-block', width: '90%', textOverflow: 'ellipsis' }}
                    >
                        {collaborator.user.email}
                    </div>
                </span>
            );
        } else {
            email = (
                <input
                    type='email'
                    class='bottom-border'
                    name='user_email'
                    value={collaborator.user.email}
                    onChange={event => {
                        const { dispatch } = this.props;
                        dispatch(
                            Actions.updateCollaboratorEmail(collaborator.id, event.target.value),
                        );
                    }}
                    placeholder='Account email'
                    required
                />
            );
        }

        let submitButton;
        if (role !== PRIMARY_OWNER) submitButton = <Delete onDelete={this.deleteModerator} />;

        let roleDisplay;
        if (role === PRIMARY_OWNER) {
            roleDisplay = (
                <div class='large-12 columns greyed' style={{ marginBottom: '.5em' }}>
                    Primary owner of this project.
                </div>
            );
        } else if (collaborator) {
            roleDisplay = (
                <div class='medium-12 columns' style={{ paddingRight: '0px' }}>
                    <PermissionGroupSelection collaborator={collaborator} />
                    <div
                        class='info'
                        data-tip='Setup your own permissions using a permission group below'
                    >
                        i
                    </div>
                </div>
            );
        }

        return (
            <div class='row small-margin-bottom'>
                <ReactTooltip effect='solid' />
                <form id='new-moderator-form' onSubmit={this.submit}>
                    <div
                        class='medium-3 columns'
                        style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
                    >
                        {email}
                    </div>
                    <div class='medium-4 columns'>
                        <div
                            style={{
                                width: '100%',
                                height: '1px',
                                background: '#cacaca',
                                position: 'relative',
                                top: '1em',
                                opacity: 0.7,
                            }}
                        />
                    </div>
                    <div class='medium-4 columns'>
                        <div class='row' style={{ marginTop: collaborator ? 0 : '0.5em' }}>
                            {roleDisplay}
                        </div>
                    </div>
                    <div class='medium-1 columns text-right'>
                        <div style={{ position: 'relative', top: '.5em' }}>{submitButton}</div>
                    </div>
                </form>
            </div>
        );
    }
}

const ConnectedCollaborator = connect()(Collaborator);

export default connect(store => {
    return { permissionsDetails: store.permissions };
})(CollaboratorsList);
