// @flow
import { all, put, takeLatest, select, take, call } from 'redux-saga/effects';
import {
    DUPLICATE_BOARD,
    REQUEST_BOARD_DUPLICATION,
    REQUEST_BOARD_PREPARATION,
    REQUEST_SAVE_LOCAL_LAYOUT,
} from './consts';
import type { $Action } from '../../../../util/Types';
import * as ProcessOut from '../../../../util/ProcessOut';
import { typeFailed, typeFulfilled } from '../../../../util/ProcessOut';
import * as BoardActions from './actions';
import type { $Params } from '../../ChartPreviewer/consts';
import type { $Board } from '../consts';
import { REQUEST_BOARDS_FETCH } from '../consts';
import { push } from 'react-router-redux';
import { createChartReducerWithId } from '../charts/reducer';
import * as Store from '../../../../stores/Store';
import { combineReducers } from 'redux';
import { injectReducer } from '../../../../stores/Reducers/Index';
import { replaceForCountries, replaceForCurrencies, replaceForGwayKeys } from '../charts/utils';
import * as ChartsActions from '../charts/actions';
import { REQUEST_ADD_TEXT_CHART } from './consts';
import * as ChartPreviewerActions from '../../ChartPreviewer/actions';
import { APIcallPromise } from '../../../../util/ProcessOut';
import type { $BoardLayout } from '../../Boards/Board/consts';

type Action = {
    payload: {
        board: string, // board id
        fetchParams: $Params,
    },
} & $Action;

export function* prepareBoard(action: Action): Generator<*, *, *> {
    try {
        const { board, fetchParams } = action.payload;
        const boardDetails = yield put.resolve(BoardActions.fetchBoardDetails(board, fetchParams));
        if (!boardDetails.value.data.success) throw 'Error while fetching board data';

        const charts = boardDetails.value.data.board.charts.slice();

        const chartsReducers = charts.reduce((result, chart) => {
            return {
                ...result,
                [chart.id]: createChartReducerWithId(chart.id),
            };
        }, {});
        const store = Store.store;
        if (Object.keys(chartsReducers).length > 0) {
            const newReducers = injectReducer(combineReducers(chartsReducers), 'charts');
            store.replaceReducer(newReducers);
        }

        const initCharts = boardDetails.value.data.board.charts.map(chart => {
            return put.resolve({
                type: `INIT_CHART_${chart.id}`,
                payload: {
                    ...chart,
                },
            });
        });

        // we fetch the gateway ids and names list from the store.
        let gway_configurations_names = yield select(store => store.gateway_configurations_names);
        // if we don't have them we wait for it
        while (!gway_configurations_names.fetched) {
            yield take();
            gway_configurations_names = yield select(store => store.gateway_configurations_names);
        }

        // we update the selector_datapoints with the gateway names
        let selector_datapoints = boardDetails.value.data.selector_datapoints;
        if (selector_datapoints) {
            selector_datapoints = replaceForGwayKeys(
                boardDetails.value.data.selector_datapoints,
                gway_configurations_names.gateway_configuration_names,
            );
            selector_datapoints = replaceForCountries(selector_datapoints);
            selector_datapoints = replaceForCurrencies(selector_datapoints);
        }

        yield all(initCharts);

        yield put({
            type: typeFulfilled(REQUEST_BOARD_PREPARATION),
            payload: {
                ...boardDetails.value.data,
                board: {
                    ...boardDetails.value.data.board,
                    charts: charts,
                },
                selector_datapoints: selector_datapoints,
            },
        });
    } catch (e) {
        yield put({
            type: typeFailed(REQUEST_BOARD_PREPARATION),
            payload: { error: e },
        });
    }
}

type $DuplicateAction = {
    payload: {
        newBoard: $Board,
        projectId: string,
    },
} & $Action;

function* duplicateBoard(action: $DuplicateAction): Generator<*, *, *> {
    const { newBoard, projectId } = action.payload;
    const boardResult = yield put.resolve({
        type: DUPLICATE_BOARD,
        payload: ProcessOut.APIcallPromise('/boards', 'POST', JSON.stringify(newBoard)),
    });
    if (boardResult.value.data.success) {
        yield put({ type: REQUEST_BOARDS_FETCH, payload: { silent: true } });
        yield put(push(`/projects/${projectId}/boards/${boardResult.value.data.board.id}`));
    } else {
        yield put({ type: typeFailed(REQUEST_BOARD_DUPLICATION) });
    }
}

type $SaveLocalLayoutAction = {
    payload: {
        boardId: string,
        charts: any,
    },
};
function* saveLocalLayout(action: $SaveLocalLayoutAction): Generator<*, *, *> {
    const { boardId, charts } = action.payload;
    const layout = [];
    for (const index in charts) {
        const chart = charts[index];
        layout.push({
            chart_id: chart.i,
            height: chart.h,
            position_x: chart.x,
            position_y: chart.y,
            size: chart.w,
        });
    }

    // We retrieve all the charts that were selected to be deleted
    const storeCharts = yield select(store => store.charts);
    const chartsToBeDeleted: Array<string> = [];
    for (const chart in storeCharts) {
        if (storeCharts[chart].selectedForDeletion) {
            chartsToBeDeleted.push(chart);
        }
    }

    // run all http calls to delete them
    yield all(chartsToBeDeleted.map(chartId => ChartsActions.deleteChart(boardId, chartId)));

    // save the new layout
    yield put.resolve(BoardActions.updateBoardGrid(boardId, layout));
}

function* addTextChart(): Generator<*, *, *> {
    const newChart = {
        description: '',
        name: 'text',
        type: 'text',
        text_markdown: '',
        size: 6,
        position: { x: 0, y: 0 },
        height: 3,
    };

    const params = yield select(store => store.analytics.params);
    const currentProject = yield select(store => store.currentProject);
    const board = yield select(store => store.analytics_v2.boardDetails.board);

    // First we move every chart down

    const newLayout: $BoardLayout = [];
    for (const chart of board.charts) {
        newLayout.push({
            chart_id: chart.id,
            height: chart.height,
            position_x: chart.position_x,
            position_y: chart.position_y + newChart.height,
            size: chart.size,
        });
    }

    yield call(APIcallPromise, `/boards/${board.id}/charts-positions`, 'PUT', {
        charts: newLayout,
    });

    yield put(
        ChartPreviewerActions.requestChartSave(
            null,
            currentProject.project.id,
            'text',
            '',
            'Double click to edit',
            'text',
            '',
            6,
            board.id,
            '',
            params,
            newChart.position,
            newChart.height,
        ),
    );
}

export default function* watchForBoardPreparation(): Generator<*, *, *> {
    yield takeLatest(REQUEST_BOARD_DUPLICATION, duplicateBoard);
    yield takeLatest(REQUEST_BOARD_PREPARATION, prepareBoard);
    yield takeLatest(REQUEST_SAVE_LOCAL_LAYOUT, saveLocalLayout);
    yield takeLatest(REQUEST_ADD_TEXT_CHART, addTextChart);
}
