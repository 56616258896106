// @flow

import React from 'react';
import { FormattedDate } from 'react-intl';
import DateDisplay from '../DateDisplay';
import { connect } from 'react-redux';
import * as Actions from './actions';
import type { $Dispatcher } from '../../util/Types';

export type $ExportStatus = 'pending' | 'processing' | 'processed';
export type $ExportType = 'transaction' | 'payout' | 'payout_item';

export type $Export = {
    id: string,
    start_at: string,
    end_at: string,
    filter: string,
    type: $ExportType,
    status: $ExportStatus,
    description: string,
    created_at: string,
};

type Props = {
    ex: $Export,
} & $Dispatcher;

class Export extends React.Component<Props> {
    requestDownload = () => {
        const { dispatch, ex } = this.props;
        dispatch(Actions.requestExportDownload(ex.id));
    };

    render() {
        const { ex } = this.props;
        return (
            <div
                class='row box-row padding'
                onClick={this.requestDownload}
                style={{ cursor: 'pointer' }}
            >
                <div class='medium-1 columns'>
                    <div
                        className={`log-level ${
                            ex.status === 'pending' || ex.status === 'processing'
                                ? 'warning'
                                : 'success'
                        }`}
                    >
                        {ex.status}
                    </div>
                </div>
                <div class='medium-3 columns'>{ex.description}</div>
                <div class='medium-3 columns'>
                    <FormattedDate
                        value={ex.start_at}
                        day={'2-digit'}
                        month={'2-digit'}
                        year={'2-digit'}
                    />{' '}
                    —{' '}
                    <FormattedDate
                        value={ex.end_at}
                        day={'2-digit'}
                        month={'2-digit'}
                        year={'2-digit'}
                    />
                </div>
                <div class='medium-2 columns'>{ex.type}</div>
                <div class='medium-3 columns text-right greyed'>
                    <DateDisplay value={ex.created_at} />
                </div>
            </div>
        );
    }
}

export default connect()(Export);
