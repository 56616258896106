// @flow
/**
 * Created by jeremylejoux on 03/10/2017.
 */

import React from 'react';
import { connect } from 'react-redux';
import Error from '../../components/Error';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router';

type Props = {
    user: {
        details: {
            email: string,
        },
    },
    location: {
        pathname: string,
    },
};

class ErrorBoundary extends React.Component {
    props: Props;
    state: {
        hasError: boolean,
    };

    constructor() {
        super();
        this.state = { hasError: false };
    }

    componentDidCatch(error: any, info: any) {
        // Display fallback UI
        this.setState({ hasError: true });

        // Set user context if available
        let { user } = this.props;
        if (user.details.email) {
            Sentry.configureScope(scope => {
                scope.setUser({ email: user.details.email });
            });
        }

        // Capture error in sentry
        Sentry.captureException(error);
    }

    componentWillReceiveProps(nextProps: Props, nextContext: any): void {
        if (this.state.hasError && nextProps.location.pathname !== this.props.location.pathname) {
            // location changed we reset the error boundary
            this.setState({ hasError: false });
        }
    }

    render() {
        if (this.state.hasError) {
            // FAllback UI rendered
            return (
                <div style={{ marginTop: '2em' }}>
                    <Error />
                </div>
            );
        }
        return this.props.children;
    }
}

export default connect(function(store) {
    return {
        user: store.user,
    };
})(withRouter(ErrorBoundary));
