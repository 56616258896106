// @flow
import React from 'react';

type Props = {
    value: ?string,
    onChange: string => void,
};

class TextFilter extends React.Component<Props> {
    render() {
        const { value, onChange } = this.props;
        return (
            <div class='row collapse'>
                <div class='medium-12 columns'>
                    <input
                        type='text'
                        value={value}
                        onChange={event => {
                            onChange(event.target.value);
                        }}
                        placeholder={'Is equal to...'}
                    />
                </div>
            </div>
        );
    }
}

export default TextFilter;
