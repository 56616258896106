import * as ApplePayActions from '../../Actions/ApplePayActions';
import * as ProcessOut from '../../util/ProcessOut';

export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case 'SET_PROJECT_ID': {
            return {
                ...state,
                fetching: false,
                fetched: false,
                project: null,
            };
        }

        case 'FETCH_PROJECT_DETAILS_PENDING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: false,
            };
        }

        case 'FETCH_PROJECT_DETAILS_FULFILLED': {
            return {
                ...state,
                ...action.payload,
                fetched: true,
                fetching: false,
            };
        }

        case 'FETCH_PROJECT_DETAILS_REJECTED': {
            return {
                ...state,
                error: true,
                fetching: false,
                fetched: false,
            };
        }

        case ProcessOut.typeFulfilled(ApplePayActions.CREATE_KEYS_APPLE_PAY): {
            return {
                ...state,
                error: false,
                fetching: false,
                fetched: true,
                project: {
                    ...state.project,
                    applepay_settings: {
                        ...state.project.applepay_settings,
                        merchant_csr: action.payload.keys.merchant_csr,
                        processing_csr: action.payload.keys.processing_csr,
                        merchant_domain_names: state.merchant_domain_names || [],
                    },
                },
            };
        }

        case ProcessOut.typeFulfilled(ApplePayActions.DISABLE_APPLE_PAY):
        case ProcessOut.typeFulfilled(ApplePayActions.ENABLE_APPLE_PAY): {
            return {
                ...state,
                project: { ...state.project, ...action.payload.project },
            };
        }
    }

    return state;
}
