// @flow

import { takeLatest, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import * as ProcessOut from '../../util/ProcessOut';

export const REQUEST_DECLINE_EXPLANATION_FETCH = 'REQUEST_DECLINE_EXPLANATION_FETCH';
export type $FetchDeclineExplainationAction = {
    type: 'REQUEST_DECLINE_EXPLANATION_FETCH',
    payload: {
        transaction: string,
    },
};
function* fetchDeclineExplaination(action: $FetchDeclineExplainationAction): Generator<*, *, *> {
    try {
        const { transaction } = action.payload;
        const explainationResult = yield ProcessOut.APIcallPromise(
            `/transactions/${transaction}/decline-explainer`,
            'GET',
            null,
            null,
            true,
            true,
        );
        yield put({
            type: ProcessOut.typeFulfilled(REQUEST_DECLINE_EXPLANATION_FETCH),
            payload: explainationResult.data.explanation,
        });
    } catch (error) {
        yield put({
            type: ProcessOut.typeFailed(REQUEST_DECLINE_EXPLANATION_FETCH),
            payload: { error },
        });
        Sentry.captureException(error);
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeLatest(REQUEST_DECLINE_EXPLANATION_FETCH, fetchDeclineExplaination);
}
