import * as ProcessOut from '../util/ProcessOut';
import * as RefundActions from '../Actions/RefundActions';

export function loadTransactions(filter, length, after, id) {
    filter = filter ? encodeURIComponent(filter) : '';
    var bound = after ? 'start_after' : 'end_before';
    if (!id) {
        id = '';
    }
    return {
        type: 'FETCH_TRANSACTIONS',
        payload: ProcessOut.APIcallPromise(
            `/transactions?${bound}=${id}&limit=${length}&order=desc&expand[]=gateway_configuration.gateway&expand[]=customer&expand[]=card&filter=${filter}`,
            'GET',
            null,
        ),
    };
}

export function loadTransactionDetails(id) {
    return function(dispatch) {
        dispatch({
            type: 'FETCH_TRANSACTION_DETAILS',
            payload: new Promise(function(resolve, reject) {
                ProcessOut.APIcallPromise(
                    `/transactions/${id}?expand[]=subscription&expand[]=operations.gateway_configuration.gateway&expand[]=gateway_configuration.gateway&expand[]=customer&expand[]=operations&expand[]=card&expand[]=invoice`,
                    'GET',
                    null,
                ).then(function(response) {
                    if (response.data.success && response.data.transaction.gateway_configuration)
                        dispatch(RefundActions.getRefunds(id));
                    resolve(response);
                    return response;
                });
            }),
        });
    };
}

export function captureTransaction(transaction) {
    return function(dispatch) {
        return {
            type: 'CAPTURE_TRANSACTION',
            payload: ProcessOut.APIcallPromise(
                `/invoices/${transaction.invoice.id}/capture`,
                'POST',
                {},
            ).then(function(response) {
                if (response.data.success) {
                    dispatch(loadTransactionDetails(transaction.id));
                }
            }),
        };
    };
}

export function voidTransaction(transaction) {
    return function(dispatch) {
        return {
            type: 'CAPTURE_TRANSACTION',
            payload: ProcessOut.APIcallPromise(
                `/invoices/${transaction.invoice.id}/void`,
                'POST',
                {},
            ).then(function(response) {
                if (response.data.success) {
                    dispatch(loadTransactionDetails(transaction.id));
                }
            }),
        };
    };
}
