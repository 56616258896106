import React from 'react';
import { ReactIntl } from 'react-intl';
import PropTypes from 'prop-types';

var ReactMarkdown = require('react-markdown');

export default class Insctruction extends React.Component {
    static propTypes = {
        instruction: PropTypes.object.isRequired,
        showInputs: PropTypes.bool,
        showTexts: PropTypes.bool,
    };

    constructor() {
        super();
    }

    componentDidUpdate(prevProps, prevState) {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    render() {
        var instruction = this.props.instruction;
        instruction.text = instruction.text.replace('%%PROJECTID%%', this.props.projectID);
        instruction.text = instruction.text.replace(
            '%%GATEWAYCONFIGURATIONID%%',
            this.props.confID,
        );

        return (
            <div class='row'>
                <div class='large-12 columns'>
                    {this.props.showTexts === false ? null : (
                        <div class='row'>
                            <div class='large-12 columns'>
                                <h4>{instruction.title}</h4>
                            </div>
                        </div>
                    )}
                    {this.props.showTexts === false ? null : (
                        <ReactMarkdown source={instruction.text} />
                    )}
                    {instruction.fields.map(
                        function(field) {
                            if (this.props.showInputs === false) return null;

                            var placeholder;
                            for (var i = this.props.configs.length - 1; i >= 0; i--) {
                                if (this.props.configs[i].key == field)
                                    placeholder = this.props.configs[i].value;
                            }
                            return (
                                <div class='row' key={field}>
                                    <div class='large-12 columns'>
                                        <input
                                            type='text'
                                            name={field}
                                            placeholder={placeholder}
                                            class='bottom-border processor-input'
                                            required
                                        />
                                    </div>
                                </div>
                            );
                        }.bind(this),
                    )}
                </div>
            </div>
        );
    }
}
