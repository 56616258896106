// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../../../util/Types';
import type { $Board, $BoardDisplay, $DataPoint, $FetchParams } from '../consts';
import { REQUEST_BOARD_EDITION } from '../consts';
import ReactToolTip from 'react-tooltip';
import { Link } from 'react-router';
import Empty from '../../../../components/Empty';
import { REQUEST_BOARD_DUPLICATION } from './consts';
import ComparableSelector from './ComparableSelector';
import getColor from '../charts/colors';
import PlottedField from '../../ChartPreviewer/PlottedField/PlottedField';
import ModalFooter from '../../../../components/ModalFooter';
import moment from 'moment';
import { hasFeature } from '../../../FeatureFlags/FeatureFlags';
import BoardGrid from '../../BoardGrid/BoardGrid';

type Props = {
    board: $Board,
    boardDisplay: $BoardDisplay,
    fetchParams: $FetchParams,
} & $Dispatcher;

type State = {
    keysMask: Array<string>,
};

class Board extends React.Component {
    props: Props;
    state: State;

    constructor() {
        super();
        this.state = {
            keysMask: [],
        };
    }

    updateKeysMask = (newSelected: Array<string>) => {
        const keysMask = this.props.boardDetails.selector_datapoints
            .filter(point => !newSelected.includes(point.key))
            .map(point => point.key);
        this.setState({ keysMask: keysMask });
    };

    changeComparisonField = (newValue: string) => {
        const { boardDetails, dispatch } = this.props;
        if (newValue === boardDetails.board.comparison_selector.plotted_field) return;

        const newBoard = {
            ...boardDetails.board,
            comparison_selector: {
                ...boardDetails.board.comparison_selector,
                plotted_field: newValue,
            },
        };
        delete newBoard.charts;
        dispatch({
            type: REQUEST_BOARD_EDITION,
            payload: {
                newBoard: newBoard,
                remount: true,
            },
        });
    };

    switchToNormal = () => {
        const { boardDetails, dispatch } = this.props;

        const newBoard = {
            ...boardDetails.board,
            comparison_selector: null,
        };
        delete newBoard.charts;
        dispatch({
            type: REQUEST_BOARD_EDITION,
            payload: {
                newBoard: newBoard,
                remount: true,
            },
        });
    };

    setToCompare = () => {
        const { boardDetails, dispatch } = this.props;
        const newBoard: $Board = {
            ...boardDetails.board,
            comparison_selector: {
                formula: 'count{path: "transactions.captured_amount_local";default: 0;}',
                plotted_field: 'transactions.card_scheme',
            },
        };
        delete newBoard.charts;
        if (boardDetails.board.id.includes('board_default')) {
            this.refs['duplicate-modal'].getWrappedInstance().open(newBoard);
        } else {
            dispatch({
                type: REQUEST_BOARD_EDITION,
                payload: {
                    newBoard: newBoard,
                    remount: true,
                },
            });
        }
    };

    render() {
        const { params, boardDetails } = this.props;
        const { board } = boardDetails;
        if (boardDetails.fetching || !boardDetails.fetched) return null;
        const colors = boardDetails.selector_datapoints
            ? boardDetails.selector_datapoints.map((point: $DataPoint, index, array) => {
                  return {
                      key: point.key,
                      color: getColor(index),
                  };
              })
            : [{ key: 'single', color: getColor(0) }];
        const newChartButton = (
            <div class='row' style={{ marginTop: '2em' }}>
                <div class='large-12 columns text-center'>
                    <div
                        style={{
                            height: '240px',
                            paddingTop: '95px',
                            border: '3px dashed rgba(81, 99, 149, 1)',
                            borderRadius: '4px',
                            opacity: 0.7,
                        }}
                    >
                        <Link
                            to={`/projects/${this.props.params.project}/boards/${
                                this.props.params.board
                            }/new-chart`}
                            class='round border button'
                        >
                            <div>New chart</div>
                        </Link>
                    </div>
                </div>
            </div>
        );

        const empty =
            boardDetails.board.charts.length === 0 ? (
                <Empty
                    text={
                        <div>
                            This board doesn't contain any chart. Go to the{' '}
                            <Link to={`/projects/${params.project}/boards/${board.id}/new-chart`}>
                                chart builder page
                            </Link>{' '}
                            to add one.
                        </div>
                    }
                />
            ) : null;

        return (
            <div class='row'>
                <div class='large-12 columns' style={{ paddingRight: '0.9375rem' }}>
                    <ReactToolTip effect='solid' />
                    <div class='row'>
                        <div class='large-12 columns text-center'>
                            {this.props.fetchParams.timeCompare.comparing ? (
                                <div class='row margin-bottom'>
                                    <div class='large-12 columns text-center'>
                                        <div class='row'>
                                            <div class='large-6 columns text-right'>
                                                <div
                                                    style={{
                                                        width: '3em',
                                                        height: '1em',
                                                        marginRight: '.5em',
                                                        backgroundColor: `${getColor(0)}8C`,
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    {moment
                                                        .unix(
                                                            this.props.fetchParams.timeCompare.from,
                                                        )
                                                        .calendar()}{' '}
                                                    →{' '}
                                                    {moment
                                                        .unix(this.props.fetchParams.timeCompare.to)
                                                        .calendar()}
                                                </div>
                                            </div>
                                            <div class='large-6 columns text-left'>
                                                <div
                                                    style={{
                                                        width: '3em',
                                                        height: '1em',
                                                        marginRight: '.5em',
                                                        backgroundColor: getColor(0),
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    {moment
                                                        .unix(this.props.fetchParams.interval.from)
                                                        .calendar()}{' '}
                                                    →{' '}
                                                    {moment
                                                        .unix(this.props.fetchParams.interval.to)
                                                        .calendar()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : boardDetails.board.comparison_selector &&
                              this.props.currentProject.fetched &&
                              hasFeature(
                                  this.props.currentProject.project.feature_flags,
                                  'analytics-v2',
                              ) &&
                              !boardDetails.editing ? (
                                <div class='row margin-bottom'>
                                    <div class='large-12 columns'>
                                        <div class='row'>
                                            <div class='large-12 columns text-left'>
                                                <div class='row small-margin-bottom'>
                                                    <div
                                                        class='large-5 columns'
                                                        style={{
                                                            paddingTop: '2em',
                                                        }}
                                                    >
                                                        <h5
                                                            style={{
                                                                display: 'inline-block',
                                                            }}
                                                        >
                                                            Filter values
                                                        </h5>
                                                    </div>
                                                    <div class='large-7 columns text-right'>
                                                        <div class='row'>
                                                            <div class='large-6 columns text-left'>
                                                                <label class='greyed'>
                                                                    Comparing using
                                                                </label>
                                                            </div>
                                                            <div class='large-6 columns text-right small-font'>
                                                                <a onClick={this.switchToNormal}>
                                                                    Cancel comparison
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class='row'>
                                                            <div class='large-12 columns text-left'>
                                                                <PlottedField
                                                                    defaultValue={
                                                                        boardDetails.board
                                                                            .comparison_selector
                                                                            .plotted_field
                                                                    }
                                                                    shouldDisplayApplyButton={true}
                                                                    allowMetadata={true}
                                                                    rtl={false}
                                                                    suffix={'transactions.'}
                                                                    onChange={
                                                                        this.changeComparisonField
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class='row'>
                                                    <div class='large-12 columns'>
                                                        <ComparableSelector
                                                            points={
                                                                boardDetails.selector_datapoints
                                                            }
                                                            colors={colors}
                                                            onChange={this.updateKeysMask}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : boardDetails.board.id !== 'board_default-customers' &&
                              this.props.currentProject.fetched &&
                              !this.props.boardDetails.editing &&
                              hasFeature(
                                  this.props.currentProject.project.feature_flags,
                                  'analytics-v2',
                              ) ? (
                                <div class='row'>
                                    <div class='large-12 columns text-right'>
                                        <a
                                            class='round main button white'
                                            onClick={this.setToCompare}
                                        >
                                            <img
                                                src='//dashboard.processout.com/images/compare.png'
                                                style={{
                                                    height: '20px',
                                                    marginRight: '.5em',
                                                    display: 'inline-block',
                                                }}
                                            />
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    top: '.2em',
                                                    display: 'inline-block',
                                                    color: '#00c853',
                                                }}
                                            >
                                                Start comparison
                                            </div>
                                            <DuplicateModalConfirm
                                                ref='duplicate-modal'
                                                board={this.props.boardDetails.board}
                                            />
                                        </a>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {empty}
                    <BoardGrid
                        params={{ project: this.props.params.project }}
                        keysMask={this.state.keysMask}
                    />
                    {!this.props.boardDetails.editing ||
                    !this.props.currentProject.project ||
                    !hasFeature(this.props.currentProject.project.feature_flags, 'analytics-v2') ||
                    this.props.boardDetails.board.id.includes('board_default') ? null : (
                        <div class='row'>
                            <div class='large-6 columns end'>{newChartButton}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect((store, props) => {
    return {
        boardDetails: store.analytics_v2.boardDetails,
        fetchParams: store.analytics.params,
        currentProject: store.currentProject,
        charts: store.charts,
    };
})(Board);

class DuplicateModal extends React.Component {
    props: {
        board: $Board,
    } & $Dispatcher;

    state: {
        newBoard: ?$Board,
    };

    constructor() {
        super();
        this.state = {
            newBoard: null,
        };
    }

    duplicate = () => {
        const { newBoard } = this.state;
        if (!newBoard) return;
        newBoard.duplicate_from = this.props.board.id;
        if (newBoard.comparison_selector && newBoard.comparison_selector.plotted_field) {
            newBoard.name = `${
                newBoard.name
            } per ${newBoard.comparison_selector.plotted_field.replace('transactions.', '')}`;
        }
        const { dispatch, currentProject } = this.props;
        dispatch({
            type: REQUEST_BOARD_DUPLICATION,
            payload: {
                newBoard: newBoard,
                projectId: currentProject.project.id,
            },
        });
        dispatch({ type: 'CLOSE_MODAL' });
    };

    open = (newBoard: $Board) => {
        this.setState({ newBoard: newBoard });
        const { dispatch } = this.props;
        dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: 'Duplicate board',
                content: (
                    <div class='row'>
                        <div class='large-10 columns large-centered'>
                            {this.props.board.name} is a default board. By continuing this board
                            will be duplicated to allow you to edit it.
                        </div>
                    </div>
                ),
                footer: <ModalFooter submitTitle='Duplicate' submitCallback={this.duplicate} />,
            },
        });
    };

    render() {
        return null;
    }
}

const DuplicateModalConfirm = connect(
    store => {
        return {
            currentProject: store.currentProject,
        };
    },
    null,
    null,
    { withRef: true },
)(DuplicateModal);
