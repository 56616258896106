import React from 'react';
import * as UserActions from '../Actions/UserActions';
import Loader from '../components/Loader';
import * as ProcessOut from '../util/ProcessOut';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

var md5 = require('js-md5');
var serialize = require('form-serialize');

@connect(function(store) {
    return {
        twoFactor: store.user.two_factor,
    };
})
export default class UserUpdate extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
    };

    componentDidMount() {
        document.title = 'Settings | ProcessOut';
        this.props.dispatch(UserActions.getTwoFactorData());
    }

    constructor() {
        super();

        this.state = {
            confirm_email: false,
            confirm_password: false,
        };
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    passwordTyped() {
        var passwordField = document.getElementById('password').value;
        this.setState({
            confirm_password: passwordField != '',
        });
    }

    emailTyped() {
        var emailField = document.getElementById('email').value;
        this.setState({
            confirm_email: emailField != '',
        });
    }

    submit(event) {
        event.preventDefault();
        var form = document.querySelector('#update-form');
        var data = serialize(form, { hash: true });
        var check = true;
        if (data.password && data.password != data.password_confirmation) {
            ProcessOut.addNotification.bind(this)('Passwords are differents', 'error');
            check = false;
        }
        if (data.email && data.email != data.email_confirmation) {
            ProcessOut.addNotification.bind(this)('Emails are differents', 'error');
            check = false;
        }

        if (data.email == '') data.email = this.props.user.details.email;
        if (data.name == '') data.name = this.props.user.details.name;
        if (data.password == null) {
            data.password = data.old_password;
            data.password_confirmation = data.old_password;
        }

        if (check) {
            var json = JSON.stringify(data);
            UserActions.updateUser(json);
        }
    }

    twoFactorSubmit(event) {
        event.preventDefault();
        var data = serialize(event.target, { hash: true });

        if (this.props.twoFactor.data.enabled) {
            this.props.dispatch(UserActions.disableTwoFactor(data.two_factor_token));
        } else {
            this.props.dispatch(UserActions.enableTwoFactor(data.two_factor_token));
        }
    }

    render() {
        const user = this.props.user;
        var password;

        if (this.state.confirm_password) {
            password = (
                <div class='row'>
                    <div class='large-12 columns'>
                        <input
                            name='password_confirmation'
                            type='password'
                            placeholder='Confirm password'
                            class='bottom-border'
                            required
                        />
                    </div>
                </div>
            );
        }

        var email = (
            <div class='row'>
                <div class='large-12 columns'>
                    <input
                        type='text'
                        name='email_confirmation'
                        placeholder='Confirm new email'
                        class='bottom-border'
                    />
                </div>
            </div>
        );

        var twoFactor = <Loader />;
        if (this.props.twoFactor.fetched) {
            if (this.props.twoFactor.data.enabled) {
                twoFactor = (
                    <form onSubmit={this.twoFactorSubmit.bind(this)} id='two-factor-form'>
                        <div class='row'>
                            <div class='large-12 columns'>
                                <p>
                                    Two factor authentication is currently enabled on your account.
                                    We strongly advise you not to disable it.
                                </p>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-9 columns'>
                                <input
                                    type='password'
                                    placeholder='Two factor code'
                                    name='two_factor_token'
                                />
                            </div>
                            <div class='large-3 columns'>
                                <input
                                    type='submit'
                                    value='Disable'
                                    class='button small round border red right expand'
                                />
                            </div>
                        </div>
                    </form>
                );
            } else {
                twoFactor = (
                    <form onSubmit={this.twoFactorSubmit.bind(this)} id='two-factor-form'>
                        <div class='row'>
                            <div class='large-4 medium-8 medium-centered large-uncentered columns margin-bottom'>
                                <img
                                    style={{
                                        marginTop: '5px',
                                    }}
                                    src={
                                        this.props.twoFactor.data.qr_code
                                            ? this.props.twoFactor.data.qr_code
                                            : ''
                                    }
                                />
                            </div>
                            <div class='large-8 columns'>
                                <p
                                    style={{
                                        textAlign: 'justify',
                                    }}
                                >
                                    Two-step verification prevents access to your account except for
                                    yourself by adding a second layer to the authentication. All you
                                    need to do is input the code provided in your favorite
                                    authentication application (ex: Google Authenticator) on your
                                    smartphone while logging in! Enabling this feature is strongly
                                    recommended.
                                </p>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-12 columns'>
                                <p>
                                    Please scan the QR code in your application on your smartphone
                                    and input the given code.
                                </p>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='medium-9 columns'>
                                <input
                                    type='number'
                                    placeholder='Two factor code'
                                    name='two_factor_token'
                                />
                            </div>
                            <div class='medium-3 columns'>
                                <input
                                    type='submit'
                                    value='Enable'
                                    class='button small round border right expand'
                                />
                            </div>
                        </div>
                    </form>
                );
            }
        }

        return (
            <div class='row'>
                <div class='large-7 medium-8 small-centered columns'>
                    <form id='update-form' onSubmit={this.submit.bind(this)}>
                        <div class='row'>
                            <div class='large-3 medium-4 columns'>
                                <h5>Update information</h5>
                            </div>
                            <div class='large-9 medium-8 columns'>
                                <div class='row'>
                                    <div class='large-12 columns'>
                                        <input
                                            type='email'
                                            name='email'
                                            id='email'
                                            onChange={this.emailTyped.bind(this)}
                                            name='email'
                                            placeholder='New email (optional)'
                                            class='bottom-border'
                                        />
                                    </div>
                                </div>
                                {email}
                            </div>
                            <div class='large-9 medium-8 columns'>
                                <div class='row'>
                                    <div class='large-12 columns'>
                                        <input
                                            name='password'
                                            name='password'
                                            id='password'
                                            onChange={this.passwordTyped.bind(this)}
                                            type='password'
                                            placeholder='New password (optional)'
                                            class='bottom-border'
                                        />
                                    </div>
                                </div>
                                {password}
                            </div>
                        </div>

                        <hr />

                        <div class='row'>
                            <div class='large-3 medium-4 columns'>
                                <h5>Confirmation</h5>
                            </div>
                            <div class='large-9 medium-8 columns'>
                                <div class='row'>
                                    <div class='large-12 columns'>
                                        <input
                                            name='old_password'
                                            type='password'
                                            placeholder='Current password'
                                            class='bottom-border'
                                            required
                                        />
                                    </div>
                                </div>
                                <div class='row'>
                                    <div class='large-12 columns clearfix'>
                                        <input
                                            type='submit'
                                            class='button small round border'
                                            value='Update'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <hr />

                    <div class='row'>
                        <div class='large-3 medium-4 columns'>
                            <h5>Two factor authentication</h5>
                        </div>
                        <div class='large-9 medium-8 columns'>{twoFactor}</div>
                    </div>
                </div>
            </div>
        );
    }
}
