// @flow
/**
 * Created by jeremylejoux on 07/06/17.
 */

import React from 'react';
import { connect } from 'react-redux';

export class ApplePaySettings extends React.Component {
    props: Props;

    static defaultProps = {
        dispatch: null,
    };

    componentDidMount() {
        document.title = 'Settings - Apple Pay | ProcessOut';
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    render() {
        let { children } = this.props;

        return <div>{children}</div>;
    }
}

type Props = {
    children: Array<any>,
    dispatch: any | (() => void),
};

export default connect(function(store) {
    return {};
})(ApplePaySettings);
