import { combineReducers } from 'redux';
import Details from './DetailsReducer';
import Tokens from './TokensReducer';
import Transactions from './TransactionsReducer';

const dataReducer = combineReducers({
    details: Details,
    transactions: Transactions,
    tokens: Tokens,
});

export default dataReducer;
