// @flow
import React from 'react';
import { connect } from 'react-redux';
import { INTERCOM_APP_ID } from './consts';
import type { User } from '../../Types/User';

export class Intercom extends React.Component {
    props: Props;

    componentDidMount() {
        const { user } = this.props;
        window.Intercom('boot', {
            app_id: INTERCOM_APP_ID,
            ...user.details,
            hide_default_launcher: true,
        });
    }

    componentWillReceiveProps(nextProps: Props) {
        const { user } = this.props;
        const nextUser = nextProps.user;

        if (nextUser.details.email !== user.details.email)
            window.Intercom('update', {
                app_id: INTERCOM_APP_ID,
                ...nextUser.details,
            });
    }

    componentWillUnmount() {
        window.Intercom('shutdown');
    }

    render() {
        return null;
    }
}

type Props = {
    user: {
        details: User,
        auth: { loggedIn: boolean },
    },
};

export default connect(store => {
    return {
        intercom: store.intercom,
        gatewaysConfigurations: store.processorsConfigurations,
        user: store.user,
        currentProject: store.currentProject,
    };
})(Intercom);
