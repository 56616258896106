import React from 'react';
import { connect } from 'react-redux';
import Metadata from './Metadata';
import CurrencyField from './CurrencyField';
import * as CouponsActions from '../Actions/CouponsActions';
import PropTypes from 'prop-types';

var serialize = require('form-serialize');

@connect(
    function(store) {
        return {
            currenciesDetails: store.currencies,
        };
    },
    null,
    null,
    { withRef: true },
)
export default class CouponModal extends React.Component {
    static propTypes = {
        modalName: PropTypes.string.isRequired,
        coupon: PropTypes.object,
    };

    created() {
        this.props.dispatch({ type: 'CLOSE_MODAL' });
    }

    save(event) {
        event.preventDefault();
        var couponForm = document.querySelector('#coupon-form');
        var data = serialize(couponForm, { hash: true });

        data.percent_off = parseInt(data.percent_off);
        data.iteration_count = parseInt(data.iteration_count);
        data.max_redemptions = parseInt(data.max_redemptions);

        if (!data.metadata) data.metadata = {};

        var metas = this._meta.updateValue();
        for (var i = 0; i < metas.length; i++) {
            data.metadata[metas[i].key] = metas[i].value;
        }

        if (data.metadata && Object.keys(data.metadata).length == 0) {
            delete data.metadata;
        }

        if (!this.props.coupon) {
            this.props.dispatch(CouponsActions.createCoupon(data, this.created.bind(this)));
        } else {
            this.props.dispatch(
                CouponsActions.editCoupon(data, this.props.coupon.id, this.created.bind(this)),
            );
        }
    }

    deleteCoupon() {
        if (confirm('Are you sure you want to delete this coupon?')) {
            this.props.dispatch(CouponsActions.deleteCoupon(this.props.coupon.id));
            this.props.dispatch({ type: 'CLOSE_MODAL' });
        }
    }

    openModal() {
        var coupon = this.props.coupon;
        var name = this.props.modalName;
        var couponUrl;

        var deleteButton;
        if (coupon) {
            deleteButton = (
                <a
                    onClick={this.deleteCoupon.bind(this)}
                    class='round border small button'
                    style={{ opacity: 0.5 }}
                >
                    Delete
                </a>
            );
        }

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: name,
                content: (
                    <div class='row'>
                        <div class='large-12 columns'>
                            <form id='coupon-form' onSubmit={this.save.bind(this)}>
                                <div class='row small-margin-bottom'>
                                    <div
                                        class='medium-12 medium-centered columns'
                                        style={{
                                            padding: '25px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Details
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <div class='row'>
                                                    <div class='large-6 columns'>
                                                        <label class='greyed'>Identifier</label>
                                                        <input
                                                            type='text'
                                                            class='bottom-border'
                                                            name='id'
                                                            defaultValue={coupon ? coupon.id : ''}
                                                            placeholder='coupon-id'
                                                            disabled={coupon != null}
                                                            required
                                                        />
                                                    </div>
                                                    <div class='large-6 columns'>
                                                        <label class='greyed'>Percent off</label>
                                                        <input
                                                            type='text'
                                                            placeholder='0%'
                                                            name='percent_off'
                                                            defaultValue={
                                                                coupon ? coupon.percent_off : ''
                                                            }
                                                            class='bottom-border'
                                                            disabled={coupon != null}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div class='row small-margin-bottom'>
                                                    <div class='large-6 medium-6 columns'>
                                                        <label class='greyed'>Amount off</label>
                                                        <input
                                                            type='text'
                                                            placeholder='0.00'
                                                            name='amount_off'
                                                            defaultValue={
                                                                coupon ? coupon.amount_off : ''
                                                            }
                                                            disabled={coupon != null}
                                                            class='bottom-border'
                                                            required
                                                        />
                                                    </div>
                                                    <div class='large-6 medium-6 columns'>
                                                        <CurrencyField
                                                            defaultValue={
                                                                coupon ? coupon.currency : 'USD'
                                                            }
                                                            disabled={this.props.coupon != null}
                                                        />
                                                    </div>
                                                </div>
                                                <div class='row small-margin-bottom'>
                                                    <div class='large-6 medium-6 columns'>
                                                        <label class='greyed'>
                                                            Number of iterations
                                                        </label>
                                                        <input
                                                            type='number'
                                                            step='1'
                                                            placeholder='0 (unlimited)'
                                                            name='iteration_count'
                                                            defaultValue={
                                                                coupon ? coupon.iteration_count : ''
                                                            }
                                                            disabled={coupon != null}
                                                            class='bottom-border'
                                                            required
                                                        />
                                                    </div>
                                                    <div class='large-6 medium-6 columns'>
                                                        <label class='greyed'>
                                                            Max redemptions
                                                        </label>
                                                        <input
                                                            type='number'
                                                            step='1'
                                                            placeholder='0 (unlimited)'
                                                            name='max_redemptions'
                                                            defaultValue={
                                                                coupon ? coupon.max_redemptions : ''
                                                            }
                                                            disabled={coupon != null}
                                                            class='bottom-border'
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <hr />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Additional data
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <Metadata
                                                    resource={coupon}
                                                    ref={m => (this._meta = m)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ),
                footer: (
                    <div class='row'>
                        <div class='medium-4 columns'>{deleteButton}</div>
                        <div class='medium-8 columns text-right'>
                            <a
                                onClick={function(event) {
                                    event.preventDefault();
                                    this.props.dispatch({
                                        type: 'CLOSE_MODAL',
                                    });
                                }.bind(this)}
                                class='round border small button'
                            >
                                Cancel
                            </a>
                            <input
                                type='button'
                                onClick={this.save.bind(this)}
                                class='round small main button white-text'
                                value={this.props.coupon ? 'Save' : 'Create'}
                            />
                        </div>
                    </div>
                ),
            },
        });
    }

    render() {
        return null;
    }
}
