// @flow
import { FormattedNumber } from 'react-intl';
import type { $Recommendation } from './consts';
import React from 'react';

class Recommendation extends React.Component<{
    recommendation: $Recommendation,
    currency: string,
    onSelect: () => void,
}> {
    render() {
        const { recommendation } = this.props;
        return (
            <div class='row small-margin-bottom'>
                <div class={`large-12 columns recommendation`}>
                    <div class='row'>
                        <div class='large-12 columns row-title'>
                            <div class='row'>
                                <div class={`large-8 columns name`}>{recommendation.name}</div>
                                <div class='large-4 columns text-right'>
                                    <div class='learn-more' onClick={this.props.onSelect}>
                                        Click to learn more <div class='arrow'>→</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Recommendation;
