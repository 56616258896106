// @flow

import React from 'react';
import type { $Project } from '../../util/Types';

type Props = {
    project: $Project,
};

class ProjectRegionFlag extends React.Component<Props> {
    render() {
        const { project } = this.props;
        return (
            <div
                style={{
                    display: 'inline-block',
                    backgroundColor: '#D1C4E9',
                    color: 'rgba(81, 45, 168, .6)',
                    padding: '.2em .5em .2em .5em',
                    marginRight: '1em',
                    fontWeight: 'bold',
                    fontSize: '.65em',
                    borderRadius: '4px',
                    filter: 'grayscale(1)',
                }}
            >
                {project.region_name.includes('us-east-1') ? 'US' : 'EU'}
            </div>
        );
    }
}

export default ProjectRegionFlag;
