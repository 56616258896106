// @flow

import type { $ApplyPresetAction, $ChartPreviewAction } from './actions';
import * as DataExplorerActions from './actions';
import * as ChartBuilderActions from './ChartBuilder/actions';
import * as ChartsActions from './actions';
import {
    APPLY_DATA_EXPLORER_PRESET,
    REQUEST_CHART_FETCH,
    SAVE_CHART_ON_BOARD,
    SET_METRIC_TAB,
    UPDATE_DATA_EXPLORER_FORMULA,
    UPDATE_DATA_EXPLORER_TYPE,
    UPDATE_DATA_EXPLORER_UNIT,
    UPDATE_METRIC_FILTERS,
    UPDATE_METRIC_LOCAL_CURRENCY,
    UPDATE_METRIC_PATH,
    UPDATE_METRIC_TYPE,
} from './actions';
import uniqid from 'uniqid';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import * as ProcessOut from '../../../util/ProcessOut';
import { typeFailed, typeFulfilled, typePending } from '../../../util/ProcessOut';
import * as Actions from '../ChartPreviewer/actions';
import {
    replaceForCountries,
    replaceForCurrencies,
    replaceForGwayKeys,
} from '../Boards/charts/utils';
import type { $DataExplorerState } from './reducer';
import type { $ChartBuilderState } from './ChartBuilder/reducer';
import { formatFormula } from '../ChartPreviewer/BuilderEffects/actions';
import { BAR_CHART, LINE_CHART, SINGLE_VALUE, PIE_CHART } from '../Boards/consts';
import { UPDATE_CHART_BUILDER_DETAILS } from './consts';
import type { $Dimension } from './ChartBuilder/DimensionSelection/consts';
import { generateDefaultDimension } from './ChartBuilder/DimensionSelection/consts';
import type { $Action } from '../../../util/Types';
import { ON_NEW_BOARD } from './SavingModal';
import { requestBoardsFetch } from '../Boards/actions';
import * as Sentry from '@sentry/browser';
import {
    REQUEST_UPDATE_DIMENSIONS,
    SELECT_BUILDER_METRIC,
    SELECT_TYPE,
    SET_DISPLAY_LOCAL_CURRENCY,
    UPDATE_DIMENSIONS,
    UPDATE_FILTERS,
} from './ChartBuilder/actions';
import { computeFilterString, computeMergedFormula, mergeFilters } from './Utils';
import type { $SelectTypeAction, $UpdateDimensionsAction } from './ChartBuilder/actions';
import { SET_DISPLAY_NET_METRICS } from './ChartBuilder/actions';
import { MAP_CHART } from '../Boards/consts';

export const PREVIEW_CHART = 'PREVIEW_CHART';

function* fetchChartPreview(action: $ChartPreviewAction): Generator<*, *, *> {
    try {
        yield put({ type: typePending(PREVIEW_CHART) });
        const { name, formula, unit, type } = action.payload;
        const chart = {
            name: name,
            description: '',
            type: type,
            settings: {
                formula: formula,
                plotted_field: null,
            },
            size: 12,
            unit: unit,
            preview: true,
        };
        const chartResult = yield put.resolve(
            Actions.fetchPreviewedChart(chart, 'board_default-sales', action.payload.params),
        );
        const chartData = chartResult.value.data;
        // we fetch the gateway ids and names list from the store.
        let gway_configurations_names = yield select(store => store.gateway_configurations_names);
        // if we don't have them we wait for it
        while (!gway_configurations_names.fetched) {
            yield take();
            gway_configurations_names = yield select(store => store.gateway_configurations_names);
        }

        // we update the charts keys with gateway names
        chartData.data = replaceForGwayKeys(
            chartData.data,
            gway_configurations_names.gateway_configuration_names,
        );

        // do the same for country codes
        chartData.data = replaceForCountries(chartData.data);

        // same for currencies
        chartData.data = replaceForCurrencies(chartData.data);

        const payload = {
            chart: {
                ...chartResult.value.data.chart,
                fetched: true,
                data: chartData.data,
                is_comparison: chartResult.value.data.is_comparison,
            },
        };

        yield put({
            type: chartResult.value.data.success
                ? typeFulfilled(PREVIEW_CHART)
                : typeFailed(PREVIEW_CHART),
            payload: payload,
        });
    } catch (error) {
        yield put({ type: typeFailed(PREVIEW_CHART), payload: { error } });
    }
}

export const APPLY_PRESET_DATA_EXPLORER = 'APPLY_PRESET_DATA_EXPLORER';
export const APPLY_PRESET_CHART_BUILDER = 'APPLY_PRESET_CHART_BUILDER';
function* applyPreset(action: $ApplyPresetAction): Generator<*, *, *> {
    const { preset } = action.payload;
    yield put.resolve({ type: APPLY_PRESET_DATA_EXPLORER, payload: preset });
    yield put.resolve({
        type: APPLY_PRESET_CHART_BUILDER,
        payload: preset.chartBuilder,
    });
}

function* rebuildFormula(): Generator<*, *, *> {
    try {
        const chartBuilder: $ChartBuilderState = yield select(store => store.chartBuilder);
        const dataExplorer: $DataExplorerState = yield select(store => store.dataExplorer);

        // We make sure we are on the chart builder tab
        if (dataExplorer.selectedTab === 'editor') return;

        let formula = '';
        const { dimensions } = chartBuilder;
        // Deep copy metrics
        const duplicatedMetrics = chartBuilder.selectedMetric.metrics.map(m => ({ ...m }));
        for (const metric of duplicatedMetrics) {
            if (chartBuilder.displayNetMetrics) {
                // Should add duplicate_distance_seconds filters
                if (metric.filters.find(f => f.path === 'duplicate_distance_seconds')) continue;
                metric.filters = metric.filters.slice(0);
                metric.filters.push({
                    id: uniqid(),
                    path: 'duplicate_distance_seconds',
                    operand: 'is-null',
                    value: [],
                });
            }
        }
        if (dimensions.length < 1) {
            // we need to merge metrics filters with global filters
            for (const metric of duplicatedMetrics) {
                metric.filters = mergeFilters(metric.filters, chartBuilder.filters);
            }
        }

        const dataFormula = computeMergedFormula(
            chartBuilder,
            chartBuilder.selectedMetric.generalFormula,
            '',
            duplicatedMetrics,
        );

        for (let i = 0; i < dimensions.length; i++) {
            const strategy = dimensions[i].top
                ? `${dimensions[i].strategy ? `strategy: ${dimensions[i].strategy}` : ''};`
                : '';
            const top = dimensions[i].top ? `top: ${dimensions[i].top};` : '';
            const filterString = computeFilterString(chartBuilder.filters).replace(';', '');
            if (i === 0) {
                formula = `plot{path:${dimensions[i].field}; ${strategy} ${top} formula: ${
                    dimensions.length === 1 ? `count{path: transactions; ${filterString}};` : ''
                } ${dataFormula || ''}};`;
            } else {
                formula = `plot{formula: count{path: transactions; ${filterString}};path: ${
                    dimensions[i].field
                }; ${strategy} ${top} ${formula}}`;
            }
        }
        if (dimensions.length === 0) {
            // no dimensions: this is a single value
            formula = dataFormula;
        }
        const formattedFormula = formatFormula(formula);
        yield put(DataExplorerActions.updateFormula(formattedFormula));
        return formattedFormula;
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
    }
}

function* metricTabWatcher(action: $Action): Generator<*, *, *> {
    const { payload } = action;
    if (!payload) return;
    if (payload.tab === 'authorization' || payload.tab === 'fees') {
        yield put({
            type: UPDATE_CHART_BUILDER_DETAILS,
            payload: {
                unit: 'percentage',
            },
        });
    }
}

function* formulaWatcher(action: $Action): Generator<*, *, *> {
    const { payload } = action;
    if (!payload) return;
    const dataExplorer: $DataExplorerState = yield select(store => store.dataExplorer);
    const analytics = yield select(store => store.analytics);
    if (dataExplorer.selectedTab === 'presets') {
        yield put(
            ChartsActions.requestChartFetch(
                payload.formula,
                dataExplorer.name || 'Preview',
                dataExplorer.type,
                dataExplorer.unit,
                analytics.params,
            ),
        );
    }
}

function* saveChart(action: $Action): Generator<*, *, *> {
    try {
        const { payload } = action;
        if (!payload) return;
        const dataExplorer: $DataExplorerState = yield select(store => store.dataExplorer);
        const analytics = yield select(store => store.analytics);
        const currentProject = yield select(store => store.currentProject);
        let { boardId } = payload;
        if (boardId === ON_NEW_BOARD) {
            // we need to create a board
            const boardResult = yield call(ProcessOut.APIcallPromise, '/boards', 'POST', {
                name: 'New board',
            });
            const board = boardResult.data.board;
            yield put.resolve(requestBoardsFetch(board.id, true));
            boardId = board.id;
        }
        if (dataExplorer.editingChartId) {
            // we're editing a chart
            const charts = yield select(store => store.charts);
            const chart =
                charts[Object.keys(charts).find(id => id === dataExplorer.editingChartId)];
            yield put.resolve(
                Actions.requestChartSave(
                    chart.id,
                    currentProject.project.id,
                    dataExplorer.name,
                    chart.description,
                    dataExplorer.formula,
                    dataExplorer.type,
                    dataExplorer.unit,
                    chart.size,
                    boardId,
                    '',
                    analytics.params,
                    { x: chart.position_x, y: chart.position_y },
                    chart.height,
                ),
            );
        } else {
            // We're creating a new chart
            yield put.resolve(
                Actions.requestChartSave(
                    null,
                    currentProject.project.id,
                    dataExplorer.name,
                    '',
                    dataExplorer.formula,
                    dataExplorer.type,
                    dataExplorer.unit,
                    12,
                    boardId,
                    '',
                    analytics.params,
                    null,
                    dataExplorer.type === MAP_CHART
                        ? 12
                        : dataExplorer.type === SINGLE_VALUE
                        ? 3
                        : 6,
                ),
            );
        }
    } catch (error) {
        ProcessOut.addNotification('An error occurred while saving your chart.', 'error');
        Sentry.captureException(error);
    }
}

function* rebuildFormulaWatcher(action: $Action): Generator<*, *, *> {
    try {
        const dataExplorer: $DataExplorerState = yield select(store => store.dataExplorer);
        if (dataExplorer.selectedTab !== 'editor') yield call(rebuildFormula, action);
    } catch (error) {
        Sentry.captureException(error);
    }
}

function* setupChartEdition(action: $Action): Generator<*, *, *> {
    try {
        const { payload } = action;
        if (!payload) throw new Error('missing payload');
        const charts = yield select(store => store.charts);
        const analytics = yield select(store => store.analytics);
        const chart = charts[Object.keys(charts).find(id => id === payload.chartId)];
        yield put({
            type: UPDATE_CHART_BUILDER_DETAILS,
            payload: {
                type: chart.type,
                unit: chart.unit,
                name: chart.name,
                size: chart.size,
            },
        });
    } catch (error) {
        Sentry.captureException(error);
    }
}

function* rebuildFormulaAndFetchWatcher(action: $Action): Generator<*, *, *> {
    try {
        const dataExplorer: $DataExplorerState = yield select(store => store.dataExplorer);
        const chartBuilder: $ChartBuilderState = yield select(store => store.chartBuilder);

        // We make sure we are on the chart builder tab
        if (dataExplorer.selectedTab === 'editor') return;

        const analytics = yield select(store => store.analytics);
        const newFormula = yield call(rebuildFormula, action);
        // Retrieve newly updated formula
        yield put(
            DataExplorerActions.requestChartFetch(
                newFormula,
                dataExplorer.name || chartBuilder.selectedMetric.name,
                dataExplorer.type,
                dataExplorer.unit,
                analytics.params,
            ),
        );
    } catch (error) {
        Sentry.captureException(error);
    }
}

function* dimensionsUpdateWatcher(action: $UpdateDimensionsAction): Generator<*, *, *> {
    const chartBuilder: $ChartBuilderState = yield select(store => store.chartBuilder);
    const dataExplorer: $DataExplorerState = yield select(store => store.dataExplorer);

    yield put({ type: UPDATE_DIMENSIONS, payload: action.payload });
    if (action.payload.dimensions.length === 0) return;
    else if (!new RegExp(/.*_at$/).test(action.payload.dimensions[0].field)) {
        if (dataExplorer.type !== 'pie-chart')
            yield put(ChartBuilderActions.selectType('bar-chart', dataExplorer.type));
    } else {
        yield put(ChartBuilderActions.selectType('line-chart', dataExplorer.type));
    }
}

function* selectTypeWatcher(action: $SelectTypeAction): Generator<*, *, *> {
    const chartBuilder: $ChartBuilderState = yield select(store => store.chartBuilder);
    const { type, oldType } = action.payload;

    if (type === SINGLE_VALUE) {
        yield put({ type: UPDATE_DIMENSIONS, payload: { dimensions: [] } });
    } else if (oldType === SINGLE_VALUE) {
        if (type === PIE_CHART) {
            yield put({
                type: UPDATE_DIMENSIONS,
                payload: {
                    dimensions: [
                        {
                            ...generateDefaultDimension(),
                            field: 'transactions.card_scheme',
                            top: 10,
                            strategy: 'value_descending',
                        },
                    ],
                },
            });
        } else {
            yield put({
                type: UPDATE_DIMENSIONS,
                payload: { dimensions: [generateDefaultDimension()] },
            });
        }
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeLatest(REQUEST_CHART_FETCH, fetchChartPreview);
    yield takeLatest(APPLY_DATA_EXPLORER_PRESET, applyPreset);
    yield takeLatest(
        [
            UPDATE_METRIC_FILTERS,
            UPDATE_METRIC_LOCAL_CURRENCY,
            UPDATE_METRIC_PATH,
            UPDATE_METRIC_TYPE,
            UPDATE_DATA_EXPLORER_UNIT,
            UPDATE_DATA_EXPLORER_TYPE,
            SET_METRIC_TAB,
            UPDATE_CHART_BUILDER_DETAILS,
            APPLY_PRESET_CHART_BUILDER,
            APPLY_PRESET_DATA_EXPLORER,
            UPDATE_FILTERS,
        ],
        rebuildFormulaWatcher,
    );
    yield takeLatest(
        [
            SELECT_BUILDER_METRIC,
            SET_DISPLAY_LOCAL_CURRENCY,
            SELECT_TYPE,
            UPDATE_DIMENSIONS,
            SET_DISPLAY_NET_METRICS,
        ],
        rebuildFormulaAndFetchWatcher,
    );
    yield takeLatest(UPDATE_DATA_EXPLORER_FORMULA, formulaWatcher);
    yield takeLatest(SET_METRIC_TAB, metricTabWatcher);
    yield takeLatest(SAVE_CHART_ON_BOARD, saveChart);
    yield takeLatest(REQUEST_UPDATE_DIMENSIONS, dimensionsUpdateWatcher);
    yield takeLatest(SELECT_TYPE, selectTypeWatcher);
}
