// @flow
import React from 'react';
import type { $Dispatcher } from '../../util/Types';
import type { $Webhook } from './reducer';
import * as Actions from './actions';
import { connect } from 'react-redux';

type Props = { hook: $Webhook } & $Dispatcher;

class Hook extends React.Component<Props> {
    deleteHook = () => {
        const { dispatch, hook } = this.props;
        if (confirm('Are you sure you want to remove this webhook endpoint?')) {
            dispatch(Actions.deleteWebhook(hook));
        }
    };

    render() {
        const hook = this.props.hook;

        return (
            <div class='row small-margin-bottom'>
                <div class='large-12 columns'>
                    <a
                        style={{ marginRight: '1.2em', opacity: '0.7' }}
                        onClick={this.deleteHook.bind(this)}
                    >
                        <img
                            src='//dashboard.processout.com/images/delete.svg'
                            style={{ height: '1em' }}
                        />
                    </a>
                    <a href={hook.url}>{hook.url}</a>
                </div>
            </div>
        );
    }
}

export default connect()(Hook);
