import { combineReducers } from 'redux';

var processoutJS = function(state = { loaded: false }, action) {
    switch (action.type) {
        case 'PROCESSOUT_JS_LOADED': {
            return {
                ...state,
                loaded: true,
            };
        }
    }

    return state;
};

export default combineReducers({
    processout: processoutJS,
});
