// @flow

import type { $Action } from '../../util/Types';
import {
    REQUEST_HTTP_CALL,
    UPDATE_TOKENS,
    NETWORK_VALIDATION_ERROR,
    NETWORK_REQUEST_FAILURE,
    NETWORK_BAD_REQUEST,
} from './consts';
import { takeEvery, select, put, call, take } from 'redux-saga/effects';
import * as Actions from './actions';
import moment from 'moment';
import * as ProcessOut from '../../util/ProcessOut';
import Base64 from 'base-64';
import LocalStorage from 'local-storage';
import * as Sentry from '@sentry/browser';
import Auth from '../../util/Auth';

type $HTTPPayload = {
    disableStatusParsing: ?boolean,
    disableErrorDisplay: ?boolean,
    url: string,
    request: any,
    resolve: () => any,
    reject: () => any,
};

/**
 * Make the call to refresh tokens from a valid refreshToken
 * @param refreshToken
 */
export function* refreshToken(refreshToken: string): Generator<*, *, *> {
    try {
        yield put({ type: 'REFRESHING TOKENS' });
        const projectId = yield select(store => store.projects.current_project_id);
        const headers = {
            Accept: 'application/json',
            'X-ProcessOut-Dashboard-Version': process.env.VERSION,
            'Content-Type': 'application/json',
            'API-Version': ProcessOut.APIVersion,
            'Disable-Logging': 'true',
            Authorization: 'Basic ' + Base64.encode(projectId + ':' + refreshToken),
        };
        const forceRegion = LocalStorage('force-region');
        if (forceRegion) {
            headers['X-ProcessOut-region'] = forceRegion;
        }
        const req = {
            method: 'POST',
            timeout: 30000,
            headers: headers,
            data: JSON.stringify({ token: refreshToken }),
        };
        const tokens = yield Actions.HTTPCall('/authenticate/refresh', req);
        LocalStorage('token', tokens.data.token.token || '');
        LocalStorage('token-expiry', tokens.data.token.expires_at || '');
        LocalStorage('refresh-token', tokens.data.token.refresh_token || '');
        LocalStorage('refresh-token-expiry', tokens.data.token.refresh_token_expires_at || '');
        yield put({ type: UPDATE_TOKENS, payload: tokens.data });
        return tokens.data;
    } catch (error) {
        // refreshing the token might be a specific case as it can happen while the computer is asleep
        if (error.type) {
            switch (error.type) {
                case NETWORK_BAD_REQUEST:
                case NETWORK_REQUEST_FAILURE: {
                    // In that case it will most likely fail because of NO_NETWORK which we want to retry
                    return null;
                }
                case NETWORK_VALIDATION_ERROR: {
                    // The API responded with > 299
                    Auth.logout();
                    return null;
                }
                default: {
                    return null;
                }
            }
        }
        Sentry.captureException(error);
    }
}

/**
 * Make the correct call when the request handler requests it
 * @param payload
 * @param newToken
 */
export function* makeHTTPCall(payload: $HTTPPayload, newToken?: string): Generator<*, *, *> {
    let headers = payload.request.headers;
    if (!headers || newToken) {
        const authDetails = yield select(store => store.user.auth);
        const projectId = yield select(store => store.projects.current_project_id);
        if (!headers) headers = ProcessOut.generateHeaders();
        headers['Authorization'] =
            'Basic ' + Base64.encode(projectId + ':' + (newToken || authDetails.token.token));
    }

    if (headers['X-ProcessOut-region'] === 'auto') {
        delete headers['X-ProcessOut-region'];
    }
    if (payload.url.includes('authenticate')) {
        headers['X-ProcessOut-region'] = 'aws-us-east-1';
    }
    const request = {
        ...payload.request,
        headers: headers,
    };
    yield Actions.HTTPCall(
        payload.url,
        request,
        payload.disableStatusParsing,
        payload.disableErrorDisplay,
    )
        .then(response => {
            payload.resolve(response);
        })
        .catch(error => {
            // Error
            if (error.data) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                payload.reject({
                    type: NETWORK_VALIDATION_ERROR,
                    details: error,
                    status: error.status,
                    notPermitted: error.status === 417,
                });
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                payload.reject({ type: NETWORK_REQUEST_FAILURE, details: error });
            } else {
                // Something happened in setting up the request that triggered an Error
                payload.reject({ type: NETWORK_BAD_REQUEST, details: error });
            }
        });
}

/**
 * Receives a request for a HTTP call and handles it
 * @param action
 */
export function* handleNetworkCall(action: $Action): Generator<*, *, *> {
    const { payload } = action;
    // We make sure we have everything to do the call
    if (!payload) {
        Sentry.captureMessage(' Trying to achieve HTTP call but payload is empty');
        return;
    }

    // We wait for the store to be unlocked
    let network = yield select(store => store.network);
    while (network.locked) {
        yield take();
        network = yield select(store => store.network);
    }

    // We retrieve all tokens and expiry dates
    const authDetails = yield select(store => store.user.auth);

    // We now check for the token expiry date
    if (moment().isAfter(moment.unix(authDetails.token.expires_at))) {
        // Token expired, we should now check for the refresh token expiry
        if (moment().isAfter(moment.unix(authDetails.refreshToken.expires_at))) {
            // Expiry token expired, user should be logged out
            // We make the call but it'll end up throwing a 401 and logging out the user
            yield makeHTTPCall(payload);
        } else {
            // Refresh token is still valid, we should use it to get a new token
            yield put(Actions.lockNetwork());
            const newTokens = yield call(refreshToken, authDetails.refreshToken.token);
            yield put(Actions.unlockNetwork());
            if (newTokens) {
                yield makeHTTPCall(payload, newTokens.token.token);
            } else {
                // Couldn't refresh the token but an error was displayed, waiting for next call
            }
        }
    } else {
        // Token is still valid
        yield makeHTTPCall(payload);
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeEvery(REQUEST_HTTP_CALL, handleNetworkCall);
}
