export default function(
    state = {
        fetching: false,
        fetched: false,
    },
    action,
) {
    switch (action.type) {
        case 'FETCH_INVOICE_DETAILS_PENDING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
            };
        }
        case 'FETCH_INVOICE_DETAILS_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                invoice: action.payload.product,
            };
        }
        case 'START_INVOICE_CREATION': {
            return {
                ...state,
                fetching: false,
                fetched: false,
                invoice: null,
            };
        }
        case 'EDIT_INVOICE_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                invoice: action.payload.product,
            };
        }
    }

    return state;
}
