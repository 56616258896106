// @flow

export const TRANSACTION_FIELDS: Array<{ key: string, desc: string, group: string }> = [
    { key: 'error_code', desc: 'Transaction error codes: do-not-honor…', group: 'Results' },
    {
        key: 'payment_type',
        desc: 'Transaction payment types: Card, Paypal…',
        group: 'Transaction parameters',
    },
    { key: 'name', desc: 'Transaction names', group: 'Transaction parameters' },
    {
        key: 'currency',
        desc: 'Currency used for the transactions: USD, EUR, CAD…',
        group: 'Transaction parameters',
    },
    { key: 'status', desc: 'Transaction status: completed, pending, failed…', group: 'Results' },
    {
        key: 'country',
        desc: 'Country in which the transactions occured: FR, US, UK…',
        group: 'Card parameters',
    },
    {
        key: 'continent',
        desc: 'Continent in which the transactions occured',
        group: 'Card parameters',
    },
    {
        key: 'attempts_count',
        desc: 'Number of attempts for a transaction',
        group: 'Transaction parameters',
    },
    {
        key: 'subscription_cycle',
        desc: 'Number of iteration of a subscription',
        group: 'Transaction parameters',
    },
    { key: 'card_scheme', desc: 'Card scheme: Visa, Mastercard, Amex…', group: 'Card parameters' },
    { key: 'card_co_scheme', desc: 'Card co-schemes: CB…', group: 'Card parameters' },
    { key: 'card_iin', desc: 'Card BIN/IIN', group: 'Card parameters' },
    { key: 'card_type', desc: 'Card types: Debit, Credit…', group: 'Card parameters' },
    { key: 'card_category', desc: 'Card categories: ', group: 'Card parameters' },
    {
        key: 'card_bank',
        desc: 'Bank that issued the card used for the transactions',
        group: 'Card parameters',
    },
    {
        key: 'cvc_check',
        desc: 'CVC status for transactions: passed, checked…',
        group: 'Card parameters',
    },
    {
        key: 'acquirer_name',
        desc: 'Name of acquirers that handled the transactions',
        group: 'Transaction parameters',
    },
    {
        key: 'gateway_configuration_id',
        desc: 'ID of the gateway configuration used for a transaction',
        group: 'Gateway parameters',
    },
    {
        key: 'gateway_name',
        desc: 'Name of the gateways used: Stripe, Adyen, Braintree…',
        group: 'Gateway parameters',
    },
    {
        key: 'gateway_fee_currency',
        desc: 'Currency of the fees paid to the gateway',
        group: 'Gateway parameters',
    },
    { key: 'created_at', desc: 'Transaction creation dates', group: 'Time' },
    {
        key: 'created_at_day_hour',
        desc: 'Hour of the day transaction was created (int)',
        group: 'Time',
    },
    {
        key: 'created_at_day_hour_segment',
        desc: 'Hour of the day transaction was created (string)',
        group: 'Time',
    },
    {
        key: 'created_at_month_day',
        desc: 'Day of the month transaction was created (int)',
        group: 'Time',
    },
    {
        key: 'created_at_week_day',
        desc: 'Day of the week transaction was created (int)',
        group: 'Time',
    },
    {
        key: 'created_at_week_day_name',
        desc: 'Day of the week transaction was created (name/string)',
        group: 'Time',
    },
    {
        key: 'three_d_s_status',
        desc: 'Whether or not the transactions went through 3DS',
        group: 'Results',
    },
    { key: 'eci', desc: 'Transaction type (such as recurring)', group: 'Results' },
    { key: 'mcc', desc: 'Merchant Category Code', group: 'Gateway parameters' },
    { key: 'merchant_account_id', desc: 'ID of the merchant account', group: 'Gateway parameters' },
    {
        key: 'is_on_us',
        desc: 'Whether the transaction is performed on-us or not',
        group: 'Transaction parameters',
    },
    { key: 'origin', desc: 'Origin of the transaction', group: 'Transaction parameters' },
];

export const TRANSACTION_AMOUNT_FIELDS: Array<{ key: string, desc: string, group: string }> = [
    { key: 'amount', desc: 'Transaction’s amount requested at its creation.', group: 'Amount' },
    {
        key: 'amount_local',
        desc: 'Transaction’s amount requested at its creation in your project’s currency',
        group: 'Amount',
    },
    {
        key: 'available_amount',
        desc: 'Captured amount minus the total refund value.',
        group: 'Amount',
    },
    {
        key: 'available_amount_local',
        desc: 'Captured amount minus the total refund value in your project’s currency',
        group: 'Amount',
    },
    { key: 'refunded_amount', desc: 'Total refunded amount.', group: 'Amount' },
    {
        key: 'refunded_amount_local',
        desc: 'Total refunded amount in your project’s currency.',
        group: 'Amount',
    },
    { key: 'captured_amount', desc: 'Total captured amount.', group: 'Amount' },
    {
        key: 'captured_amount_local',
        desc: 'Total captured amount in your project’s currency.',
        group: 'Amount',
    },
    { key: 'authorized_amount', desc: 'Total authorized amount.', group: 'Amount' },
    {
        key: 'authorized_amount_local',
        desc: 'Total authorized amount in your project’s currency.',
        group: 'Amount',
    },
    { key: 'gateway_fee', desc: 'Fee charged by your gateway.', group: 'Amount' },
    {
        key: 'gateway_fee_local',
        desc: 'Fee charged by your gateway in your project’s currency.',
        group: 'Amount',
    },
    { key: 'processing_fee', desc: 'Total processing fee.', group: 'Amount' },
    {
        key: 'processing_fee_local',
        desc: 'Total processing fees in your project’s currency.',
        group: 'Amount',
    },
    { key: 'exchange_fee', desc: 'Total exchange fee', group: 'Amount' },
    {
        key: 'exchange_fee_local',
        desc: 'Total exchange fees in your project’s currency.',
        group: 'Amount',
    },
    { key: 'refund_fee', desc: 'Total refund fee.', group: 'Amount' },
    {
        key: 'refund_fee_local',
        desc: 'Total refund fee in your project’s currency',
        group: 'Amount',
    },
    { key: 'interchange_fee', desc: 'Total interchange fee.', group: 'Amount' },
    {
        key: 'interchange_fee_local',
        desc: 'Total interchange fee in your project’s currency.',
        group: 'Amount',
    },
    { key: 'scheme_fee', desc: 'Total scheme (Visa, mastercard…) fee.', group: 'Amount' },
    {
        key: 'scheme_fee_local',
        desc: 'Total scheme (Visa, mastercard…) fee in your project’s currency.',
        group: 'Amount',
    },
    { key: 'markup_fee', desc: 'Fee above the interchange+', group: 'Amount' },
    {
        key: 'markup_fee_local',
        desc: 'Fee above the interchange+ in your project’s currency.',
        group: 'Amount',
    },
    { key: 'interchange_fee_estimate', desc: 'Total estimated interchange fee.', group: 'Amount' },
    {
        key: 'interchange_fee_estimate_local',
        desc: 'Total estimated interchange fee in your project’s currency.',
        group: 'Amount',
    },
    {
        key: 'scheme_fee_estimate',
        desc: 'Total estimated scheme (Visa, mastercard…) fee.',
        group: 'Amount',
    },
    {
        key: 'scheme_fee_estimate_local',
        desc: 'Total estimated scheme (Visa, mastercard…) fee in your project’s currency.',
        group: 'Amount',
    },
];
