// @flow
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import Analytics from '../../util/Analytics';
import type { $Dispatcher } from '../../util/Types';
import type { $FeesRecommendation } from '../../features/Telescope/reducer';
// $FlowFixMe
import './advice.scss';

const ReactMarkdown = require('react-markdown');

class Advice extends React.Component {
    props: $FeesRecommendation & $Dispatcher;

    generateImage(): string {
        switch (this.props.type) {
            case 'country':
                return 'flag';
            case 'fee':
                return 'fees';
            default:
                return 'fees';
        }
    }

    showAdvice(event) {
        event.preventDefault();
        Analytics('RECOMMENDATION_OPENED', {
            title: this.props.title,
            email: this.props.user.details.email,
        });
        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: (
                    <div class="row">
                        <div class="medium-2 columns">
                            <img
                                src={`//dashboard.processout.com/images/${this.generateImage()}.png`}
                                style={{
                                    height: '2.6em',
                                    width: 'auto',
                                    opacity: 0.1,
                                }}
                            />
                        </div>
                        <div class="medium-10 columns small-font" style={{ marginTop: '0.4em' }}>
                            {this.props.title}
                        </div>
                    </div>
                ),
                content: (
                    <div>
                        <div class="row">
                            <div class="large-12 columns">
                                <ReactMarkdown source={this.props.advice} />
                            </div>
                        </div>
                    </div>
                ),
                footer: (
                    <div class="row">
                        <div class="medium-12 columns text-right">
                            <a
                                onClick={function(event) {
                                    event.preventDefault();
                                    this.props.dispatch({
                                        type: 'CLOSE_MODAL',
                                    });
                                }.bind(this)}
                                class="round border small button"
                            >
                                Close
                            </a>
                        </div>
                    </div>
                ),
            },
        });
    }

    render() {
        return (
            <a onClick={this.showAdvice.bind(this)}>
                <div
                    className="row telescope-advice-row"
                    style={{
                        padding: '15px 22px',
                        borderBottom: '1px solid #f3f5f6',
                    }}
                >
                    <div className="medium-1 columns text-left">
                        <img
                            src={`//dashboard.processout.com/images/${this.generateImage()}.png`}
                            style={{
                                maxHeight: '1.5em',
                                width: 'auto',
                                opacity: 0.1,
                            }}
                        />
                    </div>
                    <div className="medium-10 columns text-left" style={{ marginTop: '0.1em' }}>
                        {this.props.title}
                    </div>
                    <div className="medium-1 columns text-right" style={{ fontSize: '1.3em' }}>
                        <div class="arrow">
                            <span href="#">&rarr;</span>
                        </div>
                    </div>
                </div>
            </a>
        );
    }
}

export default connect(function(store) {
    return {
        projects: store.projects,
        user: store.user,
    };
})(Advice);
