export default function(
    state = {
        fetching: false,
        fetched: false,
    },
    action,
) {
    switch (action.type) {
        case 'FETCH_SUBSCRIPTION_DETAILS_PENDING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
            };
        }
        case 'FETCH_SUBSCRIPTION_DETAILS_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                subscription: action.payload.subscription,
            };
        }
    }
    return state;
}
