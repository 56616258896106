// @flow
import React from 'react';
import { connect } from 'react-redux';
import HighLight from '../../components/EventsDetails/Highlight';
import _ from 'underscore';
import type { $Dispatcher } from '../../util/Types';

type Props = {
    probabilities: { answer: string },
} & $Dispatcher;

class ProbaModal extends React.Component<Props> {
    openModal = () => {
        let content = JSON.stringify(this.props.probabilities.answer, null, 4);

        // Escape content html to prevent xss
        content = _.escape(content);

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                large: true,
                header: 'Processors probabilities',
                content: (
                    <div class='row'>
                        <div class='large-10 large-centered columns'>
                            <HighLight innerHTML={true}>
                                {`<pre><code>${content}</code></pre>`}
                            </HighLight>
                        </div>
                    </div>
                ),
                footer: (
                    <div class='row'>
                        <div class='large-12 columns text-right'>
                            <a
                                class='round border button'
                                onClick={() => {
                                    this.props.dispatch({
                                        type: 'CLOSE_MODAL',
                                    });
                                }}
                            >
                                cancel
                            </a>
                        </div>
                    </div>
                ),
            },
        });
    };

    render() {
        return null;
    }
}

export default connect(
    store => {
        return {
            probabilities: store.transactionDetails.probabilities,
        };
    },
    null,
    null,
    { withRef: true },
)(ProbaModal);
