// @flow

import React from 'react';
import { replace } from 'react-router-redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Project from '../../util/Project';
import type { $Dispatcher } from '../../util/Types';

type Props = {
    params: {
        project: ?string,
    },
} & $Dispatcher;

export class SandboxSwitch extends React.Component<Props> {
    updateSandbox = () => {
        const { params } = this.props;
        if (!params.project) return;
        const project = new Project(params.project);
        const { dispatch } = this.props;
        dispatch(
            replace(
                `/projects/${
                    !project.isSandbox() ? `test-${project._id}` : project._id.replace('test-', '')
                }/telescope`,
            ),
        );
    };

    render() {
        const { params } = this.props;
        if (!params.project) return null;
        const project = new Project(params.project);
        return (
            <div class='row'>
                <div
                    class='medium-9 columns text-right capitalize text-right'
                    style={{
                        color: 'white',
                        fontSize: '.9em',
                        marginTop: '.7em',
                        padding: 0,
                    }}
                >
                    sandbox mode
                </div>
                <div class='medium-3 columns'>
                    <label class='ios7-switch sandbox' style={{ marginTop: '.5em' }}>
                        <input
                            type='checkbox'
                            checked={project.isSandbox()}
                            onChange={this.updateSandbox}
                        />
                        <span />
                    </label>
                </div>
            </div>
        );
    }
}

export default connect()(withRouter(SandboxSwitch));
