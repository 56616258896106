// @flow
import uniqid from 'uniqid';

export const PREPARE_PERMISSIONS = 'PREPARE_PERMISSIONS';
export const PREPARE_COLLABORATORS = 'PREPARE_COLLABORATORS';
export const PREPARE_PERMISSION_GROUPS = 'PREPARE_PERMISSION_GROUPS';

export type $Collaborator = {
    id: string,
    role: 'owner' | 'primary-owner' | 'permission-group',
    permission_group_name: ?string,
    email_unsubscriptions: Array<$NotificationType>,
    user: {
        email: string,
    },
    editing?: ?boolean, // WARNING: there can only be one editing collaborator
};

export type $Permission = {
    id: string,
    action: string,
};

export type $PermissionGroup = {
    id: ?string,
    name: string,
    permissions: {
        allow: ?Array<$Permission>,
        deny: ?Array<$Permission>,
    },
};

export type $PermissionFeature = {
    id: string,
    name: string,
    actions: Array<string>,
    description: string,
};

export const CUSTOM_PERM = 'permission-group';
export const OWNER = 'owner';
export const PRIMARY_OWNER = 'primary-owner';

export const NOT_ALLOWED = "Your role doesn't allow you to access this feature.";
export const POST_NOT_ALLOWED = "Your role doesn't allow you to create this resource.";

export const Notifications = {
    'telescope.report': 'Telescope report each week.',
    'project-billing.charged': 'Your project was successfully billed.',
    'project-billing.failed': 'Your project could not be billed.',
    'transaction.completed': 'You received a new payment.',
    'transaction.refunded': 'A refund was issued for one of your payments.',
    'transaction.reversed': 'A chargeback was issued against one of your payments.',
    'payment-provider.rejected-credentials': "Your payment provider's credentials were rejected.",
};

export type $NotificationType = $Keys<typeof Notifications>;

export const newPermissionGroup = (): $PermissionGroup => ({
    ...DefaultPermissionGroup,
    id: uniqid(),
});

const DefaultPermissionGroup = {
    id: uniqid(),
    name: 'New Group',
    permissions: {
        allow: [],
        deny: null,
    },
};
