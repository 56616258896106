import React from 'react';
import ContentLayout from './ContentLayout';
import { FormattedNumber, ReactIntl } from 'react-intl';
import ModalFooter from '../components/ModalFooter';
import * as RefundActions from '../Actions/RefundActions';
import * as TransactionsActions from '../Actions/TransactionsActions';
import { connect } from 'react-redux';

@connect(store => {
    return {
        transactionDetails: store.transactionDetails.details,
    };
})
export default class TransactionDetailsLayout extends React.Component {
    refundTransaction() {
        let header = <div class='large-12 columns'>Refund a transaction</div>;

        let content = (
            <div class='large-12 columns'>
                <div class='row small-margin-bottom'>
                    <div class='medium-4 columns font-console' style={{ marginTop: '7px' }}>
                        Amount ({this.props.transactionDetails.transaction.currency})
                    </div>
                    <div class='medium-8 columns'>
                        <input
                            id='refund-amount'
                            type='number'
                            min='0'
                            step='0.01'
                            placeholder='Amount to be refunded'
                            class='no-margin'
                        />
                    </div>
                </div>
                <div class='row'>
                    <div class='large-12 columns margin-bottom text-right'>
                        <a
                            onClick={function(event) {
                                event.preventDefault();
                                document.querySelector(
                                    '#refund-amount',
                                ).value = this.props.transactionDetails.transaction.available_amount;
                            }.bind(this)}
                        >
                            Operate a full refund &rarr;
                        </a>
                    </div>
                </div>
                <p class='greyed no-margin'>
                    {`There might be a slight delay between the refund and the availability on your customer's statement.`}
                </p>
            </div>
        );

        let footer = (
            <div class='row'>
                <div class='medium-12 columns text-right'>
                    <ModalFooter
                        ref={m => (this._modalFooter = m)}
                        submitTitle='Refund'
                        submitCallback={function(event) {
                            event.preventDefault();
                            this._modalFooter.getWrappedInstance().lock();
                            let amount = document.querySelector('#refund-amount').value;
                            this.createRefund(amount);
                        }.bind(this)}
                    />
                </div>
            </div>
        );

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: header,
                content: content,
                footer: footer,
            },
        });
    }

    captureTransaction() {
        let header = <div class='large-12 columns'>Capture a transaction</div>;

        let content = (
            <div class='large-12 columns'>
                <div class='row margin-bottom'>
                    <div class='medium-4 columns font-console'>
                        Amount ({this.props.transactionDetails.transaction.currency})
                    </div>
                    <div class='medium-8 columns text-right'>
                        <FormattedNumber
                            value={this.props.transactionDetails.transaction.amount}
                            style='currency'
                            currency={this.props.transactionDetails.transaction.currency}
                        />
                    </div>
                </div>
                <div class='row'>
                    <div class='large-12 columns margin-bottom'>
                        <p class='greyed no-margin'>
                            {`This will capture all the funds that were authorized for this transaction.`}
                        </p>
                    </div>
                </div>
            </div>
        );

        let footer = (
            <div class='large-12 columns'>
                <div class='row'>
                    <div class='large-12 columns text-right'>
                        <a
                            onClick={function(event) {
                                event.preventDefault();
                                this.props.dispatch({ type: 'CLOSE_MODAL' });
                            }.bind(this)}
                            class='round border small button'
                        >
                            Cancel
                        </a>

                        <a
                            onClick={function(event) {
                                event.preventDefault();
                                this.capture();
                            }.bind(this)}
                            class='round small main button white-text'
                        >
                            Capture
                        </a>
                    </div>
                </div>
            </div>
        );

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: header,
                content: content,
                footer: footer,
            },
        });
    }

    voidTransaction() {
        let header = <div class='large-12 columns'>Void a transaction</div>;

        let content = (
            <div class='large-12 columns'>
                <div class='row margin-bottom'>
                    <div class='medium-4 columns font-console'>
                        Amount ({this.props.transactionDetails.transaction.currency})
                    </div>
                    <div class='medium-8 columns text-right'>
                        <FormattedNumber
                            value={this.props.transactionDetails.transaction.amount}
                            style='currency'
                            currency={this.props.transactionDetails.transaction.currency}
                        />
                    </div>
                </div>
                <div class='row'>
                    <div class='large-12 columns margin-bottom'>
                        <p class='greyed no-margin'>
                            {`This will release all the funds that were authorized for this transaction.`}
                        </p>
                    </div>
                </div>
            </div>
        );

        let footer = (
            <div class='large-12 columns'>
                <div class='row'>
                    <div class='large-12 columns text-right'>
                        <a
                            onClick={function(event) {
                                event.preventDefault();
                                this.props.dispatch({ type: 'CLOSE_MODAL' });
                            }.bind(this)}
                            class='round border small button'
                        >
                            Cancel
                        </a>

                        <a
                            onClick={function(event) {
                                event.preventDefault();
                                this.void();
                            }.bind(this)}
                            class='round small main button white-text'
                        >
                            Void
                        </a>
                    </div>
                </div>
            </div>
        );

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: header,
                content: content,
                footer: footer,
            },
        });
    }

    createRefund(amount) {
        if (!this.props.transactionDetails) return;

        let data = {
            reason: 'customer_request',
            amount: amount ? amount : this.props.transactionDetails.transaction.available_amount,
        };

        this.props.dispatch(
            RefundActions.createRefund(
                this.props.transactionDetails.transaction.id,
                JSON.stringify(data, null, 2),
                function() {
                    this.props.dispatch({ type: 'CLOSE_MODAL' });
                }.bind(this),
                function() {
                    this._modalFooter.getWrappedInstance().unlock();
                }.bind(this),
            ),
        );
    }

    capture() {
        this.props.dispatch({ type: 'CLOSE_MODAL' });
        if (!this.props.transactionDetails) return;

        this.props.dispatch(
            TransactionsActions.captureTransaction(this.props.transactionDetails.transaction),
        );
    }

    void() {
        this.props.dispatch({ type: 'CLOSE_MODAL' });
        if (!this.props.transactionDetails) return;

        this.props.dispatch(
            TransactionsActions.voidTransaction(this.props.transactionDetails.transaction),
        );
    }

    render() {
        const { location } = this.props;
        const { transaction } = this.props.transactionDetails;
        const containerStyle = {
            marginTop: '60px',
        };

        let refundButton;
        if (this.props.transactionDetails.fetched && transaction.gateway_configuration) {
            let gateway = transaction.gateway_configuration.gateway;

            if (
                gateway.can_refund &&
                transaction.available_amount > 0 &&
                transaction.status !== 'chargeback-initiated'
            ) {
                refundButton = (
                    <input
                        type='button'
                        onClick={this.refundTransaction.bind(this)}
                        class='round button border right small'
                        value='Refund'
                    />
                );
            }
        }

        let captureButton;
        let voidButton;
        if (
            this.props.transactionDetails.fetched &&
            transaction.status === 'authorized' &&
            transaction.invoice
        ) {
            captureButton = (
                <input
                    type='button'
                    style={{ width: '150px' }}
                    onClick={this.captureTransaction.bind(this)}
                    class='round button border right small'
                    value='Capture'
                />
            );
            voidButton = (
                <input
                    type='button'
                    style={{ width: '150px' }}
                    onClick={this.voidTransaction.bind(this)}
                    class='round button border right small'
                    value='Void'
                />
            );
        }

        let name;
        if (this.props.transactionDetails.fetched) {
            name = <h6 class='greyed bold'>{transaction.name}</h6>;
        }

        let header = (
            <div>
                <div class='row'>
                    <div class='medium-8 columns'>
                        <h4>{this.props.title}</h4>
                    </div>
                    <div class='medium-2 columns'>{voidButton}</div>
                    <div class='medium-2 columns'>
                        {refundButton}
                        {captureButton}
                    </div>
                </div>
                <div class='row'>
                    <div class='large-12 columns'>{name}</div>
                </div>
            </div>
        );

        return <ContentLayout title={header}>{this.props.children}</ContentLayout>;
    }
}
