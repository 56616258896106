// @flow
import React from 'react';
import uniqid from 'uniqid';
import * as ReactDOMServer from 'react-dom/server';
import getColor from '../Boards/charts/colors';
import { FormattedNumber, IntlProvider } from 'react-intl';
import type { $ChartType } from '../Boards/consts';

type $ChartPoint = {
    _count: number,
    value: number,
    key: string,
};

type Props = {
    data: Array<{ key: string, datapoints: Array<$ChartPoint> }>,
    currency: ?string,
    type: $ChartType,
    keysMask?: ?Array<string>, // keys to be masked
};

type State = {
    id: string,
    chart: any,
    colors: Array<{ color: string, key: string }>,
};

class StackChart extends React.Component {
    props: Props;
    state: State;
    _chartElement: any;

    constructor() {
        super();
        this.state = {
            id: uniqid(),
            chart: null,
            colors: [],
        };
    }

    componentDidMount(): void {
        if (this.props.error) return;
        this.prepareDraw(this.props);
    }

    componentWillReceiveProps(nextProps: Props): void {
        this.prepareDraw(nextProps);
    }

    componentWillUnmount() {
        const { chart } = this.state;
        if (chart) chart.clear();
    }

    prepareDraw = (props: Props) => {
        const { data } = props;
        const colors: Array<{ color: string, key: string }> = [];
        for (const entry of data) {
            for (let i = 0; i < entry.datapoints.length; i++) {
                if (colors.findIndex(color => color.key === entry.datapoints[i].key) !== -1)
                    continue;
                colors.push({ color: getColor(i), key: entry.datapoints[i].key });
            }
        }
        this.setState({ colors }, () => {
            this.draw();
        });
    };

    draw = () => {
        const { data, currency, type, keysMask } = this.props;
        const { colors } = this.state;
        let chart;
        if (!this.state.chart) {
            // $FlowFixMe
            chart = new Picasso.BarLineChart(this._chartElement, {
                yAxisFormatter: d => {
                    return '$' + d;
                },
            });
            this.setState({ chart });
        } else {
            chart = this.state.chart;
        }
        chart.cleanupTip();
        chart.reset();

        // fill chart
        const bars = [];
        for (const entry of data) {
            if (keysMask && keysMask.includes(entry.key)) continue;

            let bar = { key: entry.key };
            for (const set of entry.datapoints)
                bar[set.key] = type === 'percentage' ? set._count : set.value;
            bars.push({ ...bar, _datapoints: entry.datapoints });
        }

        chart.addBar({
            data: bars,
            colors: colors.map(color => color.color),
            tip: d => {
                return ReactDOMServer.renderToString(
                    <IntlProvider locale={navigator ? navigator.language || 'en-US' : 'en-US'}>
                        <div>
                            <h5>{d.key}</h5>
                            {type === 'amount' ? (
                                <AmountTooltip
                                    barName={d.key}
                                    datapoints={d._datapoints}
                                    currency={currency || 'USD'}
                                    colors={colors}
                                />
                            ) : type === 'percentage' ? (
                                <PercentTooltip datapoints={d._datapoints} colors={colors} />
                            ) : (
                                <DefaultTooltip datapoints={d._datapoints} colors={colors} />
                            )}
                        </div>
                    </IntlProvider>,
                );
            },
            onclick: function(d) {
                console.log(d);
            },
        });

        chart.resetSVG();
        chart.draw();
    };

    render() {
        const { id } = this.state;
        return (
            <svg
                class={`chart stack-chart `}
                id={`chart${id}`}
                ref={e => (this._chartElement = e)}
                width="100%"
                height="200px"
            />
        );
    }
}

export default StackChart;

const AmountTooltip = (props: {
    barName: string,
    datapoints: Array<$ChartPoint>,
    currency: string,
    colors: Array<{ color: string, key: string }>,
}) => {
    const { datapoints, barName, currency, colors } = props;
    const totalValue = datapoints.reduce((count, point) => count + point.value, 0);
    return (
        <div style={{ fontSize: '.8em', maxWidth: '400px' }}>
            {datapoints.filter(entry => entry._count > 0).map(entry => {
                const color = colors.find(color => color.key === entry.key);
                return (
                    <div
                        className="small-margin-bottom"
                        key={entry.key}
                        style={{ float: 'left', width: '200px' }}
                    >
                        <div
                            style={{
                                fontSize: '1.1em',
                                marginBottom: 0,
                                color: color ? color.color : getColor(0),
                            }}
                        >
                            {entry.key}
                        </div>
                        <div>
                            <FormattedNumber
                                key={entry.key}
                                value={entry.value}
                                style={'currency'}
                                currency={currency}
                                maximumFractionDigits={2}
                                minimumFractionDigits={2}
                            />{' '}
                            <div className="greyed" style={{ display: 'inline-block' }}>
                                (<FormattedNumber
                                    value={entry.value / totalValue}
                                    style={'percent'}
                                />)
                            </div>
                        </div>
                        <div>
                            <FormattedNumber key={entry.key} value={entry._count} />{' '}
                            <div className="greyed" style={{ display: 'inline-block' }}>
                                transactions
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const PercentTooltip = (props: {
    datapoints: Array<$ChartPoint>,
    colors: Array<{ color: string, key: string }>,
}) => {
    const { datapoints, colors } = props;
    const totalCount = datapoints.reduce((count, point) => count + point._count, 0);
    return (
        <div style={{ fontSize: '.8em', maxWidth: '400px' }}>
            {datapoints.filter(entry => entry._count > 0).map(entry => {
                const color = colors.find(color => color.key === entry.key);
                return (
                    <div
                        className="small-margin-bottom"
                        key={entry.key}
                        style={{ float: 'left', width: '200px' }}
                    >
                        <div
                            style={{
                                fontSize: '1.1em',
                                marginBottom: 0,
                                color: color ? color.color : getColor(0),
                            }}
                        >
                            {entry.key}{' '}
                            <span style={{ display: 'inline-block' }} className="greyed">
                                {' '}
                                —{' '}
                                <FormattedNumber
                                    value={entry._count / totalCount}
                                    style={'percent'}
                                />
                                {' of transactions'}
                            </span>
                        </div>
                        <FormattedNumber
                            key={entry.key}
                            value={entry.value}
                            style={'percent'}
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                        />{' '}
                        <div className="greyed" style={{ display: 'inline-block' }}>
                            — <FormattedNumber value={entry._count} /> transactions
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const DefaultTooltip = (props: {
    datapoints: Array<$ChartPoint>,
    colors: Array<{ color: string, key: string }>,
}) => {
    const { datapoints, colors } = props;
    const totalCount = datapoints.reduce((count, point) => count + point._count, 0);
    return (
        <div style={{ fontSize: '.8em', maxWidth: '400px' }}>
            {datapoints.filter(entry => entry._count > 0).map(entry => {
                const color = colors.find(color => color.key === entry.key);
                return (
                    <div
                        className="small-margin-bottom"
                        key={entry.key}
                        style={{ float: 'left', width: '200px' }}
                    >
                        <div
                            style={{
                                fontSize: '1.1em',
                                marginBottom: 0,
                                color: color ? color.color : getColor(0),
                            }}
                        >
                            {entry.key}
                        </div>
                        <FormattedNumber value={entry._count} /> transactions
                        <span class="greyed">
                            {' '}
                            (<FormattedNumber
                                value={entry._count / totalCount}
                                style={'percent'}
                            />)
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
