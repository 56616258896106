import React from 'react';
import ContentLayout from './ContentLayout';
import * as EventsDetailsActions from '../Actions/EventsDetailsActions';
import Data from '../components/EventsDetails/Data';
import Webhook from '../components/EventsDetails/Webhook';
import Loader from '../components/Loader';
import Empty from '../components/Empty';
import Error from '../components/Error';
import { ReactIntl } from 'react-intl';
import { connect } from 'react-redux';
import HighLight from '../components/EventsDetails/Highlight';
import * as ProcessOut from '../util/ProcessOut';
import PropTypes from 'prop-types';
import _ from 'underscore';

@connect(store => {
    return { details: store.eventDetails };
})
export default class EventsDetails extends React.Component {
    static contextTypes = {
        store: PropTypes.object.isRequired,
    };

    constructor() {
        super();
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Event details | ProcessOut';
        this.props.dispatch(EventsDetailsActions.loadEvent(this.props.params.event));
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.details.fetching && !nextProps.details.fetched && !nextProps.details.error) {
            this.props.dispatch(EventsDetailsActions.loadEvent(this.props.params.event));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    render() {
        const details = this.props.details;

        if ((details.fetching || !details.fetched) && !details.error) {
            return (
                <ContentLayout title={<h4>Event details</h4>}>
                    <Loader />
                </ContentLayout>
            );
        } else if (details.error) {
            return (
                <ContentLayout title={<h4>Event details</h4>}>
                    <Error />
                </ContentLayout>
            );
        }

        const event = details.event;

        var content = JSON.stringify(event.data, null, 4);

        // Escape content html to prevent xss
        content = _.escape(content);

        content = content.replace(
            /&quot;(tr_[a-z0-9]+)&quot;/gi,
            `<a class="json-link" target="_blank" href="#/projects/${
                ProcessOut.ProjectId
            }/transactions/$1">\"$1 &nearr;\"</a>`,
        );
        content = content.replace(
            /&quot;(cust_[a-z0-9]+)&quot;/gi,
            `<a class="json-link" target="_blank" href="#/projects/${
                ProcessOut.ProjectId
            }/customers/$1">\"$1 &nearr;\"</a>`,
        );
        content = content.replace(
            /&quot;(sub_[a-z0-9]+)&quot;/gi,
            `<a class="json-link" target="_blank" href="#/projects/${
                ProcessOut.ProjectId
            }/recurring/subscriptions/$1">\"$1 &nearr;\"</a>`,
        );

        var webhooks;
        if (details.event.webhooks.length > 0) {
            webhooks = (
                <div class='large-12 columns'>
                    {event.webhooks.map(function(hook) {
                        return <Webhook webhook={hook} key={hook.id} />;
                    })}
                </div>
            );
        } else {
            webhooks = <Empty text='No webhook available.' />;
        }

        return (
            <ContentLayout title={<h4>Event details</h4>}>
                <div class='row'>
                    <div class='large-5 columns'>
                        <div class='row small-margin-bottom'>
                            <div class='large-12 columns'>
                                <h6 class='greyed capitalize font-console'>Details</h6>
                            </div>
                        </div>
                        <div class='row small-margin-bottom'>
                            <div class='large-12 columns'>
                                <Data event={event} />
                            </div>
                        </div>
                        <div class='row small-margin-bottom'>
                            <div class='large-12 columns'>
                                <hr />
                            </div>
                        </div>
                        <div class='row small-margin-bottom'>
                            <div class='large-12 columns'>
                                <h6 class='greyed capitalize font-console'>Webhooks</h6>
                            </div>
                        </div>
                        <div class='row'>{webhooks}</div>
                    </div>
                    <div class='large-7 columns'>
                        <div class='row'>
                            <div class='large-12 medium-9 columns'>
                                <HighLight innerHTML={true}>
                                    {`<pre><code>${content}</code></pre>`}
                                </HighLight>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentLayout>
        );
    }
}
