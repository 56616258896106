import React from 'react';
import { ReactIntl } from 'react-intl';
import * as GatewaysActions from '../../Actions/GatewaysActions';
import GatewayModal from './GatewayModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as ConfirmActions from '../ConfirmModal/actions';
import ConfigurationModal from './ConfigurationModal';

@connect(function(store) {
    return {
        routingRulesSettings: store.routingRulesSettings,
    };
})
export default class Configuration extends React.Component {
    static propTypes = {
        config: PropTypes.object.isRequired,
    };

    constructor() {
        super();
        this.state = {
            activated: false,
            work: '',
        };
        this._configModal = React.createRef();
    }

    componentDidMount() {
        this.setState({ activated: this.props.config.enabled });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ activated: nextProps.config.enabled });
    }

    switchChanged(config, event) {
        event.preventDefault();

        var checkbox = document.getElementById(config.id);
        if (checkbox.checked) {
            this.setState({ work: 'ENABLING' });
            this.props.dispatch(
                GatewaysActions.enableGateway(
                    config.gateway.name,
                    config.id,
                    function(success) {
                        this.setState({ work: '' });
                        if (success) {
                            this.setState({ activated: true });
                        } else {
                            this.setState({ activated: false });
                        }
                    }.bind(this),
                ),
            );
        } else {
            const disableGateway = () => {
                this.setState({ work: 'DISABLING' });
                this.props.dispatch(
                    GatewaysActions.disableGateway(
                        config.gateway.name,
                        config.id,
                        function(success) {
                            this.setState({ work: '' });
                            if (success) {
                                this.setState({ activated: false });
                            } else {
                                this.setState({ activated: true });
                            }
                        }.bind(this),
                    ),
                );
            };
            if (this.isGatewayUsedInRouting(config)) {
                this.props.dispatch(
                    ConfirmActions.requestConfirmation(
                        <div class='row small-margin-bottom ' style={{ paddingTop: '1.5em' }}>
                            <div class='row small-margin-bottom'>
                                <div class='large-11 large-centered columns'>
                                    <p>
                                        Are you sure you want to disable this gateway configuration?
                                    </p>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='large-11 large-centered columns text-left'>
                                    <p style={{ color: '#e74c3c' }}>
                                        <strong>Warning</strong>: This configuration is used in one
                                        of your static rules.
                                    </p>
                                </div>
                            </div>
                        </div>,
                        disableGateway,
                        () => {
                            // User canceled
                        },
                    ),
                );
            } else {
                disableGateway();
            }
        }
    }

    isGatewayUsedInRouting = config => {
        return this.props.routingRulesSettings.rules.reduce(
            (usedInRouting, rule) =>
                usedInRouting ||
                rule.gateways.reduce(
                    (usedInRule, gateway) => usedInRule || gateway.gateway === config.id,
                    false,
                ),
            false,
        );
    };

    confirmDelete(config, event) {
        event.preventDefault();
        this.props.dispatch(
            GatewaysActions.deleteGatewayConfig(
                config.gateway.name,
                config.id,
                function() {
                    this.props.dispatch({ type: 'CLOSE_MODAL' });
                    this.props.dispatch(GatewaysActions.loadGatewaysConfigurations());
                }.bind(this),
            ),
        );
    }

    deleteConfig(config, event) {
        event.preventDefault();
        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: `Delete ${config.gateway.display_name} configuration?`,
                content: (
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <div class='row small-margin-bottom' style={{ paddingTop: '1.5em' }}>
                            <div class='large-12 columns text-center'>
                                <p>Are you sure you want to delete this configuration?</p>
                            </div>
                        </div>
                        <div class='row small-margin-bottom'>
                            <div class='large-2 columns'>ID:</div>
                            <div class='large-10  columns greyed text-right'>
                                <span class='console-font small-font'>{config.id}</span>
                            </div>
                        </div>
                        <div class='row small-margin-bottom'>
                            <div class='large-2 columns'>Gateway:</div>
                            <div class='large-10  columns greyed text-right'>
                                {config.gateway.display_name}
                            </div>
                        </div>
                        <div class='row small-margin-bottom'>
                            <div class='large-2 columns'>Status:</div>
                            <div class='large-10  columns greyed text-right'>
                                {config.enabled ? (
                                    <span class='bold'>Enabled</span>
                                ) : (
                                    <span>Disabled</span>
                                )}
                            </div>
                        </div>
                        {this.isGatewayUsedInRouting(config) ? (
                            <div class='row small-margin-bottom ' style={{ paddingTop: '1.5em' }}>
                                <div class='large-12 columns text-left'>
                                    <p style={{ color: '#e74c3c' }}>
                                        <strong>Warning</strong>: This configuration is used in one
                                        of your static rules.
                                    </p>
                                </div>
                            </div>
                        ) : null}
                    </div>
                ),
                footer: (
                    <div class='row'>
                        <div class='large-12 columns text-right'>
                            <a
                                onClick={function(event) {
                                    event.preventDefault();
                                    this.props.dispatch({
                                        type: 'CLOSE_MODAL',
                                    });
                                }.bind(this)}
                                class='round border small button'
                            >
                                Cancel
                            </a>
                            <input
                                type='button'
                                onClick={this.confirmDelete.bind(this, config)}
                                class='round small main button white-text'
                                value='Delete'
                            />
                        </div>
                    </div>
                ),
            },
        });
    }

    openModal(event) {
        event.preventDefault();
        const { config } = this.props;
        console.log(this.refs);
        this._configModal.current.getWrappedInstance().openModal();
    }

    render() {
        var config = this.props.config;
        var status;
        if (this.state.work === 'ENABLING') {
            status = 'Enabling';
        } else if (this.state.work === 'DISABLING') {
            status = 'Disabling';
        } else if (this.state.activated) {
            status = 'This configuration is currently enabled';
        }

        return (
            <div class='payment-processor-wrapper details-box'>
                <ConfigurationModal configuration={config} ref={this._configModal} />
                <a onClick={this.openModal.bind(this)}>
                    <div class='row'>
                        <div class='large-12 columns text-center img-wrapper'>
                            <img
                                class={this.state.activated ? '' : 'desaturate'}
                                src={config.gateway.logo_url}
                            />
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns text-center description'>
                            {config.name || config.gateway.display_name}
                        </div>
                    </div>
                </a>

                <div class='row'>
                    <div class='medium-6 columns text-left'>
                        <label class='ios7-switch big' style={{ marginTop: '.2em' }}>
                            <input
                                id={config.id}
                                type='checkbox'
                                onChange={this.switchChanged.bind(this, config)}
                                checked={this.state.activated}
                            />
                            <span />
                        </label>
                    </div>
                    <div class='medium-6 columns text-right delete' style={{ marginTop: '3px' }}>
                        <a onClick={this.deleteConfig.bind(this, config)}>delete</a>
                    </div>
                </div>
            </div>
        );
    }
}
