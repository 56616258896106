// @flow

// Remove all whitespaces from a formula
import type { $Metric } from './ChartBuilder/consts';
import type { $Filter } from './ChartBuilder/Filters/consts';
import type { $ChartBuilderState } from './ChartBuilder/reducer';

export function areFormulasEqual(formula1: string, formula2: string): boolean {
    return trimChartFormula(formula1) === trimChartFormula(formula2);
}

// Remove all whitespaces from a formula
// WARNING: this also removes whitespaces from keys and string values
function trimChartFormula(formula: string): string {
    return formula.replace(new RegExp(/(\r\n\t|\n|\r\t|transactions.|;|{|}|\s)/gm), '');
}

// Turns a Array of filters into a filters string for POlang
export const computeFilterString = (filters: Array<$Filter>): string => {
    return filters
        .filter(
            filter =>
                filter.path &&
                (filter.operand.includes('null') || (filter.value && filter.value.length > 0)),
        )
        .map((filter, index, array) => {
            if (filter.value.length === 0) {
                if (filter.operand === 'is-null') {
                    return `${filter.path} == null${index === array.length - 1 ? ';' : ''}`;
                } else if (filter.operand === 'is-not-null') {
                    return `${filter.path} != null${index === array.length - 1 ? ';' : ''}`;
                } else return '';
            }
            return filter.value
                .map(entry => {
                    if (filter.operand === 'is-null') {
                        return `${filter.path} == null${index === array.length - 1 ? ';' : ''}`;
                    } else if (filter.operand === 'is-not-null') {
                        return `${filter.path} != null${index === array.length - 1 ? ';' : ''}`;
                    } else {
                        return `${filter.path} ${filter.operand} ${
                            isNaN(filter.value) || filter.path.includes('metadata')
                                ? `"${entry}"`
                                : entry
                        }`;
                    }
                })
                .reduce(
                    (value, entry, index, array) =>
                        `${index === 0 && array.length > 1 ? '(' : ''}${value}${entry}${
                            index < array.length - 1
                                ? ` ${filter.operand === '!=' ? 'and' : 'or'} `
                                : ''
                        }${index === array.length - 1 && array.length > 1 ? ')' : ''}`,
                    '',
                );
        })
        .reduce(
            (result, filter, index, array) =>
                `${result}${filter}${index < array.length - 1 ? ' AND ' : ';'}`,
            '',
        );
};

// Turns a metric object into its POlang form
export const computeMetricFormula = (chartBuilder: $ChartBuilderState, metric: $Metric): string => {
    return `${metric.type}{path:${metric.path}${
        chartBuilder.selectedMetric.unit === 'amount' && chartBuilder.displayInLocalCurrency
            ? '_local'
            : ''
    }; default: 0;${computeFilterString(metric.filters)}}`;
};

// Turns a general formula and an array of metrics into its POlang form
export const computeMergedFormula = (
    chartBuilder: $ChartBuilderState,
    generalFormula: string,
    currentFormula: string,
    metrics: Array<$Metric>,
) => {
    if (!generalFormula) return currentFormula;
    const currentChar = generalFormula.toUpperCase().charCodeAt(0);
    if (currentChar >= 65 && currentChar < 91) {
        const metric = metrics.find(
            m => m.name.localeCompare(String.fromCharCode(currentChar)) === 0,
        );
        if (!metric)
            return `${currentFormula}${computeMergedFormula(
                chartBuilder,
                generalFormula.slice(1),
                String.fromCharCode(currentChar),
                metrics,
            )}`;
        const formula = computeMetricFormula(chartBuilder, metric);
        return `${currentFormula}${computeMergedFormula(
            chartBuilder,
            generalFormula.slice(1),
            `${formula}`,
            metrics,
        )}`;
    } else {
        return `${currentFormula}${computeMergedFormula(
            chartBuilder,
            generalFormula.slice(1),
            String.fromCharCode(currentChar),
            metrics,
        )}`;
    }
};

export const mergeFilters = (
    filtersA: Array<$Filter>,
    filtersB: Array<$Filter>,
): Array<$Filter> => {
    const newFiltersA = filtersA.slice(0);
    const newFiltersB = filtersB.slice(0);

    for (const filter of newFiltersB) {
        newFiltersA.push(filter);
    }

    return newFiltersA;
};

export const groupBy = (key: string) => (array: Array<any>) =>
    array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});
