// @flow

import { takeLatest, put, call, select, take } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import type { $Chart, $FetchParams } from '../Boards/consts';
import { REQUEST_CHART_FETCH, REQUEST_FORMULA_COMPILE } from './actions';
import * as ProcessOut from '../../../util/ProcessOut';
import type {
    $CompiledFormula,
    $CompiledSelectors,
    $ConditionNode,
    $FormulaPlottingInfo,
    $FormulaVariable,
    $SimpleCompiledFormula,
} from './FormulaCompiler/consts';
import type { $Metric } from './ChartBuilder/consts';
import uniqid from 'uniqid';
import type { $BuilderMetric, $ChartBuilderState } from './ChartBuilder/reducer';
import { APPLY_PRESET_CHART_BUILDER } from './sagas';
import type { $Dimension } from './ChartBuilder/DimensionSelection/consts';
import * as ASTUtils from './ASTUtils';
import type { $Filter } from './ChartBuilder/Filters/consts';
import { replace } from 'react-router-redux';
import * as DataExplorerActions from './actions';
import { formatFormula } from '../ChartPreviewer/BuilderEffects/actions';
import { COUNT, SUM } from '../consts.js/consts';
import * as DataExplorerActios from './actions';
import * as BoardActions from '../Boards/Board/actions';
import { UPDATE_CHART_BUILDER_DETAILS } from './consts';
import { extractCommonFilters } from './ASTUtils';
import { detectPresetMetric } from './ASTUtils';
import { splitFilters } from './ASTUtils';
import { mergeCommonFilters } from './ASTUtils';

function* fetchCompiledFormula(chart: $Chart): Generator<*, *, *> {
    const compiledResult = yield ProcessOut.APIcallPromise(
        `/boards/${chart.board_id}/charts/${chart.id}/compile`,
        'POST',
        null,
        null,
        false,
        true,
    );
    if (compiledResult.data.formula_compiled.evaluation)
        return compiledResult.data.formula_compiled.evaluation;
    else if (compiledResult.data.formula_compiled.comparator)
        return compiledResult.data.formula_compiled.comparator;
}

export function computeMetricFilters(variable: $FormulaVariable): Array<$Filter> {
    let filters: ?Array<$Filter>;
    if (variable.compiled_condition) {
        return ASTUtils.mergeTwoNodes(variable.compiled_condition) || [];
    }
    return [];
}

// Converts a compiled variable to a Metric used in the chart builder state
function variableToMetric(variable: $FormulaVariable, name: string): ?$Metric {
    let filters: ?Array<$Filter>;
    if (variable.compiled_condition) {
        filters = ASTUtils.mergeTwoNodes(variable.compiled_condition);
        if (!filters) return null;
    }
    return {
        id: uniqid(),
        filters: mergeCompiledFilters(filters || []),
        path: variable.path.replace('_local', ''),
        display_in_local_currency: variable.path.includes('_local'),
        type: variable.type,
        name,
    };
}

// Converts a compiled plotting info to a Dimension used in chart builder state
function plottingInfoToDimension(plottingInfo: $FormulaPlottingInfo): $Dimension {
    return {
        id: uniqid(),
        field: plottingInfo.field,
        top: plottingInfo.top,
        strategy: plottingInfo.strategy,
        formula: 'count{path: transactions; default: 0;}',
    };
}

// Takes an array of compiled filters and merge filters that can be
function mergeCompiledFilters(filters: Array<$Filter>): Array<$Filter> {
    const newArray: Array<$Filter> = [];
    const baseFilters = filters.slice();

    while (baseFilters.length > 0) {
        const currentFilter = baseFilters[0];
        for (let i = 1; i < baseFilters.length; i++) {
            // for each other filter we check if it can be merged with the current one
            const f = baseFilters[i];
            if (f.path !== currentFilter.path || f.operand !== currentFilter.operand) {
                // Cannot be merged
                continue;
            }

            // can be merged
            currentFilter.value = currentFilter.value.concat(f.value);

            // remove second filter from baseFilters
            baseFilters.splice(i, 1);
        }

        // add currentFilter to the new array
        newArray.push(currentFilter);

        // remove currentFilter
        baseFilters.splice(0, 1);
    }

    return newArray;
}

export type $RequestCompileFormulaToBuilderState = {
    type: 'REQUEST_FORMULA_COMPILE',
    payload: {
        chartId: string,
        project: string,
        board: string,
        params: $FetchParams,
    },
};

function* setChartBuilderDetails(chart: $Chart): Generator<*, *, *> {
    yield put({
        type: UPDATE_CHART_BUILDER_DETAILS,
        payload: {
            type: chart.type,
            unit: chart.unit,
            name: chart.name,
            size: chart.size,
        },
    });
}

// Takes a compiled formula and converts it to a complete usable chart builder state
function* compileFormulaToBuilderState(
    action: $RequestCompileFormulaToBuilderState,
): Generator<*, *, *> {
    const { chartId, project, board, params } = action.payload;

    // We retrieve boardDetails
    const boardDetails = yield select(store => store.analytics_v2.boardDetails);
    const charts: Array<$Chart> = boardDetails.board.charts.slice();

    // We retrieve the chart details
    const chart = charts.find(c => c.id === chartId);
    if (!chart) {
        throw new Error(`chart-edition: The chart id ${chartId} could not be found`);
    }

    try {
        // We first fetch the compiled formula
        const compiledResult: $CompiledFormula = yield call(fetchCompiledFormula, chart);

        let variablesNames: Array<string>;
        let variables: Array<$FormulaVariable>;
        let formula: string;

        // We check if we are on a comparison
        if (compiledResult.selectors) {
            // We are on a comparison case
            variablesNames = Object.keys(compiledResult.templates.default.evaluation.variables);
            variables = variablesNames.map(
                v => compiledResult.templates.default.evaluation.variables[v],
            );
            formula = compiledResult.templates.default.evaluation.formula;
        } else {
            // We are on a non-comparison case
            variablesNames = Object.keys(compiledResult.variables);
            variables = variablesNames.map(v => compiledResult.variables[v]);
            formula = compiledResult.formula;
        }

        // We reconstruct the general formula
        for (let i = 0; i < variablesNames.length; i++) {
            formula = formula.replace(variablesNames[i].toLocaleLowerCase(), getCharAtPos(i));
        }

        const commonFilters: Array<$Filter> = [];
        // We first etract common filters from all metrics
        const variablesArrayFilters: Array<Array<$Filter>> = [];
        for (let i = 0; i < variables.length; i++) {
            variablesArrayFilters.push(computeMetricFilters(variables[i]));
        }

        // Reconstruct dimensions
        let dimensions: Array<$Dimension> = [];
        if (compiledResult.selectors) {
            for (const selector of compiledResult.selectors) {
                if (selector.evaluation.variables) {
                    const variables = Object.keys(selector.evaluation.variables).map(key => {
                        return { name: key, value: selector.evaluation.variables[key] };
                    });
                    for (const variable of variables) {
                        variablesArrayFilters.push(computeMetricFilters(variable.value));
                    }
                }
                dimensions.push(plottingInfoToDimension(selector.plotting_info));
            }
            if (compiledResult.templates.default.plotting_info)
                dimensions.push(
                    plottingInfoToDimension(compiledResult.templates.default.plotting_info),
                );
        }
        if (compiledResult.plotting_info)
            dimensions.push(plottingInfoToDimension(compiledResult.plotting_info));

        // We reverse the dimensions array
        dimensions = dimensions.reverse();

        // Reconstruct metrics
        const metrics: Array<$Metric> = [];

        // Then we rebuild every metrics
        for (let i = 0; i < variables.length; i++) {
            const metric = variableToMetric(variables[i], getCharAtPos(i));
            if (!metric) {
                throw new Error('Unssuported metric');
            }
            metrics.push(metric);
        }

        const selectedMetric: ?$BuilderMetric = detectPresetMetric(metrics, formula);
        if (!selectedMetric) {
            yield put(
                replace(
                    `/projects/${project}/boards/${chart.board_id}/new-chart/editor?chart=${chart.id}`,
                ),
            );
            throw new Error('Selected metric not found');
        }

        const selectedMetricFiltersArray = selectedMetric.metrics.map(m => m.filters);
        const selectedMetricFilters: Array<$Filter> = selectedMetricFiltersArray.reduce(
            (acc, cur) => acc.concat(cur),
            [],
        );
        const variablesFilters: Array<$Filter> = variablesArrayFilters.reduce(
            (acc, cur) => acc.concat(cur),
            [],
        );
        const resultFilters: Array<$Filter> = [];

        const variablesFiltersSplit = splitFilters(variablesFilters);
        const selectedFiltersSplit = splitFilters(selectedMetricFilters);

        for (const filter of variablesFiltersSplit) {
            if (
                selectedFiltersSplit.find(
                    f =>
                        f.path === filter.path &&
                        f.operand === filter.operand &&
                        ((filter.value.length === 0 && f.value.length === 0) ||
                            f.value.includes(filter.value[0])),
                )
            ) {
                // skip
            } else {
                resultFilters.push(filter);
            }
        }

        // Check if we are in NET or RAW metrics
        let displayNetMetrics =
            resultFilters.filter(f => f.path === 'duplicate_distance_seconds') !== null;
        if (displayNetMetrics) {
            let index = resultFilters.findIndex(f => f.path === 'duplicate_distance_seconds');
            while (index > -1) {
                resultFilters.splice(index, 1);
                index = resultFilters.findIndex(f => f.path === 'duplicate_distance_seconds');
            }
        }

        // Generate the compiled chart builder state
        const chartBuilderState: $ChartBuilderState = {
            displayInLocalCurrency: metrics.reduce(
                (acc, cur) => acc && cur.path.includes('_local'),
                true,
            ),
            filters: mergeCommonFilters(resultFilters),
            type: chart.type,
            selectedMetric: selectedMetric,
            dimensions,
            metrics,
            canEditIfChartIdExists: true,
            displayNetMetrics,
        };

        // Dispatch that the UI compile has been done
        yield put({
            type: ProcessOut.typeFulfilled(REQUEST_FORMULA_COMPILE),
            payload: chartBuilderState,
        });

        // Dispatch the compiled chart builder state
        yield put({
            type: APPLY_PRESET_CHART_BUILDER,
            payload: chartBuilderState,
        });

        // Set the correct name, unit, type...
        yield call(setChartBuilderDetails, chart);

        // Re-fetch the chart preview with correct params
        const params: $FetchParams = yield select(store => store.analytics.params);
        yield put({
            type: REQUEST_CHART_FETCH,
            payload: {
                name: chart.name,
                formula: chart.settings.formula,
                type: chart.type,
                unit: chart.unit,
                params,
            },
        });
    } catch (error) {
        // Something went wrong while compiling the UI, we redirect towards the formula editor
        yield put({ type: ProcessOut.typeFailed(REQUEST_FORMULA_COMPILE), payload: { error } });
        yield put(
            replace(`/projects/${project}/boards/${board}/new-chart/editor?chart=${chartId}`),
        );
        yield put(DataExplorerActions.updateFormula(chart.settings.formula));
        // Set the correct name, unit, type...
        yield call(setChartBuilderDetails, chart);
        Sentry.captureException(error);
    }
}

// Returns A + index char. ex: 0 returns A, 1 returns B etc
function getCharAtPos(index: number): string {
    return String.fromCharCode(65 + index);
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeLatest(REQUEST_FORMULA_COMPILE, compileFormulaToBuilderState);
}
