import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux';
import reducer from './Reducers/Index';
import { useRouterHistory, browserHistory } from 'react-router';
import { createHashHistory } from 'history';
import { createTracker, EventTypes } from 'redux-segment';
import createSagaMiddleware from 'redux-saga';

import Sagas from '../sagas';

const customMapper = {
    mapper: {
        '@@router/CALL_HISTORY_LOCATION': EventTypes.page,
        '@@router/LOCATION_CHANGE': (getState, action) => {
            window.Intercom('trackEvent', 'Visited page', {
                page_name: location.hash.split('/').pop(),
            });
            return {
                eventType: EventTypes.page,
                eventPayload: {
                    properties: {
                        path: action.payload.pathname,
                    },
                },
            };
        },
    },
};
const tracker = createTracker(customMapper);

const errorHandler = store => next => action => {
    if (!action.payload) return next(action);
    if (action.payload.error) {
        action.type = action.type.replace('_FULFILLED', '_REJECTED');
    }
    if (action.payload.status) {
        // The action is an HTTP call. We filter the action payload to keep only the JSON
        if (action.payload.status === 412) {
            //Two factor is needed.
            action.payload.waiting_for_two_factor = true;
        }
        action.payload = action.payload.data || action.payload;
    }

    return next(action);
};

const sagaMiddleware = createSagaMiddleware();
let middleware;
let his;
let composeEnhancers;
if (process.env.NODE_ENV !== 'TEST') {
    his = browserHistory;
    const logger = createLogger({
        collapsed: true,
    });
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    if (process.env.LOCAL) {
        middleware = applyMiddleware(
            promise(),
            thunk,
            errorHandler,
            logger,
            routerMiddleware(his),
            tracker,
            sagaMiddleware,
        );
    } else {
        middleware = applyMiddleware(
            promise(),
            thunk,
            errorHandler,
            routerMiddleware(his),
            tracker,
            sagaMiddleware,
        );
    }
} else {
    composeEnhancers = compose;
    middleware = applyMiddleware(promise(), thunk, errorHandler, sagaMiddleware);
}
export const store = createStore(reducer, composeEnhancers(middleware));
store.asyncReducers = {};
export const history = process.env.NODE_ENV === 'TEST' ? null : syncHistoryWithStore(his, store);

// Start listening for Sagas
sagaMiddleware.run(Sagas);
