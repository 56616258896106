// @flow

import React from 'react';
import { Link } from 'react-router';
import * as ProcessOut from '../util/ProcessOut';
import { FormattedNumber, ReactIntl } from 'react-intl';
import type { Customer } from '../Types/Customer';
import { connect } from 'react-redux';
import type { $Project } from '../util/Types';

const md5 = require('js-md5');

class CustomerComponent extends React.Component {
    props: {
        customer: Customer,
        currentProject: {
            project: $Project,
        },
    };

    constructor() {
        super();
    }

    render() {
        const customer = this.props.customer;

        return (
            <Link to={'/projects/' + ProcessOut.ProjectId + '/customers/' + customer.id}>
                <div class='box-row padding row'>
                    <div class='medium-1 columns'>
                        <img
                            class='gravatar-icon'
                            src={
                                'https://secure.gravatar.com/avatar/' +
                                md5(customer.email ? customer.email : '') +
                                '?s=80&r=g&d=identicon'
                            }
                        />
                    </div>
                    <div class='medium-11 columns'>
                        {customer.first_name ? customer.first_name : ''}{' '}
                        {customer.last_name ? customer.last_name : ''}
                        {!customer.last_name && !customer.first_name ? 'Anonymous user' : ''}
                        <br />
                        <span class='greyed'>
                            {customer.email ? customer.email : 'No email provided'}
                        </span>
                    </div>
                </div>
            </Link>
        );
    }
}

export default connect(store => {
    return { currentProject: store.currentProject };
})(CustomerComponent);
