import React from 'react';
import Intercom from '../features/Intercom';

export default class AppLayout extends React.Component {
    props: {
        children: any,
    };

    render() {
        return (
            <div style={{ height: '100%' }}>
                {this.props.children}
                <Intercom />
            </div>
        );
    }
}
