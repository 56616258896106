import React from 'react';
import { ReactIntl } from 'react-intl';
import * as CurrencyAction from '../Actions/CurrencyActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

var md5 = require('js-md5');

@connect(function(store) {
    return {
        currenciesDetails: store.currencies,
    };
})
export default class CurrencyField extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        defaultValue: PropTypes.string,
        name: PropTypes.string,
    };

    constructor() {
        super();
        this.state = {
            currency: 'USD',
        };
    }

    componentDidMount() {
        if (this.props.currenciesDetails.fetching || !this.props.currenciesDetails.fetched) {
            this.props.dispatch(CurrencyAction.loadCurrencies());
        }

        if (this.props.defaultValue) {
            this.setState({ currency: this.props.defaultValue });
        }
    }

    currencyChanged() {
        var s = document.querySelector('#item-currency');
        var cur = s.options[s.selectedIndex].value;
        this.setState({ currency: cur });

        if (this.props.onChange) {
            this.props.onChange(cur);
        }
    }

    render() {
        if (this.props.currenciesDetails.fetching || !this.props.currenciesDetails.fetched) {
            return null;
        }

        var currencies = this.props.currenciesDetails.currencies;

        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <label class='greyed'>Currency</label>
                    <select
                        name={this.props.name ? this.props.name : 'currency'}
                        class='no-margin bottom-border'
                        disabled={this.props.disabled}
                        id='item-currency'
                        value={this.state.currency}
                        onChange={this.currencyChanged.bind(this)}
                    >
                        {currencies.map(function(cur) {
                            return (
                                <option key={cur.code} name={cur.code}>
                                    {cur.code}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        );
    }
}
