// @flow

import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { REQUEST_EXPORT_CREATION, REQUEST_EXPORT_DOWNLOAD, REQUEST_EXPORTS_FETCH } from './actions';
import type { $RequestExportFetchAction } from './actions';
import * as ProcessOut from '../../util/ProcessOut';
import * as Sentry from '@sentry/browser/dist/index';
import type { $ExportState } from './reducer';
import type { $RequestExportDownload } from './actions';
import Project from '../../util/Project';
import type { $SearchBarState } from '../SearchBar/reducer';

function* fetchTransactionsExports(action: $RequestExportFetchAction): Generator<*, *, *> {
    try {
        let { after, id } = action.payload;

        const bound = after ? 'start_after' : 'end_before';
        if (!id) {
            id = '';
        }
        const exportsResults = yield call(
            ProcessOut.APIcallPromise,
            `/exports?${bound}=${id}&limit=${10}&order=desc`,
            'GET',
        );
        yield put({
            type: ProcessOut.typeFulfilled(REQUEST_EXPORTS_FETCH),
            payload: exportsResults.data,
        });
    } catch (error) {
        Sentry.captureException(error);
        yield put({
            type: ProcessOut.typeFailed(REQUEST_EXPORTS_FETCH),
            payload: error,
        });
    }
}

function* createTransactionsExport(): Generator<*, *, *> {
    try {
        const exportsDetails: $ExportState = yield select(store => store.transactionsExports);
        const currentProject = yield select(store => store.currentProject);
        const searchBarDetails: $SearchBarState = yield select(store => store.searchBar);
        const project: Project = new Project(currentProject.project.id);

        yield call(ProcessOut.APIcallPromise, '/exports', 'POST', {
            format: exportsDetails.form.format,
            description: exportsDetails.form.description,
            columns: exportsDetails.form.columns,
            start_at: exportsDetails.form.start_at,
            end_at: exportsDetails.form.end_at,
            notify_email_when_processed: exportsDetails.form.email,
            type: exportsDetails.form.type,
            filter:
                exportsDetails.form.type === 'transaction'
                    ? searchBarDetails.filter
                    : exportsDetails.form.type === 'payout-item'
                    ? exportsDetails.form.payout_id
                    : null,
        });
        yield put({ type: 'CLOSE_MODAL' });
        yield put(push(`/projects/${project._id}/reports/exports`));
    } catch (error) {
        Sentry.captureException(error);
        yield put({
            type: ProcessOut.typeFailed(REQUEST_EXPORT_CREATION),
            payload: error,
        });
    }
}

function* downloadExport(action: $RequestExportDownload): Generator<*, *, *> {
    try {
        // retrieve the download URL
        const urlResult = yield call(
            ProcessOut.APIcallPromise,
            `/exports/${action.payload.id}`,
            'GET',
        );

        if (!urlResult.data.download_url) {
            ProcessOut.addNotification(
                'This report is not ready yet. Please try again later',
                'error',
            );
        } else {
            window.open(urlResult.data.download_url);
        }
    } catch (error) {
        Sentry.captureException(error);
        yield put({ type: ProcessOut.typeFailed(REQUEST_EXPORT_DOWNLOAD) });
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeLatest(REQUEST_EXPORTS_FETCH, fetchTransactionsExports);
    yield takeLatest(REQUEST_EXPORT_CREATION, createTransactionsExport);
    yield takeEvery(REQUEST_EXPORT_DOWNLOAD, downloadExport);
}
