export default function(oldQuery, newQuery) {
    var query = {
        ...oldQuery,
        ...newQuery,
    };
    var result = '?';
    Object.keys(query).map(function(key) {
        if (result === '?') result += `${key}=${query[key]}`;
        else result += `&${key}=${query[key]}`;
    });
    return result;
}
