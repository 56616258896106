// @flow

import React from 'react';
import type { $Filter } from './consts';
import Filter from './Filter';
import uniqid from 'uniqid';

type Props = {
    filters: Array<$Filter>,
    onChange: (Array<$Filter>) => void,
};

class Filters extends React.Component<Props> {
    filterChanged = (filter: $Filter) => {
        const { filters, onChange } = this.props;
        const newFilters = filters.slice(0);
        const index = newFilters.findIndex(f => f.id === filter.id);
        if (index < 0) {
            newFilters.push({
                id: uniqid(),
                ...filter,
                operand: '==',
                value: [],
            });
        } else {
            newFilters[index] = filter;
        }
        onChange(newFilters);
    };

    deleteFilter = (filter: $Filter) => {
        const { onChange, filters } = this.props;
        const newFilters = filters.slice(0);
        const index = newFilters.findIndex(f => f.id === filter.id);
        if (index < 0) return;
        newFilters.splice(index, 1);
        onChange(newFilters);
    };

    render() {
        const { filters } = this.props;
        return [
            filters.map((filter, index) => (
                <Filter
                    key={filter.id || uniqid()}
                    prefixLabel={index === 0 ? 'Where' : 'and'}
                    filter={filter}
                    onChange={this.filterChanged}
                    onDelete={this.deleteFilter}
                />
            )),
            <Filter
                prefixLabel={filters.length === 0 ? 'Where' : 'and'}
                filter={null}
                onChange={this.filterChanged}
                onDelete={() => {}}
            />,
        ];
    }
}

export default Filters;
