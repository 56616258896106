// @flow

import React from 'react';
import { Link } from 'react-router';
import Auth from '../../util/Auth';
import * as UserActions from '../../Actions/UserActions';
import UserStore from '../../stores/UserStore';
import ErrorStore from '../../stores/ErrorStore';
import NotificationStore from '../../stores/NotificationStore';
import * as ProcessOut from '../../util/ProcessOut';
import { connect } from 'react-redux';
import * as Store from '../../stores/Store';
import PropTypes from 'prop-types';
import type { User } from '../../Types/User';
import SandboxSwitch from '../../features/SandboxSwitch/index';
import * as Sentry from '@sentry/browser';
import RatingWidget from '../../features/RatingWidget/RatingWidget';
import { withRouter } from 'react-router';
import type { $Dispatcher } from '../../util/Types';

const md5 = require('js-md5');

declare function Intercom(command: string): void;

class Header extends React.Component {
    props: {
        redirectToWebsite: boolean,
        location: {
            pathname: string,
        },
    } & $Dispatcher;

    state: {
        sandbox: boolean,
        user: ?User,
    };

    _unsubscribeLoginWatcher: ?() => void = null;
    displayNotification: () => void;
    getDetails: () => void;
    displayError: () => void;

    constructor() {
        super();
        this.state = {
            sandbox: ProcessOut.sandbox,
            user: null,
        };
    }

    componentWillMount() {
        this.displayNotification = this.displayNotification.bind(this);
        this.getDetails = this.getDetails.bind(this);
        this.displayError = this.displayError.bind(this);
        ErrorStore.on('error', this.displayError);
        UserStore.on('change', this.getDetails);
        NotificationStore.on('notification', this.displayNotification);
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        if (this.props.user.auth.loggedIn) {
            this.props.dispatch(UserActions.loadUser2());
        }
        this._unsubscribeLoginWatcher = Store.store.subscribe(this.updateRavenUser);
    }

    componentWillReceiveProps(nextProps: Object) {
        if (
            nextProps.user.auth.loggedIn &&
            (!nextProps.user.details.fetching && !nextProps.user.details.fetched)
        ) {
            this.props.dispatch(UserActions.loadUser2());
        }
    }

    updateRavenUser() {
        let state = Store.store.getState();
        if (state.user.auth.loggedIn) {
            Sentry.configureScope(scope => {
                scope.setUser({ email: state.user.details.email });
            });
        } else {
            Sentry.configureScope(scope => {
                scope.setUser({ email: null });
            });
        }
    }

    displayNotification() {
        let data = NotificationStore.getNotification();
    }

    componentWillUnmount() {
        UserStore.removeListener('change', this.getDetails);
        NotificationStore.removeListener('notification', this.displayNotification);
        ErrorStore.removeListener('error', this.displayError);
        if (this._unsubscribeLoginWatcher) this._unsubscribeLoginWatcher();
    }

    displayError() {
        let error = ErrorStore.getError();
        ProcessOut.addNotification.bind(this)(error, 'error');
    }

    getDetails() {
        let details = UserStore.getAll();
        this.setState({ user: details.user });
    }

    openIntercom() {
        Intercom('show');
    }

    logout() {
        Auth.logout();
    }

    render() {
        const user = this.props.user.details;

        let name = '';
        let email = '';
        if (user.fetched) {
            name = user.name;
            email = user.email;
        }

        let logoLink = <Link to={'/projects'}>ProcessOut</Link>;
        if (this.props.redirectToWebsite) {
            logoLink = <a href="https://www.processout.com">ProcessOut</a>;
        }

        if (window.ProcessOutConfig && window.ProcessOutConfig.logoUrl) {
            logoLink = (
                <Link to={'/projects'}>
                    <img
                        alt={window.ProcessOutConfig.company}
                        style={{ height: window.ProcessOutConfig.logoHeight }}
                        src={window.ProcessOutConfig.logoUrl}
                    />
                </Link>
            );
        }

        let loggedout;
        if (!Auth.isLogged()) {
            loggedout = (
                <ul class="menu">
                    <li>
                        <a href="#">Help &amp; support</a>
                    </li>
                    <li>
                        <Link
                            to="/login"
                            class="white round border button"
                            style={{
                                marginLeft: '15px',
                                marginRight: '15px',
                            }}
                        >
                            Sign in
                        </Link>
                    </li>
                </ul>
            );
        } else {
            loggedout = (
                <ul class="menu">
                    <li>
                        <RatingWidget pageName={this.props.location.pathname} />
                    </li>
                    <li>
                        <Link to="/user">{name}</Link>
                    </li>
                    <li>
                        <img
                            class="gravatar-icon"
                            src={
                                'https://secure.gravatar.com/avatar/' +
                                md5(email) +
                                '?s=80&r=g&d=identicon'
                            }
                        />
                    </li>
                    <li>
                        <Link to="" onClick={this.logout.bind(this)}>
                            sign out
                        </Link>
                    </li>
                </ul>
            );
        }

        let leftBar = (
            <ul class="menu">
                <li class="menu-title">{logoLink}</li>
                <li>
                    <a target="_blank" href="https://docs.processout.com">
                        Documentation ↗
                    </a>
                </li>
                <li>
                    <SandboxSwitch />
                </li>
            </ul>
        );
        if (!Auth.isLogged()) {
            leftBar = (
                <ul class="menu">
                    <li class="menu-title">{logoLink}</li>
                    <li>
                        <a target="_blank" href="https://docs.processout.com">
                            Documentation ↗
                        </a>
                    </li>
                </ul>
            );
        }

        return (
            <div>
                <div class="top-bar" id="main-menu">
                    <div class="top-bar-left">{leftBar}</div>
                    <div class="top-bar-right">{loggedout}</div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { user: store.user };
})(withRouter(Header));
