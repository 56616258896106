export default function(state = { fetched: false, fetching: false }, action) {
    switch (action.type) {
        case 'FETCH_COUNTRIES_PENDING': {
            state = {
                ...state,
                fetched: false,
                fetching: true,
            };
            break;
        }

        case 'FETCH_COUNTRIES_FULFILLED': {
            state = {
                ...state,
                ...action.payload,
                fetched: true,
                fetching: false,
            };
            break;
        }
    }

    return state;
}
