// @flow

import React from "react";
import * as Actions from "../actions";

type Props = {
    onChange: (displayInLocal: boolean) => void,
    displayInLocal: boolean,
};

class LocalSwitch extends React.Component<Props> {
    render() {
        const { onChange, displayInLocal } = this.props;
        return (
            <div class="row small-margin-bottom">
                <div class="large-12 columns">
                    <div class="switch-container">
                        <label class="local-switch">
                            <input
                                type="checkbox"
                                onChange={event => {
                                    onChange(!displayInLocal);
                                }}
                                checked={displayInLocal}
                            />
                            <span class="local-slider round" />
                        </label>
                        Display amount in your project's currency
                    </div>
                </div>
            </div>
        );
    }
}

export default LocalSwitch;
