// @flow
import React from 'react';

type Props = {
    title: any,
    content: ?any,
    children?: ?any,
};

class Section extends React.PureComponent<Props> {
    render() {
        const { title, content } = this.props;
        return (
            <div class='row' style={{ paddingLeft: '10px' }}>
                <div class='large-12 columns'>
                    <div class='row'>
                        <div class='medium-4 columns text-left'>
                            <h4>{title}</h4>
                        </div>
                        <div class='medium-8 columns text-right'>{content}</div>
                    </div>
                    <div class='row'>
                        <div class='large-12 columns'>{this.props.children}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Section;
