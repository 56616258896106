// @flow
/**
 * Created by jeremylejoux on 07/06/17.
 */

import { combineReducers } from 'redux';
import KeysReducer from './KeysReducer';

export default combineReducers({
    keys: KeysReducer,
});
