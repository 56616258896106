// @flow

import { takeEvery, put, select } from 'redux-saga/effects';
import { REGISTER_RATING } from './actions';
import * as ProcessOut from '../../util/ProcessOut';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import type { $Action } from '../../util/Types';

export type $Rating = {
    rating: number,
    message: string,
    page: string,
    email: ?string,
};
function* registerRating(action: $Action): Generator<*, *, *> {
    const { payload } = action;
    if (!payload) return;
    try {
        const rating: $Rating = payload;

        const user = yield select(store => store.user.details);
        rating.email = user.email;

        yield axios.post('https://processout-slackgment.now.sh/feedback', rating);

        if (payload.resolve) payload.resolve(true);
    } catch (error) {
        yield put(ProcessOut.typeFailed(REGISTER_RATING));
        if (payload.resolve) payload.resolve(false);
        Sentry.captureException(error);
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeEvery(REGISTER_RATING, registerRating);
}
