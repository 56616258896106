// @flow
import React from 'react';
import { connect } from 'react-redux';
import Partial from './Partial';
import PanelNumber from '../Components/PanelNumber';
import type { $TelescopeState } from '../../../stores/Reducers/TelescopeReducer/Index';
import type { $Project } from '../../../util/Types';
import Warning from '../Components/Warning';

type Props = {
    telescope: $TelescopeState,
    currentProject: {
        project: $Project,
    },
};

class Captures extends React.Component<Props> {
    render() {
        const { data } = this.props.telescope.standard;
        const currency = this.props.currentProject.project.default_currency || 'USD';
        return (
            <Partial
                leftPanel={
                    <div class='row'>
                        <div class='large-6 columns'>
                            <PanelNumber
                                number={data.captured_amount}
                                name='Captured'
                                style='currency'
                                currency={currency}
                            />
                        </div>
                        <div class='large-6 columns text-right'>
                            <PanelNumber
                                number={data.transactions_captured}
                                name='Captured transactions'
                            />
                        </div>
                    </div>
                }
                rightPanel={
                    <div class='row'>
                        <div class='large-6 columns'>
                            <PanelNumber
                                number={data.gateway_fees}
                                style='currency'
                                currency={currency}
                                name='Fees'
                                warning={
                                    !data.gateway_fees && data.transactions_captured
                                        ? 'Fee data is missing'
                                        : null
                                }
                            />
                        </div>
                        <div class='large-6 columns text-right'>
                            <PanelNumber
                                number={
                                    data.telescope
                                        ? data.telescope.fees_status.volume_impacted
                                        : null
                                }
                                name='Overpaid in fees'
                                style='currency'
                                currency={currency}
                                warning={
                                    !data.gateway_fees && data.transactions_captured
                                        ? 'Fee data is missing'
                                        : null
                                }
                            />
                        </div>
                    </div>
                }
            />
        );
    }
}

export default connect(store => {
    return {
        telescope: store.telescope,
        currentProject: store.currentProject,
    };
})(Captures);
