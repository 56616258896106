import React from 'react';
import { Link } from 'react-router';
import AuthStore from '../stores/AuthStore';
import Header from '../components/layout/Header';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import * as UserActions from '../Actions/UserActions';
import NotificationCenter from '../features/NotificationBar/NotificationCenter';
import * as Store from '../stores/Store';
import Background from '../features/Background/Background';

var serialize = require('form-serialize');

@connect(function(store) {
    return {
        user: store.user,
    };
})
export default class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            twoFactorNeeded: false,
        };
    }

    componentWillMount() {
        document.title = 'ProcessOut | Login';
        this.loggedIn = this.loggedIn.bind(this);
        this.twoFactor = this.twoFactor.bind(this);
        AuthStore.on('two-factor', this.twoFactor);
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        this.unsubscribeLoginWatcher = Store.store.subscribe(this.loggedIn);
    }

    componentWillUnmount() {
        AuthStore.removeListener('two-factor', this.twoFactor);
    }

    loginClicked(event) {
        event.preventDefault();
        var loginForm = document.querySelector('#login-form');
        var login = serialize(loginForm, { hash: true });
        this.props.dispatch(
            UserActions.generateToken(login.email, login.password, login.two_step_auth_code),
        );
    }

    twoFactor() {
        this.setState({ twoFactorNeeded: true, fetching: false });
    }

    loggedIn() {
        var nextState = Store.store.getState();
        if (nextState.user.auth.loggedIn) {
            this.unsubscribeLoginWatcher();
            this.props.dispatch(push('/projects'));
        }
    }

    redirectToSignup = (event: Event) => {
        this.refs.signPopup.className = this.refs.signPopup.className.replace(
            'loaded',
            'disappear',
        );
        setTimeout(() => {
            this.props.dispatch(push('/register'));
        }, 200);
    };

    render() {
        var twoFactorCode;
        if (this.props.user.auth.waiting_for_two_factor) {
            twoFactorCode = (
                <div class='row margin-bottom'>
                    <div class='large-10 large-centered columns'>
                        <input
                            type='text'
                            class='bottom-border'
                            name='two_step_auth_code'
                            placeholder='Two step code'
                            hidden='true'
                            required
                        />
                    </div>
                </div>
            );
        }

        var button = <input type='submit' class='round border button expanded' value='SIGN IN' />;
        if (this.props.user.auth.fetching) {
            button = (
                <a class='round border button expanded'>
                    <div class='loading-spinner' />
                </a>
            );
        }

        return (
            <div class='colorful main-wrapper'>
                <Background />
                <Header redirectToWebsite={true} />
                <div class='row huge-margin-top'>
                    <form id='login-form' onSubmit={this.loginClicked.bind(this)}>
                        <div class='large-12 columns'>
                            <div class='row'>
                                <div class='large-4 medium-4 small-centered columns'>
                                    <NotificationCenter />
                                </div>
                            </div>
                            <div ref='signPopup' class='row signup-popup loaded'>
                                <div class='large-4 medium-4 small-centered columns'>
                                    <div class='normal-content rounded shadowed'>
                                        <div class='row margin-bottom'>
                                            <div class='large-12 columns centered'>
                                                <h3 class='title no-spacing'>Sign In</h3>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-10 medium-11 medium-centered columns'>
                                                <input
                                                    type='text'
                                                    name='email'
                                                    class='bottom-border'
                                                    placeholder='Email'
                                                />
                                            </div>
                                        </div>
                                        <div class='row small-margin-bottom'>
                                            <div class='large-10 medium-11 medium-centered columns'>
                                                <input
                                                    type='password'
                                                    name='password'
                                                    class='bottom-border'
                                                    placeholder='Password'
                                                />
                                            </div>
                                        </div>
                                        {twoFactorCode}
                                        <div class='row large-margin-bottom'>
                                            <div class='large-10 medium-11 medium-centered columns small-font text-right margin-bottom'>
                                                <Link
                                                    to='/reminder'
                                                    style={{
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    Forgot your password ?
                                                </Link>
                                            </div>
                                        </div>
                                        <div class='row small-margin-bottom'>
                                            <div class='medium-5 columns medium-centered text-center'>
                                                {button}
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns centered margin-bottom text-center'>
                                                Don't have an account?{' '}
                                                <a
                                                    onClick={this.redirectToSignup}
                                                    style={{
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    Join us now!
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
