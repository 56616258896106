// @flow

import React from 'react';
import moment from 'moment';
import { abbreviateNumber } from './Utils';
import type { $ChartRenderer, $DataPoint } from '../Boards/consts';
import { FormattedNumber, IntlProvider } from 'react-intl';
import * as ReactDOMServer from 'react-dom/server';
import { COLORS } from './BarChart';
import getColor from '../Boards/charts/colors';
import Error from '../../../components/Error';
import UniqId from 'uniqid';

type Props = {
    keysMask?: ?Array<string>,
    colors: Array<{ key: string, color: string }>,
    error?: ?boolean,
} & $ChartRenderer;

type State = {
    id: number,
    chart: any,
};

export default class LineChart extends React.Component {
    props: Props;
    state: State;

    constructor() {
        super();
        this.state = { id: 0, chart: null };
    }

    componentWillMount() {
        this.setState({
            id: UniqId(),
        });
    }

    componentDidMount() {
        const { error } = this.props;
        if (!error) this.draw();
    }

    componentDidUpdate() {
        const { error } = this.props;
        if (!error) this.draw();
    }

    componentWillUnmount() {
        const { chart } = this.state;
        if (chart) chart.clear();
    }

    draw() {
        if (!this.props.data || !document.getElementById(`chart${this.state.id}`)) return;
        const data = this.props.data;

        if (!this.state.chart) {
            const shouldDisplayHours =
                (data[0].datapoints && data[0].datapoints.length < 2) ||
                moment(data[0].datapoints[0].key).hour() !==
                    moment(data[0].datapoints[1].key).hour();
            this.setState({
                chart: new Picasso.BarLineChart(`#chart${this.state.id}`, {
                    name: this.props.name,
                    linesTip: function(d) {
                        const lineKey = d[0].key;
                        const isDate = moment(lineKey, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid();
                        const date = moment(isDate ? lineKey : d[0].key).calendar(null, {
                            sameDay: shouldDisplayHours ? '[Today at] LT' : '[Today]',
                            nextDay: '[Tomorrow]',
                            nextWeek: 'dddd',
                            lastDay: shouldDisplayHours ? '[Yesterday at] LT' : '[Yesterday]',
                            lastWeek: shouldDisplayHours ? 'MMMM Do YYYY, h:mm a' : 'MMMM Do YYYY',
                            sameElse: shouldDisplayHours ? 'MMMM Do YYYY, h:mm a' : 'MMMM Do YYYY',
                        });
                        const tips = d
                            .map((point: $DataPoint, index: number) => {
                                return { ...point, color: getColor(index) };
                            })
                            .sort((a, b) => b._count - a._count)
                            .map((point: $DataPoint & { color: string }, index: number) => {
                                const currentColor = this.props.colors
                                    ? this.props.colors.find(entry => entry.key === point.lineName)
                                    : getColor(index);
                                switch (this.props.type) {
                                    case 'percentage': {
                                        return (
                                            <IntlProvider
                                                locale={
                                                    navigator
                                                        ? navigator.language || 'en-US'
                                                        : 'en-US'
                                                }
                                            >
                                                <div>
                                                    <FormattedNumber
                                                        value={point.value}
                                                        style={'percent'}
                                                        minimumFractionDigits={2}
                                                    />
                                                    <span
                                                        style={{
                                                            color: currentColor
                                                                ? currentColor.color
                                                                : point.color,
                                                        }}
                                                    >
                                                        {point.lineName !== 'single'
                                                            ? `- ${point.lineName}`
                                                            : ''}
                                                    </span>
                                                </div>
                                            </IntlProvider>
                                        );
                                    }

                                    case 'amount': {
                                        return (
                                            <IntlProvider
                                                locale={
                                                    navigator
                                                        ? navigator.language || 'en-US'
                                                        : 'en-US'
                                                }
                                            >
                                                <div>
                                                    <FormattedNumber
                                                        value={point.value}
                                                        style={'currency'}
                                                        currency={this.props.currency}
                                                        minimumFractionDigits={2}
                                                    />
                                                    <span
                                                        style={{
                                                            color: currentColor
                                                                ? currentColor.color
                                                                : point.color,
                                                        }}
                                                    >
                                                        {point.lineName !== 'single'
                                                            ? `- ${point.lineName}`
                                                            : ''}
                                                    </span>
                                                </div>
                                            </IntlProvider>
                                        );
                                    }

                                    default: {
                                        return (
                                            <IntlProvider
                                                locale={
                                                    navigator
                                                        ? navigator.language || 'en-US'
                                                        : 'en-US'
                                                }
                                            >
                                                <div>
                                                    <FormattedNumber
                                                        value={point.value}
                                                        maximumFractionDigits={2}
                                                    />
                                                    <span
                                                        style={{
                                                            color: currentColor
                                                                ? currentColor.color
                                                                : point.color,
                                                        }}
                                                    >
                                                        {point.lineName !== 'single'
                                                            ? `- ${point.lineName}`
                                                            : ` ${this.props.type}`}
                                                    </span>
                                                </div>
                                            </IntlProvider>
                                        );
                                    }
                                }
                            });
                        return ReactDOMServer.renderToString(
                            <div class='row'>
                                <div class='large-12 columns'>
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            <h5>{date}</h5>
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns'>{tips}</div>
                                    </div>
                                </div>
                            </div>,
                        );
                    }.bind(this),
                    yAxisFormatter: function(d) {
                        const suffix = this.props.type === 'percentage' ? '%' : '';
                        return `${abbreviateNumber(
                            this.props.type === 'percentage' ? Math.round(d * 100 * 100) / 100 : d,
                        )}${suffix}`;
                    }.bind(this),
                }),
            });
            return;
        }

        const lines = [];
        let colorCount = -1;
        for (const entry of data) {
            colorCount++;
            // we check that all the keys are valid dates
            let onlyDates = true;
            for (const point of entry.datapoints) {
                if (!moment(point.key, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid()) {
                    onlyDates = false;
                    break;
                }
            }
            if (onlyDates) {
                // We only have dates so we update each key to a Date object
                for (const point of entry.datapoints) {
                    point.key = new Date(point.key);
                }
            }

            if (this.props.keysMask && this.props.keysMask.includes(entry.key)) continue;

            const currentColor = this.props.colors
                ? this.props.colors.find(color => color.key === entry.key)
                : getColor(0);
            lines.push({
                data: entry.datapoints,
                color: currentColor ? currentColor.color : getColor(colorCount),
                name: entry.key,
                dotOutsideSize: 0,
                dotInsideSize: 0,
            });
        }

        const chart = this.state.chart;
        chart.reset();
        for (const line of lines) chart.addLine(line);

        chart.resetSVG();
        chart.draw();
    }

    render() {
        if (this.props.error) {
            return <Error text={'An error occured while loading this chart.'} />;
        }
        return (
            <svg
                class='chart line-chart'
                id={`chart${this.state.id}`}
                width='100%'
                height='200px'
            />
        );
    }
}
