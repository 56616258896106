// @flow

import React from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../../util/Types';
import { Link } from 'react-router';
import Error from '../../../components/Error';

type Props = {
    value: number,
    unit: string,
    currentProject: {
        project?: {
            id: string,
        },
    },
    correspondingFilter: ?string,
    error?: ?boolean,
} & $Dispatcher;

class SingleValue extends React.Component {
    props: Props;

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    filter = (event: Event) => {
        event.preventDefault();
        this.props.dispatch({
            type: 'ANALYTICS_FILTER_CHANGE',
            payload: { filter: this.props.correspondingFilter },
        });
    };

    render() {
        const { unit, currentProject, correspondingFilter } = this.props;

        if (!currentProject.project) return null;

        let content;
        switch (unit) {
            case 'percentage': {
                content = (
                    <FormattedNumber
                        value={this.props.value}
                        style="percent"
                        minimumFractionDigits={2}
                    />
                );
                break;
            }

            case 'amount': {
                content = (
                    <FormattedNumber
                        value={this.props.value}
                        currency={currentProject.project.default_currency}
                        style={'currency'}
                        currencyDisplay={'symbol'}
                    />
                );
                break;
            }

            default: {
                content = <FormattedNumber value={this.props.value} maximumFractionDigits={2} />;
            }
        }

        let error;
        if (this.props.error) {
            content = null;
            error = <Error text={'An error occured while loading this chart.'} />;
        }

        return (
            <div class="row">
                <div class="large-12 columns text-center">
                    {correspondingFilter ? (
                        <div class="large-value">
                            <Link
                                to={`/projects/${currentProject.project
                                    .id}/transactions?filter=${encodeURI(correspondingFilter)}`}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                {content}
                                {error}
                            </Link>
                        </div>
                    ) : (
                        <div>
                            <div class="large-value" style={{ whiteSpace: 'nowrap' }}>
                                {content}
                            </div>
                            <span class="small-text" style={{ position: 'relative', top: '-3em' }}>
                                {error}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { currentProject: store.currentProject };
})(SingleValue);
