import * as ProcessOut from '../util/ProcessOut';

export function fetchPlans(length, after, id) {
    var bound = after ? 'start_after' : 'end_before';
    if (!id) {
        id = '';
    }

    return {
        type: 'FETCH_PLANS',
        payload: ProcessOut.APIcallPromise(
            `/plans?${bound}=${id}&order=desc&limit=${length}`,
            'GET',
        ),
    };
}

export function createPlan(plan, resolve) {
    return function(dispatch) {
        var body = JSON.stringify(plan);
        dispatch({
            type: 'CREATE_PLAN',
            payload: ProcessOut.APIcallPromise('/plans', 'POST', plan).then(function(response) {
                if (response.data.success) {
                    resolve();
                    dispatch(fetchPlans());
                }
                return response;
            }),
        });
    };
}

export function editPlan(plan, id, resolve) {
    return function(dispatch) {
        var body = JSON.stringify(plan);
        dispatch({
            type: 'EDIT_PLAN',
            payload: ProcessOut.APIcallPromise(`/plans/${id}`, 'PUT', plan).then(function(
                response,
            ) {
                if (response.data.success) {
                    resolve();
                    dispatch(fetchPlans());
                }
                return response;
            }),
        });
    };
}

export function deletePlan(id) {
    return function(dispatch) {
        dispatch({
            type: 'DELETE_PLAN',
            payload: ProcessOut.APIcallPromise(`/plans/${id}`, 'DELETE', null).then(function(
                response,
            ) {
                if (response.data.success) {
                    dispatch(fetchPlans());
                }
                return response;
            }),
        });
    };
}
