import React from 'react';
import ContentLayout from './ContentLayout';
import * as CouponsActions from '../Actions/CouponsActions';
import * as SubscriptionsActions from '../Actions/SubscriptionsActions';
import { connect } from 'react-redux';
import CouponSelect from '../components/InvoiceEdit/CouponSelect';

var relativeStyle = {
    position: 'relative',
};

var relativeFullStyle = {
    position: 'relative',
    width: '100%',
};

var topRelativeStyle = {
    position: 'absolute',
    width: '100%',
    top: 0,
};

@connect(store => {
    return { subscriptionDetails: store.subscriptionDetails };
})
export default class SubscriptionDetailsLayout extends React.Component {
    openModal() {
        var header = <div class='large-12 columns'>Add a coupon</div>;

        var content = (
            <div class='large-12 columns'>
                <div class='row small-margin-bottom'>
                    <div class='medium-4 columns font-console' style={{ marginTop: '7px' }}>
                        Coupon
                    </div>
                    <div id='coupon-selector' class='medium-8 columns'>
                        <CouponSelect />
                    </div>
                </div>
            </div>
        );

        var footer = (
            <div class='large-12 columns'>
                <div class='row'>
                    <div class='large-4 large-offset-4 columns text-center'>
                        <a
                            onClick={function(event) {
                                event.preventDefault();
                                this.props.dispatch({ type: 'CLOSE_MODAL' });
                            }.bind(this)}
                            class='round border small button expanded'
                        >
                            Cancel
                        </a>
                    </div>
                    <div class='large-4 columns text-center'>
                        <a
                            onClick={function(event) {
                                event.preventDefault();
                                var couponId = document.getElementsByName('coupon_id')[0].value;
                                this.createCoupon(couponId);
                            }.bind(this)}
                            class='round small main button expanded white-text'
                        >
                            Add
                        </a>
                    </div>
                </div>
            </div>
        );

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: header,
                content: content,
                footer: footer,
            },
        });
    }

    cancel(event) {
        event.preventDefault();
        if (!this.props.subscriptionDetails) return;

        if (window.confirm('This will cancel the subscription')) {
            this.props.dispatch(
                SubscriptionsActions.deleteSubscription(
                    this.props.subscriptionDetails.subscription.id,
                ),
            );
        }
    }

    createCoupon(id) {
        this.props.dispatch({ type: 'CLOSE_MODAL' });
        if (!this.props.subscriptionDetails) return;

        this.props.dispatch(
            CouponsActions.applyCoupon(this.props.subscriptionDetails.subscription.id, id),
        );
    }

    render() {
        var couponButton;
        let cancelButton;
        if (
            this.props.subscriptionDetails.fetched &&
            !this.props.subscriptionDetails.subscription.canceled
        ) {
            couponButton = (
                <input
                    type='button'
                    onClick={this.openModal.bind(this)}
                    class='round button border right small'
                    value='Apply a coupon'
                />
            );
            if (!this.props.subscriptionDetails.subscription.cancel_at) {
                cancelButton = (
                    <input
                        type='button'
                        onClick={this.cancel.bind(this)}
                        class='round button border right small'
                        value='Cancel'
                    />
                );
            }
        }

        var header = (
            <div class='row'>
                <div class='large-8 columns'>
                    <h4>{this.props.title}</h4>
                </div>
                <div class='large-2 columns'>{cancelButton}</div>
                <div class='large-2 columns'>{couponButton}</div>
            </div>
        );

        return <ContentLayout title={header}>{this.props.children}</ContentLayout>;
    }
}
