// @flow
import React from 'react';

class Getstarted extends React.Component<{}> {
    render() {
        return (
            <div class='row'>
                <div class='large-6 columns'>
                    <div class='row margin-bottom'>
                        <div class='large-12 columns'>
                            <a target='_blank' href='https://docs.processout.com/getting-started/'>
                                <div class='tile'>
                                    <div class='logo-div'>
                                        <img
                                            class='tips-img'
                                            src='//dashboard.processout.com/images/documentation.png'
                                        />
                                    </div>
                                    Discover how to get started with <b>ProcessOut</b>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='large-12 columns'>
                            <a
                                target='_blank'
                                href='https://docs.processout.com/payments/processoutjs/'
                            >
                                <div class='tile'>
                                    <div class='logo-div'>
                                        <img
                                            class='tips-img'
                                            src='//dashboard.processout.com/images/js.png'
                                        />
                                    </div>
                                    Setup your payment page using ProcessOut.js
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class='large-6 columns'>
                    <div class='row margin-bottom'>
                        <div class='large-12 columns'>
                            <a
                                target='_blank'
                                href='https://docs.processout.com/subscriptions/get-started/'
                            >
                                <div class='tile'>
                                    <div class='logo-div'>
                                        <img
                                            class='tips-img'
                                            src='//dashboard.processout.com/images/recurring.png'
                                        />
                                    </div>
                                    Create your first subscription
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='large-12 columns'>
                            <a target='_blank' href='https://www.processout.com/flight-journal/'>
                                <div class='tile'>
                                    <div class='logo-div'>
                                        <img
                                            class='tips-img'
                                            src='//dashboard.processout.com/images/blog.png'
                                        />
                                    </div>
                                    Learn more about online payment
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Getstarted;
