import React from 'react';
import ContentLayout from './ContentLayout';
import * as SubscriptionsActions from '../Actions/SubscriptionsActions';
import Loader from '../components/Loader';
import Empty from '../components/Empty';
import { connect } from 'react-redux';
import Error from '../components/Error';
import Pagination from '../components/Pagination';
import Subscription from '../components/Subscription';
import PropTypes from 'prop-types';
import { RebuildFilter } from '../features/SearchBar/Util';
import SearchBar from '../features/SearchBar/SearchBar';
import NotPermitted from '../features/Permissions/NotPermitted';

const subscriptionsPerPage = 15;

@connect(store => {
    return {
        subscriptionsDetails: store.subscriptions,
        projects: store.projects,
    };
})
export default class Subscriptions extends React.Component {
    static contextTypes = {
        store: PropTypes.object.isRequired,
    };

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Subscriptions | ProcessOut';
        const filter = this.props.location.query.filter;
        this.setState({ filter: filter });
        // we check that there is an afterItem parameter
        // if not then we are going backward and there should be a before item
        const afterItem = this.props.location.query.after_item;
        const beforeItem = this.props.location.query.before_item;
        this.applyFilter(filter, false, afterItem || beforeItem, !beforeItem);
    }

    componentWillReceiveProps(nextProps) {
        var firstItem = this.props.location.query.after_item;
        if (!firstItem) firstItem = '';
        if (
            !nextProps.subscriptionsDetails.fetching &&
            !nextProps.subscriptionsDetails.fetched &&
            !nextProps.subscriptionsDetails.error
        ) {
            this.props.dispatch(
                SubscriptionsActions.loadSubscriptions(
                    this.state.filter,
                    subscriptionsPerPage,
                    true,
                    firstItem,
                ),
            );
        }
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    constructor() {
        super();
        this.state = {
            filter: '',
        };
    }

    applyFilter = (filter: string, reset: boolean, firstItem: string, forward?: boolean) => {
        this.props.dispatch(
            SubscriptionsActions.loadSubscriptions(
                filter,
                subscriptionsPerPage,
                forward !== null ? forward : true,
                firstItem,
            ),
        );
    };

    render() {
        const subscriptions = this.props.subscriptionsDetails.subscriptions;

        var header;
        var content;
        if (
            (this.props.subscriptionsDetails.fetching ||
                !this.props.subscriptionsDetails.fetched) &&
            !this.props.subscriptionsDetails.error
        ) {
            content = <Loader />;
        } else if (this.props.subscriptionsDetails.error) {
            if (this.props.subscriptionsDetails.error.notPermitted) content = <NotPermitted />;
            else content = <Error />;
        } else {
            var empty;
            if (subscriptions.length == 0) {
                if (this.state.filter !== '') {
                    empty = <Empty text='No subscription matches your search.' />;
                } else {
                    empty = (
                        <Empty text="You haven't added any subscription to your customers yet." />
                    );
                }
            }

            content = (
                <div>
                    <div class='row'>
                        <div class='large-12 columns'>
                            {empty}

                            {subscriptions.map(function(subscription) {
                                return (
                                    <Subscription
                                        subscription={subscription}
                                        key={subscription.id}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            );

            if (subscriptions && subscriptions.length > 0) {
                header = (
                    <div
                        class='row capitalize greyed bold small-margin-bottom'
                        style={{ fontSize: '0.8em' }}
                    >
                        <div class='medium-offset-1 medium-3 columns'>
                            <div style={{ marginLeft: '-1em' }}>Amount</div>
                        </div>
                        <div class='medium-6 columns'>Name</div>
                        <div class='medium-2 columns text-right'>Date</div>
                    </div>
                );
            }
        }

        const title = (
            <div class='row'>
                <div class='large-2 columns'>
                    <h4>Subscriptions</h4>
                </div>
                <div class='large-7 end columns'>
                    <SearchBar
                        defaultFilter={this.props.location.query.filter || ''}
                        applyFunc={RebuildFilter.bind(this, this.applyFilter)}
                        document={'subscriptions'}
                    />
                </div>
            </div>
        );

        return (
            <ContentLayout title={title}>
                <div class='row'>
                    <div class='large-12 columns'>
                        {header}
                        {content}
                        <Pagination
                            location={this.props.location}
                            reloadFunction={SubscriptionsActions.loadSubscriptions.bind(
                                null,
                                this.state.filter,
                                subscriptionsPerPage,
                            )}
                            hasMore={
                                this.props.subscriptionsDetails.fetched
                                    ? this.props.subscriptionsDetails.has_more
                                    : false
                            }
                            itemsArray={
                                this.props.subscriptionsDetails.fetched ? subscriptions : []
                            }
                            hide={
                                this.props.subscriptionsDetails.fetching ||
                                !this.props.subscriptionsDetails.fetched
                            }
                        />
                    </div>
                </div>
            </ContentLayout>
        );
    }
}
