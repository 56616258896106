import React from 'react';
import { Link } from 'react-router';
import { FormattedDate, FormattedNumber, ReactIntl } from 'react-intl';
import * as ProcessOut from '../util/ProcessOut';
import PropTypes from 'prop-types';

export default class Subscription extends React.Component {
    constructor() {
        super();
    }

    static propTypes = {
        subscription: PropTypes.object.isRequired,
    };

    render() {
        const subscription = this.props.subscription;

        var country;
        if (subscription.country != null) {
            country = <div class='greyed'>- {subscription.country_code}</div>;
        }

        var status;
        var statusName;
        if (subscription.active) {
            status = (
                <div class='log-level success' style={{ margin: '0px', marginTop: '0.2em' }}>
                    Active
                </div>
            );
        } else if (subscription.canceled) {
            status = (
                <div class='log-level failed' style={{ margin: '0px', marginTop: '0.2em' }}>
                    Canceled
                </div>
            );
        } else {
            status = (
                <div class='log-level warning' style={{ margin: '0px', marginTop: '0.2em' }}>
                    Pending
                </div>
            );
        }

        var interval;
        if (subscription.interval_days === 365) interval = <span>every year</span>;
        else if (subscription.interval_days === 30) interval = <span>every month</span>;
        else if (subscription.interval_days === 7) interval = <span>every week</span>;
        else if (subscription.interval_days === 1) interval = <span>every day</span>;
        else interval = <span>every {subscription.interval_days} days</span>;

        var name = subscription.name;
        if (subscription.plan) {
            name = subscription.plan.name;
        }

        return (
            <Link
                to={
                    '/projects/' +
                    ProcessOut.ProjectId +
                    '/recurring/subscriptions/' +
                    subscription.id
                }
            >
                <div class='box-row padding row'>
                    <div class='medium-1 columns'>{status}</div>
                    <div class='medium-3 columns clearfix' style={{ marginLeft: '-1em' }}>
                        <span class='big-font left'>
                            <span class='greyed'>{subscription.currency}</span>{' '}
                            <FormattedNumber
                                value={subscription.amount}
                                style='currency'
                                currency={subscription.currency}
                            />
                        </span>
                        <span class='right'>{interval}</span>
                    </div>
                    <div class='medium-6 columns'>
                        <span style={{ marginLeft: '1em' }}>{name}</span>
                    </div>
                    <div class='medium-2 columns greyed text-right'>
                        <FormattedDate
                            value={subscription.created_at}
                            day='2-digit'
                            month='2-digit'
                            year='numeric'
                        />
                    </div>
                </div>
            </Link>
        );
    }
}
