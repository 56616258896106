// @flow

import React from 'react';
import { Creatable } from 'react-select-v2';
import uniqid from 'uniqid';

type Props = {
    options: ?Array<{ label: string, value: string }>,
    onChange: (Array<string>) => void,
    value: ?Array<{ label: string, value: string }>,
    isMulti: boolean,
    isLoading: boolean,
};

class ValueSelection extends React.Component<Props> {
    onChange = newValue => {
        const result = [];
        if (newValue instanceof Array) {
            for (const value of newValue) {
                result.push(value.value.replace(/\"(.*)\"/g, '$1'));
            }
        } else {
            result.push(newValue.value);
        }
        this.props.onChange(result);
    };

    render() {
        const { value, options, isMulti } = this.props;
        let fieldValue = [];
        if (value) {
            fieldValue = value.map(v => {
                if (options) {
                    const correspondingOption = options.find(op => op.value === v);
                    return correspondingOption || { value: v, label: v };
                }
                return { value: v, label: v };
            });
        }

        return (
            <div class='labeled-field' style={{ width: '100%' }} key={uniqid()}>
                <Creatable
                    className={'react-select'}
                    options={options || []}
                    value={fieldValue}
                    onChange={this.onChange}
                    placeholder={'Type for custom value'}
                    isClearable={false}
                    isMulti={isMulti}
                    formatCreateLabel={label => label}
                    closeMenuOnSelect={!isMulti}
                    isLoading={this.props.isLoading}
                />
            </div>
        );
    }
}

export default ValueSelection;
