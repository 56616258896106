import React from 'react';
import { Link } from 'react-router';
import { FormattedDate, ReactIntl } from 'react-intl';
import * as ProcessOut from '../util/ProcessOut';
import PropTypes from 'prop-types';

export default class POEvent extends React.Component {
    static propTypes = {
        event: PropTypes.object.isRequired,
    };

    constructor() {
        super();
    }

    render() {
        var event = this.props.event;

        var eventName = event.name;
        if (eventName.length > 40) eventName = `${eventName.substring(0, 40)}...`;

        return (
            <Link to={'/projects/' + ProcessOut.ProjectId + '/developer/events/' + event.id}>
                <div class='box-row padding row'>
                    <div class='medium-5 columns font-console'>{eventName}</div>
                    <div class='medium-5 columns font-console greyed'>{event.id}</div>
                    <div class='medium-2 columns text-right greyed'>
                        <FormattedDate
                            value={event.fired_at}
                            day='2-digit'
                            month='2-digit'
                            year='numeric'
                            hour='2-digit'
                            minute='2-digit'
                            seconds='2-digit'
                        />
                    </div>
                </div>
            </Link>
        );
    }
}
