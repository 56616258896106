export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case 'FETCH_API_REQUEST_DETAILS_PENDING': {
            return {
                ...state,
                error: false,
                fetching: true,
                fetched: false,
            };
        }

        case 'FETCH_API_REQUEST_DETAILS_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                request: action.payload.api_request,
            };
        }

        case 'FETCH_API_REQUEST_DETAILS_REJECTED': {
            return {
                ...state,
                error: true,
                fetched: false,
                fetching: false,
            };
        }
    }

    return state;
}
