import React from 'react';
import Select from 'react-select';
import * as CustomerDetailsActions from '../../Actions/CustomerDetailsActions';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

@connect(function(store) {
    return {
        tokens: store.customerDetails.tokens,
    };
})
export default class TokenSelect extends React.Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired,
    };

    constructor() {
        super();
        this.state = {
            value: null,
        };
    }

    componentDidMount() {
        if (!this.props.tokens.fetched && this.props.tokens.fetching)
            this.props.dispatch(CustomerDetailsActions.fetchTokens(customerId));
    }

    planSelected(value) {
        this.setState({ value: value });
    }

    render() {
        const tokens = this.props.tokens.tokens;

        var fetching = !this.props.tokens.fetched && this.props.tokens.fetching;
        var options = [];
        if (!fetching && this.props.tokens.fetched) {
            options = tokens
                .filter(function(token) {
                    return !token.is_subscription_only;
                })
                .map(function(token) {
                    return {
                        value: token.id,
                        label:
                            token.type === 'card'
                                ? `${token.card.scheme} - ${token.card.last_4_digits}`
                                : token.gateway_configuration.gateway.name,
                    };
                });
        }

        return (
            <Select
                name="source"
                placeholder="Select a payment method..."
                options={options}
                isLoading={fetching}
                value={this.state.value}
                onChange={this.planSelected.bind(this)}
            />
        );
    }
}
