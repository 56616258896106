import React from 'react';
import { FormattedDate, FormattedNumber, ReactIntl } from 'react-intl';
import PropTypes from 'prop-types';

export default class Discount extends React.Component {
    static propTypes = {
        discount: PropTypes.object.isRequired,
        currency: PropTypes.string.isRequired,
    };

    render() {
        const discount = this.props.discount;

        return (
            <div class='row'>
                <div class='large-4 columns'>
                    <div class='big-font'>
                        <span class='greyed'>{this.props.currency}</span>{' '}
                        <FormattedNumber
                            value={discount.amount}
                            style='currency'
                            currency={this.props.currency}
                        />
                    </div>
                </div>
                <div class='large-4 columns'>
                    <span class='greyed'>{discount.coupon.id}</span>
                </div>
                <div class='large-4 columns text-right'>
                    <span class='greyed'>
                        <FormattedDate
                            value={discount.created_at}
                            day='2-digit'
                            month='2-digit'
                            year='numeric'
                        />
                    </span>
                </div>
            </div>
        );
    }
}
