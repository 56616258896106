// @flow

import Uniqid from 'uniqid';

export const DIMENSIONS_MAP: Array<{
    name: string,
    description: string,
    field: string,
    top: ?number,
    strategy: string,
    group: string,
}> = [
    {
        name: 'Error code',
        field: 'error_code',
        description: 'Transaction error codes: do-not-honor…',
        top: 10,
        strategy: 'value_descending',
        group: 'Results',
    },
    {
        name: 'Payment type',
        field: 'payment_type',
        description: 'Transaction payment types: Card, Paypal…',
        top: 10,
        strategy: 'value_descending',
        group: 'Transaction parameter',
    },
    {
        name: 'Name',
        field: 'name',
        description: 'Transaction names',
        top: 10,
        strategy: 'value_descending',
        group: 'Transaction parameter',
    },
    {
        name: 'Currency',
        field: 'currency',
        description: 'Currency used for the transactions: USD, EUR, CAD…',
        top: 10,
        strategy: 'value_descending',
        group: 'Transaction parameter',
    },
    {
        name: 'Status',
        field: 'status',
        description: 'Transaction status: completed, pending, failed…',
        top: 10,
        strategy: 'value_descending',
        group: 'Results',
    },
    {
        name: 'Country',
        field: 'country',
        description: 'Country in which the transactions occured: FR, US, UK…',
        top: 75,
        strategy: 'value_descending',
        group: 'Card parameter',
    },
    {
        name: 'Continent',
        field: 'continent',
        description: 'Continent in which the transactions occured',
        top: 10,
        strategy: 'value_descending',
        group: 'Card parameter',
    },
    {
        name: 'Attempts count',
        field: 'attempts_count',
        description: 'Number of attempts for a transaction',
        top: 10,
        strategy: 'value_descending',
        group: 'Transaction parameter',
    },
    {
        name: 'Subscription cycle',
        field: 'subscription_cycle',
        description: 'Number of iteration of a subscription',
        top: 10,
        strategy: 'value_descending',
        group: 'Transaction parameter',
    },
    {
        name: 'Card scheme',
        field: 'card_scheme',
        description: 'Card scheme: Visa, Mastercard, Amex…',
        top: 10,
        strategy: 'value_descending',
        group: 'Card parameter',
    },
    {
        name: 'Card co-scheme',
        field: 'card_co_scheme',
        description: 'Card co-schemes: CB…',
        top: 10,
        strategy: 'value_descending',
        group: 'Card parameter',
    },
    {
        name: 'Card IIN/BIN',
        field: 'card_iin',
        description: 'Card BIN/IIN',
        top: 10,
        strategy: 'value_descending',
        group: 'Card parameter',
    },
    {
        name: 'Card type',
        field: 'card_type',
        description: 'Card types: Debit, Credit…',
        top: 10,
        strategy: 'value_descending',
        group: 'Card parameter',
    },
    {
        name: 'Card category',
        field: 'card_category',
        description: 'Card categories: ',
        top: 10,
        strategy: 'value_descending',
        group: 'Card parameter',
    },
    {
        name: 'Card bank',
        field: 'card_bank',
        description: 'Bank that issued the card used for the transactions',
        top: 10,
        strategy: 'value_descending',
        group: 'Card parameter',
    },
    {
        name: 'CVC check status',
        field: 'cvc_check',
        description: 'CVC status for transactions: passed, checked…',
        top: 10,
        strategy: 'value_descending',
        group: 'Results',
    },
    {
        name: 'Acquirer name',
        field: 'acquirer_name',
        description: 'Name of acquirers that handled the transactions',
        top: 10,
        strategy: 'value_descending',
        group: 'Gateway',
    },
    {
        name: 'Gateway configuration ID',
        field: 'gateway_configuration_id',
        description: '',
        top: 10,
        strategy: 'value_descending',
        group: 'Gateway',
    },
    {
        name: 'Gateway name',
        field: 'gateway_name',
        description: 'Name of the gateways used: Stripe, Adyen, Braintree…',
        top: 10,
        strategy: 'value_descending',
        group: 'Gateway',
    },
    {
        name: 'Gateway fee currency',
        field: 'gateway_fee_currency',
        description: 'Currency of the fees paid to the gateway',
        top: 10,
        strategy: 'value_descending',
        group: 'Gateway',
    },
    {
        name: 'Creation date',
        field: 'created_at',
        description: 'Transaction creation dates',
        strategy: 'key_ascending',
        top: null,
        group: 'Time',
    },
    {
        name: 'Hour of creation',
        field: 'created_at_day_hour',
        description: 'Hour of the day transaction was created (int)',
        top: 25,
        strategy: 'key_ascending',
        group: 'Time',
    },
    {
        name: 'Hour segment of creation',
        field: 'created_at_day_hour_segment',
        description: 'Hour of the day transaction was created (string)',
        top: 25,
        strategy: 'key_ascending',
        group: 'Time',
    },
    {
        name: 'Day in month of creation (number)',
        field: 'created_at_month_day',
        description: 'Day of the month transaction was created (int)',
        top: 31,
        strategy: 'key_ascending',
        group: 'Time',
    },
    {
        name: 'Day of the week of creation (number)',
        field: 'created_at_week_day',
        description: 'Day of the week transaction was created (int)',
        top: 7,
        strategy: 'key_ascending',
        group: 'Time',
    },
    {
        name: 'Day of the week of creation (name)',
        field: 'created_at_week_day_name',
        description: 'Day of the week transaction was created (name/string)',
        top: 7,
        strategy: 'key_ascending',
        group: 'Time',
    },
    {
        name: '3DS status',
        field: 'three_d_s_status',
        description: 'Whether or not the transactions went through 3DS',
        top: 10,
        strategy: 'value_descending',
        group: 'Results',
    },
    {
        name: 'ECI',
        field: 'eci',
        description: 'Transaction type (such as recurring)',
        top: 10,
        strategy: 'value_descending',
        group: 'Results',
    },
    {
        name: 'MCC',
        field: 'mcc',
        description: 'Merchant Category Code',
        top: 10,
        strategy: 'value_descending',
        group: 'Gateway',
    },
    {
        name: 'Merchant account ID',
        field: 'merchant_account_id',
        description: 'ID of the merchant account',
        top: 10,
        strategy: 'value_descending',
        group: 'Gateway',
    },
    {
        name: 'Is on us',
        field: 'is_on_us',
        description: 'Whether the transaction is performed on-us or not',
        top: 2,
        strategy: 'value_descending',
        group: 'Gateway',
    },
    {
        name: 'Origin',
        field: 'origin',
        description: 'Origin of the transaction',
        top: 10,
        strategy: 'value_descending',
        group: 'Transaction parameter',
    },
    {
        name: 'AVS check status',
        field: 'avs_street_check',
        description: 'Status of the address verification system',
        top: 10,
        strategy: 'value_descending',
        group: 'Results',
    },
    {
        name: 'Chargeback date',
        field: 'chargedback_at',
        description: 'Date at which a chargeback occured',
        strategy: 'key_ascending',
        top: null,
        group: 'Time',
    },
    {
        name: 'Refund date',
        field: 'refunded_at',
        description: 'Date at which a refund occured',
        strategy: 'key_ascending',
        top: null,
        group: 'Time',
    },
];
export type $Dimension = {
    id: string,
    field: string,
    formula: string,
    strategy: ?string,
    top: ?number,
};
export const generateDefaultDimension = (): $Dimension => {
    return {
        id: Uniqid(),
        field: 'transactions.created_at',
        formula: 'count{path: "transactions"; default: 0;}',
        strategy: '',
        top: null,
    };
};
