import React from 'react';

import Header from '../components/layout/Header';
import Modal from '../components/layout/Modal';
import Nav from '../components/layout/Nav';
import * as ProcessOut from '../util/ProcessOut';
import NotificationCenter from '../features/NotificationBar/NotificationCenter';
import PullingIndicator from '../features/PullingIndicator';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorBoundary from '../features/ErrorBoundary';
import SandboxIndicator from '../features/SandboxIndicator/index';
import Background from '../features/Background/Background';

class Layout extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    componentWillMount() {
        ProcessOut.ProjectId = this.props.params.project;
    }

    render() {
        const { whiteLabel } = this.props;
        return (
            <div>
                <Modal />
                <div class='colorful main-wrapper'>
                    <Background />
                    <Header />
                    <div class='row main-content shadowed rounded-top'>
                        <NotificationCenter />
                        <div class='large-12 columns'>
                            <PullingIndicator />
                            <SandboxIndicator />
                        </div>
                        <Nav location={this.props.location}>
                            <ErrorBoundary>{this.props.children}</ErrorBoundary>
                        </Nav>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return {
        whiteLabel: store.whiteLabel,
    };
})(Layout);
