// @flow

import React from 'react';
import type { $RoutingRule, $RoutingRulesSettings } from './consts';
import TagsListing from './TagsListing';
import * as Actions from './actions';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../util/Types';

type Props = {
    rule: $RoutingRule,
    children?: ?any,
    collapse: () => void,
    routingRules: $RoutingRulesSettings,
} & $Dispatcher;

class RuleContainer extends React.Component<Props> {
    tagsChanged = (newTags: Array<string>) => {
        const { rule, dispatch } = this.props;
        const newRule = {
            ...rule,
            tags: newTags,
        };
        dispatch(Actions.updateRule(rule.id, newRule));
    };

    render() {
        const { rule, routingRules } = this.props;
        return (
            <div className='row'>
                <div className='medium-12 columns '>
                    <div
                        className={`row small-margin-bottom routing-rule-row ${
                            rule.declaration === 'trigger_3ds'
                                ? 'trigger_3ds'
                                : rule.declaration === 'block'
                                ? 'block'
                                : 'route'
                        }`}
                    >
                        <div class='medium-12 columns'>
                            <div className='row margin-bottom'>
                                <div className='medium-8 columns'>
                                    <TagsListing tags={rule.tags} onChange={this.tagsChanged} />
                                </div>
                                <div
                                    className='medium-4 columns text-right'
                                    style={{ position: 'relative', top: '.5em' }}
                                >
                                    <a
                                        className='greyed'
                                        style={{ marginRight: '1em' }}
                                        onClick={this.props.collapse}
                                    >
                                        Collapse
                                    </a>

                                    {rule.declaration !== 'route' ||
                                    routingRules.rules.filter(rule => rule.declaration === 'route')
                                        .length > 1 ? (
                                        <a
                                            className='greyed'
                                            onClick={() => {
                                                this.props.dispatch(
                                                    Actions.removeRule(this.props.rule.id),
                                                );
                                            }}
                                            style={{ display: 'inline-block' }}
                                        >
                                            <img
                                                src='//dashboard.processout.com/images/delete.png'
                                                style={{
                                                    height: '20px',
                                                    display: 'inline-block',
                                                    marginRight: '.5em',
                                                    opacity: 0.4,
                                                }}
                                            />
                                            <span style={{ display: 'inline-block' }}>
                                                remove rule
                                            </span>
                                        </a>
                                    ) : null}
                                </div>
                            </div>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => ({ routingRules: store.routingRulesSettings }))(RuleContainer);
