// @flow
/**
 * Created by jeremylejoux on 24/04/17.
 */

import { combineReducers } from 'redux';
import Details from './TransactionDetailsReducer';
import Probabilities from '../../../features/TransactionDetailsProbabilities/reducer';

export default combineReducers({
    details: Details,
    probabilities: Probabilities,
});
