// @flow
import type { $Action, $State } from '../../util/Types';

type $Coupon = {
    id: string,
    name: string,
};

export type $CouponsState = {
    coupons: Array<$Coupon>,
} & $State;

const defaultState: $CouponsState = {
    fetching: false,
    fetched: false,
    error: undefined,
    coupons: [],
};

export default function(state: $CouponsState = defaultState, action: $Action): $CouponsState {
    switch (action.type) {
        case 'FETCH_COUPONS_PENDING': {
            state = {
                ...state,
                ...defaultState,
                fetching: true,
            };
            return state;
        }

        case 'FETCH_COUPONS_FULFILLED': {
            state = {
                ...state,
                ...action.payload,
                fetched: true,
                fetching: false,
            };
            return state;
        }

        case 'FETCH_COUPONS_REJECTED': {
            return {
                ...state,
                error: action.payload,
                fetched: false,
                fetching: false,
            };
        }
    }

    return state;
}
