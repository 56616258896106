// @flow

import {
    ADD_RULE,
    PREPARE_ROUTING_RULES_SETTINGS,
    REMOVE_RULE,
    FETCH_ROUTING_RULES,
    SAVE_ROUTING_RULES,
    UPDATE_RULE,
    COMPILE_RULES,
} from './consts';
import type { $Action } from '../../util/Types';
import type { $RemoveRuleAction, $UpdateRuleAction } from './sagas';
import type { $RoutingRule } from './consts';
import * as ProcessOut from '../../util/ProcessOut';
import moment from 'moment';

export function addRule(type: 'route' | 'block' | 'trigger_3ds'): $Action {
    return {
        type: ADD_RULE,
        payload: {
            type,
        },
    };
}

export function fetchRules(): $Action {
    return {
        type: FETCH_ROUTING_RULES,
        payload: ProcessOut.APIcallPromise('/routing-rules', 'GET'),
    };
}

export function compileRules(formula: string): $Action {
    return {
        type: COMPILE_RULES,
        payload: ProcessOut.APIcallPromise('/routing-rules/compile', 'POST', {
            routing_rules: formula,
        }),
    };
}

export function prepareRoutingRulesSettings(): $Action {
    return {
        type: PREPARE_ROUTING_RULES_SETTINGS,
        payload: null,
    };
}

export function removeRule(id: string): $RemoveRuleAction {
    return {
        type: REMOVE_RULE,
        payload: {
            id: id,
        },
    };
}

export function updateRule(id: string, rule: $RoutingRule): $UpdateRuleAction {
    return {
        type: UPDATE_RULE,
        payload: {
            ruleId: id,
            rule,
        },
    };
}

/**
 * This fetches the number of transactions from the previous 30 days that match the current rule filters
 * @returns {{type: string, payload: *}}
 */
export function fetchChartData(rule: $RoutingRule): $Action {
    const filters = rule.conditions.reduce((value, condition) => {
        const filterString = condition.filters
            .filter(filter => filter.path && (filter.operand.includes('null') || filter.value))
            .map((filter, index, array) => {
                if (filter.operand === 'is-null') {
                    return `${filter.path} == null${index === array.length - 1 ? ';' : ' AND '}`;
                } else if (filter.operand === 'is-not-null') {
                    return `${filter.path} != null${index === array.length - 1 ? ';' : ' AND '}`;
                } else {
                    return `${filter.path} ${filter.operand} ${
                        isNaN(filter.value) ? `"${filter.value}"` : filter.value
                    } ${index === array.length - 1 ? ';' : ' AND '}`;
                }
            })
            .reduce((result, filter) => `${result}${filter}`, '');
        return `${value}${filterString}`;
    }, '');
    const chart = {
        name: 'rule preview',
        description: '',
        type: 'single-value',
        settings: {
            formula: `count{path: "transactions";${filters};default: 0}`,
            plotted_field: null,
        },
        size: 12,
        unit: 'percentage',
        preview: true,
    };
    return {
        type: 'PREPARE_CHART_PREVIEW',
        payload: ProcessOut.APIcallPromise(
            `/boards/board_default-sales/charts?interval=monthly&start_at=${moment()
                .subtract(1, 'months')
                .unix()}&end_at=${moment().unix()}`,
            'POST',
            chart,
            null,
            null,
            true,
        ),
    };
}

export function fetchTotalTransactions(): $Action {
    const chart = {
        name: 'rule preview',
        description: '',
        type: 'single-value',
        settings: {
            formula: `count{path: "transactions";default: 0}`,
            plotted_field: null,
        },
        size: 12,
        unit: 'percentage',
        preview: true,
    };
    return {
        type: 'PREPARE_CHART_PREVIEW',
        payload: ProcessOut.APIcallPromise(
            `/boards/board_default-sales/charts?interval=monthly&start_at=${moment()
                .subtract(1, 'months')
                .unix()}&end_at=${moment().unix()}`,
            'POST',
            chart,
            null,
            null,
            true,
        ),
    };
}

export function requestRulesSaving(): $Action {
    return { type: SAVE_ROUTING_RULES };
}

export function saveRules(formula: string): $Action {
    return {
        type: SAVE_ROUTING_RULES,
        payload: ProcessOut.APIcallPromise(`/routing-rules`, 'PUT', {
            routing_rules: formula,
        }),
    };
}

export const CHANGE_ROUTING_RULES_ORDER = 'CHANGE_ROUTING_RULES_ORDER';
export function changeRoutingRulesOrder(
    type: 'route' | 'block' | 'trigger_3ds',
    rules: Array<$RoutingRule>,
): $Action {
    return {
        type: CHANGE_ROUTING_RULES_ORDER,
        payload: {
            type,
            rules,
        },
    };
}
