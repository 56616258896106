// @flow

import type { $Board } from './consts';
import {
    CLEAR_BOARS_LIST,
    CREATE_BOARD,
    DELETE_BOARD,
    EDIT_BOARD,
    REQUEST_BOARD_DELETION,
    REQUEST_BOARDS_FETCH,
} from './consts';
import * as ProcessOut from '../../../util/ProcessOut';
import type { $Action } from '../../../util/Types';
import Analytics from '../../../util/Analytics';

/*
 * Request bboards fetching
 */
export function requestBoardsFetch(redirectToBoard: ?string, silent?: boolean): $Action {
    return {
        type: REQUEST_BOARDS_FETCH,
        payload: { redirectToBoard: redirectToBoard, silent: silent },
    };
}

/**
 * Creates a new board
 * @param name
 * @param description
 */
export function createBoard(project: string, name: string, description: string) {
    return (dispatch: () => void) => {
        dispatch({
            type: CREATE_BOARD,
            payload: ProcessOut.APIcallPromise('/boards', 'POST', {
                name,
                description,
            }).then(response => {
                if (response.data.success) {
                    dispatch(requestBoardsFetch(response.data.board.id, true));
                    // Track the event
                    Analytics('CREATED_BOARD', { name, description });
                }
                return response;
            }),
        });
    };
}

export function requestBoardDeletion(projectId: string, id: string): $Action {
    return {
        type: REQUEST_BOARD_DELETION,
        payload: {
            projectId,
            id,
        },
    };
}

export function deleteBoard(id: string): $Action {
    Analytics('DELETED_BOARD', { id });
    return {
        type: DELETE_BOARD,
        payload: ProcessOut.APIcallPromise(`/boards/${id}`, 'DELETE'),
    };
}

/*
 * fetch all the boards
 */
export function fetchBoards(silent?: boolean): $Action {
    return {
        type: `FETCH_BOARDS${silent ? '_SILENT' : ''}`,
        payload: ProcessOut.APIcallPromise('/boards?order=asc&limit=20', 'GET').then(response => {
            return {
                ...response,
                data: {
                    ...response.data,
                    boards: response.data.boards.filter(board => {
                        // Filter out default boards
                        return (
                            board.id !== 'board_default-sales' &&
                            board.id !== 'board_default-customers' &&
                            board.id !== 'board_default-providers'
                        );
                    }),
                },
            };
        }),
    };
}

export function editBoard(id: string, newBoard: $Board): $Action {
    const body = JSON.stringify(newBoard);
    Analytics('EDITED_BOARD', { newBoard });
    return {
        type: EDIT_BOARD,
        payload: ProcessOut.APIcallPromise(`/boards/${id}`, 'PUT', body),
    };
}

export function clearBoardsList(): $Action {
    return {
        type: CLEAR_BOARS_LIST,
    };
}
