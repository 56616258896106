// @flow

import type { $RequestPayoutDetailsFetchAction, $RequestPayoutItemsFetch, $RequestPayoutsFetchAction } from "./sagas";
import { FormattedNumber } from 'react-intl';
import React from 'react';

export const REQUEST_PAYOUTS_FETCH = 'REQUEST_PAYOUTS_FETCH';
export function requestPayoutsFetch(
    length: number,
    after: boolean,
    id: ?string,
): $RequestPayoutsFetchAction {
    return {
        type: REQUEST_PAYOUTS_FETCH,
        payload: {
            length,
            after,
            id,
        },
    };
}

export const REQUEST_PAYOUT_DETAILS_FETCH = 'REQUEST_PAYOUT_DETAILS_FETCH';
export function requestPayoutDetailsFetch(id: string): $RequestPayoutDetailsFetchAction {
    return {
        type: REQUEST_PAYOUT_DETAILS_FETCH,
        payload: {
            id,
        },
    };
}

export const REQUEST_PAYOUT_ITEMS_FETCH = 'REQUEST_PAYOUT_ITEMS_FETCH';
export function requestPayoutItemsFetch(payoutId: string, length: number, after: boolean, afterId: ?string): $RequestPayoutItemsFetch {
    console.trace()
    return {
        type: REQUEST_PAYOUT_ITEMS_FETCH,
        payload: {
            id: payoutId,
            length,
            after,
            afterId,
        },
    };
}

export function formatCurrency(value: string, currency: string): any {
    const content = <FormattedNumber value={Math.abs(value)} currency={currency} style={'currency'} />;
    if (value < 0) return <div>({content})</div>;
    return content;
}
