import 'brace/mode/text';

export class CustomHighlightRules extends window.ace.acequire('ace/mode/text_highlight_rules')
    .TextHighlightRules {
    constructor() {
        super();
        this.$rules = {
            start: [
                {
                    token: 'support-class',
                    regex: /(plot)/, // String or RegExp: the regexp to match
                    next: 'params', // [Optional] String: next state to enter
                },
                {
                    token: 'support-function',
                    regex: /(sum|average|count|cardinality)/, // String or RegExp: the regexp to match
                    next: 'params', // [Optional] String: next state to enter
                },
            ],
            params: [
                {
                    token: 'support-function',
                    regex: /(sum|average|count|cardinality)/, // String or RegExp: the regexp to match
                    next: 'params', // [Optional] String: next state to enter
                },
                {
                    token: 'support-class',
                    regex: /(plot)/, // String or RegExp: the regexp to match
                    next: 'params', // [Optional] String: next state to enter
                },
                {
                    token: 'support-function-constant',
                    regex: /([a-z_]+:)/,
                    next: 'paramsDefinition',
                },
                // {
                //     token: 'comment.number-sign',
                //     regex: /#.*/, // String or RegExp: the regexp to match
                //     // next:  next   // [Optional] String: next state to enter
                // },
                {
                    token: 'support-double-quotes',
                    regex: /(\".*\")/, // String or RegExp: the regexp to match
                    next: 'paramsDefinition', // [Optional] String: next state to enter
                },
                {
                    token: 'support-opening',
                    regex: /{/,
                    next: 'paramsDefinition',
                },
                {
                    token: 'support-closing',
                    regex: /(;|})/,
                    next: 'params',
                },
                {
                    token: 'support-constant',
                    regex: /(([a-z_]+\.)?[a-z_]+)/,
                    next: 'paramsDefinition',
                },
                {
                    token: 'keyword.operator',
                    regex: /(or|and|\+|-|\/|\*|>|>=|<|<=|==|!=)/,
                    next: 'paramsDefinition',
                },
                {
                    token: 'constant',
                    regex: /[0-9]+/,
                },
            ],
            paramsDefinition: [
                {
                    token: 'support-double-quotes',
                    regex: /(\".*\")/, // String or RegExp: the regexp to match
                    next: 'paramsDefinition', // [Optional] String: next state to enter
                },
                {
                    token: 'support-function',
                    regex: /(sum|average|count|cardinality)/, // String or RegExp: the regexp to match
                    next: 'params', // [Optional] String: next state to enter
                },
                {
                    token: 'support-class',
                    regex: /(plot)/, // String or RegExp: the regexp to match
                    next: 'params', // [Optional] String: next state to enter
                },
                {
                    token: 'support-opening',
                    regex: /{/,
                    next: 'paramsDefinition',
                },
                {
                    token: 'support-closing',
                    regex: /(;|})/,
                    next: 'params',
                },
                {
                    token: 'support-constant',
                    regex: /(([a-z_]+\.)?[a-z_]+)/,
                    next: 'paramsDefinition',
                },
                {
                    token: 'keyword.operator',
                    regex: /(or|and|\+|-|\/|\*|>|>=|<|<=|==|!=)/,
                    next: 'paramsDefinition',
                },
                {
                    token: 'constant',
                    regex: /[0-9]+/,
                },
            ],
        };
    }
}

export default class CustomPolangMode extends window.ace.acequire('ace/mode/text').Mode {
    constructor() {
        super();
        this.HighlightRules = CustomHighlightRules;
    }
}
