// @flow

import React from 'react';
import TransactionDetailsLayout from './TransactionDetailsLayout';
import Loader from '../components/Loader';
import Error from '../components/Error';
import * as TransactionsActions from '../Actions/TransactionsActions';
import { FormattedNumber } from 'react-intl';
import Transaction from '../components/TransactionsDetails/Transaction';
import CardDetails from '../components/TransactionsDetails/CardDetails';
import Operation from '../components/TransactionsDetails/Operation';
import Customer from '../components/TransactionsDetails/Customer';
import MetadataDisplay from '../components/MetadataDisplay';
import { connect } from 'react-redux';
import type { TransactionModel } from '../Types/Transaction';
import PropTypes from 'prop-types';
import { REQUEST_TRSN_PROBA_FETCH } from '../features/TransactionDetailsProbabilities/consts';
import ProbaModal from '../features/TransactionDetailsProbabilities/ProbaModal';
import type { $State } from '../util/Types';
import { hasFeature } from '../features/FeatureFlags/FeatureFlags';
import CountryData from 'country-data';
import DeclineExplainer from '../features/DeclineExplainer/DeclineExplainer';
import type { $DeclineExplainerState } from '../features/DeclineExplainer/reducer';
import CountryFlag from '../features/CountryFlag/CountryFlag';
import NotPermitted from '../features/Permissions/NotPermitted';

type Props = {
    details: any,
    dispatch: (payload: Function) => void,
    probabilities: $State,
    params: any,
    declineExplainer: $DeclineExplainerState,
};

type State = {};

@connect(store => {
    return {
        details: store.transactionDetails.details,
        refunds: store.refunds,
        probabilities: store.transactionDetails.probabilities,
        project: store.currentProject,
        declineExplainer: store.declineExplainer,
    };
})
export default class TransactionsDetails extends React.Component {
    static contextTypes = {
        store: PropTypes.object.isRequired,
    };

    props: Props;
    state: {};

    componentDidUpdate(prevProps: Props, prevState: State) {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Transactions | ProcessOut';
        this.props.dispatch(
            TransactionsActions.loadTransactionDetails(this.props.params.transaction),
        );
    }

    componentWillReceiveProps(nextProps: Props) {
        if (!nextProps.details.fetching && !nextProps.details.fetched && !nextProps.details.error) {
            this.props.dispatch(
                TransactionsActions.loadTransactionDetails(this.props.params.transaction),
            );
        }
    }

    openProbaModal = () => {
        this.refs['proba-modal'].getWrappedInstance().openModal();
    };

    render() {
        const { details, declineExplainer } = this.props;
        if ((details.fetching || !details.fetched) && !details.error) {
            return (
                <TransactionDetailsLayout title='Transaction details'>
                    <Loader />
                </TransactionDetailsLayout>
            );
        } else if (details.error) {
            if (details.error.notPermitted) {
                return (
                    <TransactionDetailsLayout title='Transaction details'>
                        <NotPermitted />
                    </TransactionDetailsLayout>
                );
            }
            return (
                <TransactionDetailsLayout title='Transaction details'>
                    <Error />
                </TransactionDetailsLayout>
            );
        }

        const transaction: TransactionModel = this.props.details.transaction;

        if (transaction.id === 0) {
            return <TransactionDetailsLayout title='Transaction details' />;
        }

        const customer = transaction.customer;

        let lastRequestId;
        for (let i = 0; i < transaction.operations.length; i++) {
            if (transaction.operations[i].type === 'request')
                lastRequestId = transaction.operations[i].id;
        }

        let error;
        if (transaction.error_code) {
            error = (
                <div>
                    <label>Error code</label>
                    <span class='big-font font-console'>{transaction.error_code}</span>
                </div>
            );
        }

        let country;
        if (transaction.details.country)
            country = <CountryFlag country_code={transaction.details.country} />;

        return (
            <TransactionDetailsLayout title='Transaction details'>
                <div class='row margin-bottom'>
                    <div class='large-5 columns'>
                        <div class='details-box'>
                            <div class='row small-margin-bottom'>
                                <div class='large-4 columns'>
                                    <label>Amount</label>
                                    <span class='huge-font bold'>
                                        <FormattedNumber
                                            value={transaction.amount}
                                            style='currency'
                                            currency={transaction.currency}
                                        />
                                    </span>
                                </div>
                                <div class='large-4 columns text-right'>
                                    <label>Processor</label>
                                    <span class='big-font'>
                                        {transaction.gateway_configuration ? (
                                            <img
                                                style={{ maxHeight: '1.5em' }}
                                                src={
                                                    transaction.gateway_configuration.gateway
                                                        .logo_url
                                                }
                                            />
                                        ) : (
                                            '--'
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='medium-12 columns text-right'>
                                    {transaction.gateway_configuration &&
                                        transaction.gateway_configuration.name}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='large-7 columns' style={{ paddingRight: 0 }}>
                        <div class='details-box' style={{ paddingBottom: '0em' }}>
                            <div class='row'>
                                <div class='medium-5 columns'>
                                    <div class='row small-margin-bottom'>
                                        <div class='large-12 columns'>
                                            <label>Status</label>
                                            <span class='big-font capitalize bold'>
                                                {transaction.status}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class='medium-7 columns text-right'>
                                    <div class='row small-margin-bottom'>
                                        <div class='large-12 columns'>{error}</div>
                                    </div>
                                    {transaction.status === 'failed' ? (
                                        <div className='row small-margin-bottom'>
                                            <div className='large-12 columns text-right'>
                                                <DeclineExplainer transaction={transaction} />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='row'>
                    <div class='large-5 columns left-border'>
                        <div class='row'>
                            <div class='large-12 columns'>
                                <div class='row'>
                                    <div class='large-12 columns'>
                                        <h6 class='margin-bottom capitalize greyed font-console'>
                                            Details {country}
                                        </h6>
                                    </div>
                                </div>
                                <div class='row'>
                                    <div class='large-12 columns'>
                                        <Transaction transaction={transaction} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-12 columns'>
                                {Object.keys(transaction.metadata).length > 0 ? (
                                    <MetadataDisplay
                                        metadata={transaction.metadata}
                                        currency={transaction.currency}
                                    />
                                ) : null}
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-12 columns'>
                                <hr />
                            </div>
                        </div>
                        <Customer customer={customer} />
                    </div>
                    <div class='large-7 columns'>
                        <CardDetails card={transaction.card} />
                        {// Only display 3DS status for card and 3ds transactions
                        transaction.payment_type === 'three-d-s' ||
                        transaction.payment_type === 'card' ? (
                            <div class='row small-margin-bottom'>
                                <div class='large-4 columns'>
                                    <span class='greyed'>3-D Secure</span>
                                </div>
                                <div class='large-8 columns'>
                                    <span class='right'>
                                        <ThreeDSStatus status={transaction.three_d_s_status} />
                                    </span>
                                </div>
                            </div>
                        ) : null}
                        {transaction.card ? (
                            <div class='row'>
                                <div class='large-12 columns'>
                                    <hr />
                                </div>
                            </div>
                        ) : null}
                        <div class='row margin-bottom'>
                            <div class='large-12 columns'>
                                <h6 class='margin-bottom capitalize greyed font-console'>
                                    Operations history
                                </h6>
                            </div>
                            <div class='large-12 columns'>
                                <div class='row small-margin-bottom'>
                                    <div class='medium-5 columns'>Transaction created:</div>
                                    <div class='medium-7 columns greyed font-console text-right'>
                                        {transaction.id}
                                    </div>
                                </div>
                                {transaction.operations.map((operation, index, operations) => {
                                    let auth;
                                    for (let i = 0; i < transaction.operations.length; i++) {
                                        if (transaction.operations[i].type === 'authorization')
                                            auth = transaction.operations[i];
                                    }

                                    let finalRequest = false;
                                    if (
                                        operation.gateway_operation_id !== '' &&
                                        auth &&
                                        operation.gateway_operation_id === auth.gateway_variable
                                    ) {
                                        finalRequest = true;
                                    }

                                    if (!finalRequest) {
                                        finalRequest = operation.id === lastRequestId;
                                    }

                                    if (operation.is_attempt && index !== operations.length - 1)
                                        return null;

                                    return (
                                        <Operation
                                            key={operation.id}
                                            operation={operation}
                                            request={finalRequest}
                                            transaction={transaction}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </TransactionDetailsLayout>
        );
    }
}

class ThreeDSStatus extends React.Component {
    props: {
        status: string,
    };

    render() {
        let display = null;
        const style = {
            paddingLeft: '5px',
            paddingRight: '5px',
            marginTop: '0px',
            marginRight: '-3px',
        };
        switch (this.props.status) {
            case 'success': {
                display = (
                    <div class='log-level success' style={style}>
                        {this.props.status}
                    </div>
                );
                break;
            }

            case 'unchecked':
            case 'pending': {
                display = (
                    <div class='log-level warning' style={style}>
                        {this.props.status}
                    </div>
                );
                break;
            }

            case 'invalidated':
            case 'failed': {
                display = (
                    <div class='log-level failed' style={style}>
                        {this.props.status}
                    </div>
                );
                break;
            }
        }

        return display;
    }
}
