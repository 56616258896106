export default function(state = { fetched: false, fetching: false }, action) {
    switch (action.type) {
        case 'FETCH_GATEWAYS_PENDING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: false,
            };
        }
        case 'FETCH_GATEWAYS_FULFILLED': {
            let gateways = action.payload.gateways;
            let payu = {
                demo: true,
                name: 'payu',
                display_name: 'Payu',
                logo_url: 'https://js.processout.com/images/gateways/payu.png',
                url: 'https://www.alipay.com',
                public_fields: [],
                identifier_fields: [],
                flows: ['one-off'],
                tags: ['credit-card', 'region-au', 'region-eu', 'region-af'],
                can_pull_transactions: false,
                can_refund: true,
                is_oauth_authentication: false,
            };

            gateways.unshift(payu);
            return {
                ...state,
                ...action.payload,
                gateways: gateways,
                fetching: false,
                fetched: true,
            };
        }
    }

    return state;
}
