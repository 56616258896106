import React from 'react';
import { ReactIntl } from 'react-intl';
import PropTypes from 'prop-types';

export default class MData extends React.Component {
    static propTypes = {
        remove: PropTypes.func.isRequired,
        metadata: PropTypes.object.isRequired,
    };

    constructor() {
        super();
    }

    componentDidUpdate(prevProps, prevState) {
        this.onValueEdit();
        this.onKeyEdit();
    }

    onValueEdit() {
        if (this.refs.value.value != '') this.refs.key.setAttribute('required', '');
        else this.refs.key.removeAttribute('required');

        this.props.updateForm();
    }

    onKeyEdit() {
        if (this.refs.key.value != '') this.refs.value.setAttribute('required', '');
        else this.refs.value.removeAttribute('required');

        if (this.refs.key.value == 'shipping_amount' || this.refs.key.value == 'taxes_amount') {
            this.refs.value.removeAttribute('required');
            this.refs.value.setAttribute('disabled', true);
            this.refs.value.value = '';
        } else {
            this.refs.value.removeAttribute('disabled');
        }

        this.props.updateForm();
    }

    componentWillReceiveProps(props) {
        this.refs.key.value = props.metadata.key;
        this.refs.value.value = props.metadata.value;
    }

    deleteMeta() {
        this.props.remove(this.props.metadata.key);
    }

    render() {
        var prop = this.props.metadata;

        var deleteButton;
        if ((prop.key !== '' && prop.value !== '') || this.props.propKey === 1) {
            deleteButton = (
                <a
                    onClick={() => {
                        this.deleteMeta();
                    }}
                    href='javascript:void(0);'
                >
                    ✕
                </a>
            );
        }

        return (
            <div class='row'>
                <div class='medium-5 columns'>
                    <input
                        ref='key'
                        type='text'
                        placeholder='Key'
                        onInput={this.onKeyEdit.bind(this)}
                        class='bottom-border key'
                        defaultValue={prop.key}
                    />
                </div>
                <div class='medium-6 columns'>
                    <input
                        ref='value'
                        type='text'
                        placeholder='Value'
                        onInput={this.onValueEdit.bind(this)}
                        class='bottom-border value'
                        defaultValue={prop.value}
                    />
                </div>
                <div
                    style={{
                        marginTop: '15px',
                    }}
                    class='medium-1 columns text-right bigger1-2'
                >
                    {deleteButton}
                </div>
            </div>
        );
    }
}
