// @flow

import React from 'react';
import { Link } from 'react-router';
import { FormattedDate, FormattedNumber, ReactIntl } from 'react-intl';
import * as ProcessOut from '../util/ProcessOut';

import type { TransactionModel } from '../Types/Transaction';
import CardSchemeImage from '../util/CardSchemeImage';
import { loadTransactionDetails } from '../Actions/TransactionsActions';
import moment from 'moment';
import DateDisplay from '../features/DateDisplay';

export default class Transaction extends React.Component<{
    transaction: TransactionModel,
    smallDisplay?: boolean,
}> {
    render() {
        const transaction = this.props.transaction;

        let name = '';
        let isNameEmpty = false;
        if (transaction.customer && transaction.customer.first_name) {
            name =
                transaction.customer.first_name +
                ' ' +
                (transaction.customer.last_name ? transaction.customer.last_name : '');
        } else if (transaction.buyer && transaction.buyer.first_name) {
            name =
                transaction.buyer.first_name +
                ' ' +
                (transaction.buyer.last_name ? transaction.buyer.last_name : '');
        } else if (transaction.buyer && transaction.buyer.email) {
            name = transaction.buyer.email;
        } else {
            name = '--';
            isNameEmpty = true;
        }

        let transactionName = transaction.name;
        let nameIsEmpty = false;
        if (typeof transactionName === 'string' && transactionName === '') {
            transactionName = '--';
            nameIsEmpty = true;
        }
        if (transactionName.length > 40) transactionName = `${transactionName.substring(0, 40)}...`;

        let pendingClass: string = '';
        let status: React$Element<any>;
        if (transaction.status === 'completed') {
            status = (
                <div class='log-level success' style={{ margin: '0px', marginTop: '0.2em' }}>
                    Completed
                </div>
            );
        } else if (transaction.status === 'waiting' || transaction.status.includes('pending')) {
            if (transaction.three_d_s_status === 'failed') {
                status = (
                    <div class='log-level failed' style={{ margin: '0px', marginTop: '0.2em' }}>
                        3DS failed
                    </div>
                );
            } else {
                status = (
                    <div class='log-level warning' style={{ margin: '0px', marginTop: '0.2em' }}>
                        {transaction.status}
                    </div>
                );
                if (transaction.status === 'pending') pendingClass = 'greyed';
            }
        } else if (transaction.status.includes('refund')) {
            status = (
                <div class='log-level warning' style={{ margin: '0px', marginTop: '0.2em' }}>
                    Refunded
                </div>
            );
        } else if (transaction.status === 'authorized') {
            status = (
                <div class='log-level warning' style={{ margin: '0px', marginTop: '0.2em' }}>
                    Authorized
                </div>
            );
        } else if (transaction.status === 'blocked') {
            status = (
                <div className='log-level blocked' style={{ margin: '0px', marginTop: '0.2em' }}>
                    Blocked
                </div>
            );
        } else {
            status = (
                <div
                    data-tip={transaction.error_code}
                    class={`log-level ${
                        transaction.status === 'failed' || transaction.status === 'reversed'
                            ? 'failed'
                            : 'warning'
                    }`}
                    style={{ margin: '0px', marginTop: '0.2em' }}
                >
                    {transaction.status}
                </div>
            );
        }

        let planName;
        if (!this.props.smallDisplay) {
            planName = (
                <div class={`medium-${this.props.smallDisplay ? 6 : 3} columns`}>
                    {nameIsEmpty ? <span class='greyed'>{transactionName}</span> : transactionName}
                </div>
            );
        }

        let custName;
        if (!this.props.smallDisplay) {
            custName = (
                <div class='medium-2 columns'>
                    {isNameEmpty ? <span class='greyed'>{name}</span> : name}
                </div>
            );
        }
        return (
            <Link to={'/projects/' + ProcessOut.ProjectId + '/transactions/' + transaction.id}>
                <div class={`box-row padding row ${pendingClass}`}>
                    <div class={`medium-${this.props.smallDisplay ? 2 : 1} columns`}>{status}</div>
                    <div class={'medium-1 columns text-center'}>
                        {transaction.card ? CardSchemeImage(transaction.card) : <div>&nbsp;</div>}
                    </div>
                    <div
                        class={`medium-${this.props.smallDisplay ? '5' : '3'} columns clearfix`}
                        style={{ marginLeft: '-15px' }}
                    >
                        <span class='big-font left'>
                            <span class='greyed'>{transaction.currency}</span>{' '}
                            <FormattedNumber
                                value={transaction.amount}
                                style='currency'
                                currency={transaction.currency}
                            />
                        </span>
                        <span class='right transaction-processor'>
                            {transaction.gateway_configuration ? (
                                <img
                                    class='right'
                                    style={{
                                        opacity: transaction.status.includes('pending') ? 0.3 : 1,
                                    }}
                                    src={transaction.gateway_configuration.gateway.logo_url}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    </div>
                    {planName}
                    {custName}
                    <div class={`medium-${this.props.smallDisplay ? '4' : '2'} columns greyed`}>
                        <span class='right'>
                            <DateDisplay value={transaction.created_at} />
                        </span>
                    </div>
                </div>
            </Link>
        );
    }
}
