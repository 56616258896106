// @flow

export const REQUEST_ADD_AGGREGATION = 'REQUEST_ADD_AGGREGATION';
export const ADD_AGGREGATION = 'ADD_AGGREGATION';

export const REQUEST_DELETE_AGGREGATION = 'REQUEST_DELETE_AGGREGATION';
export const DELETE_AGGREGATION = 'DELETE_AGGREGATION';

export const REQUEST_UPDATE_AGGREGATION = 'REQUEST_UPDATE_AGGREGATION';
export const UPDATE_AGGREGATION = 'UPDATE_AGGREGATION';

export const REQUEST_ADD_DIMENSION = 'REQUEST_ADD_DIMENSION';
export const ADD_DIMENSION = 'ADD_DIMENSION';

export const REQUEST_DELETE_DIMENSION = 'REQUEST_DELETE_DIMENSION';
export const DELETE_DIMENSION = 'DELETE_DIMENSION';

export const REQUEST_UPDATE_DIMENSION = 'REQUEST_UPDATE_DIMENSION';
export const UPDATE_DIMENSION = 'UPDATE_DIMENSION';

export const REQUEST_UPDATE_FORMULA = 'REQUEST_UPDATE_FORMULA';
export const UPDATE_FORMULA = 'UPDATE_FORMULA';

export const REQUEST_UPDATE_RENDER_FUNCTION = 'REQUEST_UPDATE_RENDER_FUNCTION';
export const UPDATE_RENDER_FUNCTION = 'UPDATE_RENDER_FUNCTION';

export const REQUEST_FORM_RESET = 'REQUEST_FORM_RESET';
export const FORM_RESET = 'FORM_RESET';
