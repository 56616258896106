// @flow
import React from 'react';
import uniqid from 'uniqid';
import type { $ChartRenderer, $ChartType, $DataPoint } from '../../Boards/consts';
import type { $Dispatcher } from '../../../../util/Types';
import { connect } from 'react-redux';
import getColor from '../../Boards/charts/colors';
import { FormattedNumber, IntlProvider } from 'react-intl';
import * as ReactDOMServer from 'react-dom/server';
import './charts.scss';
import Legend from './Legend';

type $ChartPoint = {
    _count: number,
    value: number,
    key: string,
};

type Props = {
    currentProject: any,
    canUpdateAnalyticsFilter?: boolean,
    data: Array<$ChartPoint>,
    type: $ChartType,
} & $Dispatcher;

type State = {
    id: string,
    chart: any,
};

class PieChart extends React.Component<Props, State> {
    _chartElement: any;

    constructor() {
        super();
        this.state = {
            id: uniqid(),
            chart: null,
        };
    }

    componentDidMount(): void {
        this.updateCounts(this.props);
    }

    componentDidUpdate() {
        if (!this.props.error) this.draw();
    }

    componentWillReceiveProps(nextProps: Props) {
        this.updateCounts(nextProps);
    }

    updateCounts = (props: Props) => {
        if (props.error) return;

        this.draw();
    };

    getTotalCount = () => {
        return this.props.data.reduce((total, entry) => total + entry._count, 0);
    };

    getTotalValue = () => {
        return this.props.data.reduce((total, entry) => total + entry.value, 0);
    };

    draw = () => {
        const { data, type, currency } = this.props;

        if (!data) return;

        let chart;
        if (!this.state.chart) {
            // $FlowFixMe
            chart = new Picasso.PieChart(this._chartElement, {
                tip: d => {
                    const totalValue = this.getTotalValue();
                    const totalCount = this.getTotalCount();
                    return ReactDOMServer.renderToString(
                        <IntlProvider locale={navigator ? navigator.language || 'en-US' : 'en-US'}>
                            <div class='row'>
                                <div class='large-12 columns'>
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            <h5>
                                                {d.key} —{' '}
                                                <span class='greyed'>
                                                    <FormattedNumber
                                                        value={
                                                            type === 'percentage'
                                                                ? d._count / totalCount
                                                                : d.value / totalValue
                                                        }
                                                        style={'percent'}
                                                        minimumFractionDigits={2}
                                                        maximumFractionDigits={2}
                                                    />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            {(() => {
                                                switch (type) {
                                                    case 'amount': {
                                                        return (
                                                            <AmountTooltip
                                                                point={d}
                                                                currency={currency}
                                                            />
                                                        );
                                                    }
                                                    case 'percentage': {
                                                        return <PercentTooltip point={d} />;
                                                    }
                                                    default: {
                                                        return <DefaultTooltip point={d} />;
                                                    }
                                                }
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </IntlProvider>,
                    );
                },
            });
            this.setState({ chart });
        } else {
            chart = this.state.chart;
        }
        const totalValue = this.getTotalValue();
        const totalCount = this.getTotalCount();

        chart.cleanupTip();
        chart.reset();

        for (let i = 0; i < data.length; i++) {
            chart.addSlice({
                ...data[i],
                key: data[i].key,
                data: Math.round(
                    type === 'percentage'
                        ? (data[i]._count / totalCount) * 100
                        : (data[i].value / totalValue) * 100,
                ),
                color: getColor(i),
            });
        }
        chart.resetSVG();
        chart.draw();
    };

    render() {
        const { data } = this.props;
        return (
            <div class='pie-chart'>
                <svg
                    className={`chart`}
                    id={`chart${this.state.id}`}
                    ref={e => (this._chartElement = e)}
                    width='100%'
                    height='250px'
                    style={{
                        position: 'relative',
                        left: '-3.5em',
                    }}
                />
                <Legend
                    colors={data.map((entry, index) => {
                        return { key: entry.key, color: getColor(index) };
                    })}
                />
            </div>
        );
    }
}

export default connect(store => {
    return { currentProject: store.currentProject };
})(PieChart);

const AmountTooltip = (props: { currency: ?string, point: $DataPoint }) => {
    const { point, currency } = props;
    return (
        <div>
            <FormattedNumber value={point.value} style={'currency'} currency={currency} /> —{' '}
            <span class='greyed'>
                <FormattedNumber value={point._count} />
                {' transactions'}
            </span>
        </div>
    );
};

const PercentTooltip = (props: { point: $DataPoint }) => {
    const { point } = props;
    return (
        <div>
            <FormattedNumber
                value={point.value}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                style={'percent'}
            />{' '}
            —{' '}
            <span class='greyed'>
                <FormattedNumber value={point._count} />
                {' transactions'}
            </span>
        </div>
    );
};

const DefaultTooltip = (props: { point: $DataPoint }) => {
    const { point } = props;
    return (
        <div>
            <FormattedNumber value={point.value} /> transactions
        </div>
    );
};
