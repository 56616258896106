// flow
import React from 'react';
import { connect } from 'react-redux';
import type { $Dispatcher, $State } from '../../util/Types';
import ModalFooter from '../../components/ModalFooter';
import type { $ReportsState } from './reducer';
import * as Actions from './actions';
import Select from 'react-select';

type $GatewayConfiguration = {
    id: string,
    name: string,
};

type Props = {
    gatewayConfigurations: {
        configurations: Array<$GatewayConfiguration>,
    } & $State,
    reportsDetails: $ReportsState,
} & $Dispatcher;

class UploadModal extends React.Component {
    props: Props;

    openModal = () => {
        const { dispatch } = this.props;
        dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: (
                    <div class="row">
                        <div class="medium-12 columns text-center">Reports upload</div>
                    </div>
                ),
                content: (
                    <form id="report-gateway-modal-form" onSubmit={this.startUpload}>
                        <ConnectedModalContent />
                    </form>
                ),
                footer: (
                    <ModalFooter
                        submitCallback={this.startUpload}
                        submitTitle="Upload"
                        formId="report-gateway-modal-form"
                    />
                ),
            },
        });
    };

    startUpload = (e: Event) => {
        e.preventDefault();
        const { reportsDetails } = this.props;
        this.props.dispatch(
            Actions.initUpload(
                reportsDetails.selectedFiles,
                reportsDetails.selectedGatewayConfiguration,
            ),
        );
    };

    render() {
        return null;
    }
}

export default connect(
    store => {
        return {
            reportsDetails: store.reports,
            gatewayConfigurations: store.processorsConfigurations,
        };
    },
    null,
    null,
    { withRef: true },
)(UploadModal);

class ModalContent extends React.Component {
    props: Props;

    render() {
        const { dispatch, gatewayConfigurations, reportsDetails } = this.props;
        return (
            <div className="row">
                <div className="medium-12 columns">
                    <div className="row">
                        <div className="medium-12 columns">
                            <label>Gateway configuration</label>

                            <Select
                                value={reportsDetails.selectedGatewayConfiguration || null}
                                options={gatewayConfigurations.configurations.map(config => {
                                    return { label: config.name, value: config.id };
                                })}
                                creatable={false}
                                clearable={false}
                                placeholder="Select a gateway configuration"
                                onChange={newValue => {
                                    this.props.dispatch(
                                        Actions.selectGatewayConfiguration(newValue.value),
                                    );
                                }}
                                required={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ConnectedModalContent = connect(store => {
    return {
        reportsDetails: store.reports,
        gatewayConfigurations: store.processorsConfigurations,
    };
})(ModalContent);
