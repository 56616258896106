// @flow

import type { $DataPoint, $ChartType } from '../../analytics/Boards/consts';

export const REQUEST_SURFACES_FETCH = 'REQUEST_SURFACES_FETCH';

export type $Recommendation = {
    id: string,
    improvement_percentage: number,
    count: number,
    name: string,
    authorization_rate_gain: number,
    money_impact: number,
    estimated_gain: number,
    description: string,
    datapoints_with_gain: Array<$DataPoint>,
    datapoints_without_gain: Array<$DataPoint>,
    corresponding_filter: string,
    type: string,
};

export type $Surface = {
    name: string,
    type: string,
    recuperable: boolean,
    description: string,
    count: number,
    chart_name: string,
    chart_type: $ChartType,
    authorization_rate_impact: number,
    authorization_rate: number,
    suggested_fixes: string,
    total_money_impact: ?number,
    total_estimated_gain: number,
    datapoints: {
        possible_gain: Array<$DataPoint>,
        current_performance: Array<$DataPoint>,
    },
    threshold_datapoints: Array<$DataPoint>,
    associated_recommendations: ?Array<$Recommendation>,
    corresponding_filter: string,
    tag: 'recuperable' | 'medium' | 'hard',
    clickable: boolean,
    id: string,
    is_new: boolean,
};

export type $Surfaces = {
    surface_explained: number,
    total_failed: number,
    surfaces: Array<$Surface>,
    generating: ?boolean,
    validated: boolean,
};
