import React from 'react';
import { FormattedDate } from 'react-intl';

export default class Data extends React.Component {
    constructor() {
        super();
    }

    render() {
        const event = this.props.event;

        if (event.id == 0) {
            return <div />;
        }

        return (
            <div class="row">
                <div class="large-12 columns">
                    <div class="row small-margin-bottom greyed">
                        <div class="large-2 medium-3 columns">ID</div>
                        <div class="large-10 medium-9 columns font-console">{event.id}</div>
                    </div>
                    <div class="row small-margin-bottom">
                        <div class="large-2 medium-3 columns greyed">Name</div>
                        <div class="large-10 medium-9 columns font-console">{event.name}</div>
                    </div>
                    <div class="row small-margin-bottom">
                        <div class="large-2 medium-3 columns greyed">Date</div>
                        <div class="large-10 medium-9 columns">
                            <FormattedDate
                                value={event.fired_at}
                                day="numeric"
                                month="long"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                seconds="2-digit"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
