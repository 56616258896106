// @flow
import React from 'react';
import { connect } from 'react-redux';
import ContentLayout from '../../pages/ContentLayout';
import type { $RoutingRulesSettings } from './consts';
import type { $Dispatcher, $State } from '../../util/Types';
import Rules from './RoutingRuleListing';
import * as Actions from './actions';
import Loading from '../../components/Loader';
// $FlowFixMe
import './routingRules.scss';
import * as ProcessOut from '../../util/ProcessOut';
import Error from '../../components/Error';
import { arrayMove } from 'react-sortable-hoc';
import NotPermitted from '../Permissions/NotPermitted';
import RoutingRules from './RoutingRules';
import BlockingRules from './BlockingRules';
import TriggerRules from './TriggerRules';

type Props = {
    routingRulesSettings: $RoutingRulesSettings & $State,
} & $Dispatcher;

class RulesIndex extends React.Component<Props> {
    componentDidMount() {
        this.props.dispatch(Actions.prepareRoutingRulesSettings());
        document.title = 'Routing rules | ProcessOut';
    }

    render() {
        const { routingRulesSettings } = this.props;
        return (
            <ContentLayout title={<h4>Routing rules</h4>}>
                {!routingRulesSettings.fetched || routingRulesSettings.fetching ? (
                    <Loading />
                ) : routingRulesSettings.error ? (
                    routingRulesSettings.error.notPermitted ? (
                        <NotPermitted />
                    ) : (
                        <Error />
                    )
                ) : (
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <BlockingRules
                                rules={routingRulesSettings.rules.filter(
                                    rule => rule.declaration === 'block',
                                )}
                            />

                            <TriggerRules
                                rules={routingRulesSettings.rules.filter(
                                    rule => rule.declaration === 'trigger_3ds',
                                )}
                            />
                            <RoutingRules
                                rules={routingRulesSettings.rules.filter(
                                    rule => rule.declaration === 'route',
                                )}
                            />
                        </div>
                    </div>
                )}
            </ContentLayout>
        );
    }
}

export default connect(store => {
    return { routingRulesSettings: store.routingRulesSettings };
})(RulesIndex);
