// @flow
import React from 'react';
import { VELOCITY_FIELDS } from './consts';
import Select from 'react-select-v2';
import { SelectStyle } from '../../analytics/DataExplorer/ChartBuilder/consts';

type FieldProps = {
    onChange: string => void,
    value: ?string,
    suffix?: string,
    disabled: ?boolean,
    options?: ?Array<$Option>,
    allowMetadata?: ?boolean,
    shouldDisplayApplyButton?: ?boolean,
};

type $Option = { label: string, value: string, desc: string };

class VelocityPath extends React.Component<FieldProps> {
    renderOption = (option: $Option) => {
        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <div class='row'>
                        <div class='large-12 columns'>{option.label}</div>
                    </div>
                    <div class='row small-font' style={{ opacity: 0.7 }}>
                        <div class='large-12 columns'>{option.desc}</div>
                    </div>
                </div>
            </div>
        );
    };

    updateValue = (newValue: { label: string, value: string }) => {
        const { onChange } = this.props;
        onChange(newValue.value);
    };

    render() {
        const { options, value, suffix } = this.props;

        const OPTIONS = VELOCITY_FIELDS;
        const correspondingValue = VELOCITY_FIELDS.find(op => op.value === value);
        let label = value;
        if (correspondingValue) label = correspondingValue.label;

        return (
            <div class='labeled-field' style={{ width: '75%' }}>
                <Select
                    options={OPTIONS}
                    placeholder='Card fingerprint...'
                    value={value ? { label, value } : null}
                    onChange={this.updateValue}
                    disabled={this.props.disabled}
                    styles={SelectStyle}
                />
            </div>
        );
    }
}

export default VelocityPath;
