// @flow

import type { $Action } from '../../../util/Types';
import type { $BoardPreset } from './reducer';
import Analytics from '../../../util/Analytics';

export const SELECT_BOARD_PRESET = 'SELECT_BOARD_PRESET';
export function selectBoardPreset(preset: $BoardPreset): $Action {
    Analytics('BOARD_PRESET_SELECTED', { name: preset.name });
    return {
        type: SELECT_BOARD_PRESET,
        payload: {
            preset,
        },
    };
}

export const REQUEST_BOARD_PREVIEW = 'REQUEST_BOARD_PREVIEW';
export type $RequestBoardPreviewAction = {
    type: string,
    payload: {
        board: $BoardPreset,
    },
};
export function requestBoardPreview(board: $BoardPreset): $RequestBoardPreviewAction {
    return {
        type: REQUEST_BOARD_PREVIEW,
        payload: {
            board,
        },
    };
}

export const UPDATE_BOARDS_PRESETS_SEARCH = 'UPDATE_BOARDS_PRESETS_SEARCH';
export type $BoardsPresetsSearchChange = {
    type: string,
    payload: {
        search: string,
    },
};
export function updateSearch(search: string): $BoardsPresetsSearchChange {
    return {
        type: UPDATE_BOARDS_PRESETS_SEARCH,
        payload: {
            search,
        },
    };
}

export const REQUEST_BOARD_PRESET_SAVE = 'REQUEST_BOARD_PRESET_SAVE';
export type $BoardPresetSaveRequest = {
    type: string,
    payload: {
        preset: $BoardPreset,
    },
};
export function saveBoardPreset(preset: $BoardPreset): $BoardPresetSaveRequest {
    Analytics('BOARD_PRESET_SAVED', { name: preset.name });
    return {
        type: REQUEST_BOARD_PRESET_SAVE,
        payload: { preset },
    };
}

export const RESET_BOARD_CREATOR = 'RESET_BOARD_CREATOR';
export function resetBoardCreator(): $Action {
    return {
        type: RESET_BOARD_CREATOR,
    };
}
