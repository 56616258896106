// @flow

import React from 'react';
import { BulletNumber } from './Adyen';

type Props = {
    hide: boolean,
    gatewayId: string,
    projectId: string,
    ext?: any,
};

type State = {
    hmacKey: string,
    gatewayId: string,
};

export default class Second extends React.Component<Props, State> {
    constructor() {
        super();
        let c = '';
        for (; c.length < 32; )
            c += Math.random()
                .toString(36)
                .substr(2, 1);
        this.state = {
            hmacKey: '',
            gatewayId: c,
        };
    }

    hmacUpdated(event: any) {
        this.setState({ hmacKey: event.target.value });
    }

    render() {
        let { hide, projectId } = this.props;
        const { gatewayId } = this.state;

        let input;
        if (!hide) {
            input = (
                <input
                    onChange={this.hmacUpdated.bind(this)}
                    type='text'
                    defaultValue={this.state.hmacKey}
                    name='hmac_key'
                    placeholder='Adyen webhook HMAC key'
                    className='bottom-border processor-input'
                    required
                />
            );
        } else {
            input = (
                <div>
                    <input type='hidden' name='conf_id' value={gatewayId} />
                    <input
                        type='hidden'
                        name='hmac_key'
                        placeholder='Adyen webhook HMAC key'
                        className='bottom-border processor-input'
                        value={this.state.hmacKey}
                    />
                </div>
            );
        }

        let domain = 'api.processout.com';
        if (this.props.ext) domain = 'transaction-intelligence.com';

        let content;
        if (!hide) {
            content = (
                <div className='medium-12 columns'>
                    <div class='row margin-bottom'>
                        <div class='large-12 columns'>
                            <h4>Adyen configuration setup</h4>
                        </div>
                    </div>
                    <div className='row margin-bottom'>
                        <div className='medium-1 columns'>
                            <BulletNumber value={3} />
                        </div>
                        <div className='medium-11 columns greyed'>
                            <label className='bold margin-bottom greyed'>Enable webhooks</label>
                            <p>
                                Enabling webhooks allows ProcessOut to be notified of new payments
                                and payment updates, allowing Telescope to also work on transactions
                                not processed by ProcessOut. To set it up, go to your Adyen
                                dashboard, and click on Settings, then for Standard Notification
                                click Add.
                            </p>
                        </div>
                    </div>
                    <div className='row margin-bottom'>
                        <div className='medium-1 columns'>
                            <BulletNumber value={4} />
                        </div>
                        <div className='medium-11 columns greyed'>
                            <label className='bold greyed margin-bottom'>
                                Setup the ProcessOut webhook
                            </label>
                            <pre>
                                <code
                                    style={{
                                        overflow: 'auto',
                                        border: '1px solid #cacaca',
                                        backgroundColor: '#f4f5fb',
                                    }}
                                >
                                    https://{domain}/handlers/webhooks/project/{projectId}
                                    /gway_conf_{gatewayId}
                                </code>
                            </pre>
                        </div>
                    </div>
                    <div className='row margin-bottom'>
                        <div className='medium-1 columns'>
                            <BulletNumber value={5} />
                        </div>
                        <div className='medium-11 columns greyed'>
                            <label className='bold greyed margin-bottom'>Make sure that</label>
                            <lu>
                                <li>the active checkbox is checked</li>
                                <li>the Method dropbox is set to JSON.</li>
                                <li>SSL Version is left to SSL</li>
                                <li>Service Version is left to 1</li>
                                <li>Populate SOAP action header is left unchecked.</li>
                                <li>Click on Select All in Additional Settings.</li>
                            </lu>
                        </div>
                    </div>
                    <div className='row '>
                        <div className='medium-1 columns'>
                            <BulletNumber value={6} />
                        </div>
                        <div className='medium-11 columns greyed'>
                            <label className='bold greyed margin-bottom'>
                                Specify your HMAC key
                            </label>
                            <div className='row margin-bottom'>
                                <div className='large-12 columns greyed'>
                                    In additional Settings, Click on Generate HMAC key, and enter it
                                    below.
                                </div>
                            </div>
                            <div className='row margin-bottom'>
                                <div className='large-12 columns greyed'>{input}</div>
                            </div>
                        </div>
                    </div>
                    <div className='row margin-bottom'>
                        <div className='medium-1 columns'>
                            <BulletNumber value={7} />
                        </div>
                        <div className='medium-11 columns greyed'>
                            <label className='bold greyed margin-bottom'>
                                Setup the notification webhooks
                            </label>
                            <p>
                                In Settings go to server communication then Report notification and
                                put the same link as given in step 4. Make sure that active is
                                checked and leave everything else by default.
                            </p>
                        </div>
                    </div>
                </div>
            );
        } else {
            content = <div>{input}</div>;
        }

        return <div className='row'>{content}</div>;
    }
}
