import React from 'react';
import { FormattedNumber, ReactIntl } from 'react-intl';
import { connect } from 'react-redux';
import PlansModal from './PlansModal';
import PropTypes from 'prop-types';

var blockStyle = {
    display: 'block',
};

@connect(function(store) {
    return {};
})
export default class Plan extends React.Component {
    static propTypes = {
        plan: PropTypes.object.isRequired,
    };

    constructor() {
        super();
        this.state = {
            showDetails: false,
        };
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    toggle() {
        this.setState({
            showDetails: !this.state.showDetails,
        });
    }

    displayPlan(event) {
        event.preventDefault();
        this._modal.getWrappedInstance().openModal();
    }

    render() {
        const plan = this.props.plan;
        const id = plan.id;

        var interval = <span>every {plan.interval_days} days</span>;
        if (plan.interval_days == 1) interval = <span>per day</span>;
        else if (plan.interval_days == 7) interval = <span>per week</span>;
        else if (plan.interval_days == 30) interval = <span>per month</span>;
        else if (plan.interval_days == 365) interval = <span>per year</span>;

        return (
            <div>
                <PlansModal modalName='Edit plan' plan={plan} ref={m => (this._modal = m)} />
                <div class='box-row row padding'>
                    <div class='medium-10 columns'>
                        <a onClick={this.displayPlan.bind(this)}>
                            <div class='row'>
                                <div class='medium-2 columns'>
                                    <div class='big-font'>
                                        <span class='greyed'>{plan.currency}</span>{' '}
                                        <FormattedNumber
                                            value={plan.amount}
                                            style='currency'
                                            currency={plan.currency}
                                        />
                                    </div>
                                </div>
                                <div class='medium-2 columns' style={{ marginLeft: '-1em' }}>
                                    {interval}
                                </div>
                                <div class='medium-8 columns'>{plan.name}</div>
                            </div>
                        </a>
                    </div>
                    <div class='medium-2 expanded columns text-right'>
                        <a target='_blank' href={plan.url}>
                            Payment link ↗
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
