import { typeFailed } from '../../util/ProcessOut';

export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case 'FETCH_GATEWAYS_CONFIGURATIONS_PENDING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: false,
            };
        }
        case 'FETCH_GATEWAYS_CONFIGURATIONS_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                count: action.payload.count,
                total_count: action.payload.total_count,
                limit: action.payload.limit,
                page: action.payload.page,
                configurations: action.payload.gateway_configurations,
            };
        }

        case typeFailed('FETCH_GATEWAYS_CONFIGURATIONS'): {
            return {
                fetching: false,
                fetched: false,
                error: action.payload,
            };
        }
    }

    return state;
}
