// @flow
import { put, takeLatest, select } from 'redux-saga/effects';
import * as Actions from './actions';
import { REQUEST_BOARD_DELETION } from './consts';
import type { $Action } from '../../../util/Types';
import { push } from 'react-router-redux';
import * as Sentry from '@sentry/browser';

type Action = {
    payload: {
        projectId: string,
        id: string,
    },
} & $Action;

function* deleteBoard(action: Action): Generator<*, *, *> {
    try {
        yield put.resolve(Actions.deleteBoard(action.payload.id));
        yield put(
            push(`/projects/${action.payload.projectId}/boards?deleted=${action.payload.id}`),
        );
        const boards = yield select(store => store.analytics_v2.boards);
        const remainingBoards = boards.boards.filter(board => board.id !== action.payload.id);
        let boardTarget = remainingBoards.length > 0 ? remainingBoards[0].id : null;
        yield put.resolve(Actions.requestBoardsFetch(boardTarget, true));
    } catch (e) {
        Sentry.captureException(e);
    }
}

export default function* watchForDelete(): Generator<*, *, *> {
    yield takeLatest(REQUEST_BOARD_DELETION, deleteBoard);
}
