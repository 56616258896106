// @flow
import React from 'react';

type Props = {};

type State = {
    name: string,
    type: 'sandbox' | 'production',
};

class NewKeyModal extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            name: '',
            type: 'production',
        };
    }

    getValues = () => {
        return { ...this.state };
    };

    render() {
        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <div class='row small-margin-bottom'>
                        <div class='medium-4 columns'>Name</div>
                        <div class='medium-8 columns'>
                            <input
                                type='text'
                                value={this.state.name}
                                onChange={e => {
                                    this.setState({ name: e.target.value });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewKeyModal;
