// @flow
/**
 * Created by jeremylejoux on 08/06/17.
 */

import React from 'react';
import { connect } from 'react-redux';
import Error from '../../Error';
import ApplePayState from './ApplePayState';
import ApplePayDomainNames from './ApplePayDomainNames';
import ContentLayout from '../../../pages/ContentLayout';
import Loader from '../../Loader';
import { push } from 'react-router-redux';
import * as Store from '../../../stores/Store';
import * as Actions from '../../../Actions/ApplePayActions';

export class ApplePayOverview extends React.Component {
    props: Props;

    static defaultProps = {
        dispatch: null,
    };

    _unsubscribe: () => void;

    componentDidMount() {
        const { currentProject, dispatch, params } = this.props;
        this._unsubscribe = Store.store.subscribe(this.storeDidUpdate.bind(this));
        if (currentProject.fetched && !currentProject.project.applepay_settings) {
            if (this._unsubscribe) this._unsubscribe();
            dispatch(push(`/projects/${params.project}/settings/apple-pay/step-1`));
        }
    }

    storeDidUpdate() {
        let state = Store.store.getState();
        let { dispatch, params } = this.props;

        if (state.currentProject.fetched && !state.currentProject.project.applepay_settings) {
            if (this._unsubscribe) this._unsubscribe();
            dispatch(push(`/projects/${params.project}/settings/apple-pay/step-1`));
        }
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    render() {
        let { currentProject } = this.props;

        if (currentProject.error) return <Error />;

        if (!currentProject.fetched || currentProject.fetching)
            return (
                <ContentLayout title={<h4>Apple Pay</h4>}>
                    <Loader />
                </ContentLayout>
            );

        return (
            <div>
                <ApplePayState />
                <ApplePayDomainNames />
            </div>
        );
    }
}

type Props = {
    params: {
        project: string,
    },
    currentProject: {
        error: boolean,
        fetched: boolean,
        fetching: boolean,
        project?: {
            id: string,
        },
    },
    dispatch: any | (() => void),
};

export default connect(function(store) {
    return {
        currentProject: store.currentProject,
    };
})(ApplePayOverview);
