// @flow
import { put, call } from 'redux-saga/effects';
import * as ProcessOut from '../../../util/ProcessOut';
import {
    CHART_PREVIEW_TEMPLATE,
    computeUrl,
    fetchTelescopeChartData,
    formatBarChart,
} from './sagas';
import {
    GLOBAL_REFUND_FORMULA,
    REFUND_AMOUNT_PER_PSP_BAR,
    REFUNDED_AMOUNT_FORMULA,
    REFUNDED_TRANSACTIONS_FORMULA,
    REFUNDS_PER_COUNTRY_FORMULA,
    VOIDED_AMOUNT_FORMULA,
    VOIDED_TRANSACTIONS_FORMULA,
} from '../consts';
import { fetchChartData } from '../../RoutingRules/actions';
import * as Sentry from '@sentry/browser';

export function* fetchGlobalRefunds(timespan: string): Generator<*, *, *> {
    try {
        const chartData = yield call(fetchTelescopeChartData, GLOBAL_REFUND_FORMULA, timespan);
        return {
            global_refund_percent: chartData[0].value,
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetching global refunds' },
        });

        return null;
    }
}

export function* fetchRefundedAmount(timespan: string): Generator<*, *, *> {
    try {
        const chartData = yield call(fetchTelescopeChartData, REFUNDED_AMOUNT_FORMULA, timespan);
        return {
            refunded_amount: chartData[0].value,
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetching refunded amount' },
        });

        return null;
    }
}

export function* fetchRefundedTransactions(timespan: string): Generator<*, *, *> {
    try {
        const chartData = yield call(
            fetchTelescopeChartData,
            REFUNDED_TRANSACTIONS_FORMULA,
            timespan,
        );
        return {
            transactions_refunded: chartData[0].value,
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetching nbr. refunded trs' },
        });

        return null;
    }
}

export function* fetchRefundsPerCountry(timespan: string): Generator<*, *, *> {
    try {
        const chartData = yield call(
            fetchTelescopeChartData,
            REFUNDS_PER_COUNTRY_FORMULA,
            timespan,
        );
        return {
            refunded_per_country: chartData.map(country => {
                return { ...country, refunds_volume: country.value };
            }),
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetching refunds per country' },
        });

        return null;
    }
}

export function* fetchVoidedAmount(timespan: string): Generator<*, *, *> {
    try {
        const chartData = yield call(fetchTelescopeChartData, VOIDED_AMOUNT_FORMULA, timespan);
        return {
            voided_amount: chartData[0].value,
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetching voided amount' },
        });

        return null;
    }
}

export function* fetchVoidedTransactions(timespan: string): Generator<*, *, *> {
    try {
        const chartData = yield call(
            fetchTelescopeChartData,
            VOIDED_TRANSACTIONS_FORMULA,
            timespan,
        );
        return {
            transactions_voided: chartData[0].value,
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetching nbr. voided trs' },
        });

        return null;
    }
}

export function* fetchRefundAmountPerPspBar(
    timespan: string,
    gatewayConfigurations: Array<{ id: string, name: string }>,
): Generator<*, *, *> {
    try {
        let chartData = yield call(fetchTelescopeChartData, REFUND_AMOUNT_PER_PSP_BAR, timespan);
        chartData = chartData.map(point => {
            const gatewayConfig = gatewayConfigurations.find(config => config.id === point.key);
            if (!gatewayConfig) return null;
            return {
                ...point,
                key: gatewayConfig.name,
            };
        });
        if (chartData.includes(null)) return null;
        return {
            refund_amount_per_psp_bar: formatBarChart(chartData),
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetching refunds per psp' },
        });

        return null;
    }
}
