import React from 'react';
import { Link } from 'react-router';
import { FormattedDate, ReactIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

@connect(function(store) {
    return {
        projects: store.projects,
    };
})
export default class ApiRequest extends React.Component {
    static propTypes = {
        request: PropTypes.object.isRequired,
    };

    render() {
        var request = this.props.request;

        var status;
        if (request.response_code < 300) {
            status = 'success';
        } else if (request.response_code < 500) {
            status = 'warning';
        } else {
            status = 'failed';
        }

        var url = request.url;
        if (url.length > 30) {
            var gex = new RegExp(/(\/[a-z0-9]+)\/.*\/([a-z0-9]+)/i);
            url = url.replace(gex, '$1/.../$2');
            if (url.length > 30) {
                url = `${url.substring(0, 30)}...`;
            }
        }

        var version = 'Unkwown API version';
        if (request.response_headers['Api-Version']) {
            version = request.response_headers['Api-Version'];
        }

        return (
            <Link
                to={
                    '/projects/' +
                    this.props.projects.current_project_id +
                    '/developer/api-requests/' +
                    request.id
                }
            >
                <div class='box-row padding row'>
                    <div class='medium-1 columns'>
                        <div
                            class={`log-level ${status} bold`}
                            style={{
                                margin: '0px',
                                marginTop: '0px',
                                fontSize: '12px',
                            }}
                        >
                            {request.response_code}
                        </div>
                    </div>
                    <div class='medium-1 columns capitalize bold'>{request.method}</div>
                    <div class='medium-2 columns font-console'>{version}</div>
                    <div class='medium-5 columns font-console text-left'>{url}</div>
                    <div class='medium-1 columns font-console greyed text-right'>
                        {`${request.response_ms} ms`}
                    </div>
                    <div class='medium-2 columns greyed'>
                        <div class='right'>
                            <FormattedDate
                                value={request.created_at}
                                day='2-digit'
                                month='2-digit'
                                year='numeric'
                                hour='2-digit'
                                minute='2-digit'
                                seconds='2-digit'
                            />
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}
