import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';

class NotificationStore extends EventEmitter {
    constructor() {
        super();
        this.notification = null;
        this.type = null;
        this.position = null;
    }

    getNotification() {
        return {
            message: this.notification,
            position: this.position,
            type: this.type,
        };
    }

    handleAction(action) {
        switch (action.type) {
            case 'RECEIVE_NOTIFICATION': {
                this.notification = action.notification;
                this.type = action.level;
                this.position = action.position ? action.position : 'tr';
                this.emit('notification');
                break;
            }
        }
    }
}

const notificationStore = new NotificationStore();
Dispatcher.register(notificationStore.handleAction.bind(notificationStore));
export default notificationStore;
