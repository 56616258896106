// @flow
import { all, put, takeLatest } from 'redux-saga/effects';
import { FETCH_PAYMENT_ANALYTICS, REQUEST_PAYMENT_ANALYTICS_FETCH } from './consts';
import type { Action } from '../../../Types/Action';
import * as AnalyticsActions from '../../../Actions/AnalyticsActions';
import { typeFailed, typeFulfilled, typePending } from '../../../util/ProcessOut';
import * as Sentry from '@sentry/browser';

type $ParamsType = {
    from: string,
    to: string,
    filter?: string,
};

type $ActionType = Action & {
    payload: {
        params: $ParamsType,
    },
};

/**
 * we need to fetch every data needed for PaymentAnalytics
 * @param action
 */
function* fetchPaymentAnalytics(action: $ActionType) {
    // Retrieve the parameters (date, filter, groupby)
    const params = action.payload.params;
    const { from, to, filter } = params;

    // Set the current paymentAnalytics state to fetching
    yield put({ type: typePending(FETCH_PAYMENT_ANALYTICS) });

    try {
        // Array of promises we want to resolve
        const promises = {
            // Revenues fetch
            revenues: put.resolve(AnalyticsActions.loadRevenues(from, to, filter)),
            gateways: put.resolve(AnalyticsActions.loadGateways(from, to, filter)),
            countries: put.resolve(AnalyticsActions.loadCountries(from, to, filter)),
            currencies: put.resolve(AnalyticsActions.loadCurrencies(from, to, filter)),
            // Authorizations fetch
            authDates: put.resolve(
                AnalyticsActions.loadAuthorizationRateGrouped(from, to, filter, 'date'),
            ),
            authCountries: put.resolve(
                AnalyticsActions.loadAuthorizationRateGrouped(from, to, filter, 'country'),
            ),
            authGateways: put.resolve(
                AnalyticsActions.loadAuthorizationRateGrouped(from, to, filter, 'gateway'),
            ),
            authCards: put.resolve(
                AnalyticsActions.loadAuthorizationRateGrouped(from, to, filter, 'card'),
            ),
            // Fees fetch
            feesDates: put.resolve(AnalyticsActions.loadFeesGrouped(from, to, filter, 'date')),
            feesCountries: put.resolve(
                AnalyticsActions.loadFeesGrouped(from, to, filter, 'country'),
            ),
            feesGateways: put.resolve(
                AnalyticsActions.loadFeesGrouped(from, to, filter, 'gateway'),
            ),
            feesCards: put.resolve(AnalyticsActions.loadFeesGrouped(from, to, filter, 'card')),
            errors: put.resolve(AnalyticsActions.loadErrorCodes(from, to, filter)),
            failed: put.resolve(AnalyticsActions.loadFailedTransactions(from, to, filter)),
        };

        // Resolving all promises asynchronously
        const results = yield all(promises);
        const countries = results.countries.value.data.points.map(entry => entry.key);

        /*
         * we've got all the data except the benchmark data as its call is really long.
         * We will do it asynchronously (not the use of the put method instead of put.resolve
         * (put will not block and will instantly yield out)
         */
        yield put(AnalyticsActions.fetchBenchmark(countries));

        // Set the current paymentAnalytics state to fetched (while still fetching the benchmark data)
        yield put({ type: typeFulfilled(FETCH_PAYMENT_ANALYTICS) });
    } catch (error) {
        // Set the current paymentAnalytics state to fetched
        yield put({ type: typeFailed(FETCH_PAYMENT_ANALYTICS) });
        Sentry.captureException(error);
    }
}

export function* watchPaymentAnalyticsFetchRequests(): any {
    yield takeLatest(REQUEST_PAYMENT_ANALYTICS_FETCH, fetchPaymentAnalytics);
}
