// @flow
import { call, put, select, takeLatest } from 'redux-saga/effects';
import type { $Aggregation } from '../Aggregator/consts';
import {
    ADD_AGGREGATION,
    ADD_DIMENSION,
    DELETE_AGGREGATION,
    DELETE_DIMENSION,
    FORM_RESET,
    REQUEST_ADD_AGGREGATION,
    REQUEST_ADD_DIMENSION,
    REQUEST_DELETE_AGGREGATION,
    REQUEST_DELETE_DIMENSION,
    REQUEST_FORM_RESET,
    REQUEST_UPDATE_AGGREGATION,
    REQUEST_UPDATE_DIMENSION,
    REQUEST_UPDATE_FORMULA,
    REQUEST_UPDATE_RENDER_FUNCTION,
    UPDATE_AGGREGATION,
    UPDATE_DIMENSION,
    UPDATE_FORMULA,
    UPDATE_RENDER_FUNCTION,
} from './consts';
import Uniqid from 'uniqid';
import { formatFormula } from './actions';
import type { $Dimension } from '../../DataExplorer/ChartBuilder/DimensionSelection/consts';

/**
 * AGGREGATIONS
 */

type $AddAggregationAction = {
    type: string,
    payload: {
        aggregation: $Aggregation,
    },
};
function* addAggregation(action: $AddAggregationAction): Generator<*, *, *> {
    const dispatch: { type: string, payload: { aggregation: $Aggregation } } = {
        type: ADD_AGGREGATION,
        payload: {
            aggregation: action.payload.aggregation,
        },
    };
    yield put(dispatch);
    yield call(updateFormula);
}

type $DeleteAggregationAction = {
    type: string,
    payload: {
        id: string,
    },
};
function* deleteAggregation(action: $DeleteAggregationAction): Generator<*, *, *> {
    yield put({
        type: DELETE_AGGREGATION,
        payload: action.payload,
    });
    yield call(updateFormula);
}

type $UpdateAggregationAction = {
    type: string,
    payload: {
        aggregation: $Aggregation,
    },
};
function* updateAggregation(action: $UpdateAggregationAction): Generator<*, *, *> {
    yield put({
        type: UPDATE_AGGREGATION,
        payload: action.payload,
    });
    yield call(updateFormula);
}

/**
 * DIMENSIONS
 */

type $AddDimensionAction = {
    type: string,
};
function* addDimension(action: $AddDimensionAction): Generator<*, *, *> {
    const dispatch: { type: string, payload: { dimension: $Dimension } } = {
        type: ADD_DIMENSION,
        payload: {
            dimension: {
                id: Uniqid(),
                field: 'transactions.created_at',
                top: null,
                strategy: '',
                formula: 'count{path: transactions}',
            },
        },
    };
    yield put(dispatch);
    yield call(updateFormula);
}

type $DeleteDimensionAction = {
    type: string,
    payload: {
        id: string,
    },
};
function* deleteDimension(action: $DeleteDimensionAction): Generator<*, *, *> {
    yield put({
        type: DELETE_DIMENSION,
        payload: action.payload,
    });
    yield call(updateFormula);
}

type $UpdateDimensionAction = {
    type: string,
    payload: {
        dimension: $Dimension,
    },
};
function* updateDimension(action: $UpdateDimensionAction): Generator<*, *, *> {
    yield put({
        type: UPDATE_DIMENSION,
        payload: action.payload,
    });
    yield call(updateFormula);
}

type $UpdateFormulaAction = {
    type: string,
    payload: ?{
        formula: ?string,
    },
};
function* updateFormula(action: ?$UpdateFormulaAction): Generator<*, *, *> {
    if (action && action.payload && action.payload.formula) {
        yield put({
            type: UPDATE_FORMULA,
            payload: action.payload,
        });
        return;
    }

    const getChartBuilder = state => state.chartBuilder;
    const chartBuilder = yield select(getChartBuilder);
    const dimensions = chartBuilder.dimensions.list;
    let formula = '';
    const dataFormula = chartBuilder.aggregations.mergedFormula;
    for (let i = 0; i < dimensions.length; i++) {
        const strategy = dimensions[i].top ? `strategy: ${dimensions[i].strategy};` : '';
        const top = dimensions[i].top ? `top: ${dimensions[i].top};` : '';
        if (i === 0) {
            formula = `plot{path:${dimensions[i]
                .field}; ${strategy} ${top} formula: ${dataFormula || ''}}`;
        } else {
            formula = `plot{formula: count{path: transactions;};path: ${dimensions[i]
                .field}; ${strategy} ${top} ${formula}}`;
        }
    }
    if (dimensions.length === 0) {
        // no dimensions: this is a single value
        formula = dataFormula;
    }
    yield put({
        type: UPDATE_FORMULA,
        payload: { formula: formatFormula(formula) },
    });
}

type $UpdateRenderFunction = {
    type: string,
    payload: { renderFunction: string },
};
function* updateRenderFunction(action: $UpdateRenderFunction): Generator<*, *, *> {
    yield put({ type: UPDATE_RENDER_FUNCTION, payload: action.payload });
    yield call(updateFormula);
}

function* resetForm(): Generator<*, *, *> {
    yield put({ type: FORM_RESET });
}

export default function* watchForActions(): Generator<*, *, *> {
    yield takeLatest(REQUEST_ADD_AGGREGATION, addAggregation);
    yield takeLatest(REQUEST_DELETE_AGGREGATION, deleteAggregation);
    yield takeLatest(REQUEST_UPDATE_AGGREGATION, updateAggregation);

    yield takeLatest(REQUEST_ADD_DIMENSION, addDimension);
    yield takeLatest(REQUEST_DELETE_DIMENSION, deleteDimension);
    yield takeLatest(REQUEST_UPDATE_DIMENSION, updateDimension);

    yield takeLatest(REQUEST_UPDATE_RENDER_FUNCTION, updateRenderFunction);

    yield takeLatest(REQUEST_FORM_RESET, resetForm);

    yield takeLatest(REQUEST_UPDATE_FORMULA, updateFormula);
}
