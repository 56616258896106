import * as ProcessOut from '../util/ProcessOut';

export function loadCustomers(filter, length, after, id) {
    filter = filter ? filter : '';
    var bound = after ? 'start_after' : 'end_before';
    if (!id) {
        id = '';
    }

    return {
        type: 'FETCH_CUSTOMERS',
        payload: ProcessOut.APIcallPromise(
            `/customers?${bound}=${id}&limit=${length}&filter=${filter}`,
            'GET',
            null,
        ),
    };
}

export function editCustomer(id, customer, callback, error) {
    return {
        type: 'EDITING_CUSTOMER',
        payload: ProcessOut.APIcallPromise(`/customers/${id}`, 'PUT', customer).then(function(
            response,
        ) {
            if (response.data.success) callback();
            else error();
            return response;
        }),
    };
}

export function createCustomer(customer, callback, error) {
    return function(dispatch) {
        dispatch({
            type: 'CREATING_CUSTOMER',
            payload: ProcessOut.APIcallPromise(`/customers`, 'POST', customer).then(function(
                response,
            ) {
                if (response.data.success) {
                    dispatch(loadCustomers('', 15));
                    callback();
                } else error();
                return response;
            }),
        });
    };
}
