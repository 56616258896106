// @flow

import React from 'react';
import Autocomplete from 'react-autocomplete';
import {
    getExpectedTokenTypeAtPosition,
    getLastFieldFromPosition,
    getTokenAtPosition,
    getTokenType,
    insertToken,
    replaceToken,
} from './Util';
import type { $TokenInfo } from './consts';
import {
    FIELD_TOKEN,
    FIELD_TOKENS,
    LOGICAL_TOKEN,
    LOGICAL_TOKENS,
    MENU_STYLE,
    OPERAND_TOKEN,
    OPERAND_TOKENS,
    REQUEST_FETCH_VALUES_FOR_FIELD,
    WRAPPER_STYLE,
} from './consts';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../util/Types';
import getCaretCoordinates from './GetCaretCoordinates';
import Analytics from '../../util/Analytics';
import * as Actions from './actions';
import type { $SearchBarState } from './reducer';

type State = {
    shouldDisplayAllPossibilities: boolean,
    inputFocused: boolean,
    cursorPositionOnKeyDown: number,
};

type Props = $Dispatcher & {
    applyFunc: (reset: boolean, filter: string) => void,
    defaultFilter: ?string,
    document: string,
    searchBarDetails: $SearchBarState,
};

class SearchBar extends React.Component<Props, State> {
    _input: {
        selectionStart: number,
        value: string,
        focus: () => void,
        blur: () => void,
        offsetWidth: number,
    };

    constructor() {
        super();
        this.state = {
            shouldDisplayAllPossibilities: false,
            inputFocused: false,
            cursorPositionOnKeyDown: 0,
        };
    }

    componentDidMount() {
        const { defaultFilter, dispatch } = this.props;
        if (defaultFilter) dispatch(Actions.updateSearchBarFilter(defaultFilter));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(Actions.resetSerachBarFilter());
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.defaultFilter !== nextProps.defaultFilter) {
            const newFilter = nextProps.defaultFilter || '';
            this.props.dispatch(Actions.updateSearchBarFilter(newFilter));
        }
    }

    onSubmit = (event: ?Event) => {
        const { dispatch, applyFunc, searchBarDetails } = this.props;
        if (event) event.preventDefault();
        this._input.blur();
        dispatch(Actions.updateSearchBarAppliedFilter(searchBarDetails.filter));
        applyFunc(false, searchBarDetails.filter);
        Analytics('USED_SEARCH_BAR', { filter: searchBarDetails.filter });
    };

    onChange = (event: { target: { value: string } }) => {
        const { dispatch } = this.props;
        dispatch(Actions.updateSearchBarFilter(event.target.value));
        if (event.target.value === '') this.props.applyFunc(false, '');
    };

    render() {
        const { searchBarDetails } = this.props;
        const type = getExpectedTokenTypeAtPosition(
            this.props.document,
            searchBarDetails.filter,
            this._input ? this._input.selectionStart : 0,
        );
        let items: Array<any>;
        if (searchBarDetails.filter === '') items = FIELD_TOKENS[this.props.document];
        if (this.props.document) {
            switch (type) {
                case FIELD_TOKEN:
                    items = FIELD_TOKENS[this.props.document].sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });
                    break;
                case OPERAND_TOKEN:
                    items = OPERAND_TOKENS;
                    break;
                case LOGICAL_TOKEN:
                    items = LOGICAL_TOKENS;
                    break;
                default:
                    items = (
                        this.props.searchBarDetails[
                            getLastFieldFromPosition(
                                this.props.document,
                                searchBarDetails.filter,
                                this._input ? this._input.selectionStart : 0,
                            )
                        ] || []
                    ).map(item => ({ ...item, value: `"${item.value}"` }));
            }
        } else {
            items = [];
        }
        return (
            <div class='row'>
                <div class={`large-12 columns`} style={{ position: 'relative' }}>
                    <form onSubmit={this.onSubmit}>
                        <div class='row'>
                            <div class='large-12 columns'>
                                {/*Check the doc of AutoComplete at https://github.com/reactjs/react-autocomplete*/}
                                <Autocomplete
                                    autoHighlight={true}
                                    getItemValue={item => item.label}
                                    items={items}
                                    shouldItemRender={item => {
                                        if (this.state.shouldDisplayAllPossibilities) return true;
                                        const tokenAtCursor = getTokenAtPosition(
                                            this.props.document,
                                            searchBarDetails.filter,
                                            this._input ? this._input.selectionStart : 0,
                                        );
                                        if (tokenAtCursor) {
                                            // we check for " " to see if we are at the beginning of a token or "inside" a token
                                            return item.label
                                                .toUpperCase()
                                                .includes(tokenAtCursor.value.trim().toUpperCase());
                                        }
                                        return true;
                                    }}
                                    renderItem={(item, isHighlighted) => {
                                        if (item.value === 'trigger-search') {
                                            return (
                                                <div
                                                    key={item.value}
                                                    style={{
                                                        backgroundColor: isHighlighted
                                                            ? '#f2f9fc'
                                                            : '',
                                                    }}
                                                    class='row'
                                                >
                                                    <div class='large-12 columns' style={{}}>
                                                        {item.label}
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div
                                                key={item.value}
                                                class='row'
                                                style={{
                                                    backgroundColor: isHighlighted ? '#f2f9fc' : '',
                                                    padding: '1em',
                                                }}
                                            >
                                                <div class='large-12 columns'>
                                                    <div class='row'>
                                                        <div class='large-12 columns'>
                                                            {item.label}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class='row small-font'
                                                        style={{ opacity: 1 }}
                                                    >
                                                        <div class='large-12 columns'>
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }}
                                    open={this._input === document.activeElement}
                                    renderInput={props => {
                                        return (
                                            <input
                                                type='text'
                                                size='15'
                                                class='animated-search-bar'
                                                {...props}
                                                placeholder={
                                                    searchBarDetails.appliedFilter ||
                                                    'status == "completed"'
                                                }
                                                ref={el => {
                                                    props.ref(el);
                                                    this._input = el;
                                                }}
                                                onFocus={e => {
                                                    this.setState({
                                                        inputFocused: true,
                                                    });
                                                    props.onFocus(e);
                                                }}
                                                onBlur={e => {
                                                    // we use a timeout to prevent the onblur to unfocus the input before capturing the selection click
                                                    const filter = searchBarDetails.filter;
                                                    setTimeout(() => {
                                                        this.setState({
                                                            inputFocused:
                                                                searchBarDetails.filter !== filter,
                                                        });
                                                    }, 150);
                                                    props.onBlur(e);
                                                }}
                                                onClick={e => {
                                                    this.setState({
                                                        shouldDisplayAllPossibilities: true,
                                                        cursorPositionOnKeyDown: this._input
                                                            .selectionStart,
                                                    });
                                                    props.onClick(e);
                                                }}
                                                onKeyDown={e => {
                                                    if (
                                                        (e.metaKey || e.ctrlKey) &&
                                                        e.keyCode === 13
                                                    ) {
                                                        // cmd + enter or ctrl + enter
                                                        this.onSubmit();
                                                    }
                                                    props.onKeyDown(e);
                                                }}
                                                onKeyUp={e => {
                                                    this.setState({
                                                        shouldDisplayAllPossibilities:
                                                            e.keyCode === 37 ||
                                                            e.keyCode === 39 ||
                                                            (e.keyCode < 48 &&
                                                                this.state
                                                                    .shouldDisplayAllPossibilities),
                                                        cursorPositionOnKeyDown: this._input
                                                            .selectionStart,
                                                    });
                                                }}
                                                style={{
                                                    marginBottom: 0,
                                                    tabIndex: 0,
                                                    zIndex: 6,
                                                }}
                                            />
                                        );
                                    }}
                                    wrapperStyle={WRAPPER_STYLE}
                                    menuStyle={{
                                        ...MENU_STYLE,
                                        transform: this._input
                                            ? `translateX(${Math.min(
                                                  getCaretCoordinates(
                                                      this._input,
                                                      this._input.selectionStart,
                                                  ).left,
                                                  this._input.offsetWidth,
                                              )}px)`
                                            : null,
                                    }}
                                    value={searchBarDetails.filter}
                                    onChange={e => {
                                        this.props.dispatch(
                                            Actions.updateSearchBarFilter(e.target.value),
                                        );
                                    }}
                                    onSelect={(val, item) => {
                                        let newValue: ?string;
                                        if (item.value === 'trigger-search') {
                                            this.onSubmit();
                                            return;
                                        }

                                        if (
                                            this._input.value[
                                                this.state.cursorPositionOnKeyDown - 1
                                            ] === ' '
                                        ) {
                                            newValue = insertToken(
                                                searchBarDetails.filter,
                                                item.value,
                                            );
                                        } else {
                                            newValue = replaceToken(
                                                this.props.document,
                                                searchBarDetails.filter,
                                                item.value,
                                                this._input.selectionStart,
                                            );
                                        }
                                        if (newValue) {
                                            this.props.dispatch(
                                                Actions.updateSearchBarFilter(newValue),
                                            );
                                        }
                                        const tokenInfos: $TokenInfo = FIELD_TOKENS[
                                            this.props.document
                                        ].find(item => item.label === val);
                                        if (
                                            getTokenType(this.props.document, val) ===
                                                FIELD_TOKEN &&
                                            tokenInfos &&
                                            tokenInfos.canFetchValues
                                        ) {
                                            this.props.dispatch({
                                                type: REQUEST_FETCH_VALUES_FOR_FIELD,
                                                payload: {
                                                    field: val,
                                                    projectId: '',
                                                    document: this.props.document,
                                                },
                                            });
                                        }
                                        // we do this because the event handler on `onBlur` will unfocus the input
                                        this._input.focus();
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { searchBarDetails: store.searchBar };
})(SearchBar);
