// @flow

import * as Sentry from '@sentry/browser';
import * as ProcessOut from '../../util/ProcessOut';
import {
    REQUEST_PAYOUT_DETAILS_FETCH,
    REQUEST_PAYOUT_ITEMS_FETCH,
    REQUEST_PAYOUTS_FETCH,
} from './actions';
import { takeLatest, put, select, call } from 'redux-saga/effects';
import * as GatewayActions from '../../Actions/GatewaysActions';

export type $RequestPayoutsFetchAction = {
    type: string,
    payload: {
        length: number,
        after: boolean,
        id: ?string,
    },
};
export function* fetchPayouts(action: $RequestPayoutsFetchAction): Generator<*, *, *> {
    try {
        const { length, after, id } = action.payload;
        const bound = after ? 'start_after' : 'end_before';
        const payoutsResults = yield ProcessOut.APIcallPromise(
            `/payouts?${bound}=${id ||
                ''}&limit=${length}&order=desc&expand[]=gateway_configuration`,
            'GET',
        );
        const payoutsDetails = payoutsResults.data;

        yield put.resolve(GatewayActions.loadGatewaysConfigurations());

        yield put({
            type: ProcessOut.typeFulfilled(REQUEST_PAYOUTS_FETCH),
            payload: payoutsDetails,
        });
    } catch (error) {
        yield put({ type: ProcessOut.typeFailed(REQUEST_PAYOUTS_FETCH), payload: error });
        Sentry.captureException(error);
    }
}

export type $RequestPayoutDetailsFetchAction = {
    type: string,
    payload: {
        id: string,
    },
};
export function* fetchPayoutDetails(action: $RequestPayoutDetailsFetchAction): Generator<*, *, *> {
    try {
        const { id } = action.payload;
        const payoutDetailsResult = yield ProcessOut.APIcallPromise(
            `/payouts/${id}?expand[]=gateway_configuration`,
            'GET',
        );

        yield put({
            type: ProcessOut.typeFulfilled(REQUEST_PAYOUT_DETAILS_FETCH),
            payload: payoutDetailsResult.data,
        });
    } catch (error) {
        yield put({
            type: ProcessOut.typeFailed(REQUEST_PAYOUT_DETAILS_FETCH),
            payload: error,
        });
        Sentry.captureException(error);
    }
}

export type $RequestPayoutItemsFetch = {
    type: string,
    payload: {
        id: string,
        after: boolean,
        length: number,
        afterId: ?string,
    },
};
export function* fetchPayoutItems(action: $RequestPayoutItemsFetch): Generator<*, *, *> {
    try {
        const { id, after, length, afterId } = action.payload;
        const bound = after ? 'start_after' : 'end_before';
        const itemsResult = yield ProcessOut.APIcallPromise(
            `/payouts/${id}/items?${bound}=${afterId || ''}&limit=${length}&order=desc`,
            'GET',
        );

        yield put({
            type: ProcessOut.typeFulfilled(REQUEST_PAYOUT_ITEMS_FETCH),
            payload: itemsResult.data,
        });
    } catch (error) {
        yield put({ type: ProcessOut.typeFailed(REQUEST_PAYOUT_ITEMS_FETCH), payload: error });
        Sentry.captureException(error);
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeLatest(REQUEST_PAYOUTS_FETCH, fetchPayouts);
    yield takeLatest(REQUEST_PAYOUT_DETAILS_FETCH, fetchPayoutDetails);
    yield takeLatest(REQUEST_PAYOUT_ITEMS_FETCH, fetchPayoutItems);
}
