import React from 'react';
import { Link } from 'react-router';
import * as ProcessOut from '../../util/ProcessOut';
import Empty from '../Empty';
import * as Store from '../../stores/Store';
import CountryData from 'country-data';

export default class Customer extends React.Component {
    getLine(key, value) {
        return (
            <div class="row small-margin-bottom">
                <div class="large-4 columns">
                    <span class="greyed">{key}</span>
                </div>
                <div class="large-8 columns">
                    <span class="right">
                        {value && value !== '' ? value : <span class="greyed">--</span>}
                    </span>
                </div>
            </div>
        );
    }

    render() {
        const customer = this.props.customer;

        if (!customer) {
            return <Empty text="No customer data available." />;
        }

        return (
            <div class="row">
                <div class="large-12 columns">
                    <div class="row">
                        <div class="large-7 columns">
                            <h6 class="margin-bottom greyed capitalize font-console">Customer</h6>
                        </div>
                        <div class="large-5 columns text-right">
                            <Link
                                onClick={function(event) {
                                    Store.store.dispatch({
                                        type: 'CLOSE_MODAL',
                                    });
                                }}
                                to={
                                    '/projects/' +
                                    ProcessOut.ProjectId +
                                    '/customers/' +
                                    customer.id
                                }
                                class="greyed"
                            >
                                See customer page ↗
                            </Link>
                        </div>
                    </div>
                    {this.getLine('First name', customer.first_name)}
                    {this.getLine('Last name', customer.last_name)}
                    {this.getLine('Email', customer.email)}
                    {this.getLine('Address', customer.address1)}
                    {this.getLine('City', customer.city)}
                    {this.getLine('Zip', customer.zip)}
                    {this.getLine(
                        'Country Code',
                        customer.country_code
                            ? CountryData.countries[customer.country_code]
                              ? CountryData.countries[customer.country_code].name
                              : customer.country_code
                            : null,
                    )}
                </div>
            </div>
        );
    }
}
