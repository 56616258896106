import React from 'react';
import ContentLayout from './ContentLayout';
import Loader from '../components/Loader';
import Error from '../components/Error';
import * as ApiRequestsActions from '../Actions/ApiRequestsActions';
import { ReactIntl } from 'react-intl';
import { connect } from 'react-redux';
import Empty from '../components/Empty';
import HighLight from '../components/EventsDetails/Highlight';

@connect(store => {
    return {
        details: store.apiRequests.details,
        projects: store.projects,
    };
})
export default class ApiRequestDetails extends React.Component {
    constructor() {
        super();

        this.state = {
            showRequestHeaders: false,
            showResponseHeaders: false,
        };
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Request details | ProcessOut';
        this.props.dispatch(ApiRequestsActions.fetchRequest(this.props.params.request));
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.details.fetching && !nextProps.details.fetched && !nextProps.details.error) {
            this.props.dispatch(ApiRequestsActions.fetchRequest(this.props.params.request));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    toggleRequestHeaders() {
        this.setState({
            showRequestHeaders: !this.state.showRequestHeaders,
        });
    }

    toggleResponseHeaders() {
        this.setState({
            showResponseHeaders: !this.state.showResponseHeaders,
        });
    }

    render() {
        const details = this.props.details;

        if ((details.fetching || !details.fetched) && !details.error) {
            return (
                <ContentLayout title={<h4>Request details</h4>}>
                    <Loader />
                </ContentLayout>
            );
        } else if (details.error) {
            return (
                <ContentLayout title={<h4>Request details</h4>}>
                    <Error />
                </ContentLayout>
            );
        }

        const request = details.request;

        var contentRequest = request.body;
        try {
            contentRequest = JSON.parse(contentRequest);
            contentRequest = JSON.stringify(contentRequest, null, 4);
            contentRequest = contentRequest.replace(
                /"(tr_[a-z0-9]+)"/gi,
                `<a class="json-link" target="_blank" href="#/projects/${
                    this.props.projects.current_project_id
                }/transactions/$1">\"$1 &nearr;\"</a>`,
            );
            contentRequest = contentRequest.replace(
                /"(cust_[a-z0-9]+)"/gi,
                `<a class="json-link" target="_blank" href="#/projects/${
                    this.props.projects.current_project_id
                }/customers/$1">\"$1 &nearr;\"</a>`,
            );
            contentRequest = contentRequest.replace(
                /"(sub_[a-z0-9]+)"/gi,
                `<a class="json-link" target="_blank" href="#/projects/${
                    this.props.projects.current_project_id
                }/recurring/subscriptions/$1">\"$1 &nearr;\"</a>`,
            );
        } catch (err) {
            /*No replacement will be done*/
        }

        var contentResponse = request.response_body;
        try {
            contentResponse = JSON.parse(contentResponse);
            contentResponse = JSON.stringify(contentResponse, null, 4);
            contentResponse = contentResponse.replace(
                /"(tr_[a-z0-9]+)"/gi,
                `<a class="json-link" target="_blank" href="#/projects/${
                    this.props.projects.current_project_id
                }/transactions/$1">\"$1 &nearr;\"</a>`,
            );
            contentResponse = contentResponse.replace(
                /"(cust_[a-z0-9]+)"/gi,
                `<a class="json-link" target="_blank" href="#/projects/${
                    this.props.projects.current_project_id
                }/customers/$1">\"$1 &nearr;\"</a>`,
            );
            contentResponse = contentResponse.replace(
                /"(sub_[a-z0-9]+)"/gi,
                `<a class="json-link" target="_blank" href="#/projects/${
                    this.props.projects.current_project_id
                }/recurring/subscriptions/$1">\"$1 &nearr;\"</a>`,
            );
        } catch (err) {
            /*No replacement will be done*/
        }

        var status;
        if (request.response_code < 300) {
            status = 'success';
        } else if (request.response_code < 500) {
            status = 'warning';
        } else {
            status = 'failed';
        }

        var toggleRequestButton;
        if (!this.state.showRequestHeaders) {
            toggleRequestButton = (
                <div class='text-center bold'>
                    <a
                        onClick={this.toggleRequestHeaders.bind(this)}
                        style={{
                            position: 'relative',
                            top: '-35px',
                            zIndex: '10',
                        }}
                    >
                        Click here to show request headers
                    </a>
                </div>
            );
        }

        var toggleResponseButton;
        if (!this.state.showResponseHeaders) {
            toggleResponseButton = (
                <div class='text-center bold'>
                    <a
                        onClick={this.toggleResponseHeaders.bind(this)}
                        style={{
                            position: 'relative',
                            top: '-35px',
                            zIndex: '10',
                        }}
                    >
                        Click here to show response headers
                    </a>
                </div>
            );
        }

        const headerHiddenStyle = {
            overflow: 'hidden',
            opacity: 0.2,
            height: '60px',
            paddingRight: '20px',
        };

        const headerStyle = {
            overflow: 'auto',
            opacity: 1,
            height: '250px',
            paddingRight: '20px',
        };

        var requestHeaders = Object.keys(request.headers).map(function(key) {
            return { key: key, value: request.headers[key] };
        });
        var responseHeaders = Object.keys(request.response_headers).map(function(key) {
            return { key: key, value: request.response_headers[key] };
        });

        var bodyRequestDisplay;
        if (request.body.length == 0) {
            bodyRequestDisplay = <Empty text='The request body was empty.' />;
        } else {
            bodyRequestDisplay = (
                <HighLight innerHTML={true}>
                    {`<pre><code>${contentRequest}</code></pre>`}
                </HighLight>
            );
        }

        return (
            <ContentLayout title={<h4>Request details</h4>}>
                <div class='row small-margin-bottom'>
                    <div class='large-12 columns text-left font-console'>{request.url}</div>
                </div>
                <div class='row margin-bottom'>
                    <div class='large-6 columns'>
                        <div class='row small-margin-bottom'>
                            <div class='large-3 columns greyed'>Identifier</div>
                            <div class='large-9 columns text-right font-console'>{request.id}</div>
                        </div>
                        <div class='row'>
                            <div class='large-3 columns greyed'>Response time</div>
                            <div class='large-9 columns text-right font-console'>
                                {request.response_ms} ms
                            </div>
                        </div>
                    </div>
                    <div class='large-6 columns'>
                        <div class='row small-margin-bottom'>
                            <div class='large-3 columns greyed'>Method</div>
                            <div class='large-2 large-offset-7 columns capitalize bold text-center'>
                                {request.method}
                            </div>
                        </div>
                        <div class='row small-margin-bottom'>
                            <div class='large-3 columns greyed'>Response code</div>
                            <div class='large-2 large-offset-7 columns'>
                                <div class={`log-level ${status}`}>{request.response_code}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='row margin-bottom'>
                    <div class='large-6 columns'>
                        <h5 class='bold greyed'>Request</h5>
                    </div>
                    <div class='large-6 columns'>
                        <h5 class='bold greyed'>Response</h5>
                    </div>
                </div>
                <div class='row margin-bottom'>
                    <div class='large-6 columns'>
                        <div class='row margin-bottom'>
                            <div class='large-12 columns'>
                                <div
                                    style={
                                        this.state.showRequestHeaders
                                            ? headerStyle
                                            : headerHiddenStyle
                                    }
                                >
                                    {requestHeaders.map(function(entry) {
                                        return (
                                            <div
                                                key={entry.key}
                                                class='row small-margin-bottom small-font'
                                            >
                                                <div class='large-3 columns greyed'>
                                                    {entry.key}
                                                </div>
                                                <div class='large-9 columns text-right font-console'>
                                                    {entry.value}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {toggleRequestButton}
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-12 columns'>{bodyRequestDisplay}</div>
                        </div>
                    </div>
                    <div class='large-6 columns'>
                        <div class='row margin-bottom'>
                            <div class='large-12 columns'>
                                <div
                                    style={
                                        this.state.showResponseHeaders
                                            ? headerStyle
                                            : headerHiddenStyle
                                    }
                                >
                                    {responseHeaders.map(function(entry) {
                                        return (
                                            <div
                                                key={entry.key}
                                                class='row small-margin-bottom small-font'
                                            >
                                                <div class='large-3 columns greyed'>
                                                    {entry.key}
                                                </div>
                                                <div class='large-9 columns text-right font-console'>
                                                    {entry.value}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {toggleResponseButton}
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-12 columns'>
                                <HighLight innerHTML={true}>
                                    {`<pre><code>${contentResponse}</code></pre>`}
                                </HighLight>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentLayout>
        );
    }
}
