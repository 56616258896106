// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { $DataExplorerState } from './reducer';
import type { $Dispatcher } from '../../../util/Types';
import * as Actions from './actions';

type Props = {
    dataExplorer: $DataExplorerState,
} & $Dispatcher;

class SearchBar extends React.Component {
    props: Props;

    updateSearch = event => {
        this.props.dispatch(Actions.updatePresetsSearch(event.target.value));
    };

    render() {
        const { dataExplorer } = this.props;
        return (
            <div class="search text-left">
                <label for="preset-search" />
                <input
                    type="text"
                    id="preset-search"
                    value={dataExplorer.presetsSearch}
                    onChange={this.updateSearch}
                    placeholder={'Search for a preset'}
                />
            </div>
        );
    }
}

export default connect(store => {
    return { dataExplorer: store.dataExplorer };
})(SearchBar);
