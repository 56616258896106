import React from 'react';
import ContentLayout from './ContentLayout';
import Loader from '../components/Loader';
import Empty from '../components/Empty';
import { FormattedDate, FormattedNumber, ReactIntl } from 'react-intl';
import Customer from '../components/TransactionsDetails/Customer';
import Transaction from '../components/Transaction';
import Pagination from '../components/Pagination';
import * as SubscriptionsActions from '../Actions/SubscriptionsActions';
import { connect } from 'react-redux';
import PlanDetails from '../components/SubscriptionDetails/PlanDetails';
import MetadataDisplay from '../components/MetadataDisplay';
import SubscriptionDetailsLayout from './SubscriptionDetailsLayout';
import * as CouponsActions from '../Actions/CouponsActions';
import Discount from '../components/Discount';
import moment from 'moment';
import PropTypes from 'prop-types';

const TRANSACTIONS_PER_PAGE = 10;

@connect(store => {
    return {
        details: store.subscriptionDetails,
        transactions: store.subscriptionTransactions,
        discounts: store.discounts,
    };
})
export default class SubscriptionDetails extends React.Component {
    static contextTypes = {
        store: PropTypes.object.isRequired,
    };

    componentDidUpdate(prevProps, prevState) {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Subscription | ProcessOut';
        this.props.dispatch(
            SubscriptionsActions.loadSubscriptionDetails(this.props.params.subscription),
        );
        this.props.dispatch(
            SubscriptionsActions.loadSubscriptionTransactions(
                TRANSACTIONS_PER_PAGE,
                this.props.params.subscription,
            ),
        );
        this.props.dispatch(CouponsActions.fetchDiscounts(this.props.params.subscription));
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.details.fetching && !nextProps.details.fetched)
            this.props.dispatch(
                SubscriptionsActions.loadSubscriptionDetails(this.props.params.subscription),
            );
        if (!nextProps.transactions.fetching && !nextProps.transactions.fetched)
            this.props.dispatch(
                SubscriptionsActions.loadSubscriptionTransactions(
                    TRANSACTIONS_PER_PAGE,
                    this.props.params.subscription,
                ),
            );
        if (!nextProps.discounts.fetching && !nextProps.discounts.fetched)
            this.props.dispatch(CouponsActions.fetchDiscounts(this.props.params.subscription));
    }

    constructor() {
        super();
    }

    render() {
        const details = this.props.details;
        if (details.fetching || !details.fetched) {
            return (
                <ContentLayout title={<h4>Subscription details</h4>}>
                    <Loader />
                </ContentLayout>
            );
        }

        const subscription = this.props.details.subscription;

        if (subscription.id == 0) {
            return <SubscriptionDetailsLayout title='Subscription details' />;
        }

        var status;
        if (subscription.activated && subscription.canceled) {
            status = <span class='big-font capitalize bold'>Canceled</span>;
        } else if (subscription.active) {
            if (subscription.active_trial) {
                var today = moment();
                var ending = moment(subscription.trial_end_at);
                var daysLeft = ending.diff(today, 'days');
                status = (
                    <div>
                        <span class='big-font capitalize bold'>Trial</span>
                        <span style={{ marginLeft: '5px' }} class='greyed'>
                            ({daysLeft} days left)
                        </span>
                    </div>
                );
            } else {
                status = <span class='big-font capitalize bold'>Active</span>;
            }
        } else {
            status = <span class='big-font capitalize bold'>Not activated</span>;
        }

        var customer;
        if (subscription.customer) {
            customer = <Customer customer={subscription.customer} />;
        }

        var activationDate;
        var expireDate;
        if (subscription.activated) {
            activationDate = (
                <div class='row small-margin-bottom'>
                    <div class='large-3 medium-3 columns greyed'>Activated on</div>
                    <div class='large-9 medium-9 columns'>
                        <span class='right'>
                            <FormattedDate
                                value={subscription.activated_at}
                                day='2-digit'
                                month='2-digit'
                                year='numeric'
                                hour='numeric'
                                minute='numeric'
                                time='numeric'
                            />
                        </span>
                    </div>
                </div>
            );
            expireDate = (
                <div class='row small-margin-bottom'>
                    <div class='large-4 medium-4 columns greyed'>
                        {subscription.active_trial ? 'Trial end' : 'Next iteration'}
                    </div>
                    <div class='large-8 medium-8 columns'>
                        <span class='right'>
                            <FormattedDate
                                value={
                                    subscription.active_trial
                                        ? subscription.trial_end_at
                                        : subscription.iterate_at
                                }
                                day='2-digit'
                                month='2-digit'
                                year='numeric'
                                hour='numeric'
                                minute='numeric'
                                time='numeric'
                            />
                        </span>
                    </div>
                </div>
            );
        }

        var endedDate;
        var endReason;
        if (subscription.canceled) {
            endedDate = (
                <div class='row small-margin-bottom'>
                    <div class='large-3 medium-3 columns greyed'>Canceled on</div>
                    <div class='large-9 medium-9 columns'>
                        <span class='right'>
                            <FormattedDate
                                value={subscription.canceled_at}
                                day='2-digit'
                                month='2-digit'
                                year='numeric'
                                hour='numeric'
                                minute='numeric'
                                time='numeric'
                            />
                        </span>
                    </div>
                </div>
            );
        }

        const pStyle = {
            fontSize: '3.5em',
            letterSpacing: '0.08em',
            opacity: 0.4,
        };

        var transactions;
        if (this.props.transactions.fetching || !this.props.transactions.fetched) {
            transactions = <Loader />;
        } else if (this.props.transactions.count > 0) {
            transactions = (
                <div class='row'>
                    <div class='large-12 columns'>
                        {this.props.transactions.transactions.map(function(transaction) {
                            return (
                                <div class='row' key={transaction.id}>
                                    <div class='large-12 columns'>
                                        <Transaction
                                            transaction={transaction}
                                            smallDisplay={true}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            transactions = (
                <div class='row small-margin-bottom'>
                    <div class='large-12 columns text-center'>
                        <Empty text='No transaction data available' />
                    </div>
                </div>
            );
        }

        var cancelReason;
        if (subscription.canceled) {
            cancelReason = (
                <div class='large-6 columns' style={{ wordBreak: 'break-word' }}>
                    <div class='right'>
                        <label>Reason</label>
                        <span>
                            {subscription.cancellation_reason != ''
                                ? subscription.cancellation_reason
                                : 'Unknown'}
                        </span>
                    </div>
                </div>
            );
        }

        var interval;
        if (subscription.interval_days === 365) interval = 'yearly';
        else if (subscription.interval_days === 30) interval = 'monthly';
        else if (subscription.interval_days === 7) interval = 'weekly';
        else if (subscription.interval_days === 1) interval = 'daily';
        else interval = `${subscription.interval_days} days`;

        var futureCancel;
        if (subscription.pending_cancellation) {
            futureCancel = (
                <div style={{ color: 'orange', marginTop: '15px' }}>
                    <span class='bold'>Will cancel on</span>{' '}
                    <FormattedDate
                        value={subscription.cancel_at}
                        day='2-digit'
                        month='2-digit'
                        year='numeric'
                        hour='numeric'
                        minute='numeric'
                        time='numeric'
                    />
                </div>
            );
        }

        var plandetails;
        if (subscription.plan) {
            plandetails = (
                <div>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <h6 class='margin-bottom capitalize greyed font-console'>Plan</h6>
                        </div>
                        <div class='large-12 columns'>
                            <PlanDetails plan={subscription.plan} />
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <hr />
                        </div>
                    </div>
                </div>
            );
        }

        var discounts;
        if (this.props.discounts.fetched && this.props.discounts.discounts.length > 0) {
            discounts = (
                <div>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <h6 class='margin-bottom capitalize greyed bold'>Discounts</h6>
                        </div>
                        <div class='large-12 columns'>
                            {this.props.discounts.discounts.map(function(discount) {
                                return (
                                    <div class='row' key={discount.id}>
                                        <div class='large-12 columns'>
                                            <Discount
                                                discount={discount}
                                                currency={subscription.currency}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <hr />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <SubscriptionDetailsLayout title='Subscription details'>
                <div class='row margin-bottom'>
                    <div class='large-5 columns'>
                        <div class='details-box'>
                            <div class='row'>
                                <div class='large-4 columns'>
                                    <label>Amount</label>
                                    <span class='huge-font bold'>
                                        <FormattedNumber
                                            value={subscription.amount}
                                            style='currency'
                                            currency={subscription.currency}
                                        />
                                    </span>
                                </div>
                                <div class='large-4 columns'>
                                    <div class='right'>
                                        <label>Interval</label>
                                        <span class='big-font'>{interval}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='large-7 columns' style={{ paddingRight: 0 }}>
                        <div class='details-box'>
                            <div class='row'>
                                <div class={`large-${cancelReason ? 6 : 12} columns`}>
                                    <label>Status</label>
                                    {status}
                                    {futureCancel}
                                </div>
                                {cancelReason}
                            </div>
                        </div>
                    </div>
                </div>
                <div class='row'>
                    <div class='large-5 columns left-border'>
                        <div class='row'>
                            <div class='large-12 columns'>
                                <h6 class='margin-bottom capitalize greyed font-console'>
                                    Details
                                </h6>
                            </div>
                            <div class='large-12 columns'>
                                <div class='row'>
                                    <div class='large-12 medium-8 columns'>
                                        <div class='row small-margin-bottom'>
                                            <div class='large-3 medium-3 columns greyed'>
                                                Identifier
                                            </div>
                                            <div class='large-9 medium-9 columns'>
                                                <span class='right font-console'>
                                                    {subscription.id}
                                                </span>
                                            </div>
                                        </div>
                                        <div class='row small-margin-bottom'>
                                            <div class='large-3 medium-3 columns greyed'>Name</div>
                                            <div class='large-9 medium-9 columns'>
                                                <span class='right'>{subscription.name}</span>
                                            </div>
                                        </div>
                                        <div class='row small-margin-bottom'>
                                            <div class='large-3 medium-3 columns greyed'>
                                                Created on
                                            </div>
                                            <div class='large-9 medium-9 columns'>
                                                <span class='right'>
                                                    <FormattedDate
                                                        value={subscription.created_at}
                                                        day='2-digit'
                                                        month='2-digit'
                                                        year='numeric'
                                                        hour='numeric'
                                                        minute='numeric'
                                                        time='numeric'
                                                    />
                                                </span>
                                            </div>
                                        </div>

                                        {activationDate}
                                        {expireDate}
                                        {endedDate}

                                        <div class='row small-margin-bottom'>
                                            <div class='large-3 medium-3 columns greyed'>
                                                Currency
                                            </div>
                                            <div class='large-9 medium-9 columns'>
                                                <span class='right'>{subscription.currency}</span>
                                            </div>
                                        </div>
                                        <div class='row small-margin-bottom'>
                                            <div class='large-12 medium-9 columns'>
                                                {subscription.metadata.length > 0 ? (
                                                    <MetadataDisplay
                                                        metadata={subscription.metadata}
                                                        currency={subscription.currency}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-12 columns' />
                        </div>
                        <div class='row'>
                            <div class='large-12 columns'>
                                <hr />
                            </div>
                        </div>
                        {customer}
                    </div>
                    <div class='large-7 columns'>
                        {plandetails}
                        {discounts}

                        <div class='row margin-bottom'>
                            <div class='large-12 columns'>
                                <div class='row'>
                                    <div class='large-12 columns'>
                                        <h6 class='margin-bottom capitalize greyed font-console'>
                                            Transactions history
                                        </h6>
                                    </div>
                                </div>
                                {transactions}
                                <Pagination
                                    smallContainer={true}
                                    reloadFunction={SubscriptionsActions.loadSubscriptionTransactions.bind(
                                        null,
                                        TRANSACTIONS_PER_PAGE,
                                        this.props.params.subscription,
                                    )}
                                    hasMore={
                                        this.props.transactions.fetched
                                            ? this.props.transactions.has_more
                                            : false
                                    }
                                    location={this.props.location}
                                    itemsArray={
                                        this.props.transactions.fetched
                                            ? this.props.transactions.transactions
                                            : []
                                    }
                                    hide={
                                        this.props.transactions.fetching ||
                                        !this.props.transactions.fetched
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </SubscriptionDetailsLayout>
        );
    }
}
