// @flow

import React from 'react';
import CountryData from 'country-data';

type Props = {
    country_code: string,
};

class CountryFlag extends React.PureComponent {
    props: Props;
    render() {
        const { country_code } = this.props;

        return (
            <span style={{ float: 'right', marginTop: '-5px', opacity: 0.5 }}>
                <img
                    title={
                        CountryData.countries[country_code]
                            ? CountryData.countries[country_code].name
                            : country_code
                    }
                    src={`//dashboard.processout.com/images/flags-iso/flat/32/${country_code}.png`}
                />
            </span>
        );
    }
}

export default CountryFlag;
