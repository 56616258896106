import React from 'react';
import { connect } from 'react-redux';
import Name from './InvoiceEdit/Name';
import Prices from './InvoiceEdit/Prices';
import Data from './InvoiceEdit/Data';
import Metadata from './Metadata';
import * as InvoicesActions from '../Actions/InvoicesActions';
import ModalFooter from './ModalFooter';
import PropTypes from 'prop-types';

var serialize = require('form-serialize');

@connect(
    function(store) {
        return {
            currenciesDetails: store.currencies,
            invoiceDetails: store.invoiceDetails,
        };
    },
    null,
    null,
    { withRef: true },
)
export default class InvoiceModal extends React.Component {
    static propTypes = {
        modalName: PropTypes.string.isRequired,
        invoice: PropTypes.object,
        customer: PropTypes.string,
    };

    constructor() {
        super();

        this.state = {
            create: false,
            showDetails: false,
        };
    }

    componentDidMount() {
        if (this.props.invoice) {
            this.id = this.props.invoice.id;
        }
        this.create = !this.props.invoice;
        if (this.create) {
            this.props.dispatch({ type: 'START_INVOICE_CREATION' });
        }

        this.setState({ create: this.create, customer: this.props.customer });
    }

    createResponse(response) {
        this._modalFooter.getWrappedInstance().unlock();
        if (response.success) {
            this.props.dispatch({ type: 'CLOSE_MODAL' });
        }
    }

    save(event) {
        event.preventDefault();

        this._modalFooter.getWrappedInstance().lock();

        var invoiceForm = document.querySelector('#invoice-form');
        var data = serialize(invoiceForm, { hash: true });

        if (!data.metadata) data.metadata = {};

        data.metadata['request_email'] = data.request_email;
        data.metadata['request_shipping'] = data.request_shipping;

        var metas = this._meta.updateValue();
        for (var i = 0; i < metas.length; i++) {
            data.metadata[metas[i].key] = metas[i].value;
        }

        if (data.metadata && Object.keys(data.metadata).length == 0) {
            delete data.metadata;
        }
        if (this.props.recurring) {
            data.customer_id = this.props.customer;
        }

        if (!data.amount) {
            data.amount = 0;
        }
        data.amount = parseFloat(data.amount);
        data.amount = data.amount + '';
        var invoice = JSON.stringify(data);
        if (this.state.create) {
            this.props.dispatch(
                InvoicesActions.createInvoice(invoice, this.createResponse.bind(this)),
            );
        } else {
            this.props.dispatch(
                InvoicesActions.editInvoice(
                    invoice,
                    this.props.invoice.id,
                    this.createResponse.bind(this),
                ),
            );
        }
    }

    deleteInvoice() {
        this._modalFooter.getWrappedInstance().lock();
        if (confirm('Are you sure you want to delete this product?')) {
            this.props.dispatch(InvoicesActions.deleteInvoice(this.props.invoice.id));
            this.props.dispatch({ type: 'CLOSE_MODAL' });
        } else {
            this._modalFooter.getWrappedInstance().unlock();
        }
    }

    openModal() {
        var invoice = this.props.invoice;
        var invoiceUrl;
        var deleteButton;
        if (invoice) {
            invoiceUrl = (
                <a target='_blank' href={invoice.url} class='greyed'>
                    Payment link ↗
                </a>
            );
            deleteButton = (
                <a
                    onClick={this.deleteInvoice.bind(this)}
                    class='round border small button'
                    style={{ opacity: 0.5 }}
                >
                    Delete
                </a>
            );
        }

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: this.props.modalName,
                content: (
                    <div class='row'>
                        <div class='large-12 columns'>
                            <form id='invoice-form' onSubmit={this.save.bind(this)}>
                                <div class='row small-margin-bottom'>
                                    <div class='medium-12 medium-centered columns'>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Details
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <Name invoice={invoice} />
                                                <Prices
                                                    invoice={invoice}
                                                    currencies={
                                                        this.props.currenciesDetails.currencies
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <hr />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Additional data
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <Metadata
                                                    resource={invoice}
                                                    ref={m => (this._meta = m)}
                                                />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <hr />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    KYC handling
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row margin-bottom'>
                                            <div class='large-12 columns'>
                                                <Data invoice={invoice} />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns text-right'>
                                                {invoiceUrl}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ),
                footer: (
                    <ModalFooter
                        ref={m => (this._modalFooter = m)}
                        submitTitle={this.props.invoice ? 'Save' : 'Create'}
                        submitCallback={this.save.bind(this)}
                        deleteCallback={this.props.invoice ? this.deleteInvoice.bind(this) : null}
                    />
                ),
            },
        });
    }

    render() {
        return null;
    }
}
