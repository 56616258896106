// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../../util/Types';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import * as Actions from './actions';
import type { $Period } from './consts';
import { INIT_ANALYTICS_DATES, PERIODS } from './consts';
import type { $FetchParams } from '../Boards/consts';

type Props = {
    params: $FetchParams,
} & $Dispatcher;
type State = {
    fromDate: any,
    toDate: any,
    dateFocused: any,
    compareFromDate: any,
    compareToDate: any,
    compareDateFocused: any,
    editing: boolean,
    comparing: boolean,
    period: $Period,
    comparePeriod: $Period,
};

class DatePicker extends React.Component {
    props: Props;
    state: State;
    _panel: any;

    constructor() {
        super();
        this.state = {
            editing: false,
            fromDate: moment(),
            toDate: moment(),
            dateFocused: null,
            compareFromDate: moment(),
            compareToDate: moment(),
            compareDateFocused: null,
            comparing: false,
            period: 'last-30',
            comparePeriod: 'previous',
        };
    }

    componentDidMount = () => {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.setState({
            comparing: this.props.params.timeCompare.comparing,
            fromDate: moment.unix(this.props.params.interval.from) || moment().subtract(1, 'month'),
            toDate: moment.unix(this.props.params.interval.to) || moment(),
            compareFromDate:
                moment.unix(this.props.params.interval.from).subtract(1, 'months') ||
                moment().subtract(2, 'months'),
            compareToDate:
                moment.unix(this.props.params.interval.from) || moment().subtract(1, 'month'),
        });
        this.props.dispatch({ type: INIT_ANALYTICS_DATES });
    };

    componentWillReceiveProps = (nextProps: Props) => {
        if (nextProps.params.timeCompare.comparing !== this.state.comparing)
            this.setState({
                comparing: nextProps.params.timeCompare.comparing,
            });
    };

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this._panel && !this._panel.contains(event.target)) {
            this.setState({ editing: false });
        }
    };

    onDatesChange = (dates: any) => {
        this.setState({
            fromDate: dates.startDate,
            toDate: dates.endDate,
            period: 'custom',
        });
    };

    onFocusChange = (focused: any) => {
        this.setState({
            dateFocused: focused,
        });
    };

    onCompareDatesChange = (dates: any) => {
        this.setState({
            compareFromDate: dates.startDate,
            compareToDate: dates.endDate,
        });
    };

    onCompareFocusChange = (focused: any) => {
        this.setState({
            compareDateFocused: focused,
        });
    };

    onApply = () => {
        let { fromDate, toDate } = this.state;
        if (!fromDate || !toDate) return;

        // We set the hour and minute for the startDate and endDate to be at the beginning and the end of each date
        fromDate.set({ hour: 0, minute: 0, second: 0 });
        toDate.set({ hour: 23, minute: 59, second: 59 });

        this.props.dispatch(Actions.updateAnalyticsDates(fromDate, toDate));

        if (this.state.comparePeriod !== 'custom') {
            const newCompareDates: {
                from: any,
                to: any,
            } = Actions.computeCompareDatesFromPeriod(
                fromDate,
                toDate,
                this.state.period,
                this.state.comparePeriod,
            );
            this.setState({
                compareFromDate: newCompareDates.from,
                compareToDate: newCompareDates.to,
            });
            this.props.dispatch(
                Actions.changeCompareDate(newCompareDates.from.unix(), newCompareDates.to.unix()),
            );
        } else {
            let { compareFromDate, compareToDate } = this.state;
            // We set the hour and minute for the startDate and endDate to be at the beginning and the end of each date
            compareFromDate.set({ hour: 0, minute: 0, second: 0 });
            compareToDate.set({ hour: 23, minute: 59, second: 59 });

            this.props.dispatch(
                Actions.changeCompareDate(compareFromDate.unix(), compareToDate.unix()),
            );
        }

        this.setState({ editing: false });
        this.props.dispatch(Actions.setComparing(this.state.comparing));
    };

    cancel = () => {
        this.setState({ editing: false });
    };

    render() {
        const panel = this.state.editing ? (
            <div
                ref={el => {
                    this._panel = el;
                }}
                class={`row text-left ${this.state.editing ? '' : 'hide'}`}
            >
                <div class="large-12 columns">
                    <div class="date-range-picker">
                        <div class="row small-margin-bottom">
                            <div class="large-12 columns">
                                <div class="row collapse">
                                    <div class="large-6 end columns">
                                        <label>Date range</label>
                                        <select
                                            style={{
                                                fontSize: '.8em',
                                                height: '100%',
                                            }}
                                            value={this.state.period}
                                            onChange={event => {
                                                const period = event.target.value;
                                                if (period !== 'custom') {
                                                    const newDates = Actions.computeDatesFromPeriod(
                                                        event.target.value,
                                                    );
                                                    const newCompareDate = Actions.computeCompareDatesFromPeriod(
                                                        newDates.from,
                                                        newDates.to,
                                                        period,
                                                        this.state.comparePeriod,
                                                    );
                                                    this.setState({
                                                        period: event.target.value,
                                                        fromDate: newDates.from,
                                                        toDate: newDates.to,
                                                        compareFromDate: newCompareDate.from,
                                                        compareToDate: newCompareDate.to,
                                                    });
                                                } else {
                                                    this.setState({
                                                        period,
                                                    });
                                                }
                                            }}
                                        >
                                            {PERIODS.map(
                                                (period: { value: string, label: string }) => {
                                                    return (
                                                        <option
                                                            key={period.value}
                                                            value={period.value}
                                                        >
                                                            {period.label}
                                                        </option>
                                                    );
                                                },
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div class="row collapse">
                                    <div class="large-12 columns">
                                        <DateRangePicker
                                            startDate={this.state.fromDate}
                                            endDate={this.state.toDate}
                                            focusedInput={this.state.dateFocused}
                                            onDatesChange={this.onDatesChange}
                                            onFocusChange={this.onFocusChange}
                                            isOutsideRange={() => false}
                                            minimumNights={0}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="large-12 columns">
                                <div
                                    style={{
                                        display: 'inline-block',
                                        marginRight: '.5em',
                                    }}
                                >
                                    <input
                                        id="compare_checkbox"
                                        type="checkbox"
                                        onChange={event => {
                                            this.setState({
                                                comparing: event.target.checked,
                                            });
                                        }}
                                        checked={this.state.comparing}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        margin: '0 .5em 0 .5em',
                                    }}
                                >
                                    <label for="compare_checkbox">compare to:</label>
                                </div>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        margin: '0 .5em 0 .5em',
                                    }}
                                >
                                    <select
                                        name="compare-period"
                                        disabled={!this.state.comparing}
                                        style={{
                                            height: '100%',
                                            fontSize: '.8em',
                                        }}
                                        value={this.state.comparePeriod}
                                        onChange={event => {
                                            const period = event.target.value;
                                            if (period !== 'custom') {
                                                const newDates = Actions.computeCompareDatesFromPeriod(
                                                    this.state.fromDate,
                                                    this.state.toDate,
                                                    this.state.period,
                                                    event.target.value,
                                                );
                                                this.setState({
                                                    compareFromDate: newDates.from,
                                                    compareToDate: newDates.to,
                                                    comparePeriod: period,
                                                });
                                            } else {
                                                this.setState({
                                                    comparePeriod: period,
                                                });
                                            }
                                        }}
                                    >
                                        <option value="previous">Previous period</option>
                                        <option value="previous-year">Previous year</option>
                                        <option value="custom">Custom period</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div
                            class={`row margin-bottom ${this.state.comparing &&
                            this.state.comparePeriod === 'custom'
                                ? ''
                                : 'hide'}`}
                        >
                            <div class="large-12 columns">
                                <label>Compare period</label>
                                <DateRangePicker
                                    startDate={this.state.compareFromDate}
                                    endDate={this.state.compareToDate}
                                    focusedInput={this.state.compareDateFocused}
                                    onDatesChange={this.onCompareDatesChange}
                                    onFocusChange={this.onCompareFocusChange}
                                    isOutsideRange={() => false}
                                />
                            </div>
                        </div>
                        <div class="row small-margin-bottom">
                            <div class="large-4 columns large-offset-4">
                                <a
                                    class="expanded round main button small-font"
                                    onClick={this.onApply}
                                >
                                    Apply
                                </a>
                            </div>
                            <div class="large-4 columns">
                                <a
                                    class="expanded round border button small-font"
                                    onClick={this.cancel}
                                >
                                    cancel
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;

        return (
            <div class="row">
                <div class="large-12 columns">
                    <div class="row" style={{ paddingTop: '.5em' }}>
                        <div class="large-12 columns">
                            <a
                                class={`date-range-input ${this.state.editing ? 'selected' : ''}`}
                                onClick={() => {
                                    this.setState({
                                        editing: !this.state.editing,
                                    });
                                }}
                            >
                                {moment.unix(this.props.params.interval.from).format('ll')} -{' '}
                                {moment.unix(this.props.params.interval.to).format('ll')}
                            </a>
                        </div>
                    </div>
                    {panel}
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        params: store.analytics.params,
    };
};

export default connect(mapStateToProps)(DatePicker);
