// @flow
import React from 'react';
import AnalyticsLayout from '../../../pages/AnalyticsLayout';
import ContentLayout from '../../../pages/ContentLayout';
import { connect } from 'react-redux';
import type { $BoardCreatorState } from './reducer';
import type { $Dispatcher, $OnChangeEvent } from '../../../util/Types';
import { CHANGE_BOARD_CREATOR_NAME } from './reducer';
import Presets from './Presets';
// $FlowFixMe
import './BoardsPresets.scss';
import Loading from '../../../components/Loader';
import BoardPreviewer from './BoardPreviewer';
import * as BoardsActions from '../Boards/actions';
import * as Actions from './actions';
import type { $FetchParams } from '../Boards/consts';
import moment from 'moment';

type Props = {
    location: any,
    params: {
        project: string,
    },
    boardCreator: $BoardCreatorState,
    fetchParams: $FetchParams,
} & $Dispatcher;

class BoardCreator extends React.Component {
    props: Props;

    componentDidMount() {
        this.props.dispatch(Actions.resetBoardCreator());
    }

    onNameChange = (event: $OnChangeEvent) => {
        this.props.dispatch({
            type: CHANGE_BOARD_CREATOR_NAME,
            payload: { name: event.target.value },
        });
    };

    onBoardCreate = (event: Event) => {
        event.preventDefault();
        this.props.dispatch(
            BoardsActions.createBoard(this.props.params.project, this.props.boardCreator.name, ''),
        );
    };

    render() {
        const boardCreator: $BoardCreatorState = this.props.boardCreator;
        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <ContentLayout
                        title={
                            <div class='row'>
                                <div class='medium-12 columns'>
                                    <h4>Create a brand new board</h4>
                                </div>
                            </div>
                        }
                        style={{ marginBottom: '1em' }}
                    >
                        <div class='row'>
                            <div class='large-12 columns'>
                                <p>
                                    Get a fresh start with a new empty board and add your own charts
                                    to it
                                </p>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-4 columns'>
                                <input
                                    type='text'
                                    placeholder='Board name'
                                    value={boardCreator.name}
                                    onChange={this.onNameChange}
                                />
                            </div>
                            <div class='large-2 columns end'>
                                <a class='round border small button' onClick={this.onBoardCreate}>
                                    Create
                                </a>
                            </div>
                        </div>
                    </ContentLayout>
                    <ContentLayout
                        title={
                            <div className='row'>
                                <div className='medium-12 columns'>
                                    <h4>Use boards created by our merchants community</h4>
                                </div>
                            </div>
                        }
                        style={{
                            padding: 0,
                            marginBottom: '1em',
                        }}
                    >
                        <Presets />
                    </ContentLayout>
                    {boardCreator.fetched || boardCreator.fetching ? (
                        <ContentLayout
                            title={
                                <span>
                                    <h4 style={{ display: 'inline-block', marginRight: '.5em' }}>
                                        Preview
                                    </h4>
                                    <p class='greyed' style={{ display: 'inline-block' }}>
                                        <span>
                                            {moment
                                                .unix(this.props.fetchParams.interval.from)
                                                .calendar()}
                                        </span>{' '}
                                        —{' '}
                                        <span>
                                            {moment
                                                .unix(this.props.fetchParams.interval.to)
                                                .calendar()}
                                        </span>
                                    </p>
                                </span>
                            }
                            style={{ marginBottom: '1em' }}
                        >
                            <BoardPreviewer />
                        </ContentLayout>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { boardCreator: store.boardCreator, fetchParams: store.analytics.params };
})(BoardCreator);
