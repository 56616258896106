// @flow
/**
 * Created by jeremylejoux on 13/09/2017.
 */

import * as Store from '../stores/Store';

export default function Analytics(title: string, parameters: any) {
    let state = Store.store.getState();
    if (!state.user.details.fetched) return;

    analytics.track(title, parameters);
}
