import React from 'react';

export default class Name extends React.Component {
    constructor() {
        super();
    }

    render() {
        const invoice = this.props.invoice;

        var name;
        if (invoice != null) {
            name = invoice.name;
        }

        var recurring;
        if (this.props.recurring) {
            recurring = (
                <div>
                    <div class="row small-margin-bottom">
                        <div class="large-6 medium-6 columns">
                            <input
                                type="number"
                                placeholder="Interval"
                                name="interval_days"
                                class="bottom-border"
                                defaultValue={this.props.interval_days}
                                required
                            />
                        </div>
                        <div class="large-6 medium-6 columns">
                            <p style={{ marginTop: '15px' }}>Days</p>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div class="row">
                    <div class="large-12 medium-8 columns">
                        <input
                            type="text"
                            placeholder="Name"
                            name="name"
                            class="bottom-border"
                            id="item-name"
                            defaultValue={name}
                            required
                        />
                    </div>
                </div>

                {recurring}
            </div>
        );
    }
}
