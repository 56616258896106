// @flow

export const REQUEST_BOARDS_FETCH = 'REQUEST_BOARDS_FETCH';
export const FETCH_BOARDS = 'FETCH_BOARDS';

export const CREATE_BOARD = 'CREATE_BOARD';
export const REQUEST_BOARD_DELETION = 'REQUEST_BOARD_DELETION';
export const DELETE_BOARD = 'DELETE_BOARD';
export const REQUEST_BOARD_EDITION = 'REQUEST_BOARD_EDITION';
export const EDIT_BOARD = 'EDIT_BOARD';
export const CHANGE_EDIT_BOARD_MODE = 'CHANGE_EDIT_BOARD_MODE';

export const REQUEST_CHARTS_FETCH = 'REQUEST_CHARTS_FETCH';
export const FETCH_CHART = 'FETCH_CHART';
export const FETCH_CHARTS = 'FETCH_CHARTS';
export const DELETE_CHART = 'DELETE_CHART';

export const REQUEST_UPDATE_BOARD_GRID = 'REQUEST_UPDATE_CHART_GRID';
export const UPDATE_BOARD_GRID = 'UPDATE_CHART_GRID';

export const CLEAR_BOARS_LIST = 'CLEAR_BOARS_LIST';

export type $Board = {
    charts: Array<$Chart>,
    comparison_selector?: ?$ComparisonSelector,
    duplicate_from?: string, // id from which we duplicate a board
} & $BaseModel;

export type $ComparisonSelector = { formula: string, plotted_fields?: string };

export type $ChartBase = {
    type: $ChartType,
    position_x: number,
    position_y: number,
    is_comparison?: boolean,
    size: number,
    settings: { formula: string, plotted_fields?: string },
    unit: string,
    x_axis_format?: string,
    board_id: string,
    text_markdown?: string,
    boardName: string,
    height: number,
};

export type $Chart = $ChartBase & $BaseModel;

export type $DataPoint = {
    key: string,
    value: any,
    _corresponding_filter: ?string,
    _count: number,
    lineName?: ?string,
    datapoints: Array<$DataPoint>,
};

export type $DataChart = $Chart & {
    data: Array<$DataPoint>,
};

export type $ChartType =
    | $TIME_SERIES
    | $LINE_CHART
    | $BAR_CHART
    | $PIE_CHART
    | $SINGLE_VALUE
    | $MAP_CHART
    | $TEXT_CHART;

export type $TIME_SERIES = 'time-series';
export type $LINE_CHART = 'line-chart';
export type $BAR_CHART = 'bar-chart';
export type $PIE_CHART = 'pie-chart';
export type $SINGLE_VALUE = 'single-value';
export type $MAP_CHART = 'map-chart';
export type $TEXT_CHART = 'text';

export const TIME_SERIES = 'time-series';
export const LINE_CHART = 'line-chart';
export const BAR_CHART = 'bar-chart';
export const PIE_CHART = 'pie-chart';
export const SINGLE_VALUE = 'single-value';
export const MAP_CHART = 'map-chart';
export const TEXT_CHART = 'text';

type $BaseModel = {
    id: string,
    name: string,
    description: string,
    created_at?: string,
};

export type $ChartDisplay = $Chart & {
    data: Array<$DataPoint>,
    board_id: string,
    is_last_on_row: boolean,
    value: number,
    label: any,
    is_last_on_line: boolean,
};

type $BoardRow = Array<$ChartDisplay>;

export type $BoardDisplay = {
    rows: Array<$BoardRow>,
    board: $Board,
};

export type $FetchParams = {
    filter: string,
    interval: {
        from: number,
        to: number,
    },
    timeCompare: {
        comparing: boolean,
        from: number,
        to: number,
    },
};

export type $ChartRenderer = {
    data: Array<$DataPoint>,
    currency?: string,
    tip?: number => string,
    plotted_field: ?string,
    type: string,
    format?: string,
    name: string,
};
