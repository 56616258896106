// @flow

export const VELOCITY_FIELDS = [
    { value: 'card_uid', label: 'Card ID' },
    { value: 'card_fingerprint', label: 'Card fingerprint' },
    { value: 'customer_uid', label: 'Customer ID' },
    { value: 'customer_email', label: 'Customer email' },
];

export const VELOCITY_INTERVAL_OPTIONS = [
    { label: '1 minute', value: '1m' },
    { label: '5 minutes', value: '5m' },
    { label: '10 minutes', value: '10m' },
    { label: '30 minutes', value: '30m' },
    { label: '1 hour', value: '1h' },
    { label: '5 hours', value: '5h' },
    { label: '10 hours', value: '10h' },
    { label: '24 hours', value: '1d' },
];
