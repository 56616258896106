// @flow
import React from "react";
import Select from "react-select-v2";
import type { $Operand } from "./consts";
import { SelectStyle } from "../consts";

const GLOBAL_OPTIONS = [
    { label: "==", value: "==" },
    { label: "!=", value: "!=" },
    { label: "<", value: "<" },
    { label: "<=", value: "<=" },
    { label: ">", value: ">" },
    { label: ">=", value: ">=" },
    { label: "is null", value: "is-null" },
    { label: "is not null", value: "is-not-null" },
];

const KEYWORD_OPTIONS = [
    { label: "==", value: "==" },
    { label: "!=", value: "!=" },
    { label: "is null", value: "is-null" },
    { label: "is not null", value: "is-not-null" },
];

type Props = {
    onChange: $Operand => void,
    value: $Operand,
    isKeywordOnly: boolean,
};

class Operand extends React.Component<Props> {
    valueChanged = (option: { value: $Operand }) => {
        let { onChange } = this.props;
        if (onChange) onChange(option.value);
    };

    render() {
        let { value } = this.props;
        const options = this.props.isKeywordOnly ? KEYWORD_OPTIONS : GLOBAL_OPTIONS;
        return (
            <div class="labeled-field" style={{ width: "100px" }}>
                <Select
                    options={options}
                    isSearcheable={false}
                    value={{ label: value, value }}
                    styles={SelectStyle}
                    onChange={this.valueChanged}
                />
            </div>
        );
    }
}

export default Operand;
