import {
    FETCH_DUNNING_SETTINGS_FULFILLED,
    FETCH_DUNNING_SETTINGS_PENDING,
} from '../../Actions/ProjectsActions';

export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case FETCH_DUNNING_SETTINGS_PENDING: {
            return {
                ...state,
                fetched: false,
                fetching: true,
            };
        }

        case FETCH_DUNNING_SETTINGS_FULFILLED: {
            return {
                ...state,
                dunning_configurations: action.payload.project.dunning_configuration,
                fetched: true,
                fetching: false,
            };
        }
    }

    return state;
}
