import React from 'react';
import { FormattedNumber, ReactIntl } from 'react-intl';
import CouponModal from './CouponModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

@connect(function(store) {
    return {};
})
export default class Coupon extends React.Component {
    static propTypes = {
        coupon: PropTypes.object.isRequired,
    };

    displayCoupon(event) {
        event.preventDefault();
        this._modal.getWrappedInstance().openModal();
    }

    render() {
        const coupon = this.props.coupon;

        var amount;
        if (coupon.amount_off !== '0' && coupon.percent_off === 0) {
            amount = (
                <FormattedNumber
                    value={coupon.amount_off}
                    style='currency'
                    currency={coupon.currency}
                />
            );
        } else {
            amount = <span>{coupon.percent_off}%</span>;
        }

        var lasts;
        if (coupon.iteration_count > 1)
            lasts = <span>Lasts {coupon.iteration_count} iterations once applied</span>;
        else if (coupon.iteration_count > 0) lasts = <span>Applies to one iteration only</span>;
        else lasts = <span>Lasts forever once applied</span>;

        var redemptions;
        if (coupon.max_redemptions === 0) redemptions = <span>Unlimited possible redemptions</span>;
        else if (coupon.max_redemptions === 1)
            redemptions = <span>Only one possible redemption</span>;
        else redemptions = <span>{coupon.max_redemptions} maximum redemptions</span>;

        return (
            <div>
                <CouponModal modalName='Edit coupon' coupon={coupon} ref={m => (this._modal = m)} />
                <a onClick={this.displayCoupon.bind(this)}>
                    <div class='box-row row padding'>
                        <div class='medium-2 columns'>
                            <div class='big-font'>{amount}</div>
                            <div class='greyed'>
                                {coupon.amount_off !== '0' && coupon.percent_off === 0
                                    ? coupon.currency
                                    : 'FROM TOTAL'}
                            </div>
                        </div>
                        <div class='medium-5 columns'>
                            <div class='font-console'>{coupon.id}</div>
                            <div class='greyed'>{lasts}</div>
                        </div>
                        <div class='medium-5 columns'>
                            <div>Redeemed {coupon.redeemed_number} time(s)</div>
                            <div class='greyed'>{redemptions}</div>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}
