// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { $PermissionsState } from './reducer';
import type { $Dispatcher } from '../../util/Types';
import ContentLayout from '../../pages/ContentLayout';
import PermissionGroup from './PermissionGroup';
import * as Actions from './actions';
import uniqid from 'uniqid';
import type { $PermissionGroupsState } from './PermissionGroupsReducer';
import Loading from '../../components/Loader';

type Props = {
    permissionsGroupsDetails: $PermissionGroupsState,
} & $Dispatcher;

class PermissionGroups extends React.Component {
    props: Props;

    addGroup = () => {
        const { dispatch } = this.props;
        dispatch(Actions.addPermissionGroup());
    };

    saveGroups = () => {
        const { dispatch } = this.props;
        dispatch(Actions.savePermissionGroups());
    };

    render() {
        const { permissionsGroupsDetails } = this.props;
        return (
            <ContentLayout title={<h4>Permission groups</h4>}>
                <div class="row small-margin-bottom">
                    <div class="medium-12 columns">
                        {permissionsGroupsDetails.permissionGroups
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(group => (
                                <PermissionGroup key={group.id} permissionGroupName={group.name} />
                            ))}
                    </div>
                </div>
                <div class="row">
                    <div class="medium-2 medium-offset-7 columns">
                        <a class="small round border button expanded" onClick={this.addGroup}>
                            Add new group
                        </a>
                    </div>
                    <div class="medium-3 columns text-right">
                        {permissionsGroupsDetails.saving ? (
                            <Loading size={14} />
                        ) : (
                            <a
                                className="round main small button expanded"
                                onClick={this.saveGroups}
                            >
                                Save permission groups
                            </a>
                        )}
                    </div>
                </div>
            </ContentLayout>
        );
    }
}

export default connect(store => {
    return {
        permissionsGroupsDetails: store.permissions.permissionGroups,
    };
})(PermissionGroups);
