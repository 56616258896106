// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { $Dispatcher, $State } from '../../../util/Types';
import type { $BoardDisplay } from './consts';
import { replace } from 'react-router-redux';
import { WIPE_CURRENT_BOARD } from './Board/consts';
import Welcome from './Welcome/Welcome';
import { withRouter } from 'react-router';

type Props = {
    boards: {
        boards: $BoardDisplay,
    } & $State,
    params: {
        project: string,
    },
} & $Dispatcher;

class IndexBoard extends React.Component {
    props: Props;

    componentDidMount() {
        const { boards, dispatch } = this.props;
        dispatch({ type: WIPE_CURRENT_BOARD });
        if (this.props.location.query.deleted) {
            // we juste deleted a board we don't want to redirect to it
            const boards = this.props.boards.boards.filter(
                board => board.id !== this.props.location.query.deleted,
            );
            if (boards.length > 0)
                this.props.dispatch(
                    replace(`/projects/${this.props.params.project}/boards/${boards[0].id}`),
                );
        } else {
            if (this.props.location.query.redirect) {
                this.props.dispatch(
                    replace(
                        `/projects/${this.props.params.project}/boards/${
                            this.props.location.query.redirect
                        }`,
                    ),
                );
            } else {
                const boards = this.props.boards.boards;
                if (boards.length > 0)
                    this.props.dispatch(
                        replace(`/projects/${this.props.params.project}/boards/${boards[0].id}`),
                    );
            }
        }
    }

    render() {
        return <Welcome />;
    }
}

export default connect(store => {
    return {
        boards: store.analytics_v2.boards,
    };
})(withRouter(IndexBoard));
