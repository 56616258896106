import Dispatcher from '../Dispatcher';
import 'whatwg-fetch';
import * as ProcessOut from './ProcessOut';
import * as Store from '../stores/Store';
import { replace } from 'react-router-redux';
import LocalStorage from 'local-storage';

class Auth {
    isLogged() {
        return Store.store.getState().user.auth.loggedIn;
    }

    logout() {
        const projectid = LocalStorage('projectid');
        LocalStorage.clear();
        LocalStorage('projectid', projectid);
        Store.store.dispatch({ type: 'LOGOUT' });
        Store.store.dispatch(replace('/login'));
    }

    askResetPassword(email, password, token) {
        var body = JSON.stringify(
            {
                email: email,
            },
            null,
            2,
        );

        if (token && password) {
            body = JSON.stringify(
                {
                    email: email,
                    password: password,
                    password_token: token,
                },
                null,
                2,
            );
        }

        fetch(ProcessOut.ProcessOutUrl + '/users/password-reminders', {
            method: token && password ? 'PUT' : 'POST',
            headers: {
                Accept: 'application/json',
                'API-Version': '1.2.0.0',
                'Content-Type': 'application/json',
            },
            body: body,
        })
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                if (json.success) {
                    Dispatcher.dispatch({ type: 'ASK_RESET_PASSWORD' });
                } else {
                    Dispatcher.dispatch({
                        type: 'ASK_RESET_PASSWORD_REJECTED',
                        error: json.message,
                    });
                }
            })
            .catch(function(ex) {
                //Store.store.dispatch(replace('/error'));
                console.log('parsing failed', ex);
            });
    }
}

const authManager = new Auth();
export default authManager;
