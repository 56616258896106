import { DISPLAY_ERROR } from '../features/NotificationBar/consts';

export const ProcessOutUrl = process.env.API_HOST;
export const APIVersion = '1.4.0.0';
export var sandbox = false;
export var ProjectId;
import * as ProcessOut from './ProcessOut';
import Dispatcher from '../Dispatcher';
import Base64 from 'base-64';
import Auth from '../util/Auth';
import { push, replace } from 'react-router-redux';
import * as Store from '../stores/Store';
import axios from 'axios';
import type { $ErrorContext } from '../features/ErrorContext/consts';
import LocalStorage from 'local-storage';
import moment from 'moment';
import { REQUEST_HTTP_CALL } from '../features/NetworkManager/consts';
import Analytics from './Analytics';

export function getCurrentProject() {
    var currentState = Store.store.getState();
    var projects = currentState.projects.projects;
    for (var i = 0; i < projects.length; i++) {
        if (projects[i].id === currentState.projects.current_project_id) return projects[i];
    }
}

export function generateHeaders(projectId) {
    const currentState = Store.store.getState();
    var auth;
    if (!projectId) {
        projectId = currentState.projects.current_project_id;
    }
    if (currentState.user.auth.loggedIn) {
        auth = 'Basic ' + Base64.encode(projectId + ':' + currentState.user.auth.token.token);
    }
    return {
        Accept: 'application/json',
        'X-ProcessOut-Dashboard-Version': process.env.VERSION,
        'Content-Type': 'application/json',
        'API-Version': ProcessOut.APIVersion,
        'Disable-Logging': 'true',
        Authorization: auth,
    };
}

/**
 * Displays an error
 * @param message
 * @param type
 * @param errorContext
 */
export function addNotification(message, type: ?string, errorContext: ?$ErrorContext) {
    Store.store.dispatch({
        type: DISPLAY_ERROR,
        payload: {
            message: message,
            errorContext: errorContext,
            type: type || 'error',
        },
    });
    if (type === 'error') Analytics('DISPLAYED_ERROR', { message });
}

export function APIcall(url, method, body, callback, headers) {
    fetch(ProcessOutUrl + url, {
        method: method,
        headers: headers ? headers : ProcessOut.generateHeaders(),
        body: body,
    })
        .then(function(response) {
            var json = response.json();
            json = fetchStatus(response.status, json);
            return json;
        })
        .then(function(json) {
            console.log(json);
            if (!json.success && json.message) {
                ProcessOut.addNotification(json.message, 'error');
            }
            callback(json);
        })
        .catch(function(ex) {
            console.log('parsing failed', ex);
        });
}

export function APIcallPromise(
    url,
    method,
    body,
    headers,
    disableStatusParsing,
    disableErrorDisplay,
) {
    const req = {
        method: method,
        timeout: 30000,
        headers: headers,
        data: body,
    };

    // we remove test- from url
    if (new RegExp(/.*\/test-proj_\/*/).test(url)) {
        url = url.replace('test-', '');
    }

    return new Promise((resolve, reject) => {
        Store.store.dispatch({
            type: REQUEST_HTTP_CALL,
            payload: {
                url: url,
                request: req,
                resolve: resolve,
                reject: reject,
                disableStatusParsing,
                disableErrorDisplay,
            },
        });
    });
}

export function fetchStatus(status, json) {
    switch (status) {
        case 200: {
            return json;
        }
        case 401: {
            Auth.logout();
            return json;
        }
        case 412: {
            Dispatcher.dispatch({ type: 'TWOFACTOR_NEEDED' });
            return json;
        }
        case 404: {
            Store.store.dispatch(push('/404'));
            return json;
        }
        case 417: {
            return {
                ...json,
                not_permitted: true,
            };
        }
        default:
            return json;
    }
}

export function typePending(type: string): string {
    return `${type}_PENDING`;
}

export function typeFulfilled(type: string): string {
    return `${type}_FULFILLED`;
}

export function typeFailed(type: string): string {
    return `${type}_REJECTED`;
}

export const NETWORK_ERROR = 'Network Error';
