// @flow
import React from 'react';
import { connect } from 'react-redux';
import PayoutItem from './PayoutItem';
import Pagination from '../../components/Pagination';
import ContentLayout from '../../pages/ContentLayout';
import type { $PayoutDetailsState } from './detailsReducer';
import { withRouter } from 'react-router';
import Loading from '../../components/Loader';
import Error from '../../components/Error';
import type { $Dispatcher } from '../../util/Types';
import * as Actions from './actions';
import Empty from '../../components/Empty';

type Props = {
    payoutDetails: $PayoutDetailsState,
    location: any,
    params: {
        project: string,
        payout: string,
    },
} & $Dispatcher;

const ITEMS_PER_PAGE = 10;

class ItemsList extends React.Component {
    props: Props;

    componentDidMount(): void {
        const { dispatch, params } = this.props;
        dispatch(Actions.requestPayoutItemsFetch(params.payout, ITEMS_PER_PAGE, false, null));
    }

    render() {
        const { payoutDetails, params } = this.props;
        const payout = payoutDetails.payout;

        return (
            <ContentLayout
                title={
                    <div className="row medium-collapse">
                        <div className="medium-12 columns">
                            <h5>Transactions</h5>
                        </div>
                    </div>
                }
                style={null}
            >
                <div className="row capitalize greyed bold small-margin-bottom">
                    <div className="medium-1 columns" style={{ fontSize: '.8em' }}>
                        <span>Type</span>
                    </div>
                    <div className="medium-2 columns" style={{ fontSize: '.8em' }}>
                        <span>Amount</span>
                    </div>
                    <div className="medium-2 columns" style={{ fontSize: '.8em' }}>
                        <span>Fee</span>
                    </div>
                    <div className="medium-4 columns" style={{ fontSize: '.8em' }}>
                        <span>Gateway event ID</span>
                    </div>
                    <div class="medium-3 columns text-right" style={{ fontSize: '.8em' }}>
                        <span>Date</span>
                    </div>
                </div>

                <div class="row">
                    <div class="medium-12 columns">
                        {payoutDetails.items.fetching || !payoutDetails.items.fetched ? (
                            <Loading />
                        ) : payoutDetails.items.error ? (
                            <Error />
                        ) : payoutDetails.items.count === 0 ? (
                            <Empty text={'This payout is empty.'} />
                        ) : (
                            payoutDetails.items.items.map(item => (
                                <PayoutItem
                                    payoutItem={item}
                                    key={item.id}
                                    project={params.project}
                                    payout={payout}
                                />
                            ))
                        )}

                        <Pagination
                            reloadFunction={Actions.requestPayoutItemsFetch.bind(
                                null,
                                params.payout,
                                ITEMS_PER_PAGE,
                            )}
                            itemsArray={payoutDetails.items.items}
                            hasMore={payoutDetails.items.has_more}
                            hide={payoutDetails.items.fetching || !payoutDetails.items.fetched}
                            location={this.props.location}
                        />
                    </div>
                </div>
            </ContentLayout>
        );
    }
}

export default connect(store => {
    return {
        payoutDetails: store.payoutDetails,
    };
})(withRouter(ItemsList));
