// @flow

import React from 'react';
import { Link } from 'react-router';
import type { $PayoutItem } from './detailsReducer';
import { FormattedNumber } from 'react-intl';
import type { $Payout } from './reducer';
import moment from 'moment';
import * as Actions from './actions';

type Props = {
    payoutItem: $PayoutItem,
    project: string,
    payout: $Payout,
};

const Wrapper = props =>
    props.transactionId ? (
        <Link to={`/projects/${props.project}/transactions/${props.transactionId}`}>
            {props.children}
        </Link>
    ) : (
        <div>{props.children}</div>
    );

class PayoutItem extends React.PureComponent {
    props: Props;

    render() {
        const { payoutItem, project, payout } = this.props;
        return (
            <Wrapper project={project} transactionId={payoutItem.transaction_id}>
                <div class="row payout-item-row">
                    <div className="medium-1 columns">
                        <span
                            className={`log-level ${payoutItem.type === 'sale'
                                ? 'success'
                                : payoutItem.type === 'chargeback' ? 'failed' : 'warning'}`}
                        >
                            {payoutItem.type}
                        </span>
                    </div>
                    <div class="medium-2 columns">
                        {payoutItem.amount && payoutItem.amount !== '0' ? (
                            Actions.formatCurrency(payoutItem.amount, payout.currency)
                        ) : (
                            <span class="greyed">—</span>
                        )}
                    </div>
                    <div className="medium-2 columns">
                        {payoutItem.fees && payoutItem.fees !== '0' ? (
                            Actions.formatCurrency(payoutItem.fees, payout.currency)
                        ) : (
                            <span class="greyed">—</span>
                        )}
                    </div>
                    <div class="medium-4 columns">
                        <span class="font-console greyed">{payoutItem.gateway_resource_id}</span>
                    </div>
                    <div className="medium-3 columns text-right">
                        {moment(payoutItem.created_at).calendar()}
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default PayoutItem;
