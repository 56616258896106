// @flow
/**
 * Created by jeremylejoux on 09/06/17.
 */

import React from 'react';
import { connect } from 'react-redux';
import Serialize from 'form-serialize';
import { replace } from 'react-router-redux';
import * as ApplePayActions from '../../../Actions/ApplePayActions';
import ContentLayout from '../../../pages/ContentLayout';

export class DomainNameFlow extends React.Component {
    props: Props;

    static defaultProps = {
        dispatch: null,
    };

    componentDidMount() {
        this.isApplePayReady(this.props);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.isApplePayReady(nextProps);
    }

    isApplePayReady(props: Props) {
        let { dispatch, currentProject } = props;
        if (currentProject.fetched && !currentProject.project.applepay_settings)
            dispatch(replace(`/projects/${currentProject.project.id}/settings/apple-pay`));
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    submit(event: Event) {
        event.preventDefault();
        let data = Serialize(event.target, { hash: true });
        let { currentProject, dispatch, params } = this.props;

        dispatch(
            ApplePayActions.addDomain(
                params.project,
                data.merchant_domain_name,
                currentProject.project.applepay_settings.merchant_domain_names || [],
            ),
        );
    }

    render() {
        let separator = (
            <div class="row">
                <div class="large-12 columns">
                    <hr />
                </div>
            </div>
        );

        return (
            <div class="row">
                <form onSubmit={this.submit.bind(this)}>
                    <div class="large-12 columns large-centered">
                        <ContentLayout
                            title={
                                <div>
                                    <div class="row small-margin-bottom">
                                        <div class="large-12 columns">
                                            <h4>Apple Pay domain name setup</h4>
                                        </div>
                                    </div>
                                </div>
                            }
                        >
                            <div class="row margin-bottom">
                                <div class="medium-6 columns">
                                    <p>
                                        Scroll down to "Apple Pay on the Web". Next to "Merchant
                                        Domains", click "Add Domain".
                                    </p>
                                </div>
                                <div class="medium-5 medium-offset-1 columns">
                                    <img src="//dashboard.processout.ninja/images/applepay-4.jpg" />
                                </div>
                            </div>
                            {separator}
                            <div class="row margin-bottom">
                                <div class="medium-6 columns">
                                    <p>
                                        On the next screen, enter the domain name where the payment
                                        page will be hosted. This must be the same domain entered in
                                        the ProcessOut Dashboard. Click "Continue".
                                    </p>
                                </div>
                                <div class="medium-5 medium-offset-1 columns">
                                    <img src="//dashboard.processout.ninja/images/applepay-5.jpg" />
                                </div>
                            </div>
                            {separator}
                            <div class="row margin-bottom">
                                <div class="medium-6 columns">
                                    <p>Download the verification file provided by Apple.</p>
                                </div>
                                <div class="medium-5 medium-offset-1 columns">
                                    <img src="//dashboard.processout.ninja/images/applepay-6.jpg" />
                                </div>
                            </div>
                            {separator}
                            <div class="row margin-bottom">
                                <div class="medium-6 columns">
                                    <p>Enter the domain name.</p>
                                </div>
                                <div class="medium-5 medium-offset-1 columns">
                                    <label>Domain name</label>
                                    <input
                                        type="text"
                                        name="merchant_domain_name"
                                        placeholder="Domain name"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="row margin-bottom">
                                <div class="medium-12 columns text-right">
                                    <input
                                        type="submit"
                                        class="button round border small"
                                        value="Add domain"
                                    />
                                </div>
                            </div>
                        </ContentLayout>
                    </div>
                </form>
            </div>
        );
    }
}

type Props = {
    params: {
        project: string,
    },
    applePay: {
        fetching: boolean,
        fetched: boolean,
        keys: any,
    },
    currentProject: {
        fetched: boolean,
        fetching: boolean,
        project: {
            id: string,
            applepay_settings: {
                merchant_domain_names: Array<string>,
            },
        },
    },
    dispatch: any | (() => void),
};

export default connect(function(store) {
    return {
        currentProject: store.currentProject,
        applePay: store.applePay,
    };
})(DomainNameFlow);
