// @flow
import React from 'react';
import isoCurrencies from 'iso-currencies';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Prices extends React.Component {
    props: {
        hideShippingTaxes: boolean,
        displayLabel: boolean,
        invoice?: any,
        plan?: any,
        currentProject: {
            project?: {
                default_currency: string,
            },
        },
    };
    state: {
        currency: string,
    };

    constructor() {
        super();
        this.state = { currency: 'USD' };
    }

    componentDidMount() {
        const project = this.props.currentProject.project;
        if (project) {
            this.setState({ currency: project.default_currency });
        }
        let resource = this.props.invoice;
        if (this.props.plan) {
            resource = this.props.plan;
        }

        if (!resource && !this.props.plan) return;

        this.setState({ currency: resource.currency });
    }

    currencyChanged() {
        var s = document.getElementById('item-currency');
        var cur = s.options[s.selectedIndex].value;
        this.setState({ currency: cur });
        this.formatPrices(null, cur);
    }

    formatPrices(event, currency) {
        var price = document.querySelector('#item-price');
        if (price.value.includes('.') && price.value[price.value.length - 1] !== '.') {
            var formatted = this.formatAmount(price.value, currency);
            if (formatted) price.value = formatted;
        }
    }

    formatAmount(amount, currency) {
        if (!currency) {
            currency = this.state.currency;
        }

        var precision = amount.split('.')[1].length;
        var currencyInfo = isoCurrencies.information(currency);
        if (precision <= currencyInfo.places) return null;

        amount = parseFloat(amount);
        return amount.toFixed(currencyInfo.places);
    }

    render() {
        var resource = this.props.invoice;
        if (this.props.plan) {
            resource = this.props.plan;
        }

        const currencies = this.props.currencies;

        var name;
        var price = null;
        var currency = this.state.currency;
        if (resource != null) {
            name = resource.name;
            price = parseFloat(resource.amount);
            // We only want to take 2 decimals if the price is exactly equal
            if (price == price.toFixed(2)) price = price.toFixed(2);
        }

        return (
            <div>
                <div class="row small-margin-bottom">
                    <div class="large-12 medium-8 columns">
                        <div class="row small-margin-bottom">
                            <div class="large-6 columns">
                                {this.props.displayLabel ? (
                                    <label class="greyed">Price</label>
                                ) : null}
                                <input
                                    type="text"
                                    id="price-amount"
                                    onInput={this.formatPrices.bind(this)}
                                    name="amount"
                                    disabled={this.props.plan != null}
                                    class="bottom-border no-margin"
                                    placeholder="Amount"
                                    required
                                    id="item-price"
                                    defaultValue={price}
                                />
                            </div>
                            <div class="large-6 columns">
                                {this.props.displayLabel ? (
                                    <label class="greyed">Currency</label>
                                ) : null}
                                <select
                                    name="currency"
                                    class="no-margin bottom-border"
                                    id="item-currency"
                                    value={currency}
                                    onChange={this.currencyChanged.bind(this)}
                                >
                                    {currencies
                                        .sort((curA, curB) => curA.code.localeCompare(curB.code))
                                        .map(function(cur) {
                                            return (
                                                <option key={cur.code} name={cur.code}>
                                                    {cur.code}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { currentProject: store.currentProject };
})(Prices);
