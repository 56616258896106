import React from 'react';

import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import NotificationCenter from '../features/NotificationBar/NotificationCenter';
import PropTypes from 'prop-types';
import Background from '../features/Background/Background';

export default class LayoutNoSideBar extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    render() {
        const containerStyle = {
            paddingTop: '60px',
        };

        return (
            <div class='colorful main-wrapper'>
                <Background />
                <Header />
                <div class='row main-content shadowed rounded-top'>
                    <NotificationCenter />
                    <div
                        class='wrapper large-12 columns shadowed rounded-top'
                        style={containerStyle}
                    >
                        <div class='row'>
                            <div class='large-12 columns panel-content'>{this.props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
