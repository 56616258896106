// @flow

import type { $ProjectCreationPayload } from './sagas';
import type { $Action } from '../../util/Types';
import { REQUEST_PROJECT_CREATION } from './consts';

export function createProject(data: $ProjectCreationPayload): $Action {
    return {
        type: REQUEST_PROJECT_CREATION,
        payload: data,
    };
}
