// @flow
import React from 'react';
import Select from 'react-select';
import { TRANSACTION_FIELDS } from './consts';

type Props = {
    onChange: string => void,
    defaultValue?: string,
    suffix?: string,
    rtl?: ?boolean,
    disabled: ?boolean,
    options?: ?Array<$Option>,
    allowMetadata?: ?boolean,
    shouldDisplayApplyButton?: ?boolean,
};

type $Option = { label: string, value: string, desc: string };

type State = {
    value: ?$Option,
    metadataValue: string,
};

class PlottedField extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            value: { label: '', value: '', desc: '' },
            metadataValue: '',
        };
    }

    componentDidMount() {
        this.setDefaultValue(this.props);
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.defaultValue && nextProps.defaultValue !== this.props.defaultValue)
            this.setDefaultValue(nextProps);
    }

    setDefaultValue = (props: Props) => {
        const { defaultValue, options } = props;

        if (defaultValue) {
            let newValue;
            // we parse the metadata part
            if (defaultValue.includes('metadata')) {
                // we remove the right part of the metadata
                const splits = defaultValue.split('metadata');
                newValue = `${splits[0]}metadata`;
                // splits[1] should be something like ".CHOSEN_KEYWORD"
                const keyword = splits[1].split('.')[1];
                this.setState({ metadataValue: keyword });
            } else {
                newValue = defaultValue;
            }

            let label;
            if (options) {
                const currentOption = options.find(o => o.value === newValue);
                if (currentOption) label = currentOption.label;
            }
            this.setState({
                value: { label: label || newValue, value: newValue, desc: '' },
            });
        }
    };

    updateValue = (newValue: $Option) => {
        this.setState({ value: newValue });
        if (!newValue) return;
        if (newValue.value.includes('metadata')) {
            // we wait for the user to hit the "apply" button
        } else {
            this.props.onChange(newValue ? newValue.value : '');
        }
    };

    updateMetadataValue = (event: Event) => {
        this.setState({ metadataValue: event.target.value });
    };

    submitMetadata = (event: Event) => {
        this.props.onChange(`${this.state.value.value}.${this.state.metadataValue}`);
    };

    renderOption = (option: $Option) => {
        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>{option.label}</div>
                    </div>
                    <div class='row small-font' style={{ opacity: 0.7 }}>
                        <div class='large-12 columns'>{option.desc}</div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { suffix, allowMetadata } = this.props;
        const metadataOption = {
            label: 'metadata',
            value: 'metadata',
            desc: '',
        };
        let options;

        if (this.props.options) {
            options = (allowMetadata ? [metadataOption] : []).concat(this.props.options);
        } else {
            options = Object.keys(
                allowMetadata ? { ...TRANSACTION_FIELDS, metadata: '' } : TRANSACTION_FIELDS,
            ).map(field => {
                return {
                    value: `${suffix || ''}${field}`,
                    label: `${suffix || ''}${field}`,
                    desc: TRANSACTION_FIELDS[field],
                };
            });
        }

        options.sort((a, b) => {
            if (b.label > a.label) return -1;
            return 1;
        });

        const selectInput = (
            <Select
                name='plotted_field'
                id='plotted-field-select-field'
                placeholder='Plotted field...'
                options={options}
                disabled={this.props.disabled}
                optionRenderer={this.renderOption}
                value={this.state.value}
                onChange={this.updateValue}
                clearable={false}
                menuStyle={{ height: '700px' }}
                rtl={this.props.rtl}
            />
        );

        if (this.state.value && this.state.value.value.includes('metadata')) {
            return (
                <div id='plotted-field' class='row'>
                    <div class='medium-5 columns'>{selectInput}</div>
                    <div class={`medium-${this.props.shouldDisplayApplyButton ? 5 : 7} columns`}>
                        <input
                            id='plotted-field-metadata-field-name-input'
                            ref='metadata-field'
                            type='text'
                            placeholder='Customer id...'
                            onChange={this.updateMetadataValue}
                            onBlur={
                                this.props.shouldDisplayApplyButton ? null : this.submitMetadata
                            }
                            value={this.state.metadataValue}
                        />
                    </div>
                    <div class='medium-2 columns'>
                        {this.props.shouldDisplayApplyButton ? (
                            <a
                                class='round border button small'
                                onClick={this.submitMetadata}
                                disabled={
                                    this.props.defaultValue &&
                                    this.props.defaultValue ===
                                        `transactions.metadata.${this.state.metadataValue}`
                                }
                            >
                                Apply
                            </a>
                        ) : null}
                    </div>
                </div>
            );
        } else {
            return (
                <div id='plotted-field' class='row'>
                    <div class='medium-12 columns'>{selectInput}</div>
                </div>
            );
        }
    }
}

export default PlottedField;
