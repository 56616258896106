import React from 'react';
import { Link } from 'react-router';
import Auth from '../util/Auth';
import * as ProcessOut from '../util/ProcessOut';
import AuthStore from '../stores/AuthStore';
import NotificationCenter from '../features/NotificationBar/NotificationCenter';
import Header from '../components/layout/Header';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import * as UserActions from '../Actions/UserActions';
import * as Store from '../stores/Store';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import Background from '../features/Background/Background';

@connect(function(store) {
    return {};
})
export default class Reminder extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
        store: PropTypes.object.isRequired,
    };

    constructor() {
        super();
    }

    componentWillMount() {
        this.asked = this.asked.bind(this);
        this.error = this.error.bind(this);
        AuthStore.on('asked', this.asked);
        AuthStore.on('error', this.error);
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));

        // Add a watcher to redirect when the user will be loggedin
        this.unsubscribeLoginWatcher = Store.store.subscribe(this.loggedIn.bind(this));
    }

    componentWillUnmount() {
        AuthStore.removeListener('asked', this.asked);
        AuthStore.removeListener('error', this.error);
    }

    componentWillUnmount() {
        this.unsubscribeLoginWatcher();
    }

    loginClicked(event) {
        event.preventDefault();
        var loginForm = document.querySelector('#remind-form');
        var login = serialize(loginForm, { hash: true });
        Auth.askResetPassword(login.email);
    }

    error() {
        ProcessOut.addNotification(AuthStore.getError(), 'error');
    }

    asked() {
        if (this.props.location.query.token && this.props.location.token != '') {
            this.props.dispatch(UserActions.generateToken(this.state.email, this.state.password));
            ProcessOut.addNotification('Password changed successfuly.', 'success');
        } else {
            ProcessOut.addNotification('An email has been sent to you.', 'success');
        }
    }

    // Watcher
    loggedIn() {
        var nextState = Store.store.getState();
        if (nextState.user.auth.loggedIn) {
            this.unsubscribeLoginWatcher();
            this.props.dispatch(replace('/projects'));
        }
    }

    reset(event) {
        event.preventDefault();
        var loginForm = document.querySelector('#reset-form');
        var login = serialize(loginForm, { hash: true });
        if (login.password_confirmation != login.password) {
            ProcessOut.addNotification('Passwords are different.', 'error');
        } else {
            this.setState({ email: login.email, password: login.password });
            Auth.askResetPassword(login.email, login.password, login.password_token);
        }
    }

    render() {
        const { location } = this.props;

        var title = 'Password reminder';
        var fields = (
            <div class='row margin-bottom'>
                <div class='large-12 columns'>
                    <div class='content'>
                        <div class='row collapse'>
                            <div class='small-9 large-10 large-centered columns'>
                                <p>
                                    Forgot your password? Drop us your email so our robots can help
                                    you recover it.
                                </p>
                            </div>
                        </div>
                        <form id='remind-form' onSubmit={this.loginClicked.bind(this)}>
                            <div class='row collapse margin-bottom'>
                                <div class='small-9 large-10 large-centered columns'>
                                    <input
                                        type='email'
                                        class='bottom-border'
                                        name='email'
                                        placeholder='Email'
                                        required
                                    />
                                </div>
                            </div>
                            <div class='row'>
                                <div class='medium-5 medium-centered columns'>
                                    <input
                                        type='submit'
                                        class='button round expanded border'
                                        value='Send email'
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );

        if (this.props.location.query.token && this.props.location.token != '') {
            title = 'Password reset';
            fields = (
                <div class='row small-margin-bottom'>
                    <div class='large-12 columns'>
                        <form id='reset-form' onSubmit={this.reset.bind(this)}>
                            <input
                                type='hidden'
                                name='password_token'
                                value={this.props.location.query.token}
                            />
                            <div class='row collapse'>
                                <div class='small-9 large-10 large-centered columns'>
                                    <input
                                        class='bottom-border'
                                        type='email'
                                        name='email'
                                        placeholder='Email'
                                        required
                                    />
                                </div>
                            </div>
                            <div class='row collapse'>
                                <div class='small-9 large-10 large-centered columns'>
                                    <input
                                        class='bottom-border'
                                        type='password'
                                        name='password'
                                        placeholder='Password'
                                        required
                                    />
                                </div>
                            </div>
                            <div class='row collapse small-margin-bottom'>
                                <div class='small-9 large-10 large-centered columns'>
                                    <input
                                        class='bottom-border'
                                        type='password'
                                        name='password_confirmation'
                                        placeholder='Password confirmation'
                                        required
                                    />
                                </div>
                            </div>
                            <div class='row'>
                                <div class='large-5 large-centered columns'>
                                    <input
                                        class='bottom-border'
                                        type='submit'
                                        value='Reset password'
                                        class='button small round border expanded'
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        }

        return (
            <div class='colorful main-wrapper'>
                <Background />
                <Header />
                <div class='row huge-margin-top'>
                    <div class='large-4 medium-4 small-centered columns'>
                        <div class='normal-content rounded shadowed'>
                            <div class='row'>
                                <div class='large-12 small-centered columns'>
                                    <NotificationCenter />
                                </div>
                            </div>
                            <div class='row margin-bottom'>
                                <div class='big-wrapper'>
                                    <div class='large-12 columns'>
                                        <div class='title no-spacing'>
                                            <h3 class='title no-spacing'>{title}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {fields}

                            <div class='row'>
                                <div class='large-12 columns centered margin-bottom text-center'>
                                    Don't have an account?{' '}
                                    <Link to='/register' style={{ textDecoration: 'underline' }}>
                                        Join us now!
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
