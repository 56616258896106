// @flow
import React from 'react';
import Select, { Value } from 'react-select';
import UniqId from 'uniqid';
import SmartRoutingSelector from './SmartRoutingSelector';
import type { $GatewayConfiguration } from '../PaymentProviders/consts';
import type { $SmartRoutingGateway } from './consts';
type Props = {
    gateways: any,
    value: Array<{ id: string, gateway: string } | $SmartRoutingGateway>,
    onChange: (Array<{ id: string, gateway: string }>) => void,
    processors: Array<$GatewayConfiguration>, // contains only cards gateways
};

type $Option = {
    label: string,
    value: string,
};

class GatewaysSelection extends React.Component<Props> {
    onChange = (id: string, newValue: string | $SmartRoutingGateway) => {
        const value = this.props.value.slice();
        const index = value.findIndex(g => g.id === id);
        if (index > -1) {
            if (value[index].gateway !== 'processout') {
                value[index].gateway = 'processout';
                value[index].configurations = ['all'];
            } else if (newValue.gateway) {
                // We're in a case of smart routing
                value[index] = newValue;
            } else {
                value[index] = {
                    id: value[index].id,
                    gateway: newValue,
                };
            }
            this.props.onChange(value);
        }
    };
    render() {
        return (
            <div>
                {' '}
                {this.props.value.map((gateway, index, array) => {
                    return (
                        <div class='row small-margin-bottom' key={gateway.id}>
                            <div class='medium-12 columns'>
                                <div class='row small-margin-bottom'>
                                    <div class='large-7 columns'>
                                        <Gateway
                                            id={gateway.id}
                                            gateway={gateway.gateway}
                                            gateways={this.props.gateways}
                                            onChange={this.onChange}
                                            processors={this.props.processors}
                                        />
                                        {gateway.gateway === 'processout' && (
                                            <div style={{ display: 'inline-block', width: '100%' }}>
                                                <div
                                                    className='field-label text-center'
                                                    style={{
                                                        width: '25%',
                                                        borderRadius: '0 0 0 4px',
                                                    }}
                                                >
                                                    within
                                                </div>
                                                <SmartRoutingSelector
                                                    gateways={gateway.configurations}
                                                    onChange={(newValues: Array<string>) => {
                                                        this.onChange(gateway.id, {
                                                            ...gateway,
                                                            configurations: newValues,
                                                        });
                                                    }}
                                                    configurations={this.props.processors}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div class='large-5 columns' style={{ paddingTop: '.5em' }}>
                                        {array.length > 1 ? (
                                            <a
                                                style={{
                                                    marginRight: '1em',
                                                }}
                                                class='greyed small-font'
                                                onClick={() => {
                                                    const value = this.props.value.slice();
                                                    const index = value.findIndex(
                                                        v => v.id === gateway.id,
                                                    );
                                                    if (index > -1) {
                                                        value.splice(index, 1);
                                                        this.props.onChange(value);
                                                    }
                                                }}
                                            >
                                                Remove
                                            </a>
                                        ) : null}
                                        {index === array.length - 1 ? (
                                            <a
                                                onClick={() => {
                                                    const unusedGateway = Object.keys(
                                                        this.props.gateways,
                                                    ).find(g => !this.props.value.includes(g));
                                                    if (unusedGateway) {
                                                        const gateways = this.props.value.slice();
                                                        gateways.push({
                                                            id: UniqId(),
                                                            gateway: unusedGateway,
                                                        });
                                                        this.props.onChange(gateways);
                                                    }
                                                }}
                                            >
                                                &#43; add fallback gateway
                                            </a>
                                        ) : null}
                                    </div>
                                </div>
                                {index < array.length - 1 ? (
                                    <div class='row'>
                                        <div class='medium-12 columns greyed'>
                                            If transaction fails then fallback to
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

class Gateway extends React.Component {
    props: {
        id: string,
        gateway: string,
        gateways: any,
        onChange: (string, string) => void,
        processors: Array<{ id: string, gateway: { id: string, tags: Array<string> } }>,
    };

    onChange = (option: $Option) => {
        this.props.onChange(this.props.id, option.value);
    };
    render() {
        const { gateway, processors, gateways } = this.props;
        const options: Array<$Option> = Object.keys(gateways)
            .filter(gateway => processors.findIndex(processor => processor.id === gateway) !== -1)
            .map(key => {
                return { value: key, label: gateways[key] };
            })
            .concat({ value: 'processout', label: 'Smart-Routing' });

        return (
            <div class='row'>
                <div class='medium-12 columns'>
                    <Select
                        value={{
                            label: gateway === 'processout' ? 'Smart-Routing' : gateways[gateway],
                            value: gateway,
                        }}
                        clearable={false}
                        options={options}
                        onChange={this.onChange}
                    />
                </div>
            </div>
        );
    }
}

export default GatewaysSelection;
