import React from 'react';
import { connect } from 'react-redux';
import Loader from '../Loader';
import ModalFooter from '../ModalFooter';
import * as CustomerDetailsActions from '../../Actions/CustomerDetailsActions';
import PropTypes from 'prop-types';
import * as ProcessOutUtil from '../../util/ProcessOut';

@connect(
    function(store) {
        return {
            projects: store.projects,
            scripts: store.scripts,
        };
    },
    null,
    null,
    { withRef: true },
)
export default class TokenModal extends React.Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired,
    };

    constructor() {
        super();
        this._client = null;
    }

    componentDidMount() {
        if (this.props.scripts.processout.loaded)
            this._client = new ProcessOut.ProcessOut(this.props.projects.current_project_id);
    }

    componentWillReceiveProps(nextProps) {
        if (!this._client && nextProps.scripts.processout.loaded)
            this._client = new ProcessOut.ProcessOut(this.props.projects.current_project_id);
    }

    closeModal() {
        this.props.dispatch({ type: 'CLOSE_MODAL' });
    }

    submit(event) {
        event.preventDefault();

        this._modalFooter.getWrappedInstance().lock();

        this._form.startLoading();
        var form = this._form.getProcessOutForm();
        var name = document.getElementById('card-holder-name').value;

        this._client.tokenize(
            form,
            { name: name },
            function(token) {
                this.props.dispatch(
                    CustomerDetailsActions.addToken(
                        this.props.customerId,
                        token,
                        function() {
                            this._form.stopLoading();
                            this.closeModal();
                        }.bind(this),
                    ),
                );
            }.bind(this),
            function(err) {
                this._modalFooter.getWrappedInstance().unlock();
                this._form.stopLoading();
                ProcessOutUtil.addNotification(err.message);
            }.bind(this),
        );
    }

    openModal() {
        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: 'New card token',
                content: (
                    <PaymentForm
                        ref={m => (this._form = m)}
                        processOutClient={this._client}
                        project={this.props.projects.current_project_id}
                    />
                ),
                footer: (
                    <div class="row">
                        <div class="medium-12 columns text-right">
                            <ModalFooter
                                ref={m => (this._modalFooter = m)}
                                submitTitle="Save"
                                submitCallback={this.submit.bind(this)}
                            />
                        </div>
                    </div>
                ),
            },
        });
    }

    render() {
        return null;
    }
}

class PaymentForm extends React.Component {
    static propTypes = {
        project: PropTypes.string.isRequired,
        processOutClient: PropTypes.object.isRequired,
    };

    constructor() {
        super();
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        var formElement = document.getElementById('payment-form');
        this.props.processOutClient.setupForm(
            formElement,
            this.processoutReadyHandler.bind(this),
            function(err) {
                console.log('error', err);
            },
            function(event, data) {
                switch (event) {
                    case 'onfocus': {
                        break;
                    }
                    case 'onblur': {
                        break;
                    }
                }
            },
        );
    }

    startLoading() {
        this.setState({ loading: true });
    }

    stopLoading() {
        this.setState({ loading: false });
    }

    getProcessOutForm() {
        return this._form;
    }

    processoutReadyHandler(form) {
        this._form = form;
        this.setState({ loading: false });
    }

    render() {
        var boxStyle = {
            border: '1px solid #cacaca',
            borderRadius: '4px',
            boxShadow: '0 5px 7px rgba(50, 50, 93, 0.04), 0 1px 3px rgba(0, 0, 0, 0.03)',
            height: '2.5em',
            padding: '0em 0.5em',
        };

        var boxFocusStyle = {
            border: '1px solid #8a8a8a',
            borderRadius: '4px',
            boxShadow: '0 5px 7px rgba(50, 50, 93, 0.04), 0 1px 3px rgba(0, 0, 0, 0.03)',
            height: '2.5em',
        };

        var content;
        if (this.state.loading || this.props.loading) {
            content = <Loader />;
        }

        return (
            <div>
                <form id="payment-form" class={`${this.state.loading ? 'hide' : ''}`}>
                    <div class="row small-margin-bottom">
                        <div class="large-12 columns">
                            <input
                                type="text"
                                id="card-holder-name"
                                name="name"
                                class="bottom-border no-margin"
                                placeholder="Card holder name"
                                required
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="large-12 columns">
                            <div
                                class="small-margin-bottom"
                                style={boxStyle}
                                data-processout-input="cc-number"
                                data-processout-style="font-size:14px"
                                data-processout-placeholder="1234 1234 1234 1234"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="medium-6 columns">
                            <div
                                class="small-margin-bottom"
                                style={boxStyle}
                                data-processout-input="cc-exp"
                                data-processout-style="font-size:14px"
                                data-processout-placeholder="Expiration date (mm / yy)"
                            />
                        </div>
                        <div class="medium-6 columns">
                            <div
                                class="small-margin-bottom"
                                data-processout-style-focus={boxFocusStyle}
                                style={boxStyle}
                                data-processout-input="cc-cvc"
                                data-processout-style="font-size:14px"
                                data-processout-placeholder="CVC"
                            />
                        </div>
                    </div>
                </form>
                {content}
            </div>
        );
    }
}
