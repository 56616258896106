// @flow

import React from 'react';
// $FlowFixMe
import './ProjectWizard.scss';
export type $AwsRegion = 'aws-us-east-1' | 'aws-eu-west-1';

type Props = {
    onChange: (region: $AwsRegion) => void,
    value: ?$AwsRegion,
};

class RegionChoice extends React.Component<Props> {
    render() {
        const { onChange, value } = this.props;
        return (
            <div class='row small-margin-bottom'>
                <div class='medium-6 columns'>
                    <a
                        onClick={() => {
                            onChange('aws-us-east-1');
                        }}
                    >
                        <div
                            class={`region-choice-button${
                                value === 'aws-us-east-1' ? ' selected' : ''
                            }`}
                        >
                            <div class='row small-margin-bottom'>
                                <div class='medium-12 columns '>
                                    <img src={'//dashboard.processout.com/images/us-map.png'} />
                                </div>
                            </div>
                            <div class='row'>
                                <div class='medium-12 columns greyed'>US east region</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class='medium-6 columns'>
                    <a
                        onClick={() => {
                            onChange('aws-eu-west-1');
                        }}
                    >
                        <div
                            class={`region-choice-button${
                                value === 'aws-eu-west-1' ? ' selected' : ''
                            }`}
                        >
                            <div className='row small-margin-bottom text-center'>
                                <div className='medium-12 columns'>
                                    <img src={'//dashboard.processout.com/images/europe-map.png'} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='medium-12 columns greyed'>EU west region</div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}

export default RegionChoice;
