/**
 * Created by jeremylejoux on 07/06/17.
 */

import * as ApplePayActions from '../../../Actions/ApplePayActions';
import * as ProcessOut from '../../../util/ProcessOut';

type State = {
    fetching: boolean,
    fetched: boolean,
    keys: any,
};

export default function(
    state: State = { fetching: false, fetched: false, keys: null },
    action: Actions,
): State {
    switch (action.type) {
        case ProcessOut.typePending(ApplePayActions.CREATE_KEYS_APPLE_PAY): {
            return {
                ...state,
                fetching: true,
                fetched: false,
                keys: null,
            };
        }

        case ProcessOut.typeFulfilled(ApplePayActions.CREATE_KEYS_APPLE_PAY): {
            return {
                ...state,
                fetching: false,
                fetched: true,
                keys: action.payload.keys,
            };
        }

        case ProcessOut.typeFulfilled(ApplePayActions.RESET_APPLE_PAY): {
            return {
                ...state,
                fetching: false,
                fetched: false,
                keys: null,
            };
        }
    }

    return state;
}
