// @flow

type $Token = {
    value: string,
    meta: string,
};

const TOKENS_LIST: Array<$Token> = [
    {
        name: 'plot',
        value: 'plot',
        meta: 'Class',
    },
    {
        name: 'sum',
        value: 'sum',
        meta: 'Function',
    },
    {
        name: 'average',
        value: 'average',
        meta: 'Function',
    },
    {
        name: 'count',
        value: 'count',
        meta: 'Function',
    },
    {
        name: 'cardinality',
        value: 'cardinality',
        meta: 'Function',
    },
    {
        name: 'path',
        value: 'path',
        meta: 'Params',
    },
    {
        name: 'formula',
        value: 'formula',
        meta: 'Params',
    },
    {
        name: 'top',
        value: 'top',
        meta: 'Params',
    },
    {
        name: 'strategy',
        value: 'strategy',
        meta: 'Params',
    },
];

export default TOKENS_LIST;
