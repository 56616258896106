// @flow

import type { $Filter } from './Filter/consts';
import type { $Operand } from '../analytics/DataExplorer/ChartBuilder/Filters/consts';

export type $SmartRoutingGateway = {
    id: string,
    gateway: 'processout',
    configurations: Array<string>,
};

export type $RoutingRule = {
    id: string,
    conditions: Array<$RoutingFilterGroup>,
    gateways: ?Array<{ id: string, gateway: string } | $SmartRoutingGateway>,
    declaration: 'route' | 'block' | 'trigger_3ds',
    tags: Array<string>,
    new?: boolean,
};
export type $RoutingRulesSettings = {
    rules: Array<$RoutingRule>,
    totalTransactions: number,
    saving: boolean,
};

export type $VelocityRule = {
    velocityPath: string,
    interval: string,
    operand: $Operand,
    value: number,
};

export type $RoutingFilterGroup = {
    subGroup: ?$RoutingFilterGroup,
    filters: Array<$Filter | $VelocityRule>,
    logical: 'or' | 'and',
};

export const FETCH_ROUTING_RULES = 'FETCH_ROUTING_RULES';
export const COMPILE_RULES = 'COMPILE_RULES';
export const PREPARE_ROUTING_RULES_SETTINGS = 'PREPARE_ROUTING_RULES_SETTINGS';
export const ADD_RULE = 'ADD_RULE';
export const REMOVE_RULE = 'REMOVE_RULE';
export const UPDATE_RULE = 'UPDATE_RULE';
export const RULES_SET_TOTAL_TRANSACTIONS = 'RULES_SET_TOTAL_TRANSACTIONS';
export const SAVE_ROUTING_RULES = 'SAVE_ROUTING_RULES';

export const RULES_FILTERS = {
    name: { label: 'Name', desc: 'Transaction name', isMulti: true, type: 'string' },
    currency: {
        label: 'Currency',
        desc: 'Currency used for the transactions: USD, EUR, CAD…',
        isMulti: true,
        type: 'string',
    },
    card_country: {
        label: 'Card country',
        desc: 'Country in which the card was emitted: FR, US, UK…',
        isMulti: true,
        type: 'string',
    },
    card_scheme: {
        label: 'Card scheme',
        desc: 'Card scheme: Visa, Mastercard, American Express…',
        isMulti: true,
        type: 'string',
    },
    card_co_scheme: {
        label: 'Card co-scheme',
        desc: 'Card co-schemes: CB…',
        isMulti: true,
        type: 'string',
    },
    card_iin: { label: 'Card IIN/BIN', desc: 'Card BIN/IIN', isMulti: true, type: 'string' },
    card_type: {
        label: 'Card type',
        desc: 'Card types: Debit, Credit…',
        isMulti: true,
        type: 'string',
    },
    card_category: {
        label: 'Card category',
        desc: 'Card categories: Consumer, Business…',
        isMulti: true,
        type: 'string',
    },
    card_last4: {
        label: 'Card last 4 digits',
        desc: 'Last 4 digits of the card',
        isMulti: true,
        type: 'string',
    },
    card_bank: {
        label: 'Card issuing bank',
        desc: 'Bank that issued the card used for the transactions',
        isMulti: true,
        type: 'string',
    },
    amount: {
        label: 'Amount',
        desc: 'Transaction’s amount requested at its creation.',
        type: 'number',
    },
    check_3ds: {
        label: 'Is 3DS flow',
        desc: 'Whether or not we are currently using a 3DS flow.',
        type: 'boolean',
    },
    rand: { label: 'Random number (0-1)', desc: 'i.e. match X% of transcations', type: 'number' },
    velocity: { label: 'Velocity', desc: 'Filter by fields velocity', type: 'number' },
    cvc_check: {
        label: 'CVC check',
        desc: 'Whether or not a CVC check has been performed.',
        isMulti: true,
        type: 'string',
    },
    avs_postal_check: {
        label: 'AVS postal check',
        desc: 'Whether or not a postal check has been performed.',
        isMulti: true,
        type: 'string',
    },
    avs_street_check: {
        label: 'AVS street check',
        desc: 'Whether or not a street check has been performed.',
        isMulti: true,
        type: 'string',
    },
    avs_name_check: {
        label: 'AVS name check',
        desc: 'Whether or not a name check has been performed.',
        isMulti: true,
        type: 'string',
    },
    merchant_initiated: {
        label: 'Is merchant initiated',
        desc: 'Whether or not a transaction is flagged as merchant initiated.',
        isMulti: false,
        type: 'boolean',
    },
};
