// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { $Dispatcher, $State } from '../../util/Types';
import ContentLayout from '../../pages/ContentLayout';
import * as GatewaysActions from '../../Actions/GatewaysActions';
import ErrorBoundary from '../ErrorBoundary/index';
import GatewayDisplay from './GatewayDisplay';
import GatewayModal from '../PaymentProviders/GatewayModal';
import { EventTypes } from 'redux-segment';
import Getstarted from './Getstarted';
import Loading from '../../components/Loader';
import Error from '../../components/Error';
import Section from '../Telescope/Section';
import Telescope from '../Telescope/Telescope';
import RatingWidget from '../RatingWidget/RatingWidget';
import NotPermitted from '../Permissions/NotPermitted';

type $Gateway = {
    can_pull_transactions: boolean,
    tags: Array<string>,
};

type $Config = {
    id: string,
    gateway: ?$Gateway,
    enabled: boolean,
};

type $ProcessorsConfigurations = {
    configurations: Array<$Config>,
} & $State;

type Props = {
    params: any,
    children: any,
    route: any,
    routeParams: any,
    router: any,
    routes: Array<any>,
    location: any,
    processorsConfigurations: $ProcessorsConfigurations,
    role: string,
} & $Dispatcher;

type State = {
    gatewaySelected: string,
};

class TelescopeWrapper extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            gatewaySelected: '',
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(GatewaysActions.loadGateways());
        dispatch(GatewaysActions.loadGatewaysConfigurations());
    }

    selectGateway = (gatewayName: string) => {
        this.setState({ gatewaySelected: gatewayName });
    };

    startSetup = (event: Event) => {
        event.preventDefault();
        this.props.dispatch({
            type: 'STARTED_TELESCOPE_SETUP',
            meta: {
                analytics: {
                    eventType: EventTypes.track,
                    eventPayload: {
                        properties: {
                            gateway: this.state.gatewaySelected,
                        },
                    },
                },
            },
        });
        if (this.state.gatewaySelected)
            this.refs[this.state.gatewaySelected].getWrappedInstance().openModal();
    };

    render() {
        const { processorsConfigurations, gateways, role } = this.props;
        const { gatewaySelected } = this.state;

        const title = (
            <div class='row small-margin-bottom'>
                <div class='medium-12 columns medium-centered'>
                    <h4>
                        <div class='text-center' style={{ fontSize: '1.2em', marginTop: '0.5em' }}>
                            <img
                                src='//dashboard.processout.com/images/telescope.svg'
                                alt='Telescope'
                                style={{ height: '1em', marginRight: '0.8em' }}
                            />
                            Telescope
                        </div>
                    </h4>
                    <div class='greyed text-center' style={{ fontSize: '1.2em' }}>
                        Your personal payment assistant
                    </div>
                </div>
            </div>
        );

        let content;
        if (
            (processorsConfigurations.error || gateways.error) &&
            (processorsConfigurations.error &&
                !processorsConfigurations.error.notPermitted &&
                (gateways.error && !gateways.error.notPermitted))
        ) {
            content = (
                <ContentLayout>
                    <Error />
                </ContentLayout>
            );
        } else if (processorsConfigurations.fetching || gateways.fetching) {
            content = (
                <ContentLayout>
                    <Loading />
                </ContentLayout>
            );
        } else if (
            (processorsConfigurations.error && processorsConfigurations.error.notPermitted) ||
            (processorsConfigurations.configurations &&
                processorsConfigurations.configurations.length > 0) ||
            (role && !role.includes('owner'))
        ) {
            content = (
                <Telescope
                    params={this.props.params}
                    route={this.props.route}
                    routeParams={this.props.routeParams}
                    router={this.props.router}
                    routes={this.props.routes}
                    location={this.props.location}
                />
            );
        } else {
            content = (
                <div class='text-center'>
                    <Section title='Get started with Telescope' content={null} />
                    <ContentLayout
                        style={{
                            padding: '4em 1.5em 3.5em 1.5em',
                            marginBottom: '1.5em',
                        }}
                    >
                        <div class='row'>
                            <div class='large-10 large-offset-1 columns'>
                                <div class='row'>
                                    <div class='large-5 columns'>
                                        <div class='row margin-bottom'>
                                            {gateways.gateways
                                                ? gateways.gateways
                                                      .filter((gateway, index) => {
                                                          return index % 2 === 0;
                                                      })
                                                      .map(gateway => {
                                                          return (
                                                              <div key={gateway.name}>
                                                                  <GatewayModal
                                                                      isForTelescope={true}
                                                                      modalName='New configuration'
                                                                      gateway={gateway}
                                                                      ref={gateway.name}
                                                                  />
                                                                  <GatewayDisplay
                                                                      name={gateway.name}
                                                                      selected={
                                                                          gateway.name ===
                                                                          gatewaySelected
                                                                      }
                                                                      select={this.selectGateway}
                                                                      displayName={
                                                                          gateway.display_name
                                                                      }
                                                                      logoUrl={gateway.logo_url}
                                                                  />
                                                              </div>
                                                          );
                                                      })
                                                : null}
                                        </div>
                                        <div class='row small-margin-bottom'>
                                            {gateways.gateways
                                                ? gateways.gateways
                                                      .filter((gateway, index) => {
                                                          return index % 2 == 1;
                                                      })
                                                      .map(gateway => {
                                                          return (
                                                              <div key={gateway.name}>
                                                                  <GatewayModal
                                                                      isForTelescope={true}
                                                                      modalName='New configuration'
                                                                      gateway={gateway}
                                                                      ref={gateway.name}
                                                                  />
                                                                  <GatewayDisplay
                                                                      name={gateway.name}
                                                                      selected={
                                                                          gateway.name ===
                                                                          gatewaySelected
                                                                      }
                                                                      select={this.selectGateway}
                                                                      displayName={
                                                                          gateway.display_name
                                                                      }
                                                                      logoUrl={gateway.logo_url}
                                                                  />
                                                              </div>
                                                          );
                                                      })
                                                : null}
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <a
                                                    style={{
                                                        fontStyle: 'italic',
                                                        fontSize: '.9em',
                                                        opacity: 0.7,
                                                    }}
                                                    onClick={event => {
                                                        event.preventDefault();
                                                        window.Intercom(
                                                            'showNewMessage',
                                                            "Hi! I'm using {your payment provider} but I don't see it on the Telescope list.",
                                                        );
                                                    }}
                                                >
                                                    Not on the list? Click here to let us know!
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class='large-6 large-offset-1 columns'
                                        style={{ paddingTop: '2em' }}
                                    >
                                        <div class='row margin-bottom'>
                                            <div class='large-12 columns'>
                                                <p
                                                    class='text-center'
                                                    style={{
                                                        fontSize: '1.1em',
                                                    }}
                                                >
                                                    To start using <b>Telescope</b> go ahead and
                                                    select your current payment provider
                                                </p>
                                            </div>
                                        </div>
                                        <div class='row margin-bottom'>
                                            <div class='large-12 large-centered columns'>
                                                <input
                                                    type='submit'
                                                    onClick={this.startSetup}
                                                    value='Setup my account'
                                                    class='round border button'
                                                    style={{
                                                        opacity: gatewaySelected ? 1 : 0.3,
                                                    }}
                                                    disabled={gatewaySelected === ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ContentLayout>
                    <Section title='Get started with the Smart Router' content={null} />
                    <ContentLayout
                        style={{
                            padding: '1.5em',
                            marginBottom: '1.5em',
                            background: 'transparent',
                            boxShadow: 'none',
                        }}
                    >
                        <div class='telescope-layout text-left'>
                            <Getstarted />
                        </div>
                    </ContentLayout>
                </div>
            );
        }

        return (
            <div class='row'>
                <div class='large-12 columns'>
                    {title}
                    {content}
                </div>
            </div>
        );
    }
}

const filterConfigurations = (store: {
    processorsConfigurations: $ProcessorsConfigurations,
    gateways: {
        gateways: Array<$Gateway>,
    } & $State,
    currentProject: {
        project: any,
    } & $State,
}) => {
    const { processorsConfigurations, gateways, currentProject } = store;
    if (!processorsConfigurations.configurations || !gateways.gateways || !currentProject.project) {
        return {
            processorsConfigurations,
            gateways: {
                ...gateways,
                gateways: [],
            },
        };
    }

    const configs = processorsConfigurations.configurations.slice().filter(config => {
        if (!config.gateway) return false;
        return config.enabled;
    });

    const telescopeGateways = gateways.gateways.slice().filter(gateway => {
        return gateway.tags.includes('telescope');
    });

    return {
        gateways: {
            ...store.gateways,
            gateways: telescopeGateways,
        },
        processorsConfigurations: {
            ...processorsConfigurations,
            configurations: configs,
        },
    };
};

const Component = connect(filterConfigurations)(TelescopeWrapper);

/*
 * wrap the component around an error boundary
 */
export default (props: any) => {
    return (
        <ErrorBoundary>
            <Component {...props} />
        </ErrorBoundary>
    );
};
