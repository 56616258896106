// @flow

export type $SearchBuilderOption = {
    value: string,
    label: string,
    description: string,
    canFetchValues: boolean,
};

export const SEARCH_BUILDER_TOKENS: { [string]: Array<$SearchBuilderOption> } = {
    transactions: [
        { value: 'id', label: 'ID', description: 'Transaction ID', canFetchValues: false },
        {
            value: 'invoice_id',
            label: 'Invoice ID',
            description: 'ID used for the transaction',
            canFetchValues: false,
        },
        {
            value: 'name',
            label: 'Name',
            description: 'Transaction names',
            canFetchValues: false,
        },
        {
            value: 'status',
            label: 'Status',
            description: 'Transaction status: completed, pending, failed…',
            canFetchValues: true,
        },
        {
            value: 'customer_email',
            description: 'Email used by the customer linked to the transaction',
            label: 'Customer email',
            canFetchValues: false,
        },
        {
            value: 'gateway_name',
            label: 'Gateway name',
            description: 'Name of the gateways used: Stripe, Adyen, Braintree…',
            canFetchValues: true,
        },
        {
            value: 'error_code',
            label: 'Error code',
            description: 'Transaction error codes: do-not-honor…',
            canFetchValues: true,
        },
        {
            value: 'currency',
            label: 'Currency',
            description: 'Currency used for the transactions: USD, EUR, CAD…',
            canFetchValues: true,
        },
        {
            value: 'card_iin',
            label: 'Card IIN/BIN',
            description: 'Card BIN/IIN',
            canFetchValues: false,
        },
        {
            value: 'card_last4',
            label: 'Card last 4 digits',
            description: 'Last 4 digits of a credit card',
            canFetchValues: false,
        },
        {
            value: 'country',
            label: 'Card country',
            description: 'Country in which the transactions occured: FR, US, UK…',
            canFetchValues: true,
        },
        {
            value: 'continent',
            label: 'Card continent',
            description: 'Continent in which the transactions occured',
            canFetchValues: true,
        },
        {
            value: 'card_scheme',
            label: 'Card scheme',
            description: 'Card scheme: Visa, Mastercard, Amex…',
            canFetchValues: true,
        },
    ],
};
