// @flow
import React from 'react';
import { IndexRedirect, IndexRoute, Redirect, Route } from 'react-router';
import * as Store from '../stores/Store';
import Layout from '../pages/Layout';
import Customers from '../pages/Customers';
import CustomerDetails from '../pages/CustomerDetails';
import Transactions from '../pages/Transactions';
import TransactionsDetails from '../pages/TransactionsDetails';
import Invoices from '../pages/Invoices';
import InvoiceEdit from '../pages/InvoiceEdit';
import Events from '../pages/Events';
import EventsDetails from '../pages/EventsDetails';
import Login from '../pages/Login';
import Plans from '../pages/Plans';
import Reminder from '../pages/Reminder';
import SignUp from '../pages/SignUp';
import Projects from '../pages/Projects';
import User from '../pages/User';
import Error from '../pages/Error';
import NotFound from '../pages/NotFound';
import UserUpdate from '../pages/UserUpdate';
import ProjectWizard from '../features/ProjectWizard/ProjectWizard';
import LayoutNoSideBar from '../pages/LayoutNoSideBar';
import AppLayout from '../pages/AppLayout';
import Coupons from '../pages/Coupons';
import Subscriptions from '../pages/Subscriptions';
import SubscriptionDetails from '../pages/SubscriptionDetails';
import ApiRequests from '../pages/ApiRequests';
import Vault from '../pages/Vault';
import ApiRequestDetails from '../pages/ApiRequestDetails';
import * as ProcessOut from './ProcessOut';
import * as ProjectsActions from '../Actions/ProjectsActions';
import SettingsContainer from '../containers/SettingsContainer';
import GeneralSettings from '../components/Settings/GeneralSettings';
import TeamSettings from '../components/Settings/TeamSettings';
import DunningSettings from '../components/Settings/DunningSettings';
import ApplePaySettings from '../components/Settings/ApplePay/ApplePaySettings';
import Start from '../components/Settings/ApplePay/Start';
import SecondStep from '../components/Settings/ApplePay/SecondStep';
import ApplePayOverview from '../components/Settings/ApplePay/ApplePayOverview';
import DomainNameFlow from '../components/Settings/ApplePay/DomainNameFlow';
import BoardsContainer from '../features/analytics/Boards/BoardsContainer';
import Board from '../features/analytics/Boards/Board/Board';
import TelescopeWrapper from '../features/TelescopeOnboarding/TelescopeWrapper';
import IndexBoard from '../features/analytics/Boards/IndexBoard';
import BoardFetcher from '../features/analytics/Boards/BoardFetcher';
import RoutingRules from '../features/RoutingRules/RulesIndex';
import ApiSetup from '../features/ApiSetup/ApiSetup';
import LocalStorage from 'local-storage';
import DataExplorer, { DataExplorerBuilder } from '../features/analytics/DataExplorer/DataExplorer';
import ChartBuilder from '../features/analytics/DataExplorer/ChartBuilder/ChartBuilder';
import Editor from '../features/analytics/DataExplorer/Editor/Editor';
import Presets from '../features/analytics/DataExplorer/Presets';
import BoardCreator from '../features/analytics/BoardCreator/BoardCreator';
import { CHANGE_SANDBOX_STATE } from '../features/SandboxSwitch/consts';
import * as Sentry from '@sentry/browser';
import Reports from '../features/Reports/Reports';
import Payouts from '../features/Payouts/Payouts';
import PayoutDetails from '../features/Payouts/PayoutDetails';
import AnalyticsContainer from '../features/analytics/AnalyticsContainer';
import PaymentProviders from '../features/PaymentProviders/PaymentProviders';
import TransactionsExports from '../features/Exports/Exports';

var AuthCheck = function(nextState, replace) {
    var state = Store.store.getState();
    if (state.user.auth.loggedIn) {
        replace('/projects');
    }
};

var RequireAuth = function(prevState, nextState, replace) {
    var state = Store.store.getState();
    if (!state.user.auth.loggedIn) {
        replace('/login');
    }
};

var RequireAuthOnEnter = function(nextState, replace) {
    var state = Store.store.getState();
    if (!state.user.auth.loggedIn) {
        replace('/login');
    }
};

var SetProject = function(nextState, replace) {
    var state = Store.store.getState();
    if (!state.user.auth.loggedIn) {
        replace('/login');
    } else {
        LocalStorage('projectid', nextState.params.project);
        ProcessOut.ProjectId = nextState.params.project;
        const sandbox = nextState.params.project.includes('test-');
        Store.store.dispatch({
            type: CHANGE_SANDBOX_STATE,
            payload: { active: sandbox },
        });
        ProcessOut.sandbox = sandbox;
        Store.store.dispatch(ProjectsActions.setProjectId(nextState.params.project));
    }
};

var Setup = function(nextState, replace) {
    // Check if we are using the old history system
    if (new RegExp(/^#/).test(nextState.location.hash)) {
        replace(nextState.location.hash.replace('#', ''));
    }
    if (!process.env.LOCAL) {
        Sentry.init({
            beforeSend: (event, hints) => {
                const error = hints.originalException;
                if (error && error.type && error.type.includes('NETWORK')) {
                    // Error handled by the network manager
                    return null;
                }
                return event;
            },
            dsn: 'https://303eb0dcf1394215ae868db35d29b6da@sentry.io/93256',
            release: process.env.VERSION,
            environment: process.env.DEV ? 'staging' : 'prod',
        });
    }
    var processoutJS = document.createElement('script');
    processoutJS.src = `https://js.processout${
        process.env.API_HOST.split('https://api.processout')[1]
    }/processout.js`;
    processoutJS.type = 'text/javascript';
    processoutJS.onload = function() {
        Store.store.dispatch({ type: 'PROCESSOUT_JS_LOADED' });
    };
    document.body.appendChild(processoutJS);
};

export const Routes = (
    <div id='routes'>
        <Route path='/' component={AppLayout} onEnter={Setup}>
            <IndexRedirect to='/login' />
            <Route
                path='/projects/:project/'
                component={Layout}
                onEnter={SetProject}
                onChange={RequireAuth}
            >
                <IndexRedirect to='telescope' />
                <Route path='telescope' component={TelescopeWrapper} />
                <Route path='boards' component={AnalyticsContainer}>
                    <Route component={BoardsContainer}>
                        <IndexRoute component={IndexBoard} />
                        <Route path='new' component={BoardCreator} />
                        <Route path='data-explorer' component={DataExplorer}>
                            <IndexRedirect to='chart-builder' />
                            <Route path='chart-builder' component={ChartBuilder} />
                            <Route path='editor' component={Editor} />
                        </Route>
                        <Route path=':board' component={BoardFetcher}>
                            <IndexRoute component={Board} />
                        </Route>
                    </Route>
                    <Route path=':board' component={BoardFetcher}>
                        <Route path='new-chart' component={DataExplorerBuilder}>
                            <IndexRedirect to='chart-builder' />
                            <Route path='chart-builder' component={ChartBuilder} />
                            <Route path='editor' component={Editor} />
                        </Route>
                    </Route>
                </Route>
                <Route path='recurring/'>
                    <Route path='subscriptions' component={Subscriptions} />
                    <Route path='subscriptions/:subscription' component={SubscriptionDetails} />
                    <Route path='plans' component={Plans} />
                    <Route path='coupons' component={Coupons} />
                </Route>
                <Route path='router' component={RoutingRules} />
                <Route path='settings' component={SettingsContainer}>
                    <Route path='general' component={GeneralSettings} />
                    <Route path='team' component={TeamSettings} />
                    <Route path='dunning' component={DunningSettings} />
                    <Route path='apple-pay' component={ApplePaySettings}>
                        <IndexRoute component={ApplePayOverview} />
                        <Route path='step-1' component={Start} />
                        <Route path='step-2' component={SecondStep} />
                        <Route path='domains' component={DomainNameFlow} />
                    </Route>
                </Route>
                <Route path='customers' component={Customers} />
                <Route path='customers/:customer' component={CustomerDetails} />
                <Route path='customers/create' component={CustomerDetails} />
                <Route
                    path='customers/:customer/recurring-invoices/:invoice'
                    component={InvoiceEdit}
                />
                <Route path='transactions' component={Transactions} />
                <Route path='transactions/:transaction' component={TransactionsDetails} />
                <Route path='gateways' component={PaymentProviders} />
                <Route path='products' component={Invoices} />
                <Route path='vault' component={Vault} />
                <Route path='developer/'>
                    <Route path='api-setup' component={ApiSetup} />
                    <Route path='events' component={Events} />
                    <Route path='events/:event' component={EventsDetails} />
                </Route>
                <Route path='reports'>
                    <IndexRedirect to={'uploads'} />
                    <Route path='uploads' component={Reports} />
                    <Route path='payouts' component={Payouts} />
                    <Route path='payouts/:payout' component={PayoutDetails} />
                    <Route path='exports' component={TransactionsExports} />
                </Route>
            </Route>
            <Route onEnter={RequireAuthOnEnter} onChange={RequireAuth} component={LayoutNoSideBar}>
                <Route path='/user' component={User} />
                <Route path='/user/update' component={UserUpdate} />
                <Route path='/projects' component={Projects} />
                <Route path='/projects/create' component={ProjectWizard} />
                <Route path='/error' component={Error} />
                <Route path='/404' component={NotFound} />
            </Route>
            <Route path='/login' component={Login} onEnter={AuthCheck} />
            <Route path='/reminder' component={Reminder} onEnter={AuthCheck} />
            <Route path='/register' component={SignUp} onEnter={AuthCheck} />
        </Route>
        <Route path='*' component={Login} onEnter={AuthCheck} />
    </div>
);
