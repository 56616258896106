export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case 'FETCH_SUBSCRIPTIONS_PENDING': {
            return {
                ...state,
                error: false,
                fetching: true,
                fetched: false,
            };
        }
        case 'FETCH_SUBSCRIPTIONS_FULFILLED': {
            return {
                ...state,
                ...action.payload,
                fetching: false,
                fetched: true,
            };
        }

        case 'FETCH_SUBSCRIPTIONS_REJECTED': {
            return {
                ...state,
                error: action.payload,
                fetched: false,
                fetching: false,
            };
        }
    }

    return state;
}
