import React from 'react';
import { FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import HighLight from './Highlight';
import PropTypes from 'prop-types';

@connect(function(store) {
    return {};
})
export default class Webhook extends React.Component {
    static propTypes = {
        webhook: PropTypes.object.isRequired,
    };

    constructor() {
        super();
    }

    openDetails(forceBody, event) {
        const hook = this.props.webhook;

        var body;
        var center;
        if (hook.response_body && hook.response_body.length > 0) {
            try {
                body = JSON.parse(hook.response_body, 2);
                body = <HighLight style="Agate">{JSON.stringify(body, null, 4)}</HighLight>;
                center = false;
            } catch (e) {
                body = (
                    <div>
                        We could not parse the response body.<br />
                        <a onClick={this.openDetails.bind(this, true)}>Click here</a> to display it
                        anyway.
                    </div>
                );
                center = true;
                if (forceBody) {
                    body = <HighLight style="Agate">{hook.response_body}</HighLight>;
                    center = false;
                }
            }
        } else {
            body = <div class="greyed text-center">The returned body was empty.</div>;
        }

        var status = (
            <div class="log-level warning" style={{ margin: '0px', marginTop: '0.2em' }}>
                Pending
            </div>
        );
        if (hook.status == 'delivered') {
            status = (
                <div class="log-level success" style={{ margin: '0px', marginTop: '0.2em' }}>
                    Success
                </div>
            );
        }
        if (hook.status == 'failed') {
            status = (
                <div class="log-level failed" style={{ margin: '0px', marginTop: '0.2em' }}>
                    Failed
                </div>
            );
        }

        var resp;
        if (hook.status != 'delivered' && hook.status != 'failed') {
            // Webhook not yet released
            resp = (
                <div class="greyed">
                    The webhook will be delivered at about{' '}
                    <FormattedDate
                        value={hook.created_at}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                    />.
                </div>
            );
        } else {
            resp = (
                <div>
                    <div class="row small-margin-bottom">
                        <div class="large-3 columns greyed">Released at</div>
                        <div class="large-9 columns text-right font-console">
                            <FormattedDate
                                value={hook.release_at}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="numeric"
                                minute="numeric"
                            />
                        </div>
                    </div>
                    <div class="row small-margin-bottom">
                        <div class="large-3 columns greyed">Time</div>
                        <div
                            class="large-9 columns text-right font-console"
                            style={{ wordWrap: 'break-word' }}
                        >
                            {hook.response_time_ms}ms
                        </div>
                    </div>
                    <div class="row small-margin-bottom">
                        <div class="large-12 columns">
                            <h6
                                class="capitalize greyed font-console"
                                style={{ marginBottom: '0px' }}
                            >
                                Body
                            </h6>
                        </div>
                    </div>
                    <div class={`row small-margin-bottom${center ? ' text-center' : ''}`}>
                        <div class="large-12 columns" style={{ wordWrap: 'break-word' }}>
                            {body}
                        </div>
                    </div>
                </div>
            );
        }

        var content = (
            <div class="row">
                <div class="large-12 columns">
                    <div class="row margin-bottom">
                        <div class="large-2 medium-3 columns">
                            <h6
                                class="capitalize greyed font-console"
                                style={{ marginBottom: '0px' }}
                            >
                                Request
                            </h6>
                        </div>
                        <div
                            class="large-10 medium-9 columns font-console greyed text-right"
                            style={{ fontSize: '0.8em', marginTop: '0.2em' }}
                        >
                            {hook.id}
                        </div>
                    </div>
                    <div class="row small-margin-bottom">
                        <div class="large-12 columns text-right font-console greyed">
                            <input type="text" disabled="true" defaultValue={hook.request_url} />
                        </div>
                    </div>
                    <div class="row small-margin-bottom">
                        <div class="large-3 columns greyed">Method</div>
                        <div class="large-9 columns text-right capitalize font-console">
                            {hook.request_method}
                        </div>
                    </div>
                    <div class="row small-margin-bottom">
                        <div class="large-3 columns greyed">Created at</div>
                        <div class="large-9 columns text-right">
                            <FormattedDate
                                value={hook.created_at}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="numeric"
                                minute="numeric"
                            />
                        </div>
                    </div>
                    <div class="row small-margin-bottom">
                        <div class="large-12 columns">
                            <hr />
                        </div>
                    </div>
                    <div class="row margin-bottom">
                        <div class="large-9 medium-8 columns">
                            <h6
                                class="capitalize greyed font-console"
                                style={{ marginBottom: '0px' }}
                            >
                                Response
                            </h6>
                        </div>
                        <div className="large-3 medium-4 columns">{status}</div>
                    </div>
                    {resp}
                </div>
            </div>
        );

        var footer = (
            <div class="row">
                <div class="large-12 columns text-right">
                    <a
                        onClick={function(event) {
                            event.preventDefault();
                            this.props.dispatch({ type: 'CLOSE_MODAL' });
                        }.bind(this)}
                        class="round border small button"
                    >
                        Close
                    </a>
                </div>
            </div>
        );

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: <div>Webhook - details</div>,
                content: content,
                footer: footer,
            },
        });
    }

    render() {
        const hook = this.props.webhook;

        var status = (
            <div class="log-level warning" style={{ margin: '0px', marginTop: '0.2em' }}>
                Pending
            </div>
        );
        if (hook.status == 'delivered') {
            status = (
                <div class="log-level success" style={{ margin: '0px', marginTop: '0.2em' }}>
                    OK
                </div>
            );
        }
        if (hook.status == 'failed') {
            status = (
                <div class="log-level failed" style={{ margin: '0px', marginTop: '0.2em' }}>
                    Failed
                </div>
            );
        }

        return (
            <a onClick={this.openDetails.bind(this, false)}>
                <div class="row box-row" style={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}>
                    <div class="large-2 columns capitalize bold">{status}</div>
                    <div class="large-7 columns">
                        {hook.request_url.slice(0, 30) +
                            (hook.request_url.length > 30 ? '...' : '')}
                    </div>
                    <div class="large-3 columns text-right">
                        <FormattedDate
                            value={hook.created_at}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                        />
                    </div>
                </div>
            </a>
        );
    }
}
