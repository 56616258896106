// @flow

import type { $Action } from '../../util/Types';
import { FETCH_VALUES_FOR_FIELD } from './consts';
import * as ProcessOut from '../../util/ProcessOut';

export function fetchValues(
    action: $Action & {
        payload: { document: string, field: string, projectId: string },
    },
): $Action {
    return {
        type: FETCH_VALUES_FOR_FIELD,
        payload: ProcessOut.APIcallPromise(
            `/helpers/search/${action.payload.document}?field=${action.payload.field}`,
            'GET',
        ),
    };
}

export const UPDATE_SEARCH_BAR_FILTER = 'UPDATE_SEARCH_BAR_FILTER';
export type $UpdateSearchBarFilterAction = {
    type: 'UPDATE_SEARCH_BAR_FILTER',
    payload: { filter: string },
};
export const updateSearchBarFilter = (filter: string): $UpdateSearchBarFilterAction => ({
    type: UPDATE_SEARCH_BAR_FILTER,
    payload: { filter },
});

export const UPDATE_SEARCH_BAR_APPLIED_FILTER = 'UPDATE_SEARCH_BAR_APPLIED_FILTER';
export type $UpdateSearchBarAppliedFilterAction = {
    type: 'UPDATE_SEARCH_BAR_APPLIED_FILTER',
    payload: { filter: string },
};
export const updateSearchBarAppliedFilter = (
    filter: string,
): $UpdateSearchBarAppliedFilterAction => ({
    type: UPDATE_SEARCH_BAR_APPLIED_FILTER,
    payload: { filter },
});

export const RESET_SEARCH_BAR_FILTER = 'RESET_SEARCH_BAR_FILTER';
export const resetSerachBarFilter = () => ({ type: 'RESET_SEARCH_BAR_FILTER' });
