import React from 'react';
import ContentLayout from './ContentLayout';
import * as CustomerDefailsActions from '../Actions/CustomerDetailsActions';
import * as InvoicesActions from '../Actions/InvoicesActions';
import * as CurrencyActions from '../Actions/CurrencyActions';
import Token from '../components/CustomerDetails/Token';
import DetailsModal from '../components/CustomerDetails/DetailsModal';
import CustomerChargeModal from '../components/CustomerChargeModal';
import CustomerDetailsContent from '../components/CustomerDetailsContent';
import Loader from '../components/Loader';
import { FormattedDate, FormattedNumber, ReactIntl } from 'react-intl';
import SubscriptionModal from '../components/SubscriptionModal';
import Pagination from '../components/Pagination';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import QueryCreator from '../util/QueryCreator';
import MetadataDisplay from '../components/MetadataDisplay';
import Empty from '../components/Empty';
import TokenModal from '../components/CustomerDetails/TokenModal';
import CustomerTransactions from '../components/CustomerDetails/CustomerTransactions';
import * as DetailsActions from '../features/CustomerDetails/actions';
import NotPermitted from '../features/Permissions/NotPermitted';
import Error from '../components/Error';

const transactionsPerPage = 10;
const SUBS_PER_PAGE = 10;

@connect(store => {
    return {
        customerDetails: store.customerDetails,
        subscriptions: store.customers_subscriptions,
        currenciesDetails: store.currencies,
        currentProject: store.currentProject,
        projects: store.projects,
        permissions: store.permissions,
        scripts: store.scripts,
    };
})
export default class CustomerDetails extends React.Component {
    componentDidMount() {
        if (!this.props.currenciesDetails.fetching && !this.props.currenciesDetails.fetched) {
            this.props.dispatch(CurrencyActions.loadCurrencies());
        }
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Customer | ProcessOut';
        if (this.props.params.customer === 'create') {
            this.setState({ creating: true, fetchingInvoices: false });
        } else {
            this.props.dispatch(
                CustomerDefailsActions.loadCustomerDetails(this.props.params.customer),
            );
            this.props.dispatch(
                DetailsActions.fetchCustomerMonthRevenue(this.props.params.customer),
            );
            this.props.dispatch(CustomerDefailsActions.fetchTokens(this.props.params.customer));
            this.props.dispatch(
                InvoicesActions.loadRecurringInvoices(
                    this.props.params.customer,
                    this.props.location.query.subsfilter,
                    SUBS_PER_PAGE,
                ),
            );
        }
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    componentWillReceiveProps(nextProps) {
        if (
            !nextProps.customerDetails.details.fetching &&
            !nextProps.customerDetails.details.fetched &&
            this.props.params.customer !== 'create'
        ) {
            this.props.dispatch(
                CustomerDefailsActions.loadCustomerDetails(nextProps.params.customer),
            );
            this.props.dispatch(
                DetailsActions.fetchCustomerMonthRevenue(nextProps.params.customer),
            );
        }
    }

    edited() {
        this.props.dispatch(CustomerDefailsActions.loadCustomerDetails(this.props.params.customer));
    }

    constructor() {
        super();
        this.state = {
            filter: '',
            creating: false,
        };
    }

    openModal(event) {
        event.preventDefault();
        this._modal.getWrappedInstance().openModal();
    }

    openTokenModal(event) {
        event.preventDefault();
        this._tokenModal.getWrappedInstance().openModal();
    }

    openChargeModal(event) {
        event.preventDefault();
        this._chargeModal.getWrappedInstance().openModal();
    }

    filter() {
        var filter = '';
        this.props.dispatch(
            CustomerDefailsActions.loadCustomerTransactions(
                filter,
                10,
                this.props.params.customer,
                true,
            ),
        );

        var query = this.props.location.query;
        var nextQuery = {
            transactionsfilter: filter,
        };
        query = QueryCreator(query, nextQuery);
    }

    filterSubs() {
        var filter = this.refs.searchSubs.getFilter();
        this.props.dispatch(
            InvoicesActions.loadRecurringInvoices(
                this.props.params.customer,
                filter,
                SUBS_PER_PAGE,
            ),
        );

        var query = this.props.location.query;
        this.setState({ filter: filter });
        var nextQuery = {
            subsfilter: filter,
        };
        query = QueryCreator(query, nextQuery);

        this.props.dispatch(push(`${this.props.location.pathname}${query}`));
    }

    checkVar(obj) {
        return !obj || obj === '' ? <span class='greyed'>--</span> : obj;
    }

    getLine(title, value) {
        return (
            <div class='row small-margin-bottom'>
                <div class='large-4 columns'>
                    <span class='greyed'>{title}</span>
                </div>
                <div class='large-8 columns'>
                    <span class='right'>{this.checkVar(value)}</span>
                </div>
            </div>
        );
    }

    edit() {
        this._detailsModal.getWrappedInstance().openModal();
    }

    render() {
        const customerDetails = this.props.customerDetails;
        if (
            !this.props.customerDetails.details.fetched ||
            !this.props.customerDetails.tokens.fetched ||
            !this.props.subscriptions.fetched
        ) {
            return (
                <ContentLayout title={<h4>{this.state.creating ? 'New customer' : 'Customer'}</h4>}>
                    <Loader />
                </ContentLayout>
            );
        }

        if (customerDetails.details.error) {
            if (customerDetails.details.error.notPermitted)
                return (
                    <ContentLayout title={<h4>Customer</h4>}>
                        <NotPermitted />
                    </ContentLayout>
                );
            return (
                <ContentLayout title={<h4>Customer</h4>}>
                    <Error />
                </ContentLayout>
            );
        }

        const customer = customerDetails.details.customer;
        var invoices;
        var transactions;
        var createButton;

        if (
            !this.state.creating &&
            this.props.customerDetails.details.fetched &&
            !this.props.subscriptions.fetching &&
            this.props.subscriptions.fetched
        ) {
            var subs = <Loader />;
            if (!this.props.subscriptions.fetching && this.props.subscriptions.fetched) {
                if (this.props.subscriptions.subscriptions.length > 0)
                    subs = (
                        <CustomerDetailsContent invoices={this.props.subscriptions.subscriptions} />
                    );
                else subs = <Empty text="The customer hasn't activated any subscription yet." />;
            }

            createButton = (
                <div class='row'>
                    <div class='large-12 columns text-center'>
                        <a
                            onClick={this.openModal.bind(this)}
                            class={
                                'round expanded border small button' +
                                (this.props.subscriptions.subscriptions &&
                                this.props.subscriptions.subscriptions.length == 0
                                    ? ''
                                    : ' right')
                            }
                        >
                            New subscription
                        </a>
                    </div>
                </div>
            );

            invoices = (
                <div class='row'>
                    <div class='large-12 columns'>
                        <div
                            class='row margin-bottom'
                            style={{
                                marginBottom: '20px',
                            }}
                        >
                            <div class='medium-3 columns'>
                                <h5>Active subscriptions</h5>
                            </div>
                            <div class='medium-6 columns margin-bottom' />
                            <div class='medium-3 columns'>{createButton}</div>
                        </div>
                        {subs}
                    </div>
                </div>
            );

            const paymentButton = (
                <button
                    onClick={this.openChargeModal.bind(this)}
                    class='expanded round border small button right'
                    type='submit'
                    style={{ marginLeft: '15px' }}
                >
                    New payment
                </button>
            );

            transactions = (
                <div>
                    <div class='row small-margin-bottom'>
                        <div class='medium-3 columns'>
                            <h5>Customer transactions</h5>
                        </div>
                        <div class='medium-6 columns margin-bottom' />
                        <div class='medium-3 columns'>{paymentButton}</div>
                    </div>
                    <CustomerTransactions
                        location={this.props.location}
                        filter={this.state.filter}
                        customerId={this.props.params.customer}
                    />
                    <div class='row margin-bottom'>
                        <div class='large-12 columns'>
                            <hr />
                        </div>
                    </div>
                </div>
            );
        }

        var iModal;
        if (customer) {
            iModal = (
                <SubscriptionModal
                    modalName='New subscription'
                    ref={m => (this._modal = m)}
                    customer={customer.id}
                />
            );
        }

        var name;
        var date;
        var mrr;
        var details;
        if (this.props.customerDetails.details.fetched) {
            if (!customer.first_name && !customer.last_name) name = 'Anonymous user';
            else name = `${customer.first_name || ''} ${customer.last_name || ''}`;
            date = (
                <FormattedDate
                    value={customer.created_at}
                    day='2-digit'
                    month='2-digit'
                    year='numeric'
                />
            );
            mrr = (
                <FormattedNumber
                    value={customer.monthlyRevenue}
                    style='currency'
                    currency={this.props.currentProject.project.default_currency}
                />
            );

            const editButton = (
                <div class='row text-right'>
                    <div class='large-12 columns'>
                        <button
                            onClick={this.edit.bind(this)}
                            class='round border small button right'
                            type='submit'
                            style={{ marginLeft: '15px' }}
                        >
                            Edit
                        </button>
                    </div>
                </div>
            );

            details = (
                <div>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 end columns'>
                            {this.getLine('First name', customer.first_name)}
                            {this.getLine('Last name', customer.last_name)}
                            {this.getLine('Email', customer.email)}
                            {this.getLine(
                                'Created at',
                                <FormattedDate
                                    value={customer.created_at}
                                    day='2-digit'
                                    month='2-digit'
                                    year='numeric'
                                />,
                            )}
                            {this.getLine('Address1', customer.address1)}
                            {this.getLine('Address2', customer.address2)}
                            {this.getLine('City', customer.city)}
                            {this.getLine('Zip', customer.zip)}
                            {this.getLine('Country', customer.country_code)}
                        </div>
                    </div>
                    <div class='row'>
                        <div class='large-12 columns'>
                            <MetadataDisplay metadata={customer.metadata} />
                        </div>
                    </div>
                    {editButton}
                </div>
            );

            var tokens;
            var emptyTokens;
            if (this.props.customerDetails.tokens.fetched) {
                tokens = this.props.customerDetails.tokens.tokens
                    .filter(token => token.is_chargeable)
                    .map(function(token) {
                        return <Token key={token.id} token={token} />;
                    });

                if (this.props.customerDetails.tokens.tokens.length === 0) {
                    emptyTokens = (
                        <Empty text='This customer does not have any payment method yet.' />
                    );
                }
            }
        }

        var newCardbutton;
        if (this.props.scripts.processout.loaded) {
            newCardbutton = (
                <button
                    onClick={this.openTokenModal.bind(this)}
                    class='round border small button right'
                    type='submit'
                    style={{ marginLeft: '15px' }}
                >
                    Add a new card
                </button>
            );
        }

        return (
            <ContentLayout title={<h4>{this.state.creating ? 'New customer' : 'Customer'}</h4>}>
                <DetailsModal
                    ref={m => (this._detailsModal = m)}
                    customer={customer}
                    creating={false}
                />
                <TokenModal
                    customerId={customer.id}
                    ref={function(m) {
                        this._tokenModal = m;
                    }.bind(this)}
                />
                <CustomerChargeModal
                    customerId={customer.id}
                    ref={function(m) {
                        this._chargeModal = m;
                    }.bind(this)}
                />
                <div class='row margin-bottom'>
                    <div class='large-5 columns'>
                        <div class='details-box'>
                            <div class='row'>
                                <div class='large-6 columns'>
                                    <label>Name</label>
                                    <span class='huge-font bold'>{name}</span>
                                </div>
                                <div class='large-6 columns text-right'>
                                    <label>Created at</label>
                                    <span class='bold'>{date}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='large-7 columns'>
                        <div class='details-box'>
                            <div class='row'>
                                <div class='medium-6 columns'>
                                    <label>Subscriptions</label>
                                    <span class='greyed bold'>—</span>
                                </div>
                                <div class='medium-6 columns text-right'>
                                    <label>Last 30-days revenue</label>
                                    <span class='bold'>{mrr}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class='row'>
                    <div class='medium-5 columns'>
                        <div class='row'>
                            <div class='large-12 columns'>
                                <h6 class='margin-bottom capitalize greyed font-console'>
                                    Details
                                </h6>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-12 columns'>{details}</div>
                        </div>
                    </div>
                    <div class='medium-7 columns'>
                        <div class='row'>
                            <div class='large-12 columns'>
                                <h6 class='margin-bottom capitalize greyed font-console'>
                                    Payment methods
                                </h6>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-12 columns'>
                                {tokens}
                                {emptyTokens}
                                <div class='row text-right' style={{ marginTop: '1.5em' }}>
                                    <div class='large-12 columns'>{newCardbutton}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {iModal}
                <div class='row margin-bottom margin-top'>
                    <div class='large-12 columns'>{this.state.creating ? '' : <hr />}</div>
                </div>
                {transactions}

                {invoices}
                <Pagination
                    itemsArray={
                        this.props.subscriptions.fetched
                            ? this.props.subscriptions.subscriptions
                            : []
                    }
                    hide={!this.props.subscriptions.fetched || this.props.subscriptions.fetching}
                    location={this.props.location}
                    disableURLUpdate={true}
                    hasMore={
                        this.props.subscriptions.fetched ? this.props.subscriptions.has_more : false
                    }
                    reloadFunction={InvoicesActions.loadRecurringInvoices.bind(
                        null,
                        this.props.params.customer,
                        this.state.filter,
                        SUBS_PER_PAGE,
                    )}
                />
            </ContentLayout>
        );
    }
}
