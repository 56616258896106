// @flow

import { takeEvery, put } from 'redux-saga/effects';
import type { $Action } from '../../util/Types';
import { REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH } from './actions';
import * as ProcessOut from '../../util/ProcessOut';
import { CHART_PREVIEW_TEMPLATE } from '../Telescope/Sagas/sagas';
import moment from 'moment';
import { typeFulfilled } from '../../util/ProcessOut';
import * as Sentry from '@sentry/browser';

function* fetchCustomerMonthlyRevenue(action: $Action): Generator<*, *, *> {
    try {
        const { payload } = action;
        if (!payload) throw new Error('Missing payload');
        const chart = {
            ...CHART_PREVIEW_TEMPLATE,
            settings: {
                formula: `sum{
                    path:transactions.captured_amount_local;
                    customer_id == "${payload.customerId}";
                    default: 0;
                }`,
            },
            type: 'single-value',
        };
        const startDate = moment()
            .subtract(30, 'days')
            .date(1);
        const endDate = moment();
        console.log(startDate, endDate);
        const httpResult = yield ProcessOut.APIcallPromise(
            `/boards/board_default-sales/charts?start_at=${startDate.unix()}&end_at=${endDate.unix()}`,
            'POST',
            JSON.stringify(chart),
            null,
            null,
            true,
        );
        if (!httpResult.data.success) {
            yield put({
                type: ProcessOut.typeFailed(REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH),
                payload: {
                    error: httpResult.data.message,
                },
            });
            return null;
        }

        yield put({
            type: ProcessOut.typeFulfilled(REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH),
            payload: {
                monthlyRevenue: httpResult.data.data[0].value,
            },
        });
    } catch (error) {
        Sentry.captureException(error);
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeEvery(REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH, fetchCustomerMonthlyRevenue);
}
