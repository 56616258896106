// @flow

import type { $Surface, $Surfaces } from './consts';
import type { $Action, $State } from '../../../util/Types';
import { typeFailed, typeFulfilled, typePending } from '../../../util/ProcessOut';
import { REQUEST_SURFACES_FETCH } from './consts';

export type $SurfacesState = $Surfaces & $State;

const defaultState: $SurfacesState = {
    surface_explained: 0,
    total_failed: 0,
    surfaces: [],
    fetched: false,
    fetching: false,
    error: null,
    generating: false,
    validated: true,
};

export default function(state: $SurfacesState = defaultState, action: $Action): $SurfacesState {
    switch (action.type) {
        case typePending(REQUEST_SURFACES_FETCH): {
            return {
                ...state,
                ...defaultState,
            };
        }

        case typeFulfilled(REQUEST_SURFACES_FETCH): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                ...action.payload,
            };
        }

        case typeFailed(REQUEST_SURFACES_FETCH): {
            return {
                ...state,
                error: action.payload,
                fetched: true,
                fetching: false,
            };
        }
    }
    return state;
}
