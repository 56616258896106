// @flow

import React from 'react';
import { connect } from 'react-redux';
import ContentLayout from '../../pages/ContentLayout';
import type { $ApiSetupState } from './consts';
import * as Actions from './actions';
import type { $Dispatcher } from '../../util/Types';
import Error from '../../components/Error';
import Loading from '../../components/Loader';
import NotPermitted from '../Permissions/NotPermitted';
import Project from '../../util/Project';
// $FlowFixMe
import './apisetup.scss';
import PrivateKey from './PrivateKey';
import NewKeyModal from './NewKeyModal';
import ModalFooter from '../../components/ModalFooter';
import PrivateKeyModal from './PrivateKeyModal';
import { CLOSE_MODAL, OPEN_MODAL } from '../../util/Types';

type Props = {
    apiSetup: $ApiSetupState,
    params: {
        project: string,
    },
} & $Dispatcher;

class ApiSetup extends React.Component<Props> {
    _creationModal: any;
    _creationFooter: any;

    componentDidMount() {
        this.props.dispatch(Actions.requestApiSetupPreparation());
        document.title = 'Developer API | ProcessOut';
    }

    onApiVersionChange = event => {
        this.props.dispatch(Actions.updateApiVersion(this.props.apiSetup, event.target.value));
    };

    displayNewPrivateKey = (secret: string) => {
        const { dispatch } = this.props;
        this._creationFooter.getWrappedInstance().lock();
        dispatch(
            Actions.createApiKey(
                this._creationModal.getValues().name,
                (secret: string) => {
                    dispatch({
                        type: OPEN_MODAL,
                        payload: {
                            header: 'API key created!',
                            content: <PrivateKeyModal privateKey={secret} />,
                            footer: (
                                <div class='row'>
                                    <div class='large-12 columns text-right'>
                                        <a
                                            class='small round border button'
                                            onClick={() => {
                                                dispatch({ type: CLOSE_MODAL });
                                            }}
                                        >
                                            close
                                        </a>
                                    </div>
                                </div>
                            ),
                        },
                    });
                },
                () => {
                    this._creationFooter.getWrappedInstance().unlock();
                },
            ),
        );
    };

    openCreationModal = () => {
        const { dispatch } = this.props;
        let footer = (
            <div class='row'>
                <div class='medium-12 columns text-right'>
                    <ModalFooter
                        submitTitle='Create'
                        submitCallback={this.displayNewPrivateKey}
                        ref={e => (this._creationFooter = e)}
                    />
                </div>
            </div>
        );
        dispatch({
            type: OPEN_MODAL,
            payload: {
                header: 'New API key',
                content: <NewKeyModal ref={e => (this._creationModal = e)} />,
                footer: footer,
            },
        });
    };

    render() {
        const { apiSetup, params } = this.props;
        const project = new Project(params.project);

        return (
            <ContentLayout
                title={
                    <div class='row'>
                        <div class='large-3 columns'>
                            <h4>API setup</h4>
                        </div>
                        <div class='large-9 columns text-right' />
                    </div>
                }
            >
                {apiSetup.fetched ? (
                    apiSetup.error ? (
                        apiSetup.error.notPermitted ? (
                            <NotPermitted />
                        ) : (
                            <Error />
                        )
                    ) : (
                        <div class='row'>
                            <div class='large-12 columns'>
                                <div class='row small-margin-bottom'>
                                    <div class='large-12 columns'>
                                        <h5>
                                            {project.isSandbox() ? 'Sandbox' : 'Production'} public
                                            key
                                        </h5>
                                    </div>
                                </div>
                                <div class='row margin-bottom'>
                                    <div class='large-6 columns end'>
                                        <input
                                            type='text'
                                            disabled={true}
                                            value={`${project.isSandbox() ? 'test-' : ''}${
                                                apiSetup.publicKey
                                            }`}
                                            style={{ marginTop: '.5em' }}
                                        />
                                    </div>
                                </div>
                                <div className='row small-margin-bottom'>
                                    <div className='large-8 columns'>
                                        <h5>
                                            {project.isSandbox() ? 'Sandbox' : 'Production'} private
                                            keys
                                        </h5>
                                    </div>
                                    <div class='large-4 columns text-right'>
                                        <a
                                            className='round small border button'
                                            onClick={this.openCreationModal}
                                        >
                                            Create new private key
                                        </a>
                                    </div>
                                </div>
                                <div
                                    class='row collapse small-margin-bottom greyed'
                                    style={{ padding: '0 1em' }}
                                >
                                    <div class='large-7 columns'>Name</div>
                                    <div class='large-4 columns'>Creation date</div>
                                    <div class='large-1 columns text-right'>State</div>
                                </div>
                                {apiSetup.apiKeys.map(key => (
                                    <PrivateKey privateKey={key} key={key.name} />
                                ))}
                            </div>
                        </div>
                    )
                ) : (
                    <Loading />
                )}
            </ContentLayout>
        );
    }
}

export default connect(store => {
    return { apiSetup: store.apiSetup };
})(ApiSetup);
