// @flow

import React from 'react';
import type { $Filter } from '../analytics/DataExplorer/ChartBuilder/Filters/consts';
import { connect } from 'react-redux';
import Loading from '../../components/Loader';
import { getTokenType } from '../SearchBar/Util';
import { FIELD_TOKEN, FIELD_TOKENS, REQUEST_FETCH_VALUES_FOR_FIELD } from '../SearchBar/consts';
import type { $TokenInfo } from '../SearchBar/consts';
import type { $Dispatcher } from '../../util/Types';
import type { $Option } from '../analytics/DataExplorer/consts';
import { SEARCH_BUILDER_TOKENS } from './consts';
import TextFilter from './TextFilter';
import { DateRangePicker } from 'react-dates';
import type { $SearchBuilderOption } from './consts';

type Props = {
    filter: $Filter,
    onChange: (?$Filter) => void,
    searchBar: { [string]: Array<$Option> },
    document: string,
} & $Dispatcher;

type State = {
    showAll: boolean,
    collapsed: boolean,
};

class SearchBuilderFilter extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            showAll: false,
            collapsed: false,
        };
    }

    componentDidMount() {
        const document = SEARCH_BUILDER_TOKENS[this.props.document];
        if (document) {
            const tokenInfos = document.find(item => item.value === this.props.filter.path);
            if (tokenInfos && tokenInfos.canFetchValues) {
                this.props.dispatch({
                    type: REQUEST_FETCH_VALUES_FOR_FIELD,
                    payload: {
                        field: this.props.filter.path,
                        projectId: '',
                        document: this.props.document,
                    },
                });
            }
        }
    }

    valueUpdated = (value: string, checked: boolean) => {
        const { onChange, filter } = this.props;
        const newFilter = {
            ...filter,
        };
        if (filter.value.includes(value) && !checked) {
            // Need to remove the value from this filter
            const newValues = newFilter.value.slice(0);
            const index = newValues.findIndex(v => v === value);
            if (index > -1) newValues.splice(index, 1);
            newFilter.value = newValues;
        } else {
            // Need to add the value to the filter
            const newValues = newFilter.value.slice(0);
            newValues.push(value);
            newFilter.value = newValues;
        }
        onChange(newFilter);
    };

    textValueUpdated = (value: string) => {
        const { onChange, filter } = this.props;
        const newFilter = {
            ...filter,
            value: [value],
        };
        onChange(newFilter);
    };

    removeFilter = () => {
        this.props.onChange(null);
    };

    render() {
        const { filter, searchBar } = this.props;
        const correspondingFilter = SEARCH_BUILDER_TOKENS[this.props.document].find(
            option => option.value === filter.path,
        );
        return (
            <div class='row collapse'>
                <div class='medium-12 columns'>
                    <div class='row path-row collapse'>
                        <div className='medium-1 columns'>
                            <input type={'checkbox'} checked={true} onChange={this.removeFilter} />
                        </div>
                        <div
                            className={`medium-7 columns${
                                this.state.collapsed ||
                                !searchBar[filter.path] ||
                                searchBar[filter.path].length <= 5 ||
                                !correspondingFilter ||
                                !correspondingFilter.canFetchValues
                                    ? ' end'
                                    : ''
                            }`}
                            onClick={() => {
                                this.setState({ collapsed: !this.state.collapsed });
                            }}
                        >
                            {correspondingFilter ? correspondingFilter.label : filter.path}
                        </div>
                        {!this.state.collapsed &&
                            searchBar[filter.path] &&
                            searchBar[filter.path].length > 5 &&
                            correspondingFilter &&
                            correspondingFilter.canFetchValues && (
                                <a
                                    className='medium-4 columns text-right small-font'
                                    onClick={() => {
                                        this.setState({ showAll: !this.state.showAll });
                                    }}
                                >
                                    {this.state.showAll ? 'Hide' : 'Show all'}(
                                    {searchBar[filter.path].length - 5})
                                </a>
                            )}
                    </div>
                    {!this.state.collapsed && (
                        <div className='row filter-values collapse'>
                            <div className='medium-12 columns'>
                                {correspondingFilter && correspondingFilter.canFetchValues && (
                                    <div>
                                        {!searchBar[filter.path] && <Loading size={16} />}
                                        {searchBar[filter.path] &&
                                            searchBar[filter.path]
                                                .filter(
                                                    (_, index) => this.state.showAll || index < 5,
                                                )
                                                .map(possibility => (
                                                    <div
                                                        key={possibility.value}
                                                        className='row possibility-row collapse'
                                                    >
                                                        <div className='medium-1 columns'>
                                                            <input
                                                                type={'checkbox'}
                                                                checked={filter.value.includes(
                                                                    possibility.value,
                                                                )}
                                                                onChange={event => {
                                                                    this.valueUpdated(
                                                                        possibility.value,
                                                                        event.target.checked,
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='medium-11 columns'>
                                                            {possibility.label}
                                                        </div>
                                                    </div>
                                                ))}
                                    </div>
                                )}
                                {correspondingFilter && !correspondingFilter.canFetchValues && (
                                    <div>
                                        <TextFilter
                                            value={filter.value[0]}
                                            onChange={newValue => {
                                                this.textValueUpdated(newValue);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(store => ({ searchBar: store.searchBar }))(SearchBuilderFilter);
