// @flow
import React from 'react';
import ContentLayout from './ContentLayout';
import * as PlansActions from '../Actions/PlansActions';
import * as CurrencyActions from '../Actions/CurrencyActions';
import Plan from '../components/Plan';
import Loader from '../components/Loader';
import Error from '../components/Error';
import Empty from '../components/Empty';
import { connect } from 'react-redux';
import PlansModal from '../components/PlansModal';
import Pagination from '../components/Pagination';
import PropTypes from 'prop-types';
import type { $PlansState } from '../stores/Reducers/PlansReducer';
import type { $Dispatcher } from '../util/Types';
import NotPermitted from '../features/Permissions/NotPermitted';

const plansPerPage = 15;

type Props = {
    plansDetails: $PlansState,
} & $Dispatcher;

@connect(store => {
    return {
        plansDetails: store.plans,
        currenciesDetails: store.currencies,
    };
})
export default class Plans extends React.Component {
    props: Props;
    _modal: any;

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Plans | ProcessOut';
        var firstItem = this.props.location.query.after_item;
        if (!firstItem) firstItem = '';
        this.props.dispatch(PlansActions.fetchPlans(plansPerPage, true, firstItem));
        if (!this.props.currenciesDetails.fetching && !this.props.currenciesDetails.fetched) {
            this.props.dispatch(CurrencyActions.loadCurrencies());
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        var firstItem = this.props.location.query.after_item;
        if (!firstItem) firstItem = '';

        if (
            !nextProps.plansDetails.fetching &&
            !nextProps.plansDetails.fetched &&
            !nextProps.plansDetails.error
        ) {
            this.props.dispatch(PlansActions.fetchPlans(plansPerPage, true, firstItem));
        }

        if (
            !nextProps.currenciesDetails.fetched &&
            !nextProps.currenciesDetails.fetching &&
            !nextProps.currenciesDetails.error
        ) {
            this.props.dispatch(CurrencyActions.loadCurrencies());
        }
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    openModal() {
        this._modal.getWrappedInstance().openModal();
    }

    render() {
        const plans = this.props.plansDetails.plans;

        var content;
        if (
            (this.props.plansDetails.fetching ||
                !this.props.plansDetails.fetched ||
                this.props.currenciesDetails.fetching ||
                !this.props.currenciesDetails.fetched) &&
            !this.props.plansDetails.error &&
            !this.props.currenciesDetails.error
        ) {
            content = <Loader />;
        } else if (this.props.currenciesDetails.error || this.props.plansDetails.error) {
            if (this.props.plansDetails.error && this.props.plansDetails.error.notPermitted)
                content = <NotPermitted />;
            else content = <Error />;
        } else {
            var header;
            if (plans && plans.length > 0) {
                header = (
                    <div
                        class='row capitalize greyed bold small-margin-bottom'
                        style={{ fontSize: '0.8em' }}
                    >
                        <div class='medium-10 end columns'>
                            <div class='row'>
                                <div class='medium-4 columns'>Amount</div>
                                <div class='medium-8 columns'>Name</div>
                            </div>
                        </div>
                    </div>
                );
            }

            content = (
                <div>
                    {header}
                    {plans.map(function(plan) {
                        return (
                            <div key={plan.id}>
                                <Plan plan={plan} />
                            </div>
                        );
                    })}
                </div>
            );
        }

        const createButton = (
            <a onClick={this.openModal.bind(this)} class='round small border button'>
                New plan
            </a>
        );

        var pageHeader = (
            <div class='row'>
                <div class='medium-8 columns'>
                    <h4>Plans</h4>
                </div>
                <div class='medium-4 columns text-right'>{createButton}</div>
            </div>
        );

        return (
            <ContentLayout title={pageHeader}>
                <PlansModal modalName='New plan' ref={m => (this._modal = m)} />
                {(() => {
                    if (this.props.plansDetails.fetched && (plans == null || plans.length == 0)) {
                        return (
                            <div>
                                <div class='row'>
                                    <Empty text="You haven't created any plan yet." />
                                </div>
                            </div>
                        );
                    } else {
                        return <div>{content}</div>;
                    }
                })()}
                <Pagination
                    reloadFunction={PlansActions.fetchPlans.bind(null, plansPerPage)}
                    location={this.props.location}
                    hasMore={
                        this.props.plansDetails.fetched ? this.props.plansDetails.has_more : false
                    }
                    hide={this.props.plansDetails.fetching || !this.props.plansDetails.fetched}
                    itemsArray={this.props.plansDetails.fetched ? plans : []}
                />
            </ContentLayout>
        );
    }
}
