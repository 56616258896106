// @flow

export const REQUEST_CONFIRMATION = 'REQUEST_CONFIRMATION';
export type $RequestConfirmationAction = {
    type: string,
    payload: {
        content: any, // HTML content
        resolve: any => any,
        reject: any => any,
    },
};
