import React from 'react';
import * as ProjectsActions from '../Actions/ProjectsActions';
import { connect } from 'react-redux';
import Loader from './Loader';
import PropTypes from 'prop-types';

const MAX_CONFIGURATIONS = 10;

@connect(function(store) {
    return {
        dunning: store.dunning,
        project: store.currentProject,
    };
})
export default class Dunning extends React.Component {
    constructor() {
        super();
        this.state = {
            configurations: [],
        };
    }

    componentDidMount() {
        if (this.props.project.fetched) this.fetchDunning();
    }

    fetchDunning() {
        this.props.dispatch(ProjectsActions.fetchDunning(this.props.project.project.id));
    }

    componentWillReceiveProps(nextProps) {
        if (
            !nextProps.dunning.fetched &&
            !nextProps.dunning.fetching &&
            nextProps.project.fetched
        ) {
            this.fetchDunning();
        }

        if (nextProps.dunning.fetched)
            this.setState({
                configurations: nextProps.dunning.dunning_configurations,
            });
    }

    componentDidUpdate(prevProps, prevState) {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    saveDunning(event) {
        event.preventDefault();
        var config = this.state.configurations;
        var project = this.props.project.project;
        project.dunning_configuration = config;
        project.api_version_name = project.api_version.name;
        this.props.dispatch(
            ProjectsActions.updateDunning(this.props.project.project.id, JSON.stringify(project)),
        );
    }

    addConfiguration(id) {
        var configs = this.state.configurations;
        if (configs.length >= MAX_CONFIGURATIONS) return;

        configs.splice(id, 0, { action: 'retry', delay_in_days: 1 });
        this.setState({ configurations: configs });
    }

    updateConfiguration(id, event) {
        var configs = this.state.configurations;
        configs[id].delay_in_days = parseInt(event.target.value);
        this.setState({ configurations: configs });
    }

    removeConfiguration(id) {
        var configs = this.state.configurations;
        configs.splice(id, 1);
        this.setState({ configurations: configs });
    }

    render() {
        var count = -1;

        if (this.props.dunning.fetching || !this.props.dunning.fetched) {
            return (
                <div class='row margin-bottom'>
                    <div class='large-3 columns'>
                        <h5>Subscriptions</h5>
                    </div>
                    <div class='large-9 columns'>
                        <Loader />
                    </div>
                </div>
            );
        }

        return (
            <div class='row'>
                <div class='medium-12 columns'>
                    <form onSubmit={this.saveDunning.bind(this)}>
                        <div class='row margin-bottom'>
                            <div class='large-3 columns'>
                                <h5>Subscriptions</h5>
                            </div>
                            <div class='large-7 end columns'>
                                When a subscription payment fails, you might want to dynamically
                                attempt a new capture at a later date according to the settings you
                                set below.
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-8 large-offset-4 medium-offset-4 medium-8 columns'>
                                <div class='row margin-bottom'>
                                    <div class='large-12 columns'>
                                        <div id='dunning-actions'>
                                            {this.state.configurations.map(
                                                function(configuration) {
                                                    count++;
                                                    return (
                                                        <DunningConfiguration
                                                            id={count}
                                                            removeConfiguration={this.removeConfiguration.bind(
                                                                this,
                                                            )}
                                                            updateConfiguration={this.updateConfiguration.bind(
                                                                this,
                                                            )}
                                                            addConfiguration={this.addConfiguration.bind(
                                                                this,
                                                            )}
                                                            key={count}
                                                            ref={`config-${count}`}
                                                            isFirstAction={count === 0}
                                                            isLastAction={
                                                                count ===
                                                                this.state.configurations.length - 1
                                                            }
                                                            configuration={configuration}
                                                        />
                                                    );
                                                }.bind(this),
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-9 large-offset-3 columns clearfix'>
                                <input
                                    type='submit'
                                    class='button border small round'
                                    value='Save'
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

class DunningConfiguration extends React.Component {
    static defaultProps = {
        isFirstAction: false,
    };

    static propTypes = {
        configuration: PropTypes.object.isRequired,
        addConfiguration: PropTypes.func.isRequired,
        removeConfiguration: PropTypes.func.isRequired,
        id: PropTypes.number.isRequired,
        isFirstAction: PropTypes.bool,
    };

    getValue() {
        return this.refs.delay.value;
    }

    render() {
        var configuration = this.props.configuration;
        var isFirst = this.props.isFirstAction;

        var intro;
        var middle;
        var end;

        if (isFirst) {
            intro = (
                <div class='greyed' style={{ float: 'left' }}>
                    When the first payment fails,
                </div>
            );
        } else {
            intro = (
                <div class='greyed' style={{ float: 'left' }}>
                    then
                </div>
            );
        }

        if (configuration.delay_in_days != null) {
            middle = (
                <div style={{ float: 'left' }}>
                    <span style={{ float: 'left', marginLeft: '5px' }}>wait</span>
                    <input
                        ref='delay'
                        class='bottom-border'
                        type='number'
                        step='1'
                        style={{
                            width: '30px',
                            height: '20px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            textAlign: 'right',
                            padding: '0px',
                            float: 'left',
                        }}
                        value={configuration.delay_in_days}
                        onChange={this.props.updateConfiguration.bind(null, this.props.id)}
                    />
                    <span class='greyed' style={{ float: 'left', marginLeft: '5px' }}>
                        days
                    </span>
                </div>
            );
        }

        if (configuration.action === 'retry') {
            end = (
                <div style={{ float: 'left', marginLeft: '5px' }}>
                    <span class='greyed'> and</span> <span>try again</span>,
                </div>
            );
        } else {
            end = (
                <div style={{ float: 'left', marginLeft: '5px' }}>
                    <span class='greyed'>then</span> cancel the subscription
                    <span class='greyed'>if the payment fails again.</span>
                </div>
            );
        }

        var addButton;
        if (!this.props.isFirstAction) {
            addButton = (
                <div
                    class='bold'
                    style={{
                        float: 'left',
                        marginTop: '-20px',
                        marginLeft: '-30px',
                    }}
                >
                    <a
                        onClick={this.props.addConfiguration.bind(null, this.props.id)}
                        class='greyed'
                    >
                        +
                    </a>
                </div>
            );
        } else if (this.props.isFirstAction && this.props.isLastAction) {
            addButton = (
                <div class='bold' style={{ float: 'left', marginLeft: '-30px' }}>
                    <a
                        onClick={this.props.addConfiguration.bind(null, this.props.id)}
                        class='greyed'
                    >
                        +
                    </a>
                </div>
            );
        }
        var removeButton;
        if (!this.props.isLastAction) {
            removeButton = (
                <div style={{ float: 'left', marginLeft: '15px' }}>
                    <a
                        onClick={this.props.removeConfiguration.bind(null, this.props.id)}
                        class='greyed configuration-image'
                    >
                        remove
                    </a>
                </div>
            );
        }

        var content;
        if (this.props.isFirstAction && this.props.isLastAction) {
            content = (
                <div>
                    Cancel the subscription<span class='greyed'> if the first payment fails.</span>
                </div>
            );
        } else if (this.props.isLastAction) {
            content = (
                <div
                    style={{
                        paddingLeft:
                            !this.props.isFirstAction && !this.props.isLastAction ? '30px' : '0px',
                    }}
                >
                    {end}
                </div>
            );
        } else {
            content = (
                <div
                    style={{
                        paddingLeft:
                            !this.props.isFirstAction && !this.props.isLastAction ? '30px' : '0px',
                    }}
                >
                    {intro}
                    {middle}
                    {end}
                </div>
            );
        }

        return (
            <div class='row dunning'>
                <div class='large-12 columns text-right'>
                    {addButton}
                    <div style={{ float: 'left' }}>{content}</div>
                    {removeButton}
                </div>
            </div>
        );
    }
}
