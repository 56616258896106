import React from 'react';
import Select from 'react-select';
import * as PlansActions from '../../Actions/PlansActions';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';

@connect(function(store) {
    return {
        plansDetails: store.plans,
    };
})
export default class PlanSelect extends React.Component {
    constructor() {
        super();
        this.state = {
            value: null,
        };
    }

    componentDidMount() {
        this.props.dispatch(PlansActions.fetchPlans());
    }

    planSelected(value) {
        this.setState({ value: value });
    }

    render() {
        const plans = this.props.plansDetails.plans;

        var fetching = !this.props.plansDetails.fetched && this.props.plansDetails.fetching;
        var options = [];
        if (!fetching && this.props.plansDetails.fetched) {
            options = plans.map(function(plan) {
                return { value: plan.id, label: plan.name };
            });
        }

        return (
            <Select
                name="plan_id"
                placeholder="Use an existing plan"
                options={options}
                isLoading={fetching}
                value={this.state.value}
                onChange={this.planSelected.bind(this)}
            />
        );
    }
}
