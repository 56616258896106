// @flow
/**
 * Created by jeremylejoux on 11/09/2017.
 */

import React from 'react';
import { connect } from 'react-redux';
import { INITIAL_PULLING, PULLING } from './Messages';

@connect(function(store) {
    return {
        currentProject: store.currentProject,
    };
})
export default class PullingIndicator extends React.Component {
    props: {
        currentProject: {
            fetched: boolean,
            error: boolean,
            project: {
                id: string,
                is_pulling_initial: boolean,
                is_pulling: boolean,
            },
        },
    };

    render() {
        let { currentProject } = this.props;

        if (currentProject.error || !currentProject.fetched) return null;

        let project = currentProject.project;

        if (!project.is_pulling_initial || !project.is_pulling) return null;

        return (
            <div class="row pulling-indicator">
                <div class="large-12 columns">
                    {project.is_pulling_initial ? INITIAL_PULLING : PULLING}
                </div>
            </div>
        );
    }
}
