// @flow
import Country from 'country-data';
import type { $FeesRecommendation } from '../reducer';

/**
 * Computes a color gradient
 * @param color1
 * @param color2
 * @param ratio
 * @returns the gradient
 */
export function computeGradient(color1: string, color2: string, ratio: number) {
    const hex = function(x) {
        // $FlowFixMe
        x = x.toString(16);
        return x.length === 1 ? '0' + x : x;
    };

    const r = Math.ceil(
        parseInt(color1.substring(0, 2), 16) * ratio +
            parseInt(color2.substring(0, 2), 16) * (1 - ratio),
    );
    const g = Math.ceil(
        parseInt(color1.substring(2, 4), 16) * ratio +
            parseInt(color2.substring(2, 4), 16) * (1 - ratio),
    );
    const b = Math.ceil(
        parseInt(color1.substring(4, 6), 16) * ratio +
            parseInt(color2.substring(4, 6), 16) * (1 - ratio),
    );

    return hex(r) + hex(g) + hex(b);
}

export function computeAuthorizationColor(data: any): any {
    if (data.country.authorization < 0.6) return '#E04C4C';
    if (data.country.authorization < 0.8)
        return '#' + computeGradient('E04C4C', 'FFEB57', (0.8 - data.country.authorization) / 0.2);
    return '#' + computeGradient('FFEB57', '4CE04C', (1 - data.country.authorization) / 0.2);
}

export function computeFeesColor(
    revenue: number,
    transactions: number,
    fees_recommendations: Array<$FeesRecommendation>,
    data: any,
): any {
    // we first loop through fees recos and force red where we have a recommendation
    for (const reco of fees_recommendations) {
        if (reco.country_code === data.key) return '#E04C4C';
        if (reco.country_code === 'EU') {
            const EUROPE_COUNTRIES = Country.regions
                .map(region => region.name.toLowerCase().includes('europe'))
                .map(region => region.countries);
            if (EUROPE_COUNTRIES.includes(data.key)) return '#E04C4C';
        }
    }

    if (data.country.fees === 0) return 'rgba(0,0, 0, 0.1)';
    let percent = 2.9;
    let fixed = 0.3;
    for (const regionIndex in Country.regions) {
        const region = Country.regions[regionIndex];
        if (region.countries.includes(data.key)) {
            if (region.name.toLowerCase().includes('europe')) {
                if (revenue < 30000) {
                    percent = 1.4;
                    fixed = 0.25;
                } else if (revenue < 100000) {
                    percent = 1.2;
                    fixed = 0.15;
                } else if (revenue < 250000) {
                    percent = 1.0;
                    fixed = 0.1;
                } else if (revenue < 500000) {
                    percent = 0.9;
                    fixed = 0.1;
                } else if (revenue < 1000000) {
                    percent = 0.8;
                    fixed = 0.1;
                } else {
                    percent = 0.7;
                    fixed = 0.1;
                }
            } else if (region.name.includes('america')) {
                if (revenue < 30000) {
                    percent = 2.9;
                    fixed = 0.3;
                } else if (revenue < 100000) {
                    percent = 2.5;
                    fixed = 0.2;
                } else if (revenue < 250000) {
                    percent = 2.4;
                    fixed = 0.2;
                } else if (revenue < 500000) {
                    percent = 2.0;
                    fixed = 0.15;
                } else if (revenue < 1000000) {
                    percent = 2.0;
                    fixed = 0.15;
                } else {
                    percent = 2.0;
                    fixed = 0.15;
                }
            }
        }
    }
    const shouldHavePaid = (revenue * percent) / 100 + transactions * fixed;
    const paid = revenue * data.country.fees;
    if (paid < shouldHavePaid) return '#4CE04C';
    if (shouldHavePaid / paid < 0.5)
        return `#${computeGradient('FFEB57', 'E04C4C', shouldHavePaid / paid)}`;
    return `#${computeGradient('4CE04C', 'FFEB57', shouldHavePaid / paid)}`;
}
