// @flow
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import type { $TelescopeRequestAction } from '../consts';
import {
    PREPARE_TELESCOPE,
    PREPARE_TELESCOPE_SILENT,
    REQUEST_TELESCOPE_PREPARATION,
    UPDATE_TELESCOPE_TAB,
    UPDATE_TELESCOPE_TIMESPAN,
} from '../consts';
import * as ProcessOut from '../../../util/ProcessOut';
import moment from 'moment';
import { typeFulfilled } from '../../../util/ProcessOut';
import { typePending } from '../../../util/ProcessOut';
import * as AuthorizationSagas from './authorizationSagas';
import * as CaptureSagas from './captureSagas';
import * as RefundsSagas from './refundsSagas';
import { typeFailed } from '../../../util/ProcessOut';
import type { $TelescopeState } from '../../../stores/Reducers/TelescopeReducer/Index';
import * as Actions from '../actions';
import * as Sentry from '@sentry/browser';

export const CHART_PREVIEW_TEMPLATE = {
    name: 'preview',
    description: 'preview',
    type: 'bar-chart',
    unit: 'currency',
    preview: true,
    size: 12,
};

export const getStartDateFromTimespan = (timespan: string): number => {
    switch (timespan) {
        case 'weekly': {
            return moment()
                .subtract(1, 'weeks')
                .unix();
        }
        case 'monthly': {
            return moment()
                .subtract(1, 'months')
                .unix();
        }
        case 'quarterly': {
            return moment()
                .subtract(1, 'quarters')
                .unix();
        }
        case 'annually': {
            return moment()
                .subtract(1, 'years')
                .unix();
        }
        default: {
            return moment()
                .subtract(1, 'weeks')
                .unix();
        }
    }
};

export const computeUrl = (url: string, timespan: string): string => {
    let startDate = getStartDateFromTimespan(timespan);

    return `${url}?start_at=${startDate}&end_at=${moment().unix()}`;
};

function* fetchTelescope(timespan: string): Generator<*, *, *> {
    try {
        const httpResult = yield ProcessOut.APIcallPromise(
            `/analytics/telescope?interval=${timespan}`,
            'GET',
        );
        if (!httpResult.data.success)
            throw httpResult.data.message || 'Error while fetching telescope';
        return {
            telescope: httpResult.data.telescope,
            fees_recommendations: httpResult.data.telescope.recommendations_feed.filter(
                r => r.category === 'fees',
            ),
        };
    } catch (error) {
        return null;
    }
}

export function* prepareTelescope(action: $TelescopeRequestAction): Generator<*, *, *> {
    const { timespan, tab } = action.payload;
    yield put({
        type: typePending(action.payload.silent ? PREPARE_TELESCOPE_SILENT : PREPARE_TELESCOPE),
        payload: { tab },
    });
    try {
        const gwayResults = yield call(
            ProcessOut.APIcallPromise,
            '/helpers/gateway-configurations/names',
            'GET',
        );

        const gatewayConfigurations = Object.keys(gwayResults.data.gateway_configuration_names).map(
            id => ({ id: id, name: gwayResults.data.gateway_configuration_names[id] }),
        );

        let promises = [
            call(AuthorizationSagas.fetchGlobalAuth, timespan),
            call(CaptureSagas.fetchGlobalFees, timespan),
            call(RefundsSagas.fetchGlobalRefunds, timespan),
        ];
        switch (tab) {
            case 'auth': {
                promises = promises.concat([
                    call(fetchTelescope, timespan),
                    call(AuthorizationSagas.fetchAuthCountry, timespan),
                    call(AuthorizationSagas.fetchAuthorizedTransactions, timespan),
                    call(AuthorizationSagas.fetchAuthorizedAmount, timespan),
                    call(AuthorizationSagas.fetchFailedTransactions, timespan),
                    call(
                        AuthorizationSagas.fetchAuthorizationPerPspBar,
                        timespan,
                        gatewayConfigurations,
                    ),
                    call(
                        AuthorizationSagas.fetchAuthorizationPerPspPerCardSchemeBar,
                        timespan,
                        gatewayConfigurations,
                    ),
                    call(
                        AuthorizationSagas.fetchAuthorizationPerPspPerCardTypeBar,
                        timespan,
                        gatewayConfigurations,
                    ),
                ]);
                break;
            }
            case 'capt': {
                promises = promises.concat([
                    call(CaptureSagas.fetchCaptureAmount, timespan),
                    call(CaptureSagas.fetchCapturedTransactions, timespan),
                    call(CaptureSagas.fetchGatewayFees, timespan),
                    call(CaptureSagas.fetchFeesPercentPerCountry, timespan),
                    call(CaptureSagas.fetchGatewayFeesPerPspBar, timespan, gatewayConfigurations),
                    call(
                        CaptureSagas.fetchFeesPerPspPerCardSchemeBar,
                        timespan,
                        gatewayConfigurations,
                    ),
                    call(
                        CaptureSagas.fetchFeesPerPspPerCardTypeBar,
                        timespan,
                        gatewayConfigurations,
                    ),
                ]);
                break;
            }
            case 'refu': {
                promises = promises.concat([
                    call(RefundsSagas.fetchRefundedAmount, timespan),
                    call(RefundsSagas.fetchRefundedTransactions, timespan),
                    call(RefundsSagas.fetchRefundsPerCountry, timespan),
                    call(RefundsSagas.fetchVoidedAmount, timespan),
                    call(RefundsSagas.fetchVoidedTransactions, timespan),
                    call(RefundsSagas.fetchRefundAmountPerPspBar, timespan, gatewayConfigurations),
                ]);
                break;
            }
            default: {
                break;
            }
        }
        const results = yield all(promises);
        yield put({
            type: typeFulfilled(PREPARE_TELESCOPE),
            payload: {
                data: results.reduce((value, result) => {
                    return { ...value, ...result };
                }, {}),
                tab,
            },
        });
    } catch (error) {
        yield put({
            type: typeFailed(PREPARE_TELESCOPE),
            payload: { error, tab },
        });

        Sentry.captureException(error);
    }
}

export function formatBarChart(
    data: Array<{ key: string, value: string }>,
): Array<{ key: string, datapoints: Array<{ key: string, value: string }> }> {
    return [
        {
            key: 'single',
            datapoints: data,
        },
    ];
}

export function* fetchTelescopeChartData(formula: string, timespan: string): Generator<*, *, *> {
    const chart = {
        ...CHART_PREVIEW_TEMPLATE,
        settings: {
            formula: formula,
        },
        type: 'single-value',
    };
    const httpResult = yield ProcessOut.APIcallPromise(
        computeUrl('/boards/board_default-sales/charts', timespan),
        'POST',
        JSON.stringify(chart),
        null,
        null,
        true,
    );
    if (!httpResult.data.success) {
        return null;
    }

    return httpResult.data.data;
}

function* updatedTimespan(): Generator<*, *, *> {
    const telescope: $TelescopeState = yield select(store => store.telescope);
    yield put(
        Actions.requestTelescopePrepare(
            telescope.standard.timespan,
            true,
            telescope.standard.selectedTab,
        ),
    );
}

export function* requestTelescopePreparation(): Generator<*, *, *> {
    yield takeLatest(REQUEST_TELESCOPE_PREPARATION, prepareTelescope);
    yield takeLatest(UPDATE_TELESCOPE_TIMESPAN, updatedTimespan);
}
