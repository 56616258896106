// @flow
import React from 'react';
import ContentLayout from './ContentLayout';
import * as CouponsActions from '../Actions/CouponsActions';
import * as CurrencyActions from '../Actions/CurrencyActions';
import Loader from '../components/Loader';
import Empty from '../components/Empty';
import Error from '../components/Error';
import { connect } from 'react-redux';
import CouponModal from '../components/CouponModal';
import Coupon from '../components/Coupon';
import Pagination from '../components/Pagination';
import PropTypes from 'prop-types';
import type { $CouponsState } from '../stores/Reducers/CouponsReducer';
import type { $Dispatcher } from '../util/Types';
import NotPermitted from '../features/Permissions/NotPermitted';

const COUPONS_PER_PAGE = 15;

type Props = {
    couponsDetails: $CouponsState,
} & $Dispatcher;

@connect(store => {
    return {
        couponsDetails: store.coupons,
        currenciesDetails: store.currencies,
    };
})
export default class Coupons extends React.Component {
    state: {
        filter: string,
    };
    props: Props;
    _modal: any;

    constructor() {
        super();
        this.state = {
            filter: '',
        };
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Coupons | ProcessOut';
        this.props.dispatch(
            CouponsActions.fetchCoupons(
                COUPONS_PER_PAGE,
                true,
                this.props.location.query.firstItem,
                '',
            ),
        );
        if (!this.props.currenciesDetails.fetching && !this.props.currenciesDetails.fetched) {
            this.props.dispatch(CurrencyActions.loadCurrencies());
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (
            !nextProps.couponsDetails.fetching &&
            !nextProps.couponsDetails.fetched &&
            !nextProps.couponsDetails.error
        ) {
            this.props.dispatch(CouponsActions.fetchCoupons(COUPONS_PER_PAGE, true));
        }

        if (
            !nextProps.currenciesDetails.fetched &&
            !nextProps.currenciesDetails.fetching &&
            !nextProps.currenciesDetails.error
        ) {
            this.props.dispatch(CurrencyActions.loadCurrencies());
        }
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    openModal() {
        this._modal.getWrappedInstance().openModal();
    }

    render() {
        const coupons = this.props.couponsDetails.coupons;
        var content;

        const createButton = (
            <a onClick={this.openModal.bind(this)} class='round small border button'>
                New coupon
            </a>
        );

        if (
            (this.props.couponsDetails.fetching ||
                !this.props.couponsDetails.fetched ||
                this.props.currenciesDetails.fetching ||
                !this.props.currenciesDetails.fetched) &&
            !this.props.couponsDetails.error &&
            !this.props.currenciesDetails.error
        ) {
            content = <Loader />;
        } else if (this.props.currenciesDetails.error || this.props.couponsDetails.error) {
            if (
                this.props.currenciesDetails.error.notPermitted ||
                this.props.couponsDetails.error.notPermitted
            )
                content = <NotPermitted />;
            else content = <Error />;
        } else {
            var header;
            if (coupons && coupons.length > 0) {
                header = (
                    <div
                        class='row capitalize greyed bold small-margin-bottom'
                        style={{ fontSize: '0.8em' }}
                    >
                        <div class='medium-2 columns'>Price</div>
                        <div class='medium-5 columns'>ID</div>
                        <div class='medium-5 columns'>Redemptions</div>
                    </div>
                );
            }

            content = (
                <div>
                    {header}
                    {coupons.map(function(coupon) {
                        return <Coupon key={coupon.id} coupon={coupon} />;
                    })}
                </div>
            );
        }

        const pageHeader = (
            <div class='row'>
                <div class='medium-9 columns'>
                    <h4>Coupons</h4>
                </div>
                <div class='medium-3 columns text-right'>{createButton}</div>
            </div>
        );

        return (
            <ContentLayout title={pageHeader}>
                <CouponModal modalName='New coupon' ref={m => (this._modal = m)} />
                {(() => {
                    if (
                        this.props.couponsDetails.fetched &&
                        (coupons == null || coupons.length == 0)
                    ) {
                        return (
                            <div>
                                <div class='row'>
                                    <Empty text="You haven't created any coupon yet." />
                                </div>
                            </div>
                        );
                    } else {
                        return <div>{content}</div>;
                    }
                })()}
                <Pagination
                    reloadFunction={CouponsActions.fetchCoupons.bind(null, COUPONS_PER_PAGE)}
                    hasMore={this.props.couponsDetails.has_more || false}
                    location={this.props.location}
                    itemsArray={this.props.couponsDetails.fetched ? coupons : []}
                    hide={this.props.couponsDetails.fetching || !this.props.couponsDetails.fetched}
                />
            </ContentLayout>
        );
    }
}
