// @flow
import { takeEvery, put, call } from 'redux-saga/effects';
import { REQUEST_PROJECT_CREATION } from './consts';
import { typeFailed, typePending } from '../../util/ProcessOut';
import * as ProcessOut from '../../util/ProcessOut';
import LocalStorage from 'local-storage';
import { refreshToken } from '../NetworkManager/sagas';
import { replace } from 'react-router-redux';
import * as Sentry from '@sentry/browser';

export type $ProjectCreationPayload = {
    name: string,
    default_currency: string,
    region?: string,
};

function* createProject(action: { type: string, payload: $ProjectCreationPayload }) {
    try {
        yield put({ type: typePending(REQUEST_PROJECT_CREATION) });
        const { name, default_currency, region } = action.payload;
        const headers = region
            ? {
                  ...ProcessOut.generateHeaders(''),
                  'X-ProcessOut-region': region,
              }
            : ProcessOut.generateHeaders('');

        const httpResult = yield ProcessOut.APIcallPromise(
            '/projects',
            'POST',
            JSON.stringify({ name, default_currency, region }),
            headers,
        );
        // refresh token
        const rToken = LocalStorage('refresh-token');
        if (rToken) {
            yield call(refreshToken, rToken);
        }

        yield put(replace(`/projects?target=${httpResult.data.project.id}`));
    } catch (error) {
        yield put({
            type: typeFailed(REQUEST_PROJECT_CREATION),
            payload: { error: error },
        });
        Sentry.captureException(error);
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeEvery(REQUEST_PROJECT_CREATION, createProject);
}
