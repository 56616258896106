export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case 'FETCH_TWO_FACTOR_DATA_PENDING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
            };
        }

        case 'FETCH_TWO_FACTOR_DATA_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                data: action.payload.user,
            };
        }
    }

    return state;
}
