import React from 'react';
import { connect } from 'react-redux';
import CardDetails from './TransactionsDetails/CardDetails';
import Customer from './TransactionsDetails/Customer';
import PropTypes from 'prop-types';
import * as Actions from '../Actions/CustomerDetailsActions';

var serialize = require('form-serialize');

@connect(
    function(store) {
        return {
            projects: store.projects,
        };
    },
    null,
    null,
    { withRef: true },
)
export default class CardModal extends React.Component {
    static propTypes = {
        card: PropTypes.object,
    };

    openModal() {
        var card = this.props.card;

        var customer = <Customer customer={card.token ? card.token.customer : null} />;

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: (
                    <div class='row greyed'>
                        <div class='medium=12 small-font'>{card.id}</div>
                    </div>
                ),
                content: (
                    <div>
                        <CardDetails card={card} />
                        <div class='row small-margin-bottom'>
                            <div class='large-12 columns'>
                                <hr />
                            </div>
                        </div>
                        {customer}
                    </div>
                ),
                footer: (
                    <div class='row'>
                        <div class='large-6 columns'>
                            <a
                                onClick={function(event) {
                                    event.preventDefault();
                                    const { dispatch, customerId, tokenId } = this.props;
                                    dispatch({
                                        type: 'REQUEST_CONFIRMATION',
                                        payload: {
                                            content: (
                                                <div class='row'>
                                                    <div class='large-12 columns text-center'>
                                                        Do you really want to delete this token?
                                                    </div>
                                                </div>
                                            ),
                                            resolve: () => {
                                                dispatch(
                                                    Actions.deleteToken(
                                                        customerId,
                                                        tokenId,
                                                        success => {
                                                            if (success)
                                                                dispatch({
                                                                    type: 'CLOSE_MODAL',
                                                                });
                                                        },
                                                    ),
                                                );
                                            },
                                            reject: () => {
                                                dispatch({
                                                    type: 'CLOSE_MODAL',
                                                });
                                            },
                                        },
                                    });
                                }.bind(this)}
                                class='round border small button'
                            >
                                Delete
                            </a>
                        </div>
                        <div class='large-6 columns text-right'>
                            <a
                                onClick={function(event) {
                                    event.preventDefault();
                                    this.props.dispatch({
                                        type: 'CLOSE_MODAL',
                                    });
                                }.bind(this)}
                                class='main round small button'
                            >
                                Close
                            </a>
                        </div>
                    </div>
                ),
            },
        });
    }

    render() {
        return null;
    }
}
