// @flow
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import type { $PermissionsState } from './reducer';
import type { $Dispatcher } from '../../util/Types';
import type { $Collaborator } from './consts';
import * as Actions from './actions';

type Props = {
    collaborator: $Collaborator,
    permissionsDetails: $PermissionsState,
} & $Dispatcher;

const CREATE_NEW_GROUP = 'CREATE_NEW_GROUP';

class PermissionGroupSelection extends React.Component {
    props: Props;

    groupSelected = (newValue: { label: string, value: string }) => {
        const { dispatch, collaborator } = this.props;
        if (newValue.value === CREATE_NEW_GROUP) {
            // Create new permission group
        } else if (newValue.value === 'owner') {
            dispatch(Actions.setCollaboratorRole(collaborator, 'owner'));
        } else {
            dispatch(Actions.setCollaboratorPermissionGroup(collaborator, newValue.value));
        }
    };

    render() {
        const { collaborator, permissionsDetails } = this.props;
        const permissionGroupsDetails = permissionsDetails.permissionGroups;
        const OPTIONS = [{ value: 'owner', label: 'owner' }].concat(
            permissionGroupsDetails.fetchedState.permissionGroups.map(group => ({
                value: group.name,
                label: group.name,
            })),
        );
        return (
            <div
                style={{
                    width: '90%',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                }}
            >
                <Select
                    value={{
                        label: collaborator.permission_group_name || collaborator.role,
                        value: collaborator.permission_group_name || collaborator.role,
                    }}
                    options={OPTIONS}
                    onChange={this.groupSelected}
                    clearable={false}
                />
            </div>
        );
    }
}

export default connect(store => ({ permissionsDetails: store.permissions }))(
    PermissionGroupSelection,
);
