// @flow
import React from 'react';
import { connect } from 'react-redux';
import Partial from './Partial';
import PanelNumber from '../Components/PanelNumber';
import type { $TelescopeState } from '../../../stores/Reducers/TelescopeReducer/Index';
import type { $Project } from '../../../util/Types';
import type { $SurfacesState } from '../Surface/reducer';

type Props = {
    telescope: $TelescopeState,
    currentProject: {
        project: $Project,
    },
    surfaces: $SurfacesState,
};
class Authorizations extends React.Component<Props> {
    render() {
        const { data } = this.props.telescope.standard;
        const { surfaces } = this.props;
        const currency = this.props.currentProject.project.default_currency || 'USD';
        return (
            <Partial
                leftPanel={
                    <div class='row'>
                        <div class='large-6 columns'>
                            <PanelNumber
                                number={data.authorized_amount}
                                name='Authorized'
                                style='currency'
                                currency={currency}
                            />
                        </div>
                        <div class='large-6 columns text-right'>
                            <PanelNumber
                                number={data.transactions_authorized}
                                name='Authorized transactions'
                            />
                        </div>
                    </div>
                }
                rightPanel={
                    <div class='row'>
                        <div class='large-4 columns'>
                            <PanelNumber
                                number={data.failed_transactions}
                                name='Failed transactions'
                            />
                        </div>
                        <div class='large-4 columns text-center'>
                            <img
                                src={'//dashboard.processout.com/images/long-right-arrow.png'}
                                style={{ transform: 'scale(.4)', opacity: 0.4 }}
                            />
                        </div>
                        <div class='large-4 columns text-right'>
                            <PanelNumber
                                number={
                                    surfaces.surfaces.length === 0 || !surfaces.validated
                                        ? null
                                        : surfaces.surfaces.reduce(
                                              (value, s) => value + s.total_estimated_gain,
                                              0,
                                          )
                                }
                                name='To be recovered'
                                disableWarnings={true}
                                style='currency'
                                currency={currency}
                            />
                        </div>
                    </div>
                }
            />
        );
    }
}

export default connect(store => {
    return {
        telescope: store.telescope,
        currentProject: store.currentProject,
        surfaces: store.surfaces,
    };
})(Authorizations);
