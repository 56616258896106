// @flow

import React from 'react';

class PaymentsOK extends React.PureComponent<{}> {
    render() {
        return (
            <div class='row margin-bottom'>
                <div class='medium-12 columns text-center greyed bold'>
                    Your benchmarked payment performance is all good!
                </div>
            </div>
        );
    }
}

export default PaymentsOK;
