// @flow

import type { $Action } from '../../util/Types';
import * as ProcessOut from '../../util/ProcessOut';
import type { $Collaborator, $PermissionFeature, $PermissionGroup } from './consts';
import { PREPARE_PERMISSIONS } from './consts';

export type $PreparePermissionsAction = {
    type: 'PREPARE_PERMISSIONS_FULFILLED',
    payload: {
        collaborators: Array<$Collaborator>,
        permissionGroups: Array<$PermissionGroup>,
    },
};

export function preparePermissions(): $Action {
    return {
        type: PREPARE_PERMISSIONS,
    };
}

export const FETCH_PERMISSION_GROUPS = 'FECH_PERMISSION_GROUPS';
export function fetchPermissionGroups(): $Action {
    return {
        type: FETCH_PERMISSION_GROUPS,
        payload: ProcessOut.APIcallPromise('/permission-groups', 'GET'),
    };
}

export const FETCH_PERMISSION_GROUP_DETAILS = 'FETCH_PERMISSION_GROUP_DETAILS';
export function fetchPermissionGroupDetails(name: string): $Action {
    return {
        type: FETCH_PERMISSION_GROUP_DETAILS,
        payload: ProcessOut.APIcallPromise(`/permission-groups/${name}`, 'GET'),
    };
}

export const SET_COLLABORATOR_PERMISSION_GROUP = 'SET_COLLABORATOR_PERMISSION_GROUP';
export type $SetCollaboratorPermissionGroupAction = {
    type: 'SET_COLLABORATOR_PERMISSION_GROUP',
    payload: {
        collaborator: $Collaborator,
        group: string,
    },
};
export const setCollaboratorPermissionGroup = (
    collaborator: $Collaborator,
    group: string,
): $SetCollaboratorPermissionGroupAction => ({
    type: SET_COLLABORATOR_PERMISSION_GROUP,
    payload: { collaborator, group },
});

export const SET_COLLABORATOR_ROLE = 'SET_COLLABORATOR_ROLE';
export type $SetCollaboratorRoleAction = {
    type: 'SET_COLLABORATOR_ROLE',
    payload: {
        collaborator: $Collaborator,
        role: 'owner' | 'permission-group',
    },
};
export const setCollaboratorRole = (
    collaborator: $Collaborator,
    role: 'owner' | 'permission-group',
): $SetCollaboratorRoleAction => ({ type: SET_COLLABORATOR_ROLE, payload: { collaborator, role } });

export const SET_PERMISSION_GROUP_VALUES = 'SET_PERMISSION_GROUP_VALUES';
export type $SetPermissionGroupValuesAction = {
    type: 'SET_PERMISSION_GROUP_VALUES',
    payload: {
        groupName: string,
        feature: $PermissionFeature,
        action: string,
        category: 'allow' | 'deny',
    },
};
export const setPermissionGroupValues = (
    groupName: string,
    feature: $PermissionFeature,
    action: string,
    category: 'allow' | 'deny',
): $SetPermissionGroupValuesAction => ({
    type: SET_PERMISSION_GROUP_VALUES,
    payload: { groupName, feature, action, category },
});

export const SAVE_TEAM_SETTINGS = 'SAVE_TEAM_SETTINGS';
export const saveTeamSettings = (): $Action => {
    return {
        type: SAVE_TEAM_SETTINGS,
    };
};

export const ADD_PERMISSION_GROUP = 'ADD_PERMISSION_GROUP';
export const addPermissionGroup = (): $Action => {
    return {
        type: ADD_PERMISSION_GROUP,
    };
};

export const REMOVE_PERMISSION_FROM_GROUP = 'REMOVE_PERMISSION_FROM_GROUP';
export const removePermissionFromGroup = (
    groupName: string,
    feature: $PermissionFeature,
    category: 'allow' | 'deny',
): $Action => ({ type: REMOVE_PERMISSION_FROM_GROUP, payload: { groupName, feature, category } });

export const UPDATE_GROUP_NAME = 'UPDATE_GROUP_NAME';
export type $UpdateGroupNameAction = {
    type: 'UPDATE_GROUP_NAME',
    payload: {
        id: string,
        name: string,
    },
};
export const updateGroupName = (id: string, name: string): $UpdateGroupNameAction => ({
    type: UPDATE_GROUP_NAME,
    payload: {
        id,
        name,
    },
});

export const DELETE_PERMISSION_GROUP = 'DELETE_PERMISSION_GROUP';
export type $DeletePermissionGroupAction = {
    type: 'DELETE_PERMISSION_GROUP',
    payload: {
        name: string,
    },
};
export const deletePermissionGroup = (name: string): $DeletePermissionGroupAction => ({
    type: DELETE_PERMISSION_GROUP,
    payload: { name },
});

export const SAVE_PERMISSION_GROUPS = 'SAVE_PERMISSION_GROUPS';
export const savePermissionGroups = (): $Action => ({ type: SAVE_PERMISSION_GROUPS });

export const ADD_COLLABORATOR = 'ADD_COLLABORATOR';
export const addCollaborator = (): $Action => ({ type: ADD_COLLABORATOR });

export const UPDATE_COLLABORATOR_EMAIL = 'UPDATE_COLLABORATOR_EMAIL';
export type $UpdateCollaboratorEmailAction = {
    type: 'UPDATE_COLLABORATOR_EMAIL',
    payload: { id: string, email: string },
};
export const updateCollaboratorEmail = (
    id: string,
    email: string,
): $UpdateCollaboratorEmailAction => ({
    type: UPDATE_COLLABORATOR_EMAIL,
    payload: { id, email },
});

export const DELETE_MODERATOR = 'DELETE_MODERATOR';
export const deleteModerator = (id: string) => ({ type: DELETE_MODERATOR, payload: { id } });
