import React from 'react';
import MetadataDisplay from '../MetadataDisplay';
import PropTypes from 'prop-types';

export default class PlanDetails extends React.Component {
    static propTypes = {
        plan: PropTypes.object.isRequired,
    };

    render() {
        const plan = this.props.plan;

        return (
            <div class="row">
                <div class="large-12 columns">
                    <div class="row small-margin-bottom">
                        <div class="large-3 columns greyed">Identifier</div>
                        <div class="large-9 columns font-console text-right">{plan.id}</div>
                    </div>
                    <div class="row small-margin-bottom">
                        <div class="large-3 columns greyed">Name</div>
                        <div class="large-9 columns text-right">{plan.name}</div>
                    </div>
                    <div class="row small-margin-bottom">
                        <div class="row small-margin-bottom">
                            <div class="large-12 medium-9 columns">
                                {plan.metadata.length > 0 ? (
                                    <MetadataDisplay metadata={plan.metadata} />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
