import * as ProcessOut from '../../../util/ProcessOut';

export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case 'FETCH_CUSTOMER_TOKENS_PENDING': {
            state = {
                ...state,
                fetched: false,
                fetching: true,
            };
            break;
        }

        case 'FETCH_CUSTOMER_TOKENS_FULFILLED': {
            state = {
                ...state,
                ...action.payload,
                fetched: true,
                fetching: false,
            };
            break;
        }

        case ProcessOut.typeFailed('FETCH_CUSTOMER_TOKENS'): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: action.payload,
            };
        }
    }

    return state;
}
