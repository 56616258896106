// @flow

import type { $Action, $State } from '../../../util/Types';
import { TELESCOPE_ANALYTICS } from '../../../features/Telescope/Sagas/sagas';
import { typePending } from '../../../util/ProcessOut';
import { PREPARE_TELESCOPE } from '../../../features/Telescope/consts';

type State = {
    authorization: {},
    fees: {},
} & $State;

const defaultState: State = {
    authorization: {},
    fees: {},
    fetched: false,
    fetching: false,
};

export default function(state: State = defaultState, action: $Action): State {
    switch (action.type) {
        case typePending(PREPARE_TELESCOPE): {
            return {
                ...state,
                fetching: true,
                fetched: false,
            };
        }

        case TELESCOPE_ANALYTICS: {
            return {
                ...state,
                ...action.payload,
                fetched: true,
                fetching: false,
            };
        }
        default:
            return state;
    }
}
