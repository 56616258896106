export default function(
    state = {
        fetching: false,
        projects: [],
        fetched: false,
    },
    action,
) {
    switch (action.type) {
        case 'SET_PROJECT_ID': {
            return {
                ...state,
                current_project_id: action.payload.projectId,
            };
        }
        case 'FETCH_PROJECTS_PENDING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
            };
        }
        case 'FETCH_PROJECTS_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                projects: action.payload.projects,
            };
        }
        case 'PROJECT_CREATE_PENDING': {
            return {
                ...state,
                creatingProject: true,
            };
        }
        case 'PROJECT_CREATE_FULFILLED': {
            return {
                ...state,
                creatingProject: false,
            };
        }
    }

    return state;
}
