// @flow

import React from 'react';

class AdyenFlow extends React.Component<{
    hide: boolean,
    onChange: ('telescope' | 'smart-router') => void,
}> {
    render() {
        if (this.props.hide) return null;
        return (
            <div>
                <div class='row text-center'>
                    <div class='medium-12 columns'>
                        <div class='row'>
                            <div
                                class='medium-6 columns'
                                style={{
                                    borderRight: '1px solid #0d113f',
                                    paddingTop: '1em',
                                }}
                            >
                                <div class='row small-margin-bottom'>
                                    <h3 class='bold'>Telescope</h3>
                                </div>
                                <div class='row small-margin-bottom'>
                                    <div class='medium-10 small-centered columns'>
                                        <p>
                                            Connect your Adyen account to Telescope to get access to
                                            ProcessOut's live monitoring tools.
                                        </p>
                                        <p>
                                            This does not give access to the ProcessOut's
                                            Smart-Routing features.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class='medium-6 columns' style={{ paddingTop: '1em' }}>
                                <div class='row small-margin-bottom'>
                                    <h3 class='bold'>Router</h3>
                                </div>
                                <div class='row small-margin-bottom'>
                                    <div class='medium-10 small-centered columns'>
                                        <p>
                                            Leverage the full power of ProcessOut by connecting
                                            Adyen to ProcessOut's Smart-Routing features.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='row'>
                    <div class='medium-6 columns' style={{ borderRight: '1px solid #0d113f' }}>
                        <div class='row small-margin-bottom'>
                            <div class='medium-11 medium-centered columns'>
                                <a
                                    class='round border button expanded'
                                    onClick={this.props.onChange.bind(null, 'telescope')}
                                >
                                    Set up Telescope
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class='medium-6 columns'>
                        <div class='row small-margin-bottom'>
                            <div class='medium-11 medium-centered columns'>
                                <a
                                    class='round border button expanded'
                                    onClick={this.props.onChange.bind(null, 'smart-router')}
                                >
                                    Set up Smart-Router
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdyenFlow;
