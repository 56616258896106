import React from 'react';
import MenuButton from './MenuButton';
import SubButton from './SubButton';
import * as ProcessOut from '../../util/ProcessOut';
import { connect } from 'react-redux';
import { hasFeature } from '../../features/FeatureFlags/FeatureFlags';
import qs from 'qs';
import './SideMenuStyle.scss';
import { withRouter } from 'react-router';

class SideMenu extends React.Component {
    constructor() {
        super();
        this.state = {
            sandbox: ProcessOut.sandbox,
        };
    }

    render() {
        const { currentProject, boards, analyticsParams } = this.props;

        // we get the current applied filter to generate its query string to insert it into each board's link
        const query = {
            filter: analyticsParams.filter,
        };
        const queryString = qs.stringify(query);

        const analytics = currentProject.project ? (
            <div class='side-menu'>
                <MenuButton
                    location={this.props.location}
                    route='boards'
                    name={
                        <div style={{ display: 'inline-block' }}>
                            <div style={{ display: 'inline-block' }}>Analytics</div>
                            <div
                                style={{
                                    display: 'inline-block',
                                    backgroundColor: '#D1C4E9',
                                    color: '#512DA8',
                                    padding: '.2em .5em .2em .5em',
                                    marginLeft: '1em',
                                    fontWeight: 'bold',
                                    fontSize: '.7em',
                                    borderRadius: '4px',
                                }}
                            >
                                NEW
                            </div>
                        </div>
                    }
                    to={`/projects/${this.props.params.project}/boards?${queryString}`}
                >
                    {new RegExp(/.*@processout.com/).test(this.props.user.details.email) ||
                    hasFeature(currentProject.project.feature_flags, 'analytics-v2') ? (
                        <SubButton
                            key={'data-explorer'}
                            location={this.props.location}
                            route={'data-explorer'}
                            routeRegex={
                                /.*\/projects\/.*\/data-explorer\/(presets|chart-builder|editor)$/
                            }
                            name={
                                <span>
                                    <img
                                        src='//dashboard.processout.com/images/data-explorer.png'
                                        style={{
                                            height: '1.1em',
                                            marginRight: '.5em',
                                            opacity: new RegExp(
                                                /.*\/projects\/.*\/data-explorer\/(presets|chart-builder|editor)$/,
                                            ).test(this.props.location.pathname)
                                                ? 1
                                                : 0.8,
                                        }}
                                    />{' '}
                                    Data explorer
                                </span>
                            }
                            to={`/projects/${this.props.params.project}/boards/data-explorer`}
                        />
                    ) : null}
                    {new RegExp(/.*@processout.com$/).test(this.props.user.details.email)
                        ? [
                              <SubButton
                                  key={'sales-board'}
                                  location={this.props.location}
                                  route={'board_default-sales'}
                                  routeRegex={/.*\/projects\/.*\/boards\/board_default-sales/}
                                  name={<span>Sales overview</span>}
                                  to={`/projects/${
                                      this.props.params.project
                                  }/boards/board_default-sales`}
                              />,
                              <SubButton
                                  key={'payment-perf-board'}
                                  location={this.props.location}
                                  route={'board_default-providers'}
                                  routeRegex={/.*\/projects\/.*\/boards\/board_default-providers/}
                                  name={<span>Payment performance</span>}
                                  to={`/projects/${
                                      this.props.params.project
                                  }/boards/board_default-providers`}
                              />,
                          ]
                        : null}
                    {hasFeature(currentProject.project.feature_flags, 'analytics-v2') ? (
                        <SubButton
                            key={'board-creator'}
                            location={this.props.location}
                            route={'new'}
                            routeRegex={/.*\/projects\/.*\/boards\/new/}
                            name={<span>+ Create new board</span>}
                            to={`/projects/${this.props.params.project}/boards/new`}
                        />
                    ) : null}
                    {boards.boards && boards.boards.length > 0 ? (
                        <div>
                            <div className='menu-separator' />
                            {boards.boards.map(board => {
                                const boardName = board.name;
                                const maxDisplayLength = 20;
                                const trimmedName = boardName.substring(
                                    0,
                                    Math.min(boardName.length, maxDisplayLength),
                                );

                                return (
                                    <SubButton
                                        key={board.id}
                                        location={this.props.location}
                                        route={board.id}
                                        routeRegex={
                                            new RegExp(`.*\projects\/.*\/boards\/${board.id}.*`)
                                        }
                                        name={board.name}
                                        to={`/projects/${this.props.params.project}/boards/${
                                            board.id
                                        }?${queryString}`}
                                    />
                                );
                            })}
                        </div>
                    ) : null}
                </MenuButton>
            </div>
        ) : null;
        let settings;
        if (!this.props.sandbox) {
            settings = (
                <MenuButton
                    location={this.props.location}
                    route='settings'
                    name='Settings'
                    to={`/projects/${this.props.params.project}/settings/general`}
                >
                    <SubButton
                        location={this.props.location}
                        route='general'
                        name='General'
                        to={`/projects/${this.props.params.project}/settings/general`}
                    />
                    <SubButton
                        location={this.props.location}
                        route='team'
                        name='Team'
                        to={`/projects/${this.props.params.project}/settings/team`}
                    />
                    <SubButton
                        location={this.props.location}
                        route='dunning'
                        name='Dunning'
                        to={`/projects/${this.props.params.project}/settings/dunning`}
                    />
                    <SubButton
                        location={this.props.location}
                        route='apple-pay'
                        name='Apple Pay'
                        to={`/projects/${this.props.params.project}/settings/apple-pay`}
                    />
                </MenuButton>
            );
        }

        return (
            <ul class='main-side side-menu'>
                <ButtonGroup name='monitoring'>
                    <MenuButton
                        location={this.props.location}
                        route='telescope'
                        name='Telescope'
                        to={'/projects/' + this.props.params.project + '/telescope'}
                    />
                    {analytics}
                </ButtonGroup>
                <ButtonGroup name='routing & connections'>
                    {this.props.sandbox ? null : (
                        <MenuButton
                            location={this.props.location}
                            route='gateways'
                            name='Payment providers'
                            to={'/projects/' + this.props.params.project + '/gateways'}
                        />
                    )}
                    {!this.props.sandbox &&
                    this.props.currentProject.fetched &&
                    !this.props.currentProject.error ? (
                        <MenuButton
                            location={this.props.location}
                            route='router'
                            name='Smart-Routing'
                            to={`/projects/${this.props.params.project}/router`}
                        />
                    ) : null}
                </ButtonGroup>
                <ButtonGroup name='Payments'>
                    <MenuButton
                        location={this.props.location}
                        route='transactions'
                        name='Transactions'
                        to={'/projects/' + this.props.params.project + '/transactions'}
                    />
                    <MenuButton
                        location={this.props.location}
                        route='products'
                        name='Products'
                        to={'/projects/' + this.props.params.project + '/products'}
                    />
                    <MenuButton
                        location={this.props.location}
                        route='customers'
                        name='Customers'
                        to={'/projects/' + this.props.params.project + '/customers'}
                    />
                    <MenuButton
                        location={this.props.location}
                        route='recurring'
                        name='Recurring billing'
                        to={`/projects/${this.props.params.project}/recurring/subscriptions`}
                    >
                        <SubButton
                            location={this.props.location}
                            route='subscriptions'
                            name='Subscriptions'
                            to={`/projects/${this.props.params.project}/recurring/subscriptions`}
                        />
                        <SubButton
                            location={this.props.location}
                            route='plans'
                            name='Plans'
                            to={`/projects/${this.props.params.project}/recurring/plans`}
                        />
                        <SubButton
                            location={this.props.location}
                            route='coupons'
                            name='Coupons'
                            to={`/projects/${this.props.params.project}/recurring/coupons`}
                        />
                    </MenuButton>
                    <MenuButton
                        location={this.props.location}
                        route='vault'
                        name='Vault'
                        to={'/projects/' + this.props.params.project + '/vault'}
                    />
                </ButtonGroup>
                <ButtonGroup name='account'>
                    <MenuButton
                        location={this.props.location}
                        route='reports'
                        name='Reports'
                        to={`/projects/${this.props.params.project}/reports`}
                    >
                        <SubButton
                            location={this.props.location}
                            route='Uploads'
                            name='Uploads'
                            to={`/projects/${this.props.params.project}/reports/uploads`}
                        />
                        <SubButton
                            location={this.props.location}
                            route='Payouts'
                            name='Payouts'
                            routeRegex={
                                new RegExp(
                                    `.*\projects\/${this.props.params.project}\/reports\/payouts.*`,
                                )
                            }
                            to={`/projects/${this.props.params.project}/reports/payouts`}
                        />
                        <SubButton
                            location={this.props.location}
                            route={'exports'}
                            name={'Exports'}
                            routeRegex={
                                new RegExp(
                                    `.*projects\/${this.props.params.project}\/reports\/exports.*`,
                                )
                            }
                            to={`/projects/${this.props.params.project}/reports/exports`}
                        />
                    </MenuButton>
                    <MenuButton
                        location={this.props.location}
                        route='developer'
                        name='Developer API'
                        to={`/projects/${this.props.params.project}/developer/api-setup`}
                    >
                        <SubButton
                            location={this.props.location}
                            route='api-setup'
                            name='API Setup'
                            to={'/projects/' + this.props.params.project + '/developer/api-setup'}
                        />
                        <SubButton
                            location={this.props.location}
                            route='events'
                            name='Events'
                            to={'/projects/' + this.props.params.project + '/developer/events'}
                        />
                    </MenuButton>
                    {settings}
                </ButtonGroup>
            </ul>
        );
    }
}

class ButtonGroup extends React.Component {
    props: {
        name: string,
        children: any,
    };

    render() {
        if (this.props.children.filter(child => child !== null).length === 0) return null;
        return (
            <div class='group'>
                <div class='row'>
                    <div class='medium-12 columns'>
                        <div class='row'>
                            <div class='medium-12 columns'>
                                <span class='group-name'>{this.props.name.toUpperCase()}</span>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='medium-12 columns'>{this.props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(function(store) {
    return {
        permissions: store.permissions,
        boards: store.analytics_v2.boards,
        currentProject: store.currentProject,
        analyticsParams: store.analytics.params,
        moderators: store.moderators,
        user: store.user,
    };
})(withRouter(SideMenu));
