// @flow
/**
 * Created by jeremylejoux on 18/09/2017.
 */

export type Moderator = {
    created_at: string,
    project_id: string,
    role: string,
    id: string,
    user: User,
    email_unsubscriptions: Array<$NotificationType>,
};

export type User = {
    email: string,
    name: string,
};

export const NOT_ALLOWED =
    "Your role doesn't allow you to change your current notifications settings.";

export const Notifications = {
    'telescope.report': 'Telescope report each week.',
    'project-billing.charged': 'Your project was successfully billed.',
    'project-billing.failed': 'Your project could not be billed.',
    'transaction.completed': 'You received a new payment.',
    'transaction.refunded': 'A refund was issued for one of your payments.',
    'transaction.reversed': 'A chargeback was issued against one of your payments.',
    'payment-provider.rejected-credentials': "Your payment provider's credentials were rejected.",
};

export type $NotificationType = $Keys<typeof Notifications>;

// consts for ChartActions
export const UPDATE_NOTIFICATIONS_REQUESTED = 'UPDATE_NOTIFICATIONS_REQUESTED';
export const UPDATE_NOTIFICATIONS_PENDING = 'UPDATE_NOTIFICATIONS_PENDING';
export const UPDATE_NOTIFICATIONS_FULFILLED = 'UPDATE_NOTIFICATIONS_FULFILLED';
export const UPDATE_NOTIFICATIONS_REJECTED = 'UPDATE_NOTIFICATIONS_REJECTED';

export const FETCH_MODERATORS_PENDING = 'FETCH_MODERATORS_PENDING';
export const FETCH_MODERATORS_FULFILLED = 'FETCH_MODERATORS_FULFILLED';
export const FETCH_MODERATORS_REJECTED = 'FETCH_MODERATORS_REJECTED';
