// @flow
import React from 'react';
import { connect } from 'react-redux';
import Partial from './Partial';
import PanelNumber from '../Components/PanelNumber';
import type { $TelescopeState } from '../../../stores/Reducers/TelescopeReducer/Index';
import type { $Project } from '../../../util/Types';

type Props = {
    telescope: $TelescopeState,
    currentProject: {
        project: $Project,
    },
};
class Chargebacks extends React.Component<Props> {
    render() {
        const { data } = this.props.telescope.standard;
        const currency = this.props.currentProject.project.default_currency || 'USD';
        return (
            <Partial>
                <div class='text-center' style={{ padding: '1.5em' }}>
                    <div style={{ display: 'inline-block', marginRight: '1em' }}>
                        <img
                            src='//dashboard.processout.com/images/purple-calendar.png'
                            style={{ height: '40px' }}
                        />
                    </div>
                    <div style={{ display: 'inline-block' }}>Coming soon</div>
                </div>
            </Partial>
        );
    }
}

export default connect(store => {
    return {
        telescope: store.telescope,
        currentProject: store.currentProject,
    };
})(Chargebacks);
