// @flow
import React from 'react';
import type { $Card } from '../components/TransactionsDetails/CardDetails';

export default function(card: $Card) {
    if (card.scheme.includes('visa'))
        return (
            <img
                alt={card.scheme}
                style={{ height: '20px' }}
                src='//dashboard.processout.com/images/visa.png'
            />
        );
    else if (card.scheme.includes('mastercard'))
        return (
            <img
                alt={card.scheme}
                style={{ height: '20px' }}
                src='//dashboard.processout.com/images/mastercard.png'
            />
        );
    else if (card.scheme.includes('american express'))
        return (
            <img
                alt={card.scheme}
                style={{ height: '20px' }}
                src='//dashboard.processout.com/images/amex.png'
            />
        );
    else if (card.scheme.includes('jcb'))
        return (
            <img
                alt={card.scheme}
                style={{ height: '20px' }}
                src='//dashboard.processout.com/images/jcb.png'
            />
        );
    else if (card.scheme.includes('discover'))
        return (
            <img
                alt={card.scheme}
                style={{ height: '20px' }}
                src='//dashboard.processout.com/images/discover.png'
            />
        );
    else if (card.scheme.includes('diners'))
        return (
            <img
                alt={card.scheme}
                style={{ height: '20px' }}
                src='//dashboard.processout.com/images/diners.png'
            />
        );
    else if (card.scheme.includes('maestro'))
        return (
            <img
                alt={card.scheme}
                style={{ height: '20px' }}
                src='//dashboard.processout.com/images/maestro.png'
            />
        );
    else if (card.scheme.includes('union'))
        return (
            <img
                alt={card.scheme}
                style={{ height: '20px' }}
                src='//dashboard.processout.com/images/union.png'
            />
        );
    else return <div>&nbsp;</div>;
}
