// @flow

import type { $Action, $State } from '../../util/Types';
import type { $NotificationType } from './consts';
import { REQUEST_PREPARE_NOTIFICATIONS } from './actions';
import * as ProcessOut from '../../util/ProcessOut';
import type { $Collaborator } from '../Permissions/consts';
import {
    UPDATE_NOTIFICATIONS_FULFILLED,
    UPDATE_NOTIFICATIONS_REJECTED,
    UPDATE_NOTIFICATIONS_REQUESTED,
} from './consts';

export type $NotificationsState = {
    notifications: Array<$NotificationType>,
    collaborator: ?$Collaborator,
    saving: boolean,
} & $State;

const defaultState: $NotificationsState = {
    fetched: false,
    fetching: false,
    error: null,
    notifications: [],
    collaborator: null,
    saving: false,
};

export default function(
    state: $NotificationsState = defaultState,
    action: $Action,
): $NotificationsState {
    switch (action.type) {
        case REQUEST_PREPARE_NOTIFICATIONS: {
            return {
                ...state,
                ...defaultState,
                fetched: false,
                fetching: true,
                notifications: [],
                collaborator: null,
            };
        }

        case ProcessOut.typeFulfilled(REQUEST_PREPARE_NOTIFICATIONS): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                notifications: action.payload.notifications,
                collaborator: action.payload.collaborator,
            };
        }

        case ProcessOut.typeFailed(REQUEST_PREPARE_NOTIFICATIONS): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: action.payload,
            };
        }

        case UPDATE_NOTIFICATIONS_REQUESTED: {
            return {
                ...state,
                saving: true,
            };
        }

        case UPDATE_NOTIFICATIONS_REJECTED: {
            return {
                ...state,
                error: action.payload,
                saving: false,
            };
        }

        case UPDATE_NOTIFICATIONS_FULFILLED: {
            return {
                ...state,
                saving: false,
            };
        }
    }

    return state;
}
