// @flow

import { takeLatest, takeEvery, select, call, put, take } from 'redux-saga/effects';
import type { $Action } from '../../util/Types';
import {
    ADD_RULE,
    PREPARE_ROUTING_RULES_SETTINGS,
    REMOVE_RULE,
    RULES_FILTERS,
    RULES_SET_TOTAL_TRANSACTIONS,
    SAVE_ROUTING_RULES,
    UPDATE_RULE,
} from './consts';
import type { $RoutingRule } from './consts';
import Uniqid from 'uniqid';
import { typeFailed, typeFulfilled } from '../../util/ProcessOut';
import * as GatewaysActions from '../../Actions/GatewaysActions';
import * as GatewaysActionsBis from '../GatewaysConfigurations/actions';
import * as ProcessOut from '../../util/ProcessOut';
import * as Actions from './actions';
import UniqId from 'uniqid';
import type { $Filter } from './Filter/consts';
import uniqid from 'uniqid';
import { FIELD_TOKENS, REQUEST_FETCH_VALUES_FOR_FIELD } from '../SearchBar/consts';
import * as FilterSagas from '../SearchBar/sagas';
import * as Sentry from '@sentry/browser';
import * as Utils from './Utils';

/**
 * Fetches all the
 */
export function* prepareRoutingRulesSettings(): Generator<*, *, *> {
    try {
        // fetching all the gateways configurations
        yield put.resolve(GatewaysActions.loadGatewaysConfigurations());
        const gateways = yield select(store => store.processorsConfigurations);
        if (gateways.error) {
            yield put({ type: typeFailed(PREPARE_ROUTING_RULES_SETTINGS) });
            throw 'Could not fetch gateway configurations.';
        }

        // fetching each gateway names
        yield put.resolve(GatewaysActionsBis.fetchGwayConfNames());
        const gatewaysNames = yield select(store => store.gateway_configurations_names);
        if (gatewaysNames.error) {
            yield put({ type: typeFailed(PREPARE_ROUTING_RULES_SETTINGS) });
            throw 'Could not fetch gateway configurations names.';
        }

        // fetching all the current rules
        const rulesResult = yield put.resolve(Actions.fetchRules());
        if (rulesResult.value.status !== 200) {
            throw 'Could not fetch routing rules';
        }

        const compileResult = yield put.resolve(
            Actions.compileRules(rulesResult.value.data.routing_rules),
        );

        const fetchedPaths = [];
        const rules = compileResult.value.data.routing_rules_compiled.map(rule => {
            const compiledFilters = rule.condition_token_steps.reduce(
                (value, token) => {
                    if (token.kind !== 'LOGICALOP') {
                        value[value.length - 1].push(token);
                    } else {
                        value.push([]);
                    }
                    return value;
                },
                [[]],
            );
            // we remove the last empty array
            if (compiledFilters[compiledFilters.length - 1].length === 0) {
                compiledFilters.splice(compiledFilters.length - 1, 1);
            }

            const filtersCount = compiledFilters.length;
            const filters = [];
            for (let i = 0; i < filtersCount; i++) {
                let filter: $Filter = {
                    id: uniqid(),
                    path: '',
                    value: [],
                    operand: '==',
                };

                if (compiledFilters[i][0].kind !== 'VARIABLE') {
                    if (compiledFilters[i][0].kind === 'ACCESSOR') {
                        // we check if we're dealing with metadatas
                        const value = compiledFilters[i][0].value;
                        if (value.length > 1 && value[0] === 'metadata') {
                            filter.path = `${value[0]}.${value[1]}`;
                        } else {
                            // unknown filter
                            throw new Error('Could not parse routing rules filter ' + value);
                        }
                    } else if (
                        compiledFilters[i][0].kind === 'FUNCTION' &&
                        compiledFilters[i][0].value.name === 'rand'
                    ) {
                        filter.path = 'rand';
                        const operand = compiledFilters[i].find(
                            entry => entry.kind === 'COMPARATOR',
                        );
                        if (!operand) continue;
                        filter.operand = operand.value;
                        const value = compiledFilters[i].find(entry => entry.kind === 'NUMERIC');
                        if (!value) continue;
                        filter.value = [value.value];
                        filters.push(filter);
                        continue;
                    } else {
                        continue;
                    }
                } else if (rule.variables[compiledFilters[i][0].value]) {
                    // We're dealing with a variable
                    filter.path = rule.variables[compiledFilters[i][0].value].type;
                    if (filter.path === 'velocity') {
                        filter.velocityPath = rule.variables[compiledFilters[i][0].value].path;
                        filter.interval = rule.variables[compiledFilters[i][0].value].interval;
                    }
                } else {
                    filter.path = compiledFilters[i][0].value;
                }

                if (!fetchedPaths.includes(filter.path)) {
                    // We need to fetch possible values for this path
                    fetchedPaths.push(filter.path);
                }

                // Now let's look for the operand and value
                if (
                    compiledFilters[i][1].kind === 'COMPARATOR' &&
                    compiledFilters[i][1].value === 'in'
                ) {
                    // multi value filters
                    // Need to find if there is a negation

                    let negativeExpression = false;
                    for (let j = 2; j < compiledFilters[i].length - 1; j++) {
                        if (
                            compiledFilters[i][j].value === '==' &&
                            compiledFilters[i][j + 1].value === false
                        ) {
                            negativeExpression = true;
                            break; //  Shouldn't be necessary as FALSE should be the last token
                        }
                    }

                    if (negativeExpression) filter.operand = '!=';
                    else filter.operand = '==';
                    const values = [];
                    let valueCount = 3;

                    while (
                        valueCount < compiledFilters[i].length &&
                        compiledFilters[i][valueCount].kind !== 'CLAUSE_CLOSE' &&
                        compiledFilters[i][valueCount].kind !== 'COMPARATOR' &&
                        compiledFilters[i][valueCount].kind !== 'BOOLEAN'
                    ) {
                        values.push(compiledFilters[i][valueCount].value);
                        valueCount += 2; // We skip the comma token
                    }
                    filter.value = values;
                } else {
                    if (compiledFilters[i][2].value === 'null') {
                        if (compiledFilters[i][1].value === '==') filter.operand = 'is-null';
                        else if (compiledFilters[i][1].value === '!=')
                            filter.operand = 'is-not-null';
                    } else {
                        filter.operand = compiledFilters[i][1].value;
                        filter.value = [compiledFilters[i][2].value];
                    }
                }
                filters.push(filter);
            }
            return {
                gateways: !rule.gateways
                    ? []
                    : rule.gateways.map(gateway => {
                          if (gateway.includes('processout')) {
                              return Utils.parseSmartRoutingGateway(gateway);
                          }
                          return {
                              id: uniqid(),
                              gateway: gateway,
                          };
                      }),
                conditions: [
                    {
                        logical: 'and',
                        filters: filters,
                    },
                ],
                declaration: rule.declaration,
                tags:
                    rule.tags ||
                    Utils.computeRuleTags([
                        {
                            logical: 'and',
                            filters: filters,
                        },
                    ]),
                id: uniqid(),
            };
        });

        // Fetching the values for every filter paths
        for (const path of fetchedPaths) {
            if (path !== 'rand' && path !== 'velocity') {
                yield call(FilterSagas.fetchValues, {
                    type: REQUEST_FETCH_VALUES_FOR_FIELD,
                    payload: { document: 'transactions', field: path },
                });
            }
        }

        // Check if we have any routing rules
        if (rules.filter(rule => rule.declaration === 'route').length === 0) {
            // We need to add a default rule
            rules.push({
                id: uniqid(),
                declaration: 'route',
                tags: [],
                gateways: [{ id: uniqid(), gateway: 'processout', configurations: ['all'] }],
                conditions: [
                    {
                        subGroup: null,
                        logical: 'and',
                        filters: [],
                    },
                ],
            });
        }

        // dispatching that we're ready to display the page
        yield put({
            type: typeFulfilled(PREPARE_ROUTING_RULES_SETTINGS),
            payload: {
                rules: rules,
            },
        });
    } catch (error) {
        yield put({
            type: typeFailed(PREPARE_ROUTING_RULES_SETTINGS),
            payload: error,
        });
        Sentry.captureException(error);
    }
}

/**
 * Add a rule to the rules list
 */
function* addRule(action: $Action): Generator<*, *, *> {
    const gateways = yield select(store => store.processorsConfigurations);
    const gateway = gateways.configurations.filter(config =>
        config.gateway.tags.includes('credit-card'),
    )[0];

    const newRule: $RoutingRule = {
        id: Uniqid(),
        conditions: [
            {
                subGroup: null,
                logical: 'and',
                filters: [],
            },
        ],
        tags: [],
        declaration: action.payload.type,
        gateways:
            action.payload.type !== 'route'
                ? []
                : gateway
                ? [{ id: Uniqid(), gateway: gateway.id }]
                : [{ id: Uniqid(), gateway: '' }],
        new: true,
    };

    yield put.resolve({
        type: typeFulfilled(ADD_RULE),
        payload: { rule: newRule },
    });
}

export type $RemoveRuleAction = {
    type: string,
    payload: {
        id: string,
    },
};

/**
 * Removes a rule from the list
 * @param action (Should contain the generated id
 */
function* removeRule(action: $RemoveRuleAction): Generator<*, *, *> {
    // retrieve all the current rules
    const rules = yield select(store => store.routingRulesSettings.rules);
    // find the index of the corresponding rule we want to remove from the list
    const ruleIndex = rules.findIndex(rule => rule.id === action.payload.id);
    if (ruleIndex !== -1) {
        // index found we delete the rule
        rules.splice(ruleIndex, 1);
        yield put({
            type: typeFulfilled(REMOVE_RULE),
            payload: { rules: rules },
        });
    } else {
        ProcessOut.addNotification(
            'The rule you want to remove could not be found. Please refresh the page',
        );
    }
}

export type $UpdateRuleAction = {
    payload: {
        ruleId: string,
        rule: $RoutingRule,
    },
} & $Action;

/**
 * Update a rule
 * @param action
 */
function* updateRule(action: $UpdateRuleAction): Generator<*, *, *> {
    const { rule } = action.payload;
    // Retrieve all the current rules
    const rules = yield select(store => store.routingRulesSettings.rules);
    // look for the rule we want to update
    const ruleIndex = rules.findIndex(entry => entry.id === rule.id);
    if (ruleIndex === -1) return;

    // replace the rule
    rules[ruleIndex] = rule;
    // dispatch the new array
    yield put.resolve({
        type: typeFulfilled(UPDATE_RULE),
        payload: { rules: rules },
    });
}

function* saveRules(): Generator<*, *, *> {
    try {
        let globalFormula = '';
        const rulesSettings = yield select(store => store.routingRulesSettings);
        const rules = rulesSettings.mode === 'smart' ? [] : rulesSettings.rules;
        // order rules by declaration/type

        const blockingRules = rules.filter(rule => rule.declaration === 'block');
        const triggerRules = rules.filter(rule => rule.declaration === 'trigger_3ds');
        const routingRules = rules.filter(rule => rule.declaration === 'route');

        const orderedRules = blockingRules.concat(triggerRules).concat(routingRules);

        for (const rule of orderedRules) {
            const filters = rule.conditions.reduce((value, condition) => {
                // we check if all filters are correct (i.e. no missing values etc.)
                condition.filters = condition.filters.filter(
                    filter =>
                        filter.path &&
                        filter.operand &&
                        filter.value !== null &&
                        filter.value !== undefined &&
                        (typeof filter.value === 'boolean' ||
                            !filter.value instanceof Array ||
                            filter.value.length > 0),
                );

                const filterString = condition.filters
                    .filter(
                        filter =>
                            filter.path && filter.value !== null && filter.value !== undefined,
                    )
                    .map((filter, index, array) => {
                        const correspondingFilter = RULES_FILTERS[filter.path];
                        if (correspondingFilter && correspondingFilter.type === 'boolean') {
                            filter.operand = '=='; // We set the equal operator for every boolean value
                        } else if (filter.path === 'rand') {
                            // or rand()
                            filter.path = 'rand()';
                        } else if (filter.path === 'velocity') {
                            // or Velocity
                            return `velocity{path:${filter.velocityPath}; interval:${
                                filter.interval
                            }} ${filter.operand} ${filter.value}`;
                        }

                        if (filter.operand === 'is-null') {
                            return `${filter.path} == null${
                                index === array.length - 1 ? ';' : ' AND '
                            }`;
                        } else if (filter.operand === 'is-not-null') {
                            return `${filter.path} != null${
                                index === array.length - 1 ? ';' : ' AND '
                            }`;
                        } else {
                            if (
                                filter.value instanceof Array &&
                                filter.value.length > 1 &&
                                (filter.value[0] instanceof String ||
                                    typeof filter.value[0] === 'string')
                            )
                                return `${filter.path} IN (${filter.value.reduce(
                                    (acc, val) => `${acc}${acc && ','}"${val}"`,
                                    '',
                                )})${filter.operand === '!=' ? ' == false' : ''}`;

                            const correspondingOption = RULES_FILTERS[filter.path];
                            // Need to check if we want to store the values as strings or numbers
                            let value = '';
                            if (filter.path.includes('metadata.')) {
                                if (!isNaN(filter.value)) value = filter.value;
                                else value = `"${filter.value}"`;
                            } else if (
                                filter.path === 'rand()' ||
                                (correspondingOption && correspondingOption.type !== 'string')
                            ) {
                                value = filter.value;
                            } else {
                                value = `"${filter.value}"`;
                            }
                            return `${filter.path} ${filter.operand} ${value}`;
                        }
                    })
                    .reduce((result, filter) => `${result}${result && ' AND '}${filter}`, '');
                return `${value}${filterString}`;
            }, '');
            const formula = `${rule.declaration}{gateways: ${rule.gateways.reduce(
                (value, gateway, index, array) => {
                    if (gateway.gateway === 'processout') {
                        // smart routing
                        if (
                            !gateway.configurations ||
                            gateway.configurations.length === 0 ||
                            gateway.configurations.includes('all')
                        )
                            return `${value}${value && ', '}processout`;
                        return `${value}${value && ', '}processout[${gateway.configurations.reduce(
                            (value, config) => `${value}${value && ' '}${config}`,
                            '',
                        )}]`;
                    }
                    return `${value}${value && ', '}${gateway.gateway}`;
                },
                '',
            )};condition: ${filters === '' ? 'true' : filters}; tags: ${rule.tags.reduce(
                (acc, tag) => `${acc}${acc && ','}${tag}`,
                '',
            )}}`;
            globalFormula = `${globalFormula}\n${formula}`;
        }
        const result = yield put.resolve(Actions.saveRules(globalFormula));
        if (result.value.status === 200) {
            ProcessOut.addNotification('Routing rules saved successfully', 'success');
        }
    } catch (error) {
        yield put({ type: typeFailed(SAVE_ROUTING_RULES), payload: { error } });

        Sentry.captureException(error);
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeEvery(PREPARE_ROUTING_RULES_SETTINGS, prepareRoutingRulesSettings);
    yield takeEvery(ADD_RULE, addRule);
    yield takeEvery(REMOVE_RULE, removeRule);
    yield takeEvery(UPDATE_RULE, updateRule);
    yield takeLatest(SAVE_ROUTING_RULES, saveRules);
}
