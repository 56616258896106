// @flow
/**
 * Created by jeremylejoux on 07/06/17.
 */

import React from 'react';
import { connect } from 'react-redux';
import ContentLayout from '../../pages/ContentLayout';
import ModeratorList from '../../features/Permissions/CollaboratorsList';
import Permissions from '../../features/Permissions/Permissions';

export class TeamSettings extends React.Component {
    props: Props;

    static defaultProps = {
        dispatch: null,
    };

    componentDidMount() {
        document.title = 'Settings - Team | ProcessOut';
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    render() {
        return <Permissions />;
    }
}

type Props = {
    dispatch: any | (() => void),
};

export default connect(function(store) {
    return {};
})(TeamSettings);
