// @flow
/**
 * Created by jeremylejoux on 22/09/2017.
 */

import * as ProcessOut from '../../../util/ProcessOut';
import type { Action } from '../../../Types/Action';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import type { Moderator, $NotificationType } from '../consts';
import {
    FETCH_MODERATORS_REJECTED,
    FETCH_MODERATORS_FULFILLED,
    FETCH_MODERATORS_PENDING,
    UPDATE_NOTIFICATIONS_REJECTED,
    UPDATE_NOTIFICATIONS_FULFILLED,
    UPDATE_NOTIFICATIONS_PENDING,
    UPDATE_NOTIFICATIONS_REQUESTED,
} from '../consts';
import * as Store from '../../../stores/Store';
import * as Sentry from '@sentry/browser';
import { REQUEST_PREPARE_NOTIFICATIONS } from '../actions';
import { fetchCollaborators } from '../../Permissions/CollaboratorsActions';
import type { $Collaborator } from '../../Permissions/consts';
import uniqid from 'uniqid';
import * as Actions from '../actions';

export function* prepareNotifications(): Generator<*, *, *> {
    try {
        const collaboratorsResult = yield put.resolve(fetchCollaborators());
        const collaborators: Array<$Collaborator> = collaboratorsResult.value.data.collaborators;

        const user = yield select(store => store.user);

        const collaborator = collaborators.find(c => c.user.email === user.details.email);
        if (!collaborator) {
            yield put({
                type: ProcessOut.typeFailed(REQUEST_PREPARE_NOTIFICATIONS),
                payload: { error: 'Could not find collaborator' },
            });
            return;
        }

        yield put({
            type: ProcessOut.typeFulfilled(REQUEST_PREPARE_NOTIFICATIONS),
            payload: {
                notifications: Actions.computeNotifications(collaborator.email_unsubscriptions),
                collaborator: collaborator,
            },
        });
    } catch (error) {
        yield put({ type: ProcessOut.typeFailed(REQUEST_PREPARE_NOTIFICATIONS), payload: error });
        Sentry.captureException(error);
    }
}

function updateNotificationPromise(
    collaborator: $Collaborator,
    unsubscriptions: Array<$NotificationType>,
) {
    let state = Store.store.getState();

    return ProcessOut.APIcallPromise(`/users/email-unsubscriptions`, 'PUT', {
        email_unsubscriptions: { [state.currentProject.project.id]: unsubscriptions },
    });
}

function fetchModeratorsPromise() {
    let state = Store.store.getState();
    return ProcessOut.APIcallPromise(`/collaborators`, 'GET');
}

export function* updateNotifications(action: Action): Generator<*, *, *> {
    if (!action.payload) return;

    try {
        yield put({ type: UPDATE_NOTIFICATIONS_PENDING });
        yield call(
            updateNotificationPromise,
            action.payload.collaborator,
            action.payload.email_unsubscriptions,
        );
        yield put({ type: UPDATE_NOTIFICATIONS_FULFILLED });
        ProcessOut.addNotification('Notifications settings saved.', 'success');
    } catch (error) {
        yield put({ type: UPDATE_NOTIFICATIONS_REJECTED, payload: error });
        Sentry.captureException(error);
    }
}

export default function* watchUpdateModerator(): Generator<*, *, *> {
    yield takeLatest(REQUEST_PREPARE_NOTIFICATIONS, prepareNotifications);
    yield takeLatest(UPDATE_NOTIFICATIONS_REQUESTED, updateNotifications);
}
