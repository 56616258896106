// @flow

import type { $Action } from '../../../../util/Types';
import type { $BuilderMetric } from './reducer';
import type { $ChartType } from '../../Boards/consts';
import type { $Dimension } from './DimensionSelection/consts';
import type { $Filter } from './Filters/consts';

export const REMOVE_CHART_BUILDER_METRIC = 'REMOVE_CHART_BUILDER_METRIC';
export type $RemoveMetricAction = $Action & {
    payload: {
        metricId: string,
    },
};
export function removeMetric(metricId: string): $Action {
    return {
        type: REMOVE_CHART_BUILDER_METRIC,
        payload: {
            metricId,
        },
    };
}

export const UPDATE_CHART_BUILDER_GENERAL_FORMULA = 'UPDATE_CHART_BUILDER_GENERAL_FORMULA';
export type $UpdateGeneralFormulaAction = $Action & {
    payload: {
        formula: string,
    },
};
export function updateGeneralFormula(formula: string): $UpdateGeneralFormulaAction {
    return {
        type: UPDATE_CHART_BUILDER_GENERAL_FORMULA,
        payload: { formula },
    };
}

export const UPDATE_AUTH_AND_FEES_FILTERS = 'UPDATE_AUTH_AND_FEES_FILTERS';
export function updateAuthAndFeesFilters(filters: Array<$Filter>): $Action {
    return {
        type: UPDATE_AUTH_AND_FEES_FILTERS,
        payload: {
            filters,
        },
    };
}

export const SELECT_BUILDER_METRIC = 'SELECT_BUILDER_METRIC';
export type $SelectBuilderMetricAction = {
    type: 'SELECT_BUILDER_METRIC',
    payload: {
        metric: $BuilderMetric,
    },
};
export function selectBuilderMetric(metric: $BuilderMetric): $SelectBuilderMetricAction {
    return {
        type: SELECT_BUILDER_METRIC,
        payload: {
            metric,
        },
    };
}

export const SELECT_TYPE = 'SELECT_TYPE';
export type $SelectTypeAction = {
    type: 'SELECT_TYPE',
    payload: {
        type: $ChartType,
        oldType: $ChartType,
    },
};
export const selectType = (type: $ChartType, oldType: $ChartType): $SelectTypeAction => ({
    type: SELECT_TYPE,
    payload: { type, oldType },
});

export const SET_DISPLAY_LOCAL_CURRENCY = 'SET_DISPLAY_LOCAL_CURRENCY';
export type $SetDisplayLocalCurrencyAction = {
    type: 'SET_DISPLAY_LOCAL_CURRENCY',
    payload: {
        displayInLocalCurrency: boolean,
    },
};
export const setDisplayLocalCurrency = (displayInLocalCurrency: boolean) => ({
    type: SET_DISPLAY_LOCAL_CURRENCY,
    payload: { displayInLocalCurrency },
});

export const UPDATE_DIMENSIONS = 'UPDATE_DIMENSIONS';
export const REQUEST_UPDATE_DIMENSIONS = 'REQUEST_UPDATE_DIMENSIONS';
export type $UpdateDimensionsAction = {
    type: 'REQUEST_UPDATE_DIMENSIONS',
    payload: {
        dimensions: Array<$Dimension>,
    },
};
export const updateDimensions = (dimensions: Array<$Dimension>): $UpdateDimensionsAction => {
    return {
        type: REQUEST_UPDATE_DIMENSIONS,
        payload: { dimensions },
    };
};

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export type $UpdateFiltersAction = { type: 'UPDATE_FILTERS', payload: { filters: Array<$Filter> } };
export const updateFilters = (filters: Array<$Filter>): $UpdateFiltersAction => ({
    type: UPDATE_FILTERS,
    payload: { filters },
});

export const SET_DISPLAY_NET_METRICS = 'SET_DISPLAY_NET_METRICS';
export type $SetDisplayNetMetrics = {
    type: 'SET_DISPLAY_NET_METRICS',
    payload: {
        displayNetMetrics: boolean,
    },
};
export const setDisplayNetMetrics = (displayNetMetrics: boolean): $SetDisplayNetMetrics => ({
    type: SET_DISPLAY_NET_METRICS,
    payload: { displayNetMetrics },
});
