// @flow
import type { $Board } from './consts';
import { REQUEST_BOARD_EDITION } from './consts';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import * as Actions from './actions';
import type { $ActionType } from '../../Preparator/consts';
import { typeFailed, typeFulfilled } from '../../../util/ProcessOut';
import * as BoardsSagas from './Board/sagas';
import * as Sentry from '@sentry/browser';
import type { $FetchParams } from './consts';
import { REQUEST_CHART_DATA_FETCH } from './charts/consts';
import { replace } from 'react-router-redux';

type Action = {
    payload: {
        newBoard: $Board,
        remount: ?boolean,
    },
} & $ActionType;

function* editBoard(action: Action): Generator<*, *, *> {
    const { newBoard, remount } = action.payload;
    try {
        const editResult = yield put.resolve(Actions.editBoard(newBoard.id, newBoard));
        const params: $FetchParams = yield select(store => store.analytics.params);
        yield call(BoardsSagas.prepareBoard, {
            payload: { board: newBoard.id, fetchParams: params },
        });

        const currentProject = yield select(store => store.currentProject);

        if (remount) {
            yield put(
                replace(`/projects/${currentProject.project.id}/boards?redirect=${newBoard.id}`),
            );
        }

        yield put({ type: typeFulfilled(REQUEST_BOARD_EDITION) });
    } catch (e) {
        yield put({ type: typeFailed(REQUEST_BOARD_EDITION), payload: { e } });
        Sentry.captureException(e);
    }
}

export default function* watchBoardsEditSaga(): Generator<*, *, *> {
    yield takeLatest(REQUEST_BOARD_EDITION, editBoard);
}
