// @flow

import React from 'react';
import Error from '../../../components/Error';
import PieChart from './PieChart/PieChart';
import { connect } from 'react-redux';
import StackChart from './StackChart';
import type { $ChartType } from '../Boards/consts';

type $SingleDimensionData = Array<{ key: string, datapoints: null, value: number, _count: number }>;
type $MultiDimensionsData = Array<{
    key: string,
    value: null,
    datapoints: Array<$SingleDimensionData>,
}>;

type Props = {
    data: $SingleDimensionData | $MultiDimensionsData,
    currency: ?string,
    error?: ?boolean,
    keysMask?: ?Array<string>, // keys to be masked
    popup: {
        chartId: ?string,
    },
    type: $ChartType,
};

class PieStackChart extends React.Component<Props> {
    render() {
        const { data, type, currency, error, popup, keysMask } = this.props;
        if (error) return <Error />;

        if (data.length > 0 && data[0].value !== null && data[0].value !== undefined)
            return <PieChart data={data} currency={currency} type={type} />;
        return <StackChart data={data} currency={currency} type={type} keysMask={keysMask} />;
    }
}

export default connect(store => {
    return { popup: store.analytics_v2.popup };
})(PieStackChart);
