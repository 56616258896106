// @flow
import React from 'react';
import { connect } from 'react-redux';
import GeoChart from '../../../components/Analytics/GeoChart';
import type { $TelescopeState } from '../../../stores/Reducers/TelescopeReducer/Index';
import { computeAuthorizationColor } from '../Recommendation/utils';
import * as ISOCountries from '../../../util/ISOCountries';
import uniqid from 'uniqid';
import BarChart from '../../analytics/Charts/BarChart';
import LineChart from '../../analytics/Charts/LineChart';
import ChartSwitch from '../Components/ChartSwitch';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import Error from '../../../components/Error';
import ProviderCompareSelector from '../Components/ProviderCompareSelector';
import type { $ProviderCompareOption } from '../Components/ProviderCompareSelector';
import getColor from '../../analytics/Boards/charts/colors';
import Empty from '../../../components/Empty';

type Props = {
    telescope: $TelescopeState,
};

type State = {
    pspCompare: $ProviderCompareOption,
};

class AuthorizationsContent extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            pspCompare: { value: 'general', label: 'general' },
        };
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        return (
            nextProps.telescope.standard.authorizationReady !==
                this.props.telescope.standard.authorizationReady || this.state !== nextState
        );
    }
    render() {
        const { telescope } = this.props;
        return (
            <div class='row text-left'>
                <div class='large-12 columns'>
                    <div class='row'>
                        <div class='large-5 columns'>
                            <div class='row'>
                                <div class='large-12 columns'>
                                    <div class='chart-title'>Authorization rate per country</div>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='large-12 columns'>
                                    {telescope.standard.data.authPerCountry ? (
                                        <div style={{ height: '250px' }}>
                                            <GeoChart
                                                key={uniqid()}
                                                data={telescope.standard.data.authPerCountry}
                                                color={computeAuthorizationColor}
                                                tip={function(d) {
                                                    let c = ISOCountries.isoCountries[d.key];
                                                    const authDisplay =
                                                        d.country.authorization !== null
                                                            ? `${(
                                                                  d.country.authorization * 100
                                                              ).toLocaleString(undefined, {
                                                                  maximumFractionDigit: 2,
                                                              })}%`
                                                            : '--';

                                                    return (
                                                        c.full_name +
                                                        '<br />' +
                                                        `<span class="greyed">Authorization rate:</span> ${authDisplay}<br />`
                                                    );
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div class='row small-margin-bottom'>
                                            <div class='large-12 columns'>
                                                <Error />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div class='large-6 large-offset-1 columns'>
                            <div class='row'>
                                <div class='large-12 columns'>
                                    <div class='row'>
                                        <div class='large-7 columns'>
                                            <div class='chart-title'>Authorization rate</div>
                                        </div>
                                        <div class='large-5 columns'>
                                            <ProviderCompareSelector
                                                value={this.state.pspCompare}
                                                onChange={(newValue: $ProviderCompareOption) => {
                                                    this.setState({
                                                        pspCompare: newValue,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            <div style={{ height: '250px' }}>
                                                {(() => {
                                                    const data =
                                                        this.state.pspCompare.value === 'general'
                                                            ? telescope.standard.data
                                                                  .authorization_rate_per_psp_bar
                                                            : this.state.pspCompare.value ===
                                                              'card_scheme'
                                                            ? telescope.standard.data
                                                                  .authorization_rate_per_psp_per_card_scheme_bar
                                                            : telescope.standard.data
                                                                  .authorization_rate_per_psp_per_card_type_bar;
                                                    if (!data) {
                                                        return (
                                                            <div
                                                                style={{
                                                                    marginTop: '2em',
                                                                }}
                                                            >
                                                                <Error />
                                                            </div>
                                                        );
                                                    }
                                                    if (
                                                        data.length === 0 ||
                                                        data[0].datapoints.length === 0
                                                    )
                                                        return (
                                                            <Empty text={'No data to display'} />
                                                        );
                                                    return (
                                                        <div key={uniqid()}>
                                                            <BarChart
                                                                type='percentage'
                                                                data={data}
                                                                colors={data.map((entry, index) => {
                                                                    return {
                                                                        key: entry.key,
                                                                        color: getColor(index),
                                                                    };
                                                                })}
                                                                comparison={true}
                                                            />
                                                        </div>
                                                    );
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return {
        telescope: store.telescope,
        currentProject: store.currentProject,
    };
})(AuthorizationsContent);
