// @flow

import React from 'react';
import type { $RoutingRule, $RoutingRulesSettings } from './consts';
import { connect } from 'react-redux';
import type { $Dispatcher, $State } from '../../util/Types';
import Filter from './Filter/Filter';
import * as Actions from './actions';
import type { $Filter } from './Filter/consts';
import { FormattedNumber } from 'react-intl';
import GatewaysSelection from './GatewaysSelection';
import { RULES_FILTERS } from './consts';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import BlockingRule from './BlockingRule';
import RoutingRules from './RoutingRules';
import RoutingRule from './RoutingRule';
import TriggerRule from './TriggerRule';
import RuleContainer from './RuleContainer';

type Props = {
    rule: $RoutingRule,
    ruleWontMatch: boolean,
    onChange: $RoutingRule => void,
    processorsConfigurations: {
        configurations: Array<{
            id: string,
            gateway: { id: string, tags: Array<string> },
        }>,
    } & $State,
    gateway_configurations_names: {
        gateway_configuration_names: any,
    } & $State,
    rulesSettings: $RoutingRulesSettings,
} & $Dispatcher;

type State = {
    collapsed: boolean,
};

const DragHandle = SortableHandle(() => (
    <div class='drag-handle'>
        <img src='//dashboard.processout.com/images/drag.png' />
        <span class='greyed'>Drag to order</span>
    </div>
));

class Rule extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            collapsed: true,
        };
    }

    componentDidMount() {
        if (this.props.rule.new) this.setState({ collapsed: false });
    }

    filterChanged = (conditionIndex: number, newValue: Array<$Filter>) => {
        const newRule = { ...this.props.rule };
        newRule.conditions[conditionIndex].filters = newValue;
        this.props.dispatch(Actions.updateRule(newRule.id, newRule));
    };

    render() {
        const { rule, rulesSettings } = this.props;
        const { collapsed } = this.state;
        const index = rulesSettings.rules.findIndex(r => r.id === rule.id);
        return (
            <div className='row' key={`rule-${rule.id}`}>
                <div className='medium-12 columns'>
                    {collapsed ? (
                        <div
                            class={`row routing-rule collapsed ${rule.declaration}`}
                            onClick={() => {
                                this.setState({ collapsed: false });
                            }}
                        >
                            <div class='medium-9 columns'>
                                {rule.tags &&
                                    rule.tags.map(tag => (
                                        <div class='rule-tag' key={tag}>
                                            {tag}
                                        </div>
                                    ))}
                                {rule.gateways.map(gway => (
                                    <div class='rule-tag gateway' key={gway.gateway}>
                                        {gway.gateway === 'processout'
                                            ? 'Smart-Routing'
                                            : this.props.gateway_configurations_names
                                                  .gateway_configuration_names[gway.gateway]}
                                    </div>
                                ))}
                                {(!rule.tags || rule.tags.length === 0) &&
                                    rule.gateways.length === 0 && (
                                        <div class='greyed'>
                                            This rule doesn't contain any tag yet. Click here to add
                                            one.
                                        </div>
                                    )}
                            </div>
                            <div class='medium-3 columns text-right '>
                                <DragHandle />
                            </div>
                        </div>
                    ) : rule.declaration === 'block' ? (
                        <RuleContainer
                            rule={rule}
                            collapse={() => {
                                this.setState({ collapsed: true });
                            }}
                        >
                            <BlockingRule rule={rule} />
                        </RuleContainer>
                    ) : rule.declaration === 'route' ? (
                        <RuleContainer
                            rule={rule}
                            collapse={() => {
                                this.setState({ collapsed: true });
                            }}
                        >
                            <RoutingRule rule={rule} />
                        </RuleContainer>
                    ) : rule.declaration === 'trigger_3ds' ? (
                        <RuleContainer
                            rule={rule}
                            collapse={() => {
                                this.setState({ collapsed: true });
                            }}
                        >
                            <TriggerRule rule={rule} />
                        </RuleContainer>
                    ) : null}
                </div>
            </div>
        );
    }
}

const ConnectedRule = connect(store => {
    return {
        gateway_configurations_names: store.gateway_configurations_names,
        processorsConfigurations: store.processorsConfigurations,
        rulesSettings: store.routingRulesSettings,
    };
})(Rule);
export default SortableElement(props => <ConnectedRule {...props} />);
