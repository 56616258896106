// @flow

import React from 'react';
import Configurations from './Configurations';
import ContentLayout from '../../pages/ContentLayout';
import Loader from '../../components/Loader';
import NotPermitted from '../Permissions/NotPermitted';
import Error from '../../components/Error';
import Empty from '../../components/Empty';
import { connect } from 'react-redux';
import * as GatewaysActions from '../../Actions/GatewaysActions';
import * as ProcessOut from '../../util/ProcessOut';
import * as RoutingActions from '../RoutingRules/actions';
import { PAYMENT_PROVIDERS_MENU, PROVIDERS_PER_LINE } from './consts';
import GatewaySearch from '../../util/GatewaySearch';
import GatewayModal from './GatewayModal';
import type { $Dispatcher } from '../../util/Types';
import type { $GatewayMenu, $GatewaySubMenu } from './consts';

type Props = {} & $Dispatcher;

type State = {
    gatewaysSearchList: ?Array<any>,
    firstlevelCategory: string,
    secondlevelCategory: ?string,
};

class PaymentProviders extends React.Component<Props, State> {
    static defaultProps = {};
    constructor() {
        super();

        this.state = {
            gatewaysSearchList: null,
            firstlevelCategory: PAYMENT_PROVIDERS_MENU[0].id,
            secondlevelCategory: null,
        };
    }

    componentDidMount() {
        document.title = 'Gateways | ProcessOut';
        const { dispatch } = this.props;
        dispatch(GatewaysActions.loadGateways());
        dispatch(GatewaysActions.loadGatewaysConfigurations(ProcessOut.ProjectId));
        dispatch(RoutingActions.prepareRoutingRulesSettings());
    }

    requestBeta = (processor, event) => {
        event.preventDefault();
        // $FlowFixMe
        Intercom('showNewMessage', 'Hello, I would like to access the beta of ' + processor + '.');
    };

    openModal = processor => {
        this.refs[processor.name].getWrappedInstance().openModal();
    };

    updateCategory = (firstlevelCategory, secondlevelCategory, e) => {
        e.preventDefault();

        this.setState({
            firstlevelCategory: firstlevelCategory,
            secondlevelCategory: secondlevelCategory,
        });

        return false;
    };

    filter = e => {
        let filter = e.target.value;
        if (filter === '') {
            this.setState({ gatewaysSearchList: null });
            return;
        }
        this.setState({
            gatewaysSearchList: GatewaySearch(this.props.gatewaysDetails.gateways, filter),
        });
    };

    tagsMatches = (tags, categories) => {
        for (let j = 0; j < categories.length; j++) {
            if (!categories[j]) continue;

            let found = false;
            for (let i = 0; i < tags.length; i++) if (tags[i] === categories[j]) found = true;
            if (!found) return false;
        }

        return true;
    };

    filterCategory = categories => {
        if (!categories || categories.length === 0) {
            return this.props.gatewaysDetails.gateways;
        }

        let filteredGateways = [];
        for (let i = 0; i < this.props.gatewaysDetails.gateways.length; i++) {
            let g = this.props.gatewaysDetails.gateways[i];

            if (this.tagsMatches(g.tags, categories)) filteredGateways.push(g);
        }

        return filteredGateways;
    };

    renderGroup = (itemsArray): Array<React$Element<'div'>> => {
        let rowsN = itemsArray.length / PROVIDERS_PER_LINE;
        let rows = [];
        for (let j = 0; j < rowsN; j++) {
            let count = -1;
            let row = (
                <div class='row' key={Math.floor(Math.random() * 100000)}>
                    {itemsArray
                        .slice(j * PROVIDERS_PER_LINE, j * PROVIDERS_PER_LINE + PROVIDERS_PER_LINE)
                        .map(function(processor) {
                            // Map all the gateways for a given tag and add their display to the tile list
                            count++;
                            let last = '';
                            if (count + j * PROVIDERS_PER_LINE === itemsArray.length - 1)
                                last = 'end';
                            return (
                                <div
                                    key={count}
                                    class={`medium-${12 /
                                        PROVIDERS_PER_LINE} ${last} columns margin-bottom`}
                                >
                                    <GatewayModal
                                        modalName='New configuration'
                                        gateway={processor}
                                        ref={processor.name}
                                    />
                                    <a
                                        class='payment-processor-wrapper details-box only-img'
                                        onClick={() => {
                                            if (processor.demo)
                                                this.requestBeta(processor.display_name);
                                            else this.openModal(processor);
                                        }}
                                    >
                                        <div className='row'>
                                            <div class='large-12 columns text-center img-wrapper'>
                                                <img
                                                    class='payment-processor'
                                                    src={processor.logo_url}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='large-12 columns text-center description'>
                                                {processor.display_name}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            );
                        }, this)}
                </div>
            );
            rows.push(row);
        }
        return rows;
    };

    render() {
        const processorsDetails = this.props.gatewaysDetails;
        const { routingRulesSettings, processorsConfigurations } = this.props;

        if (
            processorsDetails.fetching ||
            !processorsDetails.fetched ||
            routingRulesSettings.fetching ||
            !routingRulesSettings.fetched
        ) {
            return (
                <ContentLayout title={<h4>Payment providers</h4>}>
                    <Loader />
                </ContentLayout>
            );
        }

        if (processorsConfigurations.error) {
            if (processorsConfigurations.error.notPermitted)
                return (
                    <ContentLayout title={<h4>Payment providers</h4>}>
                        <NotPermitted />
                    </ContentLayout>
                );
            else
                return (
                    <ContentLayout title={<h4>Payment providers</h4>}>
                        <Error />
                    </ContentLayout>
                );
        }

        let tiles: Array<React$Element<'div'> | Array<React$Element<'div'>>> = [];

        if (this.state.gatewaysSearchList) {
            // The search bar is filled
            tiles.push(this.renderGroup(this.state.gatewaysSearchList));
        } else {
            // No filter were given

            // First: get the category we're in
            let curmenu: ?$GatewayMenu;
            for (let i = 0; i < PAYMENT_PROVIDERS_MENU.length; i++)
                if (PAYMENT_PROVIDERS_MENU[i].id === this.state.firstlevelCategory) {
                    curmenu = PAYMENT_PROVIDERS_MENU[i];
                    break;
                }

            let filtered = this.filterCategory([
                this.state.firstlevelCategory,
                this.state.secondlevelCategory,
            ]);

            if (curmenu) {
                let submenus = curmenu.submenus;
                if (this.state.secondlevelCategory != null) {
                    let tmp: $GatewaySubMenu = {
                        name: '',
                        description: '',
                        id: '',
                    };
                    for (let i = 0; i < submenus.length; i++)
                        if (submenus[i].id === this.state.secondlevelCategory) {
                            tmp = submenus[i];
                            break;
                        }

                    submenus = [tmp];
                }

                for (let i = 0; i < submenus.length; i++) {
                    let subfiltered = [];
                    for (let j = 0; j < filtered.length; j++) {
                        if (this.tagsMatches(filtered[j].tags, [curmenu.id, submenus[i].id])) {
                            let skip = false;
                            if (submenus[i].not)
                                for (let k = 0; k < submenus[i].not.length; k++) {
                                    if (
                                        this.tagsMatches(filtered[j].tags, [
                                            curmenu.id,
                                            submenus[i].not[k],
                                        ])
                                    ) {
                                        skip = true;
                                        break;
                                    }
                                }

                            if (skip) continue;
                            subfiltered.push(filtered[j]);
                        }
                    }

                    if (!subfiltered.length) continue;

                    tiles.push(
                        <div class='row' key={'gateway-sub' + i + '-' + i}>
                            <div class={`large-12 columns margin-bottom`}>
                                <div class='row text-left' style={{ marginBottom: '1em' }}>
                                    <div class='large-8 medium-9 columns'>
                                        <h4 style={{ marginBottom: 0 }}>{submenus[i].name}</h4>
                                        <h6 class='greyed'>{submenus[i].description}</h6>
                                    </div>
                                </div>

                                {this.renderGroup(subfiltered)}
                            </div>
                        </div>,
                    );
                }
            }
        }

        let displayMenu = [];
        for (let i = 0; i < PAYMENT_PROVIDERS_MENU.length; i++) {
            let displaySubMenu = [];
            for (let j = 0; j < PAYMENT_PROVIDERS_MENU[i].submenus.length; j++) {
                displaySubMenu.push(
                    <li key={'menu-li-' + i + '-' + j}>
                        <a
                            href='#'
                            onClick={this.updateCategory.bind(
                                this,
                                PAYMENT_PROVIDERS_MENU[i].id,
                                PAYMENT_PROVIDERS_MENU[i].submenus[j].id,
                            )}
                        >
                            {PAYMENT_PROVIDERS_MENU[i].submenus[j].name}
                        </a>
                    </li>,
                );
            }

            displayMenu.push(
                <div class='row' key={'menu-div-' + i}>
                    <div className='large-12 columns'>
                        <h5>
                            <a
                                href='#'
                                onClick={this.updateCategory.bind(
                                    this,
                                    PAYMENT_PROVIDERS_MENU[i].id,
                                    null,
                                )}
                            >
                                {PAYMENT_PROVIDERS_MENU[i].name}
                            </a>
                        </h5>
                        <ul>{displaySubMenu}</ul>
                    </div>
                </div>,
            );
        }

        let empty;
        if (this.state.gatewaysSearchList && this.state.gatewaysSearchList.length === 0) {
            empty = <Empty text='No provider matches your search' />;
        } else if (!tiles.length) {
            empty = <Empty text="There's no provider available for that category yet" />;
        }

        return (
            <div>
                <Configurations />
                <ContentLayout title={<h4>Configure a new payment provider</h4>}>
                    <div class='row'>
                        <div class='medium-4 columns'>
                            <div class='payment-processor-menu'>
                                <div class='row'>
                                    <div class='large-12 columns'>
                                        <input
                                            type='text'
                                            placeholder='Search'
                                            onChange={this.filter}
                                        />
                                    </div>
                                </div>

                                {displayMenu}
                            </div>
                        </div>
                        <div class='medium-8 columns'>
                            {tiles}
                            {empty}
                        </div>
                    </div>
                </ContentLayout>
            </div>
        );
    }
}

export default connect(store => ({
    gatewaysDetails: store.gateways,
    user: store.user,
    routingRulesSettings: store.routingRulesSettings,
    processorsConfigurations: store.processorsConfigurations,
}))(PaymentProviders);
