// @flow

import type { $Action } from '../../util/Types';
import { INITIATE_REPORT_UPLOAD, PREPARE_REPORTS_PAGE, REQUEST_REPORTS_FETCH } from './sagas';

export const requestReportsFetch = (
    filter: string,
    length: number,
    after: ?boolean,
    id: ?string,
): $Action => {
    return {
        type: REQUEST_REPORTS_FETCH,
        payload: {
            filter,
            length,
            after,
            id,
        },
    };
};

export function initUpload(files: Array<any>, gatewayConfigurationId: string): $Action {
    return {
        type: INITIATE_REPORT_UPLOAD,
        payload: {
            files,
            gatewayConfigurationId,
        },
    };
}

export function prepareReportsPage(): $Action {
    return {
        type: PREPARE_REPORTS_PAGE,
    };
}

export const CHANGE_REPORTS_SELECTED_GATEWAY = 'CHANGE_REPORTS_SELECTED_GATEWAY';
export function selectGatewayConfiguration(gatewayConfigurationId: string): $Action {
    return {
        type: CHANGE_REPORTS_SELECTED_GATEWAY,
        payload: {
            gatewayConfigurationId,
        },
    };
}

export const CHANGE_REPORTS_SELECTED_FILES = 'CHANGE_REPORTS_SELECTED_FILES';
export function selectFiles(files: Array<any>): $Action {
    return {
        type: CHANGE_REPORTS_SELECTED_FILES,
        payload: {
            files,
        },
    };
}
