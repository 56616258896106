import React from 'react';
import { connect } from 'react-redux';
import Metadata from './Metadata';
import TokenSelect from './CustomerDetails/TokenSelect';
import * as InvoicesActions from '../Actions/InvoicesActions';
import PlanSelect from './InvoiceEdit/PlanSelect';
import CouponSelect from './InvoiceEdit/CouponSelect';
import PropTypes from 'prop-types';

var serialize = require('form-serialize');

@connect(
    function(store) {
        return {
            currenciesDetails: store.currencies,
            invoiceDetails: store.invoiceDetails,
        };
    },
    null,
    null,
    { withRef: true },
)
export default class SubscriptionModal extends React.Component {
    static propTypes = {
        modalName: PropTypes.string.isRequired,
        customer: PropTypes.string,
    };

    constructor() {
        super();
    }

    componentDidMount() {
        if (this.create) {
            this.props.dispatch({ type: 'START_SUBSCRIPTION_CREATION' });
        }

        this.setState({ customer: this.props.customer });
    }

    created() {
        this.props.dispatch({ type: 'CLOSE_MODAL' });
    }

    save(event) {
        event.preventDefault();
        var invoiceForm = document.querySelector('#invoice-form');
        var data = serialize(invoiceForm, { hash: true });
        data.interval = data.interval_days + 'd';

        if (!data.metadata) data.metadata = {};

        var metas = this._meta.updateValue();
        for (var i = 0; i < metas.length; i++) {
            data.metadata[metas[i].key] = metas[i].value;
        }

        if (data.metadata && Object.keys(data.metadata).length == 0) {
            delete data.metadata;
        }
        data.customer_id = this.props.customer;

        if (!data.amount) {
            data.amount = 0;
        }

        data.amount = parseFloat(data.amount);
        data.amount = data.amount + '';
        var invoice = JSON.stringify(data);
        this.props.dispatch(
            InvoicesActions.createRecurringInvoice(
                invoice,
                data.coupon_id,
                this.created.bind(this),
            ),
        );
    }

    openModal() {
        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: this.props.modalName,
                content: (
                    <div class='row'>
                        <div class='large-12 columns'>
                            <form id='invoice-form' onSubmit={this.save.bind(this)}>
                                <div class='row small-margin-bottom'>
                                    <div
                                        class='medium-12 medium-centered columns'
                                        style={{
                                            padding: '25px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Details
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row small-margin-bottom'>
                                            <div class='large-12 columns'>
                                                <label class='greyed'>Plan</label>
                                                <PlanSelect />
                                            </div>
                                        </div>
                                        <div class='row small-margin-bottom'>
                                            <div class='large-12 columns'>
                                                <label class='greyed'>Coupon</label>
                                                <CouponSelect />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <hr />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Payment method
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <TokenSelect customerId={this.props.customer} />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <hr />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Additional data
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <Metadata ref={m => (this._meta = m)} />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ),
                footer: (
                    <div class='row'>
                        <div class='large-12 columns text-right'>
                            <a
                                onClick={function(event) {
                                    event.preventDefault();
                                    this.props.dispatch({
                                        type: 'CLOSE_MODAL',
                                    });
                                }.bind(this)}
                                class='round border small button'
                            >
                                Cancel
                            </a>
                            <input
                                type='button'
                                onClick={this.save.bind(this)}
                                class='round small main button white-text'
                                value={this.props.invoice ? 'Save' : 'Create'}
                            />
                        </div>
                    </div>
                ),
            },
        });
    }

    render() {
        return null;
    }
}
