// @flow
/**
 * Created by jeremylejoux on 07/06/17.
 */

import React from 'react';
import { connect } from 'react-redux';
import ContentLayout from '../../pages/ContentLayout';
import Dunning from '../Dunning';
import Loader from '../Loader';

export class DunningSettings extends React.Component {
    props: Props;

    static defaultProps = {
        dispatch: null,
    };

    componentDidMount() {
        document.title = 'Settings - Dunning | ProcessOut';
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    render() {
        let { project } = this.props;

        let content = <Loader />;
        if (!project.fetching && project.fetched) content = <Dunning />;

        return <ContentLayout title={<h4>Dunning settings</h4>}>{content}</ContentLayout>;
    }
}

type Props = {
    project: {
        fetching: boolean,
        fetched: boolean,
    },
    dispatch: any | (() => void),
};

export default connect(function(store) {
    return {
        project: store.currentProject,
    };
})(DunningSettings);
