// @flow

import type { Action } from '../../Types/Action';

export const REQUEST_TELESCOPE_PREPARATION = 'REQUEST_TELESCOPE_PREPARATION';
export const PREPARE_TELESCOPE = 'PREPARE_TELESCOPE';
export const PREPARE_TELESCOPE_SILENT = 'PREPARE_TELESCOPE_SILENT';
export const FETCH_TELESCOPE_DATA = 'FETCH_TELESCOPE_DATA';
export const UPDATE_TELESCOPE_TIMESPAN = 'UPDATE_TELESCOPE_TIMESPAN';
export const UPDATE_TELESCOPE_TAB = 'UPDATE_TELESCOPE_TAB';

export type $TelescopeRequestAction = {
    payload: {
        timespan: 'string',
        silent: boolean,
        tab: 'auth' | 'capt' | 'refu' | 'char',
    },
} & Action;

export const TIMESPAN_OPTIONS = [
    { value: 'weekly', label: 'week' },
    { value: 'monthly', label: 'month' },
    { value: 'quarterly', label: 'quarter' },
    { value: 'annually', label: 'year' },
];

/**
        CHART FORMULAS
 */

// Authorizations
export const GLOBAL_AUTH_FORMULA =
    'count{\n' +
    '    path: transactions;\n' +
    '    default: 0;\n' +
    'duplicate_distance_seconds == null and status != "failed" and status != "pending" and status != "waiting"' +
    '}\n' +
    '/ count{\n' +
    '    path: transactions;\n' +
    '    default: 0;\n' +
    'status != "pending" and status != "waiting" and ' +
    'duplicate_distance_seconds == null' +
    '}';
export const AUTH_PER_COUNTRY_FORMULA =
    'plot{\n' +
    '    path:transactions.country;\n' +
    '    top: 150;\n' +
    '    formula: count{\n' +
    '        path: transactions;\n' +
    '        default: 0;\n' +
    'duplicate_distance_seconds == null and status != "failed" and status != "pending" and status != "waiting"' +
    '    }\n' +
    '    / count{\n' +
    '        path: transactions;\n' +
    '        default: 0;\n' +
    'status != "pending" and status != "waiting" and ' +
    'duplicate_distance_seconds == null' +
    '    }\n' +
    '}';
export const AUTHORIZED_TRANSACTIONS_FORMULA =
    'count{\n' +
    '    path: transactions;\n' +
    '    default: 0;\n' +
    'status != "failed" and status != "pending" and status != "waiting"' +
    '}';
export const AUTHORIZED_AMOUNT_FORMULA =
    'sum{\n' + '    path: transactions.authorized_amount_local;\n' + '    default: 0;\n' + '}';
export const FAILED_TRANSACTIONS_FORMULA =
    'count{\n' +
    '    path: transactions;\n' +
    '    default: 0;\n' +
    '    status == "failed" and duplicate_distance_seconds == null ;\n' +
    '}';

export const AUTH_PER_PSP_BAR =
    'plot{\n' +
    '    path:transactions.gateway_configuration_id;\n' +
    '    top: 15;\n' +
    '    formula: count{path: transactions; default: 0;};' +
    'count{\n' +
    '        path: transactions;\n' +
    '        default: 0;\n' +
    'duplicate_distance_seconds == null and status != "failed" and status != "pending" and status != "waiting"' +
    '    }\n' +
    '    / count{\n' +
    '        path: transactions;\n' +
    '        default: 0;\n' +
    'status != "pending" and status != "waiting" and duplicate_distance_seconds == null' +
    '    }\n' +
    '}';

export const AUTH_PER_PSP_PER_CARD_SCHEME_BAR =
    'plot{\n' +
    '    formula: count{\n' +
    '        path: transactions; default: 0;status != "pending"' +
    '               AND status != "waiting"' +
    '            AND duplicate_distance_seconds == null;' +
    '    }\n' +
    '    ;\n' +
    '    path: transactions.card_scheme;\n' +
    '    strategy: value_descending;\n' +
    '    top: 3;\n' +
    '    plot{\n' +
    '        path:transactions.gateway_name;\n' +
    '        strategy: value_descending;\n' +
    '        top: 3;\n' +
    '        formula: count{\n' +
    '            path: transactions;\n' +
    '            default: 0;\n' +
    '            status != "failed"\n' +
    '        }\n' +
    '        / count{\n' +
    '            path: transactions;\n' +
    '            default: 0;\n' +
    '        }\n' +
    '    }\n' +
    '}';

export const AUTH_PER_PSP_PER_CARD_TYPE_BAR =
    'plot{\n' +
    '    formula: count{\n' +
    '        path: transactions; default: 0;' +
    'status != "pending" and status != "waiting" and duplicate_distance_seconds == null\n' +
    '    }\n' +
    '    ;\n' +
    '    path: transactions.card_type;\n' +
    '    strategy: value_descending;\n' +
    '    top: 3;\n' +
    '    plot{\n' +
    '        path:transactions.gateway_name;\n' +
    '        strategy: value_descending;\n' +
    '        top: 3;\n' +
    '        formula: count{\n' +
    '            path: transactions;\n' +
    '            default: 0;\n' +
    '            status != "failed"\n' +
    '        }\n' +
    '        / count{\n' +
    '            path: transactions;\n' +
    '            default: 0;\n' +
    '        }\n' +
    '    }\n' +
    '}';

// Captures
export const GLOBAL_FEES_FORMULA =
    'sum{\n' +
    '    path: transactions.gateway_fee_local;\n' +
    '    default: 0;\n' +
    'gateway_fee > 0\n;' +
    '}\n' +
    '/ sum{\n' +
    '    path: transactions.amount_local;\n' +
    '    default: 0;\n' +
    'gateway_fee > 0\n;' +
    '}';

export const CAPTURE_AMOUNT_FORMULA =
    'sum{\n' + '    path: transactions.captured_amount_local;\n' + '    default: 0;\n' + '}';
export const CAPTURED_TRANSACTIONS_FORMULA =
    'count{\n' +
    '    path: transactions;\n' +
    '    default: 0;\n' +
    '    captured_amount > 0 ;\n' +
    '}';
export const GATEWAY_FEES_FORMULA =
    'sum{\n' + '    path: transactions.gateway_fee_local;\n' + 'default: 0;\n' + '}';
export const FEES_PER_COUNTRY_FORMULA =
    'plot{\n' +
    '    path:transactions.country;\n' +
    '    strategy: value_descending;\n' +
    '    top: 150;\n' +
    '    formula: sum{\n' +
    '        path: transactions.gateway_fee_local;\n' +
    '        default: 0;\n' +
    'gateway_fee > 0\n;' +
    '    }\n' +
    '    / sum{\n' +
    '        path: transactions.amount_local;\n' +
    '        default: 0;\n' +
    'gateway_fee > 0\n;' +
    '    }\n' +
    '}';

export const GATEWAY_FEES_PER_PSP_BAR_FORMULA =
    'plot{\n' +
    '    path:transactions.gateway_configuration_id;\n' +
    '    strategy: value_descending;\n' +
    '    top: 15;\n' +
    '    formula: sum{\n' +
    '        path: transactions.gateway_fee_local;\n' +
    '        default: 0;\n' +
    'gateway_fee > 0\n;' +
    '    }\n' +
    '    / sum{\n' +
    '        path: transactions.amount_local;\n' +
    '        default: 0;\n' +
    'gateway_fee > 0\n;' +
    '    }\n' +
    '}';

export const GATEWAY_FEES_PER_PSP_PER_CARD_SCHEME_BAR_FORMULA =
    'plot{\n' +
    '    formula: count{\n' +
    '        path: transactions; default: 0;\n' +
    '    }\n' +
    '    ;\n' +
    '    path: transactions.gateway_configuration_id;\n' +
    '    strategy: value_descending;\n' +
    '    top: 15;\n' +
    '    plot{\n' +
    '        path:transactions.card_scheme;\n' +
    '        strategy: value_descending;\n' +
    '        top: 15;\n' +
    '        formula: sum{\n' +
    '            path: transactions.gateway_fee_local;\n' +
    '            default: 0;\n' +
    '(card_scheme == "visa" or card_scheme == "mastercard" or card_scheme == "american express") and gateway_fee > 0;\n' +
    '        }\n' +
    '        / sum{\n' +
    '            path: transactions.amount_local;\n' +
    '            default: 0;\n' +
    '(card_scheme == "visa" or card_scheme == "mastercard" or card_scheme == "american express") and gateway_fee > 0;\n' +
    '        }\n' +
    '    }\n' +
    '}';

export const FEES_PER_PSP_PER_CARD_TYPE_BAR_FORMULA =
    'plot{\n' +
    '    formula: count{\n' +
    '        path: transactions; default: 0;\n' +
    '    }\n' +
    '    ;\n' +
    '    path: transactions.gateway_configuration_id;\n' +
    '    strategy: value_descending;\n' +
    '    top: 15;\n' +
    '    plot{\n' +
    '        path:transactions.card_type;\n' +
    '        strategy: value_descending;\n' +
    '        top: 15;\n' +
    '        formula: sum{\n' +
    '            path: transactions.gateway_fee_local;\n' +
    '            default: 0;\n' +
    '            status != "failed"\n' +
    '            AND status != "pending"\n' +
    '            AND status != "waiting"\n' +
    '            AND gateway_fee > 0;\n' +
    '        }\n' +
    '        / sum{\n' +
    '            path: transactions.amount_local;\n' +
    '            default: 0;\n' +
    '            status != "failed"\n' +
    '            AND status != "pending"\n' +
    '            AND status != "waiting"\n' +
    '            AND gateway_fee > 0;\n' +
    '        }\n' +
    '    }\n' +
    '}';

// Refunds
export const GLOBAL_REFUND_FORMULA =
    '-sum{\n' +
    '    path: transactions.refunded_amount_local;\n' +
    '    default: 0;\n' +
    '}\n' +
    '/ sum{\n' +
    '    path: transactions.captured_amount_local;\n' +
    '    default: 0;\n' +
    '}';
export const REFUNDED_AMOUNT_FORMULA =
    'sum{\n' + '    path: transactions.refunded_amount_local;\n' + '    default: 0;\n' + '}';
export const REFUNDED_TRANSACTIONS_FORMULA =
    'count{\n' +
    '    path: transactions;\n' +
    '    default: 0;\n' +
    '    status == "refunded"  or status == "partially-refunded";\n' +
    '}';
export const REFUNDS_PER_COUNTRY_FORMULA =
    'plot{\n' +
    '    path:transactions.country;\n' +
    '    strategy: value_descending;\n' +
    '    top: 150;\n' +
    '    formula: -sum{\n' +
    '        path: transactions.refunded_amount_local;\n' +
    '        default: 0;\n' +
    '    }\n' +
    '    / sum{\n' +
    '        path: transactions.captured_amount_local;\n' +
    '        default: 0;\n' +
    '    }\n' +
    '}';
export const VOIDED_AMOUNT_FORMULA =
    'sum{\n' +
    '        path: transactions.captured_amount_local;\n' +
    '    default: 0;\n' +
    '        status == "voided" ;\n' +
    '    }';
export const VOIDED_TRANSACTIONS_FORMULA =
    'count{\n' +
    '    path: transactions;\n' +
    '    default: 0;\n' +
    '    status == "voided" ;\n' +
    '}';
export const REFUND_AMOUNT_PER_PSP_BAR =
    'plot{\n' +
    '    path:transactions.gateway_configuration_id;\n' +
    '    strategy: value_descending;\n' +
    '    top: 15;\n' +
    '    formula: -sum{\n' +
    '        path: transactions.refunded_amount_local;\n' +
    '        default: 0;\n' +
    '    }\n' +
    '/ sum{\n' +
    '        path: transactions.captured_amount_local;\n' +
    '        default: 0;\n' +
    '    }' +
    '}';
