import React from 'react';
import PropTypes from 'prop-types';

export default class Loading extends React.Component {
    static defaultProps = {
        size: 32,
        spinnerClassName: 'LoadingSpinner',
    };

    static propTypes = {
        size: PropTypes.number,
    };

    constructor() {
        super();
    }

    render() {
        const { size, borderWidth, className, spinnerClassName } = this.props;
        return (
            <div
                class='loader opacity0-8'
                style={{
                    textAlign: 'center',
                    marginTop: size,
                }}
            >
                <div
                    class={spinnerClassName}
                    style={{
                        fontSize: size,
                    }}
                />
            </div>
        );
    }
}
