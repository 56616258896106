// @flow

import { connect } from 'react-redux';
import React from 'react';

import type { $ChartBuilderState } from './reducer';
import {
    type $ChartType,
    BAR_CHART,
    LINE_CHART,
    MAP_CHART,
    PIE_CHART,
    SINGLE_VALUE,
    TEXT_CHART,
} from '../../Boards/consts';
import type { $DataExplorerState } from '../reducer';
import type { $Dispatcher } from '../../../../util/Types';
import * as Actions from './actions';

type Props = {
    chartBuilder: $ChartBuilderState,
    dataExplorer: $DataExplorerState,
} & $Dispatcher;

class TypeSelection extends React.Component<Props> {
    selectType = (type: $ChartType) => {
        const { dispatch, chartBuilder, dataExplorer } = this.props;
        dispatch(Actions.selectType(type, dataExplorer.type));
    };

    render() {
        const { chartBuilder, dataExplorer } = this.props;
        const canUseLineChart =
            chartBuilder.dimensions.length === 0 ||
            new RegExp(/.*_at$/).test(chartBuilder.dimensions[0].field);
        return (
            <div class='type-selection'>
                <div class='row'>
                    <div class='medium-12 columns'>
                        <div
                            onClick={() => {
                                this.selectType(SINGLE_VALUE);
                            }}
                            class={`image-contain${
                                dataExplorer.type === SINGLE_VALUE ? ' selected' : ''
                            }`}
                        >
                            10
                        </div>

                        <div
                            onClick={() => {
                                if (canUseLineChart) this.selectType(LINE_CHART);
                            }}
                            class={`image-contain${
                                dataExplorer.type === LINE_CHART ? ' selected' : ''
                            }${canUseLineChart ? '' : ' disabled'}`}
                        >
                            <img src={'//dashboard.processout.com/images/line-chart2.png'} />
                        </div>

                        <div
                            onClick={() => {
                                this.selectType(BAR_CHART);
                            }}
                            class={`image-contain${
                                dataExplorer.type === BAR_CHART ? ' selected' : ''
                            }`}
                        >
                            <img src={'//dashboard.processout.com/images/bar-chart.png'} />
                        </div>

                        <div
                            onClick={() => {
                                if (
                                    chartBuilder.dimensions.length > 0 &&
                                    chartBuilder.dimensions[0].field.includes('country')
                                )
                                    this.selectType(MAP_CHART);
                            }}
                            class={`image-contain${
                                dataExplorer.type === MAP_CHART ? ' selected' : ''
                            }${
                                chartBuilder.dimensions.length > 0 &&
                                chartBuilder.dimensions[0].field.includes('country')
                                    ? ''
                                    : ' disabled'
                            }`}
                        >
                            <img src={'//dashboard.processout.com/images/map-chart.png'} />
                        </div>

                        <div
                            onClick={() => {
                                if (
                                    chartBuilder.dimensions.length === 0 ||
                                    !new RegExp(/.*_at$/).test(chartBuilder.dimensions[0].field)
                                ) {
                                    this.selectType(PIE_CHART);
                                }
                            }}
                            class={`image-contain${
                                dataExplorer.type === PIE_CHART ? ' selected' : ''
                            } ${
                                chartBuilder.dimensions.length > 0 &&
                                new RegExp(/.*_at$/).test(chartBuilder.dimensions[0].field)
                                    ? 'disabled'
                                    : ''
                            }`}
                        >
                            <img
                                src={`//dashboard.processout.com/images/${
                                    chartBuilder.dimensions.length > 1
                                        ? 'stacked-chart'
                                        : 'pie-chart'
                                }.png`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => ({
    dataExplorer: store.dataExplorer,
    chartBuilder: store.chartBuilder,
}))(TypeSelection);
