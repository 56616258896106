// @flow
import React from 'react';
import Loading from '../../../components/Loader';

const WAIT_BEFORE_SHOW = 2500;

type State = {
    shouldDisplay: boolean,
};

class LoadingIndicator extends React.Component<{}, State> {
    _timeout: any;

    constructor() {
        super();
        this.state = {
            shouldDisplay: false,
        };
    }

    componentDidMount() {
        this._timeout = setTimeout(() => {
            this.setState({ shouldDisplay: true });
        }, WAIT_BEFORE_SHOW);
    }

    componentWillUnmount(): void {
        if (this._timeout) clearTimeout(this._timeout);
    }

    render() {
        if (!this.state.shouldDisplay) return null;
        return (
            <div class='loading-indicator'>
                Still fetching, please wait...
                <Loading />
            </div>
        );
    }
}

export default LoadingIndicator;
