// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { CREATE_WEBHOOK, DELETE_WEBHOOK, FETCH_WEBHOOKS } from './actions';
import * as ProcessOut from '../../util/ProcessOut';
import type { $CreateWebhookAction, $DeleteWebhookAction } from './actions';
import * as Actions from './actions';

function* fetchWebhooks(): Generator<*, *, *> {
    try {
        const webhooksResults = yield call(ProcessOut.APIcallPromise, '/webhook-endpoints', 'GET');
        yield put({
            type: ProcessOut.typeFulfilled(FETCH_WEBHOOKS),
            payload: { ...webhooksResults.data },
        });
    } catch (error) {
        yield put({ type: ProcessOut.typeFailed(FETCH_WEBHOOKS), payload: error });
        Sentry.captureException(error);
    }
}

function* createWebhook(action: $CreateWebhookAction): Generator<*, *, *> {
    try {
        yield call(
            ProcessOut.APIcallPromise,
            '/webhook-endpoints',
            'POST',
            JSON.stringify({ url: action.payload.url }),
        );
        yield put(Actions.fetchWebhooks());
    } catch (error) {
        Sentry.captureException(error);
    }
}

function* deleteWebhook(action: $DeleteWebhookAction): Generator<*, *, *> {
    try {
        yield call(
            ProcessOut.APIcallPromise,
            `/webhook-endpoints/${action.payload.hook.id}`,
            'DELETE',
        );
        yield put(Actions.fetchWebhooks());
    } catch (error) {
        Sentry.captureException(error);
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeLatest(FETCH_WEBHOOKS, fetchWebhooks);
    yield takeLatest(CREATE_WEBHOOK, createWebhook);
    yield takeLatest(DELETE_WEBHOOK, deleteWebhook);
}
