// @flow
import React from 'react';
import type { $Permission, $PermissionGroup } from './consts';
import type { $Dispatcher } from '../../util/Types';
import { connect } from 'react-redux';
import * as Actions from './actions';

type Props = {
    fromPermissionGroup: $PermissionGroup,
    permission: $Permission,
    category: 'allow' | 'deny',
} & $Dispatcher;

class Permission extends React.Component {
    props: Props;

    readValueChanged = event => {
        const { dispatch, permission, fromPermissionGroup, category } = this.props;
        const canWrite =
            permission.verb === 'POST' || permission.verb === 'DELETE' || permission.verb === 'PUT';

        dispatch(
            Actions.setPermissionGroupValues(
                fromPermissionGroup.name,
                permission.url,
                event.target.checked ? (canWrite ? '*' : 'GET') : canWrite ? 'POST' : '',
                category,
            ),
        );
    };

    writeValueChanged = event => {
        const { dispatch, permission, fromPermissionGroup, category } = this.props;
        const canRead =
            permission.verb === 'POST' ||
            permission.verb === 'DELETE' ||
            permission.verb === 'PUT' ||
            permission.verb === 'GET' ||
            permission.verb === '*';

        dispatch(
            Actions.setPermissionGroupValues(
                fromPermissionGroup.name,
                permission.url,
                event.target.checked ? (canRead ? '*' : 'POST') : canRead ? 'GET' : '',
                category,
            ),
        );
    };

    render() {
        const { permission, fromPermissionGroup } = this.props;
        return (
            <div class="row">
                <div class="medium-4 columns">
                    <span>{permission.url.replace('/', '').replace('*', '')}</span>
                </div>
                <div class="medium-8 columns">
                    <div class="row">
                        <div className="medium-6 columns text-center">
                            <input
                                type="checkbox"
                                checked={permission.verb === 'GET' || permission.verb === '*'}
                                onChange={this.readValueChanged}
                            />
                        </div>
                        <div className="medium-6 columns text-center">
                            <input
                                type="checkbox"
                                checked={
                                    permission.verb === 'POST' ||
                                    permission.verb === 'PUT' ||
                                    permission.verb === 'DELETE' ||
                                    permission.verb === '*'
                                }
                                onChange={this.writeValueChanged}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(Permission);
