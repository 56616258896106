// @flow
import React from 'react';
import Select from 'react-select';
import * as CountryActions from '../../Actions/CountryActions';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../util/Types';

type Props = {
    defaultValue?: string,
} & $Dispatcher;

@connect(function(store) {
    return {
        countries: store.countries,
    };
})
export default class CountrySelector extends React.Component {
    props: Props;
    state: {
        value: ?string,
    };

    constructor() {
        super();
        this.state = {
            value: null,
        };
    }

    componentDidMount() {
        const { dispatch, defaultValue } = this.props;
        dispatch(CountryActions.fetchCountries());
        if (defaultValue) this.setState({ value: defaultValue });
    }

    countrySelected(value) {
        this.setState({ value: value });
    }

    getSelected() {
        return this.state.value;
    }

    render() {
        const countries = this.props.countries.countries;

        var fetching = !this.props.countries.fetched && this.props.countries.fetching;
        var options = [];
        if (!fetching && this.props.countries.fetched) {
            options = countries.map(function(country) {
                return { value: country.code, label: country.name };
            });
        }

        return (
            <Select
                id="country-selector"
                name="country_code"
                placeholder="Country"
                options={options}
                isLoading={fetching}
                value={this.state.value}
                onChange={this.countrySelected.bind(this)}
            />
        );
    }
}
