import React from 'react';
import { Link } from 'react-router';
import SideMenu from './Sidemenu';
import * as ProcessOut from '../../util/ProcessOut';
import * as ProjectsActions from '../../Actions/ProjectsActions';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import PropTypes from 'prop-types';
import Error from '../Error';
import Loading from '../Loader';
import { withRouter } from 'react-router';
import ProjectRegionFlag from './ProjectRegionFlag';

class Nav extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
        store: PropTypes.object.isRequired,
    };

    constructor() {
        super();
        this.state = {
            collapsed: true,
            name: '',
            logo: '',
            dropdownvisible: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.resetSelect(ProcessOut.ProjectId);
        if (!nextProps.projectsDetails.fetching && !nextProps.projectsDetails.fetched) {
            this.props.dispatch(ProjectsActions.loadProjects());
        }
    }

    componentDidMount() {
        this.props.dispatch(ProjectsActions.loadProjects());
    }

    resetSelect(id) {
        var sel = document.getElementById('select-projects');
        if (!sel) return;

        var opts = sel.options;
        for (var i = 0; i < opts.length; i++) {
            if (opts[i].value == id) {
                sel.selectedIndex = i;
            }
        }
    }

    changeProject(id) {
        this.toggleDropdown();
        this.props.dispatch(
            replace(
                `/projects?target=${
                    this.props.params.project.includes('test-') ? 'test-' : ''
                }${id}`,
            ),
        );
    }

    getProject(props) {
        if (!props) props = this.props;

        const projects = props.projectsDetails.projects;
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].id === props.params.project.replace('test-', '')) {
                return projects[i];
            }
        }
    }

    trunc(str, n) {
        return str.length > n ? str.substring(0, n) + '...' : str;
    }

    toggleDropdown() {
        this.setState({
            dropdownvisible: !this.state.dropdownvisible,
        });
    }

    render() {
        const projects = this.props.projectsDetails.projects;
        const currentProject = this.props.currentProject;
        return (
            <div class='wrapper large-12 columns shadowed rounded-top'>
                <div class='row'>
                    <div class='large-2 medium-3 columns side-nav lighten-bg lighten-content hide-for-small no-padding'>
                        <div
                            style={{
                                textAlign: 'center',
                                paddingTop: '0px',
                                width: '100%',
                            }}
                        >
                            {this.props.projectsDetails.fetched &&
                            !this.props.projectsDetails.error &&
                            projects.length > 0 ? (
                                <div
                                    class={
                                        this.state.dropdownvisible
                                            ? 'dropdown activated'
                                            : 'dropdown'
                                    }
                                >
                                    <button
                                        class='dropbtn'
                                        onClick={this.toggleDropdown.bind(this)}
                                    >
                                        <div
                                            style={{
                                                width: '20%',
                                                display: 'inline-block',
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            {currentProject.fetched && (
                                                <ProjectRegionFlag
                                                    project={currentProject.project}
                                                />
                                            )}
                                        </div>
                                        <span
                                            style={{
                                                width: '70%',
                                                display: 'inline-block',
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            {this.getProject().name}{' '}
                                        </span>
                                        <div
                                            style={{
                                                width: '10%',
                                                display: 'inline-block',
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            <img
                                                src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI1NiAyNTYiIGhlaWdodD0iMjU2cHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNTYgMjU2IiB3aWR0aD0iMjU2cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0zOC4zOTksNzYuOGMxLjYzNywwLDMuMjc0LDAuNjI1LDQuNTI0LDEuODc1bDg1LjA3NSw4NS4wNzZsODUuMDc1LTg1LjA3NmMyLjUtMi41LDYuNTUtMi41LDkuMDUsMHMyLjUsNi41NSwwLDkuMDUgIGwtODkuNiw4OS42MDFjLTIuNSwyLjUtNi41NTEsMi41LTkuMDUxLDBsLTg5LjYtODkuNjAxYy0yLjUtMi41LTIuNS02LjU1LDAtOS4wNUMzNS4xMjQsNzcuNDI1LDM2Ljc2Miw3Ni44LDM4LjM5OSw3Ni44eiIvPjwvc3ZnPg=='
                                                class='down-arrow'
                                            />
                                        </div>
                                    </button>
                                    <div class='dropdown-content'>
                                        {projects.map(
                                            function(project) {
                                                if (
                                                    project.id ===
                                                    this.props.params.project.replace('test-', '')
                                                )
                                                    return <span key={0} />;

                                                return (
                                                    <a
                                                        key={project.id}
                                                        onClick={this.changeProject.bind(
                                                            this,
                                                            project.id,
                                                        )}
                                                        class='clearfix'
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'inline-block',
                                                                verticalAlign: 'middle',
                                                                width: '20%',
                                                            }}
                                                        >
                                                            <ProjectRegionFlag project={project} />
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'inline-block',
                                                                verticalAlign: 'middle',
                                                                width: '60%',
                                                            }}
                                                        >
                                                            {project.name}
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'inline-block',
                                                                verticalAlign: 'middle',
                                                                width: '20%',
                                                            }}
                                                        >
                                                            <span class='left-arrow'>&rarr;</span>
                                                        </div>
                                                    </a>
                                                );
                                            }.bind(this),
                                        )}

                                        <Link
                                            to='/projects/create'
                                            class='text-center'
                                            style={{ padding: '1.5em' }}
                                        >
                                            <img
                                                src='//dashboard.processout.com/images/add.svg'
                                                style={{
                                                    height: '1.1em',
                                                    marginRight: '1em',
                                                }}
                                            />
                                            Create new project
                                        </Link>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {this.state.dropdownvisible ? null : (
                            <SideMenu
                                location={this.props.location}
                                sandbox={this.props.params.project.includes('test-')}
                            />
                        )}
                    </div>
                    <div class='large-10 medium-9 small-12 columns panel-content normal-bg'>
                        {currentProject.fetched && !currentProject.error ? (
                            this.props.children
                        ) : currentProject.error ? (
                            <Error />
                        ) : (
                            <Loading />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return {
        projectsDetails: store.projects,
        replace: replace,
        currentProject: store.currentProject,
    };
})(withRouter(Nav));
