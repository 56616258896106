// @flow
import React from 'react';
import { TIMESPAN_OPTIONS, UPDATE_TELESCOPE_TIMESPAN } from '../consts';
import Select from 'react-select';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../../util/Types';
import type { $TelescopeState } from '../../../stores/Reducers/TelescopeReducer/Index';
import * as TelescopeActions from '../actions';
import { REQUEST_SURFACES_FETCH } from '../Surface/consts';

type Props = {
    value: string,
    onChange: ({ value: string }) => any,
    telescope: $TelescopeState,
} & $Dispatcher;

class TimespanSelector extends React.Component<Props> {
    onChange = (newValue: { value: string }) => {
        this.props.dispatch({
            type: UPDATE_TELESCOPE_TIMESPAN,
            payload: {
                timespan: newValue.value,
            },
        });
        this.props.dispatch({
            type: REQUEST_SURFACES_FETCH,
            payload: { timespan: newValue.value },
        });
    };

    render() {
        return (
            <div class='row'>
                <div class='medium-6 medium-offset-6 columns text-left'>
                    <div class='row'>
                        <div
                            class='medium-5 columns medium-offset-1'
                            style={{ paddingTop: '.5em' }}
                        >
                            <span>Viewing data from</span>
                        </div>
                        <div
                            class='medium-6 columns'
                            style={{
                                position: 'relative',
                                right: '1em',
                                zIndex: 15,
                            }}
                        >
                            <Select
                                options={TIMESPAN_OPTIONS}
                                value={this.props.telescope.standard.timespan}
                                searchable={false}
                                valueComponent={PastComponent}
                                clearable={false}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class PastComponent extends React.Component<{
    children: any,
    placeholder: string,
    value: any,
}> {
    render() {
        return (
            <div className='Select-value' title={this.props.value.title}>
                <span className='Select-value-label'>
                    <span class='greyed'>the past </span>
                    {this.props.children}
                </span>
            </div>
        );
    }
}

export default connect(store => {
    return { telescope: store.telescope };
})(TimespanSelector);
