// @flow

import type { $Rating } from './sagas';
import type { $Action } from '../../util/Types';

export const REGISTER_RATING = 'REGISTER_RATING';
export function registerRating(rating: $Rating, resolve: (success: boolean) => void): $Action {
    return {
        type: REGISTER_RATING,
        payload: { ...rating, resolve },
    };
}
