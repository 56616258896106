// @flow

import type { $Chart, $DataChart } from '../Boards/consts';
import type { $Action, $State } from '../../../util/Types';
import {
    REQUEST_BOARD_PRESET_SAVE,
    REQUEST_BOARD_PREVIEW,
    RESET_BOARD_CREATOR,
    SELECT_BOARD_PRESET,
    UPDATE_BOARDS_PRESETS_SEARCH,
} from './actions';
import * as ProcessOut from '../../../util/ProcessOut';

export type $BoardPreset = {
    name: string,
    description: string,
    charts: Array<$Chart>,
};

export type $BoardCreatorState = {
    name: string,
    presetSearch: string,
    selectedPreset: ?$BoardPreset,
    previewedCharts: Array<{ data: $DataChart, chart: $Chart }>,
    saving: boolean,
} & $State;

const defaultState: $BoardCreatorState = {
    name: '',
    presetSearch: '',
    selectedPreset: null,
    previewedCharts: [],
    fetching: false,
    error: false,
    fetched: false,
    saving: false,
};

export const CHANGE_BOARD_CREATOR_NAME = 'CHANGE_BOARD_CREATOR_NAME';

export default function(
    state: $BoardCreatorState = defaultState,
    action: $Action,
): $BoardCreatorState {
    switch (action.type) {
        case RESET_BOARD_CREATOR: {
            return {
                ...state,
                ...defaultState,
            };
        }

        case CHANGE_BOARD_CREATOR_NAME: {
            const { payload } = action;
            if (!payload) return state;
            return {
                ...state,
                name: payload.name,
            };
        }

        case UPDATE_BOARDS_PRESETS_SEARCH: {
            const { payload } = action;
            if (!payload) return state;
            return {
                ...state,
                presetSearch: payload.search,
            };
        }

        case SELECT_BOARD_PRESET: {
            const { payload } = action;
            if (!payload) return state;
            return {
                ...state,
                selectedPreset: payload.preset,
                fetched: false,
                fetching: false,
                error: false,
                previewedCharts: [],
            };
        }

        case ProcessOut.typePending(REQUEST_BOARD_PREVIEW): {
            return {
                ...state,
                previewedCharts: [],
                fetching: true,
                fetched: false,
                error: false,
            };
        }
        case ProcessOut.typeFulfilled(REQUEST_BOARD_PREVIEW): {
            const { payload } = action;
            if (!payload) return state;
            return {
                ...state,
                previewedCharts: payload.previewedCharts,
                fetching: false,
                fetched: true,
            };
        }
        case ProcessOut.typeFailed(REQUEST_BOARD_PREVIEW): {
            return {
                ...state,
                fetching: false,
                error: true,
                fetched: true,
            };
        }

        case ProcessOut.typePending(REQUEST_BOARD_PRESET_SAVE): {
            return {
                ...state,
                saving: true,
            };
        }

        case ProcessOut.typeFulfilled(REQUEST_BOARD_PRESET_SAVE): {
            return {
                ...state,
                saving: false,
            };
        }
        case ProcessOut.typeFailed(REQUEST_BOARD_PRESET_SAVE): {
            return {
                ...state,
                saving: false,
            };
        }
    }
    return state;
}
