// @flow

import { put, takeEvery } from 'redux-saga/effects';
import { REQUEST_FETCH_VALUES_FOR_FIELD } from './consts';
import type { $Action } from '../../util/Types';
import * as Actions from './actions';
import { typeFulfilled } from '../../util/ProcessOut';
import * as Sentry from '@sentry/browser';

type Action = $Action & {
    payload: {
        field: string,
        document: string,
    },
};

export function* fetchValues(action: Action): Generator<*, *, *> {
    try {
        if (
            new RegExp(/.*(amount$|amount_.+|fee$|fee_local|fee_estimate.*|seconds)/).test(
                action.payload.field,
            )
        )
            return; // we do not want to fetch numbers values
        if (action.payload.field === 'is_three_d_s_flow' || !action.payload.field) {
            return; // this field is only used in local (we are using three_d_s_status behind the scene)
        }
        if (action.payload.field === 'merchant_initiated') return; // Not fetching for merchant_initiated flag
        if (action.payload.field === 'rand') return; // Not fetching values for rand()
        if (action.payload.field === 'velocity') return; // Not fetching values for velocity
        if (action.payload.field === 'check_3ds') return; // Not fetching check_3ds values
        const result = yield put.resolve(Actions.fetchValues(action));
        yield put({
            type: typeFulfilled(REQUEST_FETCH_VALUES_FOR_FIELD),
            payload: {
                field: action.payload.field,
                values: result.value.data.values,
                document: action.payload.document,
            },
        });
    } catch (error) {
        Sentry.captureException(error);
    }
}

export default function* watchSearchBarSagas(): Generator<*, *, *> {
    yield takeEvery(REQUEST_FETCH_VALUES_FOR_FIELD, fetchValues);
}
