// @flow
import React from 'react';
import type { $DataExplorerState } from './reducer';
import { connect } from 'react-redux';
// $FlowFixMe
import './dataexplorer.scss';
import type { $Dispatcher } from '../../../util/Types';
import * as Actions from './actions';
import Previewer from './Previewer';
import Header from './Header';
import type { $FetchParams } from '../Boards/consts';

type Props = {
    params: {
        project: string,
        board: ?string,
    },
    dataExplorer: $DataExplorerState,
    isChartBuilder: ?boolean,
    analytics: {
        params: $FetchParams,
    },
} & $Dispatcher;

class DataExplorer extends React.Component<Props> {
    componentDidMount() {
        const { dispatch, location, params, dataExplorer, analytics } = this.props;
        dispatch(Actions.setBoardId(params.board));

        // Check if we have a chart id as parameter
        if (location.query.chart) {
            // we set up the edit mode
            dispatch(Actions.setDataExplorerChartEdition(location.query.chart));
            // And Request for the chart compilation
            dispatch(
                Actions.requestFormulaCompile(
                    location.query.chart,
                    params.project,
                    params.board,
                    analytics.params,
                ),
            );
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { dataExplorer } = nextProps;
        const oldParams = this.props.analytics.params;
        const newParams = nextProps.analytics.params;
        if (
            oldParams.interval.to !== newParams.interval.to ||
            oldParams.interval.from !== newParams.interval.from ||
            oldParams.filter !== newParams.filter
        ) {
            this.props.dispatch(
                Actions.requestChartFetch(
                    dataExplorer.formula,
                    dataExplorer.name || 'Preview',
                    dataExplorer.type,
                    dataExplorer.unit,
                    newParams,
                ),
            );
        }
    }

    componentWillUnmount(): void {
        const { dispatch } = this.props;
        // We reset the explorer
        dispatch(Actions.resetDataExplorer());
    }

    render() {
        const { dataExplorer, location } = this.props;
        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <div class='data-explorer'>
                        <Header isChartBuilder={this.props.isChartBuilder} />
                        <div class='row margin-bottom'>
                            <div class='large-12 columns'>
                                <div class='content'>
                                    <div class='row'>
                                        <div class='large-12 columns no-padding'>
                                            {this.props.children}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.dataExplorer.selectedTab === 'editor' ? <Previewer /> : null}
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { dataExplorer: store.dataExplorer, analytics: store.analytics };
})(DataExplorer);

export const DataExplorerBuilder = connect(store => {
    return { dataExplorer: store.dataExplorer, analytics: store.analytics };
})(props => <DataExplorer {...props} isChartBuilder={true} />);
