// @flow
import React from 'react';
import ContentLayout from '../../pages/ContentLayout';
import * as GatewaysActions from '../../Actions/GatewaysActions';
import Loader from '../../components/Loader';
import { ReactIntl } from 'react-intl';
import { connect } from 'react-redux';
import Configuration from './Configuration';
import FilterBar from '../FilterBar/FilterBar';
import type { $GatewayConfiguration } from './consts';
import type { $Dispatcher, $State } from '../../util/Types';

const PROVIDERS_PER_LINE = 6;

type Props = {
    configurationsDetails: {
        configurations: Array<$GatewayConfiguration>,
    } & $State,
} & $Dispatcher;

type State = {
    filteredConfigs: Array<$GatewayConfiguration>,
};

@connect(function(store) {
    return {
        configurationsDetails: store.processorsConfigurations,
    };
})
export default class Configurations extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            filteredConfigs: [],
        };
    }

    componentDidMount() {
        this.props.dispatch(GatewaysActions.loadGatewaysConfigurations());
    }

    componentWillReceiveProps(nextProps: Props) {
        if (!nextProps.configurationsDetails.fetching) {
            nextProps.configurationsDetails.configurations.sort(function(a, b) {
                return a.enabled && !b.enabled ? -1 : 1;
            });
        }
    }

    render() {
        const { configurationsDetails } = this.props;
        if (!configurationsDetails.fetched || configurationsDetails.fetching) {
            return (
                <ContentLayout title={<h4>Your activated payment providers</h4>}>
                    <Loader />
                </ContentLayout>
            );
        }
        const configurations = this.props.configurationsDetails.configurations;

        if (!configurations.length) return <div />;

        const { filteredConfigs } = this.state;

        const rowsN = filteredConfigs.length / PROVIDERS_PER_LINE;
        let rows = [];
        for (let j = 0; j < rowsN; j++) {
            let count = -1;
            const row = (
                <div class='row' key={Math.floor(Math.random() * 100000)}>
                    {filteredConfigs
                        .slice(j * PROVIDERS_PER_LINE, j * PROVIDERS_PER_LINE + PROVIDERS_PER_LINE)
                        .map(function(config) {
                            count++;
                            let last = '';
                            if (count + j * PROVIDERS_PER_LINE == filteredConfigs.length - 1)
                                last = 'end';
                            return (
                                <div
                                    key={'config-' + count}
                                    class={`medium-2 ${last} columns margin-bottom`}
                                >
                                    <Configuration key={config.id} config={config} />
                                </div>
                            );
                        }, this)}
                </div>
            );
            rows.push(row);
        }

        return (
            <ContentLayout title={<h4>Your activated payment providers</h4>}>
                <div class='row'>
                    <div class='large-12 columns'>
                        <div className='row small-margin-bottom'>
                            <div className='large-4 end columns'>
                                <FilterBar
                                    onFilter={(values: Array<$GatewayConfiguration>) => {
                                        this.setState({ filteredConfigs: values });
                                    }}
                                    options={configurations.map(config => ({
                                        key: config.name,
                                        value: config,
                                    }))}
                                />
                            </div>
                        </div>
                        <div class='row small-margin-bottom'>
                            <div class='large-12 columns'>{rows}</div>
                        </div>
                    </div>
                </div>
            </ContentLayout>
        );
    }
}
