// @flow

export const TRANSACTION_FIELDS = {
    error_code: 'Transaction error codes: do-not-honor…',
    payment_type: 'Transaction payment types: Card, Paypal…',
    name: 'Transaction names',
    currency: 'Currency used for the transactions: USD, EUR, CAD…',
    status: 'Transaction status: completed, pending, failed…',
    country: 'Country in which the transactions occured: FR, US, UK…',
    continent: 'Continent in which the transactions occured',
    attempts_count: 'Number of attempts for a transaction',
    subscription_cycle: 'Number of iteration of a subscription',
    card_scheme: 'Card scheme: Visa, Mastercard, Amex…',
    card_co_scheme: 'Card co-schemes: CB…',
    card_iin: 'Card BIN/IIN',
    card_type: 'Card types: Debit, Credit…',
    card_category: 'Card categories: ',
    card_bank: 'Bank that issued the card used for the transactions',
    cvc_check: 'CVC status for transactions: passed, checked…',
    acquirer_name: 'Name of acquirers that handled the transactions',
    gateway_configuration_id: '',
    gateway_name: 'Name of the gateways used: Stripe, Adyen, Braintree…',
    gateway_fee_currency: 'Currency of the fees paid to the gateway',
    created_at: 'Transaction creation dates',
    created_at_day_hour: 'Hour of the day transaction was created (int)',
    created_at_day_hour_segment: 'Hour of the day transaction was created (string)',
    created_at_month_day: 'Day of the month transaction was created (int)',
    created_at_week_day: 'Day of the week transaction was created (int)',
    created_at_week_day_name: 'Day of the week transaction was created (name/string)',
    three_d_s_status: 'Whether or not the transactions went through 3DS',
    eci: 'Transaction type (such as recurring)',
    mcc: 'Merchant Category Code',
    merchant_account_id: 'ID of the merchant account',
    is_on_us: 'Whether the transaction is performed on-us or not',
    origin: 'Origin of the transaction',
};

export const TRANSACTION_AMOUNT_FIELDS = {
    amount: 'Transaction’s amount requested at its creation.',
    amount_local: 'Transaction’s amount requested at its creation in your project’s currency',
    available_amount: 'Captured amount minus the total refund value.',
    available_amount_local:
        'Captured amount minus the total refund value in your project’s currency',
    refunded_amount: 'Total refunded amount.',
    refunded_amount_local: 'Total refunded amount in your project’s currency.',
    captured_amount: 'Total captured amount.',
    captured_amount_local: 'Total captured amount in your project’s currency.',
    authorized_amount: 'Total authorized amount.',
    authorized_amount_local: 'Total authorized amount in your project’s currency.',
    gateway_fee: 'Fee charged by your gateway.',
    gateway_fee_local: 'Fee charged by your gateway in your project’s currency.',
    processing_fee: 'Total processing fee.',
    processing_fee_local: 'Total processing fees in your project’s currency.',
    exchange_fee: 'Total exchange fee',
    exchange_fee_local: 'Total exchange fees in your project’s currency.',
    refund_fee: 'Total refund fee.',
    refund_fee_local: 'Total refund fee in your project’s currency',
    interchange_fee: 'Total interchange fee.',
    interchange_fee_local: 'Total interchange fee in your project’s currency.',
    scheme_fee: 'Total scheme (Visa, mastercard…) fee.',
    scheme_fee_local: 'Total scheme (Visa, mastercard…) fee in your project’s currency.',
    markup_fee: 'Fee above the interchange+',
    markup_fee_local: 'Fee above the interchange+ in your project’s currency.',
    interchange_fee_estimate: 'Total estimated interchange fee.',
    interchange_fee_estimate_local: 'Total estimated interchange fee in your project’s currency.',
    scheme_fee_estimate: 'Total estimated scheme (Visa, mastercard…) fee.',
    scheme_fee_estimate_local:
        'Total estimated scheme (Visa, mastercard…) fee in your project’s currency.',
    duplicate_distance_seconds:
        'Number of seconds to the next transaction which is a duplicate of the current one, null if none.',
};
