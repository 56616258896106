// @flow

import type { $Action } from '../../util/Types';
import { REQUEST_FETCH_VALUES_FOR_FIELD } from './consts';
import { typeFulfilled } from '../../util/ProcessOut';
import { UPDATE_SEARCH_BAR_FILTER } from './actions';
import type { $UpdateSearchBarFilterAction, $UpdateSearchBarAppliedFilterAction } from './actions';
import { UPDATE_SEARCH_BAR_APPLIED_FILTER, RESET_SEARCH_BAR_FILTER } from './actions';

export type $SearchBarState = {
    filter: string,
    appliedFilter: string,
} & { [string]: string };

const defaultState: $SearchBarState = { appliedFilter: '', filter: '' };

export default function(
    state: $SearchBarState = defaultState,
    action: $Action | $UpdateSearchBarFilterAction | $UpdateSearchBarAppliedFilterAction,
): $SearchBarState {
    switch (action.type) {
        case typeFulfilled(REQUEST_FETCH_VALUES_FOR_FIELD): {
            if (!action.payload || !action.payload.field) return state;
            const values = action.payload.values;
            const fields = {
                ...state,
                [action.payload.field]: values.map(value => {
                    const isString = typeof value === 'string';
                    return {
                        label: String(value),
                        value: String(value),
                    };
                }),
            };
            return {
                ...state,
                ...fields,
            };
        }

        case UPDATE_SEARCH_BAR_FILTER: {
            const { payload } = action;
            return {
                ...state,
                filter: payload.filter,
            };
        }

        case UPDATE_SEARCH_BAR_APPLIED_FILTER: {
            return {
                ...state,
                appliedFilter: action.payload.filter,
            };
        }

        case RESET_SEARCH_BAR_FILTER: {
            return {
                ...state,
                ...defaultState,
            };
        }
    }
    return state;
}
