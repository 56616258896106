// @flow

import React from 'react';
import Select from 'react-select';
import type { $DataPoint } from '../consts';
import getColor from '../charts/colors';

type State = {
    value: ?Array<$Option>,
};

type Props = {
    points: Array<$DataPoint>,
    colors: Array<{ key: string, color: string }>,
    onChange: (Array<string>) => void,
};

type $Option = {
    label: string,
    value: string,
    color: string,
    count: number,
};

class ComparableSelector extends React.Component {
    props: Props;
    state: State;

    constructor() {
        super();
        this.state = {
            value: null,
        };
    }

    componentDidMount() {
        const { points } = this.props;
        const value = points.map(point => {
            const correspondingColor = this.props.colors.find(entry => entry.key === point.key);
            return {
                label: point.key,
                value: point.key,
                color: correspondingColor ? correspondingColor.color : getColor(0),
                count: point.value,
            };
        });
        this.setState({ value: value, option: value });
    }

    handleSelectChange = (newValue: Array<$Option>) => {
        this.setState({ value: newValue });
        this.props.onChange(newValue.map(option => option.value));
    };

    renderValue = (option: $Option) => {
        return <strong style={{ color: option.color }}>{option.label}</strong>;
    };

    render() {
        const { points, colors } = this.props;
        const { value } = this.state;

        return (
            <div style={{ display: 'inline-block', width: '100%' }}>
                <Select
                    closeOnSelect={false}
                    disabled={false}
                    multi
                    onChange={this.handleSelectChange}
                    options={this.state.option}
                    optionRenderer={(option: $Option) => {
                        return (
                            <span style={{ color: option.color }}>
                                {option.label} ({option.count})
                            </span>
                        );
                    }}
                    placeholder="Select your favourite(s)"
                    removeSelected={true}
                    rtl={false}
                    value={value}
                    valueComponent={KeyComponent}
                    scrollMenuIntoView={false}
                    style={{
                        textAlign: 'left',
                        padding: '.5em',
                    }}
                />
            </div>
        );
    }
}

class KeyComponent extends React.Component {
    props: { value: $Option, onRemove: $Option => void };

    onRemove = () => {
        this.props.onRemove(this.props.value);
    };

    render() {
        const { value } = this.props;
        return (
            <div
                style={{
                    paddingRight: '.5em',
                    border: `1px solid ${value.color}`,
                    borderRadius: '4px',
                    padding: '.2em',
                    display: 'inline-block',
                    margin: '.3em',
                    backgroundColor: value.color,
                    opacity: 0.7,
                }}
            >
                <a onClick={this.onRemove}>
                    <span class="small-font" style={{ color: 'white', marginRight: '.2em' }}>
                        {value.label} ({value.count}) <span style={{ fontSize: '.8em' }}>✕</span>
                    </span>
                </a>
            </div>
        );
    }
}

export default ComparableSelector;
