// @flow

import type { $Action } from '../../util/Types';

export const REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH = 'REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH';
export function fetchCustomerMonthRevenue(customerId: string): $Action {
    return {
        type: REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH,
        payload: {
            customerId,
        },
    };
}
