// @flow

import type { $Board } from './consts';
import { FETCH_BOARDS, REQUEST_BOARDS_FETCH } from './consts';
import { put, takeLatest, select } from 'redux-saga/effects';
import * as Actions from './actions';
import type { $ActionType } from '../../Preparator/consts';
import { push } from 'react-router-redux';
import * as ProcessOut from '../../../util/ProcessOut';
import { typeFailed } from '../../../util/ProcessOut';
import * as GwayACtions from '../../GatewaysConfigurations/actions';

type Action = {
    payload: ?{
        redirectToBoard?: string,
        silent?: boolean,
    },
} & $ActionType;

export function* fetchBoards(action: Action): Generator<*, *, *> {
    try {
        yield put.resolve(GwayACtions.fetchGwayConfNames());
        const boardsResult = yield put.resolve(
            Actions.fetchBoards(!!(action.payload && action.payload.silent)),
        );
        const boards: Array<$Board> = boardsResult.value.data.boards.filter(
            board => !board.id.includes('board_default'),
        );
        const location = yield select(store => store.routing.locationBeforeTransitions);
        if (boards.length > 0) {
            const redirection =
                action.payload && action.payload.redirectToBoard
                    ? `/${action.payload.redirectToBoard}`
                    : null;
            if (redirection) {
                yield put(push(`/projects/${ProcessOut.ProjectId}/boards${redirection}`));
            }
        }
    } catch (error) {
        yield put({
            type: typeFailed(FETCH_BOARDS),
            payload: error,
        });
    }
}

export default function* watchBoardsSagas(): Generator<*, *, *> {
    yield takeLatest(REQUEST_BOARDS_FETCH, fetchBoards);
}
