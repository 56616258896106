// @flow

import React from 'react';

type Props<T> = {
    options: Array<{ key: string, value: T }>,
    onFilter: (values: Array<T>) => void,
};

type State<T> = {
    filter: string,
    values: Array<{ key: string, value: T }>,
};

class FilterBar<T> extends React.Component<Props<T>, State<T>> {
    constructor() {
        super();
        this.state = {
            filter: '',
            values: [],
        };
    }

    componentDidMount() {
        const { filter } = this.state;
        const { onFilter, options } = this.props;
        const values = options.filter(o => o.key.includes(filter));
        this.setState({ values });
        onFilter(values.map(value => value.value));
    }

    filter = (event: SyntheticInputEvent<HTMLInputElement>) => {
        const filter = event.target.value;
        const { onFilter, options } = this.props;
        const values = options.filter(o => o.key.toLowerCase().includes(filter.toLowerCase()));
        this.setState({ filter, values });
        onFilter(values.map(value => value.value));
    };

    render() {
        return (
            <input
                type='text'
                value={this.state.filter}
                placeholder='Search'
                onChange={this.filter}
            />
        );
    }
}

export default FilterBar;
