// @flow
import React from 'react';
import { FormattedDate } from 'react-intl';
import CardSchemeImage from '../../util/CardSchemeImage';
import moment from 'moment';
import Check from '../../features/Checks/Check';
import type { $Check } from '../../Types/Transaction';
import CountryFlag from '../../features/CountryFlag/CountryFlag';

export type $Card = {
    city: ?string,
    country: ?string,
    exp_year: number,
    exp_month: number,
    last_4_digits: number,
    iin: ?number,
    card_scheme: ?string,
    bank_name: ?string,
    category: ?string,
    type: ?string,
    name: ?string,
    brand: ?string,
    co_scheme: ?string,
    cvc_check: $Check,
    avs_check: $Check,
    country_code: string,
    scheme: string,
};

type Props = {
    card: $Card,
};

export default class CardDetails extends React.Component {
    props: Props;

    checkVar = (obj: any): any => {
        return !obj || obj === '' ? <span class="greyed">--</span> : obj;
    };

    getLine = (title: string, value: any): any => {
        return (
            <div class="row small-margin-bottom">
                <div class="large-4 columns">
                    <span class="greyed">{title}</span>
                </div>
                <div class="large-8 columns">
                    <span class="right">{this.checkVar(value)}</span>
                </div>
            </div>
        );
    };

    formatAdress = (): any => {
        const city = this.props.card.city;
        const country = this.props.card.country_code;

        if (!city || !country || city === '' || country === '') {
            if (city && city !== '') return city;
            if (country && country !== '') return country;
            return <span class="greyed">--</span>;
        }
        return `${city}, ${country}`;
    };

    formatCheck = (check: string): any => {
        let status;
        if (check === 'passed') status = 'success';
        else if (
            check.includes('pending') ||
            check.includes('unavailable') ||
            check.includes('unknown')
        )
            status = 'warning';
        else status = 'failed';

        return (
            <div
                class={'right log-level ' + status}
                style={{
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    marginTop: '0px',
                    marginRight: '-3px',
                }}
            >
                {check}
            </div>
        );
    };

    render() {
        const card = this.props.card;

        if (!card) {
            return null;
        }

        const expiry = moment(new Date(card.exp_year, card.exp_month - 1));

        return (
            <div class="row">
                <div class="large-12 columns">
                    <div class="row">
                        <div class="large-12 columns">
                            <h6 class="capitalize greyed font-console margin-bottom">
                                Card details
                            </h6>
                        </div>
                    </div>
                    {this.getLine('Name on card', card.name)}
                    {this.getLine(
                        'Last 4 digits',
                        <span class="font-console">{card.last_4_digits}</span>,
                    )}
                    {this.getLine('IIN', <span class="font-console">{card.iin}</span>)}
                    {this.getLine('Bank name', card.bank_name)}
                    {this.getLine('Type', card.type)}
                    {this.getLine('Category', card.category)}
                    {this.getLine(
                        'Expiration date',
                        card.exp_month && card.exp_year ? (
                            <span class="font-console">
                                <FormattedDate
                                    value={expiry.toISOString()}
                                    month="2-digit"
                                    year="numeric"
                                />
                            </span>
                        ) : (
                            <span class="greyed">Unknown expiration</span>
                        ),
                    )}
                    {this.getLine('Scheme', CardSchemeImage(card))}
                    {this.getLine('Co-scheme', card.co_scheme)}
                    {this.getLine('Brand', card.brand)}
                    {this.getLine('Country', <CountryFlag country_code={card.country_code} />)}
                    {this.getLine('Address', this.formatAdress())}
                    <div class="row small-margin-bottom">
                        <div class="medium-4 columns greyed text-left">
                            <div style={{ display: 'inline-block' }}>AVS check</div>
                            <div
                                class="info"
                                data-tip="Best result of an address check performed on this card."
                                style={{ display: 'inline-block' }}
                            >
                                i
                            </div>
                        </div>
                        <div class="medium-8 columns text-right">
                            <Check check={card.avs_check} />
                        </div>
                    </div>
                    <div class="row small-margin-bottom">
                        <div class="medium-4 columns greyed text-left">
                            <div style={{ display: 'inline-block' }}>CVC check</div>
                            <div
                                class="info"
                                data-tip="Best result of a CVC check performed on this card."
                                style={{ display: 'inline-block' }}
                            >
                                i
                            </div>
                        </div>
                        <div class="medium-8 columns text-right">
                            <Check check={card.cvc_check} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
