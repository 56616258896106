// @flow

import React from 'react';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import type { $DataExplorerState } from './reducer';
import type { $Dispatcher } from '../../../util/Types';
import { PRESETS } from './consts';
import uniqid from 'uniqid';
import * as Actions from './actions';
import type { $DataExplorerPreset } from './consts';

type Props = {
    dataExplorer: $DataExplorerState,
} & $Dispatcher;

class PresetsList extends React.Component {
    props: Props;

    selectPreset = (preset: $DataExplorerPreset) => {
        this.props.dispatch(Actions.selectPreset(preset));
        this.props.dispatch(Actions.applyPreset(preset));
    };

    render() {
        const { dataExplorer } = this.props;
        return (
            <div class="large-4 columns presets-container">
                <ReactCSSTransitionGroup
                    transitionName={'preset-animation'}
                    transitionEnterTimeout={120}
                    transitionLeaveTimeout={120}
                >
                    {(dataExplorer.presetsSearch
                        ? PRESETS['business-intel']
                              .concat(PRESETS['payment-performance'])
                              .concat(PRESETS['payment-fees'])
                              .filter(preset =>
                                  preset.name
                                      .toLowerCase()
                                      .includes(dataExplorer.presetsSearch.toLowerCase()),
                              )
                        : PRESETS[dataExplorer.selectedPresetCategory]
                    ).map(preset => (
                        <div
                            class={`row preset${dataExplorer.selectedPreset &&
                            preset.id === dataExplorer.selectedPreset.id
                                ? ' active'
                                : ''}`}
                            key={`${preset.name}-${preset.chartBuilder.selectedMetric.unit}`}
                            onClick={() => {
                                this.selectPreset(preset);
                            }}
                        >
                            <div class="large-10 columns">{preset.name}</div>
                            <div class="large-2 columns text-right">
                                <div
                                    class="tag"
                                    title={
                                        preset.chartBuilder.selectedMetric.unit === 'percentage'
                                            ? `This graph shows values in your percentage`
                                            : preset.unit === 'amount'
                                              ? `This graph shows values in your project's currency`
                                              : `This graph shows values in number of transactions`
                                    }
                                >
                                    {preset.chartBuilder.selectedMetric.unit === 'percentage'
                                        ? '%'
                                        : preset.chartBuilder.selectedMetric.unit === 'amount'
                                          ? '$'
                                          : '#'}
                                </div>
                            </div>
                        </div>
                    ))}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

export default connect(store => {
    return {
        dataExplorer: store.dataExplorer,
    };
})(PresetsList);
