// @flow

import React from 'react';
import { withRouter } from 'react-router';
import Project from '../../util/Project';

export class SandboxIndicator extends React.Component<{
    params: {
        project: ?string,
    },
}> {
    render() {
        const { params } = this.props;
        if (!params.project) return null;
        const project = new Project(params.project);
        if (!project.isSandbox()) return null;

        return (
            <div class='row sandbox-indicator'>
                <div class='large-12 columns'>Sandbox is active</div>
            </div>
        );
    }
}

export default withRouter(SandboxIndicator);
