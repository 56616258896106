import * as ProcessOut from '../../../util/ProcessOut';

export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case 'FETCH_CUSTOMER_TRANSACTIONS_PENDING': {
            return {
                ...state,
                fetched: false,
                fetching: true,
            };
        }

        case 'FETCH_CUSTOMER_TRANSACTIONS_NORELOAD_FULFILLED': {
            return formatReducer(state, action);
        }
        case 'FETCH_CUSTOMER_TRANSACTIONS_FULFILLED': {
            return formatReducer(state, action);
        }
        case ProcessOut.typeFailed('FETCH_CUSTOMER_TRANSACTIONS'): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: action.payload,
            };
        }
    }

    return state;
}

function formatReducer(state, action) {
    return {
        ...state,
        fetched: true,
        fetching: false,
        has_more: action.payload.has_more,
        total_count: action.payload.total_count,
        transactions: action.payload.transactions,
    };
}
