import React from 'react';
import { connect } from 'react-redux';
import Prices from './InvoiceEdit/Prices';
import Metadata from './Metadata';
import * as PlansActions from '../Actions/PlansActions';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

var serialize = require('form-serialize');

@connect(
    function(store) {
        return {
            currenciesDetails: store.currencies,
            planDetails: store.planDetails,
        };
    },
    null,
    null,
    { withRef: true },
)
export default class PlanModal extends React.Component {
    static propTypes = {
        modalName: PropTypes.string.isRequired,
        plan: PropTypes.object,
    };

    constructor() {
        super();

        this.state = {
            create: false,
            showDetails: false,
        };
    }

    componentDidMount() {
        if (this.props.plan) {
            this.id = this.props.plan.id;
        }
        this.create = !this.props.plan;
        if (this.create) {
            this.props.dispatch({ type: 'START_PLAN_CREATION' });
        }

        this.setState({
            create: this.create,
            recurring: this.recurring,
            customer: this.props.customer,
        });
    }

    created() {
        this.props.dispatch({ type: 'CLOSE_MODAL' });
    }

    save(event) {
        event.preventDefault();
        var planForm = document.querySelector('#plan-form');
        var data = serialize(planForm, { hash: true });

        if (!data.metadata) data.metadata = {};

        var metas = this._meta.updateValue();
        for (var i = 0; i < metas.length; i++) {
            data.metadata[metas[i].key] = metas[i].value;
        }

        if (data.metadata && Object.keys(data.metadata).length == 0) {
            data.metadata = {};
        }

        if (!data.amount) {
            data.amount = 0;
        }

        if (this.state.create) {
            data.amount = parseFloat(data.amount);
            data.amount = data.amount + '';
        } else {
            delete data.amount;
        }

        var plan = JSON.stringify(data);
        if (this.state.create) {
            this.props.dispatch(PlansActions.createPlan(plan, this.created.bind(this)));
        } else {
            this.props.dispatch(
                PlansActions.editPlan(plan, this.props.plan.id, this.created.bind(this)),
            );
        }
    }

    deletePlan() {
        if (confirm('Are you sure you want to delete this plan?')) {
            this.props.dispatch(PlansActions.deletePlan(this.props.plan.id));
            this.props.dispatch({ type: 'CLOSE_MODAL' });
        }
    }

    openModal() {
        var plan = this.props.plan;
        var planUrl;
        var deleteButton;
        if (plan) {
            planUrl = (
                <a target='_blank' href={plan.url} class='greyed'>
                    Payment link ↗
                </a>
            );
            deleteButton = (
                <a
                    onClick={this.deletePlan.bind(this)}
                    class='round border small button'
                    style={{ opacity: 0.5 }}
                >
                    Delete
                </a>
            );
        }

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: this.props.modalName,
                content: (
                    <div class='row'>
                        <ReactTooltip effect='solid' />
                        <div class='large-12 columns'>
                            <form id='plan-form' onSubmit={this.save.bind(this)}>
                                <div class='row small-margin-bottom'>
                                    <div
                                        class='medium-12 medium-centered columns'
                                        style={{
                                            padding: '25px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Details
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <div class='row'>
                                                    <div class='large-6 columns'>
                                                        <label class='greyed'>Name</label>
                                                        <input
                                                            type='text'
                                                            class='bottom-border'
                                                            name='name'
                                                            placeholder='Name'
                                                            defaultValue={plan ? plan.name : ''}
                                                            required
                                                        />
                                                    </div>
                                                    <div class='large-6 columns'>
                                                        <label class='greyed'>Identifier</label>
                                                        <input
                                                            type='text'
                                                            class='bottom-border'
                                                            name='id'
                                                            defaultValue={plan ? plan.id : ''}
                                                            placeholder='Identifier'
                                                            disabled={plan != null}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div class='row small-margin-bottom'>
                                                    <div class='large-6 medium-6 columns'>
                                                        <label class='greyed'>
                                                            Charging interval{' '}
                                                            <span
                                                                class='info'
                                                                data-tip='Format: 1d1w1m1y. 5d for every 5 days, 1m for every month.'
                                                            >
                                                                i
                                                            </span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            placeholder='Interval'
                                                            name='interval'
                                                            defaultValue={plan ? plan.interval : ''}
                                                            disabled={plan != null}
                                                            class='bottom-border'
                                                            required
                                                        />
                                                    </div>
                                                    <div class='large-6 medium-6 columns'>
                                                        <label class='greyed'>
                                                            Trial period{' '}
                                                            <span
                                                                class='info'
                                                                data-tip='Format: 1d1w1m1y. 5d for every 5 days, 1m for every month.'
                                                            >
                                                                i
                                                            </span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            placeholder='Trial period'
                                                            name='trial_period'
                                                            defaultValue={
                                                                plan ? plan.trial_period : ''
                                                            }
                                                            class='bottom-border'
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <Prices
                                                    plan={plan}
                                                    displayLabel={true}
                                                    currencies={
                                                        this.props.currenciesDetails.currencies
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <hr />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Additional data
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row small-margin-bottom'>
                                            <div class='large-12 columns'>
                                                <Metadata
                                                    resource={plan}
                                                    ref={m => (this._meta = m)}
                                                />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns text-right'>{planUrl}</div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ),
                footer: (
                    <div class='row'>
                        <div class='medium-4 columns'>{deleteButton}</div>
                        <div class='medium-8 columns text-right'>
                            <a
                                onClick={function(event) {
                                    event.preventDefault();
                                    this.props.dispatch({
                                        type: 'CLOSE_MODAL',
                                    });
                                }.bind(this)}
                                class='round border small button'
                            >
                                Cancel
                            </a>
                            <input
                                type='button'
                                onClick={this.save.bind(this)}
                                class='round small main button white-text'
                                value={this.props.plan ? 'Save' : 'Create'}
                            />
                        </div>
                    </div>
                ),
            },
        });
    }

    render() {
        return null;
    }
}
