// @flow

import React from 'react';
import { connect } from 'react-redux';
import CurrencyField from '../../components/CurrencyField';
import Instruction from '../../components/Instruction';
import StripeConnect from '../../components/StripeConnect';
import * as GatewaysActions from '../../Actions/GatewaysActions';
import AdyenTelescope from '../TelescopeOnboarding/processors/Adyen';
import type { $Dispatcher } from '../../util/Types';

const serialize = require('form-serialize');

@connect(
    function(store) {
        return {
            currenciesDetails: store.currencies,
            projectsDetails: store.projects,
        };
    },
    null,
    null,
    { withRef: true },
)
export default class GatewayModal extends React.Component {
    props: {
        modalName: string,
        configuration?: ?{
            id: string,
        },
        gateway?: ?{
            name: string,
            beta: boolean,
            config: Array<{ key: string, value: string }>,
        },
        isForTelescope?: boolean,
    } & $Dispatcher;
    gateway: any;

    constructor() {
        super();
        this.gateway = null;
    }

    submit = (event: Event) => {
        event.preventDefault();
        const gateway = this.props.gateway;
        if (!gateway) {
            return;
        }

        const form = document.querySelector('#gateway-form');
        const config = serialize(form, { hash: true });

        const settings = {
            id: config.conf_id,
            import_data: true,
            fee_fixed: parseFloat(config.fee_fixed),
            fee_percentage: parseFloat(config.fee_percentage),
            default_currency: config.default_currency,
        };

        delete config.conf_id;
        delete config.import_data;
        delete config.fee_fixed;
        delete config.fee_percentage;
        delete config.default_currency;

        if (this.props.configuration)
            this.props.dispatch(
                GatewaysActions.updateGatewayConfig(
                    gateway.name,
                    this.props.configuration.id,
                    config,
                    settings,
                    this.receivedResponse.bind(this),
                ),
            );
        else
            this.props.dispatch(
                GatewaysActions.createGatewayConfig(
                    gateway.name,
                    config,
                    settings,
                    this.receivedResponse.bind(this),
                ),
            );
    };

    receivedResponse(success: boolean) {
        if (success) {
            this.props.dispatch({ type: 'CLOSE_MODAL' });
            this.props.dispatch(GatewaysActions.loadGatewaysConfigurations());
        }
    }

    getCurrentProjectCurrency() {
        const projects = this.props.projectsDetails.projects;
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].id === this.props.projectsDetails.current_project_id)
                return projects[i].default_currency;
        }

        return 'USD';
    }

    openModal() {
        let gateway = this.props.gateway;
        const config = this.props.configuration;
        if (config) {
            gateway = config.gateway;
        }

        const instructions = gateway.instructions;

        let beta;
        if (gateway.beta) {
            beta = (
                <p class='no-margin'>
                    <span class='bold' style={{ marginRight: '10px' }}>
                        Warning:{' '}
                    </span>
                    This payment gateway integration is currently in beta.
                </p>
            );
        }

        let configs;
        if (config && config.id) {
            configs = (
                <p class='no-margin'>
                    <div class='row'>
                        <div class='large-2 columns' style={{ paddingTop: '0.5em' }}>
                            <span class='bold' style={{ marginRight: '10px' }}>
                                Warning:{' '}
                            </span>
                        </div>
                        <div class='large-10 columns'>
                            This payment gateway is already configured. Saving will overwrite any
                            previous configuration.
                        </div>
                    </div>
                </p>
            );
        }

        let c = '';
        for (; c.length < 32; )
            c += Math.random()
                .toString(36)
                .substr(2, 1);
        let confID = `gway_conf_${c}`;
        if (config && config.id) {
            confID = config.id;
        }

        const projID = this.props.projectsDetails.current_project_id;

        let content;
        if (gateway.name === 'stripe') {
            content = <StripeConnect />;
        } else {
            content = (
                <div>
                    {instructions.map(instruction => {
                        return (
                            <Instruction
                                key={instruction.title}
                                showTexts={false}
                                instruction={instruction}
                                configs={gateway.config}
                                projectID={projID}
                                confID={confID}
                            />
                        );
                    })}
                </div>
            );
        }

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                large: true,
                header: <img style={{ maxHeight: '3em' }} src={gateway.logo_url} />,
                content:
                    gateway.name === 'adyen' ? (
                        <form
                            id='gateway-form'
                            autoComplete='off'
                            onSubmit={this.submit.bind(this)}
                        >
                            <AdyenTelescope
                                projectId={projID}
                                isForTelescope={this.props.isForTelescope}
                            />
                        </form>
                    ) : (
                        <div>
                            <div class='row small-margin-bottom' style={{ marginTop: '2em' }}>
                                <div class='large-11 large-centered columns'>
                                    <form
                                        id='gateway-form'
                                        autoComplete='off'
                                        onSubmit={this.submit}
                                    >
                                        <div class='row'>
                                            <div class='large-6 columns'>
                                                <div class='row big-margin-bottom'>
                                                    <div class='large-12 small-centered columns'>
                                                        {instructions.map(function(instruction) {
                                                            return (
                                                                <Instruction
                                                                    key={instruction.title}
                                                                    showInputs={false}
                                                                    instruction={instruction}
                                                                    configs={gateway.config}
                                                                    projectID={projID}
                                                                    confID={confID}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='large-5 large-offset-1 columns'>
                                                {gateway.tags.includes('beta') ? (
                                                    <div class='row small-margin-bottom'>
                                                        <div class='large-12 columns'>
                                                            <div
                                                                class='row small-margin-bottom'
                                                                style={{
                                                                    marginTop: '5em',
                                                                }}
                                                            >
                                                                <div class='large-12 columns text-center'>
                                                                    <span
                                                                        style={{
                                                                            color: '#FF8F00',
                                                                            fontWeight: '500',
                                                                        }}
                                                                    >
                                                                        {gateway.display_name} is
                                                                        currently only available in
                                                                        beta.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class='row margin-bottom'>
                                                                <div class='large-12 columns text-center greyed'>
                                                                    If you want to be a part of it
                                                                    you can request its access
                                                                </div>
                                                            </div>
                                                            <div class='row'>
                                                                <div class='large-12 columns text-center'>
                                                                    <a
                                                                        class='round main button small-font'
                                                                        onClick={() => {
                                                                            window.Intercom(
                                                                                'showNewMessage',
                                                                                `I'd like to have to have access to ${
                                                                                    gateway.display_name
                                                                                } beta.`,
                                                                            );
                                                                        }}
                                                                    >
                                                                        request access
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div class='row small-margin-bottom'>
                                                            <div class='large-12 columns'>
                                                                {content}
                                                            </div>
                                                        </div>
                                                        <div class='row small-margin-bottom'>
                                                            <div class='large-12 columns'>
                                                                <hr />
                                                            </div>
                                                        </div>
                                                        <div class='row'>
                                                            <div class='large-12 columns'>
                                                                <label class='greyed'>
                                                                    Configuration name
                                                                </label>
                                                                <input
                                                                    type='text'
                                                                    name='name'
                                                                    placeholder={
                                                                        gateway.display_name
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class='row'>
                                                            <div class='large-6 columns'>
                                                                <label class='greyed'>
                                                                    Percentage fee (optional)
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    name='fee_percentage'
                                                                    placeholder='2.9'
                                                                />
                                                            </div>
                                                            <div class='large-6 columns'>
                                                                <label class='greyed'>
                                                                    Fixed fee (optional)
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    step='0.01'
                                                                    name='fee_fixed'
                                                                    placeholder='0.30'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class='row margin-bottom'>
                                                            <div class='large-12 columns'>
                                                                <CurrencyField
                                                                    name='default_currency'
                                                                    defaultValue={this.getCurrentProjectCurrency()}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <input type='hidden' name='conf_id' value={confID} />
                                    </form>
                                </div>
                            </div>
                            <div class='row margin-bottom'>
                                <div class='large-7 columns large-centered'>{beta}</div>
                            </div>
                        </div>
                    ),
                footer: (
                    <div class='row' style={{ paddingTop: '0.3em' }}>
                        <div class='large-7 columns greyed'>{configs}</div>
                        <div class='large-5 columns text-right'>
                            <a
                                onClick={event => {
                                    event.preventDefault();
                                    this.props.dispatch({
                                        type: 'CLOSE_MODAL',
                                    });
                                }}
                                class='round border small button'
                            >
                                Cancel
                            </a>
                            {gateway.name === 'adyen' || gateway.tags.includes('beta') ? null : (
                                <input
                                    type='button'
                                    onClick={this.submit.bind(this)}
                                    class='round small main button white-text'
                                    value={this.props.configuration ? 'Save' : 'Create'}
                                />
                            )}
                        </div>
                    </div>
                ),
            },
        });
    }

    render() {
        return null;
    }
}
