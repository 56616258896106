// @flow
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import * as Actions from './actions';
import { SAVE_TEAM_SETTINGS } from './actions';
import type { $Collaborator, $PermissionFeature, $PermissionGroup } from './consts';
import {
    PREPARE_COLLABORATORS,
    PREPARE_PERMISSION_GROUPS,
    PREPARE_PERMISSIONS,
    PRIMARY_OWNER,
} from './consts';
import * as ProcessOut from '../../util/ProcessOut';
import type { $PermissionsState } from './reducer';
import { fetchCollaborators } from './CollaboratorsActions';
import { SAVE_PERMISSION_GROUPS } from './actions';
import uniqid from 'uniqid';

function* preparePermissions(): Generator<*, *, *> {
    try {
        yield put({ type: ProcessOut.typePending(PREPARE_PERMISSIONS) });

        // Retrieve permission features
        const permissionGroupDefaults = yield call(
            ProcessOut.APIcallPromise,
            '/permission-groups-defaults',
            'GET',
        );
        const permissionFeatures: Array<$PermissionFeature> = Object.keys(
            permissionGroupDefaults.data.permission_groups,
        ).map(feature => ({
            id: feature,
            ...permissionGroupDefaults.data.permission_groups[feature],
        }));

        // Retrieve collaborators
        const collaboratorsResult = yield put.resolve(fetchCollaborators());
        const collaborators: Array<
            $Collaborator,
        > = collaboratorsResult.value.data.collaborators.map(c => ({ ...c, id: uniqid() }));

        // Retrieve permission groups
        const permissionGroupsResult = yield put.resolve(Actions.fetchPermissionGroups());
        const permissionGroups: Array<
            $PermissionGroup,
        > = permissionGroupsResult.value.data.permission_groups.map(group => ({
            ...group,
            id: uniqid(),
        }));

        yield put({
            type: ProcessOut.typeFulfilled(PREPARE_COLLABORATORS),
            payload: { collaborators },
        });
        yield put({
            type: ProcessOut.typeFulfilled(PREPARE_PERMISSION_GROUPS),
            payload: {
                permissionGroups,
                permissionFeatures,
            },
        });
        yield put({
            type: ProcessOut.typeFulfilled(PREPARE_PERMISSIONS),
        });
    } catch (error) {
        yield put({ type: ProcessOut.typeFailed(PREPARE_PERMISSIONS), payload: error });
        Sentry.captureException(error);
    }
}

function* saveTeamSettings(): Generator<*, *, *> {
    try {
        const permissions: $PermissionsState = yield select(store => store.permissions);
        const fetchedCollaborators = permissions.collaborators.fetchedState.collaborators;
        const promises = [];

        for (const collab of permissions.collaborators.collaborators) {
            // Check if the collaborator already exists
            if (
                fetchedCollaborators &&
                fetchedCollaborators.find(
                    g =>
                        g.id === collab.id &&
                        (g.role !== collab.role ||
                            g.permission_group_name !== collab.permission_group_name),
                )
            ) {
                // Collaborator existed we update it
                promises.push(
                    call(
                        ProcessOut.APIcallPromise,
                        `/collaborators/${collab.user.email}`,
                        'PUT',
                        JSON.stringify({
                            role: collab.role,
                            permission_group_name: collab.permission_group_name,
                        }),
                    ),
                );
            } else if (
                fetchedCollaborators &&
                !fetchedCollaborators.find(g => g.id === collab.id)
            ) {
                // Collaborator doesn't exist we create it
                promises.push(
                    call(
                        ProcessOut.APIcallPromise,
                        `/collaborators`,
                        'POST',
                        JSON.stringify({
                            role: collab.role,
                            permission_group_name: collab.permission_group_name,
                            user_email: collab.user.email,
                        }),
                    ),
                );
            }
        }

        // Check if some collaborators were deleted
        if (fetchedCollaborators) {
            for (const collab of fetchedCollaborators) {
                if (!permissions.collaborators.collaborators.find(g => g.id === collab.id)) {
                    // Group was deleted
                    promises.push(
                        call(
                            ProcessOut.APIcallPromise,
                            `/collaborators/${collab.user.email}`,
                            'DELETE',
                        ),
                    );
                }
            }
        }

        yield all(promises);

        yield put({ type: ProcessOut.typeFulfilled(SAVE_TEAM_SETTINGS) });
        ProcessOut.addNotification('Team settings saved.', 'success');

        yield put(Actions.preparePermissions());
    } catch (error) {
        Sentry.captureException(error);
        yield put({ type: ProcessOut.typeFailed(SAVE_TEAM_SETTINGS), payload: { error } });
        ProcessOut.addNotification('Could not save team settings.');
    }
}

function* savePermissionGroups(): Generator<*, *, *> {
    try {
        const permissions: $PermissionsState = yield select(store => store.permissions);
        const fetchedPermissionGroups = permissions.permissionGroups.fetchedState.permissionGroups;
        const promises = [];
        // First we save permission groups

        for (const group of permissions.permissionGroups.permissionGroups) {
            // Check if the group already exists
            if (
                fetchedPermissionGroups &&
                fetchedPermissionGroups.find(g => g.name === group.name)
            ) {
                // Group existed we update it
                promises.push(
                    call(
                        ProcessOut.APIcallPromise,
                        `/permission-groups/${group.name}`,
                        'PUT',
                        JSON.stringify({ name: group.name, permissions: group.permissions }),
                    ),
                );
            } else {
                // Group doesn't exist we create it
                promises.push(
                    call(
                        ProcessOut.APIcallPromise,
                        `/permission-groups`,
                        'POST',
                        JSON.stringify({ name: group.name, permissions: group.permissions }),
                    ),
                );
            }
        }

        // Check if some groups were deleted
        if (fetchedPermissionGroups) {
            for (const group of fetchedPermissionGroups) {
                if (
                    !permissions.permissionGroups.permissionGroups.find(g => g.name === group.name)
                ) {
                    // Group was deleted
                    promises.push(
                        call(
                            ProcessOut.APIcallPromise,
                            `/permission-groups/${group.name}`,
                            'DELETE',
                        ),
                    );
                }
            }
        }

        yield all(promises);
        yield put({ type: ProcessOut.typeFulfilled(SAVE_PERMISSION_GROUPS) });
        ProcessOut.addNotification('Permission groups saved successfully', 'success');
        yield put(Actions.preparePermissions());
    } catch (error) {
        yield put({ type: ProcessOut.typeFailed(SAVE_PERMISSION_GROUPS) });
        Sentry.captureException(error);
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeLatest(PREPARE_PERMISSIONS, preparePermissions);
    yield takeLatest(SAVE_TEAM_SETTINGS, saveTeamSettings);
    yield takeLatest(SAVE_PERMISSION_GROUPS, savePermissionGroups);
}
