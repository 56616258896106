// @flow
/**
 * Created by jeremylejoux on 18/09/2017.
 */

import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import type { Moderator } from './consts';
import { NOT_ALLOWED, Notifications } from './consts';
import * as Actions from './actions';
import Serialize from 'form-serialize';
import * as ProcessOut from '../../util/ProcessOut';
import type { $Dispatcher } from '../../util/Types';
import { fetchCollaborators } from '../Permissions/CollaboratorsActions';
import type { $NotificationsState } from './reducer';
import NotPermitted from '../Permissions/NotPermitted';

type Props = {
    notifications: $NotificationsState,
    user: {
        email: string,
    },
} & $Dispatcher;

@connect(function(store) {
    return {
        notifications: store.notificationsSettings,
        user: store.user.details,
    };
})
export default class NotificationsSettings extends React.Component {
    props: Props;

    componentDidMount() {
        let { dispatch } = this.props;
        dispatch(Actions.prepareNotifications());
    }

    /*
     * called when the user clicks on "Save"
     */
    onSubmit(event: Event) {
        event.preventDefault();
        let { dispatch, notifications } = this.props;

        let data = Serialize(event.target, { hash: true });
        let unsubs = Actions.computeUnsubscriptions(data);

        // we update the moderator and refetch the moderators
        dispatch(Actions.updateNotificationsAction(notifications.collaborator, unsubs));
    }

    render() {
        let { notifications, user } = this.props;

        let content;
        let unsubs: Array<string> = [];

        if (notifications.fetching && !notifications.fetched) {
            content = <Loader />;
        } else if (notifications.error) {
            if (notifications.error.notPermitted) content = <NotPermitted />;
            else content = <Error />;
        } else if (notifications.fetched) {
            content = (
                <div class="row small-margin-bottom">
                    <form id="notifications-form" onSubmit={this.onSubmit.bind(this)}>
                        <div class="large-12 columns">
                            <div class="row small-margin-bottom">
                                <div class="large-12 columns">
                                    {Object.keys(Notifications).map(notification => {
                                        return (
                                            <NotificationBox
                                                key={notification}
                                                defaultChecked={
                                                    notifications.notifications.findIndex(notif => {
                                                        return notif === notification;
                                                    }) > -1
                                                }
                                                name={notification}
                                                description={Notifications[notification]}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div class="row small-margin-bottom">
                                <div class="medium-2 columns end">
                                    {notifications.saving ? (
                                        <Loader size={14} />
                                    ) : (
                                        <input
                                            type="submit"
                                            className="round button border small"
                                            value="Save"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            );
        }

        return (
            <div class="row">
                <div class="large-12 columns">
                    <div class="row">
                        <div class="large-3 columns">
                            <div class="row small-margin-bottom">
                                <div class="large-12 columns">
                                    <h5>Notifications</h5>
                                </div>
                            </div>
                            <div class="row small-margin-bottom">
                                <div class="large-12 columns">
                                    <p class="small-font greyed">
                                        Only affects your account (i.e. not the project)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="large-9 columns">{content}</div>
                    </div>
                </div>
            </div>
        );
    }
}

class NotificationBox extends React.Component {
    props: {
        defaultChecked: boolean,
        name: string,
        description: string,
    };

    render() {
        let { name, description, defaultChecked } = this.props;

        return (
            <div class="row small-margin-bottom">
                <div class="medium-12 columns">
                    <p style={{ margin: 0 }}>
                        <input
                            type="checkbox"
                            defaultChecked={defaultChecked}
                            name={name}
                            id={name}
                            style={{ marginBottom: 0 }}
                        />
                        <label for={name}>{description}</label>
                    </p>
                </div>
            </div>
        );
    }
}
