// @flow

import type { $Action, $State } from '../../util/Types';
import { REQUEST_DECLINE_EXPLANATION_FETCH } from './sagas';
import * as ProcessOut from '../../util/ProcessOut';
import { RESET_DECLINE_EXPLAINER } from './actions';

export type $DeclineExplainerState = {
    description: string,
    tag: '' | 'ml' | 'knowledge' | 'unknown',
} & $State;

const defaultState: $DeclineExplainerState = {
    fetching: false,
    fetched: false,
    error: false,
    description: '',
    tag: '',
};

export default function(
    state: $DeclineExplainerState = defaultState,
    action: $Action,
): $DeclineExplainerState {
    switch (action.type) {
        case REQUEST_DECLINE_EXPLANATION_FETCH: {
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: false,
            };
        }

        case ProcessOut.typeFulfilled(REQUEST_DECLINE_EXPLANATION_FETCH): {
            return {
                ...state,
                fetching: false,
                fetched: true,
                ...action.payload,
            };
        }

        case ProcessOut.typeFailed(REQUEST_DECLINE_EXPLANATION_FETCH): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: true,
            };
        }

        case RESET_DECLINE_EXPLAINER: {
            return {
                ...state,
                ...defaultState,
            };
        }
    }

    return state;
}
