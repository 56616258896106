// @flow
// $FlowFixMe
import './payouts.scss';
import React from 'react';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../util/Types';
import * as Actions from './actions';
import ContentLayout from '../../pages/ContentLayout';
import type { $PayoutsState } from './reducer';
import Loading from '../../components/Loader';
import Error from '../../components/Error';
import Payout from './Payout';
import Pagination from '../../components/Pagination';
import Empty from '../../components/Empty';
import NotPermitted from '../Permissions/NotPermitted';
import PayoutsExporter from '../Exports/PayoutsExporter';

const PAYOUTS_PER_PAGE = 10;

type Props = {
    payoutsDetails: $PayoutsState,
} & $Dispatcher;

class Payouts extends React.Component<Props> {
    componentDidMount(): void {
        const { dispatch } = this.props;
        dispatch(Actions.requestPayoutsFetch(PAYOUTS_PER_PAGE, false, null));
    }

    render() {
        const { payoutsDetails } = this.props;
        return (
            <ContentLayout
                title={
                    <div class='row'>
                        <div class='medium-6 columns'>
                            <h4>Payouts</h4>
                        </div>
                        <div class='medium-6 columns text-right'>
                            <PayoutsExporter />
                        </div>
                    </div>
                }
            >
                {payoutsDetails.fetching || !payoutsDetails.fetched ? (
                    <Loading />
                ) : payoutsDetails.error ? (
                    payoutsDetails.error.notPermitted ? (
                        <NotPermitted />
                    ) : (
                        <Error />
                    )
                ) : payoutsDetails.payouts.length > 0 ? (
                    <div>
                        <div className='row capitalize greyed bold small-margin-bottom'>
                            <div className='medium-1 columns' style={{ fontSize: '.8em' }}>
                                <span>Status</span>
                            </div>
                            <div className='medium-2 columns' style={{ fontSize: '.8em' }}>
                                <span>Amount</span>
                            </div>
                            <div className='medium-2 columns' style={{ fontSize: '.8em' }}>
                                <span>Fees</span>
                            </div>
                            <div className='medium-4 columns' style={{ fontSize: '.8em' }}>
                                <span>Gateway</span>
                            </div>
                            <div
                                className='medium-3 columns text-right'
                                style={{ fontSize: '.8em' }}
                            >
                                <span>Date</span>
                            </div>
                        </div>
                        {payoutsDetails.payouts.map(payout => (
                            <Payout key={payout.id} payout={payout} />
                        ))}
                    </div>
                ) : (
                    <div class='row' style={{ paddingTop: '2em' }}>
                        <div class='medium-12 columns'>
                            <Empty text='No payout available yet' />
                        </div>
                    </div>
                )}
                <Pagination
                    reloadFunction={Actions.requestPayoutsFetch.bind(null, PAYOUTS_PER_PAGE)}
                    itemsArray={payoutsDetails.payouts}
                    hasMore={payoutsDetails.has_more}
                    hide={payoutsDetails.fetching || !payoutsDetails.fetched}
                    location={this.props.location}
                />
            </ContentLayout>
        );
    }
}

export default connect(store => {
    return {
        payoutsDetails: store.payouts,
    };
})(Payouts);
