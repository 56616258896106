export default function(
    state = {
        fetching: true,
    },
    action,
) {
    switch (action.type) {
        case 'FETCH_EVENT_DETAILS_PENDING': {
            return {
                ...state,
                error: false,
                fetching: true,
                fetched: false,
            };
        }
        case 'FETCH_EVENT_DETAILS_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                event: action.payload.event,
            };
        }
        case 'FETCH_EVENT_DETAILS_REJECTED': {
            return {
                ...state,
                error: true,
                fetched: false,
                fetching: false,
            };
        }
    }

    return state;
}
