// @flow
/**
 * Created by jeremylejoux on 18/09/2017.
 */

import type { Moderator, $NotificationType } from './consts';
import { Notifications, UPDATE_NOTIFICATIONS_REQUESTED } from './consts';
import type { $Collaborator } from '../Permissions/consts';

/*
 * computeNotifications computes the activated notifications array
 */
export function computeNotifications(
    notificationUnsub: Array<$NotificationType>,
): Array<$NotificationType> {
    if (
        notificationUnsub.find(notif => {
            return notif === '*';
        })
    ) {
        return [];
    }

    let notifications = Object.keys(Notifications);
    for (let notif of notificationUnsub) {
        notifications = notifications.filter(notif => {
            return !notificationUnsub.includes(notif);
        });
    }

    return notifications;
}

type SubscriptionTuple = { [$NotificationType]: 'on' | 'off' };
/*
 * computeUnsubscriptions takes a array of active subscriptions and returns the invert
 */
export function computeUnsubscriptions(
    subscriptions: Array<SubscriptionTuple>,
): Array<$NotificationType> {
    let unsubs = Object.keys(Notifications);
    return unsubs.filter(notif => {
        return !subscriptions[notif];
    });
}

export function updateNotificationsAction(
    collaborator: $Collaborator,
    email_unsubscriptions: Array<$NotificationType>,
) {
    return {
        type: UPDATE_NOTIFICATIONS_REQUESTED,
        payload: {
            collaborator: collaborator,
            email_unsubscriptions: email_unsubscriptions,
        },
    };
}

export const REQUEST_PREPARE_NOTIFICATIONS = 'REQUEST_PREPARE_NOTIFICATIONS';
export const prepareNotifications = () => ({ type: REQUEST_PREPARE_NOTIFICATIONS });
