import * as ProcessOut from '../util/ProcessOut';

export function fetchRequests(length, after, id) {
    var bound = after ? 'start_after' : 'end_before';
    if (!id) {
        id = '';
    }

    return {
        type: 'FETCH_API_REQUESTS',
        payload: ProcessOut.APIcallPromise(
            `/api-requests?order=desc&${bound}=${id}&limit=${length}`,
            'GET',
        ),
    };
}

export function fetchRequest(id) {
    return {
        type: 'FETCH_API_REQUEST_DETAILS',
        payload: ProcessOut.APIcallPromise(`/api-requests/${id}`, 'GET'),
    };
}
