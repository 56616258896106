import React from 'react';

export default class Data extends React.Component {
    constructor() {
        super();
        this.state = {
            email: false,
            shipping: false,
        };
    }

    componentDidMount() {
        if (this.props.invoice == null) {
            return;
        }
        let request_email = this.props.invoice.metadata['request_email'] === 'true';
        let request_shipping = this.props.invoice.metadata['request_shipping'] === 'true';
        this.setState({ email: request_email, shipping: request_shipping });
    }

    emailChanged() {
        this.setState({
            email: !this.state.email,
        });
    }

    shippingChanged() {
        this.setState({
            shipping: !this.state.shipping,
        });
    }

    render() {
        var shipping = this.state.shipping;
        var email = this.state.email;

        return (
            <div class="row">
                <div class="large-12 medium-8 columns">
                    <div class="row">
                        <div class="large-12 columns clearfix">
                            <span class="left">
                                <label class="ios7-switch big" style={{ marginRight: '15px' }}>
                                    <input
                                        type="checkbox"
                                        checked={email}
                                        name="request_email"
                                        value="true"
                                        id="item-request-email"
                                        onChange={this.emailChanged.bind(this)}
                                    />
                                    <span />
                                </label>
                            </span>
                            <span class="left">Request customer email address</span>
                        </div>
                        <div class="large-12 columns clearfix">
                            <span class="left">
                                <label class="ios7-switch big" style={{ marginRight: '15px' }}>
                                    <input
                                        type="checkbox"
                                        checked={shipping}
                                        name="request_shipping"
                                        value="true"
                                        id="item-request-shipping"
                                        onChange={this.shippingChanged.bind(this)}
                                    />
                                    <span />
                                </label>
                            </span>
                            <span class="left">Request customer shipping address</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
