// @flow

import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import type { $Dispatcher, $State } from '../../util/Types';
import * as CurrencyAction from '../../Actions/CurrencyActions';

type Props = {
    currenciesDetails: {
        currencies: Array<{ code: string, value: number }>,
    } & $State,
    value: string,
    onChange: (code: string) => void,
} & $Dispatcher;

class CurrencySelector extends React.Component<Props> {
    componentDidMount() {
        const { dispatch, currenciesDetails } = this.props;
        if (!currenciesDetails.fetched && !currenciesDetails.error && !currenciesDetails.fetching)
            dispatch(CurrencyAction.loadCurrencies());
    }

    render() {
        const { value, currenciesDetails } = this.props;
        const options =
            currenciesDetails.fetched && !currenciesDetails.error
                ? currenciesDetails.currencies.map(c => ({ label: c.code, value: c.code }))
                : [];
        return (
            <div class='row'>
                <div class='large-12 columns text-left'>
                    <Select
                        value={{ value, label: value }}
                        clearable={false}
                        isLoading={currenciesDetails.fetching}
                        options={options}
                        onChange={value => {
                            this.props.onChange(value.value);
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default connect(store => ({ currenciesDetails: store.currencies }))(CurrencySelector);
