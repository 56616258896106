// @flow

import React from 'react';
import { takeLatest, put } from 'redux-saga/effects';
import { REQUEST_CONFIRMATION } from './consts';
import type { $RequestConfirmationAction } from './consts';
import * as Store from '../../stores/Store';

function* requestConfirmation(action: $RequestConfirmationAction): Generator<*, *, *> {
    const header = 'Confirmation';
    const content = action.payload.content;
    const footer = (
        <div class="row">
            <div class="large-12 columns text-right">
                <a
                    onClick={() => {
                        Store.store.dispatch({ type: 'CLOSE_MODAL' });
                        action.payload.reject();
                    }}
                    class="round border small button"
                >
                    cancel
                </a>
                <input
                    type="button"
                    onClick={() => {
                        Store.store.dispatch({ type: 'CLOSE_MODAL' });
                        action.payload.resolve();
                    }}
                    class="round small main button white-text"
                    value="Confirm"
                />
            </div>
        </div>
    );
    yield put({
        type: 'OPEN_MODAL',
        payload: {
            header: header,
            content: content,
            footer: footer,
        },
    });
}

export default function* watch(): Generator<*, *, *> {
    yield takeLatest(REQUEST_CONFIRMATION, requestConfirmation);
}
