// @flow
import React from 'react';
import type { $Filter, $Operand } from './consts';
import Operand from './Operand';
import PlottedField from '../../analytics/ChartPreviewer/PlottedField/PlottedField';
import UniqId from 'uniqid';
import {
    TRANSACTION_AMOUNT_FIELDS,
    TRANSACTION_FIELDS,
} from '../../analytics/ChartPreviewer/PlottedField/consts';
import { REQUEST_FETCH_VALUES_FOR_FIELD } from '../../SearchBar/consts';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../../util/Types';
import ValueSelection from './ValueSelection';
import Select from 'react-select';
import { RULES_FILTERS } from '../consts';
import Velocity from '../Velocity/Velocity';
import type { $ProviderCompareOption } from '../../Telescope/Components/ProviderCompareSelector';
import type { $VelocityRule } from '../consts';

type State = {
    filters: Array<$Filter>,
};

type Props = {
    onChange: (filters: Array<$Filter>) => void,
    defaultFilters?: ?Array<$Filter>,
    filterValues: any,
    options?: Array<{ label: string, value: string }>,
} & $Dispatcher;

class Filter extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            filters: [],
        };
    }

    componentDidMount() {
        const { defaultFilters } = this.props;
        if (defaultFilters) this.setState({ filters: defaultFilters });
    }

    addFilter = (event: Event) => {
        event.preventDefault();
        const filters = this.state.filters;
        filters.push({ id: UniqId(), path: '', operand: '==', value: '' });
        this.setState({ filters: filters });
        this.props.onChange(filters);
    };

    filterUpdated = (filter: $Filter) => {
        const filters = this.state.filters;
        const index = filters.findIndex(item => item.id === filter.id);
        if (filters[index].path !== 'velocity' && filter.path === 'velocity') {
            // Filter wasn't a velocity, we set default values
            filter.interval = '5m';
            filter.velocityPath = 'card_fingerprint';
        }
        filters[index] = filter;
        this.setState({ filters: filters });
        this.props.onChange(filters);
    };

    deleteFilter = (filterId: string) => {
        const { filters } = this.state;
        const newFilters = filters.filter(item => item.id !== filterId);
        this.setState({ filters: newFilters });
        this.props.onChange(newFilters);
    };

    render() {
        const { filters } = this.state;
        const emptyAddButton =
            filters.length === 0 ? (
                <div class='greyed'>
                    Only apply to specific transactions by{' '}
                    <a onClick={this.addFilter}>&#43; adding a filter</a>
                </div>
            ) : null;
        const addButton =
            filters.length !== 0 ? (
                <a class='add-button' onClick={this.addFilter}>
                    +
                </a>
            ) : null;

        // We need to mmrge all the possible fees for this dropdown as we also want _amount and _fee fields
        const options =
            this.props.options ||
            Object.keys(TRANSACTION_FIELDS)
                .map(field => {
                    return {
                        value: field,
                        label: field,
                        desc: TRANSACTION_FIELDS[field],
                    };
                })
                .concat(
                    Object.keys(TRANSACTION_AMOUNT_FIELDS).map(field => {
                        return {
                            value: field,
                            label: field,
                            desc: TRANSACTION_AMOUNT_FIELDS[field],
                        };
                    }),
                )
                .sort((a, b) => {
                    if (b.label > a.label) return -1;
                    return 1;
                });

        return (
            <div class='row'>
                {filters.length !== 0 ? (
                    <div class='large-1 columns' style={{ paddingTop: '.5em' }}>
                        where
                    </div>
                ) : null}
                <div class='large-12 columns'>
                    {emptyAddButton}
                    {filters.map((filter, index, array) => {
                        return (
                            <div class='row filter-row' key={filter.id}>
                                <div class='large-10 columns'>
                                    <FilterInput
                                        filterId={filter.id}
                                        key={filter.id}
                                        options={options}
                                        dispatch={this.props.dispatch}
                                        filterValues={this.props.filterValues}
                                        onChange={this.filterUpdated}
                                        defaultValue={filter}
                                        filter={filter}
                                    />
                                </div>
                                <div class='large-2 columns' style={{ paddingTop: '.5em' }}>
                                    {index === array.length - 1 ? addButton : null}
                                    <a
                                        onClick={this.deleteFilter.bind(this, filter.id)}
                                        className='remove-button'
                                    >
                                        −
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

class FilterInput extends React.Component<
    {
        filterId: string,
        filterValues: any,
        defaultValue?: ?$Filter | $VelocityRule,
        options: Array<$ProviderCompareOption>,
        onChange: $Filter => void,
    } & $Dispatcher,
    {
        path: string,
        operand: $Operand,
        value: Array<string | number | boolean> | string | number | boolean,
        velocityPath: ?string,
        interval: ?string,
        isMulti: boolean,
    },
> {
    constructor() {
        super();
        this.state = {
            path: '',
            operand: '==',
            value: [''],
            velocityPath: null,
            interval: null,
            isMulti: false,
        };
    }

    componentDidMount() {
        const { defaultValue } = this.props;
        if (defaultValue) {
            this.setState({
                path: defaultValue.path,
                operand: defaultValue.operand,
                value: defaultValue.value,
                velocityPath: defaultValue.velocityPath,
                interval: defaultValue.interval,
                isMulti:
                    (RULES_FILTERS[defaultValue.path] &&
                        RULES_FILTERS[defaultValue.path].isMulti) ||
                    (defaultValue.path.includes('metadata.') &&
                        (defaultValue.operand === '==' || defaultValue.operand === '!=')),
            });
        }
    }

    pathChanged = (newValue: string) => {
        this.props.dispatch({
            type: REQUEST_FETCH_VALUES_FOR_FIELD,
            payload: { document: 'transactions', field: newValue },
        });
        if (newValue === 'check_3ds') {
            this.setState({ path: newValue, value: true, isMulti: false }, () => {
                this.change();
            });
        } else {
            const correspondingValue = this.props.options.find(o => o.value === newValue);
            this.setState(
                {
                    path: newValue,
                    isMulti:
                        (correspondingValue && correspondingValue.isMulti) ||
                        newValue.includes('metadata'),
                },
                () => {
                    this.change();
                },
            );
        }
        this.valueWasSelected(null);
    };

    operandChanged = (newValue: $Operand) => {
        const isMulti =
            (this.state.isMulti || this.state.path.includes('metadata')) &&
            (newValue === '==' || newValue === '!=');
        this.setState(
            {
                operand: newValue,
                isMulti,
                value:
                    this.state.isMulti && !isMulti && this.state.value
                        ? [this.state.value[0]]
                        : this.state.value, // We switch from multi to non multi so we only keep the first array element as value
            },
            () => {
                this.change();
            },
        );
    };

    valueWasSelected = (newValue: ?Array<string>) => {
        this.setState({ value: newValue }, () => {
            this.change();
        });
    };

    change = () => {
        this.props.onChange({ ...this.state, id: this.props.filterId });
    };

    velocityChanged = (newFilter: $VelocityRule) => {
        this.setState(
            {
                velocityPath: newFilter.velocityPath,
                interval: newFilter.interval,
            },
            () => {
                this.change();
            },
        );
    };

    render() {
        return (
            <div class='row small-margin-bottom'>
                <div class='large-6 columns'>
                    <div class='row'>
                        <div class='medium-12 columns'>
                            <PlottedField
                                onChange={this.pathChanged}
                                options={this.props.options}
                                defaultValue={this.state.path}
                                allowMetadata={true}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='medium-12 columns'>
                            {this.state.path === 'velocity' && (
                                <Velocity
                                    filter={{ ...this.state }}
                                    onChange={(filter: $Filter) => {
                                        this.velocityChanged(filter);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {RULES_FILTERS[this.state.path] &&
                RULES_FILTERS[this.state.path].type === 'boolean' ? (
                    <div class='medium-3 columns end'>
                        <Select
                            options={[
                                { label: 'equal true', value: true },
                                { label: 'equal false', value: false },
                            ]}
                            clearable={false}
                            value={{
                                label: `equal ${this.state.value}`,
                                value: this.state.value,
                            }}
                            onChange={(value: { label: string, value: boolean }) => {
                                this.setState({ value: value.value }, () => {
                                    this.change();
                                });
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        <div class='large-2 columns'>
                            <Operand
                                onChange={this.operandChanged}
                                defaultValue={this.state.operand}
                                options={
                                    this.state.path === 'rand'
                                        ? [
                                              { label: '<', value: '<' },
                                              { label: '<=', value: '<=' },
                                              { label: '>', value: '>' },
                                              { label: '>=', value: '>=' },
                                          ]
                                        : this.state.isMulti &&
                                          !this.state.path.includes('metadata')
                                        ? [
                                              { label: '==', value: '==' },
                                              { label: '!=', value: '!=' },
                                          ]
                                        : [
                                              { label: '==', value: '==' },
                                              { label: '!=', value: '!=' },
                                              { label: '<', value: '<' },
                                              { label: '<=', value: '<=' },
                                              { label: '>', value: '>' },
                                              { label: '>=', value: '>=' },
                                              { label: 'is null', value: 'is-null' },
                                              { label: 'is not null', value: 'is-not-null' },
                                          ]
                                }
                            />
                        </div>
                        <div class='large-4 columns '>
                            {this.state.operand.includes('null') ? (
                                <div class='medium-4 columns end' />
                            ) : (
                                <ValueSelection
                                    onChange={this.valueWasSelected}
                                    options={this.props.filterValues[this.state.path]}
                                    value={this.state.value}
                                    isMulti={this.state.isMulti}
                                    lading={this.props.filterValues[this.state.path] !== null}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(store => {
    return { filterValues: store.searchBar };
})(Filter);
