import * as ProcessOut from '../util/ProcessOut';
import * as TransactionsActions from '../Actions/TransactionsActions';

export function createRefund(transactionId, refund, callback, error) {
    return function(dispatch) {
        dispatch({
            type: 'CREATE_REFUND',
            payload: refundCreationPRomise(transactionId, refund, dispatch).then(function(
                response,
            ) {
                if (response.data.success) callback();
                else error();
                return response;
            }),
        });
    };
}

function refundCreationPRomise(transactionId, refund, dispatch) {
    return new Promise((resolve, reject) => {
        ProcessOut.APIcallPromise(`/transactions/${transactionId}/refunds`, 'POST', refund).then(
            function(response) {
                Promise.all([
                    dispatch(getRefunds(transactionId)),
                    dispatch(TransactionsActions.loadTransactionDetails(transactionId)),
                ]);
                resolve(response);
            },
        );
    });
}

export function getRefunds(transactionid) {
    return {
        type: 'FETCH_REFUNDS',
        payload: ProcessOut.APIcallPromise(`/transactions/${transactionid}/refunds`, 'GET', null),
    };
}
