// @flow
import React from 'react';
import type { $Recommendation, $Surface } from './consts';
import Recommendation from './Recommendation';
import { connect } from 'react-redux';
import type { $Dispatcher, $Project } from '../../../util/Types';
import Empty from '../../../components/Empty';
import LineChart from '../../analytics/Charts/LineChart';
import uniqid from 'uniqid';
import RecoModal from './RecoModal';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Markdown from 'react-markdown';
import BarChart from '../../analytics/Charts/BarChart';
import Analytics from '../../../util/Analytics';
import TalkToExpertCTA from '../../TalkToExpertCTA/TalkToExpertCTA';

type Props = {
    surface: $Surface,
    recommendations: Array<$Recommendation>,
    currentProject: { project: $Project },
    user: {
        details: ?{
            email: string,
        },
    },
} & $Dispatcher;

type State = {
    selectedRecoId: string,
};

class Recommendations extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            selectedRecoId: '',
        };
    }

    selectReco = (recoId: string) => {
        this.setState({ selectedRecoId: recoId });
    };

    render() {
        const { surface, recommendations } = this.props;
        const currentReco = this.props.recommendations
            ? recommendations.find(reco => reco.id === this.state.selectedRecoId)
            : null;
        return (
            <div class='content opened'>
                <ReactCSSTransitionGroup
                    transitionName='reco-modal-animation'
                    transitionEnterTimeout={600}
                    transitionLeaveTimeout={450}
                >
                    {currentReco ? (
                        <RecoModal
                            key={uniqid()}
                            recommendation={currentReco}
                            onClose={() => {
                                this.setState({ selectedRecoId: '' });
                            }}
                            surface={surface}
                            currency={this.props.currentProject.project.default_currency || 'USD'}
                        />
                    ) : null}
                </ReactCSSTransitionGroup>
                <div class='row'>
                    <div class='large-12 columns'>
                        <div class='row small-margin-bottom'>
                            <div class='large-10 large-centered columns surface-description'>
                                <Markdown source={surface.description} />
                            </div>
                        </div>
                        <div class='row'>
                            <div class={`chart-container`}>
                                <div class='row reco-chart'>
                                    <div class='large-12 columns'>
                                        <div class='row'>
                                            <div class='large-12 columns text-center chart-title'>
                                                {surface.chart_name}
                                            </div>
                                        </div>
                                        <div class='row small-margin-bottom'>
                                            <div
                                                class='large-12 columns'
                                                style={{
                                                    height: '250px',
                                                }}
                                            >
                                                {(() => {
                                                    const props = {
                                                        data: [
                                                            {
                                                                key: 'Current performance',
                                                                datapoints: surface.datapoints.current_performance.filter(
                                                                    point => point._count > 0,
                                                                ),
                                                            },
                                                            {
                                                                key: 'Expected performance',
                                                                datapoints: (
                                                                    surface.datapoints
                                                                        .possible_gain ||
                                                                    surface.datapoints
                                                                        .threshold_performance
                                                                ).filter(point => point._count > 0),
                                                            },
                                                        ],
                                                        type: 'percentage',
                                                        colors: [
                                                            {
                                                                key: 'Current performance',
                                                                color: '#5c6bc0',
                                                            },
                                                            {
                                                                key: 'Expected performance',
                                                                color: 'rgba(192, 57, 43,.7)',
                                                            },
                                                        ],
                                                    };

                                                    if (surface.chart_type === 'bar-chart')
                                                        return <BarChart {...props} />;
                                                    else if (surface.chart_type === 'line-chart')
                                                        return <LineChart {...props} />;
                                                })()}
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='row'>
                                                <div class='large-6 columns text-right'>
                                                    <div class='surface-chart-legend surface' />
                                                    Current performance
                                                </div>
                                                <div class='large-6 columns '>
                                                    <div class='surface-chart-legend benchmark' />
                                                    Expected performance
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class={`reco-container`}>
                                <div class='recos'>
                                    {!surface.associated_recommendations ||
                                    surface.associated_recommendations.length === 0 ? (
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                {surface.suggested_fixes || (
                                                    <Empty text='Telescope did not find any recommendation for this issue.' />
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div class='row'>
                                            <div class={`large-12 columns`}>
                                                <div class='row greyed small-margin-bottom'>
                                                    <div class='large-12 columns'>
                                                        Possible actions
                                                    </div>
                                                </div>
                                                <div class='row small-margin-bottom'>
                                                    <div class='large-12 columns'>
                                                        {recommendations.map(reco => {
                                                            const selected =
                                                                reco.id ===
                                                                this.state.selectedRecoId;

                                                            return (
                                                                <RecoContainer
                                                                    id={reco.id}
                                                                    key={reco.id}
                                                                    preSelected={false}
                                                                    selected={selected}
                                                                    onSelect={this.selectReco}
                                                                >
                                                                    <Recommendation
                                                                        onSelect={this.selectReco.bind(
                                                                            null,
                                                                            reco.id,
                                                                        )}
                                                                        recommendation={reco}
                                                                        currency={
                                                                            this.props
                                                                                .currentProject
                                                                                .project
                                                                                .default_currency
                                                                        }
                                                                        selected={selected}
                                                                    />
                                                                </RecoContainer>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <div class='row'>
                                                    <div class='medium-12 columns text-center'>
                                                        <TalkToExpertCTA
                                                            surface={surface.name}
                                                            email={
                                                                this.props.user.details
                                                                    ? this.props.user.details.email
                                                                    : null
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { currentProject: store.currentProject, user: store.user };
})(Recommendations);

class RecoContainer extends React.Component<{
    id: string,
    preSelected: boolean,
    selected: boolean,
    onSelect: string => void,
    children?: ?any,
}> {
    render() {
        if (this.props.preSelected || this.props.selected) {
            return <div>{this.props.children}</div>;
        } else {
            return (
                <a
                    onClick={() => {
                        this.props.onSelect(this.props.id);
                    }}
                >
                    {this.props.children}
                </a>
            );
        }
    }
}
