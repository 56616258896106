// @flow

import type { $Action } from '../../../../../util/Types';
import { CLOSE_CHART_POPUP, OPEN_CHART_POPUP } from './consts';

type State = {
    chartId: ?string,
    onFilter: ?() => void,
    onList: ?() => void,
};

const defaultState = {
    chartId: null,
    onFilter: null,
    onList: null,
};

export default function(state: State = defaultState, action: $Action): State {
    switch (action.type) {
        case OPEN_CHART_POPUP: {
            if (
                !action.payload ||
                !action.payload.onFilter ||
                !action.payload.onList ||
                !action.payload.chartId
            )
                return state;
            return {
                ...state,
                ...action.payload,
            };
        }

        case CLOSE_CHART_POPUP: {
            return {
                ...state,
                chartId: null,
                onFilter: null,
                onList: null,
            };
        }
    }

    return state;
}
