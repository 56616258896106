// @flow
import React from 'react';
import type { $PermissionGroup } from './consts';
import Permission from './Permission';
import ContentLayout from '../../pages/ContentLayout';
import * as Actions from './actions';
import type { $Dispatcher } from '../../util/Types';
import { connect } from 'react-redux';
import Delete from '../../components/Delete';

type Props = {
    permissionGroupName: string,
    permissions: $PermissionsState,
} & $Dispatcher;
import uniqid from 'uniqid';
import Error from '../../components/Error';
import PermissionFeature from './PermissionFeature';
import type { $PermissionGroupsState } from './PermissionGroupsReducer';
import type { $PermissionsState } from './reducer';
class PermissionGroup extends React.Component<Props, {
        collapsed: boolean,
    }> {

    constructor() {
        super();
        this.state = {
            collapsed: true,
        };
    }

    deleteGroup = () => {
        const { dispatch, permissionGroupName } = this.props;
        dispatch(Actions.deletePermissionGroup(permissionGroupName));
    };

    render() {
        const { permissions, permissionGroupName } = this.props;
        const permissionGroup = permissions.permissionGroups.permissionGroups.find(
            g => g.name === permissionGroupName,
        );

        const fetchedState = permissions.permissionGroups.fetchedState;
        
        if (!permissionGroup) return <Error />;
        const { collapsed } = this.state;
        return (
            <div
                class={`row ${collapsed ? 'box-row clickable' : ''} permission-group`}
                onClick={() => {
                    if (!collapsed) return;
                    this.setState({ collapsed: !collapsed });
                }}
            >
                <div class="medium-12 columns">
                    <div class={`row ${!collapsed ? 'small-margin-bottom' : ''}`}>
                        <div class="medium-6 columns">
                            {collapsed || (fetchedState.permissionGroups && fetchedState.permissionGroups.find(g => g.name === permissionGroupName))? (
                                <div style={{ fontSize: '1.2em' }}>{permissionGroup.name}</div>
                            ) : (
                                <input
                                    type={'text'}
                                    value={permissionGroup.name}
                                    onChange={event => {
                                        const { dispatch } = this.props;
                                        dispatch(
                                            Actions.updateGroupName(
                                                permissionGroup.id || '',
                                                event.target.value,
                                            ),
                                        );
                                    }}
                                />
                            )}
                        </div>
                        <div class="medium-5 columns text-right">
                            <a
                                onClick={() => {
                                    this.setState({ collapsed: !collapsed });
                                }}
                            >
                                {collapsed ? 'Edit' : 'Collapse'}
                            </a>
                        </div>
                        <div class="medium-1 columns text-right">
                            <Delete onDelete={this.deleteGroup} />
                        </div>
                    </div>
                    {!collapsed && (
                        <PermissionGroupContent permissions={permissions} group={permissionGroup} />
                    )}
                </div>
            </div>
        );
    }
}

export default connect(store => ({
    permissions: store.permissions,
}))(PermissionGroup);

class PermissionGroupContent extends React.Component<{
        permissions: $PermissionsState,
        group: $PermissionGroup,
    }> {
    render() {
        const { permissions, group } = this.props;

        return (
            <div class="row">
                <div class="medium-4 columns">
                    <h5 class="greyed">This group can</h5>
                </div>
                <div class="medium-8 columns">
                    <div class="row small-margin-bottom greyed">
                        <div class="medium-4 columns">URL</div>
                        <div class="medium-8 columns">
                            <div class="row">
                                <div class="medium-6 columns text-center">Read</div>
                                <div class="medium-6 columns text-center">Edit</div>
                            </div>
                        </div>
                    </div>
                    {permissions.permissionGroups.permissionFeatures
                        .sort((a, b) => (b.name < a.name ? 1 : -1))
                        .map(feature => (
                            <PermissionFeature
                                key={`${group.name}—${feature.id}`}
                                feature={feature}
                                permissionGroupName={group.name}
                                permissions={group.permissions.allow}
                            />
                        ))}
                </div>
            </div>
        );
    }
}
