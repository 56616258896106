// @flow
import { takeEvery, takeLatest, put, select, call } from 'redux-saga/effects';
import { INIT_ANALYTICS_DATES, UPDATE_ANALYTICS_DATES } from './consts';
import { push } from 'react-router-redux';
import type { $UpdateDatesAction } from './consts';
import qs from 'qs';
import type { $Action } from '../../../util/Types';
import moment from 'moment';

function* initDates(action: $Action): Generator<*, *, *> {
    const location = yield select(store => store.routing.locationBeforeTransitions);
    const { from, to } = location.query;
    if (from && to) {
        yield call(updateDates, {
            payload: { fromDate: moment.unix(from), toDate: moment.unix(to) },
        });
    }
}

export const ANALYTICS_INTERVAL_CHANGE = 'ANALYTICS_INTERVAL_CHANGE';
function* updateDates(action: $UpdateDatesAction): Generator<*, *, *> {
    const { fromDate, toDate } = action.payload;
    yield put({
        type: ANALYTICS_INTERVAL_CHANGE,
        payload: {
            interval: {
                from: fromDate.unix(),
                to: toDate.unix(),
            },
            success: true,
        },
    });

    const location = yield select(store => store.routing.locationBeforeTransitions);

    const query = {
        ...location.query,
        from: fromDate.unix(),
        to: toDate.unix(),
    };
    yield put(push(location.pathname + '?' + qs.stringify(query)));
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeLatest(INIT_ANALYTICS_DATES, initDates);
    yield takeEvery(UPDATE_ANALYTICS_DATES, updateDates);
}
