// @flow
import type { $PermissionFeature, $PermissionGroup } from './consts';
import type { $Action, $State } from '../../util/Types';
import {
    ADD_PERMISSION_GROUP,
    DELETE_PERMISSION_GROUP,
    REMOVE_PERMISSION_FROM_GROUP,
    SAVE_PERMISSION_GROUPS,
    SET_COLLABORATOR_PERMISSION_GROUP,
    SET_PERMISSION_GROUP_VALUES,
    UPDATE_GROUP_NAME,
} from './actions';
import { newPermissionGroup, PREPARE_PERMISSION_GROUPS } from './consts';
import * as ProcessOut from '../../util/ProcessOut';
import type { $DeletePermissionGroupAction, $UpdateGroupNameAction } from './actions';
import uniqid from 'uniqid';

export type $PermissionGroupsState = {
    permissionFeatures: Array<$PermissionFeature>,
    permissionGroups: Array<$PermissionGroup>,
    fetchedState: {
        permissionGroups: ?Array<$PermissionGroup>,
    },
    saving: boolean,
} & $State;

const defaultState: $PermissionGroupsState = {
    fetching: false,
    fetched: false,
    error: null,
    permissionFeatures: [],
    permissionGroups: [],
    fetchedState: {
        permissionGroups: null,
    },
    saving: false,
};

export default function(
    state: $PermissionGroupsState = defaultState,
    action: $UpdateGroupNameAction | $DeletePermissionGroupAction,
): $PermissionGroupsState {
    switch (action.type) {
        case PREPARE_PERMISSION_GROUPS: {
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: null,
                fetchedState: {
                    permissionGroups: null,
                },
            };
        }

        case ProcessOut.typeFulfilled(PREPARE_PERMISSION_GROUPS): {
            return {
                ...state,
                fetching: false,
                fetched: true,
                ...action.payload,
                fetchedState: {
                    permissionGroups: action.payload.permissionGroups,
                },
            };
        }

        case SAVE_PERMISSION_GROUPS: {
            return {
                ...state,
                saving: true,
            };
        }

        case ProcessOut.typeFulfilled(SAVE_PERMISSION_GROUPS): {
            return {
                ...state,
                saving: false,
            };
        }

        case ProcessOut.typeFailed(SAVE_PERMISSION_GROUPS): {
            return {
                ...state,
                saving: false,
                permissionGroups: state.fetchedState.permissionGroups
                    ? state.fetchedState.permissionGroups.slice(0)
                    : state.permissionGroups,
            };
        }

        case SET_PERMISSION_GROUP_VALUES: {
            const { payload } = action;
            const permissionGroupIndex = state.permissionGroups.findIndex(
                g => g.name === payload.groupName,
            );
            if (permissionGroupIndex < 0) return state;

            const permissions =
                state.permissionGroups[permissionGroupIndex].permissions[payload.category];
            if (!permissions) return state;

            const newPermissions = permissions.slice(0);
            const urlIndex = newPermissions.findIndex(p => p.id === payload.feature.id);
            if (urlIndex < 0)
                newPermissions.push({ action: payload.action, id: payload.feature.id });
            else newPermissions[urlIndex].action = payload.action;

            const newPermissionGroups = state.permissionGroups.slice(0);
            newPermissionGroups[permissionGroupIndex].permissions[
                payload.category
            ] = newPermissions;
            return {
                ...state,
                permissionGroups: newPermissionGroups,
                edited: true,
            };
        }

        case REMOVE_PERMISSION_FROM_GROUP: {
            const { payload } = action;

            const permissionGroupIndex = state.permissionGroups.findIndex(
                g => g.name === payload.groupName,
            );
            if (permissionGroupIndex < 0) return state;

            const permissions =
                state.permissionGroups[permissionGroupIndex].permissions[payload.category];
            if (!permissions) return state;

            const newPermissions = permissions.slice(0);
            const permIndex = newPermissions.findIndex(p => p.id === payload.feature.id);
            if (permIndex < 0) return state;

            newPermissions.splice(permIndex, 1);
            const newPermissionGroups = state.permissionGroups.slice(0);
            newPermissionGroups[permissionGroupIndex].permissions[
                payload.category
            ] = newPermissions;

            return {
                ...state,
                permissionGroups: newPermissionGroups,
                edited: true,
            };
        }

        case ADD_PERMISSION_GROUP: {
            const newGroup: $PermissionGroup = newPermissionGroup();

            const newGroupsList = state.permissionGroups.slice();
            newGroupsList.push(newGroup);

            return {
                ...state,
                permissionGroups: newGroupsList,
                edited: true,
            };
        }

        case DELETE_PERMISSION_GROUP: {
            const { payload } = action;
            const groups = state.permissionGroups.slice();
            const index = groups.findIndex(g => g.name === payload.name);
            if (index < 0) return state;
            groups.splice(index, 1);
            return {
                ...state,
                permissionGroups: groups,
                edited: true,
            };
        }

        case UPDATE_GROUP_NAME: {
            const { payload } = action;
            const groups = state.permissionGroups.slice(0);
            const index = groups.findIndex(g => g.id === payload.id);
            if (index < 0) return state;
            groups[index].name = payload.name;
            return {
                ...state,
                permissionGroups: groups,
            };
        }
    }
    return state;
}
