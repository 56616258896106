// @flow

import moment from 'moment';

export function abbreviateNumber(number: number): string {
    const SI_POSTFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    const tier = (Math.log10(Math.abs(number)) / 3) | 0;
    if (tier === 0) return number.toString();
    const postfix = SI_POSTFIXES[tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = number / scale;
    let formatted = scaled.toFixed(1) + '';
    if (/\.0$/.test(formatted)) formatted = formatted.substr(0, formatted.length - 2);
    return formatted + postfix;
}

export function parseDates(
    start: number,
    to: number,
): { from: number, to: number, timespan: string, timezone: string } {
    start = start ? start : Date.now() / 1000;
    to = to ? to : Date.now() / 1000;
    const startDate = moment.unix(start);
    const endDate = moment.unix(to);
    let timespan = 'daily';

    if (endDate.diff(startDate, 'hours') < 72) {
        timespan = 'hourly';
    }

    if (endDate.diff(startDate, 'days') > 31) {
        timespan = 'weekly';
    }

    if (endDate.diff(startDate, 'days') > 90) {
        timespan = 'monthly';
    }

    if (startDate.isSame(endDate)) {
        startDate.set('hour', 0);
        endDate.set('hour', 23);
        start = startDate.unix();
        to = endDate.unix();
    }

    return {
        from: start,
        to: to,
        timespan: timespan,
        timezone: moment().format('Z'),
    };
}

export function computeMapChartColor(minAmount: number, maxAmount: number, data: any): any {
    return lerpColor('#9fa8da', '#304ffe', data.country.value / (maxAmount - minAmount));
}

/**
 * A linear interpolator for hexadecimal colors
 * @param {String} a
 * @param {String} b
 * @param {Number} amount
 * @example
 * // returns #7F7F7F
 * lerpColor('#000000', '#ffffff', 0.5)
 * @returns {String}
 */
function lerpColor(a, b, amount) {
    let ah = parseInt(a.replace(/#/g, ''), 16),
        ar = ah >> 16,
        ag = (ah >> 8) & 0xff,
        ab = ah & 0xff,
        bh = parseInt(b.replace(/#/g, ''), 16),
        br = bh >> 16,
        bg = (bh >> 8) & 0xff,
        bb = bh & 0xff,
        rr = ar + amount * (br - ar),
        rg = ag + amount * (bg - ag),
        rb = ab + amount * (bb - ab);

    return '#' + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1);
}
