// @flow

// $FlowFixMe
import './ratingwidget.scss';
// $FlowFixMe
import './stars.scss';
import React from 'react';
import Autosize from 'autosize';
import type { $Rating } from './sagas';
import type { $Dispatcher } from '../../util/Types';
import * as Actions from './actions';
import { connect } from 'react-redux';
import Loading from '../../components/Loader';
import uniqid from 'uniqid';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

type State = {
    opened: boolean,
};

type Props = {
    pageName: string,
} & $Dispatcher;

class RatingWidget extends React.Component {
    state: State;
    props: Props;
    constructor() {
        super();
        this.state = {
            opened: false,
        };
    }

    render() {
        const { opened } = this.state;
        const { pageName, dispatch } = this.props;
        return (
            <div class="rating-widget">
                <a
                    onClick={() => {
                        this.setState({ opened: true });
                    }}
                >
                    <img
                        src={'//dashboard.processout.com/images/messenger.png'}
                        className="messenger-img"
                    />
                    Feedback or questions?
                </a>
                <ReactCSSTransitionGroup
                    transitionName={'rating-widget-anim'}
                    transitionEnterTimeout={300}
                    transitionLeaveTimeout={150}
                >
                    {opened ? (
                        <ConnectedOpenedWidget
                            key={uniqid()}
                            close={() => {
                                this.setState({ opened: false });
                            }}
                            pageName={pageName}
                        />
                    ) : null}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

class OpenedWidget extends React.Component {
    _widget: any;
    _textarea: any;
    props: {
        close: () => void,
        pageName: string,
    } & $Dispatcher;
    state: {
        rating: number,
        feedback: string,
        sent: boolean,
        sending: boolean,
    };

    constructor() {
        super();
        this.state = {
            rating: 3,
            sent: false,
            sending: false,
            feedback: '',
        };
    }

    componentDidMount(): void {
        // Register event listener for outside click
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        // Unregister event listener for outside click
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleOutsideClick = (event: Event) => {
        const { close } = this.props;
        if (this._widget && !this._widget.contains(event.target)) {
            close();
        }
    };

    handleOptionChange = changeEvent => {
        this.setState({
            rating: parseInt(changeEvent.target.value),
        });
    };

    handleFeedbackChange = event => {
        this.setState({
            feedback: event.target.value,
        });
    };

    registerFeedback = () => {
        const { dispatch, pageName } = this.props;
        const rating: $Rating = {
            rating: this.state.rating,
            message: this.state.feedback,
            page: pageName,
            email: '',
        };
        this.setState({ sending: true, sent: false });
        dispatch(
            Actions.registerRating(rating, success => {
                this.setState({ sending: false, sent: success });
            }),
        );
    };

    render() {
        const { rating, feedback, sent, sending } = this.state;

        return (
            <div class="opened-widget" ref={e => (this._widget = e)}>
                <div class="row small-margin-bottom">
                    <div class="medium-12 columns">
                        <label style={{ fontSize: '1.05em' }}>
                            How useful do you find this page?
                        </label>
                        <fieldset className="rating">
                            <input
                                type="radio"
                                id="star5"
                                name="rating"
                                value={5}
                                onChange={this.handleOptionChange}
                                checked={rating === 5}
                            />
                            <label className="full" htmlFor="star5" title="Awesome - 5 stars" />

                            <input
                                type="radio"
                                id="star4"
                                name="rating"
                                value={4}
                                checked={rating === 4}
                                onChange={this.handleOptionChange}
                            />
                            <label className="full" htmlFor="star4" title="Pretty good - 4 stars" />

                            <input
                                type="radio"
                                id="star3"
                                name="rating"
                                value={3}
                                checked={rating === 3}
                                onChange={this.handleOptionChange}
                            />
                            <label className="full" htmlFor="star3" title="Meh - 3 stars" />

                            <input
                                type="radio"
                                id="star2"
                                name="rating"
                                value={2}
                                checked={rating === 2}
                                onChange={this.handleOptionChange}
                            />
                            <label className="full" htmlFor="star2" title="Kinda bad - 2 stars" />
                            <input
                                type="radio"
                                id="star1"
                                name="rating"
                                value={1}
                                checked={rating === 1}
                                onChange={this.handleOptionChange}
                            />
                            <label
                                className="full"
                                htmlFor="star1"
                                title="Sucks big time - 1 star"
                            />
                        </fieldset>
                    </div>
                </div>
                <div className="row small-margin-bottom">
                    <div className="medium-12 columns">
                        <textarea
                            ref={e => (this._textarea = e)}
                            rows={1}
                            style={{ margin: 0 }}
                            onKeyDown={event => {
                                const el = this._textarea;
                                Autosize(el);
                                event.persist();
                            }}
                            value={feedback}
                            onChange={this.handleFeedbackChange}
                            placeHolder={'What would you like to see on this page?'}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="medium-12 columns text-right">
                        {sent ? (
                            <span>Thank you!</span>
                        ) : sending ? (
                            <div class="text-right" style={{ width: '5em', marginLeft: 'auto' }}>
                                <Loading size={8} />
                            </div>
                        ) : (
                            <a
                                style={{ marginBottom: 0 }}
                                className="round main button small"
                                onClick={this.registerFeedback}
                            >
                                Send
                            </a>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="medium-12 columns">
                        <div class="separator" />
                        <div
                            class="greyed"
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                margin: '0 .5em 0 .5em',
                            }}
                        >
                            or
                        </div>
                        <div class="separator" />
                    </div>
                </div>
                <div class="row">
                    <div class="medium-12 columns text-center">
                        <a
                            class="chat-button"
                            onClick={() => {
                                // $FlowFixMe
                                Intercom('update', {
                                    hide_default_launcher: false,
                                });
                                Intercom('showNewMessage');
                            }}
                        >
                            Chat with us!
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

const ConnectedOpenedWidget = connect()(OpenedWidget);

export default RatingWidget;
