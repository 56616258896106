// @flow

import type { $Action } from '../../util/Types';
import { FETCH_GWAY_CONF } from './consts';
import * as ProcessOut from '../../util/ProcessOut';

export function fetchGwayConfNames(): $Action {
    return {
        type: FETCH_GWAY_CONF,
        payload: ProcessOut.APIcallPromise('/helpers/gateway-configurations/names', 'GET'),
    };
}
