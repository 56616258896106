import React from 'react';
import ContentLayout from './ContentLayout';
import * as InvoicesActions from '../Actions/InvoicesActions';
import Name from '../components/InvoiceEdit/Name';
import Prices from '../components/InvoiceEdit/Prices';
import Data from '../components/InvoiceEdit/Data';
import Metadata from '../components/Metadata';
import Loader from '../components/Loader';
import * as CurrencyActions from '../Actions/CurrencyActions';
import * as ProcessOut from '../util/ProcessOut';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

var serialize = require('form-serialize');

@connect(store => {
    return {
        invoiceDetails: store.invoiceDetails,
        currenciesDetails: store.currencies,
    };
})
export default class InvoiceEdit extends React.Component {
    static contextTypes = {
        store: PropTypes.object.isRequired,
    };

    componentWillMount() {
        this.created = this.created.bind(this);
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Products | ProcessOut';
        this.id = this.props.params.invoice;
        this.create = this.id == 'create';
        if (this.create) {
            this.props.dispatch({ type: 'START_INVOICE_CREATION' });
        }
        this.recurring = this.id == 'create-recurring' && this.props.params.customer;

        if (!this.create && !this.recurring) {
            this.props.dispatch(InvoicesActions.loadInvoice(this.id));
        }
        this.setState({
            create: this.create,
            recurring: this.recurring,
            customer: this.props.params.customer,
        });
        this.props.dispatch(CurrencyActions.loadCurrencies());
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.invoiceDetails.fetching && !nextProps.invoiceDetails.fetched) {
            if (!this.create && !this.recurring) {
                this.props.dispatch(InvoicesActions.loadInvoice(this.id));
            }
        }
    }

    created() {
        ProcessOut.addNotification.bind(this)('Invoice created successfuly', 'success');
        if (!this.state.recurring) {
            this.props.dispatch(
                this.props.history.push('/projects/' + ProcessOut.ProjectId + '/products'),
            );
        } else {
            this.props.dispatch(
                this.props.history.push(
                    '/projects/' + ProcessOut.ProjectId + '/customers/' + this.state.customer,
                ),
            );
        }
    }

    constructor() {
        super();
        this.state = {
            create: false,
            recurring: false,
            showDetails: false,
        };
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    save(event) {
        event.preventDefault();
        var invoiceForm = document.querySelector('#invoice-form');
        var data = serialize(invoiceForm, { hash: true });
        if (data.request_email == 'true') data.request_email = true;
        else if (!data.request_email) data.request_email = false;
        if (data.request_shipping == 'true') data.request_shipping = true;
        else if (!data.request_shipping) data.request_shipping = false;
        if (data.interval_days) {
            data.interval = data.interval_days + 'd';
        }

        if (this.state.create && !data.metadata) data.metadata = {};

        var metas = this._meta.updateValue();
        for (var i = 0; i < metas.length; i++) {
            data.metadata[metas[i].key] = metas[i].value;
        }

        if (data.metadata && Object.keys(data.metadata).length == 0) {
            delete data.metadata;
        }
        if (this.state.recurring) {
            data.customer_id = this.state.customer;
        }

        data.amount = parseFloat(data.amount);
        if (data.metadata && data.metadata.shipping_amount)
            data.amount += parseFloat(data.metadata.shipping_amount);
        if (data.metadata && data.metadata.taxes_amount)
            data.amount += parseFloat(data.metadata.taxes_amount);
        var invoice = JSON.stringify(data);
        if (this.state.create) {
            this.props.dispatch(InvoicesActions.createInvoice(invoice, this.created.bind(this)));
        } else if (this.state.recurring) {
            this.props.dispatch(
                InvoicesActions.createRecurringInvoice(invoice, this.created.bind(this)),
            );
        } else {
            this.props.dispatch(
                InvoicesActions.editInvoice(invoice, this.props.invoiceDetails.invoice.id),
            );
        }
    }

    render() {
        const invoice = this.props.invoiceDetails.invoice;

        var submitButton = 'Create';
        if (!this.state.create) {
            submitButton = 'Save';
        }
        if (
            this.props.currenciesDetails.fetching ||
            (!this.state.create && !this.props.invoiceDetails.fetched && !this.state.recurring)
        ) {
            return (
                <ContentLayout title={<h4>Products</h4>}>
                    <Loader />
                </ContentLayout>
            );
        }

        const desc = 'Products are a great way to send payment request to your customer.';

        return (
            <ContentLayout
                title={<h4>{this.state.recurring ? 'Subscription' : 'Products'}</h4>}
                description={
                    this.state.create || this.state.recurring ? (
                        ''
                    ) : (
                        <a
                            href={`https://checkout.processout.com/t-${invoice.id}`}
                            target='_blank'
                            class='greyed'
                        >
                            https://checkout.processout.com/t-{invoice.id}
                        </a>
                    )
                }
            >
                <div class='row margin-bottom'>
                    <div class='large-12 columns'>
                        <form id='invoice-form' onSubmit={this.save.bind(this)}>
                            <div class='row small-margin-bottom'>
                                <div
                                    class='medium-5 medium-centered columns'
                                    style={{
                                        backgroundColor: '#ecf0f1',
                                        padding: '25px',
                                    }}
                                >
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            <h6 class='margin-bottom capitalize greyed font-console'>
                                                Details
                                            </h6>
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            <Name
                                                invoice={invoice}
                                                recurring={this.state.recurring}
                                            />
                                            <Prices
                                                invoice={invoice}
                                                currencies={this.props.currenciesDetails.currencies}
                                            />
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            <hr />
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            <h6 class='margin-bottom capitalize greyed font-console'>
                                                Additional data
                                            </h6>
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            <Metadata
                                                ref={m => (this._meta = m)}
                                                resource={invoice}
                                            />
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            <hr />
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            <h6 class='margin-bottom capitalize greyed font-console'>
                                                KYC handling
                                            </h6>
                                        </div>
                                    </div>
                                    <div class='row margin-bottom'>
                                        <div class='large-12 columns'>
                                            <Data invoice={invoice} />
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns text-center'>
                                            <button type='submit' class='round border button'>
                                                {submitButton}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </ContentLayout>
        );
    }
}
