// @flow
import Moment from 'moment';
import LocalStorage from 'local-storage';
import type { $Action } from '../../../util/Types';
import { UPDATE_TOKENS } from '../../../features/NetworkManager/consts';

type State = {
    loggedIn: boolean,
    fetching: boolean,
    token: {
        token: string,
        expires_at: number,
    },
    refreshToken: {
        token: string,
        expires_at: number,
    },
};

const defaultState: State = {
    loggedIn:
        (LocalStorage('token') && validateTokenExpiry(LocalStorage('token-expiry'))) ||
        (LocalStorage('refresh-token') &&
            validateTokenExpiry(LocalStorage('refresh-token-expiry'))),
    fetching: false,
    token: {
        token: LocalStorage('token'),
        expires_at: LocalStorage('token-expiry'),
    },
    refreshToken: {
        token: LocalStorage('refresh-token'),
        expires_at: LocalStorage('refresh-token-expiry'),
    },
};

export default function(state: State = defaultState, action: $Action): State {
    switch (action.type) {
        case 'LOG_USER_IN_PENDING': {
            return {
                ...state,
                loggedIn: false,
                fetching: true,
                token: {
                    token: '',
                    expires_at: 0,
                },
                refreshToken: {
                    token: '',
                    expires_at: 0,
                },
            };
        }

        case UPDATE_TOKENS: {
            if (!action.payload) return state;
            return {
                ...state,
                token: {
                    token: action.payload.token.token,
                    expires_at: action.payload.token.expires_at,
                },
                refreshToken: {
                    token: action.payload.token.refresh_token,
                    expires_at: action.payload.token.refresh_token_expires_at,
                },
            };
        }
        case 'LOG_USER_IN_FULFILLED': {
            if (!action.payload) return state;
            return {
                ...state,
                loggedIn: action.payload.waiting_for_two_factor !== true,
                fetching: false,
                token: {
                    token: action.payload.token.token,
                    expires_at: action.payload.token.expires_at,
                },
                refreshToken: {
                    token: action.payload.token.refresh_token,
                    expires_at: action.payload.token.refresh_token_expires_at,
                },
            };
        }
        case 'LOG_USER_IN_REJECTED': {
            if (!action.payload) return state;
            if (action.payload.waiting_for_two_factor) {
                return {
                    ...state,
                    fetching: false,
                    waiting_for_two_factor: true,
                };
            }
            return {
                ...state,
                loggedIn: false,
                fetching: false,
                token: {
                    token: '',
                    expires_at: 0,
                },
                refreshToken: {
                    token: '',
                    expires_at: 0,
                },
            };
        }

        case 'LOGOUT': {
            return {
                ...state,
                fetching: false,
                loggedIn: false,
                token: {
                    token: '',
                    expires_at: 0,
                },
                refreshToken: {
                    token: '',
                    expires_at: 0,
                },
            };
        }
    }

    return state;
}

function validateTokenExpiry(date) {
    return Moment().isBefore(Moment.unix(date));
}
