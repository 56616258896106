/**
 * Created by jeremylejoux on 08/08/2017.
 */

import { typeFulfilled, typePending } from '../../../util/ProcessOut';
import { TELESCOPE_FETCH_HISTORY } from '../../../features/Telescope/Sagas/sagas';

export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case typePending(TELESCOPE_FETCH_HISTORY): {
            return {
                ...state,
                error: false,
                fetching: true,
                fetched: false,
            };
        }

        case typeFulfilled(TELESCOPE_FETCH_HISTORY): {
            return {
                ...state,
                ...action.payload,
                fetched: true,
                fetching: false,
            };
        }
    }

    return state;
}
