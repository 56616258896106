import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router';
import { IntlProvider, ReactIntl } from 'react-intl';
import { Provider } from 'react-redux';
import * as ProcessOutRouter from './util/Router';
import * as store from './stores/Store';
import '../VendorStyles/styles.scss';
import ErrorBoundary from './features/ErrorBoundary/index';
import moment from 'moment';

const appElement = document.getElementById('app');

class App extends React.Component {
    locale = navigator ? navigator.language || 'en-US' : 'en-US';

    componentDidMount() {
        moment.locale(this.locale);
    }

    render() {
        return (
            <Provider store={store.store}>
                <IntlProvider locale={this.locale}>
                    <ErrorBoundary>
                        <Router history={store.history}>{ProcessOutRouter.Routes}</Router>
                    </ErrorBoundary>
                </IntlProvider>
            </Provider>
        );
    }
}

render(<App {...appElement.dataset} />, appElement);

if (module.hot) {
    module.hot.accept();
}
