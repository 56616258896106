// @flow
import React from 'react';
import type { $Dispatcher } from '../../util/Types';
import * as Actions from './actions';
import { connect } from 'react-redux';
import type { $WebhooksState } from './reducer';
import Hook from './Hook';
import NotPermitted from '../Permissions/NotPermitted';
import Error from '../../components/Error';
var serialize = require('form-serialize');

type Props = {
    webhooksDetails: $WebhooksState,
} & $Dispatcher;

class Webhooks extends React.Component<Props> {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(Actions.fetchWebhooks());
    }

    create(event) {
        const { dispatch } = this.props;
        event.preventDefault();
        const invoiceForm = document.querySelector('#hook-form');
        const data = serialize(invoiceForm, { hash: true });
        dispatch(Actions.createWebhook(data.url));
    }

    render() {
        const { webhooksDetails } = this.props;
        if (webhooksDetails.fetching || !webhooksDetails.fetched) return null;
        if (webhooksDetails.error) {
            if (webhooksDetails.error.notPermitted) return <NotPermitted />;
            return <Error />;
        }
        let hooksText;
        let hooks = <p class='margin-bottom'>You havent set any webhook endpoint yet.</p>;
        if (webhooksDetails.webhook_endpoints && webhooksDetails.webhook_endpoints.length > 0) {
            var tmp = 'one webhook endpoint';
            if (webhooksDetails.webhook_endpoints.length > 1)
                tmp = webhooksDetails.webhook_endpoints.length + ' webhook endpoints';
            hooksText = <p>You currently have {tmp}:</p>;
            hooks = webhooksDetails.webhook_endpoints.map(
                function(hook) {
                    return <Hook hook={hook} key={hook.id} />;
                }.bind(this),
            );
        }

        return (
            <div>
                <div class='large-3 medium-4 columns'>
                    <h5>Webhook endpoints</h5>
                    <p class='greyed smaller0-8'>
                        Get programmatically notified directly on your servers using{' '}
                        <a href='http://www.webhooks.org/'>webhooks</a>.
                    </p>
                </div>
                <div class='large-9 medium-8 columns'>
                    <div class='row'>
                        <div class='large-12 columns'>
                            <form id='hook-form' onSubmit={this.create.bind(this)}>
                                <div class='row'>
                                    <div class='large-10 medium-9 columns'>
                                        <input
                                            type='text'
                                            name='url'
                                            class='bottom-border'
                                            placeholder='https://your.site/webhook'
                                            required
                                        />
                                    </div>
                                    <div class='large-2 medium-9 columns text-right'>
                                        <button
                                            type='submit'
                                            class='button expanded small round border'
                                        >
                                            Create
                                        </button>
                                    </div>
                                </div>
                                <div class='row' />
                            </form>
                        </div>
                    </div>
                    <div class='row greyed'>
                        <div class='large-12 columns'>
                            {hooksText}
                            {hooks}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => ({ webhooksDetails: store.webhooks }))(Webhooks);
