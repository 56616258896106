// @flow

import type { $Action, $State } from '../../util/Types';

export type $VaultState = {
    cards: Array<$Card>,
} & $State;

const defaultState: $VaultState = {
    fetching: false,
    fetched: false,
    error: undefined,
    cards: [],
};

export default function(state: $VaultState = defaultState, action: $Action): $VaultState {
    switch (action.type) {
        case 'FETCH_VAULT_CARDS_PENDING': {
            return {
                ...state,
                ...defaultState,
                fetching: true,
                fetched: false,
            };
        }

        case 'FETCH_VAULT_CARDS_FULFILLED': {
            return {
                ...state,
                ...action.payload,
                fetching: false,
                fetched: true,
            };
        }

        case 'FETCH_VAULT_CARDS_REJECTED': {
            return {
                ...state,
                error: action.payload,
                fetched: false,
                fetching: false,
            };
        }
    }

    return state;
}
