// @flow

import type { $Payout } from './reducer';
import type { $Action, $State } from '../../util/Types';
import { REQUEST_PAYOUT_DETAILS_FETCH, REQUEST_PAYOUT_ITEMS_FETCH } from './actions';
import * as ProcessOut from '../../util/ProcessOut';

export type $PayoutItem = {
    id: string,
    amount: string,
    created_at: string,
    fees: string,
    type: 'sale' | 'refund' | 'chargeback' | 'fee' | 'adjustment' | 'reserve',
    payout_id: string,
    project_id: string,
    transaction_id: ?string,
    gateway_resource_id: string,
};

export type $PayoutDetailsState = {
    payout: ?$Payout,
    items: {
        items: Array<$PayoutItem>,
        has_more: boolean,
        count: number,
        limit: number,
        order: string,
    } & $State,
} & $State;

const defaultState: $PayoutDetailsState = {
    fetched: false,
    fetching: false,
    error: null,
    payout: null,
    items: {
        has_more: false,
        limit: 20,
        order: 'desc',
        count: 0,
        items: [],
        fetched: false,
        fetching: false,
        error: null,
    },
};

export default function(
    state: $PayoutDetailsState = defaultState,
    action: $Action,
): $PayoutDetailsState {
    switch (action.type) {
        case REQUEST_PAYOUT_DETAILS_FETCH: {
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: null,
                payout: null,
            };
        }

        case ProcessOut.typeFulfilled(REQUEST_PAYOUT_DETAILS_FETCH): {
            const { payload } = action;
            if (!payload) return state;
            return {
                ...state,
                fetched: true,
                fetching: false,
                ...payload,
            };
        }

        case ProcessOut.typeFailed(REQUEST_PAYOUT_DETAILS_FETCH): {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: action.payload,
            };
        }

        case REQUEST_PAYOUT_ITEMS_FETCH: {
            return {
                ...state,
                items: {
                    ...state.items,
                    fetching: true,
                    fetched: false,
                    error: null,
                    items: [],
                },
            };
        }

        case ProcessOut.typeFulfilled(REQUEST_PAYOUT_ITEMS_FETCH): {
            return {
                ...state,
                items: {
                    ...state.items,
                    fetched: true,
                    fetching: false,
                    ...action.payload,
                },
            };
        }

        case ProcessOut.typeFailed(REQUEST_PAYOUT_ITEMS_FETCH): {
            return {
                ...state,
                items: {
                    ...state.items,
                    fetching: false,
                    fetched: true,
                    error: action.payload,
                },
            };
        }
    }

    return state;
}
