// @flow
import { takeLatest, put, call, select, all, takeEvery } from 'redux-saga/effects';
import type { $Action } from '../../../util/Types';
import type { $BoardCreatorState, $BoardPreset } from './reducer';
import type { $BoardPresetSaveRequest, $RequestBoardPreviewAction } from './actions';
import * as ChartActions from '../ChartPreviewer/actions';
import type { $Board, $Chart, $DataChart, $FetchParams } from '../Boards/consts';
import { REQUEST_BOARD_PRESET_SAVE, REQUEST_BOARD_PREVIEW, SELECT_BOARD_PRESET } from './actions';
import * as ProcessOut from '../../../util/ProcessOut';
import { requestBoardsFetch } from '../Boards/actions';
import * as BoardsActions from '../Boards/actions';
import * as Actions from './actions';
import * as Sentry from '@sentry/browser';

function* previewBoard(action: $RequestBoardPreviewAction): Generator<*, *, *> {
    try {
        yield put({ type: ProcessOut.typePending(REQUEST_BOARD_PREVIEW) });
        const { payload } = action;
        if (!payload) throw new Error('Payload is null. Should contain a board preset');

        const board: $BoardPreset = payload.board;
        const params: $FetchParams = yield select(store => store.analytics.params);

        const chartsPromises = board.charts.map(chart =>
            put.resolve(
                ChartActions.fetchPreviewedChart(
                    { ...chart, preview: true },
                    'board_default-sales',
                    params,
                ),
            ),
        );
        const chartsResults = yield all(chartsPromises);
        // Will contain all the fetched charts
        const fetchedCharts: Array<{ chart: $Chart, data: $DataChart }> = chartsResults.map(
            result => result.value.data,
        );
        yield put({
            type: ProcessOut.typeFulfilled(REQUEST_BOARD_PREVIEW),
            payload: {
                previewedCharts: fetchedCharts,
            },
        });
    } catch (error) {
        yield put({ type: ProcessOut.typeFailed(REQUEST_BOARD_PREVIEW), payload: { error } });
        Sentry.captureException(error);
    }
}

function* saveBoardPreset(action: $BoardPresetSaveRequest): Generator<*, *, *> {
    try {
        yield put({ type: ProcessOut.typePending(REQUEST_BOARD_PRESET_SAVE) });
        const currentProject = yield select(store => store.currentProject);
        const fetchParams: $FetchParams = yield select(store => store.analytics.params);
        const preset: $BoardPreset = action.payload.preset;
        const boardResponse = yield call(
            ProcessOut.APIcallPromise,
            '/boards',
            'POST',
            JSON.stringify({ name: preset.name, description: '' }),
        );

        const board: $Board = boardResponse.data.board;

        const promises = preset.charts.map(chart =>
            call(
                ProcessOut.APIcallPromise,
                `/boards/${board.id}/charts`,
                'POST',
                JSON.stringify(chart),
            ),
        );

        yield all(promises);

        // We update the store with the newly created board
        yield put.resolve(BoardsActions.requestBoardsFetch(board.id, true));
        yield put({ type: ProcessOut.typeFulfilled(REQUEST_BOARD_PRESET_SAVE) });
    } catch (error) {
        yield put({
            type: ProcessOut.typeFailed(REQUEST_BOARD_PRESET_SAVE),
            payload: { error },
        });
        Sentry.captureException(error);
    }
}

function* selectBoardPresetWatcher(): Generator<*, *, *> {
    try {
        const boardCreator: $BoardCreatorState = yield select(store => store.boardCreator);
        if (!boardCreator.selectedPreset) throw new Error('No selected preset to preview');
        yield put(Actions.requestBoardPreview(boardCreator.selectedPreset));
    } catch (error) {
        Sentry.captureException(error);
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeLatest(REQUEST_BOARD_PREVIEW, previewBoard);
    yield takeEvery(REQUEST_BOARD_PRESET_SAVE, saveBoardPreset);
    yield takeLatest(SELECT_BOARD_PRESET, selectBoardPresetWatcher);
}
