// @flow

import React from 'react';
import { FormattedNumber, ReactIntl } from 'react-intl';
import { connect } from 'react-redux';
import InvoiceModal from './InvoiceModal';
import type { Product } from '../types/Product';

@connect(function(store) {
    return {};
})
export default class Invoice extends React.Component {
    props: {
        invoice: Product,
    };

    state: {
        showDetails: boolean,
    };

    _modal: ?Object = null;

    constructor() {
        super();
        this.state = {
            showDetails: false,
        };
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    displayInvoice(event: Event) {
        event.preventDefault();
        if (this._modal) this._modal.getWrappedInstance().openModal();
    }

    render() {
        const invoice = this.props.invoice;

        let desc = 'No KYC collected during checkout';
        if (
            invoice.metadata['request_email'] === 'true' &&
            invoice.metadata['request_shipping'] === 'true'
        ) {
            desc = 'Request email and shipping address during checkout';
        } else if (invoice.metadata['request_email'] === 'true') {
            desc = 'Request email during checkout';
        } else if (invoice.metadata['request_shipping'] === 'true') {
            desc = 'Request shipping during checkout';
        }

        return (
            <div>
                <InvoiceModal
                    modalName='Edit product'
                    invoice={invoice}
                    ref={m => (this._modal = m)}
                />
                <div class='box-row row padding'>
                    <div class='medium-10 columns'>
                        <a onClick={this.displayInvoice.bind(this)}>
                            <div class='row'>
                                <div class='medium-2 columns'>
                                    <div class='big-font'>
                                        <span class='greyed'>{invoice.currency}</span>{' '}
                                        <FormattedNumber
                                            value={invoice.amount}
                                            style='currency'
                                            currency={invoice.currency}
                                        />
                                    </div>
                                </div>
                                <div class='large-5 medium-4 columns'>{invoice.name}</div>
                                <div class='large-5 medium-6 columns greyed'>{desc}</div>
                            </div>
                        </a>
                    </div>
                    <div class='medium-2 expanded columns text-right'>
                        <a target='_blank' href={invoice.url}>
                            Payment link ↗
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
