export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case 'FETCH_DISCOUNTS_PENDING': {
            state = {
                ...state,
                fetched: false,
                fetching: true,
            };
            return state;
        }

        case 'FETCH_DISCOUNTS_FULFILLED': {
            state = {
                ...state,
                fetched: true,
                fetching: false,
                discounts: action.payload.discounts,
            };
            return state;
        }
    }

    return state;
}
