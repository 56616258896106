// @flow

import { takeLatest, put, call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import {
    CLEAR_NOTIFICATION,
    DISPATCH_NOTIFICATION,
    DISPLAY_ERROR,
    REQUEST_CLEAR_NOTIFICATION,
} from './consts';
import UniqueId from 'uniqid';
import type { $ErrorContext } from '../ErrorContext/consts';

export type $ErrorAction = {
    type: string,
    payload: {
        message: string,
        errorContext: $ErrorContext,
        type: string,
    },
};
export function* addError(action: $ErrorAction): Generator<*, *, *> {
    const { errorContext } = action.payload;
    if (errorContext) {
        const currentUrl = yield select(store => {
            return store.errorContext.currentUrl;
        });
        if (currentUrl && currentUrl !== errorContext.contextUrl) return;
    }

    /**
     * generate a unique id for this notification
     * this will allow us to clear only this notification
     */
    const id = UniqueId();

    // dispatch the notification
    yield put({
        type: DISPATCH_NOTIFICATION,
        payload: {
            ...action.payload,
            id: id,
        },
    });

    // we wait 7s before clearing it
    yield call(delay, 7000);

    // clear this notification
    yield put({ type: CLEAR_NOTIFICATION, payload: { id: id } });
}

export type $ClearErrorAction = {
    type: string,
    payload: {
        id: string,
    },
};
export function* clearError(action: $ClearErrorAction): Generator<*, *, *> {
    const currentErrorId: string = yield select(store => {
        return store.notification.id;
    });
    if (!action.payload || !action.payload.id || action.payload.id === currentErrorId)
        yield put({ type: CLEAR_NOTIFICATION });
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeLatest(DISPLAY_ERROR, addError);
    yield takeLatest(REQUEST_CLEAR_NOTIFICATION, clearError);
    yield takeLatest(action => {
        return new RegExp(/^@@router\/\w*/).test(action.type);
    }, clearError);
}
