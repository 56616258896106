// @flow
import React from 'react';
import type { $RouterLocation } from '../../util/Types';
import SearchBuilder from '../SearchBuilder/SearchBuilder';
import uniqid from 'uniqid';
import type { $Filter } from '../analytics/DataExplorer/ChartBuilder/Filters/consts';
import * as Utils from '../SearchBuilder/Utils';
import SearchBar from './SearchBar';

type Props = {
    defaultFilter?: ?string,
    onChange: string => void, // The state is kept in this component but the output (string filter) is dispatched to the parent
    applyFunc: (reset: boolean, filter: string) => void,
};

type State = {
    filters: Array<$Filter>,
    appliedFilters: Array<$Filter>,
};

class SearchSwitcher extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            filters: [],
            appliedFilters: [],
        };
    }

    saveFilters = () => {
        this.setState({ appliedFilters: this.state.filters });
        this.props.onChange(Utils.computeFilterStringFromArray(this.state.filters));
    };

    render() {
        return (
            <div class='row'>
                <div className='medium-3 columns'>
                    <SearchBuilder
                        value={this.state.filters}
                        document={'transactions'}
                        onChange={filters => {
                            this.setState({ filters: filters });
                        }}
                        onSave={this.saveFilters}
                        modified={
                            !Utils.areFiltersArraysEquals(
                                this.state.appliedFilters,
                                this.state.filters,
                            )
                        }
                    />
                </div>
                <div className='medium-9 columns' style={{ position: 'relative', top: '-.1em' }}>
                    <SearchBar
                        defaultFilter={this.props.defaultFilter}
                        document={'transactions'}
                        applyFunc={this.props.applyFunc}
                    />
                </div>
            </div>
        );
    }
}

export default SearchSwitcher;
