import * as ProcessOut from '../util/ProcessOut';
import * as CouponsActions from '../Actions/CouponsActions';
import { EventTypes } from 'redux-segment';

export function loadInvoices(length, after, id, filter) {
    filter = filter || '';
    var bound = after ? 'start_after' : 'end_before';
    if (!id) {
        id = '';
    }

    return {
        type: 'FETCH_INVOICES',
        payload: ProcessOut.APIcallPromise(
            `/products?order=desc&${bound}=${id}&limit=${length}&filter=${filter}`,
            'GET',
            null,
        ),
    };
}

export function loadRecurringInvoices(id, filter, length, after, itemId) {
    if (!filter) {
        filter = '';
    }
    var bound = after ? 'start_after' : 'end_before';
    if (!itemId) {
        itemId = '';
    }

    return {
        type: `FETCH_RECURRING_INVOICES${filter !== '' ? '_NORELOAD' : ''}`,
        payload: ProcessOut.APIcallPromise(
            `/customers/${id}/subscriptions?expand=customer&${bound}=${itemId}&order=desc&limit=${length}&filter=${filter}`,
            'GET',
            null,
        ),
    };
}

export function loadInvoice(id) {
    return {
        type: 'FETCH_INVOICE_DETAILS',
        payload: ProcessOut.APIcallPromise(`/products/${id}`, 'GET', null),
    };
}

export function createInvoice(invoice, resolve) {
    return function(dispatch) {
        let invoiceData = JSON.parse(invoice);
        dispatch({
            type: 'CREATE_INVOICE',
            payload: createInvoicePromise(invoice, resolve, dispatch).catch(error => {
                resolve(error.details);
            }),
            meta: {
                analytics: {
                    eventType: EventTypes.track,
                    eventPayload: {
                        properties: {
                            name: invoiceData.name,
                            amount: invoiceData.amount,
                            currency: invoiceData.currency,
                        },
                    },
                },
            },
        });
    };
}

export function createSingleInvoice(invoice, resolve, error) {
    return {
        type: 'CREATE_SINGLE_INVOICE',
        payload: ProcessOut.APIcallPromise('/invoices', 'POST', invoice).then(function(response) {
            if (response.data.success) resolve(response.data.invoice);
            else error();
            return response;
        }),
    };
}

export function chargeSingleInvoice(invoice, source, resolve, error) {
    return {
        type: 'CHARGE_SINGLE_INVOICE',
        payload: ProcessOut.APIcallPromise(
            `/invoices/${invoice}/capture`,
            'POST',
            JSON.stringify({ source: source }),
        ).then(function(response) {
            if (response.data.success) resolve();
            else error();
            return response;
        }),
    };
}

function createInvoicePromise(invoice, resolve, dispatch) {
    return ProcessOut.APIcallPromise('/products', 'POST', invoice).then(function(response) {
        if (response.data.success) {
            dispatch(loadInvoices());
        }
        resolve(response.data);
        return response;
    });
}

export function createRecurringInvoice(invoice, couponId, resolve) {
    var invoiceData = JSON.parse(invoice);
    return function(dispatch) {
        dispatch({
            type: 'CREATE_RECURRING_INVOICE',
            payload: ProcessOut.APIcallPromise('/subscriptions', 'POST', invoice).then(function(
                response,
            ) {
                if (response.data.success) {
                    dispatch(loadRecurringInvoices(invoiceData.customer_id));
                    if (couponId) {
                        dispatch(
                            CouponsActions.applyCoupon(response.data.subscription.id, couponId),
                        );
                    }
                    resolve(response.data);
                }
                return response;
            }),
        });
    };
}

export function deleteRecurringInvoice(customer, invoice) {
    return function(dispatch) {
        dispatch({
            type: 'DELETE_RECURRING_INVOICE',
            payload: ProcessOut.APIcallPromise(`/subscriptions/${invoice}`, 'DELETE', null).then(
                function(response) {
                    if (response.data.success) {
                        Promise.all([dispatch(loadRecurringInvoices(customer))]);
                    }
                    return response;
                },
            ),
        });
    };
}

export function editInvoice(invoice, id, resolve) {
    return function(dispatch) {
        dispatch({
            type: 'EDIT_INVOICE',
            payload: ProcessOut.APIcallPromise(`/products/${id}`, 'PUT', invoice).then(function(
                response,
            ) {
                if (response.data.success) {
                    Promise.all([dispatch(loadInvoices())]);
                }
                resolve(response.data);
                return response;
            }),
        });
    };
}

export function deleteInvoice(id) {
    return function(dispatch) {
        dispatch({
            type: 'DELETE_INVOICE',
            payload: ProcessOut.APIcallPromise(`/products/${id}`, 'DELETE', null).then(function(
                response,
            ) {
                if (response.data.success) {
                    Promise.all([dispatch(loadInvoices())]);
                }
                return response;
            }),
        });
    };
}
