// @flow
import React from 'react';

type Props = {
    onChange: ('bar' | 'line') => void,
    value: 'bar' | 'line',
};

class ChartSwitch extends React.Component<Props> {
    render() {
        return (
            <div class='chart-switch'>
                <img
                    src={'//dashboard.processout.com/images/horizontal-bar-chart.png'}
                    style={{ opacity: this.props.value === 'bar' ? 1 : 0.4 }}
                />
                <div class='switch-container'>
                    <label class='switch'>
                        <input
                            type='checkbox'
                            checked={this.props.value === 'line'}
                            onClick={() => {
                                const newValue = this.props.value === 'bar' ? 'line' : 'bar';
                                this.props.onChange(newValue);
                            }}
                        />{' '}
                        <div />
                    </label>
                </div>
                <img
                    src={'//dashboard.processout.com/images/line-chart.png'}
                    style={{ opacity: this.props.value === 'line' ? 1 : 0.4 }}
                />
            </div>
        );
    }
}

export default ChartSwitch;
