// @flow

import type { Action } from '../../Types/Action';
import type { State } from '../../Types/State';

export default function(
    state: State = {
        fetched: false,
        fetching: false,
    },
    action: Action,
) {
    switch (action.type) {
        case 'FETCH_CUSTOMERS_PENDING': {
            return {
                ...state,
                error: false,
                fetching: true,
                fetched: false,
            };
        }
        case 'FETCH_CUSTOMERS_FULFILLED': {
            if (action.payload && !action.payload.success) {
                return {
                    ...state,
                    fetching: false,
                    fetched: true,
                    customers: [],
                    total_count: [],
                };
            }

            return {
                ...state,
                ...action.payload,
                fetching: false,
                fetched: true,
            };
        }
        case 'FETCH_CUSTOMERS_REJECTED': {
            return {
                ...state,
                fetched: false,
                fetching: false,
                error: action.payload,
            };
        }
    }

    return state;
}
