import * as ProcessOut from '../../util/ProcessOut';

export default function(
    state = {
        fetching: false,
        fetched: false,
    },
    action,
) {
    switch (action.type) {
        case 'FETCH_REFUNDS_PENDING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
            };
        }
        case 'FETCH_REFUNDS_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                refunds: action.payload.refunds,
            };
        }
        case 'CREATE_REFUND_PENDING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
            };
        }
        case 'CREATE_REFUND_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
            };
        }
    }

    return state;
}
