// @flow

class Project {
    _id: string;
    _userRole: string;
    _defaultCurrency: string;
    _name: string;

    constructor(id: string, userRole: ?string, defaultCurrency: ?string, name: ?string) {
        this._id = id;
        if (userRole) this._userRole = userRole;
        if (defaultCurrency) this._defaultCurrency = defaultCurrency;
        if (name) this._name = name;
    }

    isSandbox(): boolean {
        return new RegExp(/^test-.*/).test(this._id);
    }
}

export default Project;
