// @flow

import type { $Filter } from '../analytics/DataExplorer/ChartBuilder/Filters/consts';
import { RULES_FILTERS } from '../RoutingRules/consts';

export function computeFilterStringFromArray(filters: Array<$Filter>): string {
    return filters
        .filter(
            filter => filter.path && (filter.operand.includes('null') || filter.value.length > 0),
        )
        .map((filter, index, array) => {
            return `${array.length > 1 ? '(' : ''}${filter.value.reduce(
                (acc, value) => `${acc}${acc && ' OR '}${filter.path} ${filter.operand} "${value}"`,
                '',
            )}${array.length > 1 ? ')' : ''}`;
        })
        .reduce((result, filter) => `${result}${result && ' AND '}${filter}`, '');
}

/**
 * Compare two filters arrays. Returns true if equals
 * @param arr1
 * @param arr2
 */
export function areFiltersArraysEquals(arr1: Array<$Filter>, arr2: Array<$Filter>): boolean {
    // compare lengths - can save a lot of time
    if (arr1.length !== arr2.length) return false;

    for (let i = 0, l = arr1.length; i < l; i++) {
        // Check if we have nested arrays
        if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
            // recurse into the nested arrays
            if (!arr1[i].equals(arr2[i])) return false;
        } else if (
            !arr1[i] ||
            !arr2[i] ||
            arr1[i].path !== arr2[i].path ||
            arr1[i].operand !== arr2[i].operand
        ) {
            return;
        } else if (arr1[i] && arr2[i]) {
            if (arr1[i].value.length !== arr2[i].value.length) return false;
            for (let j = 0, h = arr1[i].value.length; j < h; j++) {
                if (arr1[i].value[j] !== arr2[i].value[j]) return false;
            }
        }
    }
    return true;
}
