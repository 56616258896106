// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { $DataExplorerState } from './reducer';
import type { $Dispatcher } from '../../../util/Types';
import * as Actions from './actions';
import type { $PresetCategory } from './consts';

type Props = {
    dataExplorer: $DataExplorerState,
} & $Dispatcher;

class PresetCategorySelector extends React.Component {
    props: Props;

    selectPresetCategory = (category: $PresetCategory) => {
        this.props.dispatch(Actions.setSelectedPresetCategory(category));
    };

    render() {
        const { dataExplorer } = this.props;
        return (
            <div
                class={`large-3 columns presets-categories-container${dataExplorer.presetsSearch
                    ? ' search-active'
                    : ''}`}
            >
                <div
                    class={`row category${dataExplorer.selectedPresetCategory === 'business-intel'
                        ? ' active'
                        : ''}`}
                    onClick={() => {
                        this.selectPresetCategory('business-intel');
                    }}
                >
                    <div class="large-12 columns">Business</div>
                </div>
                <div
                    class={`row category${dataExplorer.selectedPresetCategory ===
                    'payment-performance'
                        ? ' active'
                        : ''}`}
                    onClick={() => {
                        this.selectPresetCategory('payment-performance');
                    }}
                >
                    <div class="large-12 columns">Payment Performance</div>
                </div>
                <div
                    class={`row category${dataExplorer.selectedPresetCategory === 'payment-fees'
                        ? ' active'
                        : ''}`}
                    onClick={() => {
                        this.selectPresetCategory('payment-fees');
                    }}
                >
                    <div class="large-12 columns">Payment Fees</div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { dataExplorer: store.dataExplorer };
})(PresetCategorySelector);
