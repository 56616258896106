import { combineReducers } from 'redux';
import Reducer from '../../../features/Telescope/reducer';
import Analytics from './AnalyticsReducer';
import History from './HistoryReducer';
import type { $TelescopeStateType } from '../../../features/Telescope/reducer';

const dataReducer = combineReducers({
    standard: Reducer,
    analytics: Analytics,
    history: History,
});

export type $TelescopeState = {
    standard: $TelescopeStateType,
};

export default dataReducer;
