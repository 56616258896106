import React from 'react';
import CardSchemeImage from '../../util/CardSchemeImage';
import { FormattedDate } from 'react-intl';
import CardModal from '../CardModal';
import GatewayConfModal from '../GatewayConfModal';
import PropTypes from 'prop-types';

export default class Token extends React.Component {
    static propTypes = {
        token: PropTypes.object.isRequired,
    };

    openModal(event) {
        event.preventDefault();
        if (this.props.token.type !== 'card') this._gatewayModal.getWrappedInstance().openModal();
        else this._modal.getWrappedInstance().openModal();
    }

    render() {
        const token = this.props.token;

        var cnt;
        if (token.type !== 'card') {
            cnt = (
                <div class='row'>
                    <div class='medium-3 columns'>
                        <div className='transaction-processor'>
                            <img
                                src={token.gateway_configuration.gateway.logo_url}
                                style={{ left: '0' }}
                            />
                        </div>
                    </div>
                    <div class='medium-9 columns greyed'>{token.gateway_configuration.name}</div>
                </div>
            );
        } else {
            cnt = (
                <div class='row'>
                    <div class='medium-3 columns'>
                        <div class='row'>
                            <div class='medium-6 columns'>{CardSchemeImage(token.card)}</div>
                            <div class='medium-6 columns font-console'>
                                {token.card.last_4_digits}
                            </div>
                        </div>
                    </div>
                    <div className='medium-9 columns'>
                        <span class={!token.card.name ? 'greyed' : ''}>
                            {token.card.name || 'No name provided'}
                        </span>
                    </div>
                </div>
            );
        }

        var modal;
        if (this.props.token.type === 'card') {
            modal = (
                <CardModal
                    ref={m => (this._modal = m)}
                    card={this.props.token.card}
                    subscriptionOnly={this.props.token.is_subscription_only}
                    customerId={this.props.token.customer_id}
                    tokenId={this.props.token.id}
                />
            );
        } else {
            modal = (
                <GatewayConfModal
                    ref={m => (this._gatewayModal = m)}
                    configuration={token.gateway_configuration}
                    subscriptionOnly={this.props.token.is_subscription_only}
                />
            );
        }

        return (
            <div>
                {modal}
                <a onClick={this.openModal.bind(this)}>
                    <div
                        class='box-row padding row small-margin-bottom'
                        style={{ marginBottom: '0' }}
                    >
                        <div class='medium-9 columns'>{cnt}</div>
                        <div class='medium-3 columns text-right'>
                            <FormattedDate
                                value={token.created_at}
                                day='2-digit'
                                month='2-digit'
                                year='numeric'
                            />
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}
