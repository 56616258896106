import React from 'react';
import { connect } from 'react-redux';
import { FormattedDate } from 'react-intl';
import PropTypes from 'prop-types';

@connect(
    function(store) {
        return {};
    },
    null,
    null,
    { withRef: true },
)
export default class GatewayConfModal extends React.Component {
    static propTypes = {
        configuration: PropTypes.object.isRequired,
        subscriptionOnly: PropTypes.bool,
    };

    checkVar(obj) {
        return !obj || obj === '' ? <span class='greyed'>--</span> : obj;
    }

    getLine(title, value) {
        return (
            <div class='row small-margin-bottom'>
                <div class='large-4 columns'>
                    <span class='greyed'>{title}</span>
                </div>
                <div class='large-8 columns'>
                    <span class='right'>{this.checkVar(value)}</span>
                </div>
            </div>
        );
    }

    openModal() {
        var config = this.props.configuration;

        var subOnly;
        if (this.props.subscriptionOnly) {
            subOnly = (
                <div class='row' style={{ marginTop: '1em' }}>
                    <div class='large-12 columns text-center bold'>
                        <p>Can only be used on the subscription it was created for.</p>
                    </div>
                </div>
            );
        }

        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: `${config.gateway.display_name} token`,
                content: (
                    <div>
                        <div class='row small-margin-bottom'>
                            <div class='large-12 columns'>
                                <h6 class='capitalize greyed font-console'>Payment token</h6>
                            </div>
                        </div>
                        <div class='row margin-bottom'>
                            <div class='large-12 columns text-right font-console greyed'>
                                <input type='text' disabled='true' defaultValue={config.id} />
                            </div>
                        </div>
                        {this.getLine('Name', config.gateway.display_name)}
                        {this.getLine('Currency', config.default_currency)}
                        {this.getLine(
                            'Created',
                            <FormattedDate
                                value={config.created_at}
                                day='2-digit'
                                month='2-digit'
                                year='numeric'
                            />,
                        )}
                        {subOnly}
                        <div class='row' style={{ marginTop: '2em' }}>
                            <div class='large-12 columns text-center small-font'>
                                <p>
                                    This payment method was created for this specific gateway, and
                                    may not be used to process a payment on another gateway.
                                </p>
                            </div>
                        </div>
                    </div>
                ),
                footer: (
                    <div class='row'>
                        <div class='large-12 columns text-right'>
                            <a
                                onClick={function(event) {
                                    event.preventDefault();
                                    this.props.dispatch({
                                        type: 'CLOSE_MODAL',
                                    });
                                }.bind(this)}
                                class='round border small button'
                            >
                                Cancel
                            </a>
                        </div>
                    </div>
                ),
            },
        });
    }

    render() {
        return null;
    }
}
