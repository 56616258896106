// @flow
import React from 'react';

export default class Empty extends React.Component {
    props: {
        text: string | *,
    };

    render() {
        const { text } = this.props;
        return (
            <div class='row'>
                <div class='large-12 columns text-center'>
                    <div>
                        <p
                            class='greyed'
                            style={{
                                fontSize: '3.5em',
                                letterSpacing: '0.08em',
                            }}
                        >
                            {'{...}'}
                        </p>
                        <h5 class='margin-bottom greyed'>{text}</h5>
                    </div>
                </div>
            </div>
        );
    }
}
