import * as ProcessOut from '../util/ProcessOut';

export function loadCustomerDetails(id, filter) {
    if (!filter) {
        filter = '';
    }

    return {
        type: 'FETCH_CUSTOMER_DETAILS',
        payload: ProcessOut.APIcallPromise(`/customers/${id}?filter=${filter}`, 'GET', null),
    };
}

export function loadCustomerTransactions(filter, length, customerId, after, id) {
    if (!filter) {
        filter = '';
    }
    var bound = after ? 'start_after' : 'end_before';
    if (!id) {
        id = '';
    }

    return {
        type: `FETCH_CUSTOMER_TRANSACTIONS${filter !== '' ? '_NORELOAD' : ''}`,
        payload: ProcessOut.APIcallPromise(
            `/customers/${customerId}/transactions?order=desc&${bound}=${id}&limit=${length}&expand=gateway_configuration.gateway&filter=${filter}`,
            'GET',
        ),
    };
}

export function fetchTokens(customer) {
    return {
        type: 'FETCH_CUSTOMER_TOKENS',
        payload: ProcessOut.APIcallPromise(
            `/customers/${customer}/tokens?expand[]=gateway_configuration.gateway&expand[]=card`,
            'GET',
        ),
    };
}

export function addToken(customer, token, resolve) {
    return function(dispatch) {
        return {
            type: 'ADD_CUSTOMER_TOKEN',
            payload: ProcessOut.APIcallPromise(`/customers/${customer}/tokens`, 'POST', {
                source: token,
            }).then(function(response) {
                if (response.data.success) {
                    dispatch(fetchTokens(customer));
                    resolve();
                }

                return response;
            }),
        };
    };
}

export function deleteToken(customer, token, resolve) {
    return dispatch => ({
        type: 'DELETE_CUSTOMER_TOKEN',
        payload: ProcessOut.APIcallPromise(`/customers/${customer}/tokens/${token}`, 'DELETE').then(
            response => {
                if (response.data.success) {
                    dispatch(fetchTokens(customer));
                    resolve(true);
                } else {
                    resolve(false);
                }
                return response;
            },
        ),
    });
}
