import React from 'react';
import { connect } from 'react-redux';
import * as CustomerDefailsActions from '../../Actions/CustomerDetailsActions';
import Loader from '../Loader';
import Transaction from '../Transaction';
import Empty from '../Empty';
import Pagination from '../Pagination';
import PropTypes from 'prop-types';

const transactionsPerPage = 10;

@connect(function(store) {
    return {
        transactions: store.customerDetails.transactions,
    };
})
export default class CustomerTransactions extends React.Component {
    static propTypes = {
        filter: PropTypes.string,
        customerId: PropTypes.string.isRequired,
    };

    componentDidMount() {
        var firstItem = this.props.location.query.after_item;
        if (!firstItem) firstItem = '';
        this.props.dispatch(
            CustomerDefailsActions.loadCustomerTransactions(
                this.props.filter,
                transactionsPerPage,
                this.props.customerId,
                true,
                firstItem,
            ),
        );
    }

    componentDidUpdate(prevProps, prevState) {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    render() {
        var content;
        if (this.props.transactions.fetching || !this.props.transactions.fetched) {
            content = (
                <div style={{ minHeight: '500px' }}>
                    <Loader />
                </div>
            );
        } else {
            var transactions;
            if (this.props.transactions.transactions.length == 0) {
                transactions = <Empty text="The customer hasn't processed any transaction yet." />;
            } else {
                transactions = (
                    <div class="row small-margin-bottom">
                        <div class="large-12 columns">
                            {this.props.transactions.transactions.map(function(transaction) {
                                return (
                                    <div class="row" key={transaction.id}>
                                        <div class="large-12 columns">
                                            <Transaction
                                                transaction={transaction}
                                                hideCustomer={true}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }

            content = transactions;
        }

        return (
            <div>
                {content}
                <Pagination
                    reloadFunction={CustomerDefailsActions.loadCustomerTransactions.bind(
                        null,
                        this.props.filter,
                        transactionsPerPage,
                        this.props.customerId,
                    )}
                    hasMore={
                        this.props.transactions.fetched ? this.props.transactions.has_more : false
                    }
                    location={this.props.location}
                    hide={this.props.transactions.fetching || !this.props.transactions.fetched}
                    itemsArray={
                        this.props.transactions.fetched ? this.props.transactions.transactions : []
                    }
                />
            </div>
        );
    }
}
