// @flow

import React from 'react';

type Props = {
    privateKey: string,
};

class PrivateKeyModal extends React.Component<Props> {
    render() {
        const { privateKey } = this.props;
        return (
            <div class='row'>
                <div class='medium-12 columns'>
                    <div class='row small-margin-bottom'>
                        <div class='medium-12 columns' style={{ color: 'rgb(226, 89, 80)' }}>
                            Your newly created private key is displayed below; please store it
                            securely.
                        </div>
                    </div>
                    <div class='row'>
                        <div class='medium-12 columns'>
                            <input type={'text'} value={privateKey} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivateKeyModal;
