// @flow

import * as Actions from './actions';
import { put, takeLatest } from 'redux-saga/effects';
import { REQUEST_TRSN_PROBA_FETCH } from './consts';
import type { $Action } from '../../util/Types';

type Action = {
    payload: {
        transactionId: string,
        transactionStatus: string,
    },
} & $Action;

function* fetchProbabilities(action: Action): Generator<*, *, *> {
    if (action.payload.transactionStatus === 'failed')
        yield put.resolve(Actions.fetchRecommendations(action.payload.transactionId));
    else yield put.resolve(Actions.fetchProbabilities(action.payload.transactionId));
}

export default function* watchProbabilitiesFetch(): Generator<*, *, *> {
    yield takeLatest(REQUEST_TRSN_PROBA_FETCH, fetchProbabilities);
}
