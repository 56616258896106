// @flow

import type { $DataPoint } from '../consts';
import CountryData from 'country-data';
import CurrencyCodes from 'currency-codes';

export const replaceForGwayKeys = (
    data: Array<$DataPoint>,
    configurations_names: any,
): Array<$DataPoint> => {
    let configCount = 0;
    for (const entry of data) {
        if (`${entry.key}`.includes('gway_conf')) configCount++;
    }
    // If 80%+ of configs
    if (configCount <= data.length * 0.8) return data;
    return data.slice().map(entry => {
        return {
            ...entry,
            key: configurations_names[entry.key] || entry.key,
            _original_key: entry._original_key || entry.key,
        };
    });
};

export const replaceForCountries = (data: Array<$DataPoint>): Array<$DataPoint> => {
    let countryCount = 0;
    for (const entry of data) {
        if (CountryData.countries[entry.key]) countryCount++;
    }
    // If 80%+ of countries
    if (countryCount <= data.length * 0.9) return data;
    return data.slice().map(entry => {
        return {
            ...entry,
            _original_key: entry._original_key || entry.key,
            key: CountryData.countries[entry.key]
                ? CountryData.countries[entry.key].name
                : entry.key,
        };
    });
};

export const replaceForCurrencies = (data: Array<$DataPoint>): Array<$DataPoint> => {
    let currencyCount = 0;
    for (const entry of data) {
        if (CurrencyCodes.code(`${entry.key}`)) currencyCount++;
    }
    // If 80%+ of currencies
    if (currencyCount < data.length * 0.8) return data;
    return data.slice().map(entry => {
        return {
            ...entry,
            key: CurrencyCodes.code(`${entry.key}`)
                ? CurrencyCodes.code(`${entry.key}`).currency
                : entry.key,
            _original_key: entry._original_key || entry.key,
        };
    });
};
