// @flow
import React from 'react';
import { Link } from 'react-router';

type Props = {
    route: string,
    routeRegex: ?string,
    name: string,
    location: any,
    to: string,
    children: any,
};

export default class SubButton extends React.Component {
    props: Props;
    getInitialProps() {
        return { to: '', name: '', img: '' };
    }

    constructor() {
        super();
    }

    render() {
        const { location } = this.props;
        const regex = this.props.routeRegex
            ? new RegExp(this.props.routeRegex)
            : new RegExp('/projects/.+/' + this.props.route + '$', 'gi');
        let current = regex.test(location.pathname) ? 'active' : '';

        if (this.props.route === 'analytics') {
            const regex2 = new RegExp('/projects/.+/$', 'gi');
            if (regex2.test(location.pathname)) current = 'active';
            else current = '';
        }

        return (
            <li class={current}>
                <Link
                    to={this.props.to}
                    style={{
                        width: '95%',
                        marginLeft: '30px',
                        fontSize: '0.9em',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'inline-block',
                    }}
                    title={this.props.name}
                >
                    <span>{this.props.name}</span>
                </Link>
                {this.props.children}
            </li>
        );
    }
}
