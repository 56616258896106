// @flow
import React from 'react';

type Props = {
    title: any,
    style?: ?any,
    children?: ?any,
    noBackground?: boolean,
    noPadding?: boolean,
};

export default class ContentLayout extends React.Component<Props> {
    render() {
        const { children, noPadding, noBackground, title } = this.props;
        let titleRenderer;
        if (title) {
            titleRenderer = (
                <div class={`row category-title ${noPadding ? '' : 'collapse'}`}>
                    <div className='large-12 columns' style={{ paddingRight: '22px' }}>
                        {title}
                    </div>
                </div>
            );
        }
        const style = { padding: '35px 22px' };

        if (noBackground) style.background = 'none';
        if (noPadding) {
            style.padding = 0;
            style.boxShadow = 'none';
        }

        return (
            <div>
                {titleRenderer}
                <div class='row main-content-bg' style={style}>
                    <div class='large-12 columns content'>{children}</div>
                </div>
            </div>
        );
    }
}
