// @flow

import {
    takeEvery,
    takeLatest,
    put,
    call,
    take,
    fork,
    cancel,
    cancelled,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as Sentry from '@sentry/browser';
import * as ProcessOut from '../../util/ProcessOut';
import axios from 'axios';
import { NETWORK_REQUEST_FAILURE } from '../NetworkManager/consts';
import * as GatewayActions from '../../Actions/GatewaysActions';
import uniqid from 'uniqid';
import * as Store from '../../stores/Store';
import type { $LocalReport } from './reducer';

export const PREPARE_REPORTS_PAGE = 'PREPARE_REPORTS_PAGE';
function* prepareReportsPage(): Generator<*, *, *> {
    try {
        yield put(GatewayActions.loadGatewaysConfigurations());
        yield put({ type: ProcessOut.typeFulfilled(PREPARE_REPORTS_PAGE) });
    } catch (error) {
        yield put({ type: ProcessOut.typeFailed(PREPARE_REPORTS_PAGE), payload: error });
        if (error.type && error.type.includes('NETWORK')) {
            // error handled by network manager
        } else {
            Sentry.captureException(error);
        }
    }
}

export const REQUEST_REPORTS_FETCH = 'REQUEST_REPORTS_FETCH';
function* fetchReports(action): Generator<*, *, *> {
    try {
        const { filter, length, after, id, silent } = action.payload;

        if (!silent) {
            // we reset the autofetcher
            yield put({ type: STOP_REPORTS_AUTOFETCH });
        }

        yield put({ type: ProcessOut.typePending(REQUEST_REPORTS_FETCH), payload: { silent } });
        // We retrieve all the reports from the API
        const bound = after ? 'start_after' : 'end_before';
        const reportsResult = yield ProcessOut.APIcallPromise(
            `/uploads/reports?limit=${length}&filter=${filter}&${bound}=${id || ''}`,
            'GET',
        );

        // Dispatch the results
        yield put({
            type: ProcessOut.typeFulfilled(REQUEST_REPORTS_FETCH),
            payload: { ...reportsResult.data, silent },
        });

        if (!silent) {
            //Init the auto-fetch
            const fetcher = yield fork(refetchReportsLooper, action);
            // Watch for auto-fetch cancel
            yield take(STOP_REPORTS_AUTOFETCH);
            yield cancel(fetcher);
        }
    } catch (error) {
        yield put({ type: ProcessOut.typeFailed(REQUEST_REPORTS_FETCH), payload: error });

        if (error.type && error.type.includes('NETWORK')) {
            // error handled by the network manager
            if (error.type === NETWORK_REQUEST_FAILURE) {
                // The request could not be made so we cancel the auto-fetcher
                yield put({ type: STOP_REPORTS_AUTOFETCH });
            }
        } else {
            Sentry.captureException(error);
        }
    }
}

export const STOP_REPORTS_AUTOFETCH = 'STOP_REPORTS_AUTOFETCH';
function* refetchReportsLooper(action): Generator<*, *, *> {
    try {
        // Start automatically updating report list
        while (true) {
            // Wait 10 s
            yield call(delay, 10000);
            yield call(fetchReports, { ...action, payload: { ...action.payload, silent: true } });
        }
    } catch (error) {
        Sentry.captureException(error);
    }
}

export const ADD_LOCAL_REPORT_UPLOAD = 'ADD_LOCAL_REPORT_UPLOAD';
function* startLocalUpload(fileName: string, id: string, file: any): Generator<*, *, *> {
    try {
        yield put({
            type: ADD_LOCAL_REPORT_UPLOAD,
            payload: {
                fileName,
                id,
                progress: 0,
                file,
            },
        });
    } catch (error) {
        Sentry.captureException(error);
    }
}

export const REQUEST_UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS =
    'REQUEST_UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS';
export const UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS = 'UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS';
type $UpdateReportProgressAction = {
    payload: {
        id: string,
        progress: number,
    },
};
function* updateLocalUploadProgress(action: $UpdateReportProgressAction): Generator<*, *, *> {
    try {
        const { id, progress } = action.payload;
        yield put({
            type: UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS,
            payload: {
                id,
                progress,
            },
        });
    } catch (error) {
        Sentry.captureException(error);
    }
}

export const REMOVE_LOCAL_REPORT_UPLOAD = 'REMOVE_LOCAL_REPORT_UPLOAD';
export const INITIATE_REPORT_UPLOAD = 'INITIATE_REPORT_UPLOAD';
function* initiateReportUpload(action): Generator<*, *, *> {
    try {
        const { files, gatewayConfigurationId } = action.payload;
        const localReports: Array<$LocalReport> = [...files].map(file => {
            // We generate a local id for this report
            const localId = uniqid();
            return {
                id: localId,
                fileName: file.name,
                progress_percentage: 0,
                file: file,
            };
        });

        for (const report of localReports) {
            // Store the report upload locally
            yield call(startLocalUpload, report.fileName, report.id, report.file);
        }
        yield put({ type: 'CLOSE_MODAL' });

        for (const report of localReports) {
            // generate an upload url and start uploading it
            const urlResponse = yield ProcessOut.APIcallPromise(
                '/uploads/reports',
                'POST',
                JSON.stringify({
                    fileName: report.fileName,
                    gateway_configuration_id: gatewayConfigurationId,
                }),
            );

            // start the upload
            const config = {
                onUploadProgress: progressEvent => {
                    const progression = progressEvent.loaded / progressEvent.total;
                    Store.store.dispatch({
                        type: REQUEST_UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS,
                        payload: { id: report.id, progress: progression },
                    });
                },
            };
            yield axios.put(urlResponse.data.url, report.file, config).then(response => {});
            yield put({ type: REMOVE_LOCAL_REPORT_UPLOAD, payload: { id: report.id } });
            // Update the API
            yield ProcessOut.APIcallPromise(
                '/uploads/reports',
                'PUT',
                JSON.stringify({ token: urlResponse.data.token, description: report.fileName }),
            );
        }
        // Upload done, reload the reports
        yield call(fetchReports, { payload: { filter: '', after: false, id: null, length: 10 } });
    } catch (error) {
        yield put({ type: ProcessOut.typeFailed(INITIATE_REPORT_UPLOAD), payload: { error } });
        if (error.type && error.type.includes('NETWORK')) {
            // error handled by network manager
        } else {
            Sentry.captureException(error);
        }
    }
}

export default function* watchForSagas(): Generator<*, *, *> {
    yield takeEvery(PREPARE_REPORTS_PAGE, prepareReportsPage);
    yield takeLatest(REQUEST_REPORTS_FETCH, fetchReports);
    yield takeEvery(INITIATE_REPORT_UPLOAD, initiateReportUpload);
    yield takeLatest(REQUEST_UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS, updateLocalUploadProgress);
}
