import React from 'react';
import { Link } from 'react-router';

export default class MenuButton extends React.Component {
    getInitialProps() {
        return { to: '', name: '', img: '' };
    }

    constructor() {
        super();
    }

    render() {
        const { location } = this.props;
        var name = this.props.name;
        var regex = new RegExp('/projects/.+/' + this.props.route + '$', 'gi');
        var current =
            regex.test(location.pathname) && !this.props.route === 'analytics' ? 'active' : '';

        if (this.props.route == 'analytics') {
            var regex2 = new RegExp('/projects/.+/$', 'gi');
            if (regex2.test(location.pathname) || location.pathname.includes('analytics'))
                current = 'active';
            else current = '';
        }

        if (
            location.pathname.includes(this.props.route) &&
            !location.pathname.includes('analytics')
        )
            current = 'active';

        return (
            <li class={current}>
                <Link to={this.props.to} style={{ whiteSpace: 'no-wrap' }}>
                    {this.props.image ? (
                        <img src={this.props.image} alt="" class="menu-icon" />
                    ) : (
                        ''
                    )}
                    {this.props.name}
                    {!this.props.children ||
                    this.props.children.filter(child => child !== null).length === 0 ? (
                        ''
                    ) : (
                        <img
                            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI1NiAyNTYiIGhlaWdodD0iMjU2cHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNTYgMjU2IiB3aWR0aD0iMjU2cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0zOC4zOTksNzYuOGMxLjYzNywwLDMuMjc0LDAuNjI1LDQuNTI0LDEuODc1bDg1LjA3NSw4NS4wNzZsODUuMDc1LTg1LjA3NmMyLjUtMi41LDYuNTUtMi41LDkuMDUsMHMyLjUsNi41NSwwLDkuMDUgIGwtODkuNiw4OS42MDFjLTIuNSwyLjUtNi41NTEsMi41LTkuMDUxLDBsLTg5LjYtODkuNjAxYy0yLjUtMi41LTIuNS02LjU1LDAtOS4wNUMzNS4xMjQsNzcuNDI1LDM2Ljc2Miw3Ni44LDM4LjM5OSw3Ni44eiIvPjwvc3ZnPg=="
                            class="down-arrow"
                        />
                    )}
                </Link>
                <ul class="children">{this.props.children}</ul>
            </li>
        );
    }
}
