// @flow

import type { $State } from '../../util/Types';
import type { $Collaborator, $PermissionFeature, $PermissionGroup } from './consts';
import { PREPARE_PERMISSIONS } from './consts';
import permissionGroups from './PermissionGroupsReducer';
import collaborators from './CollaboratorsReducer';
import * as ProcessOut from '../../util/ProcessOut';
import type {
    $DeletePermissionGroupAction,
    $PreparePermissionsAction,
    $SetCollaboratorPermissionGroupAction,
    $SetPermissionGroupValuesAction,
} from './actions';
import { SAVE_TEAM_SETTINGS } from './actions';
import type { $PermissionGroupsState } from './PermissionGroupsReducer';
import type { $CollaboratorsState } from './CollaboratorsReducer';
import { combineReducers } from 'redux';

export type $FetchedCollaboratorsState = {
    collaborators: Array<$Collaborator>,
    permissionGroups: Array<$PermissionGroup>,
};

export type $PermissionsState = {
    permissionGroups: $PermissionGroupsState,
    collaborators: $CollaboratorsState,
    fetchedState: ?$FetchedCollaboratorsState,
    global: $State,
};

const defaultState: $State = {
    fetching: false,
    fetched: false,
    error: null,
};

const globalReducer = (
    state: $State = defaultState,
    action:
        | $SetCollaboratorPermissionGroupAction
        | $SetPermissionGroupValuesAction
        | $PreparePermissionsAction
        | $DeletePermissionGroupAction,
): $State => {
    switch (action.type) {
        case PREPARE_PERMISSIONS: {
            return {
                ...state,
                ...defaultState,
                fetching: true,
                fetched: false,
            };
        }
        case ProcessOut.typeFulfilled(PREPARE_PERMISSIONS): {
            const { payload } = action;
            return {
                ...state,
                fetched: true,
                fetching: false,
            };
        }

        case ProcessOut.typeFailed(PREPARE_PERMISSIONS): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: action.payload,
            };
        }
    }

    return state;
};

export default combineReducers({
    global: globalReducer,
    permissionGroups,
    collaborators,
});
