// @flow
import type { $Action } from '../../util/Types';
import * as ProcessOut from '../../util/ProcessOut';

export const FETCH_COLLABORATORS = 'FETCH_COLLABORATORS';

export function fetchCollaborators(): $Action {
    return {
        type: FETCH_COLLABORATORS,
        payload: ProcessOut.APIcallPromise('/collaborators', 'GET'),
    };
}

export const FETCH_COLLABORATOR_DETAILS = 'FETCH_COLLABORATOR_DETAILS';

export function fetchCollaboratorDetails(email: string): $Action {
    return {
        type: FETCH_COLLABORATOR_DETAILS,
        payload: ProcessOut.APIcallPromise(`/collaborators/${email}`, 'GET'),
    };
}
