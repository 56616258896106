// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { $DataExplorerState } from './reducer';
import Loading from '../../../components/Loader';
import Error from '../../../components/Error';
import Chart from '../Boards/charts/Chart';
import Empty from '../../../components/Empty';
import type { $Dispatcher } from '../../../util/Types';
import * as Actions from './actions';
import * as Utils from './Utils';
import type { $ChartBuilderState } from './ChartBuilder/reducer';

type Props = {
    dataExplorer: $DataExplorerState,
    chartBuilder: $ChartBuilderState,
    hideName: ?boolean,
    presetPreviewer: ?boolean,
} & $Dispatcher;

class Previewer extends React.Component<Props> {
    preview = () => {
        const { dispatch, dataExplorer, chartBuilder, analytics } = this.props;
        dispatch(
            Actions.requestChartFetch(
                dataExplorer.formula,
                dataExplorer.name || chartBuilder.selectedMetric.name,
                dataExplorer.type,
                dataExplorer.unit,
                analytics.params,
            ),
        );
    };
    render() {
        const { dataExplorer, chartBuilder } = this.props;
        const empty = (
            <Empty
                text={
                    !chartBuilder.selectedMetric ? (
                        <div>
                            Click the <a onclick={this.preview}>preview </a>button to start
                            displaying data'
                        </div>
                    ) : (
                        'No chart to render, start by selecting a preset'
                    )
                }
            />
        );
        const chartOutdated =
            !Utils.areFormulasEqual(dataExplorer.previewedFormula, dataExplorer.formula) ||
            (dataExplorer.chart.fetched &&
                !dataExplorer.chart.fetching &&
                !dataExplorer.chart.error &&
                (dataExplorer.chart.chart.type !== dataExplorer.type ||
                    dataExplorer.chart.chart.unit !== dataExplorer.unit));
        return (
            <div class='row small-margin-bottom'>
                <div class={`large-12 columns`} style={{ minHeight: '200px' }}>
                    {!this.props.presetPreviewer || dataExplorer.selectedPreset ? (
                        dataExplorer.chart.error ? (
                            <Error />
                        ) : dataExplorer.chart.fetched ? (
                            <div>
                                {chartOutdated ? (
                                    <div
                                        class='outdated-warning text-center'
                                        onClick={this.preview}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        This chart is outdated, please click here to refresh it.
                                    </div>
                                ) : null}

                                <div
                                    class={`previewed-chart ${
                                        chartOutdated ? 'outdated-chart' : ''
                                    }`}
                                >
                                    <Chart
                                        chart={dataExplorer.chart.chart}
                                        board={{
                                            board: {
                                                id: 'board_default-sales',
                                            },
                                        }}
                                        loading={dataExplorer.chart.fetching}
                                        preview={true}
                                        fetched={true}
                                        canDelete={false}
                                        canEdit={false}
                                        hideName={this.props.hideName}
                                    />
                                </div>
                            </div>
                        ) : (
                            empty
                        )
                    ) : (
                        empty
                    )}
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return {
        chartBuilder: store.chartBuilder,
        dataExplorer: store.dataExplorer,
        analytics: store.analytics,
    };
})(Previewer);
