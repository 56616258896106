// @flow

import type { $Action, $State } from '../../util/Types';
import {
    ADD_LOCAL_REPORT_UPLOAD,
    PREPARE_REPORTS_PAGE,
    REMOVE_LOCAL_REPORT_UPLOAD,
    REQUEST_REPORTS_FETCH,
    UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS,
} from './sagas';
import * as ProcessOut from '../../util/ProcessOut';
import {
    CHANGE_REPORTS_SELECTED_FILES,
    CHANGE_REPORTS_SELECTED_GATEWAY,
    CHANGE_REPORTS_SELECTED_NAME,
} from './actions';

export type $Report = {
    id: string,
    created_at: string,
    description: string,
    is_processed: boolean,
    gateway_configuration_id: string,
    project_id: string,
    status: 'processed' | 'processing',
    progress_percentage: ?number,
};

export type $LocalReport = {
    fileName: string,
    id: string,
    progress_percentage: number,
    file: any,
};

export type $ReportsState = {
    reports: Array<$Report>,
    hasMore: boolean,
    uploadingReports: Array<$LocalReport>,
    pageReady: boolean,
    selectedGatewayConfiguration: string,
    selectedFiles: Array<any>,
} & $State;

const defaultState: $ReportsState = {
    fetching: false,
    fetched: false,
    error: null,
    reports: [],
    count: 0,
    hasMore: false,
    uploadingReports: [],
    pageReady: false,
    selectedGatewayConfiguration: '',
    selectedName: '',
    selectedFiles: [],
};

export default function(state: $ReportsState = defaultState, action: $Action): $ReportsState {
    switch (action.type) {
        case ProcessOut.typePending(REQUEST_REPORTS_FETCH): {
            const { payload } = action;
            let newState = {
                ...state,
                error: null,
            };

            if (!payload || !payload.silent)
                newState = {
                    ...defaultState,
                    ...newState,
                    fetching: true,
                    error: null,
                };

            return newState;
        }

        case ProcessOut.typeFulfilled(REQUEST_REPORTS_FETCH): {
            const { payload } = action;
            if (!payload)
                return {
                    ...state,
                    error: null,
                    fetching: false,
                    fetched: true,
                };
            return {
                ...state,
                fetching: false,
                fetched: true,
                hasMore: payload.has_more,
                reports: payload.reports,
                count: payload.count,
            };
        }

        case ProcessOut.typeFailed(REQUEST_REPORTS_FETCH): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: action.payload,
            };
        }

        case PREPARE_REPORTS_PAGE: {
            return {
                ...state,
                ready: false,
            };
        }

        case ProcessOut.typeFulfilled(PREPARE_REPORTS_PAGE): {
            return {
                ...state,
                ready: true,
            };
        }

        case ProcessOut.typeFailed(PREPARE_REPORTS_PAGE): {
            return {
                ...state,
                ready: false,
                error: action.payload,
            };
        }

        case CHANGE_REPORTS_SELECTED_GATEWAY: {
            const { payload } = action;
            if (!payload) return state;
            return {
                ...state,
                selectedGatewayConfiguration: payload.gatewayConfigurationId,
            };
        }

        case CHANGE_REPORTS_SELECTED_FILES: {
            const { payload } = action;
            if (!payload) return state;
            return {
                ...state,
                selectedFiles: payload.files,
            };
        }

        case ADD_LOCAL_REPORT_UPLOAD: {
            const { payload } = action;
            if (!payload) return state;
            const newLocalReports: Array<$LocalReport> = state.uploadingReports.slice();
            newLocalReports.push({
                id: payload.id,
                fileName: payload.fileName,
                progress_percentage: 0,
                file: payload.file,
            });
            return {
                ...state,
                uploadingReports: newLocalReports,
            };
        }

        case UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS: {
            const { payload } = action;
            if (!payload) return state;

            const reportIndex = state.uploadingReports.findIndex(
                report => report.id === payload.id,
            );
            if (reportIndex < 0) return state;

            const reports = state.uploadingReports.slice();
            reports[reportIndex].progress_percentage = payload.progress;
            return {
                ...state,
                uploadingReports: reports,
            };
        }

        case REMOVE_LOCAL_REPORT_UPLOAD: {
            const { payload } = action;
            if (!payload) return state;

            const reportIndex = state.uploadingReports.findIndex(
                report => report.id === payload.id,
            );
            if (reportIndex < 0) return state;

            const reports = state.uploadingReports.slice();
            reports.splice(reportIndex, 1);
            return {
                ...state,
                uploadingReports: reports,
            };
        }
    }
    return state;
}
