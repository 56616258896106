// @flow
import React from 'react';
import Creatable from 'react-select-v2/lib/Creatable';
import { TRANSACTION_AMOUNT_FIELDS, TRANSACTION_FIELDS } from './consts';
import { DIMENSIONS_MAP } from '../../DimensionSelection/consts';
import { SelectStyle } from '../../consts';
import { groupBy } from '../../../Utils';

type Props = {
    onChange: string => void,
    value: ?string,
    suffix?: string,
    disabled: ?boolean,
    options?: ?Array<$Option>,
    allowMetadata?: ?boolean,
    shouldDisplayApplyButton?: ?boolean,
};

type $Option = { label: string, value: string, desc: string };

class PlottedField extends React.Component<Props> {
    renderOption = (option: $Option) => {
        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <div class='row'>
                        <div class='large-12 columns'>{option.label}</div>
                    </div>
                    <div class='row small-font' style={{ opacity: 0.7 }}>
                        <div class='large-12 columns'>{option.desc}</div>
                    </div>
                </div>
            </div>
        );
    };

    updateValue = (newValue: { label: string, value: string }) => {
        const { onChange } = this.props;

        onChange(newValue.value);
    };

    createValue = (newValue: string) => {
        const { onChange } = this.props;

        onChange(`metadata.${newValue}`);
    };

    render() {
        const { options, value, suffix } = this.props;
        const groupByGroupName = groupBy('group');
        const groupedByGroupName = groupByGroupName(
            TRANSACTION_FIELDS.concat(TRANSACTION_AMOUNT_FIELDS),
        );

        const OPTIONS = Object.keys(groupedByGroupName).map(group => ({
            label: group,
            options: groupedByGroupName[group].map(entry => ({
                label: entry.key,
                value: entry.key,
                desc: entry.desc,
            })),
        }));

        return (
            <div class='labeled-field'>
                <Creatable
                    options={OPTIONS}
                    placeholder='Filter data...'
                    value={value ? { label: value, value } : null}
                    onChange={this.updateValue}
                    disabled={this.props.disabled}
                    styles={SelectStyle}
                    formatCreateLabel={(option: string) =>
                        `metadata.${option.replace('metadata.', '')}`
                    }
                    formatGroupLabel={formatGroupLabel}
                    onCreateOption={this.createValue}
                />
            </div>
        );
    }
}

export default PlottedField;

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);
