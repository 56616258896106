// @flow

export const PROVIDERS_PER_LINE = 4;
export type $GatewaySubMenu = {
    name: string,
    id: string,
    description?: string,
    not?: Array<string>,
};
export type $GatewayMenu = {
    name: string,
    id: string,
    submenus: Array<$GatewaySubMenu>,
};
export const PAYMENT_PROVIDERS_MENU: Array<$GatewayMenu> = [
    {
        name: 'Accept cards',
        id: 'credit-card',
        submenus: [
            {
                name: 'North-America',
                id: 'region-na',
                description:
                    'Payment providers for accepting payments in North America (including the US and Canada)',
            },
            {
                name: 'Europe',
                id: 'region-eu',
                description:
                    'Payment providers for domestic cards in Europe. Processing fees are generally lower',
            },
            {
                name: 'Latam',
                id: 'region-latam',
                description: 'Payment providers for accepting payments in Latin America',
            },
            {
                name: 'Asia',
                id: 'region-as',
                description: 'Payment providers for accepting payments in Asia',
            },
            {
                name: 'Africa',
                id: 'region-af',
                description: 'Payment providers best suited for accepting payments in Africa',
            },
            {
                name: 'Russia',
                id: 'region-ru',
                description: 'Payment providers for accepting payments in Russia',
            },
            {
                name: 'Other',
                id: '',
                not: ['region-na', 'region-eu', 'region-asia'],
            },
        ],
    },
    {
        name: 'Alternative payment solutions',
        id: 'alternative',
        submenus: [
            {
                name: 'E-Wallets',
                id: 'e-wallet',
                description:
                    'E-Wallet payment providers, such as PayPal, offer customers with an easy way to pay online',
            },
            {
                name: 'Alt-Coins',
                id: 'alt-coin',
                description:
                    'Coins, like Bitcoins, can be used to pay online in a decentralized way',
            },
            {
                name: 'Other',
                id: '',
                not: ['e-wallet', 'alt-coin'],
            },
        ],
    },
];

export type $Gateway = {
    can_pull_transaction: boolean,
    can_refund: boolean,
    config: Array<any>,
    display_name: string,
    name: string,
    flows: ?Array<any>,
    identifier_fields: Array<string>,
    instructions: Array<any>,
    is_oauth_authentication: boolean,
    logo_url: string,
    public_fields: Array<string>,
    sub_accounts: Array<string>,
    tags: Array<string>,
    url: string,
};

export type $GatewayConfiguration = {
    created_at: string,
    default_currency: string,
    enabled: boolean,
    gateway_id: number,
    id: string,
    is_pulling: boolean,
    merchant_account_country_code: ?string,
    name: string,
    project_id: string,
    public_keys: { [string]: string },
    sub_accounts_enabled: Array<string>,
    gateway: $Gateway,
};
