import React from 'react';
import Auth from '../util/Auth';
import Loader from '../components/Loader';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import PropTypes from 'prop-types';
import {
    REQUEST_DASHBOARD_PREPARATION,
    REQUEST_DASHBOARD_PREPARATOR_RESET,
} from '../features/Preparator/consts';
import Error from '../components/Error';
import LocalStorage from 'local-storage';

@connect(store => {
    return {
        user: store.user,
        projects: store.projects,
        replace: replace,
        currentProject: store.currentProject,
        preparator: store.preparator,
    };
})
export default class Projects extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
        store: PropTypes.object.isRequired,
    };

    componentWillMount() {
        document.title = 'Projects | ProcessOut';
        if (this.props.location.query.logout) {
            Auth.logout();
            this.context.router.replace('/login');
        }
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        this.props.dispatch({ type: REQUEST_DASHBOARD_PREPARATOR_RESET });
        this.requestDashboardPreparation(this.props);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.requestDashboardPreparation(nextProps);
    }

    requestDashboardPreparation(props: Props) {
        let { user, preparator, dispatch } = props;

        if (!user.details.fetched || preparator.error || preparator.fetching || preparator.fetched)
            return;

        dispatch({
            type: REQUEST_DASHBOARD_PREPARATION,
            payload: {
                projectId: this.props.location.query.target || LocalStorage('projectid'),
                email: user.details.email,
            },
        });
    }

    render() {
        const { preparator } = this.props;
        let content;
        if (preparator.error) content = <Error />;
        else {
            content = (
                <div class='row'>
                    <div class='large-12 columns'>
                        <div class='row large-margin-bottom' style={{ marginTop: '2em' }}>
                            <div class='large-12 columns text-center'>
                                <Loader size={48} />
                            </div>
                        </div>
                        <div class='row'>
                            <div class='large-12 columns text-center'>
                                <h4 class='bold greyed'>Preparing your dashboard</h4>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return <div>{content}</div>;
    }
}
