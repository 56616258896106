// @flow
import React from 'react';
import Select from 'react-select';
import type { $Operand } from './consts';

type $Option = {
    label: string,
    value: string,
};

type Props = {
    onChange: $Operand => void,
    defaultValue?: ?$Operand,
    options: Array<$Option>,
};

class Operand extends React.Component {
    state: {
        operand: $Operand,
    };

    props: Props;

    constructor() {
        super();
        this.state = {
            operand: '==',
        };
    }

    componentDidMount() {
        this.setDefaultValue(this.props);
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.defaultValue && nextProps.defaultValue !== this.props.defaultValue)
            this.setDefaultValue(nextProps);
    }

    setDefaultValue = (props: Props) => {
        if (props.defaultValue) this.setState({ operand: props.defaultValue });
    };

    valueChanged = (option: { value: $Operand }) => {
        let { onChange } = this.props;
        if (onChange) onChange(option.value);
        this.setState({ operand: option.value });
    };

    render() {
        let { operand } = this.state;
        const { options } = this.props;

        return (
            <div class="row">
                <div class="large-12 columns text-center">
                    <Select
                        options={options}
                        searcheable={false}
                        value={operand}
                        clearable={false}
                        onChange={this.valueChanged}
                    />
                </div>
            </div>
        );
    }
}

export default Operand;
