// @flow
/**
 * Created by jeremylejoux on 12/09/2017.
 */

import type { Feature } from './consts';
import Wildcard from 'wildcard';

export function hasFeature(flags: Array<string>, feature: Feature): boolean {
    for (let flag of flags) {
        if (Wildcard(flag, feature)) return true;
    }

    return false;
}
