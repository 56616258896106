// @flow

import * as ProcessOut from '../../../util/ProcessOut';
import { fetchTelescopeChartData, formatBarChart } from './sagas';
import {
    AUTH_PER_COUNTRY_FORMULA,
    AUTH_PER_PSP_BAR,
    AUTH_PER_PSP_PER_CARD_SCHEME_BAR,
    AUTH_PER_PSP_PER_CARD_TYPE_BAR,
    AUTHORIZED_AMOUNT_FORMULA,
    AUTHORIZED_TRANSACTIONS_FORMULA,
    FAILED_TRANSACTIONS_FORMULA,
    GLOBAL_AUTH_FORMULA,
} from '../consts';
import { call } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

export function* fetchGlobalAuth(timespan: string): Generator<*, *, *> {
    try {
        const chartData = yield call(fetchTelescopeChartData, GLOBAL_AUTH_FORMULA, timespan);
        return {
            global_authorization_rate: chartData[0].value,
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetch global auth rate' },
        });

        return null;
    }
}

export function* fetchAuthCountry(timespan: string): Generator<*, *, *> {
    try {
        const chartData = yield call(fetchTelescopeChartData, AUTH_PER_COUNTRY_FORMULA, timespan);
        return {
            authPerCountry: chartData.map(country => {
                return { ...country, authorization: country.value };
            }),
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetch auth rate per country' },
        });

        return null;
    }
}

export function* fetchAuthorizedTransactions(timespan: string): Generator<*, *, *> {
    try {
        const chartData = yield call(
            fetchTelescopeChartData,
            AUTHORIZED_TRANSACTIONS_FORMULA,
            timespan,
        );
        return {
            transactions_authorized: chartData[0].value,
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetch nbr. authorized trs' },
        });

        return null;
    }
}

export function* fetchAuthorizedAmount(timespan: string): Generator<*, *, *> {
    try {
        const chartData = yield call(fetchTelescopeChartData, AUTHORIZED_AMOUNT_FORMULA, timespan);
        return {
            authorized_amount: chartData[0].value,
        };
    } catch (error) {
        Sentry.captureException(error), { extra: { action: 'fetch total authorized amount' } };

        return null;
    }
}

export function* fetchFailedTransactions(timespan: string): Generator<*, *, *> {
    try {
        const chartData = yield call(
            fetchTelescopeChartData,
            FAILED_TRANSACTIONS_FORMULA,
            timespan,
        );
        return {
            failed_transactions: chartData[0].value,
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetch nbr. failed trs' },
        });

        return null;
    }
}

export function* fetchAuthorizationPerPspBar(
    timespan: string,
    gatewayConfigurations: Array<{ id: string, name: string }>,
): Generator<*, *, *> {
    try {
        let chartData = yield call(fetchTelescopeChartData, AUTH_PER_PSP_BAR, timespan);

        chartData = chartData.map(point => {
            const gatewayConfig = gatewayConfigurations.find(config => config.id === point.key);
            if (!gatewayConfig) return null;
            return {
                ...point,
                key: gatewayConfig.name,
            };
        });
        if (chartData.includes(null)) return null;
        return {
            authorization_rate_per_psp_bar: formatBarChart(chartData),
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetch auth rate per psp' },
        });

        return null;
    }
}

export function* fetchAuthorizationPerPspPerCardSchemeBar(
    timespan: string,
    gatewayConfigurations: Array<{ id: string, name: string }>,
): Generator<*, *, *> {
    try {
        let chartData = yield call(
            fetchTelescopeChartData,
            AUTH_PER_PSP_PER_CARD_SCHEME_BAR,
            timespan,
        );
        if (chartData.includes(null)) {
            return null;
        }
        return {
            authorization_rate_per_psp_per_card_scheme_bar: chartData,
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fsetch auth rate per psp per card scheme' },
        });

        return null;
    }
}

export function* fetchAuthorizationPerPspPerCardTypeBar(
    timespan: string,
    gatewayConfigurations: Array<{ id: string, name: string }>,
): Generator<*, *, *> {
    try {
        let chartData = yield call(
            fetchTelescopeChartData,
            AUTH_PER_PSP_PER_CARD_TYPE_BAR,
            timespan,
        );
        if (chartData.includes(null)) return null;
        return {
            authorization_rate_per_psp_per_card_type_bar: chartData,
        };
    } catch (error) {
        Sentry.captureException(error, {
            extra: { action: 'fetch auth rate per psp per card type' },
        });

        return null;
    }
}
