// @flow

import type { $RequestConfirmationAction } from './consts';
import { REQUEST_CONFIRMATION } from './consts';

export function requestConfirmation(
    content: any,
    resolve: any => any,
    reject: any => any,
): $RequestConfirmationAction {
    return {
        type: REQUEST_CONFIRMATION,
        payload: {
            content,
            resolve,
            reject,
        },
    };
}
