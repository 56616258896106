// @flow
import React from 'react';
import { connect } from 'react-redux';
import GeoChart from '../../../components/Analytics/GeoChart';
import type { $TelescopeState } from '../../../stores/Reducers/TelescopeReducer/Index';
import { computeAuthorizationColor, computeFeesColor } from '../Recommendation/utils';
import * as ISOCountries from '../../../util/ISOCountries';
import uniqid from 'uniqid';
import { FormattedNumber } from 'react-intl';
import BarChart from '../../analytics/Charts/BarChart';
import LineChart from '../../analytics/Charts/LineChart';
import Empty from '../../../components/Empty';
import CallToAction from '../Components/CallToAction';
import ChartSwitch from '../Components/ChartSwitch';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import Warning from '../Components/Warning';
import Error from '../../../components/Error';
import type { $ProviderCompareOption } from '../Components/ProviderCompareSelector';
import ProviderCompareSelector from '../Components/ProviderCompareSelector';
import getColor from '../../analytics/Boards/charts/colors';
import { Link, withRouter } from 'react-router';
import FeesRecommendations from './FeesRecommendations';

type Props = {
    telescope: $TelescopeState,
    params: {
        project: string,
    },
};

type State = {
    pspCompare: $ProviderCompareOption,
};

class CapturesContent extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            pspCompare: { value: 'general', label: 'general' },
        };
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        return (
            nextProps.telescope.standard.capturesReady !==
                this.props.telescope.standard.capturesReady || this.state !== nextState
        );
    }
    render() {
        const { telescope } = this.props;
        return (
            <div class='row text-left'>
                <div class='large-12 columns'>
                    <div class='row margin-bottom'>
                        <div class='large-5 columns'>
                            <div class='row small-margin-bottom'>
                                <div class='large-12 columns'>
                                    <div class='chart-title'>
                                        Fees percent per country
                                        {!telescope.standard.data.gateway_fees &&
                                        telescope.standard.data.transactions_captured ? (
                                            <Warning text={'Fee data is missing'} />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='large-12 columns'>
                                    <div style={{ height: '250px' }}>
                                        <GeoChart
                                            key={uniqid()}
                                            data={
                                                telescope.standard.data
                                                    .gateway_fees_percent_per_country
                                            }
                                            color={computeFeesColor.bind(
                                                null,
                                                telescope.standard.data.captured_amount,
                                                telescope.standard.data.transactions_captured,
                                                telescope.standard.data.fees_recommendations,
                                            )}
                                            tip={function(d) {
                                                let c = ISOCountries.isoCountries[d.key];
                                                const feesDisplay =
                                                    d.country.fees !== null
                                                        ? `${(d.country.fees * 100).toLocaleString(
                                                              undefined,
                                                              {
                                                                  maximumFractionDigit: 2,
                                                              },
                                                          )}%`
                                                        : '--';

                                                return (
                                                    c.full_name +
                                                    '<br />' +
                                                    `<span class="greyed">Fees percent:</span> ${feesDisplay}<br />`
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='large-6 large-offset-1 columns'>
                            <div class='row'>
                                <div class='large-12 columns'>
                                    <div class='row'>
                                        <div class='large-7 columns'>
                                            <div class='chart-title'>
                                                Fees percent
                                                {(() => {
                                                    if (!telescope.standard.capturesReady) {
                                                        return null;
                                                    }
                                                    const providersZeroFees = telescope.standard.data.gateway_fees_per_psp_bar[0].datapoints.filter(
                                                        bar => bar.value === 0,
                                                    );
                                                    const providersMissingFees = [];
                                                    for (const provider of providersZeroFees) {
                                                        const authRate = telescope.standard.data.authorization_rate_per_psp_bar[0].datapoints.find(
                                                            bar => {
                                                                return bar.key === provider.key;
                                                            },
                                                        );
                                                        if (authRate && authRate.value > 0) {
                                                            providersMissingFees.push(provider);
                                                        }
                                                    }
                                                    if (providersMissingFees.length > 0) {
                                                        return (
                                                            <Warning
                                                                text={`Fee data is missing for ${providersMissingFees.reduce(
                                                                    (
                                                                        value,
                                                                        provider,
                                                                        index,
                                                                        array,
                                                                    ) =>
                                                                        `${value}${provider.key}${
                                                                            index < array.length - 1
                                                                                ? ', '
                                                                                : ''
                                                                        }`,
                                                                    '',
                                                                )}`}
                                                            />
                                                        );
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                        <div class='large-5 columns'>
                                            <ProviderCompareSelector
                                                value={this.state.pspCompare}
                                                onChange={(newValue: $ProviderCompareOption) => {
                                                    this.setState({
                                                        pspCompare: newValue,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns'>
                                            <div style={{ height: '250px' }}>
                                                {(() => {
                                                    if (
                                                        !this.props.telescope.standard.capturesReady
                                                    )
                                                        return null;
                                                    const data =
                                                        this.state.pspCompare.value === 'general'
                                                            ? telescope.standard.data
                                                                  .gateway_fees_per_psp_bar
                                                            : this.state.pspCompare.value ===
                                                              'card_scheme'
                                                            ? telescope.standard.data
                                                                  .gateway_fees_per_psp_per_card_scheme_bar
                                                            : telescope.standard.data
                                                                  .gateway_fees_per_psp_per_card_type_bar;
                                                    if (!data)
                                                        return (
                                                            <div
                                                                style={{
                                                                    marginTop: '2em',
                                                                }}
                                                            >
                                                                <Error />
                                                            </div>
                                                        );
                                                    if (
                                                        data.length === 0 ||
                                                        data[0].datapoints.length === 0
                                                    )
                                                        return (
                                                            <Empty text={'No data to display'} />
                                                        );
                                                    return (
                                                        <div key={uniqid()}>
                                                            <BarChart
                                                                type='percentage'
                                                                data={data}
                                                                colors={data.map((entry, index) => {
                                                                    return {
                                                                        key: entry.key,
                                                                        color: getColor(index),
                                                                    };
                                                                })}
                                                                comparison={true}
                                                            />
                                                        </div>
                                                    );
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                    <FeesRecommendations />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='large-12 columns text-center'>
                            <Link
                                class='round main button small'
                                to={`/projects/${this.props.params.project}/boards`}
                            >
                                <div>Understand your fees</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return {
        telescope: store.telescope,
    };
})(withRouter(CapturesContent));
