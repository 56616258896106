// @flow

import type { $Action, $State } from '../../util/Types';
import type { $ApiSetupState } from './consts';
import { FETCH_API_DETAILS, PREPARE_API_SETUP } from './consts';
import { typeFailed, typeFulfilled } from '../../util/ProcessOut';
import { CLOSE_MODAL } from '../../util/Types';

const defaultState: $ApiSetupState = {
    currentApiVersion: '',
    apiVersions: [],
    latestApiVersion: '',
    publicKey: '',
    fetched: false,
    fetching: false,
    error: null,
    apiKeys: [],
};

export default function(state: $ApiSetupState = defaultState, action: $Action): $ApiSetupState {
    switch (action.type) {
        case PREPARE_API_SETUP: {
            return {
                ...state,
                ...defaultState,
                fetching: true,
            };
        }
        case typeFulfilled(PREPARE_API_SETUP): {
            if (!action.payload) return state;
            return {
                ...state,
                fetched: true,
                fetching: false,
                currentApiVersion: action.payload.current_version,
                latestApiVersion: action.payload.latest_version,
                apiVersions: action.payload.versions,
                publicKey: action.payload.publicKey,
                apiKeys: action.payload.apiKeys,
            };
        }

        case typeFailed(PREPARE_API_SETUP): {
            return {
                ...state,
                ...action.payload,
                fetched: true,
                fetching: false,
            };
        }
        case CLOSE_MODAL: {
            return {
                ...state,
                newPrivateKey: null,
            };
        }
    }
    return state;
}
