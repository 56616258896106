import Dispatcher from '../Dispatcher';
import * as ProcessOut from '../util/ProcessOut';
import { EventTypes } from 'redux-segment';
import * as Store from '../stores/Store';
import Analytics from '../util/Analytics';

// Replace all `-` by spaces and capitalize the first letter
function formatTagName(tag) {
    tag = tag.replace(/-/g, ' ');
    return tag.charAt(0).toUpperCase() + tag.slice(1);
}

export function loadGateways() {
    return {
        type: 'FETCH_GATEWAYS',
        payload: ProcessOut.APIcallPromise('/gateways', 'GET', null).then(function(response) {
            if (!response.data.success) return response;

            // Create an object of tags containing arrays of gateways
            var gateways = response.data.gateways;
            var tags = [];
            for (var i = 0; i < gateways.length; i++) {
                for (var j = 0; j < gateways[i].tags.length; j++) {
                    if (!tags[gateways[i].tags[j]]) tags[gateways[i].tags[j]] = [gateways[i]];
                    else tags[gateways[i].tags[j]].push(gateways[i]);
                }
            }

            // Formats the array so that each child is { tagName: [gateways]}
            var keys = Object.keys(tags);
            var tagsArray = keys.map(function(v) {
                return { name: formatTagName(v), value: tags[v] };
            });

            // Add the array to the response body
            response.data.tags = tagsArray;
            return response;
        }),
    };
}

export function loadGatewaysConfigurations() {
    return {
        type: 'FETCH_GATEWAYS_CONFIGURATIONS',
        payload: ProcessOut.APIcallPromise(
            `/gateway-configurations?expand=gateway&with_disabled=true`,
            'GET',
            null,
            null,
            true,
        ),
    };
}

export function disableGateway(gateway, id, resolve) {
    return function(dispatch) {
        var configBody = {
            enabled: false,
        };
        var body = JSON.stringify(configBody, null, 2);
        return {
            type: 'DISABLING_GATEWAY_CONFIG',
            payload: ProcessOut.APIcallPromise(
                `/gateways/${gateway}/gateway-configurations/${id}?expand=gateway`,
                'PUT',
                body,
            ).then(function(response) {
                dispatch(loadGatewaysConfigurations());
                resolve(response.data.success);
            }),
        };
    };
}

export function enableGateway(gateway, id, resolve) {
    return function(dispatch) {
        var configBody = {
            enabled: true,
        };
        var body = JSON.stringify(configBody, null, 2);

        return {
            type: 'ENABLING_GATEWAY_CONFIG',
            payload: ProcessOut.APIcallPromise(
                `/gateways/${gateway}/gateway-configurations/${id}?expand=gateway`,
                'PUT',
                body,
            ).then(function(response) {
                dispatch(loadGatewaysConfigurations());
                resolve(response.data.success);
            }),
        };
    };
}

export function updateGatewayConfig(gateway, id, config, settings, resolve) {
    var configBody = {
        ...settings,
        enabled: true,
        settings: config,
    };
    var body = JSON.stringify(configBody, null);
    return {
        type: 'UPDATE_GATEWAY_CONFIGURATION',
        payload: ProcessOut.APIcallPromise(
            `/gateways/${gateway}/gateway-configurations/${id}?expand=gateway`,
            'PUT',
            body,
        ).then(function(response) {
            resolve(response.data.success);
        }),
    };
}

export function createGatewayConfig(gateway, config, settings, resolve) {
    return function(dispatch) {
        var body = {
            ...settings,
            enabled: true,
            name: config.name,
            settings: config,
        };
        var configBody = JSON.stringify(body, null);
        const currentStoreState = Store.store.getState();
        const userEmail = currentStoreState.user.details.email;
        dispatch({
            type: 'CREATE_GATEWAY_CONFIGURATION',
            payload: ProcessOut.APIcallPromise(
                `/gateways/${gateway}/gateway-configurations?expand=gateway`,
                'POST',
                configBody,
            ).then(function(response) {
                if (response.data.success) {
                    dispatch(
                        enableGateway(gateway, response.data.gateway_configuration.id, resolve),
                    );
                    Analytics('CREATE_GATEWAY_CONFIGURATION_FULFILLED', {
                        processor: gateway,
                        email: userEmail,
                    });
                }
            }),
        });
    };
}

export function deleteGatewayConfig(gateway, id, optResolve) {
    return {
        type: 'DELETE_GATEWAY_CONFIGURATION',
        payload: ProcessOut.APIcallPromise(
            `/gateways/${gateway}/gateway-configurations/${id}`,
            'DELETE',
        ).then(function(response) {
            if (response.data.success && optResolve) {
                optResolve();
            }
        }),
    };
}
