// @flow
import React from 'react';
import type { $Recommendation, $Surface } from './consts';
import { FormattedNumber } from 'react-intl';
import BarChart from '../../analytics/Charts/BarChart';
import uniqid from 'uniqid';
import Analytics from '../../../util/Analytics';
import { connect } from 'react-redux';
import Markdown from 'react-markdown';

type Props = {
    recommendation: $Recommendation,
    currency: string,
    onClose: () => void,
    surface: $Surface,
    user: {
        details: {
            email: string,
        },
    },
};

class RecoModal extends React.Component<Props> {
    componentDidMount() {
        Analytics('RECOMMENDATION_OPENED', {
            email: this.props.user.details.email,
            title: this.props.recommendation.name,
        });
    }
    getLine = (name: string, value: any, noMargin: ?boolean) => {
        return (
            <div class={`row ${noMargin ? '' : 'small-margin-bottom'}`}>
                <div class='large-6 columns'>{name}</div>
                <div class='large-6 columns text-right'>{value}</div>
            </div>
        );
    };
    render() {
        const { recommendation } = this.props;
        return (
            <div class='reco-modal'>
                <a class='close-button' onClick={this.props.onClose}>
                    <img src='//dashboard.processout.com/images/close-purple.png' />
                </a>
                <div class='row'>
                    <div class='large-6 columns'>
                        <div class='row margin-bottom'>
                            <div class='large-12 columns'>
                                <div class='title'>{recommendation.name}</div>
                            </div>
                        </div>
                        <div class='row margin-bottom'>
                            <div class='large-12 columns'>
                                <Markdown source={recommendation.description} />
                            </div>
                        </div>
                        {this.getLine(
                            'Savable transactions:',
                            <FormattedNumber value={recommendation.count} />,
                        )}
                        <div class='small-margin-bottom' />
                        <div class='estimated-recover'>
                            {this.getLine(
                                'Estimated recovery:',
                                <div
                                    style={{
                                        color: 'white',
                                        fontSize: '1.1em',
                                    }}
                                >
                                    <FormattedNumber
                                        value={recommendation.money_impact}
                                        currency={this.props.currency}
                                        style='currency'
                                        minimumFractionDigits={0}
                                        maximumFractionDigits={0}
                                    />
                                </div>,
                                true,
                            )}
                        </div>
                        {recommendation.type === 'provider.integrate-other' ? (
                            <div class='row' style={{ marginTop: '2em' }}>
                                <div class='large-12 columns text-right'>
                                    <a
                                        class='round main button small'
                                        onClick={() => {
                                            Analytics('REQUESTED_HELP_FOR_PROVIDER');
                                            window.Intercom(
                                                'showNewMessage',
                                                `Hi, a recommendation tells me I could recover ${
                                                    recommendation.count
                                                } transactions if I integrate another provider. Can you help?`,
                                            );
                                        }}
                                    >
                                        Talk to us, we're here to help
                                    </a>
                                </div>
                            </div>
                        ) : recommendation.type === 'transaction.retry' ? (
                            <div class='row' style={{ marginTop: '2em' }}>
                                <div class='large-12 columns text-right'>
                                    <a
                                        class='round main button small'
                                        onClick={() => {
                                            Analytics('REQUESTED_HELP_FOR_RETRY');
                                            window.Intercom(
                                                'showNewMessage',
                                                `Hi, a recommendation tells me I could recover ${
                                                    recommendation.count
                                                } transactions by retrying them. Can you help?`,
                                            );
                                        }}
                                    >
                                        Talk to us, we're here to help
                                    </a>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div class='large-6 columns'>
                        <div class='row reco-chart' key={uniqid()}>
                            <div class='large-12 columns'>
                                <div class='row'>
                                    <div class='large-12 columns text-center chart-title'>
                                        Authorization rate impact
                                    </div>
                                </div>
                                <div class='row small-margin-bottom'>
                                    <div
                                        class='large-12 columns'
                                        style={{
                                            height: '250px',
                                        }}
                                    >
                                        <BarChart
                                            data={[
                                                {
                                                    key: 'Current performance',
                                                    datapoints: recommendation.datapoints_without_gain.filter(
                                                        point => point._count > 0,
                                                    ),
                                                },
                                                {
                                                    key: 'Expected performance',
                                                    datapoints: recommendation.datapoints_with_gain.filter(
                                                        point => point._count > 0,
                                                    ),
                                                },
                                            ]}
                                            type={'percentage'}
                                            colors={[
                                                {
                                                    key: 'Expected performance',
                                                    color: 'rgba(192, 57, 43,.7)',
                                                },
                                                {
                                                    key: 'Current performance',
                                                    color: '#5c6bc0',
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div class='row'>
                                    <div class='large-offset-1 large-11 columns '>
                                        <div class='surface-chart-legend surface' />
                                        Current performance for this group of transactions
                                    </div>
                                </div>
                                <div class='row'>
                                    <div class='large-offset-1 large-11 columns '>
                                        <div class='surface-chart-legend benchmark' />
                                        Expected performance after applying this action
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(store => {
    return { user: store.user };
})(RecoModal);
