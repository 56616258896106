// @flow

import React from 'react';
import { connect } from 'react-redux';
import * as BoardActions from './actions';
import type { $Dispatcher, $OnChangeEvent, $State } from '../../../util/Types';
import ModalFooter from '../../../components/ModalFooter';
import type { $Board } from '../Boards/consts';

type Props = {
    boardId: ?string,
} & $Dispatcher;

export const ON_NEW_BOARD = 'ON_NEW_BOARD';

class SavingModal extends React.Component<Props> {
    _boardForm: any;

    openModal = () => {
        const saveChart = (boardId: string) => {
            this.props.dispatch(BoardActions.saveChartOnBoard(boardId));
            this.props.dispatch({ type: 'CLOSE_MODAL' });
        };
        if (this.props.boardId) {
            saveChart(this.props.boardId);
            return;
        }
        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                large: false,
                header: 'Save chart',
                content: (
                    <SavingForm
                        ref={el => {
                            this._boardForm = el;
                        }}
                        submit={() => {
                            saveChart(ON_NEW_BOARD);
                        }}
                    />
                ),
                footer: (
                    <ModalFooter
                        submitTitle='Save'
                        submitCallback={() => {
                            saveChart(this._boardForm.getWrappedInstance().retrieveInfos().boardId);
                        }}
                    />
                ),
            },
        });
    };

    render() {
        return (
            <a
                class='small border round button save-button fade-in-button'
                onClick={this.openModal}
                style={{ marginRight: '1em' }}
            >
                Save
            </a>
        );
    }
}

export default connect(store => {
    return {
        project: store.currentProject,
    };
})(SavingModal);

class SimpleSavingForm extends React.Component<
    {
        project: {
            project?: {
                id: string,
            },
        },
        submit: () => void,
        boards: {
            boards: Array<$Board>,
        } & $State,
    } & $Dispatcher,
    {
        value: string,
    },
> {
    constructor() {
        super();
        this.state = {
            value: '',
        };
    }

    componentDidMount() {
        const availableBoards: Array<$Board> = this.props.boards.boards.filter(
            board => !board.id.includes('board_default'),
        );

        if (availableBoards.length > 0) {
            this.setState({
                value: availableBoards[0].id,
            });
        }
    }

    boardChanged = (event: $OnChangeEvent) => {
        this.setState({ value: event.target.value });
    };

    retrieveInfos = () => {
        return { boardId: this.state.value };
    };

    render() {
        const { project } = this.props;
        if (!project.project) return null;
        if (!this.props.boards.fetched || this.props.boards.error) return null;
        const availableBoards: Array<$Board> = this.props.boards.boards.filter(
            board => !board.id.includes('board_default'),
        );
        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <div class='row small-margin-bottom'>
                        <div class='large-11 large-centered columns'>
                            <p>Chose the board you want to save your chart on.</p>
                        </div>
                    </div>

                    <div>
                        <div class='row medium-margin-bottom'>
                            <div
                                class={`large-12 columns${
                                    availableBoards.length === 0 ? ' text-center' : ''
                                }`}
                            >
                                {availableBoards.length > 0 ? (
                                    <select
                                        placeholder={'Select a board...'}
                                        value={this.state.value}
                                        onChange={this.boardChanged}
                                    >
                                        {availableBoards.map(board => {
                                            return (
                                                <option value={board.id} key={board.id}>
                                                    {board.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                ) : (
                                    <span class='greyed'>No boards available.</span>
                                )}
                            </div>
                        </div>
                        <div class='row medium-margin-bottom'>
                            <div class='large-12 columns text-center small'>— or —</div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='large-12 columns text-center'>
                            <a
                                class='round border button small'
                                onClick={() => {
                                    this.props.submit();
                                }}
                            >
                                Create a new board
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const SavingForm = connect(
    store => {
        return {
            project: store.currentProject,
            boards: store.analytics_v2.boards,
        };
    },
    null,
    null,
    { withRef: true },
)(SimpleSavingForm);
