// @flow

import React from 'react';
import type { $ChartType, $DataPoint } from '../Boards/consts';
import uniqid from 'uniqid';
import * as ISOCountries from '../../../util/ISOCountries';
import Loading from '../../../components/Loader';
import * as Utils from './Utils';
import moment from './BarChart';
import { FormattedNumber, IntlProvider } from 'react-intl';
import * as ReactDOMServer from 'react-dom/server';
import qs from 'qs';
import { push } from 'react-router-redux';
import Analytics from '../../../util/Analytics';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import type { $Dispatcher } from '../../../util/Types';
import { CLOSE_CHART_POPUP, OPEN_CHART_POPUP } from '../Boards/charts/Popup/consts';
import ChartPopup from '../Boards/charts/Popup/ChartPopup';
import getColor from '../Boards/charts/colors';
import { computeMapChartColor } from './Utils';

type Props = {
    data: Array<any>,
    currency: ?string,
    type: $ChartType,
    location: any,
    currentProject: {
        project: {
            id: string,
        },
    },
} & $Dispatcher;

type State = {
    id: string,
    map: any,
    _countTotal: number,
    _valueTotal: number,
};

type $PointValue = { key: string, value: number, _count: number, _corresponding_filter: string };
type $CountryMultiDimPoint = { key: string, value: null, datapoints: Array<$PointValue> };
type $CountryPoint = $PointValue | $CountryMultiDimPoint;
type $MapChartTip = {
    key: string,
    country: $CountryPoint,
};

class MapChart extends React.Component {
    props: Props;
    state: State;

    constructor() {
        super();
        this.state = {
            id: uniqid(),
            map: null,
            _countTotal: 0,
            _valueTotal: 0,
        };
    }

    componentDidMount(): void {
        const { data } = this.props;
        let countTotal = 0;
        let valueTotal = 0;
        for (const entry of data) {
            countTotal += entry._count;
            valueTotal += entry.value;
        }
        this.setState(
            {
                _countTotal: countTotal,
                _valueTotal: valueTotal,
            },
            () => {
                this.draw();
            },
        );
    }

    componentDidUpdate() {
        this.draw();
    }

    tip = (d: $MapChartTip) => {
        const { type, currency } = this.props;
        const { _countTotal, _valueTotal } = this.state;
        const barKey = d.key;
        let content;
        switch (type) {
            case 'percentage': {
                if (d.country.value !== null && d.country.value !== undefined) {
                    content = (
                        <PercentTooltip
                            point={d.country}
                            _countTotal={_countTotal}
                            _valueTotal={_valueTotal}
                        />
                    );
                } else {
                    content = <PercentMultiDimTooltip point={d.country} />;
                }
                break;
            }

            case 'amount': {
                if (d.country.value !== null && d.country.value !== undefined) {
                    content = (
                        <AmountTooltip
                            point={d.country}
                            _countTotal={_countTotal}
                            _valueTotal={_valueTotal}
                            currency={currency || 'USD'}
                        />
                    );
                } else {
                    content = (
                        <AmountMultiDimTooltip point={d.country} currency={currency || 'USD'} />
                    );
                }
                break;
            }

            default: {
                if (d.country.value !== null && d.country.value !== undefined) {
                    content = (
                        <DefaultTooltip
                            point={d.country}
                            _countTotal={_countTotal}
                            _valueTotal={_valueTotal}
                        />
                    );
                } else {
                    content = <DefaultMultiDimTooltip point={d.country} />;
                }
            }
        }

        return ReactDOMServer.renderToString(
            <IntlProvider locale={navigator ? navigator.language || 'en-US' : 'en-US'}>
                <div class="row">
                    <div class="large-12 columns">
                        <div class="row">
                            <div class="large-12 columns">
                                <h4>{d.country.key}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="large-12 columns">{content}</div>
                        </div>
                    </div>
                </div>
            </IntlProvider>,
        );
    };

    draw() {
        const { data, dispatch, location, currentProject } = this.props;
        const { id, map } = this.state;

        if (!data || !document.getElementById(`chart${id}`)) return;
        if (!map) {
            this.setState({
                map: new Picasso.MapChart(`#chart${id}`, {
                    tip: this.tip,
                    onclick: (data: { country: $CountryPoint }) => {
                        const country = data.country;
                        if (country.value !== null && country.value !== undefined) {
                            this.state.map.cleanupTip();
                            const onFilter = () => {
                                const filter = country._corresponding_filter;
                                if (!filter) return;
                                const splits = filter.split(';');
                                const filterIndex = splits.findIndex(
                                    entry => !entry.includes('start:') && !entry.includes('end:'),
                                );
                                const filterValue = splits[filterIndex] || '';
                                const filterParam = qs.parse(this.props.location.query.search);
                                filterParam.filter = filterValue;
                                const newParam = qs.stringify(filterParam);
                                this.props.dispatch(
                                    push(`${this.props.location.pathname}?${newParam}`),
                                );
                                this.props.dispatch({
                                    type: 'ANALYTICS_FILTER_CHANGE',
                                    payload: { filter: filterValue },
                                });
                                // Track event
                                Analytics('BAR_CHART_CLICK_FILTER', {
                                    filterValue,
                                });
                            };

                            const onList = () => {
                                const filter = country._corresponding_filter;
                                if (!filter) return;
                                this.props.dispatch(
                                    push(
                                        `/projects/${
                                            currentProject.project.id
                                        }/transactions?filter=${filter}`,
                                    ),
                                );
                                // Track event
                                Analytics('MAP_CHART_CLICK_LIST', { filter });
                            };
                            dispatch({
                                type: OPEN_CHART_POPUP,
                                payload: {
                                    chartId: id,
                                    onFilter: onFilter,
                                    onList: onList,
                                },
                            });
                        }
                    },
                }),
            });
            return;
        }

        let min = 0;
        let max = 0;
        for (const entry of data) {
            if (entry.value !== null && entry.value !== undefined) {
                if (entry.value < Math.log(min)) min = entry.value;
                else if (entry.value > max) max = entry.value;
            } else {
                const currentValue = entry.datapoints.reduce((count, p) => count + p._count, 0);
                if (currentValue < min) min = currentValue;
                else if (currentValue > max) max = currentValue;
            }
        }

        for (var country of data) {
            var c = ISOCountries.isoCountries[country._original_key];
            if (!c) continue;
            map.addCountry({
                key: country._original_key,
                name: c['alpha3'],
                country: country,
                color: data => computeMapChartColor(min, max, data),
            });
        }

        map.resetSVG();
        map.draw();
    }

    render() {
        const { id, chart } = this.state;
        const { data } = this.props;
        if (!data) return <Loading />;
        return (
            <div
                onClick={e => {
                    if (this.props.popup.chartId === this.state.id) {
                        if (!e.target.classList.contains('popup-button')) {
                            this.props.dispatch({ type: CLOSE_CHART_POPUP });
                        }
                    }
                }}
            >
                <svg
                    class={`chart line-chart chart-popup ${
                        id === this.props.popup.chartId ? 'chart-popup-open' : ''
                    }`}
                    id={`chart${id}`}
                    width="100%"
                    height="400px"
                />
                <ChartPopup correspondingChartId={id} canUpdateAnalyticsFilter={true} />
            </div>
        );
    }
}

export default connect(store => {
    return { popup: store.analytics_v2.popup, currentProject: store.currentProject };
})(withRouter(MapChart));

class AmountTooltip extends React.Component {
    props: {
        point: $PointValue,
        currency: string,
        _countTotal: number,
        _valueTotal: number,
    };

    render() {
        const { point, _countTotal, _valueTotal, currency } = this.props;

        return (
            <IntlProvider locale={navigator ? navigator.language || 'en-US' : 'en-US'}>
                <ul>
                    <li>
                        <FormattedNumber
                            value={point.value}
                            style={'currency'}
                            currency={currency}
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                        />
                        <div class="greyed" style={{ display: 'inline-block' }}>
                            {` — `}
                            <FormattedNumber
                                value={point.value / _valueTotal}
                                style="percent"
                                maximumFractionDigits={2}
                                minimumFractionDigits={2}
                            />{' '}
                            of total
                        </div>
                    </li>
                    <li>
                        <div style={{ display: 'inline-block' }}>
                            <FormattedNumber value={point._count} /> transactions
                        </div>
                        <div className="greyed" style={{ display: 'inline-block' }}>
                            {` — `}
                            <FormattedNumber
                                value={point._count / _countTotal}
                                style="percent"
                                maximumFractionDigits={2}
                                minimumFractionDigits={2}
                            />{' '}
                            of total
                        </div>
                    </li>
                </ul>
            </IntlProvider>
        );
    }
}

class AmountMultiDimTooltip extends React.Component {
    props: {
        point: $CountryMultiDimPoint,
        currency: string,
    };

    render() {
        const { point, currency } = this.props;

        const totalCount = point.datapoints.reduce((count, point) => count + point._count, 0);

        return (
            <IntlProvider locale={navigator ? navigator.language || 'en-US' : 'en-US'}>
                <ul>
                    {point.datapoints
                        .sort((a, b) => b._count - a._count)
                        .map(point => {
                            return (
                                <li class="small-margin-bottom">
                                    <h6 style={{ marginBottom: 0 }}>{point.key}</h6>
                                    <FormattedNumber
                                        key={point.key}
                                        value={point.value}
                                        style={'currency'}
                                        currency={currency}
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={2}
                                    />{' '}
                                    <div class="greyed" style={{ display: 'inline-block' }}>
                                        (
                                        <FormattedNumber
                                            value={point._count / totalCount}
                                            style={'percent'}
                                        />
                                        )
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </IntlProvider>
        );
    }
}

class PercentTooltip extends React.Component {
    props: {
        point: $PointValue,
        _countTotal: number,
        _valueTotal: number,
    };

    render() {
        const { point, _countTotal, _valueTotal } = this.props;

        return (
            <IntlProvider locale={navigator ? navigator.language || 'en-US' : 'en-US'}>
                <ul>
                    <li>
                        <FormattedNumber
                            value={point.value}
                            style={'percent'}
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                        />
                    </li>
                    <li>
                        <div style={{ display: 'inline-block' }}>
                            <FormattedNumber value={point._count} /> transactions
                        </div>
                        <div className="greyed" style={{ display: 'inline-block' }}>
                            {` — `}
                            <FormattedNumber
                                value={point._count / _countTotal}
                                style="percent"
                                maximumFractionDigits={2}
                                minimumFractionDigits={2}
                            />{' '}
                            of total
                        </div>
                    </li>
                </ul>
            </IntlProvider>
        );
    }
}

class PercentMultiDimTooltip extends React.Component {
    props: {
        point: $CountryMultiDimPoint,
    };

    render() {
        const { point } = this.props;

        const totalCount = point.datapoints.reduce((count, point) => count + point._count, 0);

        return (
            <IntlProvider locale={navigator ? navigator.language || 'en-US' : 'en-US'}>
                <ul>
                    {point.datapoints
                        .sort((a, b) => b._count - a._count)
                        .map(point => {
                            return (
                                <li class="small-margin-bottom">
                                    <h6 style={{ marginBottom: 0 }}>{point.key}</h6>
                                    <FormattedNumber
                                        key={point.key}
                                        value={point.value}
                                        style={'percent'}
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={2}
                                    />{' '}
                                    <div class="greyed" style={{ display: 'inline-block' }}>
                                        (
                                        <FormattedNumber
                                            value={point._count / totalCount}
                                            style={'percent'}
                                        />
                                        )
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </IntlProvider>
        );
    }
}

class DefaultTooltip extends React.Component {
    props: {
        point: $PointValue,
        _countTotal: number,
        _valueTotal: number,
    };

    render() {
        const { point, _countTotal, _valueTotal } = this.props;

        return (
            <IntlProvider locale={navigator ? navigator.language || 'en-US' : 'en-US'}>
                <ul>
                    <li>
                        <div style={{ display: 'inline-block' }}>
                            <FormattedNumber value={point._count} /> transactions
                        </div>
                        <div className="greyed" style={{ display: 'inline-block' }}>
                            {` — `}
                            <FormattedNumber
                                value={point._count / _countTotal}
                                style="percent"
                                maximumFractionDigits={2}
                                minimumFractionDigits={2}
                            />{' '}
                            of total
                        </div>
                    </li>
                </ul>
            </IntlProvider>
        );
    }
}

class DefaultMultiDimTooltip extends React.Component {
    props: {
        point: $CountryMultiDimPoint,
    };

    render() {
        const { point } = this.props;

        const totalCount = point.datapoints.reduce((count, point) => count + point._count, 0);

        return (
            <IntlProvider locale={navigator ? navigator.language || 'en-US' : 'en-US'}>
                <ul>
                    {point.datapoints
                        .sort((a, b) => b._count - a._count)
                        .map(point => {
                            return (
                                <li class="small-margin-bottom">
                                    <h6 style={{ marginBottom: 0 }}>{point.key}</h6>
                                    <FormattedNumber
                                        key={point.key}
                                        value={point.value}
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={2}
                                    />{' '}
                                    <div class="greyed" style={{ display: 'inline-block' }}>
                                        (
                                        <FormattedNumber
                                            value={point._count / totalCount}
                                            style={'percent'}
                                        />
                                        )
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </IntlProvider>
        );
    }
}
