// @flow
import React from 'react';
import { Creatable } from 'react-select';
import { UNIT_LIST } from './consts';
import { connect } from 'react-redux';
import type { $Metric } from '../ChartBuilder/consts';
import { UPDATE_CHART_BUILDER_DETAILS } from '../consts';
import type { $Dispatcher } from '../../../../util/Types';

type Props = {
    onChange: string => void,
    unit: string,
    disabled: boolean,
} & $Dispatcher;

type $Option = { label: string, value: string };

class Unit extends React.Component {
    props: Props;

    updateValue = (newValue: $Option) => {
        this.props.dispatch({
            type: UPDATE_CHART_BUILDER_DETAILS,
            payload: {
                unit: newValue.value,
            },
        });
    };

    render() {
        let options = UNIT_LIST.map(unit => {
            return { value: unit, label: unit };
        });

        return (
            <div class="row collapse" style={{ position: 'relative', zIndex: 5 }}>
                <div class="medium-12 columns">
                    <Creatable
                        name="unit"
                        placeholder="Unit ..."
                        options={options}
                        disabled={this.props.disabled}
                        value={{
                            label: this.props.value,
                            value: this.props.value,
                        }}
                        onChange={this.updateValue}
                        clearable={false}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        unit: store.dataExplorer.unit,
    };
};

export default connect(mapStateToProps)(Unit);
