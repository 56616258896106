// @flow
import React from 'react';
import { connect } from 'react-redux';
import GeoChart from '../../../components/Analytics/GeoChart';
import type { $TelescopeState } from '../../../stores/Reducers/TelescopeReducer/Index';
import { computeMapChartColor } from '../../analytics/Charts/Utils';
import * as ISOCountries from '../../../util/ISOCountries';
import uniqid from 'uniqid';
import { FormattedNumber, IntlProvider } from 'react-intl';
import ReactDOMServer from 'react-dom/server';
import ChartSwitch from '../Components/ChartSwitch';
import LineChart from '../../analytics/Charts/LineChart';
import BarChart from '../../analytics/Charts/BarChart';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import Error from '../../../components/Error';
import Loading from '../../../components/Loader';

type Props = {
    telescope: $TelescopeState,
};

class RefundsContent extends React.Component<Props> {
    shouldComponentUpdate(nextProps: Props) {
        return (
            nextProps.telescope.standard.refundsReady !== this.props.telescope.standard.refundsReady
        );
    }
    render() {
        const { telescope } = this.props;
        return (
            <div class='row text-left'>
                <div class='large-12 columns'>
                    <div class='row'>
                        <div class='large-5 columns'>
                            <div class='row small-margin-bottom'>
                                <div class='large-12 columns'>
                                    <div class='chart-title'>Refunds percent per country</div>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='large-12 columns'>
                                    <div style={{ height: '250px' }}>
                                        {!this.props.telescope.standard.refundsReady ? null : (
                                            <GeoChart
                                                key={uniqid()}
                                                data={telescope.standard.data.refunded_per_country}
                                                color={computeMapChartColor.bind(
                                                    null,
                                                    telescope.standard.data.refunded_per_country.reduce(
                                                        (min, country) =>
                                                            country.value < min
                                                                ? country.value
                                                                : min,
                                                        1,
                                                    ),
                                                    telescope.standard.data.refunded_per_country.reduce(
                                                        (max, country) =>
                                                            country.value > max
                                                                ? country.value
                                                                : max,
                                                        0,
                                                    ),
                                                )}
                                                tip={function(d) {
                                                    let c = ISOCountries.isoCountries[d.key];

                                                    const refundsDisplay = ReactDOMServer.renderToString(
                                                        <IntlProvider
                                                            locale={
                                                                navigator
                                                                    ? navigator.language || 'en-US'
                                                                    : 'en-US'
                                                            }
                                                        >
                                                            <FormattedNumber
                                                                value={d.country.refunds_volume}
                                                                minimumFractionDigits={2}
                                                                style='percent'
                                                            />
                                                        </IntlProvider>,
                                                    );
                                                    return (
                                                        c.full_name +
                                                        '<br />' +
                                                        `<span class="greyed">Refunds percent:</span> ${refundsDisplay}<br />`
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='large-6 large-offset-1 columns'>
                            <div class='row'>
                                <div class='large-12 columns'>
                                    <div class='row small-margin-bottom'>
                                        <div class='large-6 columns'>
                                            <div class='chart-title'>Refund rate per provider</div>
                                        </div>
                                    </div>
                                    {telescope.standard.data.refund_amount_per_psp_bar ? (
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <div style={{ height: '250px' }}>
                                                    <div key={uniqid()}>
                                                        <BarChart
                                                            type='percentage'
                                                            data={
                                                                telescope.standard.data
                                                                    .refund_amount_per_psp_bar
                                                            }
                                                            comparison={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <Error />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return {
        telescope: store.telescope,
    };
})(RefundsContent);
