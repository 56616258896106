import React from 'react';

export default class Footer extends React.Component {
    render() {
        return (
            <div class="row footer-panel small-collapse">
                <div class="large-12 columns">
                    <hr />
                    <a href="https://processout.com" class="greyed">
                        &copy; ProcessOut.com
                    </a>
                </div>
            </div>
        );
    }
}
