import { typeFailed } from '../../../util/ProcessOut';

export default function(
    state = {
        fetching: false,
        fetched: false,
    },
    action,
) {
    switch (action.type) {
        case 'FETCH_TRANSACTION_DETAILS_PENDING': {
            return {
                ...state,
                error: null,
                fetching: true,
                fetched: false,
            };
        }
        case 'FETCH_TRANSACTION_DETAILS_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                transaction: action.payload.transaction,
            };
        }
        case typeFailed('FETCH_TRANSACTION_DETAILS'): {
            return {
                ...state,
                error: action.payload,
                fetched: false,
                fetching: false,
            };
        }
    }
    return state;
}
