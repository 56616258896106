// @flow
import { typeFailed } from '../../util/ProcessOut';
import type { $Action, $State } from '../../util/Types';

export type $Plan = {
    name: string,
    id: string,
    amount: number,
    currency: string,
};
export type $PlansState = {
    plans: Array<$Plan>,
} & $State;

const defaultState: $PlansState = {
    fething: false,
    fetched: false,
    error: undefined,
    plans: [],
};

export default function(state: $PlansState = defaultState, action: $Action): $PlansState {
    switch (action.type) {
        case 'FETCH_PLANS_PENDING': {
            return {
                ...state,
                ...defaultState,
                fetching: true,
            };
        }
        case 'FETCH_PLANS_FULFILLED': {
            if (!action.payload.success) {
                return {
                    ...state,
                    fetching: false,
                    fetched: true,
                    plans: [],
                };
            }
            return {
                ...state,
                ...action.payload,
                fetching: false,
                fetched: true,
            };
        }
        case typeFailed('FETCH_PLANS'): {
            return {
                ...state,
                error: action.payload,
                fetched: false,
                fetching: false,
            };
        }
    }

    return state;
}
