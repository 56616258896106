// @flow
import React from 'react';
import type { $Filter } from '../analytics/DataExplorer/ChartBuilder/Filters/consts';
// $FlowFixMe
import './searchbuilder.scss';
import SearchBuilderFilter from './SearchBuilderFilter';
import { FIELD_TOKENS } from '../SearchBar/consts';
import FilterPathSelector from './FilterPathSelector';
import { SEARCH_BUILDER_TOKENS } from './consts';
import uniqid from 'uniqid';

type Props = {
    value: Array<$Filter>,
    onChange: (Array<$Filter>) => void,
    document: string,
    onSave: () => void,
    onClose?: ?() => void,
    modified: boolean,
};

type State = {
    open: boolean,
};

class SearchBuilder extends React.Component<Props, State> {
    _panel: any;
    _filterButton: any;
    constructor() {
        super();
        this.state = {
            open: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: MouseEvent) => {
        const { onClose } = this.props;
        if (
            this._panel &&
            !this._panel.contains(event.target) &&
            this._filterButton &&
            !this._filterButton.contains(event.target)
        ) {
            this.setState({ open: false });
            if (onClose) {
                onClose();
            }
        }
    };

    newFilterSelected = (path: string) => {
        const newFilter = {
            id: uniqid(),
            path,
            operand: '==',
            value: [],
        };
        const newArray: Array<$Filter> = this.props.value.slice(0);
        newArray.push(newFilter);
        this.props.onChange(newArray);
    };

    filterUpdated = (filter: $Filter) => {
        const { onChange, value } = this.props;
        const index = value.findIndex(f => f.id === filter.id);
        if (index > -1) {
            const newFilters = value.slice(0);
            newFilters[index] = filter;
            onChange(newFilters);
        }
    };

    filterRemoved = (id: string) => {
        const { onChange, value } = this.props;
        const index = value.findIndex(f => f.id === id);
        if (index > -1) {
            const newFilters = value.slice(0);
            newFilters.splice(index, 1);
            onChange(newFilters);
        }
    };

    clearFilters = () => {
        this.props.onChange([]);
    };

    save = () => {
        this.setState({ open: false });
        this.props.onSave();
    };

    render() {
        const { value } = this.props;
        const { open } = this.state;

        return (
            <div style={{ display: 'inline-block', position: 'relative' }}>
                <a
                    onClick={() => {
                        this.setState({ open: !this.state.open });
                    }}
                    class='filter-button button round border small'
                    ref={e => (this._filterButton = e)}
                >
                    <span>
                        Filter
                        {value.length > 0 && ` (${value.length})`}
                    </span>
                </a>
                {open && (
                    <div class='search-builder' ref={e => (this._panel = e)}>
                        <div class='search-builder-content'>
                            <div class='row header-row collapse'>
                                <div class='medium-4 columns'>
                                    <button
                                        class='small round border button'
                                        onClick={this.clearFilters}
                                    >
                                        Clear
                                    </button>
                                </div>
                                <div
                                    class='medium-4 columns text-center'
                                    style={{ position: 'relative', top: '.3em' }}
                                >
                                    Filters
                                </div>
                                <div class='medium-4 columns text-right'>
                                    <button
                                        class={`small round ${
                                            this.props.modified ? 'main' : 'border'
                                        } button`}
                                        onClick={this.save}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                            <div class='row collapse'>
                                <div class='medium-12 columns'>
                                    {SEARCH_BUILDER_TOKENS[this.props.document].map(field => {
                                        const filter = value.find(v => v.path === field.value);
                                        if (filter) {
                                            // Filter is active
                                            return (
                                                <SearchBuilderFilter
                                                    document={this.props.document}
                                                    key={filter.id}
                                                    filter={filter}
                                                    onChange={newFilter => {
                                                        if (newFilter)
                                                            this.filterUpdated(newFilter);
                                                        else this.filterRemoved(filter.id);
                                                    }}
                                                />
                                            );
                                        } else {
                                            return (
                                                <FilterPathSelector
                                                    option={field}
                                                    key={uniqid()}
                                                    onSelect={() => {
                                                        this.newFilterSelected(field.value);
                                                    }}
                                                />
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default SearchBuilder;
