// @flow

import type { $Action } from '../../../util/Types';
import type { $Chart, $ChartType } from '../Boards/consts';
import type { $Params } from './consts';
import { CLEAR_PREVIEW, REQUEST_CHART_PREVIEW, REQUEST_CHART_SAVE } from './consts';
import * as ProcessOut from '../../../util/ProcessOut';
import { parseDates } from '../Charts/Utils';
import Analytics from '../../../util/Analytics';

export function requestChartPreview(
    name: string,
    description: string,
    formula: string,
    type: $ChartType,
    unit: string,
    size: number,
    boardId: string,
    params: $Params,
): $Action {
    return {
        type: REQUEST_CHART_PREVIEW,
        payload: {
            name,
            description,
            formula,
            type,
            unit,
            size,
            boardId,
            params,
        },
    };
}

export function requestChartSave(
    chartId?: ?string,
    projectId: string,
    name: string,
    description: string,
    formula: string,
    type: $ChartType,
    unit: string,
    size: number,
    boardId: string,
    boardName: string,
    params: $Params,
    position?: ?{ x: number, y: number },
    height: number,
): $Action {
    return {
        type: REQUEST_CHART_SAVE,
        payload: {
            projectId,
            name,
            description,
            formula,
            type,
            unit,
            size,
            boardId,
            boardName,
            params,
            id: chartId,
            position,
            height,
        },
    };
}

export function saveChart(chart: $Chart, boardId: string, params: $Params): $Action {
    const { from, to, timespan } = parseDates(params.interval.from, params.interval.to);
    // Track the event
    Analytics(chart.id ? 'EDITED_CHART' : 'CREATED_CHART', chart);
    return {
        type: 'PREPARE_CHART_PREVIEW',
        payload: ProcessOut.APIcallPromise(
            `/boards/${boardId}/charts${
                chart.id ? `/${chart.id}` : ''
            }?interval=${timespan}&start_at=${from}&end_at=${to}`,
            chart.id ? 'PUT' : 'POST',
            chart,
        ),
    };
}

export function fetchPreviewedChart(chart: $Chart, boardId: string, params: $Params): $Action {
    const { from, to, timespan, timezone } = parseDates(params.interval.from, params.interval.to);
    return {
        type: 'PREPARE_CHART_PREVIEW',
        payload: ProcessOut.APIcallPromise(
            `/boards/${boardId}/charts?interval=${timespan}&timezone=${encodeURIComponent(
                timezone,
            )}&start_at=${from}&end_at=${to}&filter=${params.filter}`,
            'POST',
            chart,
        ),
    };
}

export function clearPreview(): $Action {
    return {
        type: CLEAR_PREVIEW,
    };
}
