import React from 'react';
import ContentLayout from './ContentLayout';
import Loader from '../components/Loader';
import Empty from '../components/Empty';
import Error from '../components/Error';
import Pagination from '../components/Pagination';
import ApiRequest from '../components/ApiRequest';
import * as ApiRequestsActions from '../Actions/ApiRequestsActions';
import { connect } from 'react-redux';

const requestsPerPage = 15;

@connect(store => {
    return {
        requests: store.apiRequests,
        projects: store.projects,
    };
})
export default class ApiRequests extends React.Component {
    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Logs | ProcessOut';
        var firstItem = this.props.location.query.after_item;
        if (!firstItem) firstItem = '';
        this.props.dispatch(ApiRequestsActions.fetchRequests(requestsPerPage, true, firstItem));
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    componentWillReceiveProps(nextProps) {
        var firstItem = this.props.location.query.after_item;
        if (!firstItem) firstItem = '';
        if (
            !nextProps.requests.list.fetching &&
            !nextProps.requests.list.fetched &&
            !nextProps.requests.list.error
        ) {
            this.props.dispatch(ApiRequestsActions.fetchRequests(requestsPerPage, true, firstItem));
        }
    }

    render() {
        const requests = this.props.requests.list.api_requests;

        var content;
        var header;
        if (
            (this.props.requests.list.fetching || !this.props.requests.list.fetched) &&
            !this.props.requests.list.error
        ) {
            content = <Loader />;
        } else if (this.props.requests.list.error) {
            content = <Error />;
        } else if (requests.length == 0) {
            content = <Empty text='No API request has been made yet.' />;
        } else {
            header = (
                <div
                    class='row capitalize greyed bold small-margin-bottom'
                    style={{ fontSize: '0.8em' }}
                >
                    <div class='medium-2 medium-offset-2 columns'>Version</div>
                    <div class='medium-6 columns'>URL</div>
                    <div class='medium-2 columns text-right'>Date</div>
                </div>
            );
            content = (
                <div>
                    <div class='row'>
                        <div class='large-12 columns'>
                            {requests.map(function(request) {
                                return <ApiRequest key={request.id} request={request} />;
                            })}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <ContentLayout title={<h4>Logs</h4>}>
                <div class='row margin-bottom'>
                    <div class='large-12 columns'>
                        {header}
                        {content}
                        <Pagination
                            reloadFunction={ApiRequestsActions.fetchRequests.bind(
                                null,
                                requestsPerPage,
                            )}
                            hasMore={
                                this.props.requests.list.fetched
                                    ? this.props.requests.list.has_more
                                    : false
                            }
                            hide={
                                !this.props.requests.list.fetched ||
                                this.props.requests.list.fetching
                            }
                            itemsArray={this.props.requests.list.fetched ? requests : []}
                            location={this.props.location}
                        />
                    </div>
                </div>
            </ContentLayout>
        );
    }
}
