// @flow

import { combineReducers } from 'redux';
import Boards from './reducer';
import BoardDetails from './Board/reducer';
import ChartPopup from './charts/Popup/reducer';

export default combineReducers({
    boards: Boards,
    boardDetails: BoardDetails,
    popup: ChartPopup,
});
