// @flow
/**
 * Created by jeremylejoux on 07/06/17.
 */

import React from 'react';
import { connect } from 'react-redux';
import Serialize from 'form-serialize';
import Base64 from 'base-64';
import { replace } from 'react-router-redux';
import * as ApplePayActions from '../../../Actions/ApplePayActions';
import ContentLayout from '../../../pages/ContentLayout';

type State = {
    merchantCertOpen: boolean,
};

export class SecondStep extends React.Component {
    props: Props;
    state: State;

    constructor() {
        super();
        this.state = {
            merchantCertOpen: false,
        };
    }

    static defaultProps = {
        dispatch: null,
    };

    componentDidMount() {
        let { dispatch, currentProject, params } = this.props;
        if (!currentProject.project) return;
        let applePay = currentProject.project.applepay_settings;
        if (!applePay || !applePay.merchant_csr)
            dispatch(replace(`/projects/${params.project}/settings/apple-pay`));
    }

    submit(event: Event) {
        event.preventDefault();
        let data = Serialize(event.target, { hash: true });
        let { params } = this.props;
        this.props.dispatch(
            ApplePayActions.addApplePay(
                data.merchant_certificate ? Base64.encode(data.merchant_certificate) : null,
                Base64.encode(data.processing_certificate),
                params.project,
                function done() {
                    let { params, dispatch } = this.props;
                    // we check if apple pay has been setup for mobile or web
                    if (data.merchant_certificate) {
                        dispatch(replace(`/projects/${params.project}/settings/apple-pay/domains`));
                    } else {
                        dispatch(replace(`/projects/${params.project}/settings/apple-pay`));
                    }
                }.bind(this),
            ),
        );
    }

    fileSelected(type: string, e: FileEvent) {
        e.preventDefault();

        // Extend FileReader
        if (!FileReader.prototype.readAsBinaryString) {
            FileReader.prototype.readAsBinaryString = function(fileData) {
                var binary = '';
                var pt = this;
                var reader = new FileReader();
                reader.onload = function(e) {
                    var bytes = new Uint8Array(reader.result);
                    var length = bytes.byteLength;
                    for (var i = 0; i < length; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    //pt.result  - readonly so assign binary
                    pt.content = binary;
                    $(pt).trigger('onload');
                };
                reader.readAsArrayBuffer(fileData);
            };
        }

        let file = e.target.files[0];
        if (!file) {
            return;
        }
        let reader = new FileReader();
        reader.onload = function(e) {
            let contents = e.target.result;
            switch (type) {
                case 'processing': {
                    this.refs.processing_cert.value = contents;
                    break;
                }
                case 'merchant': {
                    this.refs.merchant_cert.value = contents;
                    break;
                }
            }
        }.bind(this);
        reader.readAsBinaryString(file);
    }

    render() {
        if (
            !this.props.currentProject.project ||
            !this.props.currentProject.project.applepay_settings
        ) {
            return null;
        }

        let processingDownload;
        let merchantDownload;
        if (this.props.currentProject.fetched) {
            processingDownload = (
                <div class="row">
                    <div class="large-12 columns">
                        <a
                            href={`data:application/octet-stream;charset=utf-16le;base64,${this
                                .props.currentProject.project.applepay_settings.processing_csr}`}
                            download="processing.certSigningRequest"
                        >
                            <input
                                type="button"
                                class="button border small round"
                                value="Download processing CSR"
                            />
                        </a>
                    </div>
                </div>
            );
            merchantDownload = (
                <div class="row">
                    <div class="large-12 columns">
                        <a
                            href={`data:application/octet-stream;charset=utf-16le;base64,${this
                                .props.currentProject.project.applepay_settings.merchant_csr}`}
                            download="merchant.certSigningRequest"
                        >
                            <input
                                type="button"
                                class="button border small round"
                                value="Download merchant CSR"
                            />
                        </a>
                    </div>
                </div>
            );
        }

        let separator = (
            <div class="row">
                <div class="large-12 columns">
                    <hr />
                </div>
            </div>
        );

        return (
            <div class="row">
                <form onSubmit={this.submit.bind(this)}>
                    <div class="large-12 columns large-centered">
                        <ContentLayout
                            title={
                                <div>
                                    <div class="row small-margin-bottom">
                                        <h4>Apple Pay setup</h4>
                                    </div>
                                    <div class="row">
                                        <h5>Creating the Payment Processing Certificate</h5>
                                    </div>
                                </div>
                            }
                        >
                            <div class="row margin-bottom">
                                <div class="medium-6 columns">
                                    <p>
                                        Go to the merchant ID list page, select the newly-created
                                        merchant ID.<br />
                                        <br />
                                        At the top of the page, click <em>
                                            Create Certificate
                                        </em>{' '}
                                        next to <em>Payment Processing Certificate</em>.
                                    </p>
                                </div>
                                <div class="medium-5 medium-offset-1 columns">
                                    <img src="//dashboard.processout.ninja/images/applepay-1.jpg" />
                                </div>
                            </div>
                            {separator}
                            <div class="row margin-bottom">
                                <div class="medium-6 columns">
                                    <p>Download the signed request file.</p>
                                </div>
                                <div class="medium-5 medium-offset-1 columns">
                                    {processingDownload}
                                </div>
                            </div>
                            {separator}
                            <div class="row">
                                <div class="medium-6 columns">
                                    <p>
                                        Upload the processing.certSigningRequest file downloaded
                                        from the ProcessOut Dashboard, click "Continue".
                                    </p>
                                </div>
                                <div class="medium-5 medium-offset-1 columns">
                                    <img src="//dashboard.processout.ninja/images/applepay-2.jpg" />
                                </div>
                            </div>
                            {separator}
                            <div class="row">
                                <div class="medium-6 columns">
                                    <p>
                                        Download the newly-created certificate and upload it back to
                                        the ProcessOut Dashboard. The{' '}
                                        <span class="bold">processing.certSigningRequest</span> file
                                        can be discarded.
                                    </p>
                                </div>
                                <div class="medium-5 medium-offset-1 columns">
                                    <input
                                        type="file"
                                        onChange={this.fileSelected.bind(this, 'processing')}
                                        class="button small border round"
                                        required
                                    />
                                </div>
                            </div>
                        </ContentLayout>
                        <ContentLayout
                            title={
                                <div class="row medium-collapse">
                                    <div class="medium-6 columns ">
                                        <h5>Creating the Merchant Identity Certificate </h5>
                                    </div>
                                    <div className="medium-6 columns text-right">
                                        <h5
                                            className="greyed text-right"
                                            style={{ marginRight: '1em' }}
                                        >
                                            Only required for Apple Pay on the Web
                                        </h5>
                                    </div>
                                </div>
                            }
                            style={{ marginBottom: '2em', padding: '1em 22px' }}
                        >
                            {this.state.merchantCertOpen ? (
                                <div class="row">
                                    <div class="large-12 columns">
                                        <div className="row margin-bottom">
                                            <div className="medium-6 columns">
                                                <p>
                                                    Go back to the merchant ID list page, to find
                                                    the merchant ID. Scroll down to{' '}
                                                    <em>Merchant Identity Certificate</em>, click{' '}
                                                    <em>Create Certificate</em>.
                                                </p>
                                            </div>
                                            <div className="medium-5 medium-offset-1 columns">
                                                <img src="//dashboard.processout.ninja/images/applepay-3.jpg" />
                                            </div>
                                        </div>
                                        {separator}
                                        <div className="row margin-bottom">
                                            <div className="medium-6 columns">
                                                <p>Download the signed request file.</p>
                                            </div>
                                            <div className="medium-5 medium-offset-1 columns">
                                                {merchantDownload}
                                            </div>
                                        </div>
                                        {separator}
                                        <div className="row">
                                            <div className="medium-6 columns">
                                                <p>
                                                    From there, the process is the same as for the{' '}
                                                    <span className="bold">
                                                        Payment Processing Certificate
                                                    </span>. Use the{' '}
                                                    <span className="bold">
                                                        merchant.certSigningRequest
                                                    </span>{' '}
                                                    file.
                                                </p>
                                            </div>
                                            <div className="medium-5 medium-offset-1 columns">
                                                <img src="//dashboard.processout.ninja/images/applepay-2.jpg" />
                                            </div>
                                        </div>
                                        {separator}
                                        <div className="row large-margin-bottom">
                                            <div className="medium-6 columns">
                                                <p>
                                                    Upload the resulting certificate file back to
                                                    the ProcessOut Dashboard. The{' '}
                                                    <span className="bold">
                                                        merchant.certSigningRequest
                                                    </span>{' '}
                                                    file can be discarded.
                                                </p>
                                            </div>
                                            <div className="medium-5 medium-offset-1 columns">
                                                <input
                                                    type="file"
                                                    onChange={this.fileSelected.bind(
                                                        this,
                                                        'merchant',
                                                    )}
                                                    className="button small border round"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <a
                                        onClick={() => {
                                            this.setState({ merchantCertOpen: true });
                                        }}
                                        style={{ margin: 0 }}
                                    >
                                        Click here to add a merchant certificate
                                    </a>
                                </div>
                            )}
                        </ContentLayout>
                        <div className="row margin-bottom">
                            <div className="large-12 columns text-right">
                                <input
                                    type="hidden"
                                    name="merchant_certificate"
                                    ref="merchant_cert"
                                />
                                <input
                                    type="hidden"
                                    name="processing_certificate"
                                    ref="processing_cert"
                                />
                                <input
                                    type="submit"
                                    className="button main round"
                                    value="Submit"
                                    style={{
                                        position: 'relative',
                                        right: '1em',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

type Props = {
    params: {
        project: string,
    },
    applePay: {
        fetching: boolean,
        fetched: boolean,
        keys: any,
    },
    currentProject: {
        fetched: boolean,
        fetching: boolean,
        project?: {
            id: string,
            applepay_settings: any,
        },
    },
    dispatch: any | (() => void),
};

type FileEvent = {
    preventDefault: () => void,
    target: {
        files: Array<any>,
    },
};

export default connect(function(store) {
    return {
        currentProject: store.currentProject,
        applePay: store.applePay,
    };
})(SecondStep);
