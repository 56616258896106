import type { $TelescopeRequestAction } from './consts';
import { REQUEST_TELESCOPE_PREPARATION } from './consts';

/**
 * action to fetch telescope data (/telescope call)
 * @param timespan
 * @returns {{type: string, payload: {timespan: *}}}
 */
export function fetchData(timespan) {
    return {
        type: 'FETCH_TELESCOPE_REQUESTED',
        payload: {
            timespan: timespan,
        },
    };
}

export function requestTelescopePrepare(
    timespan: string,
    silent: boolean,
    tab: 'auth' | 'capt' | 'refu',
): $TelescopeRequestAction {
    return {
        type: REQUEST_TELESCOPE_PREPARATION,
        payload: {
            timespan,
            silent,
            tab,
        },
    };
}
