// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { $BoardCreatorState, $BoardPreset } from './reducer';
import type { $Dispatcher } from '../../../util/Types';
import { BOARDS_PRESETS } from './consts';
import * as Actions from './actions';
import SearchBar from './SearchBar';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Loading from '../../../components/Loader';
import Markdown from 'react-markdown';

type Props = {
    boardCreator: $BoardCreatorState,
} & $Dispatcher;

class Presets extends React.Component {
    props: Props;

    selectPreset = (preset: $BoardPreset) => {
        if (
            !this.props.boardCreator.selectedPreset ||
            this.props.boardCreator.selectedPreset.name !== preset.name
        ) {
            this.props.dispatch(Actions.selectBoardPreset({ ...preset }));
        }
    };

    savePreset = () => {
        this.props.dispatch(Actions.saveBoardPreset(this.props.boardCreator.selectedPreset));
    };

    render() {
        const boardCreator: $BoardCreatorState = this.props.boardCreator;
        return (
            <div class="row boards-presets">
                <div class="large-12 columns">
                    <div class="row">
                        <div class="large-12 columns no-padding">
                            <SearchBar />
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="medium-6 columns"
                            style={{
                                borderRight: '1px solid #eaeaea',
                                minHeight: '17.5em',
                                background: '#f2f2f2',
                            }}
                        >
                            <ReactCSSTransitionGroup
                                transitionName="preset-transition"
                                transitionEnterTimeout={150}
                                transitionLeaveTimeout={150}
                            >
                                {BOARDS_PRESETS.filter(
                                    preset =>
                                        !boardCreator.presetSearch ||
                                        preset.name
                                            .toLocaleLowerCase()
                                            .includes(
                                                boardCreator.presetSearch.toLocaleLowerCase(),
                                            ),
                                ).map(preset => (
                                    <div
                                        class={`row board-preset${boardCreator.selectedPreset &&
                                        boardCreator.selectedPreset.name === preset.name
                                            ? ' active'
                                            : ''}`}
                                        key={preset.name}
                                        onClick={() => {
                                            this.selectPreset(preset);
                                        }}
                                    >
                                        <div class="merdium-12 columns">{preset.name}</div>
                                    </div>
                                ))}
                            </ReactCSSTransitionGroup>
                        </div>
                        <div class="medium-6 columns description">
                            {boardCreator.selectedPreset ? (
                                <div class="row">
                                    <div class="medium-12 columns">
                                        <div class="row small-margin-bottom">
                                            <div class="medium-12 columns preset-name">
                                                <h4>{boardCreator.selectedPreset.name}</h4>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div
                                                class="large-12 columns"
                                                style={{ height: '10em' }}
                                            >
                                                <Markdown
                                                    source={boardCreator.selectedPreset.description}
                                                />
                                            </div>
                                        </div>
                                        <div class="row">
                                            {boardCreator.saving ? (
                                                <div class="medium-3 medium-offset-9 columns text-right">
                                                    <Loading size={16} />
                                                </div>
                                            ) : (
                                                <div class="medium-12 columns text-right">
                                                    <a
                                                        class="round main button small"
                                                        onClick={this.savePreset}
                                                        style={{
                                                            margin: 0,
                                                            position: 'relative',
                                                            bottom: '1em',
                                                        }}
                                                    >
                                                        Use this preset →
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { boardCreator: store.boardCreator };
})(Presets);
