// @flow

import type { $ConditionNode, $EndCondition, $MergedConditionNode } from './FormulaCompiler/consts';
import type { $Filter, $Operand } from './ChartBuilder/Filters/consts';
import uniqid from 'uniqid';
import type { $Metric } from './ChartBuilder/consts';
import type { $BuilderMetric } from './ChartBuilder/reducer';
import { BUILDER_METRICS } from './ChartBuilder/consts';

function filtersCanBeMerged(
    operand: 'or' | 'and',
    filter1: $EndCondition,
    filter2: $EndCondition,
): boolean {
    return (
        (operand === 'or' &&
            filter1.original_field === filter2.original_field &&
            filter1.comparator === '==' &&
            filter2.comparator === '==') ||
        (operand === 'and' &&
            filter1.original_field === filter2.original_field &&
            filter1.comparator === '!=' &&
            filter2.comparator === '!=')
    );
}

// Takes a condition and compiles it to a filter
export function conditionToFilter(condition: $EndCondition): $Filter {
    return {
        id: uniqid(),
        path: condition.original_field,
        operand:
            condition.value === null
                ? condition.comparator === '==' ? 'is-null' : 'is-not-null'
                : condition.comparator,
        value: [condition.value],
    };
}

export function mergeTwoNodes(ast: $ConditionNode): ?Array<$Filter> {
    if (!ast.operator) {
        // we are on a leaf
        return [
            { id: uniqid(), path: ast.original_field, operand: ast.comparator, value: [ast.value] },
        ];
    }

    if (!ast.left.operator && !ast.right.operator) {
        // both are leaves
        const left: $EndCondition = ast.left;
        const right: $EndCondition = ast.right;
        if (filtersCanBeMerged(ast.operator, left, right)) {
            return [
                {
                    ...conditionToFilter(left),
                    value: [left.value, right.value],
                },
            ];
        } else if (ast.operator === 'and') {
            return [conditionToFilter(left), conditionToFilter(right)];
        } else return null;
    }

    if (!ast.left.operator && ast.right.operator) {
        // Left node is a leaf but right node isn't
        const left: $EndCondition = ast.left;
        const rightMerged = mergeTwoNodes(ast.right);
        if (!rightMerged) return null; // Right could not be merged
        if (
            (ast.operator === 'or' &&
                left.original_field === rightMerged[0].path &&
                left.comparator === '==' &&
                rightMerged[0].operand === '==') ||
            (ast.operator === 'and' &&
                left.original_field === rightMerged[0].path &&
                left.comparator === '!=' &&
                rightMerged.operand === '!=')
        ) {
            rightMerged[0].value.splice(0, 0, left.value);
        } else {
            rightMerged.push(conditionToFilter(left));
        }
        return rightMerged;
    } else if (ast.left.operator && !ast.right.operator) {
        // Right node is a leaf but left node isn't
        const right: $EndCondition = ast.right;
        const leftMerged = mergeTwoNodes(ast.left);
        if (!leftMerged) return null; // Left could not be merged
        if (
            (ast.operator === 'or' &&
                right.original_field === leftMerged[0].path &&
                right.comparator === '==' &&
                leftMerged[0].operand === '==') ||
            (ast.operator === 'and' &&
                right.original_field === leftMerged[0].path &&
                right.comparator === '!=' &&
                leftMerged.operand === '!=')
        ) {
            leftMerged[0].value.push(right.value);
        } else {
            leftMerged.push(conditionToFilter(right));
        }
        return leftMerged;
    } else {
        // Both nodes aren't leaves
        const leftMerged = mergeTwoNodes(ast.left);
        const rightMerged = mergeTwoNodes(ast.right);
        if (!leftMerged || !rightMerged) return null;
        if (
            (ast.operator === 'or' &&
                rightMerged[0].path === leftMerged[0].path &&
                rightMerged[0].operand === '==' &&
                leftMerged[0].operand === '==') ||
            (ast.operator === 'and' &&
                rightMerged[0].path === leftMerged[0].path &&
                rightMerged[0].operand === '!=' &&
                leftMerged.operand === '!=')
        ) {
            leftMerged[0].value = leftMerged[0].value.concat(rightMerged[0].value);
            return leftMerged;
        } else {
            return leftMerged.concat(rightMerged);
        }
    }
}

function addSingleValueFilter(
    filter: { path: string, operand: $Operand, value: string | number },
    outFilters: Array<$Filter>,
): void {
    const index = outFilters.findIndex(
        f =>
            f.path === filter.path &&
            f.operand === filter.operand &&
            !f.value.includes(filter.value),
    );
    if (index > -1) {
        outFilters[index].value.push(filter.value);
        return;
    } else {
        outFilters.push({
            id: uniqid(),
            path: filter.path,
            operand: filter.operand,
            value: [filter.value],
        });
        return;
    }
}

// Takes an array of array of filters and return an array of shared filters
export function extractCommonFilters(filtersArray: Array<Array<$Filter>>): Array<$Filter> {
    const output: Array<$Filter> = [];
    for (const filters of filtersArray) {
        for (let i = 0; i < filters.length; i++) {
            const filter = filters[i];
            // Check that they all have this filter with this value
            for (const value of filter.value) {
                let checked = true;
                for (const filters2 of filtersArray) {
                    if (
                        filters2.findIndex(
                            f =>
                                f.path === filter.path &&
                                f.operand === filter.operand &&
                                f.value.includes(value),
                        ) < 0
                    ) {
                        checked = false;
                        break;
                    }
                }
                if (checked) {
                    // check that we don't already have it in the output
                    if (
                        output.findIndex(
                            f =>
                                f.path === filter.path &&
                                f.operand === filter.operand &&
                                f.value.includes(value),
                        ) < 0
                    )
                        addSingleValueFilter(
                            { path: filter.path, operand: filter.operand, value },
                            output,
                        );
                }
            }
        }
    }

    return output;
}

export function detectPresetMetric(
    metrics: Array<$Metric>,
    generalFormula: string,
): ?$BuilderMetric {
    topMetricsLoop: for (const bMetric of BUILDER_METRICS) {
        if (bMetric.metrics.length !== metrics.length) continue;

        for (const metric of bMetric.metrics) {
            // Metrics
            const correspondingMetric = metrics.find(m => {
                if (m.path !== metric.path || m.type !== metric.type) return false;
                // Filters
                for (const filter of metric.filters) {
                    const correspondingFilter = m.filters.find(
                        f => f.path === filter.path && f.operand === filter.operand,
                    );
                    if (!correspondingFilter) return false;

                    // values
                    for (const value of filter.value) {
                        if (
                            filter.value.length !== correspondingFilter.value.length ||
                            !filter.value.includes(value)
                        )
                            return false;
                    }
                }

                return true;
            });
            if (!correspondingMetric) continue topMetricsLoop;
        }

        return bMetric;
    }

    return null;
}

export function splitFilters(filters: Array<$Filter>): Array<$Filter> {
    const result: Array<$Filter> = [];

    for (const filter of filters) {
        if (filter.value.length === 0) {
            const index = result.findIndex(
                f => f.path === filter.path && f.operand === filter.operand,
            );
            if (index < 0) {
                result.push(filter);
                continue;
            }
        }
        for (const value of filter.value) {
            const index = result.findIndex(
                f =>
                    f.path === filter.path &&
                    f.operand === filter.operand &&
                    f.value.includes(value),
            );
            if (index > -1) {
                // filter already there, skip
            } else {
                result.push({
                    id: uniqid(),
                    path: filter.path,
                    operand: filter.operand,
                    value: [value],
                });
            }
        }
    }

    return result;
}

// Takes an array of filters and merge possible values
export function mergeCommonFilters(filters: Array<$Filter>): Array<$Filter> {
    const result: Array<$Filter> = [];

    for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        const index = result.findIndex(f => {
            if (f.path !== filter.path) return false;
            for (const value of filter.value) {
                if (!filter.value.includes(value)) return false;
            }
            return true;
        });
        if (index < 0) {
            result.push(filter);
        } else if (result[index].operand !== filter.operand) {
            result.push(filter);
        } else {
            result[index].value = result[index].value.concat(filter.value);
        }
    }

    return result;
}
