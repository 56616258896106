// @flow

import type { $Action } from '../../../util/Types';
import type { $Period, $UpdateDatesAction } from './consts';
import { CHANGE_COMPARE_DATE, SET_COMPARING, UPDATE_ANALYTICS_DATES } from './consts';
import moment from 'moment';

export function changeCompareDate(from: any, to: any): $Action {
    return {
        type: CHANGE_COMPARE_DATE,
        payload: {
            from,
            to,
        },
    };
}

export function setComparing(comparing: boolean): $Action {
    return {
        type: SET_COMPARING,
        payload: {
            comparing,
        },
    };
}

export function computeCompareDatesFromPeriod(
    from: any,
    to: any,
    period: $Period,
    comparePeriod: $Period,
): { from: any, to: any } {
    const duration = to.diff(from, 'hours');
    switch (comparePeriod) {
        case 'previous': {
            switch (period) {
                case 'previous-month': {
                    return {
                        from: from
                            .clone()
                            .subtract(1, 'months')
                            .date(1),
                        to: to.clone().date(0),
                    };
                }

                default: {
                    return {
                        from: from.clone().subtract(duration, 'hours'),
                        to: from,
                    };
                }
            }
        }

        case 'previous-year': {
            return {
                from: from.clone().subtract(1, 'years'),
                to: to.clone().subtract(1, 'years'),
            };
        }

        default: {
            return {
                from,
                to,
            };
        }
    }
}

export function computeDatesFromPeriod(period: $Period): { from: any, to: any } {
    const today = moment()
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59);
    switch (period) {
        case 'today': {
            return {
                from: moment(today)
                    .hours(0)
                    .minutes(0)
                    .seconds(0),
                to: today,
            };
        }

        case 'yesterday': {
            return {
                from: moment(today)
                    .subtract(1, 'days')
                    .hours(0)
                    .minutes(0)
                    .seconds(0),
                to: moment(today).subtract(1, 'days'),
            };
        }

        case 'last-7': {
            return {
                from: moment(today).subtract(6, 'days'),
                to: today,
            };
        }

        case 'previous-week': {
            return {
                from: moment(today)
                    .day('Monday')
                    .subtract(1, 'weeks'),
                to: moment(today).day('Sunday'),
            };
        }

        case 'last-30': {
            return {
                from: moment(today).subtract(30, 'days'),
                to: today,
            };
        }

        case 'previous-month': {
            return {
                from: moment(today)
                    .subtract(1, 'months')
                    .date(1),
                to: moment(today).date(0),
            };
        }

        case 'last-6-months': {
            return {
                from: moment(today).subtract(6, 'months'),
                to: today,
            };
        }

        case 'previous-year': {
            return {
                from: moment(today)
                    .subtract(1, 'years')
                    .startOf('year'),
                to: moment(today)
                    .subtract(1, 'years')
                    .endOf('year'),
            };
        }

        case 'last-year': {
            return {
                from: moment(today).subtract(1, 'years'),
                to: moment(today),
            };
        }

        default: {
            return {
                from: moment(today),
                to: moment(today),
            };
        }
    }
}

export function updateAnalyticsDates(fromDate: any, toDate: any): $UpdateDatesAction {
    return {
        type: UPDATE_ANALYTICS_DATES,
        payload: {
            fromDate,
            toDate,
        },
    };
}
