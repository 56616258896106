// @flow

import type { $Action } from '../../../../util/Types';
import { put, takeEvery, select, take } from 'redux-saga/effects';
import type { $FetchParams } from '../consts';
import * as Actions from './actions';
import { typeFailed, typeFulfilled, typePending } from '../../../../util/ProcessOut';
import { REQUEST_CHART_DATA_FETCH } from './consts';
import moment from 'moment';
import { BAR_CHART, SINGLE_VALUE } from '../consts';
import { replaceForCountries, replaceForCurrencies, replaceForGwayKeys } from './utils';
import * as Sentry from '@sentry/browser';

type Action = {
    payload: {
        board: string,
        chartId: string,
        params: $FetchParams,
    },
} & $Action;

/*
 * This fetches the chart data for a given id
 * The results are then dispatched
 */
export function* fetchChartData(action: Action): Generator<*, *, *> {
    const { board, chartId, params } = action.payload;
    try {
        yield put({
            type: typePending(`${REQUEST_CHART_DATA_FETCH}_${chartId}`),
        });
        // We fetch the chart data
        const chartDataResult = yield put.resolve(
            Actions.fetchChart(board, chartId, params, false),
        );

        // Checking for errors
        if (
            !!chartDataResult.sucess ||
            (chartDataResult.value && !chartDataResult.value.data.success)
        ) {
            yield put({
                type: typeFailed(`${REQUEST_CHART_DATA_FETCH}_${chartId}`),
            });
            return;
        }

        // Retrieving the payload object
        let chartData = chartDataResult.value.data;

        // we fetch the gateway ids and names list from the store.
        let gway_configurations_names = yield select(store => store.gateway_configurations_names);
        // if we don't have them we wait for it
        while (!gway_configurations_names.fetched) {
            yield take();
            gway_configurations_names = yield select(store => store.gateway_configurations_names);
        }

        // we update the charts keys with gateway names
        chartData.data = replaceForGwayKeys(
            chartData.data,
            gway_configurations_names.gateway_configuration_names,
        );

        // we update the charts keys with full country names
        chartData.data = replaceForCountries(chartData.data);

        // And for currencies
        chartData.data = replaceForCurrencies(chartData.data);

        for (const entry of chartData.data) {
            // we do the same for subkeys
            if (entry.datapoints) {
                if (chartData.chart.settings.formula.includes('gateway_configuration_id'))
                    entry.datapoints = replaceForGwayKeys(
                        entry.datapoints,
                        gway_configurations_names.gateway_configuration_names,
                    );
                if (chartData.chart.settings.formula.includes('country'))
                    entry.datapoints = replaceForCountries(entry.datapoints);
                if (chartData.chart.settings.formula.includes('currency'))
                    entry.datapoints = replaceForCurrencies(entry.datapoints);
            }
        }

        let hadToTrim = false;
        if (chartData.is_comparison && chartData.chart.type === BAR_CHART) {
            // trim data
            const maximumPoints = 100 * chartData.chart.size / 12;
            hadToTrim = chartData.data.length > 10;
            chartData.data = chartData.data.slice(0, 10);
            const maximumSubLength = maximumPoints / (chartData.data.length || 1);
            for (const entry of chartData.data) {
                hadToTrim = hadToTrim || entry.datapoints.length > maximumSubLength;
                entry.datapoints = entry.datapoints.slice(0, maximumSubLength);
            }
        }

        // Check if we are on a multi dimensional chart
        if (
            chartData.data.length > 0 &&
            typeof chartData.data[0].key === 'string' &&
            chartData.data[0].key.includes('AND')
        ) {
            // TODO: support multi dim charts
            yield put({
                type: typeFulfilled(`${REQUEST_CHART_DATA_FETCH}_${chartId}`),
                payload: {
                    ...chartData,
                    chart: {
                        ...chartData.chart,
                        timeCompareAndDimensions: params.timeCompare.comparing,
                    },
                    hadToTrimData: hadToTrim,
                },
            });
            return;
        }

        // If we're not time comparing we dispatch the payload
        if (!params.timeCompare.comparing) {
            yield put({
                type: typeFulfilled(`${REQUEST_CHART_DATA_FETCH}_${chartId}`),
                payload: {
                    ...chartData,
                    chart: {
                        ...chartData.chart,
                        // we reset this field in case the chart has already been fetched previously and needs to be set to false
                        timeCompareAndDimensions: false,
                    },
                    hadToTrimData: hadToTrim,
                },
            });
            return;
        }

        // Looking for the oldest date
        const periods = chartData.data.map(point => point.key);
        const currentPeriodStartDate = periods.reduce((value, current) => {
            if (moment(current).isAfter(moment(value))) {
                return current;
            }
            return value;
        }, periods[0]);
        const currentPeriodStartDateIndex = periods.findIndex(
            period => period === currentPeriodStartDate,
        );

        // Retrieving the other one
        const previousPeriodStartDateIndex = periods.findIndex(
            period => period !== currentPeriodStartDate,
        );

        if (chartData.chart.type === SINGLE_VALUE) {
            chartData.data[previousPeriodStartDateIndex] = {
                datapoints: [{ ...chartData.data[previousPeriodStartDateIndex] }],
                key: chartData.data[previousPeriodStartDateIndex].key,
            };
            chartData.data[currentPeriodStartDateIndex] = {
                datapoints: [{ ...chartData.data[currentPeriodStartDateIndex] }],
                key: chartData.data[currentPeriodStartDateIndex].key,
            };
        }

        const previousData = replaceDates(
            chartData.data[previousPeriodStartDateIndex],
            chartData.data[currentPeriodStartDateIndex],
        );

        // Constructing tooltips labels
        const previousPeriodLabel = `${moment
            .unix(params.timeCompare.from)
            .format('MMMM Do YYYY')} → ${moment
            .unix(params.timeCompare.to)
            .format('MMMM Do YYYY')}`;
        const currentPeriodLabel = `${moment
            .unix(params.interval.from)
            .format('MMMM Do YYYY')} → ${moment.unix(params.interval.to).format('MMMM Do YYYY')}`;

        const newPayload = {
            ...chartData,
            chart: {
                ...chartData.chart,
                is_comparison: true,
            },
            data: [
                { key: previousPeriodLabel, datapoints: previousData },
                {
                    key: currentPeriodLabel,
                    datapoints: chartData.data[currentPeriodStartDateIndex].datapoints,
                },
            ],
        };

        // Dispatch the final payload
        yield put({
            type: typeFulfilled(`${REQUEST_CHART_DATA_FETCH}_${chartId}`),
            payload: newPayload,
        });
    } catch (error) {
        yield put({
            type: typeFailed(`${REQUEST_CHART_DATA_FETCH}_${chartId}`),
            payload: { error },
        });
        Sentry.captureException(error);
    }
}

/**
 * Replaces all the keys of an object to match the current period dates
 * @param data
 * @param currentData
 * @returns {Array}
 */
const replaceDates = (data, currentData: { datapoints: Array<{ key: string }>, key: string }) => {
    if (data.datapoints) {
        return data.datapoints
            .filter((entry, index) => {
                return (
                    currentData.datapoints[index] !== null &&
                    currentData.datapoints[index] !== undefined
                );
            })
            .map((dataEntry, index) => {
                if (moment(dataEntry.key).isValid()) {
                    // Merging dates
                    return {
                        ...dataEntry,
                        key: currentData.datapoints[index].key,
                    };
                }
                return dataEntry;
            });
    }
    return Object.keys(data.datapoints).map(key => {
        return replaceDates(data.datapoints[key], currentData);
    });
};

export default function* watchFetchChartRequest(): Generator<*, *, *> {
    yield takeEvery(REQUEST_CHART_DATA_FETCH, fetchChartData);
}
