// @flow

export const REQUEST_FETCH_VALUES_FOR_FIELD = 'REQUEST_FETCH_VALUES_FOR_FIELD';
export const FETCH_VALUES_FOR_FIELD = 'FETCH_VALUES_FOR_FIELD';

// style of the dropdown menu
export const MENU_STYLE = {
    borderRadius: '3px',
    boxShadow: '0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)',
    background: 'rgba(255, 255, 255, 1)',
    padding: '2px 0',
    fontSize: '90%',
    position: 'absolute',
    zIndex: 6,
    overflow: 'auto',
    overflowX: 'hidden',
    maxHeight: '300px',
    left: 0,
    top: '100%',
    width: 'auto',
    minWidth: 'auto',
    transitionProperty: 'transform',
    transitionTimingFunction: 'ease',
    transitionDuration: '80ms',
};

// Style of the wrapper around all the search bar
export const WRAPPER_STYLE = {
    display: 'inline-block',
    width: '100%',
    position: 'relative',
};

export type $SearchTokenType = 'FIELD' | 'OPERAND' | 'VALUE' | 'SEPARATION' | 'LOGICAL';
export const FIELD_TOKEN = 'FIELD';
export const OPERAND_TOKEN = 'OPERAND';
export const VALUE_TOKEN = 'VALUE';
export const SEPARATION_TOKEN = 'SEPARATION';
export const LOGICAL_TOKEN = 'LOGICAL';

export type $TokenInfo = {
    label: string,
    value: string,
    description: string,
    canFetchValues: boolean,
};

// Tokens for FIELD_MODE
export const FIELD_TOKENS: { transactions: Array<$TokenInfo> } = {
    transactions: [
        {
            value: 'id',
            label: 'id',
            description: 'Transaction id generated by ProcessOut',
            canFetchValues: false,
        },
        {
            value: 'avs_postal_check',
            label: 'avs_postal_check',
            description: 'Whether or not a postal check was perform for a transaction',
            canFetchValues: true,
        },
        {
            value: 'avs_street_check',
            label: 'avs_street_check',
            description: 'Whether or not a street check was perform for a transaction',
            canFetchValues: true,
        },
        {
            value: 'avs_name_check',
            label: 'avs_name_check',
            description: 'Whether or not a name check was perform for a transaction',
            canFetchValues: true,
        },
        {
            value: 'card_last4',
            label: 'card_last4',
            description: 'Last 4 digits of a credit card',
            canFetchValues: false,
        },
        {
            value: 'error_code',
            label: 'error_code',
            description: 'Transaction error codes: do-not-honor…',
            canFetchValues: true,
        },
        {
            value: 'payment_type',
            label: 'payment_type',
            description: 'Transaction payment types: Card, Paypal…',
            canFetchValues: true,
        },
        {
            value: 'name',
            label: 'name',
            description: 'Transaction names',
            canFetchValues: true,
        },
        {
            value: 'currency',
            label: 'currency',
            description: 'Currency used for the transactions: USD, EUR, CAD…',
            canFetchValues: true,
        },
        {
            value: 'status',
            label: 'status',
            description: 'Transaction status: completed, pending, failed…',
            canFetchValues: true,
        },
        {
            value: 'country',
            label: 'country',
            description: 'Country in which the transactions occured: FR, US, UK…',
            canFetchValues: true,
        },
        {
            value: 'continent',
            label: 'continent',
            description: 'Continent in which the transactions occured',
            canFetchValues: true,
        },
        {
            value: 'attempts_count',
            label: 'attempts_count',
            description: 'Number of attempts for a transaction',
            canFetchValues: false,
        },
        {
            value: 'subscription_cycle',
            label: 'subscription_cycle',
            description: 'Number of iteration of a subscription',
            canFetchValues: false,
        },
        {
            value: 'card_scheme',
            label: 'card_scheme',
            description: 'Card scheme: Visa, Mastercard, Amex…',
            canFetchValues: true,
        },
        {
            value: 'duplicate_distance_seconds',
            label: 'duplicate_distance_seconds',
            description: '',
            canFetchValues: true,
        },
        {
            value: 'card_co_scheme',
            label: 'card_co_scheme',
            description: 'Card co-schemes: CB…',
            canFetchValues: true,
        },
        {
            value: 'card_iin',
            label: 'card_iin',
            description: 'Card BIN/IIN',
            canFetchValues: true,
        },
        {
            value: 'card_type',
            label: 'card_type',
            description: 'Card types: Debit, Credit…',
            canFetchValues: true,
        },
        {
            value: 'card_category',
            label: 'card_category',
            description: 'Card categories: ',
            canFetchValues: true,
        },
        {
            value: 'card_bank',
            label: 'card_bank',
            description: 'Bank that issued the card used for the transactions',
            canFetchValues: true,
        },
        {
            value: 'cvc_check',
            label: 'cvc_check',
            description: 'CVC status for transactions: passed, checked…',
            canFetchValues: true,
        },
        {
            value: 'acquirer_name',
            label: 'acquirer_name',
            description: 'Name of acquirers that handled the transactions',
            canFetchValues: true,
        },
        {
            value: 'gateway_configuration_id',
            label: 'gateway_configuration_id',
            description: '',
            canFetchValues: true,
        },
        {
            value: 'gateway_name',
            label: 'gateway_name',
            description: 'Name of the gateways used: Stripe, Adyen, Braintree…',
            canFetchValues: true,
        },
        {
            value: 'gateway_fee_currency',
            label: 'gateway_fee_currency',
            description: 'Currency of the fees paid to the gateway',
            canFetchValues: true,
        },
        {
            value: 'created_at',
            label: 'created_at',
            description: 'Transaction creation dates',
            canFetchValues: false,
        },
        {
            value: 'created_at_day_hour',
            label: 'created_at_day_hour',
            description: 'Hour of the day transaction was created (int)',
            canFetchValues: false,
        },
        {
            value: 'created_at_day_hour_segment',
            label: 'created_at_day_hour_segment',
            description: 'Hour of the day transaction was created (string)',
            canFetchValues: true,
        },
        {
            value: 'created_at_month_day',
            label: 'created_at_month_day',
            description: 'Day of the month transaction was created (int)',
            canFetchValues: false,
        },
        {
            value: 'created_at_week_day',
            label: 'created_at_week_day',
            description: 'Day of the week transaction was created (int)',
            canFetchValues: false,
        },
        {
            value: 'created_at_week_day_name',
            label: 'created_at_week_day_name',
            description: 'Day of the week transaction was created (name/string)',
            canFetchValues: true,
        },
        {
            value: 'three_d_s_status',
            label: 'three_d_s_status',
            description: 'Whether or not the transactions went through 3DS',
            canFetchValues: true,
        },
        {
            value: 'eci',
            label: 'eci',
            description: 'Transaction type (such as recurring)',
            canFetchValues: true,
        },
        {
            value: 'mcc',
            label: 'mcc',
            description: 'Merchant Category Code',
            canFetchValues: true,
        },
        {
            value: 'merchant_account_id',
            label: 'merchant_account_id',
            description: 'ID of the merchant account',
            canFetchValues: true,
        },
        {
            value: 'is_on_us',
            label: 'is_on_us',
            description: 'Whether the transaction is performed on-us or not',
            canFetchValues: true,
        },
        {
            value: 'invoice_id',
            label: 'Invoice ID',
            description: 'ID used for the transaction',
            canFetchValues: true,
        },
        {
            value: 'origin',
            description: 'Origin of the transaction',
            label: 'origin',
            canFetchValues: false,
        },
        {
            value: 'customer_email',
            description: 'Email used by the customer linked to the transaction',
            label: 'customer_email',
            canFetchValues: false,
        },
    ],
    cards: [
        { value: 'id', label: 'id', canFetchValues: true },
        { value: 'name', label: 'name', canFetchValues: false },
        { value: 'address1', label: 'address1', canFetchValues: false },
        { value: 'address2', label: 'address2', canFetchValues: false },
        { value: 'city', label: 'city', canFetchValues: false },
        { value: 'state', label: 'state', canFetchValues: true },
        { value: 'zip', label: 'zip', canFetchValues: true },
        { value: 'country', label: 'country', canFetchValues: true },
        { value: 'iin', label: 'IIN/BIN', canFetchValues: true },
        { value: 'scheme', label: 'scheme', canFetchValues: true },
        { value: 'co_scheme', label: 'co_scheme', canFetchValues: true },
        { value: 'type', label: 'type', canFetchValues: true },
        { value: 'bank', label: 'name', canFetchValues: true },
        { value: 'brand', label: 'brand', canFetchValues: true },
        { value: 'category', label: 'category', canFetchValues: true },
        {
            value: 'last_4',
            label: 'last_4',
            canFetchValues: true,
        },
    ],
    customers: [
        { value: 'id', label: 'id', canFetchValues: true },
        { value: 'email', label: 'email', canFetchValues: true },
        { value: 'first_name', label: 'first_name', canFetchValues: false },
        { value: 'last_name', label: 'last_name', canFetchValues: false },
        { value: 'address1', label: 'address1', canFetchValues: false },
        { value: 'address2', label: 'address2', canFetchValues: false },
        { value: 'city', label: 'city', canFetchValues: false },
        { value: 'state', label: 'state', canFetchValues: true },
        { value: 'zip', label: 'zip', canFetchValues: true },
        { value: 'country', label: 'country', canFetchValues: true },
        { value: 'created_at', label: 'created_at', canFetchValues: false },
    ],
    subscriptions: [
        { value: 'id', label: 'id', canFetchValues: true },
        { value: 'customer_id', label: 'customer_id', canFetchValues: true },
        { value: 'plan_id', label: 'plan_id', canFetchValues: true },
        { value: 'plan_name', label: 'plan_name', canFetchValues: true },
        { value: 'name', label: 'name', canFetchValues: true },
        { value: 'amount', label: 'amount', canFetchValues: true },
        { value: 'currency', label: 'currency', canFetchValues: true },
        { value: 'canceled', label: 'canceled', canFetchValues: true },
        { value: 'active', label: 'active', canFetchValues: true },
        { value: 'in_trial', label: 'in_trial', canFetchValues: true },
        { value: 'pending_cancellation', label: 'pending_cancellation', canFetchValues: true },
        { value: 'interval_days', label: 'interval_days', canFetchValues: true },
        { value: 'activated_at', label: 'activated_at', canFetchValues: false },
        { value: 'iterate_at', label: 'iterate_at', canFetchValues: false },
        { value: 'canceled_at', label: 'canceled_at', canFetchValues: false },
        { value: 'cancel_at', label: 'cancel_at', canFetchValues: false },
        { value: 'created_at', label: 'created_at', canFetchValues: false },
        { value: 'metadata', label: 'metadata', canFetchValues: false },
    ],
};

// Tokens for OPERAND_MODE
export const OPERAND_TOKENS = [
    {
        label: '==',
        value: '==',
    },
    {
        label: '!=',
        value: '!=',
    },
    {
        label: '>',
        value: '>',
    },
    {
        label: '<',
        value: '<',
    },
    {
        label: '>=',
        value: '>=',
    },
    {
        label: '<=',
        value: '<=',
    },
];

export const LOGICAL_TOKENS = [
    {
        label: '↵ Filter',
        value: 'trigger-search',
    },
    {
        label: 'AND',
        value: 'AND',
    },
    {
        label: 'OR',
        value: 'OR',
    },
];

export type $SearchToken = {
    type: $SearchTokenType,
    value: string,
};
