export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case 'FETCH_API_VERSIONS_PENDING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
            };
        }

        case 'FETCH_API_VERSIONS_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                versions: action.payload.versions,
                current: action.payload.current_version,
                latest: action.payload.latest_version,
            };
        }
    }

    return state;
}
