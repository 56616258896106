import React from 'react';
import ContentLayout from './ContentLayout';
import * as TransactionsActions from '../Actions/TransactionsActions';
import Transaction from '../components/Transaction';
import Loader from '../components/Loader';
import Empty from '../components/Empty';
import Error from '../components/Error';
import { connect } from 'react-redux';
import Pagination from '../components/Pagination';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import SearchBar from '../features/SearchBar/SearchBar';
import { RebuildFilter } from '../features/SearchBar/Util';
import NotPermitted from '../features/Permissions/NotPermitted';
import TransactionsExporter from '../features/Exports/TransactionsExporter';
import SearchSwitcher from '../features/SearchBar/SearchSwitcher';
import * as Actions from '../features/SearchBar/actions';

const transactionsPerPage = 15;

@connect(store => {
    return { transactions: store.transactions };
})
export default class Transactions extends React.Component {
    static contextTypes = {
        store: PropTypes.object.isRequired,
    };

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Transactions | ProcessOut';
        const filter = this.props.location.query.filter;
        this.setState({ filter: filter });
        // we check that there is an afterItem parameter
        // if not then we are going backward and there should be a before item
        const afterItem = this.props.location.query.after_item;
        const beforeItem = this.props.location.query.before_item;
        this.applyFilter(filter, false, afterItem || beforeItem, !beforeItem);
    }

    componentWillReceiveProps(nextProps) {
        let firstItem = this.props.location.query.after_item;
        if (!firstItem) firstItem = '';

        if (
            !nextProps.transactions.fetching &&
            !nextProps.transactions.fetched &&
            !nextProps.transactions.error
        )
            this.props.dispatch(
                TransactionsActions.loadTransactions(
                    this.state.filter,
                    transactionsPerPage,
                    true,
                    firstItem,
                ),
            );
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    constructor() {
        super();
        this.state = {
            filter: '',
        };
    }
    applyFilter = (filter: string, reset: boolean, firstItem: string, forward?: boolean) => {
        this.props.dispatch(
            TransactionsActions.loadTransactions(
                filter,
                transactionsPerPage,
                forward !== null ? forward : true,
                reset ? '' : firstItem,
            ),
        );
    };

    render() {
        const transactions = this.props.transactions.transactions;

        var header;
        var content;
        if (
            (this.props.transactions.fetching || !this.props.transactions.fetched) &&
            !this.props.transactions.error
        ) {
            content = <Loader />;
        } else if (this.props.transactions.error) {
            if (this.props.transactions.error.notPermitted) content = <NotPermitted />;
            else content = <Error />;
        } else {
            var empty;
            if (transactions.length == 0) {
                if (this.state.filter !== '') {
                    empty = <Empty text='No transactions match your search.' />;
                } else {
                    empty = <Empty text="You haven't processed any transaction yet." />;
                }
            }

            if (transactions.length > 0) {
                header = (
                    <div
                        class='row capitalize greyed bold small-margin-bottom'
                        style={{ fontSize: '0.8em' }}
                    >
                        <div class='medium-2 columns'>Status</div>
                        <div class='medium-3 columns'>
                            <span style={{ marginLeft: '-1em' }}>Price</span>
                        </div>
                        <div class='medium-3 columns' style={{ marginLeft: '-1em' }}>
                            Name
                        </div>
                        <div class='medium-2 columns'>Customer</div>
                        <div class='medium-2 columns text-right'>Date</div>
                    </div>
                );
            }

            content = (
                <div>
                    <div class='row'>
                        <div class='large-12 columns'>
                            {empty}
                            {header}

                            <ReactTooltip effect='solid' />
                            {transactions.map(function(transaction) {
                                return (
                                    <Transaction transaction={transaction} key={transaction.id} />
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        }

        const title = (
            <div class='row '>
                <div class='large-2 columns'>
                    <h4>Transactions</h4>
                </div>
                <div class='large-6 columns'>
                    <SearchSwitcher
                        onChange={filter => {
                            const rebuildFilter = RebuildFilter.bind(this);
                            rebuildFilter(this.applyFilter, false, filter);
                            this.props.dispatch(Actions.updateSearchBarAppliedFilter(filter));
                        }}
                        defaultFilter={this.props.location.query.filter}
                        applyFunc={RebuildFilter.bind(this, this.applyFilter)}
                    />
                </div>
                <div class='large-offset-1 large-3 columns text-right'>
                    <TransactionsExporter />
                </div>
            </div>
        );

        return (
            <ContentLayout title={title}>
                <div class='row'>
                    <div class='large-12 columns'>
                        {content}
                        <div class='row'>
                            <div class='large-12 columns'>
                                <Pagination
                                    reloadFunction={TransactionsActions.loadTransactions.bind(
                                        null,
                                        this.state.filter,
                                        transactionsPerPage,
                                    )}
                                    itemsArray={this.props.transactions.fetched ? transactions : []}
                                    hasMore={
                                        !this.props.transactions.fetched
                                            ? false
                                            : this.props.transactions.has_more
                                    }
                                    location={this.props.location}
                                    hide={
                                        this.props.transactions.fetching ||
                                        !this.props.transactions.fetched
                                    }
                                    isFirstPage={this.props.location.query.after_item == null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ContentLayout>
        );
    }
}
