// @flow

import React from 'react';
import Creatable from 'react-select-v2/lib/Creatable';
import type { $Option } from '../../consts';
import { SelectStyle } from '../consts';

type Props = {
    options: Array<{ label: string, value: string }>,
    onChange: (Array<string>) => void,
    value: ?Array<string | number>,
};

class ValueSelection extends React.Component<Props> {
    onChange = (newValue: Array<$Option>) => {
        this.props.onChange(newValue.map(value => value.value.replace(/\"(.*)\"/g, '$1')));
    };

    render() {
        return (
            <div className='labeled-field' style={{ width: '200px' }}>
                <Creatable
                    options={this.props.options.filter(option =>
                        this.props.value ? !this.props.value.includes(option.label) : true,
                    )}
                    value={
                        this.props.value
                            ? this.props.value.map(value => {
                                  return {
                                      label: value,
                                      value: value,
                                  };
                              })
                            : null
                    }
                    onChange={this.onChange}
                    placeholder={'Type for custom'}
                    closeMenuOnSelect={false}
                    isMulti={true}
                    valueComponent={ValueComponent}
                    promptTextCreator={label => label}
                    styles={SelectStyle}
                />
            </div>
        );
    }
}

export default ValueSelection;

class ValueComponent extends React.Component<{ value: $Option, onRemove: $Option => void }> {
    onRemove = () => {
        this.props.onRemove(this.props.value);
    };

    render() {
        return (
            <div
                style={{
                    paddingRight: '.5em',
                    borderRadius: '4px',
                    padding: '.2em',
                    display: 'inline-block',
                    margin: '.3em',
                    border: '1px solid #95afc0',
                    backgroundColor: '#dff9fb',
                    opacity: 0.7,
                }}
            >
                <a onClick={this.onRemove}>
                    <span class='small-font'>
                        {this.props.value.label} <span style={{ fontSize: '.8em' }}>✕</span>
                    </span>
                </a>
            </div>
        );
    }
}
