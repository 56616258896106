// @flow

import type { $Filter } from './Filter/consts';
import type { $SmartRoutingGateway, $VelocityRule } from './consts';
import uniqid from 'uniqid';

/**
 * Computes a tag list from conditions
 *
 * @param conditions
 */
export function computeRuleTags(
    conditions: Array<{ filters: Array<$Filter & $VelocityRule>, logical: 'and' | 'or' }>,
): Array<string> {
    const tags: Array<string> = [];
    for (const condition of conditions) {
        const { filters } = condition;
        for (const filter of filters) {
            tags.push(
                `${filter.path}${
                    filter.path === 'velocity'
                        ? `(${filter.velocityPath} within ${filter.interval})`
                        : ''
                } ${filter.operand} ${filter.value}`,
            );
        }
    }
    return tags;
}

export function parseSmartRoutingGateway(gateway: string): $SmartRoutingGateway {
    if (gateway.includes('gway_')) {
        // Case where only some gateways are checked
        const gatewaysString = gateway
            .replace('processout', '')
            .replace('[', '')
            .replace(']', '');
        const gateways = gatewaysString.split(' ');
        return {
            id: uniqid(),
            gateway: 'processout',
            configurations: gateways,
        };
    }
    // Basic smart routing
    return {
        id: uniqid(),
        gateway: 'processout',
        configurations: ['all'],
    };
}

export function mergeFiltersFromTokens(
    tokens: Array<{ value: string, kind: string }>,
): Array<$Filter> {
    const filters: Array<$Filter> = [];
    // Remove parenthesis
    const rawTokens = tokens.filter(t => t.kind !== 'CLAUSE');
    for (let i = 0; i < rawTokens.length; i++) {
        let filter: $Filter = {
            id: uniqid(),
            path: '',
            operand: '==',
            value: [],
        };
        if (rawTokens[i].kind === 'VARIABLE') {
            filter.path = rawTokens[i].value;
        }
    }
}
