import React from 'react';
import ContentLayout from './ContentLayout';
import * as InvoicesActions from '../Actions/InvoicesActions';
import * as CurrencyActions from '../Actions/CurrencyActions';
import Invoice from '../components/Invoice';
import Loader from '../components/Loader';
import Error from '../components/Error';
import Empty from '../components/Empty';
import { connect } from 'react-redux';
import InvoiceModal from '../components/InvoiceModal';
import Pagination from '../components/Pagination';
import PropTypes from 'prop-types';
import SearchBar from '../features/SearchBar/SearchBar';
import { RebuildFilter } from '../features/SearchBar/Util';
import NotPermitted from '../features/Permissions/NotPermitted';

const INVOICES_PER_PAGE = 15;

@connect(store => {
    return {
        invoicesDetails: store.invoices,
        currenciesDetails: store.currencies,
        permissions: store.permissions,
    };
})
export default class Invoices extends React.Component {
    static contextTypes = {
        store: PropTypes.object.isRequired,
    };

    constructor() {
        super();
        this.state = {
            filter: '',
        };
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Products | ProcessOut';
        const filter = this.props.location.query.filter;
        this.setState({ filter: filter });
        this.props.dispatch(
            InvoicesActions.loadInvoices(
                INVOICES_PER_PAGE,
                true,
                this.props.location.query.firstItem,
                filter,
            ),
        );
        if (!this.props.currenciesDetails.fetching && !this.props.currenciesDetails.fetched) {
            this.props.dispatch(CurrencyActions.loadCurrencies());
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            !nextProps.invoicesDetails.fetching &&
            !nextProps.invoicesDetails.fetched &&
            !nextProps.invoicesDetails.error
        ) {
            this.props.dispatch(InvoicesActions.loadInvoices(INVOICES_PER_PAGE, true));
        }

        if (!nextProps.currenciesDetails.fetching && !nextProps.currenciesDetails.fetched) {
            this.props.dispatch(CurrencyActions.loadCurrencies());
        }
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    openModal() {
        this._modal.getWrappedInstance().openModal();
    }

    render() {
        if (this.props.invoicesDetails.error) {
            if (this.props.invoicesDetails.error.notPermitted) {
                return (
                    <ContentLayout title={<h4>Products</h4>}>
                        <NotPermitted />
                    </ContentLayout>
                );
            }
            return (
                <ContentLayout title={<h4>Products</h4>}>
                    <Error />
                </ContentLayout>
            );
        }

        const invoices = this.props.invoicesDetails.invoices;

        var content;
        if (
            this.props.invoicesDetails.fetching ||
            !this.props.invoicesDetails.fetched ||
            this.props.currenciesDetails.fetching ||
            !this.props.currenciesDetails.fetched
        ) {
            content = <Loader />;
        } else {
            var header;
            if (invoices != null && invoices.length > 0) {
                header = (
                    <div
                        class='row capitalize greyed bold small-margin-bottom'
                        style={{ fontSize: '0.8em' }}
                    >
                        <div class='medium-10 columns'>
                            <div class='row'>
                                <div class='medium-2 columns'>Price</div>
                                <div class='large-5 medium-4 columns'>Name</div>
                                <div class='large-5 medium-6 columns'>KYC handling</div>
                            </div>
                        </div>
                    </div>
                );
            }

            content = (
                <div>
                    {header}
                    {invoices.map(function(invoice) {
                        return (
                            <div key={invoice.id}>
                                <Invoice invoice={invoice} />
                            </div>
                        );
                    })}
                </div>
            );
        }

        const createButton = (
            <a onClick={this.openModal.bind(this)} class='button round border small'>
                New product
            </a>
        );

        var header = (
            <div class='row'>
                <div className='medium-2 columns'>
                    <h4>Products</h4>
                </div>
                <div className='medium-7 columns' />
                <div className='medium-3 columns text-right'>{createButton}</div>
            </div>
        );

        return (
            <ContentLayout title={header}>
                <InvoiceModal modalName='New product' ref={m => (this._modal = m)} />
                {(() => {
                    if (
                        this.props.invoicesDetails.fetched &&
                        (invoices == null || invoices.length == 0)
                    ) {
                        return (
                            <div>
                                <div class='row'>
                                    <Empty text="You haven't created any product yet." />
                                </div>
                            </div>
                        );
                    } else {
                        return <div>{content}</div>;
                    }
                })()}
                <Pagination
                    reloadFunction={InvoicesActions.loadInvoices.bind(null, INVOICES_PER_PAGE)}
                    itemsArray={this.props.invoicesDetails.fetched ? invoices : []}
                    hasMore={
                        this.props.invoicesDetails.fetched
                            ? this.props.invoicesDetails.has_more
                            : false
                    }
                    hide={
                        this.props.invoicesDetails.fetching || !this.props.invoicesDetails.fetched
                    }
                    location={this.props.location}
                />
            </ContentLayout>
        );
    }
}
