// @flow

import React from 'react';
import type { $Recommendation, $Surface } from './consts';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import type { $Project, $State } from '../../../util/Types';
import Tooltip from 'react-tooltip';
import Recommendations from './Recommendations';
import RecoModal from './RecoModal';
import uniqid from 'uniqid';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import type { $SurfacesState } from './reducer';
import Analytics from '../../../util/Analytics';
import type { $TelescopeState } from '../../../stores/Reducers/TelescopeReducer';

type Props = {
    surface: $Surface,
    fadeOut: boolean,
    opened: boolean,
    onOpen: string => void,
    onClose: string => void,
    currentProject: {
        project: $Project,
    } & $State,
    surfaces: $SurfacesState,
    hoveredSurface: ?string,
    telescope: $TelescopeState,
    user: {
        details: {
            email: string,
        },
    },
};

type State = {
    highlightHardFail: boolean,
};

class Surface extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            highlightHardFail: false,
        };
    }

    componentDidMount() {
        if (this.props.hoveredSurface === this.props.surface.id && this.props.surface.clickable)
            this.props.onOpen(this.props.surface.id);
        else if (
            this.props.hoveredSurface === this.props.surface.id &&
            !this.props.surface.clickable
        ) {
            this.setState({ highlightHardFail: true }, () => {
                setTimeout(() => {
                    this.setState({ highlightHardFail: false });
                }, 400);
            });
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.hoveredSurface === nextProps.surface.id && nextProps.surface.clickable)
            this.props.onOpen(this.props.surface.id);
        else if (
            nextProps.hoveredSurface === nextProps.surface.id &&
            !nextProps.surface.clickable
        ) {
            this.setState({ highlightHardFail: true }, () => {
                setTimeout(() => {
                    this.setState({ highlightHardFail: false });
                }, 400);
            });
        }
    }

    toggle = () => {
        if (this.props.surface.clickable) {
            if (!this.props.opened) {
                this.props.onOpen(this.props.surface.id);
                Analytics('OPENED_SURFACE', {
                    email: this.props.user.details.email,
                    surface: this.props.surface.name,
                    timespan: this.props.telescope.standard.timespan,
                });
            } else {
                this.props.onClose(this.props.surface.id);
                const cutElement = document.getElementById(
                    `transaction-cut-${this.props.surface.id}`,
                );
                if (!cutElement) return;
                cutElement.className = cutElement.className.replace(' highlighted', '');
            }
        } else {
            this.setState({ highlightHardFail: true }, () => {
                setTimeout(() => {
                    this.setState({ highlightHardFail: false });
                }, 400);
            });
        }
    };

    componentDidUpdate() {
        Tooltip.rebuild();
    }

    render() {
        const { surface } = this.props;

        return (
            <div
                class={`row surface ${this.props.opened ? 'opened' : ''}${
                    this.props.fadeOut ? 'fade' : ''
                }`}
                id={`surface-${surface.id}`}
            >
                <Tooltip effect='solid' />
                <div class='large-12 columns'>
                    <Container onClick={this.toggle} clickable={surface.clickable}>
                        <div
                            class={`row head recuperable`}
                            onMouseOver={() => {
                                const element = document.getElementById(
                                    `transaction-cut-${surface.id}`,
                                );
                                if (element)
                                    element.className =
                                        element.className.replace(/ highlighted/g, '') +
                                        ' highlighted';
                            }}
                            onMouseLeave={() => {
                                for (const s of this.props.surfaces.surfaces) {
                                    if (this.props.hoveredSurface !== s.id) {
                                        const element = document.getElementById(
                                            `transaction-cut-${s.id}`,
                                        );
                                        if (element)
                                            element.className = element.className.replace(
                                                / highlighted/g,
                                                '',
                                            );
                                    }
                                }
                            }}
                        >
                            <div class='large-12 columns'>
                                <div class='row'>
                                    {surface.clickable ? (
                                        <div>
                                            <div class='large-6 columns'>
                                                {surface.is_new ? (
                                                    <div>
                                                        <span class='name '>{surface.name}</span>
                                                        <div class='recommendations-new-tag'>
                                                            NEW
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div class='name '>{surface.name}</div>
                                                )}
                                            </div>
                                            <div class='large-5 columns text-right'>
                                                <div class='stats'>
                                                    Found on{' '}
                                                    <strong>
                                                        <FormattedNumber value={surface.count} />{' '}
                                                    </strong>
                                                    transactions{' '}
                                                    {surface.total_estimated_gain ? (
                                                        <div
                                                            style={{
                                                                display: 'inline-block',
                                                            }}
                                                        >
                                                            for{' '}
                                                            <span class='high-number'>
                                                                <FormattedNumber
                                                                    value={
                                                                        surface.total_estimated_gain
                                                                    }
                                                                    style='currency'
                                                                    currency={
                                                                        this.props.currentProject
                                                                            .project
                                                                            .default_currency
                                                                    }
                                                                    minimumFractionDigits={0}
                                                                    maximumFractionDigits={0}
                                                                />
                                                            </span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div class='large-1 columns'>
                                                {surface.clickable ? (
                                                    <div class='arrow'>→</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div class='large-3 columns'>
                                                <div class='name '>{surface.name}</div>
                                            </div>
                                            <div class='large-8 end columns text-right'>
                                                <div
                                                    class={`greyed hard-fail-indicator ${
                                                        this.state.highlightHardFail
                                                            ? 'highlight'
                                                            : ''
                                                    }`}
                                                >
                                                    {surface.description}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Container>
                    <ReactCSSTransitionGroup
                        transitionName='surface-animation'
                        transitionEnterTimeout={450}
                        transitionLeaveTimeout={450}
                    >
                        {this.props.opened ? (
                            <div key={uniqid()}>
                                <Recommendations
                                    recommendations={surface.associated_recommendations}
                                    surface={surface}
                                />
                            </div>
                        ) : null}
                    </ReactCSSTransitionGroup>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return {
        currentProject: store.currentProject,
        surfaces: store.surfaces,
        user: store.user,
        telescope: store.telescope,
    };
})(Surface);

class Container extends React.Component<{
    clickable: boolean,
    onClick: () => any,
    children?: ?any,
}> {
    render() {
        return <a onClick={this.props.onClick}>{this.props.children}</a>;
    }
}
