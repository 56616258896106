// @flow
import React from 'react';

type Props = {
    onDelete: () => void,
};

class Delete extends React.Component {
    props: Props;

    render() {
        return (
            <a onClick={this.props.onDelete}>
                <img
                    src='//dashboard.processout.com/images/delete.png'
                    style={{
                        height: '20px',
                        display: 'inline-block',
                        marginRight: '.5em',
                        opacity: 0.4,
                    }}
                />
            </a>
        );
    }
}

export default Delete;
