// @flow

import React from "react";
import * as Actions from "../actions";

type Props = {
    onChange: (displayNetMetrics: boolean) => void,
    displayNetMetrics: boolean,
};

class NetSwitch extends React.Component<Props> {
    render() {
        const { onChange, displayNetMetrics } = this.props;
        return (
            <div class="row small-margin-bottom">
                <div class="large-12 columns">
                    <div class="switch-container">
                        <label class="local-switch">
                            <input
                                type="checkbox"
                                onChange={event => {
                                    onChange(!displayNetMetrics);
                                }}
                                checked={displayNetMetrics}
                            />
                            <span class="local-slider round" />
                        </label>
                        De-duplicate retries / Display NET metrics
                    </div>
                </div>
            </div>
        );
    }
}

export default NetSwitch;
