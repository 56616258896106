// @flow

import React from 'react';
import type { $Filter, $Operand } from './consts';
import PlottedField from './PlottedField/PlottedField';
import Operand from './Operand';
import ValueSelection from './ValueSelection';
import { connect } from 'react-redux';
import { REQUEST_FETCH_VALUES_FOR_FIELD } from '../../../../SearchBar/consts';
import type { $Dispatcher } from '../../../../../util/Types';
import { DIMENSIONS_MAP } from '../DimensionSelection/consts';

type Props = {
    prefixLabel: string,
    filter: ?$Filter,
    onChange: $Filter => void,
    onDelete: $Filter => void,
    filterValues: any,
} & $Dispatcher;

class Filter extends React.Component<Props> {
    componentDidMount(): void {
        const { filter, dispatch } = this.props;
        if (filter && filter.path && filter.value) {
            dispatch({
                type: REQUEST_FETCH_VALUES_FOR_FIELD,
                payload: {
                    document: 'transactions',
                    field: filter.path,
                },
            });
        }
    }

    onPathChange = (newValue: string) => {
        const { dispatch, onChange, onDelete, filter } = this.props;
        dispatch({
            type: REQUEST_FETCH_VALUES_FOR_FIELD,
            payload: { document: 'transactions', field: newValue },
        });

        onChange({
            ...filter,
            path: newValue,
        });
    };

    onOperandChange = (newValue: $Operand) => {
        const { onChange, filter } = this.props;
        onChange({ ...filter, operand: newValue });
    };

    onValueChange = (newValue: Array<string>) => {
        const { onChange, filter } = this.props;
        onChange({
            ...filter,
            value: newValue,
        });
    };

    onCustomValueChange = (event: Event) => {
        const { onChange, filter } = this.props;
        onChange({
            ...filter,
            value: [event.target.value],
        });
    };

    render() {
        const { filter, prefixLabel, filterValues, onDelete } = this.props;

        return (
            <div class='row small-margin-bottom'>
                <div class='large-12 columns'>
                    <div class='field-label round-left' style={{ width: '65px' }}>
                        {prefixLabel}
                    </div>
                    <PlottedField
                        onChange={this.onPathChange}
                        value={filter ? filter.path : null}
                        disabled={false}
                    />
                    {filter && (
                        <Operand
                            onChange={this.onOperandChange}
                            value={filter.operand}
                            isKeywordOnly={
                                !filter.path &&
                                !filter.path.includes('metadata') &&
                                !new RegExp(
                                    /^\w*_*(?:estimate|count|hour|day|fee|amount(?:_local)?)$/,
                                ).test(filter.path)
                            }
                        />
                    )}
                    {filter ? (
                        filter.operand && filter.operand.includes('null') ? null : filterValues[
                              filter.path
                          ] ? (
                            <ValueSelection
                                onChange={this.onValueChange}
                                options={filterValues[filter.path]}
                                value={filter.value}
                            />
                        ) : (
                            <div style={{ width: '200px', display: 'inline-block' }}>
                                <input
                                    class='text-right'
                                    type='text'
                                    value={filter.value}
                                    onChange={this.onCustomValueChange}
                                    placeholder='0'
                                    style={{
                                        marginBottom: 0,
                                        position: 'relative',
                                        borderRadius: '0',
                                        top: '1px',
                                    }}
                                />
                            </div>
                        )
                    ) : null}
                    {filter && (
                        <div
                            class='field-label clickable round-right'
                            onClick={() => {
                                this.props.onDelete(this.props.filter);
                            }}
                        >
                            delete
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(store => ({ filterValues: store.searchBar }))(Filter);
