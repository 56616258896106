// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { $DataExplorerState } from '../reducer';
import type { $Dispatcher } from '../../../../util/Types';
import Loading from '../../../../components/Loader';
import Previewer from '../Previewer';
import BuilderMetricSelector from './BuilderMetricSelector';
import TypeSelection from './TypeSelection';
// $FlowFixMe
import './chart-builder.scss';
import type { $ChartBuilderState } from './reducer';
import LocalSwitch from './LocalSwitch';
import * as Actions from './actions';
import * as DataExplorerActions from '../actions';
import DimensionSelection from './DimensionSelection/DimensionSelection';
import { MAP_CHART, SINGLE_VALUE } from '../../Boards/consts';
import type { $Dimension } from './DimensionSelection/consts';
import type { $Filter } from './Filters/consts';
import Filters from './Filters/Filters';
import NetSwitch from './NetSwitch';

type Props = {
    dataExplorer: $DataExplorerState,
    location: any,
    chartBuilder: $ChartBuilderState,
} & $Dispatcher;

class ChartBuilder extends React.Component<Props> {
    componentDidMount(): void {
        const { dispatch } = this.props;
        dispatch(DataExplorerActions.selectTab('chart-builder'));
    }

    render() {
        const { location, chartBuilder, dataExplorer } = this.props;
        return (
            <div class='row chart-builder-v2'>
                <div class='large-12 columns'>
                    {location.query.chart && !chartBuilder.canEditIfChartIdExists ? (
                        <div class='chart-builder-building'>
                            <div class='large-12 columns'>
                                <div class='row'>
                                    <div class='medium-12 columns'>Rebuilding chart UI</div>
                                </div>
                                <Loading />
                            </div>
                        </div>
                    ) : null}
                    <div class='chart-builder'>
                        <div class='back'>
                            <div class='row small-margin-bottom'>
                                <div class='large-12 columns '>
                                    <BuilderMetricSelector />
                                    {chartBuilder.dimensions.length > 0 ? (
                                        <DimensionSelection
                                            dimensions={chartBuilder.dimensions}
                                            onChange={(newDimensions: Array<$Dimension>) => {
                                                const { dispatch } = this.props;
                                                dispatch(Actions.updateDimensions(newDimensions));
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <div
                                class='row small-margin-bottom'
                                style={{ margin: '0 -1em 0 -1em' }}
                            >
                                <div class='large-12 columns'>
                                    <Previewer hideName={true} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='large-8 columns'>
                                    <Filters
                                        onChange={(filters: Array<$Filter>) => {
                                            const { dispatch } = this.props;
                                            dispatch(Actions.updateFilters(filters));
                                        }}
                                        filters={chartBuilder.filters}
                                    />
                                </div>
                                <div className='large-4 columns text-right'>
                                    <div class='row small-margin-bottom'>
                                        <div class='large-12 columns'>
                                            <TypeSelection />
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-12 columns text-left'>
                                            {dataExplorer.unit === 'amount' ? (
                                                <LocalSwitch
                                                    onChange={() => {
                                                        const {
                                                            dispatch,
                                                            chartBuilder,
                                                        } = this.props;
                                                        dispatch(
                                                            Actions.setDisplayLocalCurrency(
                                                                !chartBuilder.displayInLocalCurrency,
                                                            ),
                                                        );
                                                    }}
                                                    displayInLocal={
                                                        chartBuilder.displayInLocalCurrency
                                                    }
                                                />
                                            ) : null}
                                            <NetSwitch
                                                onChange={() => {
                                                    const { dispatch } = this.props;
                                                    dispatch(
                                                        Actions.setDisplayNetMetrics(
                                                            !chartBuilder.displayNetMetrics,
                                                        ),
                                                    );
                                                }}
                                                displayNetMetrics={chartBuilder.displayNetMetrics}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return {
        dataExplorer: store.dataExplorer,
        chartBuilder: store.chartBuilder,
    };
})(ChartBuilder);
