// @flow

import React from 'react';
import Loader from '../../components/Loader';
import * as CurrencyActions from '../../Actions/CurrencyActions';
import { goBack, push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import * as ProjectsActions from './actions';
import type { $Dispatcher } from '../../util/Types';
import type { $AwsRegion } from './RegionChoice';
import RegionChoice from './RegionChoice';
import CurrencySelector from '../CurrencySelector/CurrencySelector';

const serialize = require('form-serialize');

type Props = {} & $Dispatcher;

type State = {
    fetching: boolean,
    privateKey: ?string,
    keySelected: boolean,
    creating: boolean,
    region: ?$AwsRegion,
    currency: string,
    name: string,
};

class ProjectWizard extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            fetching: false,
            privateKey: null,
            keySelected: true,
            creating: false,
            region: null,
            currency: 'USD',
            name: '',
        };
    }

    componentDidMount() {
        if (!this.props.currenciesDetails.fetching && !this.props.currenciesDetails.fetched) {
            this.props.dispatch(CurrencyActions.loadCurrencies());
        }
    }

    submit(event) {
        event.preventDefault();
        this.setState({ creating: true });
        const { name, currency, region } = this.state;
        const project = {
            name,
            default_currency: currency,
            region,
        };
        this.props.dispatch(ProjectsActions.createProject(project));
    }

    render() {
        if (!this.props.currenciesDetails.fetched) {
            return <Loader />;
        }

        const createButton = this.state.creating ? (
            <button class='round border button' style={{ minWidth: '120px', marginLeft: '15px' }}>
                <div class='loading-spinner' />
            </button>
        ) : (
            <input
                type='submit'
                class={`round button main${
                    !this.state.name || !this.state.region ? ' disabled' : ''
                }`}
                style={{
                    marginLeft: '15px',
                }}
                value='Create'
                disabled={this.state.creating}
            />
        );

        return (
            <div>
                <ReactTooltip effect='solid' />
                <div class='row margin-bottom'>
                    <div class='large-12 columns'>
                        <h3 class='text-center title'>Create a new project</h3>
                    </div>
                </div>

                <div class='row'>
                    <form id='project-setup' onSubmit={this.submit.bind(this)}>
                        <div class='large-6 medium-7 small-centered columns'>
                            <div class='row margin-bottom'>
                                <div class='large-12 columns'>
                                    <p>
                                        ProcessOut projects help you manage separate your different
                                        websites and services, securing your different{' '}
                                        <span style={{ fontStyle: 'italic' }}>
                                            {' '}
                                            payment environments
                                        </span>
                                        . Additionally, different projects can be used if you own
                                        several legal entities and want to{' '}
                                        <span style={{ fontStyle: 'italic' }}>
                                            split your transactions
                                        </span>{' '}
                                        between them. {"Let's"} get you started by creating a new
                                        project.
                                    </p>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='large-3 medium-4 columns greyed next-to-input'>
                                    Project name
                                </div>
                                <div class='large-8 medium-7 medium-offset-1 columns'>
                                    <input
                                        name='name'
                                        type='text'
                                        placeholder='Project name...'
                                        value={this.state.name}
                                        onChange={event => {
                                            this.setState({ name: event.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                            <div class='row margin-bottom'>
                                <div class='large-3 medium-4 columns greyed next-to-input'>
                                    Project currency
                                </div>
                                <div class='large-8 medium-7 medium-offset-1 columns'>
                                    <CurrencySelector
                                        value={this.state.currency}
                                        onChange={currency => {
                                            this.setState({ currency });
                                        }}
                                    />
                                </div>
                            </div>
                            <div class='row margin-bottom'>
                                <div class='large-12 columns'>
                                    <div class='row small-margin-bottom'>
                                        <div class='large-12 columns'>
                                            <p>
                                                Please also chose the region in which you’d like
                                                your customer data to be stored, to comply with your
                                                local requirements such as the GDPR.
                                            </p>
                                        </div>
                                    </div>
                                    <div class='row'>
                                        <div class='large-3 medium-4 columns greyed next-to-input'>
                                            Region{' '}
                                        </div>
                                        <div class='large-8 medium-7 medium-offset-1 columns'>
                                            <RegionChoice
                                                onChange={region => {
                                                    this.setState({ region });
                                                }}
                                                value={this.state.region}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='large-6 columns clearfix' />
                                <div class='large-6 columns clearfix text-right'>
                                    {this.props.projectsDetails.fetched &&
                                        this.props.projectsDetails.projects.length > 0 && (
                                            <a
                                                to='/projects'
                                                onClick={() => {
                                                    this.props.dispatch(goBack());
                                                }}
                                                class='round border button red'
                                            >
                                                Cancel
                                            </a>
                                        )}
                                    {createButton}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return {
        currenciesDetails: store.currencies,
        user: store.user,
        projectsDetails: store.projects,
    };
})(ProjectWizard);
