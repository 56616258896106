import React from 'react';
import { FormattedDate, ReactIntl } from 'react-intl';
import CardModal from './CardModal';
import CardSchemeImage from '../util/CardSchemeImage';
import moment from 'moment';
import PropTypes from 'prop-types';

export default class Card extends React.Component {
    static propTypes = {
        card: PropTypes.object.isRequired,
    };

    showModal(event) {
        event.preventDefault();
        this.refs.modal.getWrappedInstance().openModal();
    }

    render() {
        const card = this.props.card;

        var cvcStatus;
        var cvcExplain;
        if (card.cvc_check === 'passed') {
            cvcStatus = 'success';
            cvcExplain = 'The security code verification has been done successfuly.';
        } else if (
            card.cvc_check.includes('pending') ||
            card.cvc_check === 'unavailable' ||
            card.avs_check === 'unknown'
        ) {
            cvcStatus = 'warning';
            if (card.cvc_check.includes('pending'))
                cvcExplain = 'The security code verification is still pending aproval.';
            else cvcExplain = 'The security code verification is not available.';
        } else {
            cvcStatus = 'failed';
            cvcExplain = 'The security code verification has failed.';
        }

        var avsStatus;
        var avsExplain;
        if (card.avs_check === 'passed') {
            avsStatus = 'success';
            avsExplain = 'The address verification has been done successfuly.';
        } else if (
            card.avs_check.includes('pending') ||
            card.avs_check === 'unavailable' ||
            card.avs_check === 'unknown'
        ) {
            avsStatus = 'warning';
            if (card.avs_check.includes('pending'))
                avsExplain = 'The address verification is still pending aproval.';
            else avsExplain = 'The address verification is not available.';
        } else {
            avsStatus = 'failed';
            avsExplain = 'The address verification has failed.';
        }

        var expiry = moment(new Date(card.exp_year, card.exp_month - 1));

        return (
            <a onClick={this.showModal.bind(this)}>
                <CardModal ref='modal' card={card} />
                <div class={`box-row padding row`}>
                    <div class='medium-2 columns'>
                        <div class='row'>
                            <div class='medium-4 columns'>{CardSchemeImage(card)}</div>
                            <div class='medium-8 columns font-console'>{card.last_4_digits}</div>
                        </div>
                    </div>
                    <div class='medium-2 columns font-console'>
                        {card.exp_month && card.exp_year ? (
                            <FormattedDate
                                value={expiry.toISOString()}
                                month='2-digit'
                                year='numeric'
                            />
                        ) : (
                            <span class='greyed'>Unkown expiration</span>
                        )}
                    </div>
                    <div class='medium-4 columns end'>
                        {card.name &&
                        card.name !== '' &&
                        card.name.split(' ').join('').length > 0 ? (
                            card.name
                        ) : (
                            <span class='greyed'>--</span>
                        )}
                    </div>
                    <div class='medium-1 columns text-right'>
                        <div
                            data-tip={cvcExplain}
                            class={'log-level ' + cvcStatus}
                            style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                marginTop: '0px',
                                marginRight: '-3px',
                            }}
                        >
                            CVC
                        </div>
                    </div>
                    <div class='medium-1 columns text-right'>
                        <div
                            data-tip={avsExplain}
                            class={'log-level ' + avsStatus}
                            style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                marginTop: '0px',
                                marginRight: '-3px',
                            }}
                        >
                            AVS
                        </div>
                    </div>
                    <div class='medium-2 columns text-right greyed'>
                        <FormattedDate
                            value={card.created_at}
                            day='2-digit'
                            month='2-digit'
                            year='numeric'
                        />
                    </div>
                </div>
            </a>
        );
    }
}
