// @flow

import React from 'react';
import uniqid from 'uniqid';

import { type $Dimension, DIMENSIONS_MAP } from './consts';
import { groupBy } from '../../Utils';
import Creatable from 'react-select-v2/lib/Creatable';

type Props = {
    baseDimension: $Dimension,
    dimension: ?$Dimension,
    onChange: (dimension: ?$Dimension) => void,
};

class SplitSelection extends React.Component<Props> {
    onChange = (newValue: ?{ label: string, value: string }) => {
        const { onChange, dimension } = this.props;
        if (!newValue) {
            onChange(null);
        } else {
            onChange({
                ...dimension,
                field: newValue.value,
            });
        }
    };

    onChangeMetadata = (newValue: string) => {
        const { onChange, dimension } = this.props;
        onChange({
            ...dimension,
            field: `metadata.${newValue}`,
        });
    };

    addDimension = () => {
        const { onChange, baseDimension } = this.props;
        onChange({
            ...baseDimension,
            id: uniqid(),
            field: 'transactions.card_scheme',
        });
    };

    render() {
        const { dimension } = this.props;
        if (!dimension)
            return (
                <div class='field-label clickable round-right' onClick={this.addDimension}>
                    + group by
                </div>
            );

        let currentValue = DIMENSIONS_MAP.find(
            d => d.field === dimension.field.replace('transactions.', ''),
        );
        if (currentValue) currentValue = { label: currentValue.name, value: currentValue.field };
        else
            currentValue = {
                label: dimension.field.replace('metadata.', ''),
                value: dimension.field,
            };

        const groupByGroupName = groupBy('group');
        const groupedByGroupName = groupByGroupName(DIMENSIONS_MAP);

        const OPTIONS = Object.keys(groupedByGroupName).map(group => ({
            label: group,
            options: groupedByGroupName[group].map(d => ({
                value: d.field,
                label: d.name,
                top: d.top,
                strategy: d.strategy,
            })),
        }));

        return (
            <div style={{ display: 'inline-block' }}>
                <div className='field-label active' onClick={this.addDimension}>
                    group by
                </div>
                <div class='labeled-field' style={{ width: '200px' }}>
                    <Creatable
                        options={OPTIONS}
                        value={currentValue}
                        isClearable={true}
                        onChange={this.onChange}
                        styles={{
                            valueContainer: (provided: any) => ({
                                ...provided,
                                paddingLeft: '.5em',
                            }),
                            container: (provided: any) => ({
                                ...provided,
                                position: 'relative',
                                borderRadius: '0 4px 4px 0',
                            }),
                            control: (provided: any) => ({
                                ...provided,
                                borderRadius: '0 4px 4px 0',
                            }),
                        }}
                        formatGroupLabel={formatGroupLabel}
                        formatCreateLabel={(option: string) =>
                            `metadata.${option.replace('metadata.', '')}`
                        }
                        onCreateOption={this.onChangeMetadata}
                    />
                </div>
            </div>
        );
    }
}

export default SplitSelection;

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);
