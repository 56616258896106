// @flow
/**
 * Created by jeremylejoux on 07/06/17.
 */

import React from 'react';
import { connect } from 'react-redux';
import * as ProjectsActions from '../../Actions/ProjectsActions';
import Loader from '../Loader';
import ContentLayout from '../../pages/ContentLayout';
import * as ProcessOut from '../../util/ProcessOut';
import serialize from 'form-serialize';
import { replace } from 'react-router-redux';
import NotificationsSettings from '../../features/NotificationsSettings/index';
import * as CurrencyActions from '../../Actions/CurrencyActions';
import type { $Project, $State } from '../../util/Types';

export class GeneralSettings extends React.Component {
    props: Props;
    state: State;

    static defaultProps = {
        dispatch: null,
    };

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch(ProjectsActions.loadProjects());
        dispatch(CurrencyActions.loadCurrencies());
    }

    componentDidMount() {
        document.title = 'Settings - general | ProcessOut';
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    componentWillReceiveProps(nextProps: Props) {
        if (!nextProps.projects.fetching && !nextProps.projects.fetched) {
            this.props.dispatch(ProjectsActions.loadProjects());
        }
        if (!nextProps.currenciesDetails.fetching && !nextProps.currenciesDetails.fetched) {
            this.props.dispatch(CurrencyActions.loadCurrencies());
        }
    }

    edited() {
        ProcessOut.addNotification.bind(this)('Project edited successfully', 'success');
    }

    submit(event: Event) {
        event.preventDefault();
        let editForm = document.querySelector('#edit-form');
        let data = serialize(editForm, { hash: true });
        const { project } = this.props.currentProject;
        if (!project) {
            return;
        }

        data.api_version_name = project.api_version.name;
        this.props.dispatch(
            ProjectsActions.editProject(
                data,
                project.id,
                function done() {
                    this.edited();
                }.bind(this),
            ),
        );
    }

    deleteSubmit(event: Event) {
        event.preventDefault();
        let deleteForm = document.querySelector('#delete-form');
        const { project } = this.props.currentProject;
        if (!project) {
            return;
        }

        let data = serialize(deleteForm, { hash: true });
        if (data.project_name === project.name)
            ProjectsActions.deleteProject(
                project.id,
                function done() {
                    this.props.dispatch(replace('/'));
                }.bind(this),
            );
        else ProcessOut.addNotification('Invalid project name');
    }

    render() {
        if (this.props.currenciesDetails.fetching || this.props.projects.fetching) {
            return (
                <ContentLayout title={<h4>General settings</h4>}>
                    <Loader />
                </ContentLayout>
            );
        }

        const { project } = this.props.currentProject;
        if (!project) {
            return null;
        }

        let name = (
            <div class="large-10 medium-9 columns">
                <input
                    class="bottom-border"
                    type="text"
                    id="project_name"
                    name="name"
                    placeholder="John Doe payment gateway"
                    defaultValue={project.name}
                    required
                />
            </div>
        );

        let submitState = <input type="submit" class="button small border round" value="Save" />;

        return (
            <ContentLayout title={<h4>General settings</h4>}>
                <form id="edit-form" onSubmit={this.submit.bind(this)}>
                    <div class="row">
                        <div class="large-3 medium-4 columns">
                            <h5>Project information</h5>
                        </div>
                        <div class="large-9 medium-8 columns">
                            <div class="row">
                                <div class="large-2 medium-3 columns greyed next-to-input">
                                    Name
                                </div>
                                {name}
                            </div>
                            <div class="row">
                                <div class="large-2 medium-3 columns greyed next-to-input">
                                    Default currency
                                </div>
                                <div class="large-10 medium-9 columns">
                                    <input
                                        class="bottom-border"
                                        type="text"
                                        value={project.default_currency}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="large-12 columns clearfix">{submitState}</div>
                            </div>
                        </div>
                    </div>
                </form>

                <hr />

                <NotificationsSettings />

                <hr />

                <form id="delete-form" onSubmit={this.deleteSubmit.bind(this)}>
                    <div class="row">
                        <div class="large-3 medium-4 columns">
                            <h5 class="alert-text">Deleting</h5>
                        </div>
                        <div class="large-9 medium-8 columns">
                            <p>Deleting a project is permanent, and can't be undone.</p>
                            <div class="row">
                                <div class="large-9 medium-10 columns">
                                    <input
                                        class="bottom-border"
                                        type="text"
                                        name="project_name"
                                        placeholder="Project name"
                                        required
                                    />
                                </div>
                                <div class="large-3 medium-2 columns clearfix clearfix">
                                    <input
                                        type="submit"
                                        class="small button border round red right"
                                        value="Delete project"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ContentLayout>
        );
    }
}

type Props = {
    projects: {
        fetching: boolean,
        fetched: boolean,
        projects: Array<any>,
        current_project_id: string,
    },
    currenciesDetails: {
        fetching: boolean,
        fetched: boolean,
    },
    currentProject: { project: $Project },
    apiVersions: {
        fetching: boolean,
        fetched: boolean,
        versions: Array<any>,
        latest: string,
    },
    params: {
        project: string,
    },
    dispatch: any | (() => void),
};

type State = {
    recover: boolean,
    generating: boolean,
    privateKey?: string,
};

export default connect(function(store) {
    return {
        projects: store.projects,
        currentProject: store.currentProject,
        currenciesDetails: store.currencies,
        apiVersions: store.apiVersions,
        moderators: store.moderators,
    };
})(GeneralSettings);
