// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { $Project } from '../util/Types';

type Props = {
    currentProject: {
        project: $Project,
    },
};

type State = {
    waiting: boolean,
    done: boolean,
    childPage: ?any,
};

class StripeConnect extends React.Component<Props, State> {
    receiveMessage: () => void;

    constructor() {
        super();

        this.state = {
            waiting: false,
            done: false,
        };

        this.receiveMessage = this.receiveMessage.bind(this);
    }

    componentDidMount() {
        window.addEventListener('message', this.receiveMessage, false);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.receiveMessage, false);
    }

    receiveMessage(event) {
        console.log('received', event);
        this.setState({ waiting: false });
        let data;
        try {
            data = JSON.parse(event.data);
        } catch (e) {
            return;
        }

        if (data.namespace !== 'processout.gateway-configuration.oauth') return;

        if (this.state.childPage) this.state.childPage.close();

        if (data.action === 'success') {
            this.setState({ done: true });
            this.refs.publicKey.value = data.data.public_key;
            this.refs.privateKey.value = data.data.private_key;
            this.refs.gateway_merchant_id.value = data.data.gateway_merchant_id;
        } else {
            this.setState({ done: false });
        }
    }

    loadStripeOauth(event) {
        event.preventDefault();
        this.setState({ waiting: true });
        const win = window.open(
            // $FlowFixMe
            `${process.env.API_HOST}/handlers/oauth/${
                this.props.currentProject.project.id
            }/prepare/stripe`,
            '_blank',
        );

        this.setState({ childPage: win }, () => {
            const timer = setInterval(
                function checkChild() {
                    try {
                        win.postMessage('processout.ping-ready', '*');
                    } catch (error) {
                        // console.error(error);
                    }
                    if (win.closed) {
                        this.setState({ waiting: false });
                        clearInterval(timer);
                    }
                }.bind(this),
                500,
            );
        });
    }

    render() {
        let content;
        if (this.state.done) {
            content = (
                <div
                    class='large-12 columns large-centered text-center greyed'
                    style={{ fontSize: '1.2em' }}
                >
                    <span style={{ color: '#27ae60', marginRight: '0.5em' }}>
                        <img
                            src='https://audit.processout.ninja/images/success.png'
                            style={{ height: '20px', width: 'auto' }}
                        />
                    </span>{' '}
                    Stripe keys successfully retrieved.
                </div>
            );
        } else {
            content = (
                <div class='large-12 columns'>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <h3 class='greyed bold'>How does it work?</h3>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='large-12 columns'>
                            <p class='greyed'>
                                Stripe connect allows us to securely access your payment flow
                                without requesting your Stripe public and private keys.
                            </p>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='large-12 columns large-centered text-center'>
                            <button
                                onClick={this.loadStripeOauth.bind(this)}
                                style={{
                                    width: '190px',
                                    height: '33px',
                                    backgroundSize: 'cover',
                                    backgroundImage:
                                        'url(https://audit.processout.com/images/stripe-connect.png)',
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                {content}
                <input type='hidden' ref='publicKey' name='public_key' />
                <input type='hidden' ref='privateKey' name='private_key' />
                <input type='hidden' ref='gateway_merchant_id' name='gateway_merchant_id' />
            </div>
        );
    }
}

export default connect(store => {
    return { currentProject: store.currentProject };
})(StripeConnect);
