// @flow

import { push } from 'react-router-redux';
import qs from 'qs';
import type { $SearchToken, $SearchTokenType } from './consts';
import {
    FIELD_TOKEN,
    FIELD_TOKENS,
    LOGICAL_TOKEN,
    LOGICAL_TOKENS,
    OPERAND_TOKEN,
    OPERAND_TOKENS,
    VALUE_TOKEN,
} from './consts';

const TOKEN_REGEX = /"[^"]+"|\S+/g;
/*
 *
 * @param applyFunc
 * @param reset
 * @param filter
 * @constructor
 */
export function RebuildFilter(applyFunc: string => void, reset: boolean, filter: ?string) {
    filter = filter || '';
    if (this.state.filter !== filter) reset = true;

    this.setState({ filter: filter });
    const firstItem = this.props.location.query.after_item || '';

    applyFunc(filter, reset, firstItem);

    const currentQuery = qs.parse(this.props.location.query);

    let nextQuery = {
        ...currentQuery,
        filter: filter,
    };

    if (reset && (currentQuery.after_item || currentQuery.before_item)) {
        nextQuery = {
            ...nextQuery,
            after_item: null,
            before_item: null,
        };
    }
    const builtQuery = qs.stringify(nextQuery);

    this.props.dispatch(push(`${this.props.location.pathname}?${builtQuery}`));
}

/**
 * Get the token at the given position
 * @param text
 * @param position
 */
export function getTokenAtPosition(
    document: string,
    text: string,
    position: number,
): ?$SearchToken {
    if (
        position - 1 >= 0 &&
        text[position - 1] === ' ' &&
        (text[position] === ' ' || position === text.length)
    )
        return null;
    let match;
    let token;
    while ((match = RegExp(TOKEN_REGEX).exec(text)) !== null) {
        if (match.index <= position) token = match[0];
    }
    const expectedType = getExpectedTokenTypeAtPosition(document, text, position);
    return {
        type: expectedType || FIELD_TOKEN,
        value: token || '',
    };
}

/**
 * Get the word at the given position
 * @param text
 * @param position
 */
export function getWordAtPosition(text: string, position: number): string {
    if (
        position - 1 >= 0 &&
        text[position - 1] === ' ' &&
        (text[position] === ' ' || position === text.length)
    )
        return ' ';
    let match;
    let token = '';
    while ((match = RegExp(TOKEN_REGEX).exec(text)) !== null) {
        if (match.index <= position) token = match[0];
    }
    return token;
}

/**
 *  returns the start position of a token
 * @param text complete filter string
 * @param cursorPosition current cursor position at which we want to check the token
 * @returns {number} token start position
 */
export function getTokenStartPosition(
    document: string,
    text: string,
    cursorPosition: number,
): number {
    const token: ?$SearchToken = getTokenAtPosition(document, text, cursorPosition);
    if (!token) return -1;

    let startPosition = cursorPosition;
    while (
        text.slice(startPosition, startPosition + token.value.length) !== token.value &&
        startPosition > 0
    ) {
        startPosition--;
    }

    return startPosition;
}

/**
 * replace the current token with a new one
 * @param text
 * @param newToken
 * @param cursorPosition
 * @returns new filter string
 */
export function replaceToken(
    document: string,
    text: string,
    newToken: string,
    cursorPosition: number,
): ?string {
    const oldToken = getTokenAtPosition(document, text, cursorPosition);
    if (!oldToken || cursorPosition > text.length) return insertToken(text, newToken);
    if (oldToken.value.trim() === '')
        return replaceToken(document, text, newToken, cursorPosition + 1);
    const startPosition = getTokenStartPosition(document, text, cursorPosition);
    if (startPosition < 0) return null;
    return (
        text.slice(0, startPosition) +
        newToken +
        text.slice(startPosition + oldToken.value.length) +
        (isLastToken(text, cursorPosition) ? ' ' : '')
    );
}

/**
 *
 * @param text
 * @param token
 * @returns {string}
 */
export function insertToken(text: string, token: string): string {
    return `${text}${token} `;
}

/**
 *
 * @param text
 * @param position position of the token (i.e not char position)
 */
export function getExpectedTokenTypeAtPosition(
    document: string,
    text: string,
    position: number,
): ?$SearchTokenType {
    if (position > text.length || position < 0) return null;
    if (text === '' || position === 0) return FIELD_TOKEN;

    const currentToken: string = getWordAtPosition(text, position);
    // we look for the previous token
    let previousToken: ?string = null;
    let i = position;
    while (
        i >= 0 &&
        (previousToken === ' ' || currentToken === previousToken || previousToken === null)
    ) {
        previousToken = getWordAtPosition(text, i);
        i--;
    }

    if (i < 0 || !previousToken) return FIELD_TOKEN;

    switch (getTokenType(document, previousToken)) {
        case FIELD_TOKEN:
            return OPERAND_TOKEN;
        case OPERAND_TOKEN:
            return VALUE_TOKEN;
        case VALUE_TOKEN:
            return LOGICAL_TOKEN;
        case LOGICAL_TOKEN:
        default:
            return FIELD_TOKEN;
    }
}

/**
 * returns the first field token before the given position
 * @param text
 * @param position
 * @returns token(string) if found, null otherwise
 */
export function getLastFieldFromPosition(
    document: string,
    text: string,
    position: number,
): ?string {
    const currentTokenStart = getTokenStartPosition(document, text, position);
    const slice = text.slice(0, currentTokenStart);
    const tokens = slice
        .split(/\s(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g)
        .filter(item => item.trim() !== '');
    for (let i = tokens.length - 1; i >= 0; i--) {
        if (getTokenType(document, tokens[i]) === FIELD_TOKEN) return tokens[i];
    }
    return null;
}

/**
 * returns the token type for a given string
 * @param token
 */
export function getTokenType(document: string, token: string): $SearchTokenType {
    if (!FIELD_TOKENS[document]) return 'VALUE';
    if (
        FIELD_TOKENS[document].findIndex(
            item => item.value.toUpperCase() === token.toUpperCase(),
        ) !== -1
    )
        return FIELD_TOKEN;
    if (OPERAND_TOKENS.findIndex(item => item.value.toUpperCase() === token.toUpperCase()) !== -1)
        return OPERAND_TOKEN;
    if (LOGICAL_TOKENS.findIndex(item => item.value.toUpperCase() === token.toUpperCase()) !== -1)
        return LOGICAL_TOKEN;
    return VALUE_TOKEN;
}

/**
 *
 * @param text
 * @param position
 */
export function isLastToken(text: string, position: number): boolean {
    if (position < 0 || position > text.length) return false;
    return position === text.length || RegExp(/^\S+$|\s$|\S+\s+$/).test(text.slice(position));
}
