import React from 'react';
import { connect } from 'react-redux';
import Name from './InvoiceEdit/Name';
import Prices from './InvoiceEdit/Prices';
import TokenSelect from './CustomerDetails/TokenSelect';
import Metadata from './Metadata';
import ModalFooter from './ModalFooter';
import * as InvoicesActions from '../Actions/InvoicesActions';
import * as CustomerDetailsActions from '../Actions/CustomerDetailsActions';
import PropTypes from 'prop-types';

var serialize = require('form-serialize');

@connect(
    function(store) {
        return {
            currenciesDetails: store.currencies,
            customerDetails: store.customerDetails,
        };
    },
    null,
    null,
    { withRef: true },
)
export default class CustomerChargeModal extends React.Component {
    static propTypes = {
        customerId: PropTypes.string,
    };

    componentDidMount() {
        this.props.dispatch({ type: 'START_INVOICE_CREATION' });
    }

    created(invoice) {
        this.props.dispatch(
            InvoicesActions.chargeSingleInvoice(
                invoice.id,
                invoice.metadata['token'],
                function() {
                    this.props.dispatch({ type: 'CLOSE_MODAL' });
                    this.props.dispatch(
                        CustomerDetailsActions.loadCustomerTransactions(
                            '',
                            15,
                            this.props.customerId,
                        ),
                    );
                }.bind(this),
                function() {
                    this._modalFooter.getWrappedInstance().unlock();
                }.bind(this),
            ),
        );
    }

    save(event) {
        event.preventDefault();

        this._modalFooter.getWrappedInstance().lock();

        var invoiceForm = document.querySelector('#invoice-form');
        var data = serialize(invoiceForm, { hash: true });

        if (!data.metadata) data.metadata = {};

        var metas = this._meta.updateValue();
        for (var i = 0; i < metas.length; i++) {
            data.metadata[metas[i].key] = metas[i].value;
        }

        if (data.metadata && Object.keys(data.metadata).length == 0) {
            delete data.metadata;
        }

        data.metadata = {
            ...data.metadata,
            token: data.source,
        };

        delete data.source;

        if (!data.amount) {
            data.amount = 0;
        }

        data.amount = parseFloat(data.amount);
        data.amount = data.amount + '';

        var invoice = JSON.stringify(data);
        this.props.dispatch(
            InvoicesActions.createSingleInvoice(
                invoice,
                this.created.bind(this),
                function() {
                    this._modalFooter.getWrappedInstance().lock();
                }.bind(this),
            ),
        );
    }

    openModal() {
        this.props.dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: 'New charge',
                content: (
                    <div class='row'>
                        <div class='large-12 columns'>
                            <form id='invoice-form' onSubmit={this.save.bind(this)}>
                                <div class='row small-margin-bottom'>
                                    <div class='medium-12 medium-centered columns'>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Details
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <Name />
                                                <Prices
                                                    currencies={
                                                        this.props.currenciesDetails.currencies
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <hr />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Source
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <TokenSelect
                                                    customerId={
                                                        this.props.customerDetails.details.customer
                                                            .id
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <hr />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <h6 class='capitalize greyed font-console'>
                                                    Additional data
                                                </h6>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <Metadata ref={m => (this._meta = m)} />
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='large-12 columns'>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ),
                footer: (
                    <ModalFooter
                        ref={m => (this._modalFooter = m)}
                        submitTitle='Create'
                        submitCallback={this.save.bind(this)}
                    />
                ),
            },
        });
    }

    render() {
        return null;
    }
}
