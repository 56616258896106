export default function(state = { fetching: false, fetched: false }, action) {
    switch (action.type) {
        case 'FETCH_CURRENCIES_PENDING': {
            return {
                ...state,
                error: false,
                fetching: true,
                fetched: false,
            };
        }

        case 'FETCH_CURRENCIES_FULFILLED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                currencies: action.payload.currencies,
            };
        }
        case 'FETCH_CURRENCIES_REJECTED': {
            return {
                ...state,
                error: true,
                fetched: false,
                fetching: false,
            };
        }
    }

    return state;
}
