// @flow

import moment from 'moment/moment';
import * as ProcessOut from '../../util/ProcessOut';

export const REQUEST_EXPORTS_FETCH = 'REQUEST_EXPORTS_FETCH';
export type $RequestExportFetchAction = {
    type: 'REQUEST_EXPORTS_FETCH',
    payload: {
        after: boolean,
        id: ?string,
    },
};

export const requestExportsFetch = (after: boolean, id: ?string): $RequestExportFetchAction => ({
    type: REQUEST_EXPORTS_FETCH,
    payload: { after, id },
});

export const UPDATE_EXPORT_FORM = 'UPDATE_EXPORT_FORM';
export type $UpdateExportForm = {
    type: 'UPDATE_EXPORT_FORM',
    payload: {
        format?: 'csv' | 'xls',
        description?: string,
        columns?: Array<string>,
        start_at?: moment,
        end_at?: moment,
        email?: string,
    },
};
export const updateExportForm = (form: {
    format?: 'csv' | 'xls',
    description?: string,
    columns?: Array<string>,
    start_at?: moment,
    end_at?: moment,
    email?: string,
}): $UpdateExportForm => ({
    type: UPDATE_EXPORT_FORM,
    payload: {
        ...form,
    },
});

export const RESET_TRANSACTIONS_EXPORTS_FORM = 'RESET_TRANSACTIONS_EXPORTS_FORM';
export type $ResetTransactionsExportsForm = {
    type: 'RESET_TRANSACTIONS_EXPORTS_FORM',
};
export const resetTransactionsExportsForm = (): $ResetTransactionsExportsForm => ({
    type: RESET_TRANSACTIONS_EXPORTS_FORM,
});

export const REQUEST_EXPORT_CREATION = 'REQUEST_EXPORT_CREATION';
export type $RequestExportCreation = { type: 'REQUEST_EXPORT_CREATION' };
export const requestExportCreation = (): $RequestExportCreation => ({
    type: REQUEST_EXPORT_CREATION,
});

export const REQUEST_EXPORT_DOWNLOAD = 'REQUEST_EXPORT_DOWNLOAD';
export type $RequestExportDownload = {
    type: 'REQUEST_EXPORT_DOWNLOAD',
    payload: {
        id: string,
    },
};
export const requestExportDownload = (id: string): $RequestExportDownload => ({
    type: REQUEST_EXPORT_DOWNLOAD,
    payload: { id },
});

export const RESET_PAYOUTS_EXPORTS_FORM = 'RESET_PAYOUTS_EXPORTS_FORM';
export type $ResetPayoutsExportsForm = {
    type: 'RESET_PAYOUTS_EXPORTS_FORM',
};
export const resetPayoutsExportsForm = (): $ResetPayoutsExportsForm => ({
    type: RESET_PAYOUTS_EXPORTS_FORM,
});

export const RESET_PAYOUT_DETAILS_EXPORTS_FORM = 'RESET_PAYOUT_DETAILS_EXPORTS_FORM';
export type $ResetPayoutDetailsExportsForm = {
    type: 'RESET_PAYOUT_DETAILS_EXPORTS_FORM',
    payload: {
        payout: string,
    },
};
export const resetPayoutDetailsExportsForm = (payout: string): $ResetPayoutDetailsExportsForm => ({
    type: RESET_PAYOUT_DETAILS_EXPORTS_FORM,
    payload: {
        payout,
    },
});
