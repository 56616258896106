// @flow

import type { $BoardPreset } from './reducer';

export const BOARDS_PRESETS: Array<$BoardPreset> = [
    {
        name: 'Sales overview',
        description:
            '**Get a clear view on your sales performances:**\n' +
            '* Overall sales volume\n' +
            '* Refunds volume\n' +
            '* Country distribution\n',
        charts: [
            {
                name: 'Gross revenue',
                description: 'Gross revenue of the business during the period',
                position_x: 0,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula: 'sum{path: "transactions.captured_amount_local"; default: 0}',
                },
                unit: 'amount',
            },
            {
                name: 'Refunds',
                description: 'Refunds issued on the period',
                position_x: 3,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula: 'sum{path: "transactions.refunded_amount_local"; default: 0}',
                },
                unit: 'amount',
            },
            {
                name: 'Net revenue',
                description: '',
                position_x: 6,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'sum{path:"transactions.captured_amount_local"; default: 0} + sum{path:"transactions.refunded_amount_local"; default: 0}',
                },
                unit: 'amount',
            },
            {
                name: 'Sales',
                description: '',
                position_x: 9,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula: 'count{path:"transactions"; status == "completed"; default: 0}',
                },
                unit: 'transactions',
            },
            {
                name: 'Gross revenue',
                description: 'Gross revenue over time',
                position_x: 0,
                position_y: 3,
                size: 6,
                height: 6,
                type: 'line-chart',
                settings: {
                    formula:
                        'plot{path: transactions.created_at; formula: sum{path: "transactions.captured_amount_local"; status != "failed" AND status != "pending" AND status != "pending-capture"; default: 0}}',
                },
                unit: 'amount',
            },
            {
                name: 'Sales',
                description: '',
                position_x: 6,
                position_y: 3,
                size: 6,
                height: 6,
                type: 'line-chart',
                settings: {
                    formula:
                        'plot{path: transactions.created_at; formula: count{path:"transactions"; status == "completed"; default: 0}}',
                },
                unit: 'transactions',
            },
            {
                name: 'Refunds value',
                description: '',
                position_x: 0,
                position_y: 6,
                size: 6,
                height: 6,
                type: 'line-chart',
                settings: {
                    formula:
                        'plot{path: transactions.created_at; formula: sum{path:"transactions.amount_local"; status == "refunded" OR status == "partially-refunded"; default: 0}}',
                },
                unit: 'amount',
            },
            {
                name: 'Refunded transactions',
                description: '',
                position_x: 6,
                position_y: 6,
                size: 6,
                height: 6,
                type: 'line-chart',
                settings: {
                    formula:
                        'plot{path: transactions.created_at; formula: count{path:"transactions"; status == "refunded" OR status == "partially-refunded"; default: 0}}',
                },
                unit: 'transactions',
            },
            {
                name: 'Distribution of transactions / currency',
                description: '',
                position_x: 0,
                position_y: 9,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{path: transactions.currency; formula: count{path:"transactions"; captured_amount > 0; default: 0}}',
                },
                unit: 'transactions',
            },
            {
                name: 'Distribution of transactions / country',
                description: '',
                position_x: 6,
                position_y: 9,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{path: transactions.country; formula: count{path:"transactions"; captured_amount > 0; default: 0}}',
                },
                unit: 'transactions',
            },
        ],
    },
    {
        name: 'Payment performance overview',
        description:
            '**Get a clear view on your payment performance:**\n' +
            '* Monitor your authorization rate\n' +
            "* How much you're paying on fees\n" +
            '* How are your different countries performing\n',
        charts: [
            {
                name: 'Merchant accounts',
                description: '',
                position_x: 0,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'cardinality{path:"transactions.gateway_configuration_id"; default: 0}',
                },
                unit: 'providers',
            },
            {
                name: 'Authorization rate',
                description: '',
                position_x: 3,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'count{path:"transactions"; authorized_amount > 0; default: 0} / count{path:"transactions"; status != "waiting" AND status != "pending" AND duplicate_distance_seconds == null; default: 0}',
                },
                unit: 'percentage',
            },
            {
                name: 'Paid in fees',
                description: '',
                position_x: 6,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'sum{path:"transactions.gateway_fee_local"; default: 0} / sum{path:"transactions.amount_local"; authorized_amount > 0 AND gateway_fee_local > 0; default: 0}',
                },
                unit: 'percentage',
            },
            {
                name: 'Total fees',
                description: '',
                position_x: 9,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula: 'sum{path:"transactions.gateway_fee_local"; default: 0}',
                },
                unit: 'amount',
            },
            {
                name: 'Number of transactions / provider',
                description: '',
                position_x: 0,
                position_y: 3,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{path: transactions.gateway_configuration_id; formula: count{path:"transactions"; status != "waiting" AND status != "pending"; default: 0}}',
                },
                unit: 'transactions',
                x_axis_format: 'gateway-configuration',
                favorite: false,
                created_at: '0001-01-01T00:00:00Z',
            },
            {
                name: 'Value of transactions / provider',
                description: '',
                position_x: 6,
                position_y: 3,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{path: transactions.gateway_configuration_id; formula: sum{path:"transactions.amount_local"; status != "waiting" AND status != "pending"; default: 0}}',
                },
                unit: 'amount',
                x_axis_format: 'gateway-configuration',
                favorite: false,
                created_at: '0001-01-01T00:00:00Z',
            },
            {
                name: 'Authorization rate',
                description: '',
                position_x: 0,
                position_y: 6,
                size: 12,
                height: 6,
                type: 'line-chart',
                settings: {
                    formula:
                        'plot{path: transactions.created_at; formula: count{path:"transactions"; status != "waiting" and status != "pending" and status != "failed" and duplicate_distance_seconds == null; default: 0} / count{path:"transactions"; status != "waiting" AND status != "pending" AND duplicate_distance_seconds == null; default: 0}}',
                },
                unit: 'percentage',
            },
            {
                name: 'Paid in fees',
                description: '',
                position_x: 0,
                position_y: 9,
                size: 12,
                height: 6,
                type: 'line-chart',
                settings: {
                    formula:
                        'plot{path: transactions.created_at; formula: sum{path:"transactions.gateway_fee_local"; default: 0} / sum{path:"transactions.amount_local"; authorized_amount > 0 AND gateway_fee_local > 0; default: 0}}',
                },
                unit: 'percentage',
            },
            {
                name: 'Authorization rate / country',
                description: '',
                position_x: 0,
                position_y: 12,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{path: transactions.country; formula: count{path:"transactions"; status != "waiting" and status != "pending" and status != "failed" and duplicate_distance_seconds == null; default: 0} / count{path:"transactions"; status != "waiting" AND status != "pending" AND duplicate_distance_seconds == null; default: 0}}',
                },
                unit: 'percentage',
            },
            {
                name: 'Provider fees / country',
                description: '',
                position_x: 6,
                position_y: 12,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{path: transactions.country; formula: sum{path:"transactions.gateway_fee_local"; default: 0} / sum{path:"transactions.amount_local"; authorized_amount > 0 AND gateway_fee_local > 0; default: 0}}',
                },
                unit: 'percentage',
            },
            {
                name: 'Authorization rate / merchant account',
                description: '',
                position_x: 0,
                position_y: 15,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{path: transactions.gateway_configuration_id; formula: count{path:"transactions"; status != "waiting" and status != "pending" and status != "failed" and duplicate_distance_seconds == null; default: 0} / count{path:"transactions"; status != "waiting" AND status != "pending" AND duplicate_distance_seconds == null; default: 0}}',
                },
                unit: 'percentage',
                x_axis_format: 'gateway-configuration',
                favorite: false,
                created_at: '0001-01-01T00:00:00Z',
            },
            {
                name: 'Provider fees / merchant account',
                description: '',
                position_x: 6,
                position_y: 15,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{path: transactions.gateway_configuration_id; formula: sum{path:"transactions.gateway_fee_local"; default: 0} / sum{path:"transactions.amount_local"; authorized_amount > 0 AND gateway_fee_local > 0; default: 0}}',
                },
                unit: 'percentage',
                x_axis_format: 'gateway-configuration',
                favorite: false,
                created_at: '0001-01-01T00:00:00Z',
            },
            {
                name: 'Authorization rate / card scheme',
                description: '',
                position_x: 0,
                position_y: 18,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{path: transactions.card_scheme; formula: count{path:"transactions";  status != "waiting" and status != "pending" and status != "failed" and duplicate_distance_seconds == null; default: 0} / count{path:"transactions"; status != "waiting" AND status != "pending" AND duplicate_distance_seconds == null; default: 0}}',
                },
                unit: 'percentage',
            },
            {
                name: 'Provider fees / card scheme',
                description: '',
                position_x: 6,
                position_y: 18,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{path: transactions.card_scheme; formula: sum{path:"transactions.gateway_fee_local"; default: 0} / sum{path:"transactions.amount_local"; authorized_amount > 0 AND gateway_fee_local > 0; default: 0}}',
                },
                unit: 'percentage',
            },
        ],
    },
    {
        name: 'Performance healthchecker',
        description:
            '**Get insights on your payment performance:**\n' +
            '* Monitor your authorization rate depending on countries, currencies and more\n' +
            "* What's your chargeback rate\n",
        charts: [
            {
                name: 'Authorization rate',
                description: '',
                position_x: 6,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'count{\n    path:transactions;\n    default: 0;\n    status != "pending"\n    AND status != "waiting"\n    AND status != "failed"\n    AND duplicate_distance_seconds == null;\n}\n/ count{\n    path: transactions;\n    default: 0;\n    status != "pending"\n    AND status != "waiting"\n    AND duplicate_distance_seconds == null;\n}',
                },
                unit: 'percentage',
            },
            {
                name: 'Failed transactions',
                description: '',
                position_x: 3,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'count{\n    path:transactions.transactions;\n    default: 0;\n    status == "failed" and duplicate_distance_seconds == null;\n}',
                },
                unit: 'count',
            },
            {
                name: 'Successful transcations',
                description: '',
                position_x: 0,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'count{\n    path:transactions.transactions;\n    default: 0;\n    captured_amount > 0;\n}',
                },
                unit: 'count',
            },
            {
                name: 'Chargeback rate',
                description: '',
                position_x: 9,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'count{\n    path:transactions;\n    default: 0;\n    status == "reversed" or status == "chargeback-initiated" or status == "solved";\n}\n/ count{\n    path:transactions;\n    default: 0;\n}',
                },
                unit: 'percentage',
            },
            {
                name: 'Authorization rate per country',
                description: '',
                position_x: 0,
                position_y: 3,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{\n    path:transactions.country;\n    strategy: value_descending;\n    top: 20;\n    formula: count{\n        path: transactions;\n        default: 0;\n    duplicate_distance_seconds == null}\n    ;\n    count{\n        path:transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND status != "failed"\n        AND duplicate_distance_seconds == null;\n    }\n    / count{\n        path: transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND duplicate_distance_seconds == null;\n    }\n}\n;',
                },
                unit: 'percentage',
            },
            {
                name: 'Authorization rate per currency',
                description: '',
                position_x: 6,
                position_y: 3,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{\n    path:transactions.currency;\n    strategy: value_descending;\n    top: 20;\n    formula: count{\n        path: transactions;\n        default: 0;\n    duplicate_distance_seconds == null}\n    ;\n    count{\n        path:transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND status != "failed"\n        AND duplicate_distance_seconds == null;\n    }\n    / count{\n        path: transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND duplicate_distance_seconds == null;\n    }\n}\n;',
                },
                unit: 'percentage',
            },
            {
                name: 'Authorization rate per acquirer',
                description: '',
                position_x: 0,
                position_y: 9,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{\n    path:transactions.acquirer_name;\n    strategy: value_descending;\n    top: 20;\n    formula: count{\n        path: transactions;\n        default: 0;\n    duplicate_distance_seconds == null}\n    ;\n    count{\n        path:transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND status != "failed"\n        AND duplicate_distance_seconds == null;\n    }\n    / count{\n        path: transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND duplicate_distance_seconds == null;\n    }\n}\n;',
                },
                unit: 'percentage',
            },
            {
                name: 'Authorization rate per average basket',
                description: '',
                position_x: 6,
                position_y: 9,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{\n    path:transactions.amount_local;\n    intervals: 0, 2, 5, 20, 50, 200, 500;\n    formula: count{\n        path: transactions;\n        default: 0;\n    duplicate_distance_seconds == null}\n    ;\n    count{\n        path:transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND status != "failed"\n        AND duplicate_distance_seconds == null;\n    }\n    / count{\n        path: transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND duplicate_distance_seconds == null;\n    }\n}\n;',
                },
                unit: 'percentage',
            },
            {
                name: 'Chargeback rate',
                description: '',
                position_x: 6,
                position_y: 15,
                size: 6,
                height: 6,
                type: 'line-chart',
                settings: {
                    formula:
                        'plot{\n    path:transactions.created_at;\n    formula: count{\n        path:transactions;\n        default: 0;\n        status == "reversed";\n    }\n    / count{\n        path:transactions;\n        default: 0;\n    }\n}\n;',
                },
                unit: 'percentage',
            },
            {
                name: 'Authorization rate per CVC status',
                description: '',
                position_x: 0,
                position_y: 15,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{\n    path:transactions.cvc_check;\n    strategy: value_descending;\n    top: 20;\n    formula: count{\n        path: transactions;\n        default: 0;\n    duplicate_distance_seconds == null}\n    ;\n    count{\n        path:transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND status != "failed"\n        AND duplicate_distance_seconds == null;\n    }\n    / count{\n        path: transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND duplicate_distance_seconds == null;\n    }\n}\n;',
                },
                unit: 'percentage',
            },
        ],
    },
    {
        name: 'Error codes analysis',
        description:
            '**Dig into your error codes to understand their origin:**\n' +
            '* Discover why payments are failing\n' +
            '* How many failed transactions you encounter\n' +
            "* What's your decline rate",
        charts: [
            {
                name: 'Top 10 error codes distribution',
                description: '',
                position_x: 0,
                position_y: 6,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{\n    path:transactions.error_code;\n    strategy: value_descending;\n    top: 10;\n    formula: count{\n        path:transactions;\n        default: 0;\n    }\n}\n;',
                },
                unit: 'count',
            },
            {
                name: 'Top 5 error codes evolution',
                description: '',
                position_x: 3,
                position_y: 0,
                size: 9,
                height: 6,
                type: 'line-chart',
                settings: {
                    formula:
                        'plot{\n    formula: count{\n        path: transactions; default: 0;\n    }\n    ;\n    path: transactions.error_code;\n    strategy: value_descending;\n    top: 5;\n    plot{\n        path:transactions.created_at;\n        formula: count{\n            path:transactions;\n            default: 0;\n        }\n    }\n    ;\n}',
                },
                is_comparison: true,
                unit: 'count',
            },
            {
                name: 'Failed transactions',
                description: '',
                position_x: 0,
                position_y: 0,
                size: 3,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'count{\n    path:transactions.transactions;\n    default: 0;\n    status == "failed";\n}',
                },
                unit: 'count',
            },
            {
                name: 'Decline rate per payment type',
                description: '',
                position_x: 6,
                position_y: 6,
                size: 6,
                height: 6,
                type: 'bar-chart',
                settings: {
                    formula:
                        'plot{\n    path:transactions.payment_type;\n    strategy: value_descending;\n    top: 10;\n    formula: count{\n        path:transactions;\n        default: 0;\n        status == "failed"\n        AND status != "pending"\n        AND status != "waiting"\n        AND duplicate_distance_seconds == null;\n        ;\n    }\n    / count{\n        path:transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND duplicate_distance_seconds == null;\n        ;\n    }\n}\n;',
                },
                unit: 'percentage',
            },
        ],
    },
    {
        name: 'Transaction retries analysis',
        description:
            "**Understand your transaction's retries performance:**\n" +
            '* How is your retry strategy performing\n' +
            '* What transactions are retried\n' +
            '* Raw vs Net authorization rates',
        charts: [
            {
                name: 'Average retry success rate',
                description: '',
                position_x: 4,
                position_y: 0,
                size: 4,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'count{\n    path:transactions;\n    default: 0;\n    attempts_count > 1\n    AND authorized_amount > 0;\n}\n/ count{\n    path:transactions;\n    default: 0;\n    attempts_count > 1;\n}',
                },
                unit: 'percentage',
            },
            {
                name: 'Average retried basket',
                description: '',
                position_x: 8,
                position_y: 0,
                size: 4,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'average{\n    path:transactions.authorized_amount_local;\n    default: 0;\n    attempts_count > 1;\n}',
                },
                unit: 'amount',
            },
            {
                name: 'Retry success rate evolution',
                description: '',
                position_x: 0,
                position_y: 3,
                size: 12,
                height: 6,
                type: 'line-chart',
                settings: {
                    formula:
                        'plot{\n    path:transactions.created_at;\n    formula: count{\n        path:transactions;\n        default: 0;\n        attempts_count > 1\n        AND authorized_amount > 0;\n    }\n    / count{\n        path:transactions;\n        default: 0;\n        attempts_count > 1;\n    }\n}\n;',
                },
                unit: 'percentage',
            },
            {
                name: 'Retried transactions',
                description: '',
                position_x: 0,
                position_y: 0,
                size: 4,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'count{\n    path:transactions.transactions;\n    default: 0;\n    attempts_count > 1;\n}',
                },
                unit: 'count',
            },
            {
                name: 'Raw authorization rate',
                description: '',
                position_x: 2,
                position_y: 9,
                size: 4,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'count{\n    path: transactions;\n    authorized_amount > 0\n} \n/ \ncount{\n    path: transactions;\n     status != "pending" and status != "waiting"\n}',
                },
                unit: 'percentage',
            },
            {
                name: 'Net authorization rate',
                description: '',
                position_x: 6,
                position_y: 9,
                size: 4,
                height: 3,
                type: 'single-value',
                settings: {
                    formula:
                        'count{\n    path: transactions;\n    authorized_amount > 0 and duplicate_distance_seconds == null\n} \n/ \ncount{\n    path: transactions;\n    status != "pending" and status != "waiting" and duplicate_distance_seconds == null\n}',
                },
                unit: 'percentage',
            },
            {
                name: 'Raw authorization rate',
                description: '',
                position_x: 0,
                position_y: 12,
                size: 6,
                height: 6,
                type: 'line-chart',
                settings: {
                    formula:
                        'plot{\n    path:transactions.created_at;\n    formula: count{\n        path: transactions;\n        default: 0;\n    }\n    ;\n    count{\n        path:transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND status != "failed"\n        \n    }\n    / count{\n        path: transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n\n    }\n}\n;',
                },
                unit: 'percentage',
            },
            {
                name: 'Net authorization rate',
                description: '',
                position_x: 6,
                position_y: 12,
                size: 6,
                height: 6,
                type: 'line-chart',
                settings: {
                    formula:
                        'plot{\n    path:transactions.created_at;\n    formula: count{\n        path: transactions;\n        default: 0;\n    duplicate_distance_seconds == null}\n    ;\n    count{\n        path:transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND status != "failed"\n        AND duplicate_distance_seconds == null;\n    }\n    / count{\n        path: transactions;\n        default: 0;\n        status != "pending"\n        AND status != "waiting"\n        AND duplicate_distance_seconds == null;\n    }\n}\n;',
                },
                unit: 'percentage',
            },
        ],
    },
];
