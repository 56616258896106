// @flow

import React from 'react';
import Creatable from 'react-select-v2/lib/Creatable';
import { SelectStyle } from '../../analytics/DataExplorer/ChartBuilder/consts';
import { VELOCITY_INTERVAL_OPTIONS } from './consts';
import type { $Option } from '../../analytics/DataExplorer/consts';

type Props = {
    value: ?string,
    onChange: string => void,
};

class VelocityInterval extends React.Component<Props> {
    createValue = newValue => {
        this.props.onChange(newValue);
    };

    render() {
        const { value } = this.props;
        let correspondingOption = VELOCITY_INTERVAL_OPTIONS.find(op => op.value === value);
        let label = value;
        if (correspondingOption) label = correspondingOption.label;
        return (
            <div className='labeled-field' style={{ width: '75%' }}>
                <Creatable
                    options={VELOCITY_INTERVAL_OPTIONS}
                    placeholder='1 minute...'
                    value={value ? { label, value } : null}
                    onChange={(newValue: $Option) => {
                        this.props.onChange(newValue.value);
                    }}
                    styles={SelectStyle}
                    onCreateOption={this.createValue}
                />
            </div>
        );
    }
}

export default VelocityInterval;
