// @flow
import React from 'react';
import SearchBar from './SearchBar';
import type { $DataExplorerState } from './reducer';
import type { $Dispatcher, $Project } from '../../../util/Types';
import { connect } from 'react-redux';
import type { $DataExplorerTab } from './consts';
import * as ConfirmActions from '../../ConfirmModal/actions';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router';

type Props = {
    dataExplorer: $DataExplorerState,
    currentProject: {
        project: $Project,
    },
    isChartBuilder: ?boolean,
} & $Dispatcher;

class Tabs extends React.Component {
    props: Props;

    onTabClick = (tab: $DataExplorerTab) => {
        const { dispatch, location, dataExplorer } = this.props;
        const redirect = () => {
            dispatch(
                push(`/projects/${this.props.currentProject.project.id}/boards/${this.props
                    .isChartBuilder
                    ? `${this.props.dataExplorer.boardId}/new-chart/${tab}${location.query.chart
                      ? `?chart=${location.query.chart}`
                      : ''}`
                    : `data-explorer/${tab}`}`,
                ),
            );
        };
        if (
            dataExplorer.selectedTab === 'editor' &&
            tab !== 'editor' &&
            dataExplorer.editorHasBeenEdited
        ) {
            dispatch(
                ConfirmActions.requestConfirmation(
                    <div class="row">
                        <div class="large-11 columns large-centered">
                            Leaving the editor will reset its content. Are your sure you want to
                            proceed?
                        </div>
                    </div>,
                    () => {
                        redirect();
                    },
                    () => {},
                ),
            );
        } else {
            redirect();
        }
    };

    render() {
        const { dataExplorer } = this.props;
        return (
            <div class="data-explorer-tabs">
                <div class="row">
                    <div class="large-12 columns">
                        <a
                            class={`tab-button ${dataExplorer.selectedTab === 'chart-builder'
                                ? ' active'
                                : ''}`}
                            onClick={event => {
                                event.preventDefault();
                                this.onTabClick('chart-builder');
                            }}
                        >
                            Chart Builder
                        </a>
                        <a
                            class={`tab-button last${dataExplorer.selectedTab === 'editor'
                                ? ' active'
                                : ''}`}
                            onClick={event => {
                                event.preventDefault();
                                this.onTabClick('editor');
                            }}
                        >
                            Code Editor
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return {
        dataExplorer: store.dataExplorer,
        currentProject: store.currentProject,
    };
})(withRouter(Tabs));
