// @flow

import React from 'react';

type Props = {
    colors: Array<{ key: string, color: string }>, // Support maximum 5 keys
};
class Legend extends React.Component {
    props: Props;
    render() {
        const { colors } = this.props;
        return (
            <div class="pie-legend">
                {colors.map(color => {
                    return (
                        <div key={color.key} class="pie-legend-row">
                            <div
                                class="pie-legend-color"
                                style={{ backgroundColor: color.color }}
                            />
                            <div class="pie-legend-key"> {color.key}</div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
export default Legend;
