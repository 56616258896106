import React from 'react';
import { connect } from 'react-redux';
import QueryCreator from '../util/QueryCreator';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

const FORWARD = 'forward';
const BACKWARD = 'backward';

@connect(function(store) {
    return {};
})
export default class Pagination extends React.Component {
    static propTypes = {
        reloadFunction: PropTypes.func.isRequired,
        itemsArray: PropTypes.array.isRequired,
        hasMore: PropTypes.bool.isRequired,
        hide: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        smallContainer: PropTypes.bool, // if the parent is not large enough
        disableURLUpdate: PropTypes.bool,
    };

    constructor() {
        super();

        this.state = {
            prevLocked: true,
            direction: FORWARD,
        };
    }

    componentDidMount() {
        var afterItem = this.props.location.query.after_item;
        var beforeItem = this.props.location.query.before_item;
        this.setState({ prevLocked: !afterItem && !beforeItem });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.hide || nextProps.disableURLUpdate) return;

        var afterItem = nextProps.location.query.after_item;
        var beforeItem = nextProps.location.query.before_item;
        if (!afterItem && !beforeItem) this.setState({ prevLocked: true });
        if (afterItem !== this.props.location.query.after_item) {
            if (!afterItem) afterItem = '';
            this.setState({ direction: FORWARD });
            this.props.dispatch(this.props.reloadFunction(true, afterItem));
        } else if (beforeItem !== this.props.location.query.before_item) {
            if (!beforeItem) beforeItem = '';
            this.setState({ direction: BACKWARD });
            this.props.dispatch(this.props.reloadFunction(false, beforeItem));
        }
    }

    onPrevious(event) {
        event.preventDefault();
        this.setState({ direction: BACKWARD });
        this.updateURL(false, this.props.itemsArray[0].id);
        this.props.dispatch(this.props.reloadFunction(false, this.props.itemsArray[0].id));
    }

    onNext(event) {
        event.preventDefault();
        this.setState({
            prevLocked: false,
            direction: FORWARD,
        });
        this.updateURL(true, this.props.itemsArray[this.props.itemsArray.length - 1].id);
        this.props.dispatch(
            this.props.reloadFunction(
                true,
                this.props.itemsArray[this.props.itemsArray.length - 1].id,
            ),
        );
    }

    updateURL(forward, id) {
        if (this.props.disableURLUpdate) return;

        var query = this.props.location.query;
        var nextQuery;
        if (forward) {
            nextQuery = {
                after_item: id,
                before_item: '',
            };
        } else {
            nextQuery = {
                before_item: id,
                after_item: '',
            };
        }
        query = QueryCreator(query, nextQuery);

        query = encodeURI(query);
        this.props.dispatch(push(`${this.props.location.pathname}${query}`));
    }

    render() {
        if (this.props.hide || this.props.itemsArray.length === 0) return null;

        var previousEnabled =
            !this.state.prevLocked &&
            (this.state.direction === FORWARD ||
                (this.state.direction === BACKWARD && this.props.hasMore));
        var nextEnabled =
            this.state.direction === BACKWARD ||
            (this.state.direction === FORWARD && this.props.hasMore);

        var prev = <span class='disabled'>← previous</span>;
        if (previousEnabled) var prev = <a onClick={this.onPrevious.bind(this)}>← previous</a>;
        var next = <span class='disabled'>next &rarr;</span>;
        if (nextEnabled) var next = <a onClick={this.onNext.bind(this)}>next &rarr;</a>;

        return (
            <div class='row pagination-wrapper text-left'>
                <div class={`medium-${this.props.smallContainer ? '5' : '3'} columns`}>
                    <div class='row buttons'>
                        <div class='medium-6 columns'>{prev}</div>
                        <div class='medium-6 columns text-right'>{next}</div>
                    </div>
                </div>
                <div class={`medium-${this.props.smallContainer ? '7' : '9'} columns`}>
                    <div class='line' />
                </div>
            </div>
        );
    }
}
