// @flow

import type { $ChartType } from "../../Boards/consts";
import type { $Dimension } from "./DimensionSelection/consts";
import uniqid from "uniqid";
import { BUILDER_METRICS } from "./consts";
import type {
    $SelectBuilderMetricAction,
    $SelectTypeAction,
    $SetDisplayLocalCurrencyAction,
    $SetDisplayNetMetrics,
    $UpdateDimensionsAction,
    $UpdateFiltersAction,
} from "./actions";
import {
    SELECT_BUILDER_METRIC,
    SELECT_TYPE,
    SET_DISPLAY_LOCAL_CURRENCY,
    SET_DISPLAY_NET_METRICS,
    UPDATE_DIMENSIONS,
    UPDATE_FILTERS,
} from "./actions";
import type { $Metric } from "./consts";
import type { $Filter } from "./Filters/consts";
import { APPLY_PRESET_CHART_BUILDER } from "../sagas";
import { REQUEST_FORMULA_COMPILE, RESET_DATA_EXPLORER } from "../actions";
import * as ProcessOut from "../../../../util/ProcessOut";

export type $BuilderMetric = {
    name: string,
    description: string,
    metrics: Array<$Metric>,
    generalFormula: string,
    unit: string,
    group: string,
};

export type $ChartBuilderState = {
    selectedMetric: $BuilderMetric,
    dimensions: Array<$Dimension>,
    filters: Array<$Filter>,
    displayInLocalCurrency: boolean,
    displayNetMetrics: boolean,
    canEditIfChartIdExists: boolean,
};

const defaultState: $ChartBuilderState = {
    selectedMetric: BUILDER_METRICS[0],
    dimensions: [
        {
            id: uniqid(),
            field: "transactions.created_at",
            formula: "count{path: transactions; default: 0}",
            strategy: "key_ascending",
            top: null,
        },
    ],
    filters: [],
    displayInLocalCurrency: true,
    displayNetMetrics: true,
    canEditIfChartIdExists: false,
};

export default function(
    state: $ChartBuilderState = defaultState,
    action:
        | $SelectBuilderMetricAction
        | $SelectTypeAction
        | $SetDisplayLocalCurrencyAction
        | $UpdateDimensionsAction
        | $UpdateFiltersAction
        | $SetDisplayNetMetrics,
): $ChartBuilderState {
    switch (action.type) {
        case RESET_DATA_EXPLORER: {
            return {
                ...state,
                selectedMetric: BUILDER_METRICS[0],
                dimensions: [
                    {
                        id: uniqid(),
                        field: "transactions.created_at",
                        formula: "count{path: transactions; default: 0}",
                        strategy: "key_ascending",
                        top: null,
                    },
                ],
                filters: [],
                displayInLocalCurrency: true,
                canEditIfChartIdExists: false,
            };
        }

        case SELECT_BUILDER_METRIC: {
            return {
                ...state,
                selectedMetric: action.payload.metric,
                displayInLocalCurrency: true,
            };
        }

        case SET_DISPLAY_LOCAL_CURRENCY: {
            return {
                ...state,
                displayInLocalCurrency: action.payload.displayInLocalCurrency,
            };
        }

        case UPDATE_DIMENSIONS: {
            return {
                ...state,
                dimensions: action.payload.dimensions,
            };
        }

        case UPDATE_FILTERS: {
            return {
                ...state,
                filters: action.payload.filters,
            };
        }

        case APPLY_PRESET_CHART_BUILDER: {
            const { payload } = action;
            return {
                ...state,
                ...payload,
            };
        }

        case SET_DISPLAY_NET_METRICS: {
            const { payload } = action;
            return {
                ...state,
                displayNetMetrics: payload.displayNetMetrics,
            };
        }

        case ProcessOut.typeFailed(REQUEST_FORMULA_COMPILE): {
            return {
                ...state,
                canEditIfChartIdExists: true,
            };
        }
    }

    return state;
}
