import * as ProcessOut from '../util/ProcessOut';
import { goBack } from 'react-router-redux';

export function loadSubscriptions(filter, length, after, id) {
    var bound = after ? 'start_after' : 'end_before';
    if (!id) {
        id = '';
    }
    filter = filter ? filter : '';

    return {
        type: 'FETCH_SUBSCRIPTIONS',
        payload: ProcessOut.APIcallPromise(
            `/subscriptions?expand=customer&order=desc&${bound}=${id}&limit=${length}&filter=${filter}`,
            'GET',
            null,
        ),
    };
}

export function loadSubscriptionDetails(id) {
    return function(dispatch) {
        dispatch({
            type: 'FETCH_SUBSCRIPTION_DETAILS',
            payload: ProcessOut.APIcallPromise(
                `/subscriptions/${id}?expand[]=customer&expand[]=plan`,
                'GET',
                null,
            ),
        });
    };
}

export function loadSubscriptionTransactions(length, subscriptionId, after, id) {
    var bound = after ? 'start_after' : 'end_before';
    if (!id) {
        id = '';
    }

    return function(dispatch) {
        dispatch({
            type: 'FETCH_SUBSCRIPTION_TRANSACTIONS',
            payload: ProcessOut.APIcallPromise(
                `/subscriptions/${subscriptionId}/transactions?order=desc&limit=${length}&${bound}=${id}&expand[]=gateway_configuration.gateway&expand[]=customer&expand[]=operations`,
                'GET',
                null,
            ),
        });
    };
}

export function deleteSubscription(id) {
    return function(dispatch) {
        dispatch({
            type: 'DELETE_SUBSCRIPTION',
            payload: ProcessOut.APIcallPromise(`/subscriptions/${id}`, 'DELETE', {}).then(function(
                response,
            ) {
                if (response.data.success) {
                    dispatch(goBack());
                }
            }),
        });
    };
}
