// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { $Permission, $PermissionFeature, $PermissionGroup } from './consts';
import Error from '../../components/Error';
import type { $Dispatcher } from '../../util/Types';
import * as Actions from './actions';

type Props = {
    feature: $PermissionFeature,
    permissionGroupName: string,
    permissions: Array<$Permission>,
} & $Dispatcher;

class PermissionFeature extends React.Component {
    props: Props;
    _readCheckbox: any;
    _writeCheckbox: any;

    readValueChanged = event => {
        const { dispatch, feature, permissionGroupName } = this.props;

        if (this.getState(feature).read) {
            // Read is already checked so we disable this featur
            dispatch(Actions.removePermissionFromGroup(permissionGroupName, feature, 'allow'));
        } else
            // Read wasn't checked we enable it
            dispatch(
                Actions.setPermissionGroupValues(permissionGroupName, feature, 'read', 'allow'),
            );
    };

    writeValueChanged = () => {
        const { dispatch, feature, permissionGroupName } = this.props;

        dispatch(
            Actions.setPermissionGroupValues(
                permissionGroupName,
                feature,
                this.getState().write ? 'read' : 'write',
                'allow',
            ),
        );
    };

    getState = (): { read: boolean, write: boolean } => {
        const { feature, permissionGroupName, permissions } = this.props;

        return {
            read:
                permissions.findIndex(
                    p => p.id === feature.id && (p.action === 'read' || p.action === 'write'),
                ) > -1,
            write: permissions.findIndex(p => p.id === feature.id && p.action === 'write') > -1,
        };
    };

    render() {
        const { feature, permissionGroupName, permissions } = this.props;
        return (
            <div class="row">
                <div class="medium-4 columns">
                    <span>{feature.name}</span>
                </div>
                <div class="medium-8 columns">
                    <div class="row">
                        <div class="medium-6 columns text-center">
                            <input
                                ref={e => (this._readCheckbox = e)}
                                type="checkbox"
                                checked={this.getState().read}
                                onChange={this.readValueChanged}
                                disabled={!feature.actions.includes('read')}
                            />
                        </div>
                        <div class="medium-6 columns text-center">
                            <input
                                ref={e => (this._writeCheckbox = e)}
                                type="checkbox"
                                checked={this.getState().write}
                                onChange={this.writeValueChanged}
                                disabled={!feature.actions.includes('write')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => ({ permissionGroups: store.permissions.permissionGroups }))(
    PermissionFeature,
);
