// @flow
import React from 'react';
import Select from 'react-select';
import type {
    $BAR_CHART,
    $ChartType,
    $LINE_CHART,
    $PIE_CHART,
    $SINGLE_VALUE,
    $TIME_SERIES,
} from '../Boards/consts';
import {
    BAR_CHART,
    LINE_CHART,
    MAP_CHART,
    PIE_CHART,
    SINGLE_VALUE,
    TIME_SERIES,
} from '../Boards/consts';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../../util/Types';
import { UPDATE_CHART_BUILDER_DETAILS } from './consts';
import type { $Dimension } from './ChartBuilder/DimensionSelection/consts';
import type { $ChartBuilderState } from './ChartBuilder/reducer';
import { SELECT_TYPE } from './ChartBuilder/actions';

const OPTIONS = [
    { value: LINE_CHART, label: 'Over time' },
    { value: BAR_CHART, label: 'Bar chart' },
    { value: SINGLE_VALUE, label: 'Single value' },
    { value: MAP_CHART, label: 'Map chart' },
    { value: PIE_CHART, label: 'Pie chart' },
];
import * as ChartBuilderActions from './ChartBuilder/actions';
import type { $DataExplorerState } from './reducer';

type Props = {
    chartBuilder: $ChartBuilderState,
    dataExplorer: $DataExplorerState,
    type: string,
    disabled: boolean,
    user: {
        details: {
            email: string,
        },
    },
} & $Dispatcher;

class TypeSelection extends React.Component {
    props: Props;

    componentWillReceiveProps = (nextProps: Props) => {
        const { chartBuilder } = nextProps;
        if (
            chartBuilder.dimensions.length > 0 &&
            this.props.chartBuilder.dimensions.length > 0 &&
            !chartBuilder.dimensions[0].field.endsWith('_at') &&
            chartBuilder.dimensions[0].field !== this.props.chartBuilder.dimensions[0].field &&
            this.props.type === LINE_CHART
        ) {
            // If the global dimension changes we want to be able to automatically detect wether or not we
            // should set the chart type to bar chart.
            this.dispatchValue(BAR_CHART);
        }
    };

    typeSelected(option: { value: $ChartType, label: string }) {
        this.dispatchValue(option.value);
    }

    dispatchValue = (value: $ChartType) => {
        const { dispatch, dataExplorer } = this.props;
        dispatch(ChartBuilderActions.selectType(value, dataExplorer.type));
    };

    render() {
        const { user } = this.props;
        return (
            <div
                style={{
                    zIndex: 5,
                    position: 'relative',
                }}
            >
                <Select
                    name="type"
                    options={OPTIONS.filter(
                        option =>
                            user.details.email.includes('@processout.com') ||
                            (option.value !== 'pie-chart' && option.value !== 'map-chart'),
                    )}
                    value={this.props.type}
                    searchable={false}
                    disabled={this.props.disabled}
                    clearable={false}
                    onChange={this.typeSelected.bind(this)}
                />
            </div>
        );
    }
}

export default connect(store => {
    return {
        user: store.user,
        type: store.dataExplorer.type,
        dataExplorer: store.dataExplorer,
        chartBuilder: store.chartBuilder,
    };
})(TypeSelection);
