// @flow

import React from 'react';
import { connect } from 'react-redux';
import * as BoardsActions from './Boards/actions';
import type { $Dispatcher } from '../../util/Types';
import AnalyticsLayout from '../../pages/AnalyticsLayout';
import type { $BoardsState } from './Boards/reducer';

type Props = {
    boards: $BoardsState,
    location: any,
} & $Dispatcher;

class AnalyticsContainer extends React.Component {
    props: Props;

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(BoardsActions.requestBoardsFetch());
    }

    componentWillUnmount() {
        this.props.dispatch(BoardsActions.clearBoardsList());
    }

    render() {
        const { location, boards, children, params } = this.props;
        return (
            <AnalyticsLayout
                location={location}
                boardId={params.board}
                projectId={params.project}
                noBackground={
                    location.pathname.includes('data-explorer') ||
                    location.pathname.includes('new-chart') ||
                    location.pathname.includes('/boards/new') ||
                    !this.props.params.board
                }
                style={
                    this.props.location.pathname.includes('/boards/new') || !this.props.params.board
                        ? { paddingTop: 0 }
                        : null
                }
            >
                {children}
            </AnalyticsLayout>
        );
    }
}

export default connect(store => {
    return {
        boards: store.analytics_v2.boards,
        fetchParams: store.analytics.params,
    };
})(AnalyticsContainer);
