// @flow

import React from 'react';
import RoutingRuleListing from './RoutingRuleListing';
import * as Actions from './actions';
import { arrayMove } from 'react-sortable-hoc';
import type { $RoutingRule, $RoutingRulesSettings } from './consts';
import type { $Dispatcher } from '../../util/Types';
import { connect } from 'react-redux';
import BlockingRulesListing from './BlockingRulesListing';
import TriggerRulesListing from './TriggerRulesListing';

type Props = {
    rules: Array<$RoutingRule>,
} & $Dispatcher;

class TriggerRules extends React.Component<Props> {
    reorderedRules = (position: { oldIndex: number, newIndex: number }) => {
        const { dispatch, rules } = this.props;
        dispatch(
            Actions.changeRoutingRulesOrder(
                'trigger_3ds',
                arrayMove(rules, position.oldIndex, position.newIndex),
            ),
        );
    };
    render() {
        return (
            <div class='row'>
                <div className='medium-2 columns'>
                    <span class='rule-type-title'>Trigger 3DS</span>
                </div>
                <div class='medium-10 columns rule-linear-background'>
                    <TriggerRulesListing
                        rules={this.props.rules}
                        onSortEnd={this.reorderedRules}
                        useDragHandle={true}
                    />
                </div>
            </div>
        );
    }
}

export default connect()(TriggerRules);
