// @flow

import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import type { TransactionModel } from '../../Types/Transaction';
import DateDisplay from '../../features/DateDisplay/index';
import Check from '../../features/Checks/Check';
import ReactTooltip from 'react-tooltip';

type Props = {
    projects?: any,
    transaction: TransactionModel,
};

@connect(function(store) {
    return {
        projects: store.projects,
    };
})
export default class TransactionDetailsComponent extends React.Component<Props> {
    componentDidMount() {
        ReactTooltip.rebuild();
    }

    formatInterval(interval: number) {
        if (interval === 1) return 'daily';
        else if (interval === 7) return 'weekly';
        else if (interval === 30) return 'monthly';

        return (
            <span>
                every <span class='bold'>{interval}</span> days
            </span>
        );
    }

    getLine(title: string, value: any) {
        return (
            <div class='row small-margin-bottom'>
                <div class='large-4 medium-4 columns greyed'>{title}</div>
                <div class='large-8 medium-8 columns'>
                    <span class='right'>{value || <span class='greyed'>--</span>}</span>
                </div>
            </div>
        );
    }

    render() {
        const transaction = this.props.transaction;
        let currency = transaction.currency;

        if (transaction.id === 0) {
            return <div />;
        }

        let sub;
        if (transaction.subscription) {
            sub = (
                <div>
                    <div class='row small-margin-bottom'>
                        <div class='large-12 columns'>
                            <hr />
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='medium-3 columns greyed'>Subscription</div>
                        <div class='medium-9 columns'>
                            <span class='right light-bold'>
                                <Link
                                    to={`/projects/${
                                        this.props.projects
                                            ? this.props.projects.current_project_id
                                            : ''
                                    }/recurring/subscriptions/${transaction.subscription.id}`}
                                >
                                    {transaction.subscription.name}↗
                                </Link>
                            </span>
                        </div>
                    </div>
                    <div class='row small-margin-bottom'>
                        <div class='medium-3 columns greyed'>Interval</div>
                        <div class='medium-9 columns'>
                            <span class='right'>
                                {this.formatInterval(transaction.subscription.interval_days)}
                            </span>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div class='row'>
                <div class='large-12 medium-8 columns'>
                    <ReactTooltip effect={'solid'} />
                    {this.getLine(
                        'Invoice ID',
                        <span class='font-console small'>{transaction.invoice_id}</span>,
                    )}
                    {this.getLine(
                        'Available',
                        <span class='bold'>
                            <FormattedNumber
                                value={transaction.available_amount}
                                style='currency'
                                currency={currency}
                            />
                        </span>,
                    )}
                    {this.getLine(
                        'Created on',
                        <DateDisplay value={transaction.created_at} relativeSpan='day' />,
                    )}
                    {this.getLine(
                        'Fees',
                        <FormattedNumber
                            value={transaction.gateway_fee ? transaction.gateway_fee : 0}
                            style='currency'
                            currency={currency}
                        />,
                    )}
                    {this.getLine(
                        'Shipping',
                        <FormattedNumber
                            value={
                                transaction.metadata.shipping_amount
                                    ? transaction.metadata.shipping_amount
                                    : 0
                            }
                            style='currency'
                            currency={transaction.currency}
                        />,
                    )}
                    {this.getLine(
                        'Taxes',
                        <FormattedNumber
                            value={
                                transaction.metadata.taxes_amount
                                    ? transaction.metadata.taxes_amount
                                    : 0
                            }
                            style='currency'
                            currency={transaction.currency}
                        />,
                    )}

                    {transaction.avs_check ? (
                        <div class='row small-margin-bottom'>
                            <div class='medium-4 columns greyed text-left'>
                                <div style={{ display: 'inline-block' }}>AVS check</div>
                                <div
                                    class='info'
                                    data-tip='Address check performed for this transaction specifically.'
                                    style={{ display: 'inline-block' }}
                                >
                                    i
                                </div>
                            </div>
                            <div class='medium-8 columns text-right'>
                                <Check check={transaction.avs_check} />
                            </div>
                        </div>
                    ) : null}
                    {transaction.cvc_check ? (
                        <div class='row small-margin-bottom'>
                            <div class='medium-4 columns greyed text-left'>
                                <div style={{ display: 'inline-block' }}>CVC check</div>
                                <div
                                    class='info'
                                    data-tip='CVC check performed for this transaction specifically.'
                                    style={{ display: 'inline-block' }}
                                >
                                    i
                                </div>
                            </div>
                            <div class='medium-8 columns text-right'>
                                <Check check={transaction.cvc_check} />
                            </div>
                        </div>
                    ) : null}
                    {this.getLine('Currency', <span class='bold'>{transaction.currency}</span>)}
                    {this.getLine('Payment type', transaction.payment_type)}
                    {this.getLine('Merchant initiator type', transaction.merchant_initiator_type)}
                    {this.getLine('Eci', transaction.eci)}
                    {this.getLine('Mcc', transaction.mcc)}
                    {this.getLine('Merchant account', transaction.merchant_account)}
                    {this.getLine('Acquirer', transaction.acquirer_name)}
                    {this.getLine('Is on us', transaction.is_on_us)}
                    {sub}
                </div>
            </div>
        );
    }
}
