import moment from 'moment';

function parseDates(start, to) {
    start = start ? start : Date.now() / 1000;
    to = to ? to : Date.now() / 1000;
    var startDate = moment.unix(start);
    var endDate = moment.unix(to);
    var timespan = 'daily';

    if (endDate.diff(startDate, 'hours') < 72) {
        timespan = 'hourly';
    }

    if (endDate.diff(startDate, 'days') > 31) {
        timespan = 'weekly';
    }

    if (endDate.diff(startDate, 'days') > 90) {
        timespan = 'monthly';
    }

    if (startDate.isSame(endDate)) {
        startDate.set('hour', 0);
        endDate.set('hour', 23);
        start = startDate.unix();
        to = endDate.unix();
    }

    return {
        from: start,
        to: to,
        timespan: timespan,
    };
}
