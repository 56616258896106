import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

@connect(
    function(store) {
        return {};
    },
    null,
    null,
    { withRef: true },
)
export default class ModalFooter extends React.Component {
    static propTypes = {
        submitTitle: PropTypes.string.isRequired,
        submitCallback: PropTypes.func.isRequired,
        deleteCallback: PropTypes.func,
        formId: PropTypes.string,
    };

    constructor() {
        super();
        this.state = {
            locked: false,
        };
    }

    lock() {
        this.setState({ locked: true });
    }

    unlock() {
        this.setState({ locked: false });
    }

    render() {
        var deleteButton;
        if (this.props.deleteCallback) {
            deleteButton = (
                <a
                    onClick={this.props.deleteCallback}
                    class='round border small button'
                    style={{ minWidth: '100px', opacity: 0.5 }}
                >
                    Delete
                </a>
            );
        }

        var submitButton = (
            <input
                type='button'
                onClick={this.props.submitCallback}
                class='round small main button white-text'
                disabled={this.state.locked}
                value={this.props.submitTitle}
                style={{ minWidth: '100px', height: '40px' }}
            />
        );
        if (this.props.formId)
            submitButton = (
                <input
                    type='submit'
                    form={this.props.formId}
                    class='round small main button white-text'
                    disabled={this.state.locked}
                    value={this.props.submitTitle}
                    style={{ minWidth: '100px', height: '40px' }}
                />
            );
        if (this.state.locked) {
            submitButton = (
                <a
                    class='round small main button white-text'
                    style={{ minWidth: '100px', height: '40px' }}
                >
                    <div class='loading-spinner' />
                </a>
            );
        }

        return (
            <div class='row'>
                <div class='medium-4 columns'>{deleteButton}</div>
                <div class='medium-8 columns text-right'>
                    <input
                        type='button'
                        onClick={function(event) {
                            event.preventDefault();
                            this.props.dispatch({ type: 'CLOSE_MODAL' });
                        }.bind(this)}
                        class='round border small button'
                        disabled={this.state.locked}
                        value='Cancel'
                        style={{ minWidth: '100px', height: '40px' }}
                    />
                    {submitButton}
                </div>
            </div>
        );
    }
}
