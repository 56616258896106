import React from 'react';
import NotificationCenter from '../../features/NotificationBar/NotificationCenter';
import { connect } from 'react-redux';
import ShortId from 'shortid';

@connect(function(store) {
    return {
        modal: store.modal,
    };
})
export default class Modal extends React.Component {
    constructor() {
        super();
        this.state = {
            hide: true,
            id: ShortId.generate(), // we generate a unique id for this modal as multiple instances can be run simultaneously
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ hide: !nextProps.modal.open });
        this.updateModalId();
    }

    componentDidUpdate(prevProps, prevState) {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleOutsideClick = event => {
        if (
            this._modal &&
            !this._modal.contains(event.target) &&
            !this.state.hide &&
            !event.target.className.includes('Select')
        ) {
            this.props.dispatch({ type: 'CLOSE_MODAL' });
        }
    };

    /*
     * updateModalId is called when the content of the modal changes (i.e. another modal has been opened)
     */
    updateModalId() {
        this.setState({ id: ShortId.generate() });
    }

    closeModal(event) {
        if (event.target.className.includes('modal-closing')) {
            this.props.dispatch({ type: 'CLOSE_MODAL' });
        }
    }

    render() {
        const { modal } = this.props;

        return (
            <div key={this.state.id} class="modal-window">
                <div class="row collapse">
                    <div
                        class={`medium-${modal.size
                            ? modal.size
                            : modal.large ? 9 : 4} medium-centered columns window`}
                        ref={el => {
                            this._modal = el;
                        }}
                    >
                        <div class="row collapse window-header">{modal.header}</div>
                        <div class="row collapse window-content">
                            <div class="large-12 columns">
                                <div class="row">
                                    <div class="large-12 columns">
                                        <NotificationCenter source="modal" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="large-12 columns">{modal.content}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row collapse window-footer">{modal.footer}</div>
                    </div>
                </div>
            </div>
        );
    }
}
