// @flow

import type { $Board, $FetchParams } from '../consts';
import { DELETE_CHART, REQUEST_CHARTS_FETCH } from '../consts';
import type { $Action } from '../../../../util/Types';
import * as ProcessOut from '../../../../util/ProcessOut';
import { parseDates } from '../../Charts/Utils';
import { REQUEST_BOARD_PREPARATION } from '../Board/consts';
import Analytics from '../../../../util/Analytics';

export type $FetchChartsRequest = {
    payload: {
        chartsIds: Array<string>,
    },
} & $Action;

export function requestChartsFetch(
    board: $Board,
    chartsIds: Array<string>,
    params: $FetchParams,
): $FetchChartsRequest {
    return {
        type: REQUEST_CHARTS_FETCH,
        payload: { board: board, chartsIds: chartsIds, params: params },
    };
}

export function fetchChart(
    boardId: string,
    chartId: string,
    params: $FetchParams,
    silent?: ?boolean,
): $Action {
    const { from, to, timespan, timezone } = parseDates(params.interval.from, params.interval.to);
    const timeComparison = params.timeCompare.comparing
        ? `&compare_start_at=${params.timeCompare.from}&compare_end_at=${params.timeCompare.to}`
        : '';
    return {
        type: `FETCH_CHART_${chartId}${silent ? '_SILENT' : ''}`,
        payload: ProcessOut.APIcallPromise(
            `/boards/${boardId}/charts/${chartId}?interval=${timespan}&timezone=${encodeURIComponent(
                timezone,
            )}&start_at=${from}&end_at=${to}&filter=${encodeURIComponent(
                params.filter,
            )}${timeComparison}`,
            'GET',
        ),
    };
}

export function deleteChart(boardId: string, chartId: string) {
    return {
        type: DELETE_CHART,
        payload: ProcessOut.APIcallPromise(
            `/boards/${boardId}/charts/${chartId}`,
            'DELETE',
        ).then(response => {
            // Track the event
            Analytics('DELETED_CHART', { id: chartId });
            return response;
        }),
    };
}
