import * as ProcessOut from '../util/ProcessOut';

export function loadCards(filter, length, after, id) {
    filter = filter ? filter : '';
    var bound = after ? 'start_after' : 'end_before';
    if (!id) {
        id = '';
    }

    return {
        type: 'FETCH_VAULT_CARDS',
        payload: ProcessOut.APIcallPromise(
            `/cards?expand=token.customer&order=desc&${bound}=${id}&limit=${length}&filter=${filter}`,
            'GET',
        ),
    };
}
