// @flow

import React from 'react';

type Props = {
    leftPanel?: ?any,
    rightPanel?: ?any,
    children?: any,
};

class Partial extends React.Component<Props> {
    render() {
        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <div class='partial'>
                        {this.props.leftPanel || this.props.rightPanel ? (
                            <div class='row'>
                                <div class='large-6 columns' style={{ paddingRight: 0 }}>
                                    <div class='panel left'>{this.props.leftPanel}</div>
                                </div>
                                <div class='large-6 columns' style={{ paddingLeft: 0 }}>
                                    <div class='panel right'>{this.props.rightPanel}</div>
                                </div>
                            </div>
                        ) : null}
                        <div class='row'>
                            <div class='large-12 columns'>{this.props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Partial;
