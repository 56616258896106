// @flow

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REQUEST_HTTP_CALL = 'REQUEST_HTTP_CALL';
export const UPDATE_TOKENS = 'UPDATE_TOKENS';

export const LOCK_NETWORK = 'LOCK_NETWORK';
export const UNLOCK_NETWORK = 'UNLOCK_NETWORK';

export const NETWORK_VALIDATION_ERROR = 'NETWORK_VALIDATION_ERROR';
export const NETWORK_REQUEST_FAILURE = 'NETWORK_REQUEST_FAILURE';
export const NETWORK_BAD_REQUEST = 'NETWORK_BAD_REQUEST';
