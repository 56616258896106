// @flow

import type { $State } from '../../util/Types';
import * as ProcessOut from '../../util/ProcessOut';
import { FETCH_WEBHOOKS } from './actions';

export type $Webhook = {
    id: string,
    url: string,
    created_at: string,
};

export type $WebhooksState = {
    webhook_endpoints: Array<$Webhook>,
} & $State;

const defaultState: $WebhooksState = {
    fetched: false,
    fetching: false,
    error: null,
    webhook_endpoints: [],
};

export default function(state: $WebhooksState = defaultState, action: any): $WebhooksState {
    switch (action.type) {
        case FETCH_WEBHOOKS: {
            return {
                ...state,
                fetched: false,
                fetching: true,
                error: null,
                webhook_endpoints: [],
            };
        }

        case ProcessOut.typeFulfilled(FETCH_WEBHOOKS): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                ...action.payload,
            };
        }

        case ProcessOut.typeFailed(FETCH_WEBHOOKS): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: action.payload,
            };
        }
    }
    return state;
}
