// @flow
import type { Action } from '../../Types/Action';
import { typeFailed, typeFulfilled, typePending } from '../../util/ProcessOut';
import {
    PREPARE_TELESCOPE,
    PREPARE_TELESCOPE_SILENT,
    UPDATE_TELESCOPE_TAB,
    UPDATE_TELESCOPE_TIMESPAN,
} from './consts';

type $ChartData = ?Array<{
    key: string,
    datapoints: Array<{
        key: string,
        corresponding_filter: string,
        value: ?number,
    }>,
}>;

export type $FeesRecommendation = {
    advice: string,
    country_code: string,
    title: string,
    transactions_impacted: number,
    volume_impacted: number,
};

export type $TelescopeStateType = {
    error: boolean,
    timespan: string,
    authorizationReady: boolean,
    capturesReady: boolean,
    refundsReady: boolean,
    selectedTab: 'auth' | 'capt' | 'refu' | 'char',
    data: {
        // Authorization
        global_authorization_rate: ?number,
        authPerCountry: Array<{
            key: string,
            corresponding_filter: string,
            authorization: ?number,
        }>,
        authorization_rate_per_psp: $ChartData,
        authorization_rate_per_psp_bar: $ChartData,
        authorization_rate_per_psp_per_card_scheme: $ChartData,
        authorization_rate_per_psp_per_card_scheme_bar: $ChartData,
        authorization_rate_per_psp_per_card_type: $ChartData,
        authorization_rate_per_psp_per_card_type_bar: $ChartData,
        transactions_authorized: ?number,
        authorized_amount: ?number,
        failed_transactions: ?number,
        authorization_rate: ?number,
        // Capture
        global_fees_percent: ?number,
        captured_amount: ?number,
        transactions_captured: ?number,
        gateway_fees: ?number,
        gateway_fees_percent_per_country: Array<{
            key: string,
            corresponding_filter: string,
            fees: ?number,
        }>,
        gateway_fees_per_psp: $ChartData,
        gateway_fees_per_psp_bar: $ChartData,
        gateway_fees_per_psp_per_card_scheme: $ChartData,
        gateway_fees_per_psp_per_card_scheme_bar: $ChartData,
        gateway_fees_per_psp_per_card_type: $ChartData,
        gateway_fees_per_psp_per_card_type_bar: $ChartData,
        // Refunds
        global_refund_percent: ?number,
        refunded_amount: ?number,
        transactions_refunded: ?number,
        refunded_per_country: Array<{
            key: string,
            corresponding_filter: string,
            refunds_volume: ?number,
        }>,
        voided_amount: ?number,
        transactions_voided: ?number,
        refund_amount_per_psp_bar: $ChartData,
        refund_amount_per_psp: $ChartData,
        // Chargebacks
        global_chargeback_percent: ?number,
        chargedback_amount: ?number,
        chargedback_transactions: ?number,
        telescope: ?{
            authorizations_status: {
                volume_impacted: ?number,
            },
            fees_status: {
                volume_impacted: ?number,
            },
        },
        fees_recommendations: Array<$FeesRecommendation>,
    },
};

const defaultState: $TelescopeStateType = {
    error: false,
    authorizationReady: false,
    capturesReady: false,
    refundsReady: false,
    timespan: 'weekly',
    selectedTab: 'auth',
    data: {
        global_authorization_rate: 0,
        authPerCountry: [],
        authorization_rate_per_psp: [],
        authorization_rate_per_psp_bar: [],
        authorization_rate_per_psp_per_card_scheme: [],
        authorization_rate_per_psp_per_card_scheme_bar: [],
        authorization_rate_per_psp_per_card_type_bar: [],
        authorization_rate_per_psp_per_card_type: [],
        transactions_authorized: 0,
        authorized_amount: 0,
        failed_transactions: 0,
        authorization_rate: 0,
        global_fees_percent: 0,
        captured_amount: 0,
        transactions_captured: 0,
        gateway_fees: 0,
        gateway_fees_percent_per_country: [],
        gateway_fees_per_psp: [],
        gateway_fees_per_psp_bar: [],
        gateway_fees_per_psp_per_card_scheme: [],
        gateway_fees_per_psp_per_card_scheme_bar: [],
        gateway_fees_per_psp_per_card_type_bar: [],
        gateway_fees_per_psp_per_card_type: [],
        global_refund_percent: 0,
        refunded_amount: 0,
        transactions_refunded: 0,
        refunded_per_country: [],
        refund_amount_per_psp: [],
        refund_amount_per_psp_bar: [],
        voided_amount: 0,
        transactions_voided: 0,
        global_chargeback_percent: 0,
        chargedback_amount: 0,
        chargedback_transactions: 0,
        telescope: {
            authorizations_status: {
                volume_impacted: 0,
            },
            fees_status: {
                volume_impacted: 0,
            },
        },
        fees_recommendations: [],
    },
};

export default function(
    state: $TelescopeStateType = defaultState,
    action: Action,
): $TelescopeStateType {
    switch (action.type) {
        case typePending(PREPARE_TELESCOPE): {
            const { tab } = action.payload;
            return {
                ...state,
                ...defaultState,
                selectedTab: state.selectedTab,
                [tab === 'auth'
                    ? 'authorizationReady'
                    : tab === 'capt'
                    ? 'capturesReady'
                    : 'refundsReady']: false,
                error: false,
            };
        }

        case typePending(PREPARE_TELESCOPE_SILENT): {
            const { tab } = action.payload;
            return {
                ...state,
                [tab === 'auth'
                    ? 'authorizationReady'
                    : tab === 'capt'
                    ? 'capturesReady'
                    : 'refundsReady']: false,
                error: false,
            };
        }

        case typeFulfilled(PREPARE_TELESCOPE): {
            const { tab } = action.payload;
            return {
                ...state,
                [tab === 'auth'
                    ? 'authorizationReady'
                    : tab === 'capt'
                    ? 'capturesReady'
                    : 'refundsReady']: true,
                data: {
                    ...state.data,
                    ...action.payload.data,
                },
            };
        }

        case typeFailed(PREPARE_TELESCOPE): {
            const { tab } = action.payload;
            return {
                ...state,
                [tab === 'auth'
                    ? 'authorizationReady'
                    : tab === 'capt'
                    ? 'capturesReady'
                    : 'refundsReady']: false,
                error: true,
            };
        }

        case UPDATE_TELESCOPE_TIMESPAN: {
            if (!action.payload) return state;
            return {
                ...state,
                authorizationReady: false,
                capturesReady: false,
                refundsReady: false,
                timespan: action.payload.timespan,
            };
        }

        case UPDATE_TELESCOPE_TAB: {
            if (!action.payload) return state;
            return {
                ...state,
                selectedTab: action.payload.tab,
            };
        }
    }
    return state;
}
