// @flow
import React from 'react';
//$FlowFixMe Flow does not support the import of a scss module
import './surface.scss';
import type { $SurfacesState } from './reducer';
import { connect } from 'react-redux';
import Loading from '../../../components/Loader';
import Error from '../../../components/Error';
import { REQUEST_SURFACES_FETCH } from './consts';
import type { $Dispatcher } from '../../../util/Types';
import Surface from './Surface';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import type { $TelescopeState } from '../../../stores/Reducers/TelescopeReducer/Index';
import PaymentsOK from './PaymentsOK';

type Props = {
    surfaces: $SurfacesState,
    hoveredSurface: ?string,
    telescope: $TelescopeState,
    onClose: () => any,
    onOpen: string => any,
} & $Dispatcher;

type State = {
    openedSurface: string,
    hoveredSurface: ?string,
    expandRecuparable: boolean,
    expandMedium: boolean,
    expandHard: boolean,
};

class Surfaces extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            openedSurface: '',
            hoveredSurface: null,
            expandRecuparable: false,
            expandMedium: false,
            expandHard: false,
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.telescope.standard.timespan !== nextProps.telescope.standard.timespan)
            this.fetch(nextProps);
    }

    fetch = (props: Props) => {
        this.props.dispatch({
            type: REQUEST_SURFACES_FETCH,
            payload: { timespan: props.telescope.standard.timespan },
        });
    };

    openedSurface = (id: string) => {
        this.setState({ openedSurface: id });
        this.props.onOpen(id);
    };

    closedSurface = () => {
        this.setState({ openedSurface: '' });
        this.props.onClose();
    };

    render() {
        const { surfaces } = this.props;
        if (surfaces.error) {
            return <Error />;
        } else if (surfaces.fetching || !surfaces.fetched) {
            return <Loading />;
        }

        if (!surfaces.validated) {
            return <PaymentsOK />;
        }

        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <div
                        class='row '
                        style={{
                            marginBottom: '1em',
                            boxShadow:
                                '0 5px 7px rgba(50, 50, 93, 0.04), 0 1px 3px rgba(0, 0, 0, 0.03)',
                        }}
                    >
                        <div class='large-12 columns no-padding' style={{ background: '#f9fafb' }}>
                            <ReactCSSTransitionGroup
                                transitionEnterTimeout={350}
                                transitionLeaveTimeout={200}
                                transitionName={'surface-index-animation'}
                            >
                                {surfaces.surfaces
                                    .filter(surface => surface.tag === 'recuperable')
                                    .filter((_, index) => this.state.expandRecuparable || index < 3)
                                    .map(surface => (
                                        <Surface
                                            key={surface.id}
                                            opened={this.state.openedSurface === surface.id}
                                            onClose={this.closedSurface}
                                            onOpen={this.openedSurface}
                                            hoveredSurface={this.props.hoveredSurface}
                                            surface={surface}
                                            fadeOut={
                                                this.props.hoveredSurface &&
                                                surface.id !== this.props.hoveredSurface
                                            }
                                        />
                                    ))}
                            </ReactCSSTransitionGroup>
                        </div>
                    </div>
                    {surfaces.surfaces.filter(surface => surface.tag === 'recuperable').length >
                    3 ? (
                        <Expand
                            onExpand={() => {
                                this.setState({ expandRecuparable: true });
                            }}
                            onHide={() => {
                                this.setState({ expandRecuparable: false });
                            }}
                            expanded={this.state.expandRecuparable}
                            count={
                                surfaces.surfaces.filter(s => s.tag === 'recuperable').length - 3
                            }
                        />
                    ) : null}
                    <div
                        class='row '
                        style={{
                            marginBottom: '1em',
                            boxShadow:
                                '0 5px 7px rgba(50, 50, 93, 0.04), 0 1px 3px rgba(0, 0, 0, 0.03)',
                        }}
                    >
                        <div class='large-12 columns no-padding'>
                            <ReactCSSTransitionGroup
                                transitionEnterTimeout={350}
                                transitionLeaveTimeout={200}
                                transitionName={'surface-index-animation'}
                            >
                                {surfaces.surfaces
                                    .filter(surface => surface.tag === 'medium')
                                    .filter((_, index) => this.state.expandMedium || index < 3)
                                    .map(surface => (
                                        <Surface
                                            hoveredSurface={this.props.hoveredSurface}
                                            key={surface.id}
                                            surface={surface}
                                            opened={this.state.openedSurface === surface.id}
                                            onOpen={this.openedSurface}
                                            fadeOut={
                                                this.props.hoveredSurface &&
                                                surface.id !== this.props.hoveredSurface
                                            }
                                            onClose={this.closedSurface}
                                        />
                                    ))}
                            </ReactCSSTransitionGroup>
                        </div>
                    </div>
                    {surfaces.surfaces.filter(surface => surface.tag === 'medium').length > 3 ? (
                        <Expand
                            onExpand={() => {
                                this.setState({ expandMedium: true });
                            }}
                            onHide={() => {
                                this.setState({ expandMedium: false });
                            }}
                            expanded={this.state.expandMedium}
                            count={surfaces.surfaces.filter(s => s.tag === 'medium').length - 3}
                        />
                    ) : null}
                    <div
                        class='row small-margin-bottom'
                        style={{
                            boxShadow:
                                '0 5px 7px rgba(50, 50, 93, 0.04), 0 1px 3px rgba(0, 0, 0, 0.03)',
                        }}
                    >
                        <div class='large-12 columns no-padding'>
                            <ReactCSSTransitionGroup
                                transitionEnterTimeout={350}
                                transitionLeaveTimeout={200}
                                transitionName={'surface-index-animation'}
                            >
                                {surfaces.surfaces
                                    .filter(surface => surface.tag === 'hard')
                                    .filter((_, index) => this.state.expandHard || index < 3)
                                    .map(surface => (
                                        <Surface
                                            hoveredSurface={this.props.hoveredSurface}
                                            key={surface.id}
                                            surface={surface}
                                            fadeOut={
                                                this.props.hoveredSurface &&
                                                surface.id !== this.props.hoveredSurface
                                            }
                                            onClose={this.closedSurface}
                                        />
                                    ))}
                            </ReactCSSTransitionGroup>
                        </div>
                    </div>
                    {surfaces.surfaces.filter(surface => surface.tag === 'hard').length > 3 ? (
                        <Expand
                            onExpand={() => {
                                this.setState({ expandHard: true });
                            }}
                            onHide={() => {
                                this.setState({ expandHard: false });
                            }}
                            expanded={this.state.expandHard}
                            count={surfaces.surfaces.filter(s => s.tag === 'hard').length - 3}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return {
        surfaces: store.surfaces,
        telescope: store.telescope,
    };
})(Surfaces);

class Expand extends React.Component<{
    onExpand: () => any,
    onHide: () => any,
    expanded: boolean,
    count: number,
}> {
    render() {
        return (
            <div class='row' style={{ marginBottom: '1em' }}>
                <div class='large-12 columns text-center'>
                    {!this.props.expanded ? (
                        <a onClick={this.props.onExpand}>Show {this.props.count} more</a>
                    ) : (
                        <a onClick={this.props.onHide}>
                            <img
                                style={{ width: '1em', marginRight: '.5em' }}
                                src={'//dashboard.processout.com/images/collapse.png'}
                            />{' '}
                            Hide
                        </a>
                    )}
                </div>
            </div>
        );
    }
}
