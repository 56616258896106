// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../../../../util/Types';

type Props = {
    onFilter: ?() => void,
    onList: ?() => void,
    chartId: ?string,
    canUpdateAnalyticsFilter?: boolean,
    correspondingChartId: string, // chat that renders this popup
} & $Dispatcher;

class ChartPopup extends React.Component {
    props: Props;

    render() {
        const { onFilter, onList, chartId, correspondingChartId } = this.props;
        if (chartId !== correspondingChartId) return null;
        return (
            <div
                class="row"
                style={{
                    width: '100%',
                    height: '200px',
                    position: 'relative',
                    top: '-200px',
                    paddingTop: '50px',
                }}
            >
                {this.props.canUpdateAnalyticsFilter ? (
                    <div>
                        {' '}
                        <div class="large-6 columns text-right">
                            <a class="round main button popup-button small-font" onClick={onFilter}>
                                Update analytics filter
                            </a>
                        </div>
                        <div class="large-6 columns text-left">
                            <a class="round main button popup-button small-font" onClick={onList}>
                                List transactions
                            </a>
                        </div>
                    </div>
                ) : (
                    <div class="large-12 columns text-center">
                        <a class="round main button popup-button small-font" onClick={onList}>
                            List corresponding transactions
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(store => {
    return { ...store.analytics_v2.popup };
})(ChartPopup);
