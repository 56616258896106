// @flow

export const TRANSACTION_FIELDS = [
    'error_code',
    'payment_type',
    'name',
    'amount',
    'available_amount',
    'refunded_amount',
    'captured_amount',
    'authorized_amount',
    'currency',
    'status',
    'buyer_name',
    'buyer_email',
    'buyer_phone',
    'customer_email',
    'country',
    'attempts_count',
    'subscription_cycle',
    'card_scheme',
    'card_co_scheme',
    'card_type',
    'card_category',
    'card_bank',
    'card_last4',
    'cvc_check',
    'continent',
    'acquirer_name',
    'gateway_name',
    'gateway_fee_currency',
    'gateway_fee',
    'processing_fee',
    'exchange_fee',
    'refund_fee',
    'interchange_fee',
    'scheme_fee',
    'markup_fee',
    'interchange_fee_estimate',
    'scheme_fee_estimate',
    'created_at',
].sort((a, b) => {
    if (a < b) return -1;
    return 1;
});

export const CARDINALITY_FIELDS = [
    'gateway_configuration_id',
    'customer_id',
    'subscription_id',
    'plan_id',
    'card_id',
    'card_scheme',
    'card_co_scheme',
    'card_type',
    'card_category',
    'card_bank',
    'error_code',
    'payment_type',
    'country',
].sort((a, b) => {
    if (a < b) return -1;
    return 1;
});
