// @flow

import React from 'react';
import type { $GatewayConfiguration } from '../PaymentProviders/consts';
import Select from 'react-select-v2';
import Creatable from 'react-select-v2/lib/Creatable';

type Props = {
    gateways: Array<string>,
    onChange: (gateways: Array<string>) => void,
    configurations: Array<$GatewayConfiguration>,
};

class SmartRoutingSelector extends React.Component<Props> {
    render() {
        const { gateways, onChange, configurations } = this.props;

        const options = [{ label: 'Enabled gateway configurations', value: 'all' }].concat(
            configurations.filter(c => c.enabled).map(c => ({ label: c.name, value: c.id })),
        );
        const values = gateways.map(g => {
            const correspondingGateway = options.find(c => c.value === g);
            return {
                value: g,
                label: correspondingGateway ? correspondingGateway.label : g,
            };
        });
        return (
            <div className='labeled-field' style={{ width: '75%' }}>
                <Select
                    className={'react-select'}
                    options={options}
                    onChange={newValues => {
                        if (newValues instanceof Array) {
                            if (newValues.findIndex(v => v.value === 'all') > -1)
                                this.props.onChange(['all']);
                            else if (newValues.length > 0)
                                this.props.onChange(newValues.map(v => v.value));
                            else this.props.onChange(['all']);
                        } else if (newValues) {
                            this.props.onChange([newValues.value]);
                        } else {
                            this.props.onChange(['all']);
                        }
                    }}
                    isClearable={false}
                    value={values}
                    closeMenuOnSelect={false}
                    isMulti={values.findIndex(v => v.value === 'all') === -1}
                />
            </div>
        );
    }
}

export default SmartRoutingSelector;
