// @flow

import React from 'react';
import Creatable from 'react-select-v2/lib/Creatable';
import type { $RoutingRulesSettings } from './consts';
import { connect } from 'react-redux';

type Props = {
    tags: Array<string>,
    onChange: (Array<string>) => void,
    rulesSettings: $RoutingRulesSettings,
};

class TagsListing extends React.Component<Props> {
    onChange = (newValue: Array<{ value: string, label: string }>) => {
        this.props.onChange(newValue.map(value => value.value));
    };

    render() {
        const { tags, onChange, rulesSettings } = this.props;

        return (
            <div class='row tags-listing'>
                <div class='medium-12 columns'>
                    <Creatable
                        options={[]}
                        value={tags.map(tag => ({ label: tag, value: tag }))}
                        onChange={this.onChange}
                        placeholder={'Tags'}
                        closeMenuOnSelect={false}
                        isMulti={true}
                        promptTextCreator={label => label}
                        noOptionsMessage={() =>
                            'Add tags to easily understand what a rule is doing. Start by creating a new one.'
                        }
                    />
                </div>
            </div>
        );
    }
}

export default connect(store => ({ rulesSettings: store.routingRulesSettings }))(TagsListing);
