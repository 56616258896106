// @flow

import type { $Action, $State } from '../../util/Types';
import { REQUEST_PAYOUTS_FETCH } from './actions';
import * as ProcessOut from '../../util/ProcessOut';

export type $Payout = {
    id: string,
    gateway_configuration_id: string,
    created_at: string,
    currency: string,
    status: string, // TODO: set correct status type
    project_id: string,
    fees: string,
    metadata: any,
    amount: string,
    failure_reason: ?string,
    failure_reason_code: ?number,
    refunds_transactions: number,
    refunds_volume: string,
    refunds_fees: number,
    sales_transactions: number,
    sales_volume: string,
    sales_fees: number,
    bank_name: ?string,
    bank_summary: ?string,
    chargebacks_transactions: number,
    chargebacks_volume: string,
    chargebacks_fees: number,
    adjustments: number,
    reserve: number,
};

export type $PayoutsState = {
    payouts: Array<$Payout>,
    has_more: boolean,
    limit: number,
    order: string,
    count: number,
} & $State;

const defaultState: $PayoutsState = {
    fetched: false,
    fetching: false,
    error: null,
    has_more: false,
    order: 'desc',
    count: 0,
    limit: 0,
    payouts: [],
};

export default function(state: $PayoutsState = defaultState, action: $Action): $PayoutsState {
    switch (action.type) {
        case REQUEST_PAYOUTS_FETCH: {
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: null,
            };
        }

        case ProcessOut.typeFulfilled(REQUEST_PAYOUTS_FETCH): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                ...action.payload,
            };
        }

        case ProcessOut.typeFailed(REQUEST_PAYOUTS_FETCH): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: action.payload,
            };
        }
    }

    return state;
}
