// @flow

import type { $BuilderMetric } from './reducer';
import uniqid from 'uniqid';
import type { $Filter } from '../../../RoutingRules/Filter/consts';

export const AUTHORIZED_TRANSACTIONS_METRIC: $BuilderMetric = {
    name: 'Authorized transactions',
    description: '',
    unit: 'count',
    metrics: [
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'authorized_amount',
                    operand: '>',
                    value: [0],
                },
            ],
            name: 'A',
            type: 'count',
            path: 'transactions',
        },
    ],
    generalFormula: 'A',
    group: 'Number of transactions',
};

export const TRANSACTION_VALUE_METRIC: $BuilderMetric = {
    name: 'Transaction value',
    description: '',
    unit: 'amount',
    metrics: [
        {
            id: uniqid(),
            filters: [],
            name: 'A',
            type: 'sum',
            path: 'transactions.amount',
        },
    ],
    generalFormula: 'A',
    group: 'Amounts',
};

export const NET_RESULT_METRIC: $BuilderMetric = {
    name: 'Net result',
    description: '',
    unit: 'amount',
    metrics: [
        {
            id: uniqid(),
            name: 'A',
            type: 'sum',
            path: 'transactions.available_amount',
            filters: [],
        },
    ],
    group: 'Amounts',
    generalFormula: 'A',
};

export const SUCCESSFUL_TRANSACTIONS_METRIC: $BuilderMetric = {
    name: 'Successful transactions',
    description: '',
    unit: 'count',
    metrics: [
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'captured_amount',
                    operand: '>',
                    value: [0],
                },
            ],
            name: 'A',
            type: 'count',
            path: 'transactions',
        },
    ],
    generalFormula: 'A',
    group: 'Number of transactions',
};

export const FAILED_TRANSACTIONS_METRIC: $BuilderMetric = {
    name: 'Failed transactions',
    description: '',
    unit: 'count',
    metrics: [
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'status',
                    operand: '==',
                    value: ['failed'],
                },
            ],
            name: 'A',
            type: 'count',
            path: 'transactions',
        },
    ],
    generalFormula: 'A',
    group: 'Number of transactions',
};

export const PENDING_TRANSACTIONS_METRIC: $BuilderMetric = {
    name: 'Pending transactions',
    description: '',
    unit: 'count',
    metrics: [
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'status',
                    operand: '==',
                    value: ['pending', 'waiting'],
                },
            ],
            name: 'A',
            type: 'count',
            path: 'transactions',
        },
    ],
    generalFormula: 'A',
    group: 'Number of transactions',
};

export const AUTHORIZED_AMOUNT_METRIC: $BuilderMetric = {
    name: 'Authorized amount',
    description: '',
    unit: 'amount',
    metrics: [
        {
            id: uniqid(),
            filters: [],
            name: 'A',
            type: 'sum',
            path: 'transactions.authorized_amount',
        },
    ],
    generalFormula: 'A',
    group: 'Amounts',
};

export const AUTHORIZATION_RATE_METRIC: $BuilderMetric = {
    name: 'Authorization rate',
    description: '',
    unit: 'percentage',
    metrics: [
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'status',
                    operand: '!=',
                    value: ['pending', 'failed', 'waiting'],
                },

                {
                    id: uniqid(),
                    path: 'three_d_s_status',
                    operand: '!=',
                    value: ['failed'],
                },
            ],
            name: 'A',
            type: 'count',
            path: 'transactions',
        },
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'status',
                    operand: '!=',
                    value: ['pending', 'waiting'],
                },

                {
                    id: uniqid(),
                    path: 'three_d_s_status',
                    operand: '!=',
                    value: ['failed'],
                },
            ],
            name: 'B',
            type: 'count',
            path: 'transactions',
        },
    ],
    generalFormula: 'A / B',
    group: 'Payment performance',
};

export const AVERAGE_BASKET_METRIC: $BuilderMetric = {
    name: 'Average basket',
    description: '',
    unit: 'amount',
    metrics: [
        {
            id: uniqid(),
            filters: [],
            name: 'A',
            type: 'average',
            path: 'transactions.amount',
        },
    ],
    generalFormula: 'A',
    group: 'Amounts',
};

export const CAPTURED_AMOUNT_METRIC: $BuilderMetric = {
    name: 'Captured amount',
    description: '',
    unit: 'amount',
    metrics: [
        {
            id: uniqid(),
            filters: [],
            name: 'A',
            type: 'sum',
            path: 'transactions.captured_amount',
        },
    ],
    generalFormula: 'A',
    group: 'Amounts',
};

export const REFUNDED_AMOUNT_METRIC: $BuilderMetric = {
    name: 'Refunded amount',
    description: '',
    unit: 'amount',
    metrics: [
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'status',
                    operand: '==',
                    value: ['refunded', 'partially-refunded'],
                },
            ],
            name: 'A',
            type: 'sum',
            path: 'transactions.amount',
        },
    ],
    generalFormula: 'A',
    group: 'Amounts',
};

export const REFUND_RATE_METRIC: $BuilderMetric = {
    name: 'Refund rate',
    description: '',
    unit: 'percentage',
    metrics: [
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'status',
                    operand: '==',
                    value: ['refunded', 'partially-refunded'],
                },
            ],
            name: 'A',
            type: 'count',
            path: 'transactions',
        },
        {
            id: uniqid(),
            filters: [],
            name: 'B',
            type: 'count',
            path: 'transactions',
        },
    ],
    generalFormula: 'A / B',
    group: 'Payment performance',
};

export const CHARGEBACK_AMOUNT_METRIC: $BuilderMetric = {
    name: 'Chargeback amount',
    description: '',
    unit: 'amount',
    metrics: [
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'status',
                    operand: '==',
                    value: ['reversed', 'solved', 'chargeback-initiated'],
                },
            ],
            name: 'A',
            type: 'sum',
            path: 'transactions.amount',
        },
    ],
    generalFormula: 'A',
    group: 'Amounts',
};

export const TRANSACTIONS_COUNT_METRIC: $BuilderMetric = {
    name: 'Number of transactions',
    description: '',
    unit: 'count',
    metrics: [
        {
            id: uniqid(),
            filters: [],
            name: 'A',
            type: 'count',
            path: 'transactions',
        },
    ],
    generalFormula: 'A',
    group: 'Number of transactions',
};

export const FEES_AMOUNT_METRIC: $BuilderMetric = {
    name: 'Paid in fees',
    description: '',
    unit: 'amount',
    metrics: [
        {
            id: uniqid(),
            filters: [],
            name: 'A',
            type: 'sum',
            path: 'transactions.gateway_fee',
        },
    ],
    generalFormula: 'A',
    group: 'Fees performance',
};

export const FEES_RATE_METRIC: $BuilderMetric = {
    name: 'Fees rate',
    description: '',
    unit: 'percentage',
    metrics: [
        {
            id: uniqid(),
            filters: [],
            name: 'A',
            type: 'sum',
            path: 'transactions.gateway_fee',
        },
        {
            id: uniqid(),
            filters: [],
            name: 'B',
            type: 'sum',
            path: 'transactions.captured_amount',
        },
    ],
    generalFormula: 'A / B',
    group: 'Fees performance',
};

export const CHARGEBACK_RATE_METRIC: $BuilderMetric = {
    name: 'Chargeback rate',
    description: '',
    unit: 'percentage',
    metrics: [
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'status',
                    operand: '==',
                    value: ['chargeback-initiated', 'reversed', 'solved'],
                },
            ],
            name: 'A',
            type: 'count',
            path: 'transactions',
        },
        {
            id: uniqid(),
            filters: [],
            name: 'B',
            type: 'count',
            path: 'transactions',
        },
    ],
    generalFormula: 'A / B',
    group: 'Payment performance',
};

export const DECLINE_RATE_METRIC: $BuilderMetric = {
    name: 'Decline rate',
    description: '',
    unit: 'percentage',
    metrics: [
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'status',
                    operand: '==',
                    value: ['failed'],
                },
                {
                    id: uniqid(),
                    path: 'status',
                    operand: '!=',
                    value: ['pending', 'waiting'],
                },
            ],
            name: 'A',
            type: 'count',
            path: 'transactions',
        },
        {
            id: uniqid(),
            filters: [
                {
                    id: uniqid(),
                    path: 'status',
                    operand: '!=',
                    value: ['pending', 'waiting'],
                },
            ],
            name: 'B',
            type: 'count',
            path: 'transactions',
        },
    ],
    generalFormula: 'A / B',
    group: 'Payment performance',
};

export const BUILDER_METRICS: Array<$BuilderMetric> = [
    AUTHORIZED_TRANSACTIONS_METRIC,
    TRANSACTION_VALUE_METRIC,
    AUTHORIZED_AMOUNT_METRIC,
    NET_RESULT_METRIC,
    AUTHORIZATION_RATE_METRIC,
    SUCCESSFUL_TRANSACTIONS_METRIC,
    FAILED_TRANSACTIONS_METRIC,
    PENDING_TRANSACTIONS_METRIC,
    AVERAGE_BASKET_METRIC,
    CAPTURED_AMOUNT_METRIC,
    REFUNDED_AMOUNT_METRIC,
    REFUND_RATE_METRIC,
    CHARGEBACK_AMOUNT_METRIC,
    TRANSACTIONS_COUNT_METRIC,
    FEES_AMOUNT_METRIC,
    FEES_RATE_METRIC,
    CHARGEBACK_RATE_METRIC,
    DECLINE_RATE_METRIC,
];
export type $MetricType = 'sum' | 'count' | 'average' | 'cardinality';
export type $Metric = {
    id: string,
    name: string,
    type: $MetricType,
    path: string,
    filters: Array<$Filter>,
};
export const UPDATE_METRICS = 'UPDATE_METRICS';

export const SelectStyle = {
    valueContainer: (provided: any) => ({ ...provided, paddingLeft: '.5em' }),
    container: (provided: any) => ({
        ...provided,
        position: 'relative',
        borderRadius: '0',
    }),
    control: (provided: any) => ({ ...provided, borderRadius: 0 }),
};
