// @flow

import React from 'react';
import type { $Report } from './reducer';
import moment from 'moment';
import { FormattedNumber } from 'react-intl';

type Props = {
    report: $Report,
};

class Report extends React.Component {
    props: Props;

    render() {
        const { report } = this.props;
        const progression =
            report.status === 'pending'
                ? 0
                : report.status === 'processing' && report.progress_percentage
                  ? report.progress_percentage / 100
                  : 0;
        return (
            <div class="row report-row">
                <div class="medium-3 columns">
                    <span>{moment(report.created_at).calendar()}</span>
                </div>
                <div class="medium-7 columns ">
                    <span>{report.description}</span>
                </div>

                <div class="medium-2 columns text-right">
                    {report.status === 'processing' || report.status === 'pending' ? (
                        <div class="progress-bar">
                            <div class="content" style={{ width: `${progression}%` }} />
                            <div class="progress-label">
                                {report.status === 'processing' ? (
                                    <FormattedNumber value={progression} style={'percent'} />
                                ) : (
                                    <span>pending</span>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div class="medium-3 columns text-right">
                            <span
                                class={`log-level ${report.status === 'processed'
                                    ? 'success'
                                    : 'failed'}`}
                            >
                                {report.status}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Report;
