import { combineReducers } from 'redux';
import paramsReducer from './ParamsReducer';

const analyticsReducer = combineReducers({
    params: paramsReducer,
});

export default function getReducer(isForTelescope) {
    return analyticsReducer;
}
