// @flow

import { takeLatest, put, select, call, takeEvery } from 'redux-saga/effects';
import {
    CREATE_NEW_PRIVATE_KEY,
    DELETE_PRIVATE_KEY,
    FETCH_NEW_PRIVATE_KEY,
    PREPARE_API_SETUP,
    SAVE_PRIVATE_KEY_UPDATE,
} from './consts';
import { typeFailed, typeFulfilled } from '../../util/ProcessOut';
import * as Actions from './actions';
import type { $ApiKeyModel, $ApiSetupState } from './consts';
import * as ProcessOut from '../../util/ProcessOut';
import * as Sentry from '@sentry/browser';
import type { $Action } from '../../util/Types';

export function* prepareApiSetup(): Generator<*, *, *> {
    try {
        // Retrieve the list of current api-keys
        const apiKeysResult = yield ProcessOut.APIcallPromise('/api-keys', 'GET');
        const apiKeys: Array<$ApiKeyModel> = apiKeysResult.data.api_keys;

        let project = yield select(store => store.currentProject);
        while (!project.fetched || project.fetching) {
            project = yield select(store => store.currentProject);
        }
        yield put({
            type: typeFulfilled(PREPARE_API_SETUP),
            payload: { apiKeys, publicKey: project.project.id },
        });
    } catch (error) {
        yield put({ type: typeFailed(PREPARE_API_SETUP), payload: { error } });
        Sentry.captureException(error);
    }
}

export function* newPrivateKey(action: $Action): Generator<*, *, *> {
    try {
        const { name } = action.payload;
        const result = yield ProcessOut.APIcallPromise('/api-keys', 'POST', { name });
        // Call completion callback
        action.payload.callback(result.data.api_key.private_key);
        // Reload the keys list
        yield put(Actions.requestApiSetupPreparation());
    } catch (e) {
        yield put({ type: typeFailed(CREATE_NEW_PRIVATE_KEY), payload: e });
        action.payload.onError();
        Sentry.captureException(e);
    }
}

export function* deletePrivateKey(action: $Action): Generator<*, *, *> {
    try {
        yield ProcessOut.APIcallPromise(`/api-keys/${action.payload.name}`, 'DELETE');
        // Call completion callback
        action.payload.callback();
        // Reload the keys list
        yield put(Actions.requestApiSetupPreparation());
    } catch (error) {
        yield put({ type: typeFailed(DELETE_PRIVATE_KEY), payload: error });
        Sentry.captureException(error);
    }
}

export function* updatePrivateKey(action: $Action): Generator<*, *, *> {
    try {
        const key: $ApiKeyModel = action.payload.privateKey;
        yield ProcessOut.APIcallPromise(`/api-keys/${key.name}`, 'PUT', key);
        // Call completion callback
        action.payload.callback();
        // Reload the keys list
        yield put(Actions.requestApiSetupPreparation());
    } catch (error) {
        yield put({ type: typeFailed(SAVE_PRIVATE_KEY_UPDATE), payload: error });
        Sentry.captureException(error);
    }
}

export default function* watchForApiSetupSagas(): Generator<*, *, *> {
    yield takeLatest(PREPARE_API_SETUP, prepareApiSetup);
    yield takeLatest(CREATE_NEW_PRIVATE_KEY, newPrivateKey);
    yield takeEvery(DELETE_PRIVATE_KEY, deletePrivateKey);
    yield takeLatest(SAVE_PRIVATE_KEY_UPDATE, updatePrivateKey);
}
