// @flow

import React from 'react';
import CurrencyField from '../../../components/CurrencyField';
import { BulletNumber } from './Adyen';

type Props = {
    hide: boolean,
    isForTelescope?: ?boolean,
    currency: string,
};

class AdyenThird extends React.Component<Props> {
    render() {
        const { hide } = this.props;
        if (hide) return null;
        return (
            <div class='row'>
                <div class='large-12 large-centered columns'>
                    <div class='row margin-bottom'>
                        <div class='large-12 columns'>
                            <h4>Complete your configuration</h4>
                        </div>
                    </div>
                    {!this.props.isForTelescope ? (
                        <div className='row small-margin-bottom'>
                            <div className='medium-1 columns'>
                                <BulletNumber value={8} />
                            </div>
                            <div className='medium-11 columns greyed'>
                                <div className='row margin-bottom'>
                                    <div className='large-12 columns' style={{ paddingLeft: 0 }}>
                                        <label className='bold greyed margin-bottom'>
                                            Generate Adyen user
                                        </label>
                                        <p>
                                            Click on the Settings menu then Users and click on Add a
                                            new user. Click on Webservice user, leave everything by
                                            default, make sure on roles and associated accounts that
                                            all accounts are checked. Enter the generated username
                                            and password here:
                                        </p>
                                    </div>
                                </div>
                                <div className='row margin-bottom'>
                                    <div className='large-12 columns greyed'>
                                        <input
                                            type='text'
                                            name='ws_username'
                                            placeholder='Account username'
                                        />
                                        <input
                                            type='password'
                                            name='ws_password'
                                            placeholder='Password'
                                        />
                                        <input
                                            type='text'
                                            name='merchant_account'
                                            placeholder='You merchant account (upper left hand corner of your Adyen dashboard)'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {this.props.isForTelescope ? null : (
                        <div className='row small-margin-bottom'>
                            <div className='medium-1 columns'>
                                <BulletNumber value={9} />
                            </div>
                            <div className='medium-11 columns greyed'>
                                <div className='medium-12 columns'>
                                    <div className='row margin-bottom'>
                                        <label className='bold greyed margin-bottom'>
                                            Set up your Adyen sub-domain
                                        </label>
                                        <p>
                                            In your Adyen dashboard, go under Account menu > API
                                            URLs. Under “Live endpoint url prefix”, copy the value
                                            and paste it in the input below. It is generally of the
                                            form <span class='bold'>abcd1234-CompanyName</span>
                                        </p>
                                    </div>
                                </div>
                                <div className='row margin-bottom'>
                                    <div className='medium-12 columns'>
                                        <input
                                            type='text'
                                            name='sub_domain'
                                            placeholder='Your live endpoint subdomain'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='row small-margin-bottom'>
                        <div className='medium-1 columns'>
                            <BulletNumber value={this.props.isForTelescope ? 8 : 10} />
                        </div>
                        <div className='medium-11 columns greyed'>
                            <div className='row'>
                                <div className='large-12 columns' style={{ paddingLeft: 0 }}>
                                    <label className='bold greyed margin-bottom'>
                                        Saving your configuration
                                    </label>
                                </div>
                            </div>
                            <div className='row margin-bottom'>
                                <div class='medium-12 columns'>
                                    <div class='row'>
                                        <div class='medium-12 columns'>
                                            <label class='greyed'>Configuration name</label>
                                            <input type='text' name='name' placeholder='Adyen' />
                                        </div>
                                    </div>
                                    <div class='row margin-bottom'>
                                        <div class='large-12 columns'>
                                            <CurrencyField
                                                name='default_currency'
                                                defaultValue={this.props.currency}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdyenThird;
