import * as ProcessOut from '../../util/ProcessOut';

export default function(
    state = {
        fetching: false,
        fetched: false,
    },
    action,
) {
    switch (action.type) {
        case 'FETCH_RECURRING_INVOICES_PENDING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
                subscriptions: null,
            };
        }
        case 'FETCH_RECURRING_INVOICES_FULFILLED': {
            return formatReducer(state, action);
        }
        case 'FETCH_RECURRING_INVOICES_NORELOAD_FULFILLED': {
            return formatReducer(state, action);
        }

        case ProcessOut.typeFailed('FETCH_RECURRING_INVOICES_NORELOAD'):
        case ProcessOut.typeFailed('FETCH_RECURRING_INVOICES'): {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: action.payload,
            };
        }
    }

    return state;
}

function formatReducer(state, action) {
    return {
        ...state,
        ...action.payload,
        fetching: false,
        fetched: true,
    };
}
