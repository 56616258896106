// @flow

import * as ProcessOut from '../../util/ProcessOut';
import axios from 'axios';
import { ProcessOutUrl } from '../../util/ProcessOut';
import { fetchStatus } from '../../util/ProcessOut';
import type { $ErrorContext } from '../ErrorContext/consts';
import type { $Action } from '../../util/Types';
import { LOCK_NETWORK, UNLOCK_NETWORK } from './consts';
import * as Sentry from '@sentry/browser';
import { POST_NOT_ALLOWED } from '../Permissions/consts';

export const HTTPCall = (
    url: string,
    req: any,
    disableStatusParsing: ?boolean,
    disableErrorDisplay: ?boolean,
) => {
    const context: $ErrorContext = {
        contextUrl: document.location.hash,
    };
    return axios(ProcessOutUrl + url, req)
        .then(function(response) {
            if (!disableStatusParsing) fetchStatus(response.status, response.data);
            if (req.method === 'POST' && response.status === 417) {
                // NOT PERMITTED to create a resource
                if (!disableErrorDisplay) ProcessOut.addNotification(POST_NOT_ALLOWED, 'error');
            }
            return response;
        })
        .catch(function(error) {
            if (error.response) {
                if (!disableStatusParsing) fetchStatus(error.response.status);
                if (!error.response.data.success && !disableStatusParsing) {
                    if (!disableErrorDisplay) {
                        if (error.response.status === 401)
                            ProcessOut.addNotification('Your session expired.');
                        else if (error.response.status === 500)
                            ProcessOut.addNotification(
                                'An error occurred, please try again in a few minutes.',
                                null,
                                context,
                            );
                        else if (error.response.status !== 417 || req.method !== 'GET') {
                            ProcessOut.addNotification(error.response.data.message, null);
                        }
                    }

                    if (error.response.status > 500) {
                        // the error is unknown
                        Sentry.captureException(error);
                    }
                }
                throw error.response;
            }
            if (
                error.code === 'ECONNABORTED' &&
                !new RegExp(/\/analytics\/telescope\/recommendations\/.*\/details.*/).test(url) &&
                !disableErrorDisplay
            ) {
                ProcessOut.addNotification('Request timed-out, please retry later.', null, context);
            } else if (!disableErrorDisplay) ProcessOut.addNotification('Could not reach the server, please check your network.', null, context);
            throw error;
        });
};

export function lockNetwork(): $Action {
    return {
        type: LOCK_NETWORK,
    };
}

export function unlockNetwork(): $Action {
    return {
        type: UNLOCK_NETWORK,
    };
}
