// @flow
import React from 'react';
import { BulletNumber } from './Adyen';
import AdyenOnboardingWarning from './AdyenOnboardingWarning';

type Props = {
    hide: boolean,
    togglePages: (newPage: 'first' | 'second') => void,
    onAllowOnboarding: () => void,
    allowOnboarding: boolean,
    isForTelescope: boolean,
};

type State = {
    username: string,
    password: string,
};
export default class First extends React.Component<Props, State> {
    constructor() {
        super();

        this.state = {
            username: '',
            password: '',
        };
    }

    fieldUpdated(field: string, event: any) {
        this.setState({ [field]: event.target.value });
    }

    nextClicked(event: Event) {
        event.preventDefault();

        if (this.state.username !== '' && this.state.password !== '') {
            this.props.togglePages('second');
        }
    }

    render() {
        let { hide } = this.props;

        let inputs;
        if (!hide) {
            inputs = (
                <div className='row'>
                    <div className='large-12 columns'>
                        <div className='row'>
                            <div className='medium-6 columns' style={{ paddingLeft: 0 }}>
                                <input
                                    onChange={this.fieldUpdated.bind(this, 'username')}
                                    type='text'
                                    defaultValue={this.state.username}
                                    name='rp_username'
                                    placeholder='Username'
                                    className='bottom-border processor-input'
                                    required
                                />
                            </div>
                            <div className='medium-6 columns' style={{ paddingRight: 0 }}>
                                <input
                                    onChange={this.fieldUpdated.bind(this, 'password')}
                                    type='password'
                                    defaultValue={this.state.password}
                                    name='rp_password'
                                    placeholder='Password'
                                    className='bottom-border processor-input'
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            inputs = (
                <div className='row'>
                    <div className='medium-6 columns'>
                        <input
                            onChange={this.fieldUpdated.bind(this, 'username')}
                            type='hidden'
                            value={this.state.username}
                            name='rp_username'
                            placeholder='Username'
                            className='bottom-border processor-input'
                            required
                        />
                    </div>
                    <div className='medium-6 columns'>
                        <input
                            onChange={this.fieldUpdated.bind(this, 'password')}
                            type='hidden'
                            value={this.state.password}
                            name='rp_password'
                            placeholder='Password'
                            className='bottom-border processor-input'
                            required
                        />
                    </div>
                </div>
            );
        }

        let content;
        if (!hide) {
            content =
                !this.props.allowOnboarding && this.props.isForTelescope ? (
                    <AdyenOnboardingWarning
                        onHide={() => {
                            this.props.onAllowOnboarding();
                        }}
                    />
                ) : (
                    <div className='medium-12 columns'>
                        <div class='row margin-bottom'>
                            <div class='large-12 columns'>
                                <h4>Adyen configuration setup</h4>
                            </div>
                        </div>
                        <div className='row margin-bottom'>
                            <div className='medium-1 columns'>
                                <BulletNumber value={1} />
                            </div>
                            <div className='medium-11 columns greyed'>
                                <label className='bold margin-bottom greyed'>
                                    Enable adyen reports
                                </label>
                                <p>
                                    Enabling reports allows ProcessOut to be informed about your
                                    payment fees. To set it up, go to your Adyen dashboard, and
                                    click on the Reports menu, then subscribe to Payment Accounting
                                    and choose CSV format.
                                </p>
                            </div>
                        </div>
                        <div className='row small-margin-bottom'>
                            <div className='medium-1 columns'>
                                <BulletNumber value={2} />
                            </div>
                            <div className='medium-11 columns greyed'>
                                <div className='row margin-bottom'>
                                    <div className='large-12 columns' style={{ paddingLeft: 0 }}>
                                        <label className='bold greyed margin-bottom'>
                                            Generate Adyen user
                                        </label>
                                        <p>
                                            Click on the Settings menu then Users and click on Add a
                                            new user. Click on Report user, leave everything by
                                            default, make sure on roles and associated accounts that
                                            all accounts are checked. Enter the generated username
                                            and password here:
                                        </p>
                                    </div>
                                </div>
                                <div className='row margin-bottom'>
                                    <div className='large-12 columns greyed'>{inputs}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
        } else {
            content = <div>{inputs}</div>;
        }

        return <div className='row'>{content}</div>;
    }
}
