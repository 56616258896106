// @flow
import React from 'react';
import Analytics from '../../../util/Analytics';
import { connect } from 'react-redux';

class MonitoringPlusPopup extends React.Component<{
    close: () => void,
    user: {
        details: {
            email: string,
        },
    },
}> {
    componentDidMount() {
        Analytics('OPENED_ADVANCED_MONITORING_POPUP', {
            email: this.props.user.details.email,
        });
    }

    callToActionCliked = () => {
        this.props.close();
        Analytics('REQUEST_ADVANCED_MONITORING_ACCESS', {
            email: this.props.user.details.email,
        });
        window.Intercom('showNewMessage', "I'm interested in the advanced monitoring feature.");
    };
    render() {
        return (
            <div class='monitoring-plus-popup'>
                <div class='monitoring-plus-popup-background' onClick={this.props.close} />
                <div class='monitoring-plus-popup-content'>
                    <div class='row'>
                        <div class='large-5 columns text-right'>
                            <div class='row margin-bottom'>
                                <div class='large-12 columns'>
                                    <img
                                        src='//dashboard.processout.com/images/monitoring-plus-1.png'
                                        class='screenshot'
                                    />
                                </div>
                            </div>
                            <div class='row'>
                                <div class='large-12 columns'>
                                    <img
                                        src='//dashboard.processout.com/images/monitoring-plus-2.png'
                                        class='screenshot'
                                    />
                                </div>
                            </div>
                        </div>
                        <div class='large-6 large-offset-1 columns'>
                            <div class='row margin-bottom'>
                                <div class='large-12 columns'>
                                    <p class='title'>Get started with advanced monitoring</p>
                                </div>
                            </div>
                            <Bullet
                                logoUrl='//dashboard.processout.com/images/loop.png'
                                content='Dive into your payments data'
                            />
                            <Bullet
                                logoUrl='//dashboard.processout.com/images/custom-chart.png'
                                content='Create customizable boards & charts'
                            />
                            <Bullet
                                logoUrl='//dashboard.processout.com/images/compare-chart.png'
                                content='Easily cross check your data'
                            />
                            <div class='row'>
                                <div class='large-12 columns text-center'>
                                    <a
                                        class='round button border'
                                        onClick={this.callToActionCliked}
                                    >
                                        Request advanced monitoring access
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { user: store.user };
})(MonitoringPlusPopup);

class Bullet extends React.PureComponent<{
    logoUrl: string,
    content: string,
}> {
    render() {
        return (
            <div class='row margin-bottom'>
                <div class='large-12 columns'>
                    <img src={this.props.logoUrl} class='bullet-logo' />
                    <div class='bullet-content'>{this.props.content}</div>
                </div>
            </div>
        );
    }
}
