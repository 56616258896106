// @flow

import type { $Action } from '../../util/Types';
import {
    CREATE_NEW_PRIVATE_KEY,
    DELETE_PRIVATE_KEY,
    FETCH_API_DETAILS,
    FETCH_NEW_PRIVATE_KEY,
    PREPARE_API_SETUP,
    SAVE_API_DETAILS,
    SAVE_PRIVATE_KEY_UPDATE,
} from './consts';
import * as ProcessOut from '../../util/ProcessOut';
import type { $ApiKeyModel, $ApiSetupState } from './consts';

export function requestApiSetupPreparation(): $Action {
    return {
        type: PREPARE_API_SETUP,
        payload: null,
    };
}

export function fetchApiDetails(): $Action {
    return {
        type: FETCH_API_DETAILS,
        payload: ProcessOut.APIcallPromise('/api-versions', 'GET'),
    };
}

export function updateApiVersion(apiDetails: $ApiSetupState, newVersion: string): $Action {
    return {
        type: UPDATE_API_DETAILS,
        payload: {
            ...apiDetails,
            currentApiVersion: newVersion,
        },
    };
}

export function saveApiVersion(apiDetails: $ApiSetupState): $Action {
    return {
        type: SAVE_API_DETAILS,
        payload: ProcessOut.APIcallPromise('/api-versions', 'PUT', {
            api_version_name: apiDetails.currentApiVersion,
        }),
    };
}

export function generateNewPrivateKey(): $Action {
    return {
        type: FETCH_NEW_PRIVATE_KEY,
        payload: ProcessOut.APIcallPromise('/private-keys', 'POST', {}),
    };
}

export function createApiKey(
    name: string,
    callback: (secret: string) => void,
    onError: () => void,
): $Action {
    return {
        type: CREATE_NEW_PRIVATE_KEY,
        payload: {
            name,
            callback,
            onError,
        },
    };
}

export function deleteApiKey(name: string, callback: () => void): $Action {
    return {
        type: DELETE_PRIVATE_KEY,
        payload: {
            name,
            callback,
        },
    };
}

export function updatePrivateKey(privateKey: $ApiKeyModel, callback: () => void): $Action {
    return {
        type: SAVE_PRIVATE_KEY_UPDATE,
        payload: {
            privateKey,
            callback,
        },
    };
}
