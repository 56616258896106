// @flow

import { put, takeLatest, take, select, takeEvery } from 'redux-saga/effects';
import type { $ChartType } from '../Boards/consts';
import type { $Params } from '../ChartPreviewer/consts';
import { typeFailed, typeFulfilled, typePending } from '../../../util/ProcessOut';
import {
    FETCH_CHART_PREVIEW,
    REQUEST_CHART_PREVIEW,
    REQUEST_CHART_SAVE,
} from '../ChartPreviewer/consts';
import * as Actions from '../ChartPreviewer/actions';
import { replaceForGwayKeys } from '../Boards/charts/utils';
import * as BoardActions from '../Boards/actions';
import * as ProcessOut from '../../../util/ProcessOut';
import * as Sentry from '@sentry/browser';

type Action = {
    payload: {
        name: string,
        boardId: string,
        formula: string,
        description: string,
        type: $ChartType,
        unit: string,
        size: number,
        plotted: string,
        params: $Params,
    },
};

function* fetchChartPreview(action: Action): Generator<*, *, *> {
    try {
        yield put({ type: typePending(FETCH_CHART_PREVIEW) });
        const { name, description, boardId, formula, unit, size, type, plotted } = action.payload;
        const chart = {
            name: name,
            description: description,
            type: type,
            settings: {
                formula: formula,
                plotted_field: plotted,
            },
            size: size,
            unit: unit,
            preview: true,
        };
        const chartResult = yield put.resolve(
            Actions.fetchPreviewedChart(chart, boardId, action.payload.params),
        );
        const chartData = chartResult.value.data;
        // we fetch the gateway ids and names list from the store.
        let gway_configurations_names = yield select(store => store.gateway_configurations_names);
        // if we don't have them we wait for it
        while (!gway_configurations_names.fetched) {
            yield take();
            gway_configurations_names = yield select(store => store.gateway_configurations_names);
        }

        // we update the charts keys with gateway names
        chartData.data = replaceForGwayKeys(
            chartData.data,
            gway_configurations_names.gateway_configuration_names,
        );

        const payload = {
            chart: {
                ...chartResult.value.data.chart,
                fetched: true,
                data: chartData.data,
                is_comparison: chartResult.value.data.is_comparison,
            },
        };

        yield put({
            type: chartResult.value.data.success
                ? typeFulfilled(FETCH_CHART_PREVIEW)
                : typeFailed(FETCH_CHART_PREVIEW),
            payload: payload,
        });
    } catch (error) {
        yield put({ type: typeFailed(FETCH_CHART_PREVIEW) });
    }
}

type $SaveAction = {
    payload: {
        projectId: string,
        name: string,
        boardName: string,
        boardId: string,
        formula: string,
        type: $ChartType,
        unit: string,
        size: number,
        description: ?string,
        plotted: string,
        params: $Params,
        id?: ?string, // if non null the chart will be edited
        position?: { x: number, y: number },
        height: number,
    },
};

function* saveChart(action: $SaveAction): Generator<*, *, *> {
    try {
        const {
            name,
            description,
            boardId,
            formula,
            unit,
            size,
            type,
            plotted,
            height,
            id,
            position,
        } = action.payload;

        const localLayout = yield select(store => store.analytics_v2.boardDetails.localLayout);
        let positionY = 0;
        let positionX = 0;
        if (!position) {
            for (const entry in localLayout) {
                if (localLayout[entry].y > 0) positionY = localLayout[entry].y;
            }

            positionY += size;
        } else {
            positionY = position.y;
            positionX = position.x;
        }
        const chart = {
            id: id || '',
            name: name,
            description: description || '',
            type: type,
            settings: {
                formula: formula,
                plotted_field: plotted,
            },
            size: size,
            position_x: positionX,
            position_y: positionY,
            margin_left: 0,
            on_new_line: false,
            unit: unit,
            preview: false,
            is_comparison: undefined,
            created_at: undefined,
            board_id: boardId,
            x_axis_format: undefined,
            height,
        };

        yield put.resolve(Actions.saveChart(chart, boardId, action.payload.params));
        yield put(BoardActions.requestBoardsFetch(boardId));
    } catch (error) {
        console.log(error);
        if (!error.status) {
            Sentry.captureException(error);
        }
    }
}

export default function* watchChartPreviewRequest(): Generator<*, *, *> {
    yield takeLatest(REQUEST_CHART_PREVIEW, fetchChartPreview);
    yield takeEvery(REQUEST_CHART_SAVE, saveChart);
}
