// @flow
import Dispatcher from '../Dispatcher';
import * as ProcessOut from '../util/ProcessOut';
import 'whatwg-fetch';
import { EventTypes } from 'redux-segment';
import LocalStorage from 'local-storage';

export function loadUser2() {
    return function(dispatch: () => void) {
        dispatch({
            type: 'FETCH_USER',
            payload: ProcessOut.APIcallPromise('/users', 'GET', null).then(function(response) {
                if (response.data.success) {
                    let user = response.data.user;
                    dispatch({
                        type: 'SEGMENT_USER_LOGGED',
                        meta: {
                            analytics: {
                                eventType: EventTypes.identify,
                                eventPayload: {
                                    userId: user.email,
                                    traits: {
                                        email: user.email,
                                        name: user.name,
                                    },
                                },
                            },
                        },
                    });
                }
                return response;
            }),
        });
    };
}

export function createUser(user: any, error: any => void) {
    Dispatcher.dispatch({ type: 'CREATING_USER' });

    const headersField = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'API-Version': ProcessOut.APIVersion,
        'Disable-Logging': 'true',
    };

    const forceRegion = LocalStorage('force-region');
    if (forceRegion) {
        headersField['X-ProcessOut-region'] = forceRegion;
    }

    fetch(ProcessOut.ProcessOutUrl + '/users', {
        method: 'POST',
        headers: headersField,
        body: user,
    })
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {
            if (!json.success) {
                ProcessOut.addNotification(json.message, 'error');
                error();
            } else {
                Dispatcher.dispatch({ type: 'USER_CREATED', user: json.user });
            }
        })
        .catch(function(ex) {
            error();
        });
}

export function enableTwoFactor(code: number) {
    const body = {
        two_factor_token: code,
    };
    const bodyJson = JSON.stringify(body, null, 2);

    return function(dispatch: () => void) {
        return {
            type: 'ENABLE_TWO_FACTOR',
            payload: ProcessOut.APIcallPromise('/users/two-factor', 'POST', bodyJson).then(function(
                response,
            ) {
                if (response.data.success) dispatch(getTwoFactorData());
            }),
        };
    };
}

export function disableTwoFactor(code: number) {
    const body = {
        two_factor_token: code,
    };
    const bodyJson = JSON.stringify(body, null, 2);
    return function(dispatch: () => void) {
        return {
            type: 'DISABLE_TWO_FACTOR',
            payload: ProcessOut.APIcallPromise('/users/two-factor', 'DELETE', bodyJson).then(
                function(response) {
                    if (response.data.success) dispatch(getTwoFactorData());
                },
            ),
        };
    };
}

export function updateUser(user: any) {
    Dispatcher.dispatch({ type: 'EDITING_USER' });
    ProcessOut.APIcall(
        '/users',
        'PUT',
        user,
        function(json) {
            if (json.success) {
                Dispatcher.dispatch({ type: 'USER_EDITED', user: json.user });
                ProcessOut.addNotification('Settings updated.', 'success');
            }
        },
        ProcessOut.generateHeaders(''),
    );
}

export function generateToken(email: string, password: string, twoFactor: number) {
    let body;
    if (twoFactor) {
        body = JSON.stringify(
            {
                email: email,
                password: password,
                two_factor_token: twoFactor,
            },
            null,
            2,
        );
    } else {
        body = JSON.stringify(
            {
                email: email,
                password: password,
            },
            null,
            2,
        );
    }

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    return {
        type: 'LOG_USER_IN',
        payload: ProcessOut.APIcallPromise('/authenticate', 'POST', body, headers, true)
            .then(function(response) {
                const data = response.data;
                if (data.success) {
                    LocalStorage('token', data.token.token || '');
                    LocalStorage('token-expiry', data.token.expires_at || '');
                    LocalStorage('refresh-token', data.token.refresh_token || '');
                    LocalStorage('refresh-token-expiry', data.token.refresh_token_expires_at || '');
                }
                return response;
            })
            .catch(response => {
                if (response.status === 401) {
                    ProcessOut.addNotification('Invalid email/password combination.', 'error');
                } else if (response.status === 412) {
                    throw response;
                }
                throw response;
            }),
    };
}

export function getTwoFactorData() {
    return {
        type: 'FETCH_TWO_FACTOR_DATA',
        payload: ProcessOut.APIcallPromise('/users/two-factor', 'GET'),
    };
}
