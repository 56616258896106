// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { $TelescopeState } from '../../../stores/Reducers/TelescopeReducer/Index';
import uniqid from 'uniqid';
import type { $FeesRecommendation } from '../reducer';
import Advice from '../../../components/Telescope/Advice';

type Props = {
    telescope: $TelescopeState,
};

class FeesRecommendations extends React.Component<Props> {
    render() {
        return (
            <div class='row'>
                <div class='large-12 columns'>
                    {this.props.telescope.standard.data.fees_recommendations.map(reco => (
                        <Recommendation key={uniqid()} recommendation={reco} />
                    ))}
                </div>
            </div>
        );
    }
}

export default connect(store => {
    return { telescope: store.telescope };
})(FeesRecommendations);

class Recommendation extends React.PureComponent<{
    recommendation: $FeesRecommendation,
}> {
    render() {
        const { recommendation } = this.props;
        return <Advice {...recommendation} />;
    }
}
