import * as ProcessOut from '../util/ProcessOut';

export function fetchCoupons(length, after, id, filter) {
    var bound = after ? 'start_after' : 'end_before';
    if (!id) {
        id = '';
    }

    filter = filter || '';

    return {
        type: 'FETCH_COUPONS',
        payload: ProcessOut.APIcallPromise(
            `/coupons?order=desc&${bound}=${id}&limit=${length}&filter=${filter}`,
            'GET',
        ),
    };
}

export function createCoupon(coupon, resolve) {
    var couponBody = JSON.stringify(coupon);
    return function(dispatch) {
        return {
            type: 'CREATE_COUPON',
            payload: ProcessOut.APIcallPromise('/coupons', 'POST', couponBody).then(function(
                response,
            ) {
                if (response.data.success) {
                    dispatch(fetchCoupons());
                    resolve();
                }
                return response;
            }),
        };
    };
}

export function editCoupon(coupon, id, resolve) {
    var couponBody = JSON.stringify(coupon);
    return function(dispatch) {
        return {
            type: 'EDIT_COUPON',
            payload: ProcessOut.APIcallPromise(`/coupons/${id}`, 'PUT', couponBody).then(function(
                response,
            ) {
                if (response.data.success) {
                    dispatch(fetchCoupons());
                    resolve();
                }
                return response;
            }),
        };
    };
}

export function deleteCoupon(id) {
    return function(dispatch) {
        return {
            type: 'DELETE_COUPON',
            payload: ProcessOut.APIcallPromise(`/coupons/${id}`, 'DELETE').then(function(response) {
                if (response.data.success) {
                    dispatch(fetchCoupons());
                }
                return response;
            }),
        };
    };
}

export function fetchDiscounts(subscription) {
    return {
        type: 'FETCH_DISCOUNTS',
        payload: ProcessOut.APIcallPromise(
            `/subscriptions/${subscription}/discounts?expand=coupon`,
            'GET',
        ),
    };
}

export function applyCoupon(subscription, coupon) {
    var discountBody = JSON.stringify({
        coupon_id: coupon,
    });

    return function(dispatch) {
        return {
            type: 'APPLY_COUPON',
            payload: ProcessOut.APIcallPromise(
                `/subscriptions/${subscription}/discounts`,
                'POST',
                discountBody,
            ).then(function(response) {
                if (response.data.success) {
                    dispatch(fetchDiscounts(subscription));
                }
                return response;
            }),
        };
    };
}
