import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import AuthReducer from './AuthReducer';
import TwoFactorReducer from './TwoFactorReducer';

export default combineReducers({
    details: UserReducer,
    auth: AuthReducer,
    two_factor: TwoFactorReducer,
});
