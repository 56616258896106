import React from 'react';
import POEvent from '../components/POEvent';
import ContentLayout from './ContentLayout';
import Pagination from '../components/Pagination';
import * as EventsActions from '../Actions/EventsActions';
import Loader from '../components/Loader';
import Empty from '../components/Empty';
import Error from '../components/Error';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotPermitted from '../features/Permissions/NotPermitted';
import Webhooks from '../features/Webhooks/Webhooks';

const eventsPerPage = 15;

@connect(store => {
    return { details: store.events };
})
export default class Events extends React.Component {
    static contextTypes = {
        store: PropTypes.object.isRequired,
    };

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Events | ProcessOut';
        var firstItem = this.props.location.query.after_item;
        if (!firstItem) firstItem = '';
        this.props.dispatch(EventsActions.loadEvents(eventsPerPage, true, firstItem));
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    componentWillReceiveProps(nextProps) {
        var firstItem = this.props.location.query.after_item;
        if (!firstItem) firstItem = '';

        if (!nextProps.details.fetching && !nextProps.details.fetched && !nextProps.details.error) {
            this.props.dispatch(EventsActions.loadEvents(eventsPerPage, true, firstItem));
        }
    }

    render() {
        const { details } = this.props;
        var content;

        const events = this.props.details.events;

        if ((details.fetching || !details.fetched) && !details.error) {
            content = <Loader />;
        } else if (!details.error) {
            var empty;
            var header;

            if (events.length === 0) {
                empty = <Empty text='No event has been fired yet.' />;
            } else {
                header = (
                    <div
                        class='row capitalize greyed bold small-margin-bottom'
                        style={{ fontSize: '0.8em' }}
                    >
                        <div class='medium-6 columns'>Event name</div>
                        <div class='medium-6 columns text-right'>Date</div>
                    </div>
                );
            }

            content = (
                <div>
                    {header}
                    {events.map(function(poevent) {
                        return (
                            <div key={poevent.id}>
                                <POEvent event={poevent} />
                            </div>
                        );
                    })}
                </div>
            );
        }

        return (
            <ContentLayout title={<h4>Events</h4>}>
                {details.error ? (
                    details.error.notPermitted ? (
                        <NotPermitted />
                    ) : (
                        <Error />
                    )
                ) : (
                    <div>
                        <div className='row big-margin-bottom'>
                            <Webhooks />
                        </div>

                        <hr style={{ marginBottom: '3em' }} />

                        <div className='row big-margin-bottom'>
                            <div className='large-12 columns'>
                                {empty}
                                {content}
                            </div>
                        </div>
                        <Pagination
                            reloadFunction={EventsActions.loadEvents.bind(null, eventsPerPage)}
                            itemsArray={this.props.details.fetched ? events : []}
                            hasMore={
                                this.props.details.fetched ? this.props.details.has_more : false
                            }
                            location={this.props.location}
                            hide={!this.props.details.fetched || this.props.details.fetching}
                        />
                    </div>
                )}
            </ContentLayout>
        );
    }
}
