// @flow

import type { $Webhook } from './reducer';

export const FETCH_WEBHOOKS = 'FETCH_WEBHOOKS';
export type $FetchWebhooksAction = {
    type: 'FETCH_WEBHOOKS',
};
export const fetchWebhooks = (): $FetchWebhooksAction => ({ type: FETCH_WEBHOOKS });

export const CREATE_WEBHOOK = 'CREATE_WEBHOOK';
export type $CreateWebhookAction = {
    type: 'CREATE_WEBHOOK',
    payload: {
        url: string,
    },
};
export const createWebhook = (url: string): $CreateWebhookAction => ({
    type: CREATE_WEBHOOK,
    payload: { url },
});

export const DELETE_WEBHOOK = 'DELETE_WEBHOOK';
export type $DeleteWebhookAction = { type: 'DELETE_WEBHOOK', payload: { hook: $Webhook } };
export const deleteWebhook = (hook: $Webhook): $DeleteWebhookAction => ({
    type: DELETE_WEBHOOK,
    payload: { hook },
});
