// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { $RoutingRule } from './consts';
import * as Actions from './actions';
import type { $Dispatcher } from '../../util/Types';
import Rule from './Rule';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
type Props = {
    rules: Array<$RoutingRule>,
} & $Dispatcher;
import ReactTooltip from 'react-tooltip';
import { SortableContainer } from 'react-sortable-hoc';

class BlockingRulesListing extends React.Component<Props> {
    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    render() {
        const { rules } = this.props;
        return (
            <div class='row'>
                <div class='medium-12 columns'>
                    {rules.length === 0 ? (
                        <div class='greyed' style={{ position: 'relative', top: '.5em' }}>
                            {`You haven't defined any blocking rules yet. `}
                            <a
                                class='small round border button right add-rule-button'
                                onClick={() => {
                                    this.props.dispatch(Actions.addRule('block'));
                                }}
                            >
                                Add a blocking rule
                            </a>
                        </div>
                    ) : null}
                    <ReactTooltip effect='solid' />
                    <ReactCSSTransitionGroup
                        transitionName='routing-setting-rule-transition'
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={300}
                    >
                        {rules.map((rule, index, array) => {
                            return (
                                <Rule
                                    index={index}
                                    key={rule.id}
                                    rule={rule}
                                    ruleWontMatch={
                                        array
                                            .slice(0, index)
                                            .findIndex(
                                                rule =>
                                                    rule.conditions[0].filters.filter(
                                                        filter =>
                                                            filter.path !== '' &&
                                                            filter.value !== '',
                                                    ).length === 0,
                                            ) > -1
                                    }
                                />
                            );
                        })}
                    </ReactCSSTransitionGroup>
                    <div class='small-margin-bottom' />
                    {this.props.rulesSettings.rules.filter(rule => rule.declaration === 'block')
                        .length > 0 ? (
                        <a
                            onClick={() => {
                                this.props.dispatch(Actions.addRule('block'));
                            }}
                            class='round border button small right'
                        >
                            &#43; add another rule
                        </a>
                    ) : null}
                </div>
            </div>
        );
    }
}

const ConnectedRules = connect(store => {
    return {
        rulesSettings: store.routingRulesSettings,
    };
})(BlockingRulesListing);
export default SortableContainer(props => <ConnectedRules {...props} />);
