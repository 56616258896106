// @flow

/*
 * This feature is only meant to preload the dashboard data (user details, projects list)
 * and redirect to the telescope page.
 */

import { call, put, takeLatest, select } from 'redux-saga/effects';
import type { $ActionType } from './consts';
import { DASHBOARD_PREPARATION, REQUEST_DASHBOARD_PREPARATION } from './consts';
import * as ProjectsActions from '../../Actions/ProjectsActions';
import * as ProcessOut from '../../util/ProcessOut';
import { typeFulfilled, typePending } from '../../util/ProcessOut';
import { replace } from 'react-router-redux';
import { typeFailed } from '../../util/ProcessOut';
import { refreshToken } from '../NetworkManager/sagas';
import * as Sentry from '@sentry/browser';

export function* prepareDashboard(action: $ActionType): Generator<*, *, *> {
    try {
        // Url is the url that we want to load after dashboard preparation.
        let url;
        const businessName = action.payload.email.split('@')[1];
        yield put({ type: typePending(DASHBOARD_PREPARATION) });
        // We will fetch the projects list first
        const projectsResult = yield put.resolve(ProjectsActions.loadProjects());

        // If a project id is specified we need to test the authorization
        const projectId = action.payload.projectId;

        if (projectId && projectId !== '') {
            try {
                const authorized = yield call(
                    ProcessOut.APIcallPromise,
                    `/projects/${projectId}`,
                    'GET',
                    null,
                    ProcessOut.generateHeaders(projectId),
                    true,
                );
                if (authorized.data.success) {
                    /*
                     * The id was in Local storage and belongs to the user
                     */
                    // set the project id
                    ProcessOut.projectId = projectId;

                    url = `/projects/${projectId}/`;
                }
            } catch (error) {
                /*
                 * The id was in Local storage but belongs to another use
                 */
                const project = yield call(getProject, projectsResult.value.data.projects);
                if (!project) {
                    // The user does not have a project, we create one
                    url = '/projects/create';
                } else {
                    // The user has another project, we redirect to it
                    url = `/projects/${project.id}/`;
                }
            }
        } else {
            const project = yield call(getProject, projectsResult.value.data.projects);
            if (!project) {
                /*
                 * The Local storage was empty and the user doesn't have any project
                 */
                const newProject = yield call(createProject, businessName || 'Project');
                url = `/projects/${newProject.id}/`;
            } else {
                /*
                 * The Local storage was empty but the user has a project
                 */
                url = `/projects/${project.id}/`;
            }
        }
        // We update the store to tell that the dashboard is ready
        yield put({ type: typeFulfilled(DASHBOARD_PREPARATION) });
        // Go to telescope/project creation
        yield put(replace(url));
    } catch (error) {
        yield put({
            type: typeFailed(DASHBOARD_PREPARATION),
            payload: {
                error: error,
            },
        });
        Sentry.captureException(error);
    }
}

export function* getProject(projects: Array<{ id: string }>): Generator<*, *, *> {
    // we check if the user already has a project or not
    if (projects.length > 0) return projects[0];
    else return null;
}

export function* createProject(name: string): Generator<*, *, *> {
    // The user does not have any project. We need to create one

    let newProject = {
        name: name,
        default_currency: 'USD',
    };
    // We create the project
    const body = JSON.stringify(newProject);
    const project = yield call(ProcessOut.APIcallPromise, '/projects', 'POST', body);
    if (!project.data.success) throw project.data.message;

    // We need to update the user tokens as a new project has been created
    // refresh token
    const authDetails = yield select(store => store.user.auth);
    const rToken = authDetails.refreshToken.token;
    if (rToken) {
        yield call(refreshToken, rToken);
    }

    return project.data.project;
}

export function* requestDashboardPreparation(): any {
    yield takeLatest(REQUEST_DASHBOARD_PREPARATION, prepareDashboard);
}
