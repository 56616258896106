// @flow
import Dispatcher from '../Dispatcher';
import * as ProcessOut from '../util/ProcessOut';
import * as Store from '../stores/Store';
import LocalStorage from 'local-storage';

declare var analytics: {
    identify: (string, any) => void,
};

export function loadProjects() {
    return {
        type: 'FETCH_PROJECTS',
        payload: ProcessOut.APIcallPromise('/projects', 'GET', null).then(function(response) {
            if (response.data.success) {
                let projects = response.data.projects.map(function(project) {
                    return project.id;
                });

                let currentState = Store.store.getState();
                let user = currentState.user.details;
                analytics.identify(user.email, {
                    email: user.email,
                    name: user.name,
                    projects_list: projects,
                });
            }

            return response;
        }),
    };
}

export function generatePrivateKey(id: string, resolve: (privateKey: string) => void) {
    return {
        type: 'GENERATE_PRIVATE_KEY',
        payload: ProcessOut.APIcallPromise(`/projects/${id}/private-key`, 'POST').then(function(
            response,
        ) {
            if (response.data.success) resolve(response.data.project.private_key);
            return response;
        }),
    };
}

export function loadApiVersions() {
    return {
        type: 'FETCH_API_VERSIONS',
        payload: ProcessOut.APIcallPromise('/', 'GET', null, ProcessOut.generateHeaders('')),
    };
}

export function editProject(project: any, id: string, resolve: () => void) {
    let body = JSON.stringify(project);
    return {
        type: 'EDIT_PROJECT',
        payload: ProcessOut.APIcallPromise(`/projects/${id}`, 'PUT', body).then(function(response) {
            if (response.data.success) resolve();
            return response;
        }),
    };
}

export function deleteProject(id: string, resolve: () => void) {
    ProcessOut.APIcall(
        `/projects/${id}`,
        'DELETE',
        null,
        function(json) {
            if (json.success) {
                LocalStorage.remove('projectid');
                resolve();
            }
        },
        ProcessOut.generateHeaders(id),
    );
}

export function createProject(data: any) {
    const region = data.region;
    const project = JSON.stringify(data);
    Dispatcher.dispatch({ type: 'CREATING_PROJECT' });
    const headers =
        region && region !== 'null'
            ? {
                  ...ProcessOut.generateHeaders(''),
                  'X-ProcessOut-region': data.region,
              }
            : ProcessOut.generateHeaders('');
    ProcessOut.APIcall(
        '/projects',
        'POST',
        project,
        function(json) {
            if (json.success) {
                Dispatcher.dispatch({
                    type: 'CREATED_PROJECT',
                    project: json.project,
                });
            }
        },
        headers,
    );
}

export const UPDATE_DUNNING_SETTINGS = 'UPDATE_DUNNING_SETTINGS';
export const FETCH_DUNNING_SETTINGS = 'FETCH_DUNNING_SETTINGS';
export const FETCH_DUNNING_SETTINGS_PENDING = 'FETCH_DUNNING_SETTINGS_PENDING';
export const FETCH_DUNNING_SETTINGS_FULFILLED = 'FETCH_DUNNING_SETTINGS_FULFILLED';

export function updateDunning(projectId: string, project: any) {
    return function(dispatch: () => void) {
        return {
            type: 'UPDATE_DUNNING_SETTINGS',
            payload: ProcessOut.APIcallPromise(`/projects/${projectId}`, 'PUT', project).then(
                function(response) {
                    dispatch(fetchDunning(projectId));
                    return response;
                },
            ),
        };
    };
}

export function fetchDunning(projectId: string) {
    return {
        type: FETCH_DUNNING_SETTINGS,
        payload: ProcessOut.APIcallPromise(`/projects/${projectId}`, 'GET'),
    };
}

export function fetchProjectDetails(id: string) {
    return function(dispatch: () => void) {
        dispatch({
            type: 'FETCH_PROJECT_DETAILS',
            payload: ProcessOut.APIcallPromise(`/projects/${id}?expand=api_version`, 'GET'),
        });
    };
}

export function setProjectId(id: string) {
    return function(dispatch: () => void) {
        dispatch({
            type: 'SET_PROJECT_ID',
            payload: {
                projectId: id,
            },
        });

        if (id) {
            dispatch(fetchProjectDetails(id));
        }
    };
}
