// @flow

import React from 'react';
import { abbreviateNumber } from './Utils';
import type { $ChartRenderer, $DataPoint } from '../Boards/consts';
import type { $Dispatcher } from '../../../util/Types';
import { connect } from 'react-redux';
import * as ReactDOMServer from 'react-dom/server';
import { push } from 'react-router-redux';
import { FormattedNumber, IntlProvider } from 'react-intl';
import getColor from '../Boards/charts/colors';
import Error from '../../../components/Error';
import ChartPopup from '../Boards/charts/Popup/ChartPopup';
import Uniqid from 'uniqid';
import moment from 'moment';
import { CLOSE_CHART_POPUP, OPEN_CHART_POPUP } from '../Boards/charts/Popup/consts';
import Analytics from '../../../util/Analytics';
import qs from 'qs';

type Props = {
    currentProject: any,
    error?: ?boolean,
    singleValue?: ?boolean,
    comparisonPlot?: ?string,
    popup: {
        chartId: ?string,
    },
    canUpdateAnalyticsFilter?: boolean,
} & $ChartRenderer &
    $Dispatcher;

type State = {
    id: number,
    chart: any,
    initialData: any,
    hadToTrim: boolean,
};

class BarChart extends React.Component {
    props: Props;
    state: State;

    constructor() {
        super();
        this.state = { id: 0, chart: null, initialData: null };
    }

    componentWillMount() {
        this.setState({
            id: Uniqid(),
        });
    }

    componentDidMount() {
        this.setState(
            {
                initialData: this.props.data.slice(),
            },
            () => {
                if (!this.props.error) this.draw();
            },
        );
    }

    componentDidUpdate() {
        if (!this.props.error) this.draw();
    }

    componentWillUnmount() {
        const { chart } = this.state;
        if (chart) chart.clear();
    }

    draw() {
        if (!this.state.initialData || !document.getElementById(`chart${this.state.id}`)) return;
        if (!this.state.chart) {
            this.setState({
                chart: new Picasso.BarLineChart(`#chart${this.state.id}`, {
                    yAxisFormatter: function(d) {
                        const suffix = this.props.type === 'percentage' ? '%' : '';
                        return `${abbreviateNumber(
                            this.props.type === 'percentage' ? Math.round(d * 100 * 100) / 100 : d,
                        )}${suffix}`;
                    }.bind(this),
                }),
            });
            return;
        }
        let data = this.props.data.slice();

        // we loop over every value if we're on a single value chart to set the same key ""
        // This way nothing is displayed below the chart
        if (this.props.singleValue) {
            for (const set of data) {
                for (const point of set.datapoints) {
                    point.key = '';
                }
            }
        }

        const chart = this.state.chart;
        chart.cleanupTip();
        chart.reset();
        let colorCount = -1;
        const shouldDisplayHours =
            data.length > 0 &&
            ((data[0].datapoints && data[0].datapoints.length < 2) ||
                moment(data[0].datapoints[0].key).hour() !==
                    moment(data[0].datapoints[1].key).hour());
        for (const entry of data) {
            colorCount++;
            const tip = function(d) {
                const barKey = d.key;
                const isDate = moment(barKey, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid();
                const header = this.props.singleValue
                    ? null
                    : isDate
                    ? moment(barKey).calendar(null, {
                          sameDay: shouldDisplayHours ? '[Today at] LT' : '[Today]',
                          nextDay: '[Tomorrow]',
                          nextWeek: 'dddd',
                          lastDay: shouldDisplayHours ? '[Yesterday at] LT' : '[Yesterday]',
                          lastWeek: shouldDisplayHours ? 'MMMM Do YYYY, h:mm a' : 'MMMM Do YYYY',
                          sameElse: shouldDisplayHours ? 'MMMM Do YYYY, h:mm a' : 'MMMM Do YYYY',
                      })
                    : barKey;

                let content;
                switch (this.props.type) {
                    case 'percentage': {
                        content = (
                            <IntlProvider
                                locale={navigator ? navigator.language || 'en-US' : 'en-US'}
                            >
                                <div>
                                    <FormattedNumber
                                        value={d.value}
                                        style={'percent'}
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={2}
                                    />{' '}
                                    <span class='greyed'>
                                        (<FormattedNumber value={d._count} /> transactions)
                                    </span>
                                </div>
                            </IntlProvider>
                        );
                        break;
                    }

                    case 'amount': {
                        content = (
                            <IntlProvider
                                locale={navigator ? navigator.language || 'en-US' : 'en-US'}
                            >
                                <div>
                                    <FormattedNumber
                                        value={d.value}
                                        style={'currency'}
                                        currency={this.props.currency}
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={2}
                                    />{' '}
                                    <span className='greyed'>
                                        (<FormattedNumber value={d._count} /> transactions)
                                    </span>
                                </div>
                            </IntlProvider>
                        );
                        break;
                    }

                    default: {
                        content = (
                            <IntlProvider
                                locale={navigator ? navigator.language || 'en-US' : 'en-US'}
                            >
                                <div>
                                    <FormattedNumber value={d.value} maximumFractionDigits={2} />{' '}
                                    transactions
                                </div>
                            </IntlProvider>
                        );
                    }
                }

                return ReactDOMServer.renderToString(
                    <div class='row'>
                        <div class='large-12 columns'>
                            <div class='row'>
                                <div class='large-12 columns'>
                                    <h5>
                                        {header && header.length > 40
                                            ? `${header.slice(0, 40)}...`
                                            : header}
                                    </h5>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='large-12 columns'>
                                    {content}
                                    {this.props.comparison ? (
                                        <span
                                            style={{
                                                color: '#30336b',
                                                marginLeft: '.5em',
                                            }}
                                        >
                                            {`- ${entry.key}`}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>,
                );
            }.bind(this);

            // we check that all the keys are valid dates
            let onlyDates = true;
            for (const point of entry.datapoints) {
                if (!moment(point.key, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid()) {
                    onlyDates = false;
                    break;
                }
            }
            if (onlyDates) {
                // We only have dates so we update each key to a Date object
                for (const point of entry.datapoints) {
                    point.key = new Date(point.key);
                }
            }

            if (this.props.keysMask && this.props.keysMask.includes(entry.key)) continue;
            const currentColor = this.props.colors
                ? this.props.colors.find(color => color.key === entry.key)
                : null;
            chart.addBar({
                data: entry.datapoints,
                colors: currentColor ? [currentColor.color] : [getColor(colorCount)],
                tip: tip,
                onclick: (point: $DataPoint) => {
                    const onFilter = () => {
                        const filter = point._corresponding_filter;
                        if (!filter) return;
                        const splits = filter.split(';');
                        const filterIndex = splits.findIndex(
                            entry => !entry.includes('start:') && !entry.includes('end:'),
                        );
                        const filterValue = splits[filterIndex] || '';
                        const filterParam = qs.parse(this.props.location.query.search);
                        filterParam.filter = filterValue;
                        const newParam = qs.stringify(filterParam);
                        this.props.dispatch(push(`${this.props.location.pathname}?${newParam}`));
                        this.props.dispatch({
                            type: 'ANALYTICS_FILTER_CHANGE',
                            payload: { filter: filterValue },
                        });
                        // Track event
                        Analytics('BAR_CHART_CLICK_FILTER', {
                            filterValue,
                        });
                    };

                    const onList = () => {
                        const filter = point._corresponding_filter;
                        if (!filter) return;
                        this.props.dispatch(
                            push(
                                `/projects/${
                                    this.props.currentProject.project.id
                                }/transactions?filter=${filter}`,
                            ),
                        );
                        // Track event
                        Analytics('BAR_CHART_CLICK_LIST', { filter });
                    };
                    this.props.dispatch({
                        type: OPEN_CHART_POPUP,
                        payload: {
                            chartId: this.state.id,
                            onFilter: onFilter,
                            onList: onList,
                        },
                    });
                },
            });
        }
        chart.resetSVG();
        chart.draw();
    }

    render() {
        if (this.props.error) {
            return <Error text={'An error occured while loading this chart.'} />;
        }

        return (
            <div
                onClick={e => {
                    if (this.props.popup.chartId === this.state.id) {
                        if (!e.target.classList.contains('popup-button')) {
                            this.props.dispatch({ type: CLOSE_CHART_POPUP });
                        }
                    }
                }}
            >
                <svg
                    class={`chart line-chart chart-popup ${
                        this.state.id === this.props.popup.chartId ? 'chart-popup-open' : ''
                    }`}
                    id={`chart${this.state.id}`}
                    width='100%'
                    height='200px'
                />
                <ChartPopup
                    correspondingChartId={this.state.id}
                    canUpdateAnalyticsFilter={this.props.canUpdateAnalyticsFilter}
                />
            </div>
        );
    }
}

export default connect(store => {
    return {
        currentProject: store.currentProject,
        popup: store.analytics_v2.popup,
        location: store.routing.locationBeforeTransitions,
    };
})(BarChart);
