import React from 'react';
import MData from './MData';

export default class Metadata extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        this.bindData(this.props);
    }

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        this.updateMetaForm();
    }

    componentWillUnmount() {
        this.setState({ data: [] });
    }

    bindData(props) {
        var arr = [];
        var data;
        if (props.resource) {
            data = Object.keys(props.resource.metadata);
            for (const meta of data)
                arr.push({
                    key: meta,
                    value: props.resource.metadata[meta],
                });
        }
        this.setState({ data: arr });
    }

    addData(event) {
        if (event) {
            event.preventDefault();
        }
        var data = this.updateValue();
        data.push({ key: '', value: '' });
        this.setState({ data: data });
    }

    updateValue() {
        var arr = [];

        var metas = document.getElementById('metas');
        var keys = metas.getElementsByClassName('key');
        var values = metas.getElementsByClassName('value');

        for (var i = 0; i < keys.length; i++) {
            if (keys[i].value != '' && values[i].value != '')
                arr.push({ key: keys[i].value, value: values[i].value });
        }
        return arr;
    }

    updateMetaForm() {
        var metas = document.getElementById('metas');
        var keys = metas.getElementsByClassName('key');
        var values = metas.getElementsByClassName('value');

        var shouldAddNewRow = this.state.data.length - this.countBasics() != 0;
        for (var i = 0; i < keys.length; i++) {
            if (values[i].value === '') {
                shouldAddNewRow = false;
            }
        }
        if (shouldAddNewRow) {
            this.addData();
        }
    }

    removeMeta(key) {
        var data = this.state.data;
        var newD = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].key !== key) newD.push(data[i]);
        }
        this.setState({ data: newD });
    }

    countBasics() {
        var count = 0;
        for (var i = 0; i < this.state.data.length; i++) {
            let key = this.state.data[i].key;
            if (
                key === 'taxes_amount' ||
                key === 'shipping_amount' ||
                key === 'request_shipping' ||
                key === 'request_email'
            )
                count++;
        }
        return count;
    }

    render() {
        var count = 0;

        var metas;
        if (this.state.data.length - this.countBasics() > 0) {
            metas = (
                <div id='metas'>
                    {this.state.data.map(
                        function(prop) {
                            count++;
                            if (
                                prop.key !== 'shipping_amount' &&
                                prop.key !== 'taxes_amount' &&
                                prop.key !== 'request_shipping' &&
                                prop.key !== 'request_email'
                            ) {
                                return (
                                    <MData
                                        metadata={prop}
                                        updateForm={this.updateMetaForm.bind(this)}
                                        remove={this.removeMeta.bind(this)}
                                        propKey={count}
                                        key={count}
                                    />
                                );
                            }
                        }.bind(this),
                    )}
                </div>
            );
        } else {
            metas = (
                <div id='metas'>
                    <p>
                        No metadata will be stored.{' '}
                        <a class='bold' onClick={this.addData.bind(this)}>
                            Click here to add one.
                        </a>
                    </p>
                </div>
            );
        }

        return (
            <div class='row'>
                <div class='large-12 medium-8 columns'>{metas}</div>
            </div>
        );
    }
}
