// @flow
import React from 'react';
import { FormattedNumber, ReactIntl } from 'react-intl';

export default class MetadataDisplay extends React.Component {
    props: {
        metadata: { [string]: string },
        displayShipping?: ?boolean,
        displayTaxes?: ?boolean,
        currency?: ?string,
    };

    render() {
        const { metadata } = this.props;
        const metadatas: Array<{ key: string, value: string }> = Object.keys(metadata).map(key => {
            return { key: key, value: metadata[key] };
        });

        return (
            <div class='row'>
                <div class='large-12 columns'>
                    <div class='row'>
                        <div class='large-12 columns'>
                            {metadatas.map(data => {
                                if (
                                    (data.key === 'shipping_amount' &&
                                        !this.props.displayShipping) ||
                                    (data.key === 'taxes_amount' && !this.props.displayTaxes)
                                )
                                    return;

                                let value;
                                if (data.key.includes('amount')) {
                                    value = (
                                        <FormattedNumber
                                            value={data.value}
                                            style='currency'
                                            currency={this.props.currency || 'USD'}
                                        />
                                    );
                                } else {
                                    value = data.value;
                                }
                                return (
                                    <div class='row small-margin-bottom' key={data.key}>
                                        <div class='large-5 columns'>
                                            <span class='greyed'>
                                                {data.key.charAt(0).toUpperCase() +
                                                    data.key
                                                        .slice(1)
                                                        .split('_')
                                                        .join(' ')
                                                        .split(/(?=[A-Z])/)
                                                        .join(' ')}
                                            </span>
                                        </div>
                                        <div
                                            class='large-7 columns'
                                            style={{
                                                wordWrap: 'break-word',
                                            }}
                                        >
                                            <span
                                                class='right'
                                                style={{
                                                    wordBreak: 'break-all',
                                                }}
                                            >
                                                {value}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
