// @flow

import { takeLatest, put } from 'redux-saga/effects';
import type { $Action } from '../../util/Types';
import { UPDATE_CURRENT_URL } from './consts';

function* updateCurrentUrl(): Generator<*, *, *> {
    yield put({
        type: UPDATE_CURRENT_URL,
        payload: { url: document.location.hash },
    });
}

export default function* watchForSaga(): Generator<*, *, *> {
    yield takeLatest((action: $Action) => {
        return new RegExp(/^@@router\/\w*/).test(action.type);
    }, updateCurrentUrl);
}
