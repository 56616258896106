export default function(
    state = {
        fetching: false,
        fetched: false,
    },
    action,
) {
    switch (action.type) {
        case 'FETCH_TRANSACTIONS_PENDING': {
            return {
                ...state,
                error: false,
                fetching: true,
                fetched: false,
            };
        }
        case 'FETCH_TRANSACTIONS_FULFILLED': {
            if (!action.payload.success) {
                return {
                    ...state,
                    fetching: false,
                    fetched: true,
                    total_count: 0,
                    has_more: action.payload.has_more,
                    transactions: [],
                };
            }
            return {
                ...state,
                fetching: false,
                fetched: true,
                has_more: action.payload.has_more,
                total_count: action.payload.total_count,
                transactions: action.payload.transactions,
            };
        }
        case 'FETCH_TRANSACTIONS_REJECTED': {
            return {
                ...state,
                error: action.payload,
                fetching: false,
                fetched: false,
            };
        }
    }
    return state;
}
