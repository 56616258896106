// @flow

import React from 'react';
import { connect } from 'react-redux';
import * as Actions from './actions';
import type { $DataExplorerState } from './reducer';
import type { $FetchParams } from '../Boards/consts';
import type { $Dispatcher, $Project } from '../../../util/Types';
import Tabs from './Tabs';
import TypeSelection from './TypeSelection';
import Unit from './Unit/Unit';
import { UPDATE_CHART_BUILDER_DETAILS } from './consts';
import Loading from '../../../components/Loader';
import SavingModal from './SavingModal';
import { Link } from 'react-router';
import { withRouter } from 'react-router';
import type { $ChartBuilderState } from './ChartBuilder/reducer';
type Props = {
    dataExplorer: $DataExplorerState,
    analytics: {
        params: $FetchParams,
    },
    currentProject: {
        project: $Project,
    },
    isChartBuilder: ?boolean,
    chartBuilder: $ChartBuilderState,
} & $Dispatcher;

class Header extends React.Component {
    props: Props;
    preview = () => {
        const { dispatch, dataExplorer, chartBuilder, analytics } = this.props;
        dispatch(
            Actions.requestChartFetch(
                dataExplorer.formula,
                dataExplorer.name || chartBuilder.selectedMetric.name,
                dataExplorer.type,
                dataExplorer.unit,
                analytics.params,
            ),
        );
    };

    render() {
        const { dataExplorer, currentProject } = this.props;
        return (
            <div class='sticky-actions'>
                <div class='row small-margin-bottom' style={{ padding: '0 1em' }}>
                    <div class='large-5 columns'>
                        <input
                            type='text'
                            class='name-input'
                            placeholder='Chart name...'
                            value={dataExplorer.name}
                            onChange={event => {
                                event.preventDefault();
                                this.props.dispatch({
                                    type: UPDATE_CHART_BUILDER_DETAILS,
                                    payload: {
                                        name: event.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                    <div class='large-2 columns'>
                        {dataExplorer.selectedTab !== 'chart-builder' && <TypeSelection />}
                    </div>
                    <div class='large-2 columns'>
                        {dataExplorer.selectedTab !== 'chart-builder' && (
                            <Unit value={dataExplorer.unit} />
                        )}
                    </div>
                    <div class='large-3 columns text-right'>
                        {dataExplorer.chart.fetched &&
                        !dataExplorer.chart.error &&
                        dataExplorer.previewedFormula &&
                        dataExplorer.previewedFormula === dataExplorer.formula ? (
                            <SavingModal boardId={dataExplorer.boardId} />
                        ) : null}
                        {dataExplorer.chart.fetched &&
                        !dataExplorer.chart.error &&
                        dataExplorer.selectedTab === 'presets' ? (
                            dataExplorer.selectedPreset ? (
                                <Link
                                    to={`/projects/${currentProject.project.id}/boards/${
                                        dataExplorer.boardId
                                            ? dataExplorer.boardId + '/new-chart'
                                            : 'data-explorer'
                                    }/chart-builder`}
                                    class='small round main button preview-button fade-button'
                                    onClick={() => {
                                        this.props.dispatch(
                                            Actions.applyPreset(dataExplorer.selectedPreset),
                                        );
                                    }}
                                >
                                    Customize
                                </Link>
                            ) : null
                        ) : dataExplorer.chart.fetching ? (
                            <div class='preview-button'>
                                <Loading size={15} />
                            </div>
                        ) : dataExplorer.selectedTab !== 'presets' ||
                          dataExplorer.selectedPreset ? (
                            <a
                                class='small round main button preview-button'
                                onClick={this.preview}
                            >
                                Preview
                            </a>
                        ) : null}
                    </div>
                </div>
                <Tabs isChartBuilder={this.props.isChartBuilder} />
            </div>
        );
    }
}

export default connect(store => {
    return {
        dataExplorer: store.dataExplorer,
        analytics: store.analytics,
        currentProject: store.currentProject,
        chartBuilder: store.chartBuilder,
    };
})(withRouter(Header));
