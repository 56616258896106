// @flow
import React from 'react';
import Select from 'react-select';
import CallToAction from './CallToAction';
import MonitoringPlusPopup from './MonitoringPlusPopup';
import { connect } from 'react-redux';
import type { $Dispatcher, $Project } from '../../../util/Types';
import { hasFeature } from '../../FeatureFlags/FeatureFlags';
import { push } from 'react-router-redux';

type $Value = 'custom' | 'general' | 'card_scheme' | 'card_type';
export type $ProviderCompareOption = { value: $Value, label: string, isMulti: ?boolean };
type Props = {
    value: $ProviderCompareOption,
    onChange: $ProviderCompareOption => void,
    currentProject: { project: $Project },
} & $Dispatcher;

const OPTIONS = [
    { value: 'general', label: 'Provider' },
    { value: 'card_scheme', label: 'Card scheme' },
    { value: 'card_type', label: 'Card type' },
    { value: 'custom', label: 'Custom ...' },
];

class ProviderCompareSelector extends React.Component<
    Props,
    {
        showMonitoringPlusPopup: boolean,
    },
> {
    constructor() {
        super();
        this.state = {
            showMonitoringPlusPopup: false,
        };
    }

    render() {
        return (
            <div>
                {this.state.showMonitoringPlusPopup ? (
                    <MonitoringPlusPopup
                        close={() => {
                            this.setState({
                                showMonitoringPlusPopup: false,
                            });
                        }}
                    />
                ) : null}
                <div class='row text-left'>
                    <div class='large-12 columns' style={{ marginTop: '-.5em' }}>
                        <Select
                            value={this.props.value}
                            options={OPTIONS}
                            onChange={(newValue: $ProviderCompareOption) => {
                                if (newValue.value !== 'custom') this.props.onChange(newValue);
                                else {
                                    if (
                                        hasFeature(
                                            this.props.currentProject.project.feature_flags,
                                            'analytics-v2',
                                        )
                                    ) {
                                        this.props.dispatch(
                                            push(
                                                `/projects/${
                                                    this.props.currentProject.project.id
                                                }/boards`,
                                            ),
                                        );
                                    } else {
                                        this.setState({
                                            showMonitoringPlusPopup: true,
                                        });
                                    }
                                }
                            }}
                            clearable={false}
                            valueComponent={ValueContainer}
                            optionComponent={LabelContainer}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class ValueContainer extends React.Component<{
    children: any,
    placeholder: string,
    value: $ProviderCompareOption,
}> {
    render() {
        return (
            <div className='Select-value'>
                <span className='Select-value-label'>
                    {this.props.value.value === 'general' ? (
                        <div>
                            <span class='greyed'>per</span> provider
                        </div>
                    ) : (
                        <div style={{ textTransform: 'lowercase' }}>
                            <span class='greyed'>per </span>
                            provider <span class='greyed'>& </span>
                            {this.props.value.label}
                        </div>
                    )}
                </span>
            </div>
        );
    }
}

class LabelContainer extends React.Component<{
    className: string,
    children: ?any,
    option: any,
    isFocused: boolean,
    onSelect: (any, any) => void,
    onFocus: (any, any) => void,
}> {
    handleMouseDown = event => {
        this.props.onSelect(this.props.option, event);
    };

    handleMouseEnter = event => {
        this.props.onFocus(this.props.option, event);
    };

    handleMouseMove = event => {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    };

    render() {
        return (
            <div
                class={`${this.props.className} ${
                    this.props.option.value === 'custom' ? 'chart-compare-custom' : ''
                }`}
                onMouseDown={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseMove={this.handleMouseMove}
            >
                {this.props.option.value === 'custom' ? (
                    <CallToAction>
                        <span class='text'>{this.props.children}</span>
                    </CallToAction>
                ) : (
                    <div>{this.props.children}</div>
                )}
            </div>
        );
    }
}

export default connect(store => {
    return { currentProject: store.currentProject };
})(ProviderCompareSelector);
