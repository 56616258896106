import { typeFailed } from '../../../util/ProcessOut';

export default function(state = { fetched: false, fetching: false }, action) {
    switch (action.type) {
        case 'FETCH_API_REQUESTS_PENDING': {
            return {
                ...state,
                error: false,
                fetched: false,
                fetching: true,
            };
        }

        case 'FETCH_API_REQUESTS_FULFILLED': {
            return {
                ...state,
                ...action.payload,
                fetched: true,
                fetching: false,
            };
        }

        case typeFailed('FETCH_API_REQUESTS'): {
            return {
                ...state,
                error: true,
                fetched: false,
                fetching: false,
            };
        }
    }

    return state;
}
