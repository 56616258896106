// @flow
/**
 * Created by jeremylejoux on 08/06/17.
 */

import React from 'react';
import { connect } from 'react-redux';
import ContentLayout from '../../../pages/ContentLayout';
import Empty from '../../Empty';
import APDomainName from './APDomainName';
import { Link } from 'react-router';

export class ApplePayDomainName extends React.Component {
    props: Props;

    static defaultProps = {
        dispatch: null,
        currentProject: null,
    };

    componentDidUpdate() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    render() {
        let { currentProject } = this.props;

        if (!currentProject.project || !currentProject.project.applepay_settings) return null;

        currentProject = currentProject.project;
        let applePaySettings = currentProject.applepay_settings;
        if (!applePaySettings) return null;

        let content;
        if (applePaySettings.merchant_domain_names.length === 0) {
            content = <Empty text="No domain names have been added so far." />;
        } else {
            content = (
                <div class="row">
                    <div class="large-12 columns">
                        {applePaySettings.merchant_domain_names.map(function(domain) {
                            return <APDomainName key={domain} domainName={domain} />;
                        })}
                    </div>
                </div>
            );
        }

        return (
            <ContentLayout title={<h4>Domain names</h4>}>
                <div class="row margin-bottom">
                    <div class="medium-10 columns">
                        <p class="greyed">Domain names associated to your merchant id.</p>
                    </div>
                    <div class="medium-2 columns text-right">
                        <Link
                            to={`/projects/${this.props.currentProject.project
                                .id}/settings/apple-pay/domains`}
                            class="button round border small"
                        >
                            Add domain
                        </Link>
                    </div>
                </div>
                {content}
            </ContentLayout>
        );
    }
}

type Props = {
    currentProject:
        | any
        | {
              fetched: boolean,
              fetching: boolean,
              project: {
                  id: string,
                  applepay_settings: any,
              },
          },
    dispatch: any | (() => void),
};

export default connect(function(store) {
    return {
        currentProject: store.currentProject,
    };
})(ApplePayDomainName);
