import React from 'react';
import { Link } from 'react-router';

export default class NotFoundPage extends React.Component {
    render() {
        return (
            <div class='row'>
                <div class='row margin-bottom'>
                    <div class='large-9 columns large-centered text-center'>
                        <div class='error-image' />
                    </div>
                </div>
                <div class='row'>
                    <div class='large-7 large-centered columns text-center'>
                        <h6 class='capitalize bold'>
                            The resource you are looking for could not be found.
                        </h6>
                        <Link to='/projects'>&larr;Go back to your projects</Link>
                    </div>
                </div>
            </div>
        );
    }
}
