import React from 'react';
import ContentLayout from './ContentLayout';
import Loader from '../components/Loader';
import UserUpdate from './UserUpdate';
import { connect } from 'react-redux';

var md5 = require('js-md5');

@connect(function(store) {
    return {
        user: store.user,
    };
})
export default class User extends React.Component {
    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        document.title = 'Settings | ProcessOut';
    }

    componentDidUpdate(prevProps, prevState) {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
    }

    render() {
        var user = this.props.user.details;

        if (!this.props.user.details.fetched || this.props.user.details.fetching) {
            return (
                <ContentLayout title={<h4>Settings</h4>}>
                    <div class='row'>
                        <div class='large-7 medium-8 small-centered columns'>
                            <Loader />
                        </div>
                    </div>
                </ContentLayout>
            );
        }

        return (
            <div class='row content'>
                <div class='row'>
                    <div class='large-7 medium-8 small-centered columns'>
                        <div class='row'>
                            <div class='large-2 medium-3 hide-for-small columns'>
                                <a href='http://en.gravatar.com'>
                                    <img
                                        class='rounded-image'
                                        src={
                                            'https://secure.gravatar.com/avatar/' +
                                            md5(user.email) +
                                            '?s=80&r=g&d=identicon'
                                        }
                                    />
                                </a>
                            </div>
                            <div class='large-10 medium-9 small-12 columns'>
                                <div class='large-9 columns'>
                                    <h4>{user.name}</h4>
                                    <h5>{user.email}</h5>
                                </div>
                            </div>
                        </div>

                        <hr />
                    </div>
                </div>
                <UserUpdate user={user} />
            </div>
        );
    }
}
