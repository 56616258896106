// @flow

import React from 'react';
import type { $ApiKey } from './consts';
import DateDisplay from '../DateDisplay';
import { connect } from 'react-redux';
import type { $Dispatcher } from '../../util/Types';
import KeyOverviewModal from './KeyOverviewModal';
import ModalFooter from '../../components/ModalFooter';
import * as Actions from './actions';
import * as ConfirmActions from '../ConfirmModal/actions';
import { CLOSE_MODAL } from '../../util/Types';

type Props = { privateKey: $ApiKey } & $Dispatcher;

class PrivateKey extends React.Component<Props> {
    _detailsModal: any;

    openKeyDetails = () => {
        const { dispatch, privateKey } = this.props;
        const footer = (
            <ModalFooter
                submitTitle={'Save'}
                submitCallback={() => {
                    const newApiKey: $ApiKey = this._detailsModal.getValue();
                    dispatch(
                        Actions.updatePrivateKey(newApiKey, () => {
                            dispatch({ type: CLOSE_MODAL });
                        }),
                    );
                }}
                deleteCallback={() => {
                    dispatch(
                        ConfirmActions.requestConfirmation(
                            `Deleting a private key cannot be undone.`,
                            () => {
                                // Confirmed deletion
                                dispatch(
                                    Actions.deleteApiKey(this.props.privateKey.name, () => {
                                        dispatch({ type: 'CLOSE_MODAL' });
                                    }),
                                );
                            },
                            () => {
                                // Rejected deletion
                            },
                        ),
                    );
                }}
            />
        );
        dispatch({
            type: 'OPEN_MODAL',
            payload: {
                header: privateKey.name,
                content: (
                    <KeyOverviewModal privateKey={privateKey} ref={e => (this._detailsModal = e)} />
                ),
                footer: footer,
            },
        });
    };

    render() {
        const { privateKey } = this.props;
        return (
            <div
                class={`row collapse api-key-row ${
                    privateKey.sandbox ? 'sandbox' : privateKey.enabled ? 'active' : 'inactive'
                }`}
                onClick={this.openKeyDetails}
            >
                <div class='large-7 columns'>{privateKey.name}</div>
                <div class='large-4 columns'>
                    <DateDisplay value={privateKey.created_at} />
                </div>
                <div class='large-1 columns text-right'>
                    <div class={`log-level ${privateKey.enabled ? 'success' : 'warning'}`}>
                        {privateKey.enabled ? 'Enabled' : 'Disabled'}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(PrivateKey);
