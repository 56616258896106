// @flow

import type { $Action, $State } from '../../util/Types';
import {
    ADD_RULE,
    PREPARE_ROUTING_RULES_SETTINGS,
    REMOVE_RULE,
    RULES_SET_TOTAL_TRANSACTIONS,
    SAVE_ROUTING_RULES,
    UPDATE_RULE,
} from './consts';
import type { $RoutingRulesSettings } from './consts';
import { typeFailed, typeFulfilled } from '../../util/ProcessOut';
import { CHANGE_ROUTING_RULES_ORDER } from './actions';

type State = $RoutingRulesSettings & $State;

const defaultState: State = {
    fetched: false,
    fetching: false,
    error: undefined,
    rules: [],
    totalTransactions: 0,
    saving: false,
};

export default function(state: State = defaultState, action: $Action): State {
    switch (action.type) {
        case PREPARE_ROUTING_RULES_SETTINGS: {
            return {
                ...state,
                ...defaultState,
                fetching: true,
                fetched: false,
            };
        }

        case typeFulfilled(PREPARE_ROUTING_RULES_SETTINGS): {
            if (!action.payload) return state;
            return {
                ...state,
                fetched: true,
                fetching: false,
                rules: action.payload.rules,
            };
        }

        case typeFailed(PREPARE_ROUTING_RULES_SETTINGS): {
            return {
                ...state,
                ...defaultState,
                fetched: true,
                error: action.payload,
            };
        }

        case typeFulfilled(ADD_RULE): {
            if (!action.payload || !action.payload.rule) return state;
            const newRules = state.rules.slice();
            newRules.push(action.payload.rule);
            return {
                ...state,
                rules: newRules,
            };
        }

        case typeFulfilled(UPDATE_RULE):
        case typeFulfilled(REMOVE_RULE): {
            if (!action.payload || !action.payload.rules) return state;
            return {
                ...state,
                rules: action.payload.rules,
            };
        }

        case RULES_SET_TOTAL_TRANSACTIONS: {
            if (!action.payload) return state;
            return {
                ...state,
                totalTransactions: action.payload.totalTransactions,
            };
        }

        case SAVE_ROUTING_RULES: {
            return {
                ...state,
                saving: true,
            };
        }

        case typeFailed(SAVE_ROUTING_RULES):
        case typeFulfilled(SAVE_ROUTING_RULES): {
            return {
                ...state,
                saving: false,
            };
        }

        case CHANGE_ROUTING_RULES_ORDER: {
            const { payload } = action;
            if (!payload || !payload.rules) return state;
            const newBlockRules =
                payload.type === 'block'
                    ? payload.rules
                    : state.rules.filter(rule => rule.declaration === 'block');
            const newTriggerRules =
                payload.type === 'trigger_3ds'
                    ? payload.rules
                    : state.rules.filter(rule => rule.declaration === 'trigger_3ds');
            const newRouteRules =
                payload.type === 'route'
                    ? payload.rules
                    : state.rules.filter(rule => rule.declaration === 'route');
            return {
                ...state,
                rules: [].concat(newBlockRules.concat(newTriggerRules).concat(newRouteRules)),
            };
        }
    }
    return state;
}
