// @flow

import React from 'react';
import type { $Payout } from './reducer';
import moment from 'moment';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import type { $State } from '../../util/Types';
import { Link, withRouter } from 'react-router';
import * as Actions from './actions';

type Props = {
    payout: $Payout,
    processorsConfigurations: {
        configurations: Array<{ id: string, gateway: { logo_url: string }, name: string }>,
    } & $State,
    params: {
        project: string,
    },
};

class Payout extends React.PureComponent<Props> {
    render() {
        const { payout, processorsConfigurations, params } = this.props;

        const config = processorsConfigurations.configurations.find(
            config => payout.gateway_configuration_id === config.id,
        );

        return (
            <Link to={`/projects/${params.project}/reports/payouts/${payout.id}`}>
                <div class='row payout-row'>
                    <div class='medium-1 columns'>
                        <span
                            class={`log-level ${
                                payout.status === 'received'
                                    ? 'success'
                                    : payout.status === 'cancelled' || payout.status === 'failed'
                                    ? 'failed'
                                    : 'warning'
                            }`}
                        >
                            {payout.status}
                        </span>
                    </div>
                    <div className='medium-2 columns'>
                        {Actions.formatCurrency(payout.amount, payout.currency)}
                    </div>
                    <div className='medium-2 columns'>
                        {Actions.formatCurrency(payout.fees, payout.currency)}
                    </div>
                    <div className='medium-4 columns'>
                        {config ? (
                            <div class='row'>
                                <div class='medium-4 columns'>
                                    <img src={config.gateway.logo_url} />
                                </div>
                                <div class='medium-8 columns'>{config.name}</div>
                            </div>
                        ) : (
                            <span class='greyed'>—</span>
                        )}
                    </div>
                    <div className='medium-3 columns text-right greyed'>
                        {moment(payout.created_at).calendar()}
                    </div>
                </div>
            </Link>
        );
    }
}

export default connect(store => {
    return { processorsConfigurations: store.processorsConfigurations };
})(withRouter(Payout));
