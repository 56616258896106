// @flow

import type { $Action } from '../../../../util/Types';
import type { $BoardLayout } from './consts';
import { FETCH_BOARD_DETAILS } from './consts';
import * as ProcessOut from '../../../../util/ProcessOut';
import type { $Params } from '../../ChartPreviewer/consts';
import { parseDates } from '../../Charts/Utils';
import { UPDATE_BOARD_GRID } from '../consts';

export function fetchBoardDetails(id: string, params: $Params): $Action {
    const { interval, filter } = params;
    const dates = parseDates(interval.from, interval.to);

    return {
        type: FETCH_BOARD_DETAILS,
        payload: ProcessOut.APIcallPromise(
            `/boards/${id}?expand[]=charts&start_at=${dates.from}&end_at=${dates.to}&filter=${encodeURIComponent(
                params.filter,
            )}`,
            'GET',
        ),
    };
}

export function updateBoardGrid(boardId: string, layout: $BoardLayout): $Action {
    return {
        type: UPDATE_BOARD_GRID,
        payload: ProcessOut.APIcallPromise(`/boards/${boardId}/charts-positions`, 'PUT', {
            charts: layout,
        }),
    };
}
