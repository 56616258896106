import levenshtein from 'fast-levenshtein';

/*
 *   Look for all gateway which includes the given filter either in their name or tags list
 */
export default function getGatewaysList(gateways, filter) {
    var results = [];

    for (var i = 0; i < gateways.length; i++) {
        var gatewayName = gateways[i].name;
        if (
            gatewayName.includes(filter) ||
            gateways[i].display_name.includes(filter) ||
            levenshtein.get(gatewayName, filter) <= 2
        ) {
            results.push(gateways[i]);
            continue;
        }
        for (var j = 0; j < gateways[i].tags.length; j++) {
            var tagName = gateways[i].tags[j];
            if (tagName.includes(filter) || levenshtein.get(tagName, filter) <= 2) {
                results.push(gateways[i]);
                break;
            }
        }
    }

    return results;
}
