// @flow
/**
 * Created by jeremylejoux on 24/04/17.
 */
import React from 'react';

type Props = {
    text?: string,
};

export default class Error extends React.Component {
    props: Props;
    render() {
        const { text } = this.props;
        return (
            <div class='loader opacity0-8' style={{ textAlign: 'center' }}>
                <div class='row margin-bottom'>
                    <div class='large-6 columns large-centered'>
                        <img
                            src='//dashboard.processout.com/images/cone.png'
                            style={{ height: '50px', opacity: 0.4 }}
                        />
                    </div>
                </div>
                <div class='row'>
                    <div class='large-6 columns large-centered greyed bold'>
                        {text || (
                            <span>
                                An error occured while fetching data.
                                <br />
                                Please try again later.
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
