// @flow
import React from 'react';
import { connect } from 'react-redux';
import PresetCategorySelector from './PresetCategorySelector';
import PresetsList from './PresetsList';
import * as Actions from './actions';
import type { $Dispatcher } from '../../../util/Types';
import Previewer from './Previewer';

class Presets extends React.Component {
    props: $Dispatcher;
    componentDidMount() {
        this.props.dispatch(Actions.selectTab('presets'));
    }
    render() {
        return (
            <div>
                <PresetCategorySelector />
                <PresetsList />
                <div class="large-5 columns preview-container text-right">
                    <Previewer hideName={true} presetPreviewer={true} />
                </div>
            </div>
        );
    }
}

export default connect()(Presets);
