import React from 'react';
import Select from 'react-select';
import * as CouponsActions from '../../Actions/CouponsActions';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';

@connect(function(store) {
    return {
        couponsDetails: store.coupons,
    };
})
export default class CouponSelect extends React.Component {
    constructor() {
        super();
        this.state = {
            value: null,
        };
    }

    componentDidMount() {
        this.props.dispatch(CouponsActions.fetchCoupons());
    }

    couponSelected(value) {
        this.setState({ value: value });
    }

    getSelected() {
        return this.state.value;
    }

    render() {
        const coupons = this.props.couponsDetails.coupons;

        var fetching = !this.props.couponsDetails.fetched && this.props.couponsDetails.fetching;
        var options = [];
        if (!fetching && this.props.couponsDetails.fetched) {
            options = coupons.map(function(coupon) {
                return { value: coupon.id, label: coupon.id };
            });
        }

        return (
            <Select
                id="coupon-selector"
                name="coupon_id"
                placeholder="Apply a coupon"
                options={options}
                isLoading={fetching}
                value={this.state.value}
                onChange={this.couponSelected.bind(this)}
            />
        );
    }
}
