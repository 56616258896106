// @flow

import React from 'react';
import Header from '../components/layout/Header';
import * as UserActions from '../Actions/UserActions';
import UserStore from '../stores/UserStore';
import AuthStore from '../stores/AuthStore';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as Store from '../stores/Store';
import NotificationCenter from '../features/NotificationBar/NotificationCenter';
import Background from '../features/Background/Background';

let serialize = require('form-serialize');

type State = {
    fetching: boolean,
};

@connect(function(store) {
    return {
        user: store.user,
    };
})
export default class Signup extends React.Component {
    email: string;
    password: string;
    unsubscribeLoginWatcher: () => void;
    signedUp: () => void;
    error: () => void;
    loggedIn: () => void;
    state: State;
    businessName: string;

    constructor() {
        super();
        this.state = {
            fetching: false,
        };
        this.email = '';
        this.password = '';
        this.businessName = 'Default project';
    }

    componentWillMount() {
        document.title = 'ProcessOut | Register';
        this.signedUp = this.signedUp.bind(this);
        this.error = this.error.bind(this);
        this.loggedIn = this.loggedIn.bind(this);
        UserStore.on('signedup', this.signedUp);
        UserStore.on('error', this.error);
        AuthStore.on('logged', this.loggedIn);
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent('resize-dashboard'));
        this.unsubscribeLoginWatcher = Store.store.subscribe(this.loggedIn);

        const bgImg = new Image();
        bgImg.onload = event => {
            this.refs.backgroundImage.style.backgroundImage = 'url(' + bgImg.src + ')';
            this.refs.backgroundImage.className += ' signup-background';
            this.refs.signedUp.className += ' loaded';
        };
        bgImg.src = 'https://dashboard.processout.com/images/dashboard-background.jpg';
    }

    componentWillUnmount() {
        UserStore.removeListener('signedup', this.signedUp);
        UserStore.removeListener('error', this.error);
        AuthStore.removeListener('logged', this.loggedIn);
    }

    signupClicked(event: Event) {
        event.preventDefault();
        let signupForm = document.querySelector('#signup-form');
        let signup = serialize(signupForm, { hash: true });
        this.email = signup.email;
        this.password = signup.password;
        this.businessName = signup.business_name;
        let user = JSON.stringify(signup, null, 2);
        UserActions.createUser(user, () => {
            this.error();
        });
        this.setState({ fetching: true });
    }

    error() {
        this.setState({ fetching: false });
    }

    signedUp() {
        this.props.dispatch(UserActions.generateToken(this.email, this.password));
    }

    loggedIn() {
        let nextState = Store.store.getState();
        if (nextState.user.auth.loggedIn) {
            this.unsubscribeLoginWatcher();
            this.props.dispatch(push(`/projects`));
        }
    }

    redirectToSignin = (event: Event) => {
        event.preventDefault();
        this.refs.backgroundImage.className += ' disappear';
        this.refs.signedUp.className = this.refs.signedUp.className.replace('loaded', 'disappear');
        setTimeout(() => {
            this.props.dispatch(push('/login'));
        }, 200);
    };

    render() {
        let button = (
            <input
                type='submit'
                class='round border green button expanded'
                value='GO TO DASHBOARD'
            />
        );
        if (this.props.user.auth.fetching || this.state.fetching) {
            button = (
                <a class='round border button expanded'>
                    <div class='loading-spinner' />
                </a>
            );
        }

        return (
            <div class='colorful main-wrapper'>
                <div
                    class='background-img'
                    style={{
                        background: 'none',
                        backgroundColor: 'black',
                        opacity: 0.5,
                        zIndex: -5,
                    }}
                />
                <div
                    class='background-img'
                    ref={'backgroundImage'}
                    style={{
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        zIndex: -10,
                    }}
                />
                <Background
                    style={{
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        zIndex: -11,
                    }}
                />
                <Header redirectToWebsite={true} />
                <div class='row huge-margin-top'>
                    <form id='signup-form' onSubmit={this.signupClicked.bind(this)}>
                        <div class='large-12 columns'>
                            <div class='row'>
                                <div class='large-4 columns medium-4 small-centered'>
                                    <NotificationCenter />
                                </div>
                            </div>
                            <div ref='signedUp' class='row signup-popup'>
                                <div class='medium-8 medium-centered columns normal-content rounded shadowed'>
                                    <div class='row'>
                                        <div
                                            class='medium-7 columns'
                                            style={{
                                                backgroundColor: '#32325d',
                                                color: 'white',
                                                minHeight: '530px',
                                                borderRadius: '4px 0px 0px 4px',
                                                paddingLeft: '2em',
                                            }}
                                        >
                                            <div
                                                class='row large-margin-bottom'
                                                style={{ minHeight: '7em' }}
                                            >
                                                <div class='medium-4 columns text-right'>
                                                    <img
                                                        src='https://dashboard.processout.com/images/telescope-white.png'
                                                        style={{
                                                            height: '90px',
                                                            marginTop: '3.5em',
                                                        }}
                                                    />
                                                </div>
                                                <div class='medium-8 columns'>
                                                    <div class='row'>
                                                        <div class='large-12 columns'>
                                                            <h2
                                                                class=' '
                                                                style={{
                                                                    fontSize: '3em',
                                                                    marginTop: '1em',
                                                                    textAlign: 'left',
                                                                    color: 'white',
                                                                }}
                                                            >
                                                                Telescope
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div class='row'>
                                                        <div class='large-12 columns'>
                                                            <span>
                                                                Your smart payment assistant
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row margin-bottom collapse'>
                                                <div class='medium-1 columns text-center'>
                                                    <img
                                                        src='https://dashboard.processout.com/images/trendup.png'
                                                        style={{
                                                            height: '30px',
                                                            maxWidth: 'none',
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    class='medium-11 columns'
                                                    style={{
                                                        paddingLeft: '2em',
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontSize: '1.2em',
                                                        }}
                                                    >
                                                        Audit & monitoring tool for online payments
                                                    </p>
                                                </div>
                                            </div>
                                            <div class='row margin-bottom collapse'>
                                                <div class='medium-1 columns text-center'>
                                                    <img
                                                        src='https://dashboard.processout.com/images/insights.png'
                                                        style={{
                                                            height: '35px',
                                                            maxWidth: 'none',
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    class='medium-11 columns'
                                                    style={{
                                                        paddingLeft: '2em',
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontSize: '1.2em',
                                                        }}
                                                    >
                                                        Get insights to decrease failed transactions
                                                        & payment fees.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class='row margin-bottom collapse'>
                                                <div class='medium-1 columns text-center'>
                                                    <img
                                                        src='https://dashboard.processout.com/images/technical.png'
                                                        style={{
                                                            height: '30px',
                                                            maxWidth: 'none',
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    class='medium-11 columns'
                                                    style={{
                                                        paddingLeft: '2em',
                                                        paddingRight: '2em',
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontSize: '1.2em',
                                                        }}
                                                    >
                                                        Start in a click. No technical integration.
                                                        100% free.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='medium-5 columns'>
                                            <div class='row margin-bottom'>
                                                <div class='large-12 columns centered'>
                                                    <h4
                                                        class='title no-spacing'
                                                        style={{
                                                            marginBottom: '0',
                                                            marginTop: '2em',
                                                            color: '#4bb585',
                                                        }}
                                                    >
                                                        Create your account
                                                    </h4>
                                                </div>
                                            </div>
                                            <div class='row'>
                                                <div class='large-10 medium-11 medium-centered columns'>
                                                    <input
                                                        type='text'
                                                        name='name'
                                                        class='bottom-border'
                                                        placeholder='Full name'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div class='row'>
                                                <div class='large-10 medium-11 medium-centered columns'>
                                                    <input
                                                        type='text'
                                                        name='email'
                                                        class='bottom-border'
                                                        placeholder='name@yoursite.com'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div class='row small-margin-bottom'>
                                                <div class='large-10 medium-11 medium-centered columns'>
                                                    <input
                                                        type='password'
                                                        name='password'
                                                        class='bottom-border'
                                                        placeholder='Password'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div class='row small-margin-bottom'>
                                                <div class='large-10 large-centered columns'>
                                                    <p class='small-font greyed'>
                                                        By clicking on the Register button below and
                                                        registering on ProcessOut, you hereby agree
                                                        to be bound by our{' '}
                                                        <a
                                                            target='_blank'
                                                            href='https://www.processout.com/terms-of-service/'
                                                        >
                                                            Terms of Service
                                                        </a>
                                                        .
                                                    </p>
                                                </div>
                                            </div>
                                            <div class='row small-margin-bottom'>
                                                <div class='large-7 large-centered columns text-center'>
                                                    {button}
                                                </div>
                                            </div>
                                            <div class='row'>
                                                <div class='large-12 columns centered margin-bottom text-center'>
                                                    Already have an account?{' '}
                                                    <a
                                                        onClick={this.redirectToSignin}
                                                        style={{
                                                            textDecoration: 'underline',
                                                        }}
                                                    >
                                                        Sign in!
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
