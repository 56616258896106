// @flow

import {
    REQUEST_ADD_AGGREGATION,
    REQUEST_ADD_DIMENSION,
    REQUEST_DELETE_AGGREGATION,
    REQUEST_DELETE_DIMENSION,
    REQUEST_FORM_RESET,
    REQUEST_UPDATE_AGGREGATION,
    REQUEST_UPDATE_DIMENSION,
    REQUEST_UPDATE_FORMULA,
    REQUEST_UPDATE_RENDER_FUNCTION,
} from './consts';
import type { $Aggregation } from '../Aggregator/consts';
import Analytics from '../../../../util/Analytics';
import type { $Dimension } from '../../DataExplorer/ChartBuilder/DimensionSelection/consts';

export function addAggregation(aggregation: $Aggregation) {
    return {
        type: REQUEST_ADD_AGGREGATION,
        payload: {
            aggregation,
        },
    };
}

export function deleteAggregation(id: string) {
    return {
        type: REQUEST_DELETE_AGGREGATION,
        payload: {
            id,
        },
    };
}

export function updateAggregation(aggregation: $Aggregation) {
    return {
        type: REQUEST_UPDATE_AGGREGATION,
        payload: {
            aggregation,
        },
    };
}

export function addDimension() {
    // Track event
    Analytics('CHARTS_ADD_DIMENSION');
    return {
        type: REQUEST_ADD_DIMENSION,
    };
}

export function deleteDimension(id: string) {
    return {
        type: REQUEST_DELETE_DIMENSION,
        payload: {
            id,
        },
    };
}

export function updateDimension(dimension: $Dimension) {
    return {
        type: REQUEST_UPDATE_DIMENSION,
        payload: {
            dimension,
        },
    };
}

export function updateFormula(formula: ?string) {
    return {
        type: REQUEST_UPDATE_FORMULA,
        payload: formula
            ? {
                  formula: formula,
              }
            : null,
    };
}

export function setRenderFunction(renderFunction: string) {
    return {
        type: REQUEST_UPDATE_RENDER_FUNCTION,
        payload: {
            renderFunction,
        },
    };
}

export function requestFormReset() {
    return { type: REQUEST_FORM_RESET };
}

export const formatFormula = (formula: string): string => {
    let result = formula.replace(/\n/g, '').replace(/(;|{|})/g, '$1\n');
    result = result.replace(/AND/g, '\nAND');
    const lines = result.split('\n');
    const newLines = [];
    for (const line of lines) {
        newLines.push(line.trim());
    }

    const indent = (level: number, lines: Array<string>): string => {
        if (lines.length === 0) return '';

        const currentLine = `${'    '.repeat(level)}${lines[0]}${lines.length > 2 ? '\n' : ''}`;

        let newLevel: number = level;
        if (currentLine.includes('{')) newLevel++;
        else if (lines.length > 1 && lines[1].includes('}')) newLevel--;

        lines.splice(0, 1);

        return `${currentLine}${indent(Math.max(newLevel, 0), lines)}`;
    };

    return indent(0, newLines);
};
