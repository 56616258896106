import { EventEmitter } from 'events';
import Dispatcher from '../Dispatcher';

class AuthStore extends EventEmitter {
    constructor() {
        super();
        this.token = '';
        this.expires_at = '';
    }

    getAll() {
        return { token: this.token, expires_at: this.expires_at };
    }

    getError() {
        return this.error;
    }

    handleAction(action) {
        switch (action.type) {
            case 'AUTHENTICATE': {
                this.token = action.token;
                this.expires_at = action.expires_at;
                this.emit('logged');
                break;
            }
            case 'AUTHENTICATE_REJECTED': {
                this.emit('badlogin');
                break;
            }
            case 'ASK_RESET_PASSWORD': {
                this.emit('asked');
                break;
            }
            case 'ASK_RESET_PASSWORD_REJECTED': {
                this.error = action.error;
                this.emit('error');
                break;
            }
            case 'TWOFACTOR_NEEDED': {
                this.emit('two-factor');
            }
        }
    }
}

const authStore = new AuthStore();
Dispatcher.register(authStore.handleAction.bind(authStore));
export default authStore;
